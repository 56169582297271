<div class="modal-header">
    <h4 class="modal-title pull-left fontLight">
        {{ 'commons.sharePdf' | translate }}
    </h4>
    <div
        (click)="closeModal()" aria-label="Close" class="x-close pull-right"
        style="font-size: 20pt;"
    >
        &#x00D7;
    </div>
</div>
<div *ngIf="share=='none'">
    <div class="modal-body">
        <!-- options -->
        <div class="insideCheckboxes">
            <h3 class="ngorange">{{ 'commons.options' | translate }}</h3>
            <div>
                <h4>{{ 'commons.sections' | translate }}</h4>
                <mat-checkbox id="jobDesign"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['jobDesign']"
                >
                    {{ 'jobs.commons.jobDesign' | translate }}
                </mat-checkbox>
                <br/>

                <!--mat-checkbox *ngIf="user.hasPersonalityAccess" id="personalityProfile"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['personalityProfile']"
                              [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores']) ? true : undefined"
                >
                    {{ 'jobs.commons.targetPersonalityProfile' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasTalentsAccess" id="targetCompetencies"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['targetCompetencies']"
                              [disabled]="(totalCompetencies === 0) ? true : undefined"
                >
                    {{ 'jobs.commons.targetCompetenciesProfile' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasIacAccess" id="caiProfile"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['caiProfile']"
                              [disabled]="(rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? true : undefined"
                >
                    {{ 'jobs.commons.targetCaiProfile' | translate}}
                </mat-checkbox>
            </div>

            <div class="floatLeft" style="margin-left: 100px">
                <h4>{{ 'app.navigation.analysis' | translate }}</h4-->

                <mat-checkbox *ngIf="user.hasPersonalityAccess" id="personality"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['personality']"
                              [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores']) ? true : undefined"
                >
                    {{ 'commons.personality' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasTalentsAccess"  id="talents"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['talents']"
                              [disabled]="(totalCompetencies === 0) ? true : undefined"
                >
                    {{ 'commons.talents' | translate }}
                </mat-checkbox>
                <br/>
                <mat-checkbox *ngIf="user.hasIacAccess"  id="cai"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['cai']"
                              [disabled]="(rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? true : undefined"
                >
                    {{ 'commons.cai' | translate }}
                </mat-checkbox>
            </div>
            <div class="clearfix"></div>
        </div>
        <h4 style="margin-top: 10px">{{ 'rms.modal.sortBy' | translate }}</h4>
        <table class="filterTable">
            <tr *ngIf="user.hasPersonalityAccess">
                <td>
                    <input  type="radio" name="sortBy" value="personality"
                           [(ngModel)]="pdfStructure['sortBy']"
                           [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores'] || !this.optionsCheckboxes['personality']) ? true : undefined"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.personality' | translate }}</td>
            </tr>
            <tr  *ngIf="user.hasTalentsAccess">
                <td>
                    <input  type="radio" name="sortBy" value="talents"
                           [(ngModel)]="pdfStructure['sortBy']"
                           [disabled]="(totalCompetencies === 0 || !this.optionsCheckboxes['talents'])  ? true : undefined"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.talents' | translate }}</td>
            </tr>
            <tr  *ngIf="user.hasIacAccess">
                <td>
                    <input type="radio" name="sortBy" value="cai"
                           [(ngModel)]="pdfStructure['sortBy']"
                           [disabled]="(rightMatchAnalysis.itemToDisplay.profiler['RA'] === false || !this.optionsCheckboxes['cai']) ? true : undefined"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.cai' | translate }}</td>
            </tr>
        </table>
        <div style="margin-top: 10px">
            <mat-checkbox  *ngIf="user.hasPersonalityAccess" id="displayTargetPersonalityProfile"
                           [(ngModel)]="pdfStructure['displayTargetPersonalityProfile']"
                           [checked]="pdfStructure['personality'] && pdfStructure['displayTargetPersonalityProfile']"
                           [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores'] || !pdfStructure['personality']) ? '' : null"
            >&nbsp;&nbsp;{{ 'rms.commons.displayTargetPersonalityProfile' | translate }}
            </mat-checkbox>
        </div>
        <div class="insideCheckboxes">
            <h5
                [class.text-danger]="selectMethod"
            ><label for="sendingMethod">{{ 'commons.sendingMethod' | translate }}</label></h5>
            <select [(ngModel)]="method" id="sendingMethod" (change)="changeSelectMethodState()">
                <option>{{ 'commons.select' | translate }}</option>
                <option value="pdf">{{ 'commons.pdfReport' | translate }}</option>
                <option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</option>
                <option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</option>
                <option value="server">{{ 'commons.sendByServer' | translate }}
                </option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="handleReport()">{{ 'commons.continue' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
<!-- share mode -->
<!-- server -->
<div *ngIf="share=='server'">
    <div class="modal-body">
        <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

        <div>
            <h3><b>Message</b></h3>
            <div class="form-group">
                <div class="sendContent">
                    <table style="width: 700px">
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label
                                for="reportEmailEmailTo">{{ 'commons.email' | translate }}</label></td>
                            <td><input
                                id="reportEmailEmailTo"
                                type="text"
                                style="width: 250px"
                                [(ngModel)]="pdfEmail['emailTo']"/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' | translate }}</td>
                        </tr>
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label
                                for="reportEmailSubject">{{ 'commons.emailSubject' | translate }}</label></td>
                            <td><input
                                id="reportEmailSubject"
                                type="text"
                                style="width: 250px"
                                [(ngModel)]="pdfEmail.subject"
                            />
                            </td>
                        </tr>
                    </table>
                    <table style="width: 700px; margin-top: 25px">
                        <tr>
                            <td style="padding-bottom: 7px; font-weight: bold">
                                <span class="field_title"><label
                                    for="reportEmailMessage">{{ 'commons.message' | translate }}</label></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea
                                    id="reportEmailMessage"
                                    rows="7"
                                    style="border: 1px solid #ccc; width: 565px; height: 100px"
                                    [(ngModel)]="pdfEmail.message"></textarea>
                            </td>
                        </tr>
                    </table>
                    <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                    <div id="innerTextServer">
                        <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                        <p>{{ pdfEmail.message }}</p>
                        <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                        <p *ngIf="pdfReportUrl"
                           class="forceBreaks"
                           [innerHTML]="serverText"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="sendEmailReport()">{{ 'commons.shareReport' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- mailto -->
<div *ngIf="share=='mailto'">
    <div *ngIf="mailto" class="modal-body">
        <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
        <p>{{ 'commons.sendByYourEmailReportInstructions' | translate }}
            <a href="mailto:{{ mailto }}" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- cutnpaste-->
<div *ngIf="share=='cutpaste'">
    <div *ngIf="pdfReportUrl" class="modal-body">
        <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
        <p
            [translate]="'analysisCommons.linkInstruction'"
            [translateParams]="{
                    'analysisName': rightMatchAnalysis.name
                }"
        ></p>
        <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
