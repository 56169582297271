<div *ngIf="state.session.isLoggedIn !== true || !page || page=='' || page=='login'; else main">
    <!-- Forget password form -->
    <div *ngIf="page && (page.split('/')[0] == 'reset-pass' || page == 'set-pass'); else login">
        <div *ngIf="page.split('/')[0] == 'reset-pass'">
            <app-mobile-resetpass
                *ngIf="isMobile"
            ></app-mobile-resetpass>
            <app-reset-pass
                *ngIf="!isMobile"
            ></app-reset-pass>
        </div>
        <div *ngIf="page == 'set-pass'">
            <app-setpass
            ></app-setpass>
        </div>
    </div>

    <!-- login form -->
    <ng-template #login>
        <app-mobile-login
            (credentials)="log($event)"
            *ngIf="isMobile"
        ></app-mobile-login>
        <app-login
                (credentials)="log($event)"
                *ngIf="!isMobile"
        ></app-login>
    </ng-template>
</div>
<ng-template #main>
    <div
            class="main-page-content"
            *ngIf="isMobile"
    >
        <app-alert
            [configGlobal]="config"
        >
        </app-alert>
        <router-outlet></router-outlet>
    </div>
    <div
            class="container-fluid" style="overflow-x: hidden"
            *ngIf="!isMobile"
    >
        <!--div style="display: block; position: absolute; left: 150px; top: 0; margin:0; padding: 0; width: 100%; height: 20px; background: none; z-index: 1000000">
            <p style="width: 100%; text-align: left">
                <b>Version bêta.</b> Veuillez raporter un bug <a href="mailto:eopaquette@gmail.com">ici</a>.
            </p>
        </div-->
            <app-alert
                    [configGlobal]="config"
            >
            </app-alert>
            <app-news *ngIf="!showCarousel && !isSender"
                      [showNewsPanel]="showNewsPanel"
                      (closeNews)="showNews()"
            ></app-news>
        <!-- showCarousel && !isSender -->
            <app-carousel *ngIf="showCarousel && !isSender"
                          [showNewsPanel]="showNewsPanel"
                          (hideCarouselEvent)="hideCarousel()"
            ></app-carousel>
            <div class="row">
                <div style="position: absolute; z-index: 1000" [class.block]="page=='reset-pass'">
                    <app-navigation
                            (showNews)="showNews()"
                    ></app-navigation>
                </div>

                <div
                        class="main-page-content"
                >
                    <router-outlet></router-outlet>
                </div>
            </div>
    </div>
</ng-template>
<div *ngIf="update=='expired'" id="backdrop">
    <div
            id="alertFrame"

            style="top: 50%; left: 50%; margin-top: -150px; margin-left: -200px;"
    >
        <div id="innerFrame"
             style="
                width: 400px;
                border-left: 7px solid rgb(244,110,110);
                border-radius: 2px
            "
        >
            <div class="main-container">
                <!--iv *ngIf="x_confirm" style="position: absolute;top: 15px;" [style.left]="x_left_pos">&#x00D7;</div-->
                <div class="icon-container">
                    <img alt="alert" class="icon" src="{{assetsFolder}}alerts/alert.png"/>
                </div>
                <div class="title-container">
                    <h1 class="title semiBold">Mise à jour du logiciel</h1>
                </div>
            </div>
            <hr class='separation'/>
            <p>Ngenioconnect n'est plus à jour. Veuillez fermer toutes les fenêtre Ngenioconnect sauf une
                et cliquer sur « Recharger » ci-dessous afin de charger la nouvelle version.</p>
            <div>
                <div style="display: inline-block">
                    <button class='modalButton' (click)="reloadPage()">Recharger</button>&nbsp;&nbsp;

                </div>
            </div>
        </div>
    </div>
</div>
