<div class="creditBoxInner">
    <div>
        <p>
            <span class="semiBold">{{ 'commons.credits' | translate }}</span>
        </p>
    </div>

    <div class="creditCost" [style.background-color]="(displayWarningMessage$ | async) ? 'rgb(240, 123, 126)' : 'rgb(208, 238, 207)'">
        <p>{{ 'people.requestModal.required' | translate }}</p>
        <p>{{ (requestCreditsCost$ | async) }}</p>
    </div>

    <div class="creditsAvailable">
        <p [class.redWarning]="((subAccountCreditsString$ | async) == '-')">{{ 'people.requestModal.available' | translate }}</p>
        <p>{{ (subAccountCreditsString$ | async) | translate }}</p>
    </div>
    <div
         *ngIf="(subAccountCreditsString$ | async) == '-'"
         style="
             font-size: 7pt;
             line-height: 1em;
             margin-top: 3px;
             color: rgb(240, 123, 126)
            "
    >
        {{ (translate.currentLang == 'fr') ?
            'Sélectionner un sous-compte' :
            'Select a sub-account'
        }}
    </div>
</div>
