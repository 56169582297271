import {EventEmitter, Output} from '@angular/core';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Global} from '../global';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Content} from '../services/content';

export abstract class ComponentPrimitive extends Content {

    @Output() switchOff = new EventEmitter();
    @Output() pdfEvent = new EventEmitter();
    @Output() closeSidebar = new EventEmitter<boolean>();
    closed = false;

    public titleSep: string;
    public loaderDisplay = {
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
    };

    public buttonsFolder = '/assets/buttons/';

    protected constructor(
        protected stateService: StateService,
        protected translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(router, deviceService, stateService);
    }

    setLastAddress() {
        if (this.stateService && this.stateService.session && this.stateService.session.sessionData) {
            let urlArr = this.router.url.split('?');
            this.stateService.session.sessionData.lastAddress = urlArr[0];
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // View

    notSpecified(profiler = false) {
        if (profiler && typeof profiler === 'string') {
            return this.translateService.instant('jobs.commons.jobWithoutProfile');
        }
        return this.translateService.instant('commons.notSpecified');
    }

    noneVal() {
        return this.translateService.instant('commons.none');
    }

    notCompletedVal() {
        return this.translateService.instant('people.commons.notCompleted');
    }

    setSubAccountName(subAccountNumber) {
        let name = null;
        if (subAccountNumber >= 0) {
            for (let subAccData of this.stateService.session.sessionData.structure.subAccounts) {
                if (subAccData.id === subAccountNumber) {
                    name = subAccData.name;
                    // noinspection BreakStatementJS
                    break;
                }
            }
        }
        return name;
    }

    renderType(type) {
        if (type === 'quick' || type === 'short') {
            return this.translateService.instant('analysis.rightMatch.fastProfiler');
        } else if (type === 'survey') {
            return this.translateService.instant('commons.survey');
        } else {
            this.notSpecified();
        }
    }

    checkDropDownOptions(event) {
        let options = [];

        if (event && (typeof event.forEach === 'function')) {
            event.forEach(item => {
                if (item && typeof item === 'object' && item.hasOwnProperty('id')) {
                    options.push(item.id);
                }
            });
            if (!options.length) {
                options = [...event];
            }
        }

        return options;
    }

    checkDropDownPlaceholder(selectedOptions, colClass, placeholder = 'Select') {
        let col = document.getElementsByClassName(colClass)[0];
        if (col) {
            let dropDown = col.getElementsByClassName('multiselect-dropdown')[0];
            if (dropDown) {
                let dropDownText = dropDown.getElementsByClassName('dropdown-btn')[0];
                let dropDownPlHolder = dropDownText.getElementsByClassName('dropDownPlHolder')[0];

                if (dropDownPlHolder) {
                    dropDownPlHolder.remove();
                }
                if (selectedOptions.length) {
                    let span = document.createElement('span');
                    span.className = 'dropDownPlHolder';
                    span.append(placeholder);
                    dropDownText.prepend(span);
                }
            }
        }
    }

    removeDropDownPlHolders() {
        let dropDownPlHolders = document.getElementsByClassName('dropDownPlHolder');
        if (dropDownPlHolders) {
            for (let i of [9, 8, 7, 6, 5, 4, 3, 2, 1, 0]) {
                if (dropDownPlHolders[i]) {
                    dropDownPlHolders[i].remove();
                }
            }
        }
    }

    dateFormat() {
        if (this.translateService.currentLang === 'fr') {
            return 'dd/mm/yyyy';
        }
        return 'mm/dd/yyyy';
    }

    createRange(actualPage, maxPerPage, trim = false) {
        let items: number[] = [];
        let start = (actualPage - 1) * maxPerPage;
        let end = actualPage * maxPerPage;
        for (let i = start; i < end; i++) {
            items.push(i);
        }
        if (trim) {
            let itemsTrimmed: number[] = [];
            let itemsTLenghth = Math.ceil(items.length / 2);
            let startIndex = 0;
            let endIndex = itemsTLenghth;
            if (actualPage > 1) {
                startIndex = items[0] - itemsTLenghth;
                endIndex = startIndex + itemsTLenghth;
            }
            for (let i = startIndex; i < endIndex; i++) {
                if (items[i] !== undefined) {
                    itemsTrimmed.push(i);
                }
            }
            return itemsTrimmed;
        }
        return items;
    }

    itemsDate(item, lang) {
        if (item.date) {
            let date = item.date.substring(10, 0);
            if (lang === 'fr') {
                return date.split(' ')[0].split('-')[2] + '/' +
                    date.split(' ')[0].split('-')[1] + '/' +
                    date.split(' ')[0].split('-')[0];
            } else {
                return date.split(' ')[0].split('-')[1] + '/' +
                    date.split(' ')[0].split('-')[2] + '/' +
                    date.split(' ')[0].split('-')[0];
            }
        } else {
            return;
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Navigation methods

    goToList() {
        if (this.stateService[this.actualModule.name].reportBack) {
            if (this.stateService[this.actualModule.name].reportBack.to) {
                let urlArray = this.stateService[this.actualModule.name].reportBack.to.split(';');
                this.router.navigate([urlArray[0]]).then();
            }
            this.stateService[this.actualModule.name].reportBack = null;
        } else {
            let url = this.actualModule.name + '/list';
            if (this.actualModule.name === 'rightMatch' || this.actualModule.name === 'idwMatrix' || this.actualModule.name === 'graphboard') {
                url = 'analysis/' + url.replace('M', 'm');
            } else if (this.actualModule.name === 'jobSatisfaction') {
                url = 'analysis/' + url.replace('Satisfaction', 'satisfaction');
            }
            if (this.isMobile) {
                url = 'm/' + url;
            }
            this.router.navigate([url]).then(() => {
                this.stateService[this.actualModule.name].backTo = true;
            });
        }
    }

    onPdfShare(item) {
        if (item === null || item === undefined) {
            throw 'onPdfShare needs exactly 1 argument, 0 provided';
        }
        this.pdfEvent.emit(item);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Modals

    setModalClass(componentName: string) {
        let componentDom = document.getElementsByTagName(componentName);
        if (componentDom && componentDom[0]) {
            let modalElement = componentDom[0].closest('.modal-dialog');
            modalElement.className = 'modal-dialog send-modal';
        }
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Utilities

    specificState() {
        let specificState = this.actualModule.name;
        if (this.actualModule.name === 'archives') {
            let archiveModule = this.router.url.split('/')[3];
            let capitalized = archiveModule[0].toUpperCase() +
                archiveModule.slice(1);
            capitalized = capitalized.replace('m', 'M');
            specificState = specificState + capitalized;
        }
        return specificState;
    }
}
