<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'jobs.modal.noJob' | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body" style="padding: 10px;">
    {{ 'jobs.modal.noJobText' | translate }}
    <button (click)="openNewJobModal()" class="modalButton">
        {{ 'jobs.modal.newJob' | translate }}
    </button>
</div>

<div class="modal-footer">

    <div class="pull-right" style="margin-left: 10px">
        <button type="button" (click)="closeModal()" class="modalButton pull-right" style="margin-right: 10px">
            {{ 'commons.cancel' | translate }}
        </button>
    </div>
</div>
