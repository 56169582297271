<div *ngIf="jobService.id" class="formWrapper">
    <h4 class="modal-title">{{ 'jobs.modal.jobCompetenceProfile' | translate }}</h4>
    <br />
    <h4>{{ 'jobs.modal.instructions' | translate }}</h4>
    <ng-container>
        <p [innerHTML]="'jobs.modal.instruction1' | translate"></p>
        <a *ngIf="!showSortOption(translateService.currentLang)" target="_blank" href="{{ apiBaseUrl }}/assets/dictionary/{{'jobs.modal.competenciesDictionaryFilename' | translate}}">
            {{ 'jobs.modal.competenciesDictionaryLinkTitle' | translate }}
        </a>
    </ng-container>
    <div *ngIf="showSortOption(translateService.currentLang)" class="jobsCompetencesHeader">
        <mat-grid-list cols="2" rowHeight="37px">
            <mat-grid-tile colspan="1" rowspan="1">
                <a target="_blank" href="{{ apiBaseUrl }}/assets/dictionary/{{'jobs.modal.competenciesDictionaryFilename' | translate}}">
                    {{ 'jobs.modal.competenciesDictionaryLinkTitle' | translate }}
                </a>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <div *ngIf="talentsOrder === 'title'">
                    Sort:&nbsp;
                    <span class="semiBold">alphabetical</span> |
                    <a (click)="updateSorting('number')">numerical</a>
                </div>
                <div *ngIf="talentsOrder === 'number'">
                    Sort:&nbsp;
                    <a (click)="updateSorting('title')">alphabetical</a> |
                    <span class="semiBold">numerical</span>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <hr class="panelHr" />

    <table class="jobTalentsData">
        <ng-container *ngFor="let talent of talentsData; let i=index; trackBy: trackByCompetencieId">
            <tr [class.darkBg]="i%2 === 0">
                <td class="talentStatus"
                    [class.semiBold]="selectedTalents[talent.id]==1 || selectedTalents[talent.id]==2"
                >
                    <app-talent-status
                        [selectedValue]="selectedTalents[talent.id]"
                        [talentNumber]="getProperty(talent, 'number')"
                    ></app-talent-status>
                </td>
                <td class="talentStatus"
                    [class.semiBold]="selectedTalents[talent.id]==1 || selectedTalents[talent.id]==2"
                >
                        <span
                            [tooltip]="talent.text"
                            class="compLink"
                        >
                            {{ getProperty(talent, 'fullTitle') }}&nbsp;
                        </span>
                </td>
                <td>
                    <div class="dropdown">
                        <div class="dropbtn" (click)="toggleMastering(i)">
                            {{ dropDownValues(talent.id, 0)[0] }} <span class="caret"></span>
                        </div>
                        <div class="dropdown-content surfInPage"
                             [class.show]="subMenu[i]"
                        >
                            <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 1)[1], i)">
                                {{ dropDownValues(talent.id, 1)[0] }}
                            </div>
                            <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 2)[1], i)">
                                {{ dropDownValues(talent.id, 2)[0] }}
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
    </table>
    <div class="jobsCompetencesFooter justifyRight">
        <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile colspan="1" rowspan="1">
                <button
                    mat-button
                    type="button"
                    class="modalButton"
                    aria-label="Clear"
                    (click)="closeForm()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
                <button
                    mat-button
                    type="submit"
                    class="modalButton"
                    (click)="proceed()"
                >
                    {{ 'commons.save' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

</div>
