import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {Tools} from '../../../shared/barrels/misc';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AccountService} from '../../services/account.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-administration-purchase-adjustement-modal',
    templateUrl: './administration-purchase-adjustement-modal.component.html',
    styleUrls: ['./administration-purchase-adjustement-modal.component.scss']
})
export class PurchaseAdjustementModalComponent implements OnInit, OnDestroy {
    // ToDo: account modelngOn
    @Input() account;
    proceed = true;

    public close: EventEmitter<any> = new EventEmitter();
    public editRefresh: EventEmitter<any> = new EventEmitter();

    @ViewChild('purchaseAdjustementNgForm') purchaseAdjustementNgForm;

    subAccounts: any;
    purchaseAdjustementForm: FormGroup;
    sessionStructure: any = {};
    validateFormField = Tools.validateFormField;
    errorForm: any;

    private subscriptions = new Subscription();

    submitted = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private stateService: StateService,
        private accountService: AccountService,
        private router: Router,
        private apiAdministration: ApiAdministrationService) {
    }

    ngOnInit() {
        this.subAccounts = (this.account) ? this.account.subAccounts : undefined;
        this.subscriptions.add(this.stateService.session.sessionDataWatch$.subscribe(
            (res) => {
                if (res) {
                    this.sessionStructure = this.stateService.session.sessionData.structure;
                }
            }
        ));

        this.initForm();
    }

    closeModal() {
        this.resetForm();
        this.close.emit({closed: true});

        this.bsModalRef.hide();
    }

    initForm() {
        this.submitted = false;
        const accountId = (this.account) ? this.account.id : undefined;
        this.purchaseAdjustementForm = this.fb.group({
            credits: ['', [Validators.required]],
            subAccount: ['', [Validators.required]],
            extraDescription: [''],
            clientId: [accountId, [Validators.required]]
        });
    }

    formCheck(specificField = null) {
        if (this.purchaseAdjustementNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.purchaseAdjustementForm.controls[specificField], this.purchaseAdjustementNgForm)) {
                    return true;
                }
            } else {
                let fields = ['credits', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.purchaseAdjustementForm.controls[field], this.purchaseAdjustementNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    checkCreditSufficiency(subaccountNumber, amount) {
        if (
            subaccountNumber === null || subaccountNumber === undefined || subaccountNumber === '' ||
            amount === null || amount === undefined || amount === ''
        ) {
            return;
        }
        let creditSubscription = this.apiAdministration.clientSubaccountCredits(
            [this.accountService.id, subaccountNumber]
        ).subscribe(
            res => {
                // In case we are editing a transaction, we need to remove the initial amount from the current amount
                if (res.credits === 'unlimited' || res.credits === '9999') {
                    this.proceed = true;
                } else {
                    this.proceed = !(parseInt(res.credits, 10) + parseInt(amount, 10) < 0);
                }
                creditSubscription.unsubscribe();
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    onSubmit(model: FormGroup) {
        this.submitted = true;
        if (model.valid) {
            // console.log(model.value);
            let data = model.value;

            // call api to save the modification
            this.subscriptions.add(this.apiAdministration.postPurchaseAdjustment(data).subscribe(
                () => {
                    this.stateService.admin.stateChanged.next({refreshComponent: true});
                    this.closeModal();
                },
                error => {
                    this.errorForm = error.error;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    // ToDo: get subAccounts -> map to remove unlimited subAccounts
    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.purchaseAdjustementForm.reset();
            this.purchaseAdjustementNgForm.submitted = false;
            this.initForm();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }
}
