<h2><span>&nbsp;</span></h2>
<ul class="plainList {{for}}Ul">
    <li><div>&nbsp;</div></li>
    <li><div>&nbsp;</div></li>
</ul>

<hr/>

<h3 *ngIf="for !== 'ra'" class="reportTitle"><span>&nbsp;</span></h3>
<ng-container *ngIf="for === 'mpo'">
    <hr/>
    <p *ngFor="let i of [0,1,2,3,4,5]" class="georgia">
        <span *ngFor="let j of pLines[i]">&nbsp;</span>
    </p>

    <ng-container *ngFor="let i of [1,2]">
        <h3 class="reportTitle"><span>&nbsp;</span></h3>
        <ng-container *ngFor="let j of [1,2]">
            <h4><span>&nbsp;</span></h4>
            <hr class="h4sep" />
            <ul class="georgia">
                <li *ngFor="let k of [1,2,3,4]"><div class="portraitList">&nbsp;</div></li>
            </ul>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="for === 'competences'">
    <ng-container *ngFor="let i of [0,1,2]">
        <h4><span>&nbsp;</span></h4>
        <svg width="282.5" height="36.25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 930 105">
            <rect x="0" y="40" width="930" height="20" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
            <rect x="622" y="0" rx="50" ry="50" width="285" height="90" fill="none" stroke-width="6" stroke="#78c7af"></rect>
            <circle cx="669" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white" stroke-width="9"></circle>
            <text class="text" x="670" y="61" text-anchor="middle" font-size="46"></text>
            <circle cx="764" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white" stroke-width="9"></circle>
            <text class="text" x="765" y="61" text-anchor="middle" font-size="46"></text>
            <circle cx="859" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white" stroke-width="9"></circle>
            <text class="text" x="860" y="61" text-anchor="middle" font-size="46"></text>
        </svg>
        <p class="georgia">
            <span *ngFor="let j of [0,1]">&nbsp;</span>
        </p>
        <b>&nbsp;</b>
        <p *ngFor="let j of [0,1]" class="georgia">
            <span *ngFor="let k of [0,1]">&nbsp;</span>
        </p>
        <hr class="h4sep" />
    </ng-container>
</ng-container>

<ng-container *ngIf="for === 'ra'">
    <p class="fakeLink">
        <span>&nbsp;</span>
    </p>
    <img src="assets/graphicalElements/nCurve.png" style="opacity: 0.35;" alt=""/>
    <svg id="iacMainGraph" preserveAspectRatio="xMidYMid meet" viewBox="0 0 980 55">
        <rect x="0" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="50" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="110" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="160" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="220" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="270" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="330" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="380" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="440" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="490" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="550" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="600" cy="25" r="15.5" stroke="rgb(238, 238, 238)"  stroke-width="7" style="fill: white;"></circle>
        <rect x="660" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="710" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="770" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="820" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="880" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="930" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
    </svg>
    <br/>
    <h2 class="fullWidth"><span>&nbsp;</span></h2>
    <ng-container *ngFor="let i of [0,1]">
        <h3 class="reportTitle"><span>&nbsp;</span></h3>
        <p class="georgia">
            <span *ngFor="let j of [0,1]">&nbsp;</span>
        </p>
    </ng-container>
</ng-container>
