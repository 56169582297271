<div class="personCard unselectable" id="personCard_{{personOnCard.id}}"
     *ngIf="decalageLeft && personOnCard"
     [class.ngOrangeBorder]="personOnCard.id == selectedItemId"
     [class.cardSelected]="selection"
     [class.cardNotSelected]="!selection"
     (mouseenter)="checkPersonTooltips(personOnCard.id)"
     (mouseleave)="leavePersonBlock(personOnCard.id)"
>
    <div class="personCardName">
        <p style="margin:0; padding:0; width: 100%; text-align: center">{{ firstName }}<br>
            {{ lastName }}</p>
    </div>

    <div class="personCardSubTitle">
        &nbsp;{{ subTitle }}
    </div>

    <div class="personCardEvaluations">
        <div class="splitInFive">
            <div
                    class="chipContainer"
            >
                <div
                        class="indChip mpo"
                        [style.margin-left.px]="decalageLeft[0]"
                >
                    <div
                            class="chip"
                            [tooltip]="'commons.personality' | translate"
                            (onShown)="hideTooltips('person')"
                            #personTooltip="bs-tooltip"
                    >
                        <div
                                *ngIf="mpoScores; else buyMPO"
                                class="cardEvaluation"
                                (click)="display($event, 'personality')"
                        >
                            <app-mini-mpo-graph
                                    [score]="mpoScores"
                                    [interaction]="'sidebar'"
                            ></app-mini-mpo-graph>
                        </div>
                        <ng-template #buyMPO>
                            <div
                                    class="cardEvaluation"
                                    (click)="(hasMpoReport) ?
                                        activate($event, 'personality') :
                                        send($event, 'mpo', (testStatuses['mpo'] === 'N'))"

                            >
                                <app-people-card-no-report
                                        [type]="'activate.4'"
                                        [which]="'personality'"
                                        [card]="true"
                                        [cardId]="personOnCard.id"
                                        [hasMpoReport]="hasMpoReport"
                                        [anotherTitle]="(testStatuses && testStatuses['mpo'] === 'NC') ? {en: 'Resend', fr: 'Renvoyer', y : 33} : null"
                                ></app-people-card-no-report>

                            </div>
                        </ng-template>
                    </div>
                </div>
                <div
                        class="indChip satellite"
                        [style.margin-left.px]="decalageLeft[1]"
                >
                    <div
                            class="chip"
                            [tooltip]="'Satellite'"
                            (onShown)="hideTooltips('sat')"
                            #satTooltip="bs-tooltip"
                    >
                        <div
                                *ngIf="(satelliteScore && satelliteScore !== 'noJobProfile'); else buySatellite"
                                class="cardEvaluation"
                                (click)="display($event, 'satellite')"
                        >
                            <app-mini-satellite-graph
                                    [score]="satelliteScore"
                            ></app-mini-satellite-graph>
                        </div>
                        <ng-template #buySatellite>
                            <div
                                    class="cardEvaluation"
                                    (click)="(hasMpoReport) ?
                                        activate($event, 'satellite') :
                                        send($event, 'mpo', (testStatuses && testStatuses['mpo'] === 'N'))"
                            >
                                <app-people-card-no-report
                                        [type]="'activate.1'"
                                        [which]="'satellite'"
                                        [hasMpoReport]="hasMpoReport"
                                        [card]="true"
                                        [cardId]="personOnCard.id"
                                        [anotherTitle]="satelliteIconTitle"
                                ></app-people-card-no-report>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div
                        class="indChip dit"
                        [style.margin-left.px]="decalageLeft[2]"
                >
                    <div
                            class="chip"
                            [tooltip]="'Communication'"
                            (onShown)="hideTooltips('dit')"
                            #ditTooltip="bs-tooltip"
                    >
                        <div
                                *ngIf="ditQuad; else buyIDW"
                                class="cardEvaluation"
                                (click)="display($event, 'communication')"
                        >

                            <app-mini-idw-graph
                                    [quad]="ditQuad"
                                    [type]="'sidebar'"
                            ></app-mini-idw-graph>
                        </div>
                        <ng-template #buyIDW>
                            <div
                                    class="cardEvaluation"
                                    (click)="(hasMpoReport) ?
                                        activate($event, 'communication') :
                                        send($event, 'mpo', (testStatuses && testStatuses['mpo'] === 'N'))"
                            >
                                <app-people-card-no-report
                                        [type]="'activate.2'"
                                        [which]="'communication'"
                                        [card]="true"
                                        [cardId]="personOnCard.id"
                                        [hasMpoReport]="hasMpoReport"
                                        [anotherTitle]="ditIconTitle"
                                ></app-people-card-no-report>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div
                        class="indChip talent"
                        [style.margin-left.px]="decalageLeft[3]"
                >
                    <div
                            class="chip"
                            [tooltip]="'Talent'"
                            (onShown)="hideTooltips('talent')"
                            #talentTooltip="bs-tooltip"
                    >
                        <div
                                *ngIf="talentsScore; else buyTalents"
                                class="cardEvaluation"
                                (click)="display($event, 'talents')"
                        >
                            <app-mini-competences-graph
                                    [id]="'talents_mini'"
                                    [scores]="talentsScore"
                                    [type]="'cards'"
                            ></app-mini-competences-graph>
                        </div>
                        <ng-template #buyTalents>
                            <div
                                    class="cardEvaluation"
                                    (click)="(hasMpoReport) ?
                                        activate($event, 'talents') :
                                        send($event, 'mpo', (testStatuses && testStatuses['mpo'] === 'N'))"
                            >
                                <app-people-card-no-report
                                        [type]="'activate.2'"
                                        [which]="'talents'"
                                        [hasMpoReport]="hasMpoReport"
                                        [card]="true"
                                        [cardId]="personOnCard.id"
                                        [anotherTitle]="mpoIconTitle"
                                ></app-people-card-no-report>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="raPermission"
                     class="indChip ra"
                     [style.margin-left.px]="decalageLeft[4]"
                >
                    <div
                            class="chip"
                            [tooltip]="'commons.cai' | translate"
                            (onShown)="hideTooltips('cai')"
                            #caiTooltip="bs-tooltip"
                    >
                        <div
                                *ngIf="iacScores; else buyIac"
                                class="cardEvaluation"
                                (click)="display($event, 'iac')"
                        >
                            <app-mini-iac-graph
                                    [id]="'iac_mini'"
                                    [card]="true"
                                    [score]="iacScores"
                            ></app-mini-iac-graph>
                        </div>
                        <ng-template #buyIac>
                            <div
                                    (click)="send($event, 'iac', (testStatuses && testStatuses['ra'] === 'N'))"
                            >
                                <app-people-card-no-report
                                        [type]="'activate.2'"
                                        [which]="'iac'"
                                        [card]="true"
                                        [cardId]="personOnCard.id"
                                        [anotherTitle]="(testStatuses && testStatuses['ra'] === 'NC') ? {en: 'Resend', fr: 'Renvoyer', y : 33} : null"
                                ></app-people-card-no-report>

                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
