<div class="modal-header">
    <h4 class="modal-title pull-left">
        {{ 'rms.modal.filterPeople' | translate }}
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body">
    <div class="floatLeft" style="width: 50%">
        <h2 class="ngorange">{{ 'rms.modal.sortBy' | translate }}</h2>
        <table class="filterTable">
            <tr>
                <td>
                    <input type="radio" name="sortBy" value="personality"
                           [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                           [disabled]="(!rightMatch.itemToDisplay.profiler['scores'] || !user.hasPersonalityAccess || !filters.display.personality) ? true : undefined"
                    >
                </td>
                <td>{{ 'commons.personality' | translate }}</td>
            </tr>
            <tr>
                <td>
                    <input type="radio" name="sortBy" value="talents"
                           [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                           [disabled]="(totalCompetencies === 0 || !user.hasTalentsAccess || !filters.display.talents) ? true : undefined"
                    >
                </td>
                <td>{{ 'commons.talents' | translate }}</td>
            </tr>
            <tr>
                <td>
                    <input type="radio" name="sortBy" value="cai"
                           [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                           [disabled]="(rightMatch.itemToDisplay.profiler['RA'] === false || !user.hasIacAccess || !filters.display.cai) ? true : undefined"
                    >
                </td>
                <td>{{ 'commons.cai' | translate }}</td>
            </tr>
        </table>
    </div>
    <div class="floatRight" style="width: 50%">
        <h2 class="ngorange">{{ 'rms.modal.display' | translate }}</h2>
        <table class="filterTable">
            <tr>
                <td><input type="checkbox" name="display" value="displayPersonality" [(ngModel)]="filters.display.personality"
                    [disabled]="(!rightMatch.itemToDisplay.profiler['scores'] || !user.hasPersonalityAccess) ? true : undefined"
                    (click)="filter('personality')"
                ></td>
                <td>{{ 'commons.personality' | translate }}</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="sortBy" value="displayTalents" [(ngModel)]="filters.display.talents"
                     [disabled]="(totalCompetencies === 0 || !user.hasTalentsAccess)  ? true : undefined"
                     (click)="filter('talents')"
                ></td>
                <td>{{ 'commons.talents' | translate }}</td>
            </tr>
            <tr>
                <td><input type="checkbox" name="sortBy" value="displayCai" [(ngModel)]="filters.display.cai"
                        [disabled]="(rightMatch.itemToDisplay.profiler['RA'] === false || !user.hasIacAccess) ? true : undefined"
                        (click)="filter('cai')"
                ></td>
                <td>{{ 'commons.cai' | translate }}</td>
            </tr>
        </table>
    </div>
    <div class="clearfix"></div>
    <h2 class="ngorange">Option</h2>
    <input type="checkbox" name="sortBy" value="displayTargetPersonalityProfile"
           [(ngModel)]="filters.display.targetPersonalityProfile"
    > {{ 'rms.commons.displayTargetPersonalityProfile' | translate }}
</div>
<div class="modal-footer">
    <button type="submit"
            class="modalButton pull-right"
            (click)="onSubmit()"
            style="margin-top: 15px"
    >
        {{ 'commons.save' | translate }}
    </button>
    <button (click)="closeModal()" type="button" class="modalButton pull-right"
            style="margin-top: 15px; margin-right: 15px"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <div class="clearfix"></div>
</div>
