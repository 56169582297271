import {
    Component,
    Input,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { PeopleSurveyRequestFormService } from '../../../../services/people-survey-request-form.service';
import { PeopleSurveyRequestStep} from '../people-survey-request-step.enum';
import { ApiPeopleService } from 'src/app/people/services/api-people.service';
import { PeopleSurveyRequestHelper} from '../people-survey-request.helper';

@Component({
    selector: 'app-mobile-user-list',
    templateUrl: './mobile-user-list.component.html',
    styleUrls: ['./mobile-user-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileUserListComponent implements OnInit, OnDestroy {
    @ViewChild('searchTerm') searchTerm: ElementRef;
    @Input() surveyRequestFormGroup: FormGroup;

    public recordList$: Observable<any>;
    public recordsList: any;
    public convertDate: Function;
    public loading = true;
    public lastSearch = {
        value: '""',
        count: 0
    };

    public subscriptions = new Subscription();

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private apiPeopleService: ApiPeopleService,
    ) {}

    ngOnInit() {
        this.getRecordsBySearch();
        // Function to convert date
        this.convertDate = PeopleSurveyRequestHelper.convertDate;

        // first display after the recordsList initializing
        this.focusRes();
    }

    public focusRes() {
        setTimeout(() => {
            const event = new KeyboardEvent('keydown', {
                key: 'Enter',
            });
            this.loading = false;
            this.searchTerm.nativeElement.dispatchEvent(event);
        }, 800);
    }

    public clearSearch() {
        this.searchTerm.nativeElement.value = '';
        this.getRecordsBySearch();
    }

    public getRecordsBySearch(searchTerm = ''): void {
        if (this.lastSearch.value === searchTerm) {
            this.lastSearch.count++;
        } else {
            this.lastSearch.count = 0;
        }
        this.lastSearch.value = searchTerm;
        this.recordList$ = this.apiPeopleService.records([{
            evaluationReport: 'mpo',
            search: searchTerm,
            limit: '200',
            fields: 'id,firstName,creationDate,lastName,evaluations,jobTitle,organisation,status,subAccount',
            isArchived: 0
        }]).pipe(distinctUntilChanged(isEqual));
        if (this.lastSearch.count === 0) {
            this.loading = true;
            this.subscriptions = this.recordList$.subscribe((records) => {
                this.recordsList = records.data;
            });
            this.focusRes();
        }
    }

    public cancelSelectRecord(): void {
        this.surveyRequestFormGroup.get('variant').patchValue('');
        this.peopleRequestFormService.updateRequestStep(PeopleSurveyRequestStep.RequestForm);
    }

    public selectRecord(record: any): void {
        this.peopleRequestFormService.updateRecordSelected(record);
        this.peopleRequestFormService.updateRequestStep(PeopleSurveyRequestStep.RequestForm);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
