<ng-sidebar-container style="position: absolute; height: 100vh">

    <!-- A sidebar -->
    <ng-sidebar
            [position]="'right'"
            [(opened)]="jobsList._opened"
            [animate]="false"
            [closeOnClickOutside]="false"
            [autoFocus]="false"
            (openedChange)="onSidebarStatusChange($event)"
            #gSidebar
    >
        <div>
            <div class="outerColumnGrey col-md-4" [style.width]="sideWidth">
                <div class="innerColumnGrey" [style.width]="sideWidth">
                    <div *ngIf="jobsList.sideBarStatus === 'recentActivities' || jobsList.sideBarStatus === '' || (jobsList.sideBarStatus === 'reports' && !jobService.id)">
                        <app-infopanel [width]="sideWidth"></app-infopanel>
                    </div>
                    <div *ngIf="jobsList.sideBarStatus=='searchFilters'">
                        <app-jobs-list-search-filters
                            [whereAmI]="'filter'"
                            [width]="sideWidth"
                            (switchOff)="jobsList.switchOffFilterPanelIndicator($event)"
                        ></app-jobs-list-search-filters>
                    </div>
                    <div *ngIf="jobsList.sideBarStatus === 'reports' && jobService.id">
                        <app-jobs-sidebar
                            [reportType]="jobsList.jobsState.report['reportType']"
                            [width]="sideWidth"
                            [config]="'list'"
                            (refreshWindow)="refreshWindow()"
                            (closeSidebar)="closeJobsSidebar($event)"
                        ></app-jobs-sidebar>
                        <!-- removed from above component
                            (reportChangeType)="reportChangeType($event)"
                            (reportActivation)="openReportActivationModal($event)"
                            (peopleEditEvent)="openPeopleEditModal($event)"
                        -->
                    </div>
                    <div *ngIf="jobsList.sideBarStatus=='clickMode'">
                        <app-jobs-selected-items
                            (switchOff)="jobsList.switchOffFilterPanelIndicator($event, true)"
                            [archive]="jobsList.archive"
                            [width]="sideWidth"
                            (actionOnRecords)="actOnRecords($event)"
                            (completeAction)="completeAction($event)"
                        ></app-jobs-selected-items>
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar>

    <div
        (window:resize)="onResize($event)"
        (window:orientationchange)="onResize($event)"
        ng-sidebar-content
        style="overflow-x: hidden"
    >
        <div class="rightWrapper row right-wrapper-tablet"
             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"
             (scrolled)="jobsList.listScrolled()"
        >
            <div class="outerColumnWhite"
                 [class.col-md-8]="!smallSize"
                 [class.clear]="!smallSize"
                 [style.width]="(!smallSize) ? listWidth : null"
            >
                <div
                        id="topMenuWrapper"
                        class="affix"
                        [class.topMenuCalcWidth]="smallSize"
                        [style.width]="(!smallSize) ? listWidth : null"
                >
                    <div>
                        <div class="pull-left">
                            <h1 class="sTitle">
                                <span *ngIf='!jobsList.archive'>{{ 'commons.jobs' | translate }}</span>
                                <span *ngIf='jobsList.archive'>{{ 'commons.archives' | translate }}</span>
                            </h1>
                        </div>
                        <div class="pull-right">
                            <app-main-list-search-bar
                                [switchOff]="jobsList.switchOff"
                                [selectionByFilters]="jobsList.selectionByFilters"
                                (searchEvent)="setSearchFilter($event)"
                                (filterPanel)="jobsList.toggleFilterPanel()"
                            ></app-main-list-search-bar>
                            <div class="clearfix"></div>
                            <div class="searchResults">
                                <span
                                    style="font-weight: bold"
                                    *ngIf="jobsList.selectionDone && jobsList.totalListItems !== null"
                                >
                                    {{ jobsList.totalListItems }}
                                    {{ jobsList.resMention | translate }}
                                    <span *ngIf="jobsList.totalItems">
                                        {{ 'commons.of' | translate }} {{ jobsList.totalItems }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div id="subMenu">
                        <div class="pull-left" id="subMenuLeft">
                            <div>
                                <table class="nav nav-topbar">
                                    <tr class="topMenu">
                                        <td
                                            *ngIf='!stateService.session.hasSatelliteRole && !jobsList.archive'
                                            (click)="jobsModalHandlingService.jobEditModal({status: 'new', section: 'jobDescription'})"
                                        >
                                            <a>
                                                <div>
                                                    <div style="float:left">
                                                        <i class="material-icons" style="color: #CCCCCC; margin-top: 2px">add</i>
                                                    </div>
                                                    <div style="float: right; margin-top: 7px;">{{ 'commons.new' | translate }}</div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </a>
                                        </td>
                                        <td *ngIf='!stateService.session.hasSatelliteRole' [class.clickMode]="jobsList.clickMode" (click)="jobsList.switchMode()">
                                            <a>
                                                <div>
                                                    <img src="assets/header/comparer/ico_comparer.png" alt=""/>
                                                    <div>
                                                        {{ 'commons.toSelect' | translate }}
                                                    </div>
                                                    <img src="assets/header/comparer/ico_comparer_selected.png" alt=""/>
                                                </div>
                                            </a>
                                        </td>
                                        <td (click)="refreshList()">
                                            <a>
                                                <div style="padding-top: 2px">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                                                        <path
                                                            fill="#AAA"
                                                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99
                                                        8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6
                                                        6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                                                </div>
                                            </a>
                                        </td>
                                        <!--td class="dropdown" style="overflow: visible"><a class="dropbtn" href="#">
                                            <div>
                                                <img src="assets/header/options/ico_options.png"/>
                                                <div>
                                                    Options
                                                </div>
                                                <img src="assets/header/options/ico_options_selected.png"/>
                                            </div>
                                        </a>
                                            <div class="dropdown-content">
                                                <a>Multigraph</a>
                                                <a>RightMatch</a>
                                                <a>Archiver</a>
                                            </div>
                                        </td-->
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div *ngIf="jobsList.archive">
                                <div
                                    *ngIf="stateService && stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/rightmatch']"
                                >
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService && stateService.hasCommunication"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/idwmatrix']"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService && stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/graphboard']"
                                >
                                    <div>{{ 'commons.graphboard' | translate }} </div>
                                </div>
                                <div
                                    *ngIf="stateService && stateService.hasJobAccess"
                                    class="pull-right typeSelected"
                                >
                                    <div>{{ 'commons.jobs' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    [routerLink]="['/archives/list/people']"
                                >
                                    <div>{{ 'commons.individus' | translate }}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div *ngIf="jobsList.toList" [style.height]="(jobsList.toList['count']==0) ? '700px': null" id="listWrap">
                    <ngx-loading
                        [show]="jobsList.loading"
                        [config]="loaderDisplay"
                    >
                    </ngx-loading>
                    <app-main-list
                        [small]="smallSize"
                        [forceWidthTo]="listWidth.substring(0, listWidth.length - 2)"
                        [selectedItemId]="jobService.id"
                        [tableDefinition]="jobsList.tableDefinition"
                        [view]="jobsList.jobsState['listView']"
                        [clickMode]="jobsList.clickMode"
                        [listsData]="jobsList.toList"
                        [poste]="true"
                        [actualModule]="'jobs'"
                        [archive]="jobsList.archive"
                        (listScrolled)="jobsList.listScrolled()"
                        (selectedItem)="jobSelected($event)"
                        (reordering)="reorderingListsRows($event)"
                    ></app-main-list>
                </div>
            </div>
        </div>
    </div>

</ng-sidebar-container>
