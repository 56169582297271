<div class="formWrapper">
    <h4 class="modal-title" *ngIf="status !== 'edit'">
        {{ 'gbs.modal.newGraphboard' | translate }}
    </h4>
    <h4 class="modal-title" *ngIf="status === 'edit'">
        {{ 'gbs.modal.editGraphboard' | translate }}
    </h4>
    <div
        class="text-danger"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>

    <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

    <form  [formGroup]="graphboardForm" (ngSubmit)="onSubmit(graphboardForm)" #graphboardNgForm="ngForm">
        <div *ngIf="headerText">
            {{ headerText | translate }}
        </div>
        <br />

        <label for="gbName">{{ 'gbs.modal.gbName' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="gbName" formControlName="name">
            <small *ngIf="validateFormField(graphboardForm.controls.name, graphboardNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label>{{ 'commons.subAccount' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="sessionStructure">
            <mat-select formControlName="subAccount">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">{{ subAcct.name }}</mat-option>
            </mat-select>
            <small *ngIf="validateFormField(graphboardForm.controls.subAccount, graphboardNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label for="description">{{ 'commons.description' | translate }}</label>
        <mat-form-field appearance="fill">
            <textarea matInput
                      id="description"
                      formControlName="description"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="5"
            ></textarea>
            <small *ngIf="validateFormField(graphboardForm.controls.description, graphboardNgForm, submitted)"
                   class="text-danger"
            ></small>
        </mat-form-field>

        <label>{{ 'commons.division' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="departments">
            <mat-select formControlName="department">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{ dpt['fr'] }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onCancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="submit"
                        class="modalButton"
                        [disabled]="!graphboardNgForm.valid"
                >
                    <span *ngIf="status !== 'edit'">
                    {{ 'commons.create' | translate }}
                    </span>
                    <span *ngIf="status === 'edit'">
                        {{ 'commons.save' | translate }}
                    </span>
                </button>
            </mat-grid-tile>
        </mat-grid-list>

    </form>
</div>
