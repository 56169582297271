import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AbstractState} from './state';
import {PeopleState, PeopleStateColumns} from '../../interfaces/state';
import {TranslateService} from '@ngx-translate/core';

export class StateArchivesPeople extends AbstractState {

    // Structural elements ///////////////////////////////////////////////////////////////////////

    readonly columns = ['firstName', 'lastName', 'creationDate', 'jobTitle', 'organisation', 'subAccountName',
        'portraitName', 'recordType', 'departmentName']; // @ilya 2020-08-26 'status' column has been removed
    readonly columnsName = {
        fr: {
            firstName: 'Prénom',
            lastName: 'Nom',
            creationDate: 'Date',
            jobTitle: 'Titre',
            organisation: 'Organisation',
            subAccountName: 'Sous-compte',
            'portraitName': 'Portrait',
            // 'status': 'Questionnaire',
            'recordType': 'Type',
            departmentName: 'Département',
        },
        en: {
            firstName: 'First Name',
            lastName: 'Last Name',
            creationDate: 'Date',
            jobTitle: 'Title',
            organisation: 'Organization',
            subAccountName: 'Sub-account',
            'portraitName': 'Portrait',
            // 'status': 'Questionnaire',
            'recordType': 'Type',
            departmentName: 'Department',
        }
    };
    readonly filters = [
        'search',
        'evaluationStartDate',
        'evaluationEndDate',
        'evaluationType',
        'evaluationReport',
        'recordType',
        // OLD FILTERS ################
        // 'evaluationRequestExist',
        // 'evaluationEnable',
        // 'evaluationDisable',
        // 'evaluationName',
        // 'evaluationCompletionStatus',
        // NEW FILTERS ################
        'reportStatuses',
        'testStatuses',
        // ############################
        'subAccount',
        'department'
    ];

    public listColumnsInitialConfig: Array<PeopleStateColumns> = [
        {key: 'firstName', display: true},
        {key: 'lastName', display: true},
        {key: 'creationDate', display: true},
        // {key: 'status', display: true}
    ];

    ////// STATE DEFINITION //////////////////////////////////////////////////////////////////////

    protected state: PeopleState = {
        personToDisplayId: null,
        sideBarStatus: 'recentActivities',
        report: {
            type: 'personality',
            subType: null
        },
        goTo: null,
        listView: 'list',
        listSearchFilters: {},
        listAction: '',
        listColumns: this.listColumnsInitialConfig,
        hiddenColumns: [],
        prbIndex: 0,
        selectedInfoQuestionTab: 0,
        informationVisibility: true
    };

    set search(search) {
        this.listSearchFilters.search = search;
    }

    get search(): string {
        return this.listSearchFilters.search;
    }

    // MULTISELECT ///////////////////////////////////////////////////////////////////////////////

    public multiselect = [];

    // WATCHES ///////////////////////////////////////////////////////////////////////////////////

    stateChanged = new BehaviorSubject(null);
    stateChanged$ = this.stateChanged.asObservable();

    multiselectWatch = new BehaviorSubject(null);
    multiselectWatch$ = this.multiselectWatch.asObservable();

    personToDisplayWatch = new BehaviorSubject(null);
    personToDisplayWatch$ = this.personToDisplayWatch.asObservable();

    listWatch = new BehaviorSubject(null);
    listWatch$ = this.listWatch.asObservable();

    prbWatch = new BehaviorSubject(null);
    prbWatch$ = this.prbWatch.asObservable();

    // CONSTRUCTOR ///////////////////////////////////////////////////////////////////////////////

    constructor(
        private translate: TranslateService
    ) {
        super();
        // Initialization from sessionStorage
        for (let item in this.state) {
            if (this.state.hasOwnProperty(item)) {
                this.retrieveStore('archivesPeople.' + String(item));
            }
        }
    }

    // @ilya 2020-07-24 - this function is not actually used for the people archive by state.service
    // setColumnWithPermissions(permissions, hasSatelliteRole) {
    //     if (hasSatelliteRole) {
    //         let index = this.columns.indexOf('portraitName');
    //         if (index > -1) {
    //             this.columns.splice(index, 1);
    //         }
    //         this.columnsName['fr']['portraitName'] = undefined;
    //         this.columnsName['en']['portraitName'] = undefined;
    //     }
    //     if (!hasSatelliteRole) {
    //         if (permissions.mpo && this.columns.indexOf('evaluations.mpo.info.status') === -1) {
    //             this.columns.push('evaluations.mpo.info.status');
    //             this.columnsName['fr']['evaluations.mpo.info.status'] = 'Personnalité';
    //             this.columnsName['en']['evaluations.mpo.info.status'] = 'Personality';
    //         }
    //         if (permissions.dit && this.columns.indexOf('evaluations.dit.info.status') === -1) {
    //             this.columns.push('evaluations.dit.info.status');
    //             this.columnsName['fr']['evaluations.dit.info.status'] = 'Communication';
    //             this.columnsName['en']['evaluations.dit.info.status'] = 'Communication';
    //         }
    //         if (permissions.talents && this.columns.indexOf('evaluations.talents.info.status') === -1) {
    //             this.columns.push('evaluations.talents.info.status');
    //             this.columnsName['fr']['evaluations.talents.info.status'] = 'Talent';
    //             this.columnsName['en']['evaluations.talents.info.status'] = 'Talent';
    //         }
    //     }
    //     if (permissions.satellite && this.columns.indexOf('evaluations.satellite.info.status') === -1) {
    //         this.columns.push('evaluations.satellite.info.status');
    //         this.columnsName['fr']['evaluations.satellite.info.status'] = 'Satellite';
    //         this.columnsName['en']['evaluations.satellite.info.status'] = 'Satellite';
    //
    //     }
    //
    // }

    // STATE LOGIC ///////////////////////////////////////////////////////////////////////////////
    /**
     cleanWhenAll(list){
        if(
            list &&
            list.split(",").indexOf('mpo')>-1 &&
            list.split(",").indexOf('dit')>-1 &&
            list.split(",").indexOf('talents')>-1 &&
            list.split(",").indexOf('satellite')>-1
        ){
            return 'x';
        } else {
            return list;
        }
    }
     */

    actionPreProcessing(action, params, modState, url = null) {
        switch (action) {
            case 'refreshList':
                modState.listAction = 'refreshList';
                modState['listUpdate'] = '';
                return [url, modState];
            case 'resetSearchFilters':
                modState.listSearchFilters = {};
                modState.listColumns = this.listColumnsInitialConfig;
                modState.listView = 'list';
                if (!params['noRefresh']) {
                    modState['listUpdate'] = '';
                }
                modState.listAction = 'resetSearchFilters';
                return [url, modState];

            case 'setSearchFilters':
                for (let filter of this.filters) {
                    if (filter) {
                        if (typeof params[filter] !== 'undefined') {
                            modState.listSearchFilters[filter] = params[filter];
                        }
                    }
                }
                modState.listAction = 'setSearchFilters';
                modState['listUpdate'] = '';
                return [url, modState];

            case 'setListConfig':
                modState.listAction = '';
                let update = true;
                if (typeof (params.listView) !== 'undefined' &&
                    params.listView !== 'list' &&
                    params.listView !== 'cards'
                ) {
                    throw 'Illegal view for setter StateArchivePeople._listView';
                }
                // View
                if (params.listView) {
                    modState.listView = params.listView;
                }
                // Display
                if (params['key'] && params['display'] !== null && (typeof params['display'] !== 'undefined')) {
                    let index = _.findIndex(modState.listColumns, function (o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    if (index > -1) {
                        if (params['display']) {
                            modState.listColumns[index] = {
                                key: params['key'],
                                display: params['display']
                            };
                        } else {
                            modState.listColumns.splice(index, 1);
                        }
                    } else {
                        if (params['display']) {
                            modState.listColumns.push({
                                key: params['key'],
                                display: params['display']
                            });
                        }
                    }
                    update = false; // avoid flipping back to a person if selected / list didn't change
                }

                if (params['key'] && params['order'] !== null && (typeof params['order'] !== 'undefined')) {
                    // tslint:disable-next-line:forin
                    let index = _.findIndex(modState.listColumns, function (o) {
                        if (o) {
                            return o.key === params['key'];
                        }
                    });
                    for (let indexRemoved in modState.listColumns) {
                        if (
                            modState.listColumns.hasOwnProperty(indexRemoved) &&
                            modState.listColumns &&
                            modState.listColumns[indexRemoved] && (
                                modState.listColumns[indexRemoved]['order'] === 'asc' ||
                                modState.listColumns[indexRemoved]['order'] === 'desc'
                            )
                        ) {
                            delete modState.listColumns[indexRemoved]['order'];
                        }
                    }
                    let column = this.state.listColumns[index];
                    if (column) {
                        column['order'] = params['order'];
                        // console.log(column['order']);
                        modState.listColumns[index] = column;
                    }
                }

                if (update) {
                    modState['listUpdate'] = ''; // avoid flipping back to a person if selected / list didn't change
                }
                return [url, modState];

            case 'setSideBarStatus': // was sideBarStatus
                modState.listAction = '';
                if (params.sideBarStatus !== 'recentActivities' &&
                    params.sideBarStatus !== 'searchFilters' &&
                    params.sideBarStatus !== 'clickMode'
                ) {
                    throw 'Illegal status for setter StateArchivePeople.sideBarStatus';
                }
                // modState['report'] = {};
                modState['sideBarStatus'] = params.sideBarStatus;
                return [url, modState];

            case 'setReport': // was sideBarStatusReports
                modState.listAction = '';
                if (params.personToDisplayId === null) {
                    throw 'PersonTodisplayId must be non-null in setReport';
                }
                if (!params.reportType) {
                    params.reportType = 'mpo';
                }
                if (_.has(params, 'personToDisplay')) {
                    _.unset(params, 'personToDisplay');
                }

                if (params.reportBack) {
                    modState.reportBack = params.reportBack;
                    params.reportBack = null;
                }

                modState.report = params;
                // PRB index
                modState.prbIndex = 0;
                modState['sideBarStatus'] = 'reports';
                modState['personToDisplayUpdate'] = '';
                return [url, modState];

            case 'getFullReport':
                modState.listAction = '';
                modState.report = params;
                url = url + '/' + params.personToDisplayId + '/' + params.reportType;
                return [url, modState];

            case 'setPrbIndex':
                modState.listAction = '';
                modState.prbIndex = +params.prbIndex;
                modState['prbUpdate'] = '';
                return [url, modState];

            default:
                throw 'Inexistant action on state-archive-people.preProcessing';
        }

    }

    ////////////////////////////////////////////////////////////////

    urlProcessing(url, params) {
        return params;
    }

    ////////////////////////////////////////////////////////////////
    actionPostProcessing(type, param, value) {
        if (type === 'optionalParams') {
            switch (param) {
                case 'listUpdate':
                    this.listWatch.next(true);
                    return false;
                case 'personToDisplayUpdate':
                    this.personToDisplayWatch.next(true);
                    return false;
                case 'prbUpdate':
                    this.prbWatch.next(true);
                    return false;
                default:
                    break;
            }
        } else if (type === 'mendatoryParams') {
            switch (param) {
                case 'reportType':
                    this.state.report['reportType'] = value;
                    break;
                case 'personToDisplayId':
                    this.state.report['personToDisplayId'] = value;
                    break;
                default:
                    break;
            }
        }
        return true;

    }

    // GETTERS AND SETTERS /////////////////////////////////////////////////////////////

    set personToDisplayId(personToDisplayId: string) {
        // no observable. This is handled via the person service
        this.state.personToDisplayId = personToDisplayId;
    }

    get personToDisplayId(): string {
        return this.state.personToDisplayId;
    }

    set sideBarStatus(status) {
        this.state.sideBarStatus = status;
    }

    set prbIndex(prbIndex) {
        this.state.prbIndex = prbIndex;
    }

    get sideBarStatus(): string {
        return (this.state.sideBarStatus && this.state.sideBarStatus !== '') ?
            this.state.sideBarStatus : 'recentActivities';
    }

    set listAction(listAction) {
        this.state.listAction = listAction;
    }

    set reportType(reportType) {
        this.state.report.type = (reportType) ? reportType : 'personality';
    }

    get reportType(): string {
        return this.state.report.type;
    }

    set reportSubType(reportType) {
        this.state.report.subType = (reportType) ? reportType : null;
    }

    get reportSubType(): string {
        return this.state.report.subType;
    }

    set report(report) {
        this.state.report = report;
    }

    get report(): any {
        return this.state.report;
    }

    set listSearchFilters(listSearchFilters) {
        this.state.listSearchFilters = listSearchFilters;
    }

    get listSearchFilters(): any {
        return this.state.listSearchFilters;
    }

    get listView(): string {
        return this.state.listView;
    }

    set listView(value) {
        this.state.listView = value;
    }

    set listColumns(listColumns) {
        this.state.listColumns = listColumns;
    }

    get listColumns(): any {
        return this.state.listColumns;
    }

    set goTo(moduleName) {
        this.state.goTo = moduleName;
    }

    get goTo() {
        return this.state.goTo;
    }

    get tableDefinition(): any {
        this.state.listColumns = _.uniqBy(this.state.listColumns, 'key');
        this.state.listColumns = this.state.listColumns.filter(col => col.key !== 'status');
        let listColumnsNonTrivial = _.compact(this.state.listColumns);

        let colNum = listColumnsNonTrivial.length;
        let tableDefinition = this.listColumnsInitialConfig;
        if (colNum >= 2) {
            tableDefinition = listColumnsNonTrivial;
        }
        tableDefinition.forEach((col, index) => {
            col['title'] = this.columnsName[this.translate.currentLang][col.key];
            tableDefinition[index] = col;
        });
        return tableDefinition;
    }

    get reportBack(): any {
        return this.state.reportBack;
    }

    set reportBack(coord) {
        this.state.reportBack = coord;
    }

    get prbIndex(): number {
        return this.state.prbIndex;
    }
}
