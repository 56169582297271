<mat-grid-list
    cols="5"
    rowHeight="60px"
    class="mobileHeader"
>
    <mat-grid-tile
        colspan="1"
        rowspan="1"
        style="max-width: 87px;"
    >
        <app-mobile-navigation></app-mobile-navigation>
        <a
            class="reloadLink"
            (click)="goToPeople()"
        >
            <img alt="N" src="{{logoImg}}">
        </a>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <app-mobile-main-list-search-bar
            [initialValue]="lastSearch"
            (searchEvent)="setSearchFilter($event)"
            style="width: 85%"
        ></app-mobile-main-list-search-bar>
    </mat-grid-tile>
</mat-grid-list>
<div class="searchResults">
    <span
        style="font-weight: bold"
        *ngIf="selectionDone && totalListItems !== null"
    >
        {{totalListItems}}
        {{ resMention | translate }}
        <span *ngIf="totalItems">
            {{ 'commons.of' | translate }} {{totalItems}}
        </span>
    </span>
</div>
<h1 class="sectionTitle">{{'commons.administration' | translate }}</h1>
<div *ngIf="toList"
     infiniteScroll
     [infiniteScrollDistance]="4"
     [infiniteScrollThrottle]="300"
     [scrollWindow]="false"
     (scrolled)="listScrolled()"
     class="scrollableList"
>
    <ngx-loading [show]="loading" [config]="{
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
        }"></ngx-loading>
    <div *ngIf="initList">
        <app-mobile-main-list
            [selectedItemId]="getProperty(state, 'clientToDisplayId')"
            [tableDefinition]="getProperty(state, 'tableDefinition')"
            [listsData]="toList"
            [sectionName]="'admin'"
            (selectedItem)="clientSelected($event);"
            (reordering)="reorderingListsRows($event)"
            [orderedBy]="orderedBy"
        ></app-mobile-main-list>
    </div>
</div>
