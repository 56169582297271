import {Injectable} from '@angular/core';
import {PeopleRequestSendResendModalComponent} from '../components/web/people-request-send-resend-modal/people-request-send-resend-modal.component';
import {PeopleReportActivationModalComponent} from '../components/web/people-report-activation-modal/people-report-activation-modal.component';
import {UnaccessibleTestsModalComponent} from '../../shared/components/unaccessible-tests-modal/unaccessible-tests-modal.component';
import {PeoplePdfModalComponent} from '../components/web/people-pdf-modal/people-pdf-modal.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {PersonService} from './person.service';
import {StateService} from '../../core/services/state/state.service';
import {Content} from '../../shared/classes/services/content';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PeopleEditModalComponent} from '../components/web/people-edit-modal/people-edit-modal.component';
import {GraphboardsModalComponent} from '../../analysis/graphboards/components/web/graphboards-modal/graphboards-modal.component';
import {IdwMatricesModalComponent} from '../../analysis/idw-matrices/components/web/idw-matrices-modal/idw-matrices-modal.component';
import {RightMatchesModalComponent} from '../../analysis/right-matches/components/web/right-matches-modal/right-matches-modal.component';
import {take} from 'rxjs/operators';
import {AddPeopleAnalysisModalComponent} from '../../analysis/shared/add-people-analysis-modal/add-people-analysis-modal.component';
import {ApiRightMatchesService} from '../../analysis/right-matches/services/api-right-matches.service';
import {ApiIdwMatricesService} from '../../analysis/idw-matrices/services/api-idw-matrices.service';
import * as _ from 'lodash';
import {
    JobSatisfactionsModalComponent
} from '../../analysis/jobSatisfactions/components/web/job-satisfactions-modal/job-satisfactions-modal.component';
import {
    JobSatisfactionsAnalysisService
} from '../../analysis/jobSatisfactions/services/job-satisfactions-analysis.service';
import {ApiJobSatisfactionsService} from '../../analysis/jobSatisfactions/services/api-job-satisfactions.service';

@Injectable({
    providedIn: 'root'
})
export class PeopleModalHandlingService extends Content {

    modalRef: BsModalRef;

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        private person: PersonService,
        protected stateService: StateService,
        public bsModalService: BsModalService,
        private apiRightMatchesService: ApiRightMatchesService,
        private apiIdwMatrices: ApiIdwMatricesService,
        private apijobSatisfaction: ApiJobSatisfactionsService
    ) {
        super(router, deviceService, stateService);
    }

    openModal(options: any): void {
        // Input defaults
        options.data = (options.data) ? options.data : null;
        options.iacForm = (options.iacForm) ? options.iacForm : false;
        options.resendForm = (options.resendForm) ? options.resendForm : false;
        options.prbForm = (options.prbForm) ? options.prbForm : false;
        options.modal = (options.modal) ? options.modal : 'send';

        if (options.prbForm) {
            options.modal = 'sendPrb';
        }
        if (options.iacForm) {
            options.modal = 'sendIac';
        }

        // Variable correction for sending to back.
        if  (_.get(options, 'data.type')) {
            options.data.type = (options.data.type === 'communication') ? 'dit' : options.data.type;
            options.data.type = (options.data.type === 'personality') ? 'mpo' : options.data.type;
        }



        // setup default variables
        let modalId: any =  PeopleRequestSendResendModalComponent;
        let initialState: any = {
            data: (options.data) ? options.data : undefined,
            raForm: (options.iacForm) ? options.iacForm : undefined,
            prbForm: (options.prbForm) ? options.prbForm : undefined,
            resendForm: (options.resendForm) ? options.resendForm : undefined
        };

        switch (options.modal) {
            case 'edit':
                modalId = PeopleEditModalComponent;
                    break;
            case 'reportActivation':
                if (this.person.hasPrbOnHold && options.data.type === 'mpo') {
                    // when we activate a personality report with a related PRB,
                    // we should also pass the PRB index to activate it too
                    options.data.prbRelated = this.person.prbIndex;
                }
                initialState = {
                    activationData: options.data
                };
                modalId = PeopleReportActivationModalComponent;
                break;
            case 'unaccessibleTest':
                initialState = {
                    lang: this.translateService.currentLang,
                    date: options.data
                };
                modalId = UnaccessibleTestsModalComponent;
                break;
            case 'pdfModal':
                initialState = {
                    personToDisplay: this.person,
                    report: this.stateService.people.reportType
                };
                modalId = PeoplePdfModalComponent;
                break;
            case 'send':
            default:
                break;
        }
        this.modalRef = this.bsModalService.show(
            modalId,
            {
                initialState,
                backdrop : 'static'
            });
        switch (options.modal) {
            case 'send':
                this.setModalClass('app-people-request-send-resend-modal');
                break;
            case 'sendPrb':
                this.setModalClass('app-people-request-send-resend-modal');
                break;
            case 'sendIac':
                this.setModalClass('app-people-request-send-resend-modal');
                break;
            case 'edit':
                this.setModalClass('app-people-edit-modal');
                break;
            case 'resendIac':
            case 'resendMpo':
                this.setModalClass('app-people-request-resend-modal');
                break;
        }
        this.modalRef.content.close.subscribe(res => {
            let payload: any = {
                refreshReport: true,
                refreshList: true,
                id: res.id
            };

            if (options.modal === 'reportActivation') {
                let type = options.data.type;
                if (options.data.type === 'dit') {
                     type = 'communication';
                }
                if (options.data.type === 'mpo') {
                    type = 'personality';
                }
                payload['targetReport'] = type;
            }

            if (res.closed) {
                this.stateService.people.stateChanged.next(payload);
            }
        });
    }

    openSendExtraPrbModal(sendPrbData = null) {
        this.openModal({data: sendPrbData, prbForm: true});
    }

    openSurveyModal() {
        this.openModal({modal: 'send'});
    }

    openPeopleEditModal() {
        this.openModal({modal: 'edit'});
    }

    openRequestSendIacModal(sendIacData = null): void {
        this.openModal({data: sendIacData, iacForm: true});
    }

    openRequestSendMpoModal(sendMpoData = null, justMpo = false): void {
        let iacForm = !sendMpoData.justMpo;
        this.openModal({data: sendMpoData, iacForm: iacForm, justMpo: justMpo});
    }

    openRequestResendModal(resendData): void {
        this.openModal({data: resendData, resendForm: true});
    }

    openRequestResendMpoModal(resendData): void {
        this.openRequestResendModal(resendData);
    }
    openRequestResendIacModal(resendData): void {
        this.openRequestResendModal(resendData);
    }

    openPdfModal(): void {
        this.openModal({modal: 'pdfModal'});
    }




    checkReporUnaccessble(reportType = this.stateService.people.reportType) {
        let reportBackName = reportType;
        if (reportBackName === 'personality') {
            reportBackName = 'mpo';
        } else if (reportBackName === 'communication') {
            reportBackName = 'dit';
        } else if (reportBackName === 'iac') {
            reportBackName = 'ra';
        }
        if (!this.stateService.session.sessionData.structure.testAccessPermissions[reportBackName]) {
            const initialState = {
                lang: this.translateService.currentLang,
                test: reportType
            };

            this.openModalUnaccessibleTest(reportType);
            return true;
        }
        return false;
    }

    openModalUnaccessibleTest(data): void {
        console.log(data);
        this.openModal({data: data, modal: 'unaccessibleTest'});
    }

    openReportActivationModal(activationData): void {
        if (!this.actualModule.archive) {
            this.openModal({data: activationData, modal: 'reportActivation'});
        }
    }

    /**
     * Activate Mpo or Iac report(s)
     * @param activationData
     */
    openReportsActivationModal(activationData): void {
        this.openReportActivationModal(activationData);
    }

    setModalClass(componentName: string) {
        let componentDom = document.getElementsByTagName(componentName);
        if (componentDom && componentDom[0]) {
            let modalElement = componentDom[0].closest('.modal-dialog');
            modalElement.className = 'modal-dialog send-modal';
        }
    }

    openAnalysisModal(data, analysisType: any = false) {
        if (!analysisType) { throw 'analysis type mandatory!'; }
        let modalType;
        let closeUrl;
        switch (analysisType) {
            case 'graphboard':
                modalType = GraphboardsModalComponent;
                closeUrl = '/analysis/graphboard/list/';
                break;
            case 'jobSatisfaction':
                modalType = JobSatisfactionsModalComponent;
                closeUrl = '/analysis/jobsatisfaction/list/';
                break;
            case 'teamMatrix':
                modalType = IdwMatricesModalComponent;
                closeUrl = '/analysis/idwmatrix/list/';
                break;
            case 'rightMatch':
                modalType = RightMatchesModalComponent;
                closeUrl = '/analysis/rightmatch/list/';
                break;
        }
        const initialState = {
            itemToDisplay: null,
            multiAction: data.multiAction,
            status: data.status
        };

        let gModalRef = this.bsModalService.show(modalType, {initialState, backdrop : 'static'});

        gModalRef.content.close.subscribe(res => {
            if (res.closed) {
                this.router.navigate([closeUrl], {queryParams: { id: res.id }}).then(() => {});
            }
        });
    }

    openWellbeingPeopleToAnalysisModal(event) {
        this.apijobSatisfaction.jobSatisfactions([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = event.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [record.id]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                multiAction: event.multiAction,
                modalTitle: 'commons.addToIBT',
                analysisType: 'jobSatisfaction'
            };

            // Open modal
            let peopleAnalysisModalRef = this.bsModalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                // class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });

            peopleAnalysisModalRef.content.close.subscribe(res => {
                if (res.id) {
                    let url = '/analysis/jobsatisfaction/list/';
                    this.router.navigate([url]).then(() => {});
                }
            });
        });
    }

    openRightMatchPeopleToAnalysisModal(event) {
        this.apiRightMatchesService.rightMatches([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = event.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [record.id]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                multiAction: event.multiAction,
                modalTitle: 'commons.addToRm',
                analysisType: 'rightmatch'
            };

            // Open modal
            let peopleAnalysisModalRef = this.bsModalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                // class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });

            peopleAnalysisModalRef.content.close.subscribe(res => {
                if (res.id) {
                    let url = '/analysis/rightmatch/list/';
                    this.router.navigate([url]).then(() => {});
                }
            });
        });
    }

    openIdwMatrixPeopleToAnalysisModal(event) {
        this.apiIdwMatrices.ditGrams([{limit: 500, isArchived: false, 'order[name]': 'asc'}]).pipe(take(1)).subscribe((analysis: any) => {
            // Parse people data and transform them to add trait and PRB data
            const analysisRecordsData = event.multiAction['data'].map(
                (record: any) => ({...record, analysisData: [{type: 'trait', record: record.id}, {type: 'prb', dash: 0, record: record.id}]})
            );

            // Add data to modal
            const modalState = {
                analysisList: analysis['data'],
                peopleList: analysisRecordsData,
                multiAction: event.multiAction,
                modalTitle: 'commons.addToTeamMatrix',
                analysisType: 'idw'
            };

            // Open modal
            let peopleAnalysisModalRef = this.bsModalService.show(AddPeopleAnalysisModalComponent, {
                ignoreBackdropClick: true,
                // class: 'modal-sm',
                initialState: modalState,
                backdrop : 'static'
            });

            peopleAnalysisModalRef.content.close.subscribe(res => {
                if (res.id) {
                    let url = '/analysis/idwmatrix/list/';
                    this.router.navigate([url]).then(() => {});
                }
            });
        });
    }

}
