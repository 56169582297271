<div class="modal-header">
    <h4 *ngIf="status === 'new'" class="modal-title pull-left">{{ 'jobs.sidebar.fillQuickRA' |
        translate }}</h4>
    <h4 *ngIf="status === 'edit'" class="modal-title pull-left">{{ 'jobs.sidebar.editQuickRA' | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<div class="modal-body" style="padding-top: 2px; margin-bottom: 5px">
    <div id="scoreBlock" style="margin-bottom: 5px">
        <label for="quickRAScore">{{ 'jobs.sidebar.enterQuickRAScore' | translate }}</label>&nbsp;&nbsp;&nbsp;
        <input
                class="scoreContainer{{ RAScore > 9 ? ' error' : ''}}"
                type="text"
                id="quickRAScore"
                [(ngModel)]="RAScore"
                (ngModelChange)="refreshGraph()"
                (keydown)="checkPressedKey($event)"
        />
    </div>
    <div id="graphBlock">
        <img src="assets/graphicalElements/nCurve.png"  alt=""/>
        <app-iac-graph
            [id]="'modal_' + jobService.id"
            [normalizedScore]="null"
            [jobScore]="RAScore"
        ></app-iac-graph>
    </div>
    <div  *ngIf="raTexts && RAScore" id="iacTextsBlock">
        <h3>{{ 'commons.workEnvironment' | translate }}</h3>
        <p>
            {{ raTexts['env'][RAScore] }}
        </p>
        <h3>{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
        <p>
            {{ raTexts['job'][RAScore] }}
        </p>
    </div>
</div>
<div class="modal-footer">
    <div class="pull-right" style="margin-left: 10px">
        <button
            type="submit"
            class="modalButton pull-right"
            [disabled]="RAScore > 9"
            (click)="closeModal((status === 'new') ? 'save' : 'update')"
        >
            {{ 'commons.save' | translate }}
        </button>
        <button
            type="button"
            class="modalButton pull-right"
            style="margin-right: 10px"
            (click)="closeModal()"
        >
            {{ 'commons.cancel' | translate }}
        </button>
    </div>
</div>
