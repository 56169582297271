import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {StateService} from '../../../../../core/services/state/state.service';
import {UserPreferencesService} from '../../../../../administration/services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {JobSatisfactionsListService} from '../../../services/job-satisfactions-list.service';
import {JobSatisfactionsSearchFiltersService} from '../../../services/job-satisfactions-search-filters.service';
import {ComponentPrimitive} from '../../../../../shared/classes/components/componentPrimitive';

@Component({
  selector: 'app-job-satisfactions-list-search-filters',
  templateUrl: './job-satisfactions-list-search-filters.component.html',
  styleUrls: ['./job-satisfactions-list-search-filters.component.scss']
})
export class JobSatisfactionsListSearchFiltersComponent extends ComponentPrimitive  implements OnInit {

    @Input() whereAmI: string;
    @Input() width: string;
    @Output() selectPreference = new EventEmitter;

    preferenceUrl: string;
    userDataId: string;

    date: any;

    maxNumberOfColumns = 5;
    columnsToSelect: number;

    private subscriptions = new Subscription();

    constructor(
        public jobSatisfactionListService: JobSatisfactionsListService,
        public jobSatisfactionSearchFiltersService: JobSatisfactionsSearchFiltersService,
        public stateService: StateService,
        private userPreferencesService: UserPreferencesService,
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translate,
            router,
            deviceService
        );
    }

    ngOnInit() {
        this.jobSatisfactionSearchFiltersService.initDateFormat(this.dateFormat());
        this.jobSatisfactionSearchFiltersService.setSubAccountsOptions();
        this.jobSatisfactionSearchFiltersService.setDepartmentsOptions();

        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.jobSatisfactionSearchFiltersService.initDateFormat(this.dateFormat());
            this.checkPeriodDates();
        }));

        this.jobSatisfactionListService.checkModuleUrl();
        this.jobSatisfactionSearchFiltersService.checkPreferences(this.whereAmI);

        if (this.whereAmI === 'filter') {
            this.jobSatisfactionSearchFiltersService.initInputState();
        }

        if (this.whereAmI === 'preferences') {

            let accountDataId = (this.jobSatisfactionListService.accountData) ? this.jobSatisfactionListService.accountData.id : '';
            this.userDataId = (this.jobSatisfactionListService.userData) ? this.jobSatisfactionListService.userData.id : '';
            this.preferenceUrl = 'admin/account/' + accountDataId + '/user/' + this.userDataId + '/preferences';

            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.jobSatisfactionListService.userPreferences, this.preferenceUrl);

            // Init state with session vars
            this.jobSatisfactionSearchFiltersService.initInputStatePreferences(this);
            this.changeDetectorRef.markForCheck();
        }

        this.jobSatisfactionSearchFiltersService.initSubAccountFilter();

        this.checkPeriodDates();

        this.jobSatisfactionSearchFiltersService.initColumnsFilter();
    }

    setFilters(event, filter) {
        switch (filter) {
            case 'jobSatisfactionStartDate':
                this.jobSatisfactionSearchFiltersService.setSearchFilter(event, 'jobSatisfactionStartDate');
                break;
            case 'jobSatisfactionEndDate':
                this.jobSatisfactionSearchFiltersService.setSearchFilter(event, 'jobSatisfactionEndDate');
                break;
            case 'subAccount':
                let subAccounts = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(subAccounts, 'subAccountCol', this.translate.instant('commons.subAccount'));
                this.jobSatisfactionSearchFiltersService.subAccount(subAccounts, this, this.jobSatisfactionListService.specificState);
                break;
            case 'department':
                let deps = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(deps, 'depCol', this.translate.instant('commons.division'));
                this.jobSatisfactionSearchFiltersService.onDepartmentOptionChange(deps, this, this.jobSatisfactionListService.specificState);
                break;
            case 'columnDisplay':
                this.jobSatisfactionSearchFiltersService.columnDisplay(event, this, this.jobSatisfactionListService.specificState);
                break;
        }
        this.jobSatisfactionListService.getJobSatisfactionList(true, null, this);
    }

    checkPeriodDates() {
        if (this.jobSatisfactionListService.evaluationStartDate) {
            let dateStartObject = this.jobSatisfactionListService.transformDate(this.jobSatisfactionListService.jobSatisfactionStartDate);
            this.jobSatisfactionSearchFiltersService.dateStartModel = dateStartObject;
            this.jobSatisfactionSearchFiltersService.dateStart = dateStartObject;
        }

        if (this.jobSatisfactionListService.evaluationEndDate) {
            let dateEndObject = this.jobSatisfactionListService.transformDate(this.jobSatisfactionListService.jobSatisfactionEndDate);
            this.jobSatisfactionSearchFiltersService.dateEndModel = dateEndObject;
            this.jobSatisfactionSearchFiltersService.dateEnd = dateEndObject;
        }
    }

    resetSearchFiltersAction(preferences = null) {
        this.jobSatisfactionSearchFiltersService.resetSearchFiltersAction();

        if (this.whereAmI === 'filter') {
            this.jobSatisfactionSearchFiltersService.resetSearchFilters();
        }
        for (let report in this.stateService[this.jobSatisfactionListService.specificState].listSearchFilters) {
            if (report && this.stateService[this.jobSatisfactionListService.specificState].listSearchFilters.hasOwnProperty(report)) {
                this.stateService[this.jobSatisfactionListService.specificState].listColumns = this.stateService[this.jobSatisfactionListService.specificState].listColumnsInitialConfig;
                if (report !== 'search') {
                    this.stateService[this.jobSatisfactionListService.specificState].listSearchFilters[report] = null;
                }
            }
        }

        if (preferences === null) {
            this.jobSatisfactionListService.setInitialColumnsList();
            this.jobSatisfactionListService.listColumns = JSON.parse(JSON.stringify(this.jobSatisfactionListService.listColumnsInitialConfig));
        } else {
            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.jobSatisfactionListService.userPreferences, this.preferenceUrl, 'jobSatisfaction');
            this.jobSatisfactionSearchFiltersService.initInputStatePreferences(this);
        }

        this.selectPreference.emit({
            type: 'selectVanilla'
        });
    }

    resetSearchFilters() {
        if (this.whereAmI === 'filter' && this.actualModule.name !== 'jobSatisfaction') {
            const preferences = (this.userPreferencesService.isPreferenceSet('jobSatisfaction')) ?
                this.stateService.session.preferences['jobSatisfaction'] :
                null;
            this.resetSearchFiltersAction(preferences);
        } else {
            this.resetSearchFiltersAction();
            this.userPreferencesService.emptyUserPreferences('jobSatisfaction', this.userDataId);
        }
        this.jobSatisfactionListService.getJobSatisfactionList(true, null, this);
    }

    countColumnsToSelect() {
        let colSelected = 0;
        for (let col in this.jobSatisfactionListService.jobSatisfactionState.listColumns) {
            if (this.jobSatisfactionListService.jobSatisfactionState.listColumns.hasOwnProperty(col)) {
                if (this.jobSatisfactionListService.jobSatisfactionState.listColumns[col] && this.jobSatisfactionListService.jobSatisfactionState.listColumns[col].display) {
                    colSelected++;
                }
            }
        }
        this.columnsToSelect = this.maxNumberOfColumns - colSelected;
        return this.columnsToSelect;
    }

}
