<div class="sticky" *ngIf="!isLandscape || showPortraitButtons">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <h2 class="name">
                {{ rightMatch.itemToDisplay ? rightMatch.itemToDisplay.name.toUpperCase() : '' }}
            </h2>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                [disabled]="!rightMatch.records || !rightMatch.records.length"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="itemEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}
            </button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr"/>
</div>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 class="name">
        {{ rightMatch.itemToDisplay ? rightMatch.itemToDisplay.name.toUpperCase() : '' }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="editRequest" class="fullView-wrapper-scrollfix" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-right-matches-edit
        [status]="'edit'"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-right-matches-edit>
</div>
<div *ngIf="pdfRequest" class="fullView-wrapper-scrollfix" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-right-matches-pdf *ngIf="rightMatch.itemToDisplay"
                                  (close)="closeAllRequestsPanels()"
    ></app-mobile-right-matches-pdf>
</div>
<div *ngIf="!pdfRequest && !editRequest"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <div class="informationPanel">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable">
                <tr>
                    <td> {{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!rightMatch.itemToDisplay.name">
                            {{ rightMatch.itemToDisplay.name || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!rightMatch.itemToDisplay['consultant']">
                            {{ rightMatch.itemToDisplay['consultant'] || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!setSubAccountName(rightMatch.itemToDisplay['subAccount'])">
                            {{ setSubAccountName(rightMatch.itemToDisplay['subAccount'])
                        || notSpecified()  }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'rms.commons.jobProfile' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!rightMatch.profilerInfo || !rightMatch.profilerInfo['jobTitle']">&nbsp;
                            <a *ngIf="rightMatch.profilerInfo && rightMatch.profilerId"
                               (click)="goToJob(rightMatch.profilerId, rightMatch.id, 'rightMatch')"
                            >{{ rightMatch.profilerInfo['jobTitle'] || notSpecified() }}</a>
                            <span *ngIf="!rightMatch.profilerInfo || !rightMatch.profilerInfo['jobTitle']"
                                  [class.attention]="rightMatch.profilerInfo && !rightMatch.profilerId"
                            >{{ notSpecified(true) }}</span>
                        </span>
                    </td>
                </tr>
                <tr *ngIf="rightMatch.profilerInfo">
                    <td class="personTd">
                        <span *ngIf="this.rightMatchService.archive">
                            {{ 'rms.sideBar.numberOfPerson' | translate }}<span [innerHTML]="titleSep"></span>
                            {{ (rightMatch.records != null) ? rightMatch.records.length : 0 }}
                        </span>
                        <mat-expansion-panel
                            *ngIf="!this.rightMatchService.archive"
                            [expanded]="infoPanelOpenState"
                            (opened)="openInfoPanel()"
                        >
                            <mat-expansion-panel-header [collapsedHeight]="'25px'" [expandedHeight]="'25px'">
                                <mat-panel-title>
                                    {{ 'rms.sideBar.numberOfPerson' | translate }}<span
                                    [innerHTML]="titleSep"></span> &nbsp;
                                    <span
                                        class="fakeLink">{{ (rightMatch.records != null) ? rightMatch.records.length : 0 }}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <app-mobile-right-matches-person-adding
                                *ngIf="infoPanelOpenState"
                                (closePanel)="closeInfoPanel()"
                            ></app-mobile-right-matches-person-adding>
                        </mat-expansion-panel>
                    </td>
                </tr>
                <tr *ngIf="rightMatch.profilerInfo">
                    <td>{{ 'rms.sideBar.surveyType' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!rightMatch.profilerInfo.type">
                            {{renderType(rightMatch.profilerInfo.type) | translate }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.division' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!rightMatch.department || !rightMatch.department.length">
                            {{ (rightMatch.department && rightMatch.department.length) ? rightMatch.department : notSpecified() }}
                        </span>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
    </div>
    <div *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['scores']"
         class="graph-container MPOSideBarPresentation">
        <h3 class="ngorange">{{ 'rms.sideBar.targetProfile' | translate }}</h3>
        <app-mpo-graph
            [id]="'traits'"
            [width]="graphWidth+'px'"
            [mobile]="true"
            [lang]="translate.currentLang"
            [scores]="rightMatch.profilerInfo['scores']"
        ></app-mpo-graph>
    </div>
    <div *ngIf="!(rightMatch.profilerInfo && rightMatch.profilerInfo['scores'])"
         class="graph-container MPOSideBarPresentation">
        <h3 class="ngorange">{{ 'rms.sideBar.targetPersonnalityProfile' | translate }}</h3>
        <app-mpo-graph
            *ngIf="!(rightMatch.profilerInfo && rightMatch.profilerInfo['scores'])"
            [id]="'traits'"
            [width]="graphWidth+'px'"
            [mobile]="true"
            [lang]="translate.currentLang"
            [scores]="undefined"
        ></app-mpo-graph>
    </div>
    <div>
        <h3 class="ngorange subTitleRmSb">{{ 'jobs.commons.competencies' | translate }}</h3>
        <ng-container
            *ngIf="rightMatch.orderedTalents && rightMatch.profilerInfo && rightMatch.profilerInfo['competences']; else noCompetencies">
            <table class="talentsDisplay" style="width: 100%">
                <ng-container *ngFor="let i of createRange(actualPage, maxPerPage)">
                    <ng-container
                        *ngIf="rightMatch.orderedTalents[translateService.currentLang] && rightMatch.orderedTalents[translateService.currentLang][i]">
                        <tr style="height: 20px">
                            <td>{{rightMatch.orderedTalents[translateService.currentLang][i][0]}}</td>
                            <td>
                                                <span
                                                    *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1]==1"
                                                    style="color: #efce7c">⬤</span>
                                <span *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1]==2"
                                      style="color: #78c7af">⬤</span>

                            </td>
                        </tr>
                    </ng-container>
                    <ng-container
                        *ngIf="rightMatch.orderedTalents[translateService.currentLang] && !rightMatch.orderedTalents[translateService.currentLang][i]">
                        <tr style="height: 20px">
                            <td>&nbsp;</td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr>
                    <td colspan="3" style="padding-bottom: 8px">
                        <div style="padding-bottom: 8px">
                            <hr class="panelHr"/>
                        </div>
                        <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)">
                            <img
                                src="/assets/buttons/next.png" alt=">"></div>
                        <div *ngIf="actualPage == maxPage" class="pull-right"><img
                            src="/assets/buttons/next_deactivated.png" alt=">"></div>
                        <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img
                            src="/assets/buttons/before.png" alt="<"></div>
                        <div *ngIf="actualPage == 1" class="pull-right"><img
                            src="/assets/buttons/before_deactivated.png" alt="<"></div>
                        <div class="clearfix"></div>
                    </td>
                </tr>
            </table>
        </ng-container>
        <ng-template #noCompetencies>
            <div style="height: 300px">
                <table class="fakeListTable">
                    <tr *ngFor="let line of [100, 110, 120, 130, 90, 100, 120, 140, 110, 120]">
                        <td>
                            <div class="fakeItem" [style.width]="line+'px'">&nbsp;</div>
                        </td>
                        <td>
                            <span class="fakeIndicator">⬤</span>
                        </td>
                    </tr>
                </table>
            </div>
            <table class="fakeListTable">
                <tr>
                    <td>
                        <div class="pull-left" style="width: 50%">
                            <span style="color: #78c7af">⬤</span>
                            {{ 'jobs.modal.competenceMasteryOption' | translate }}
                        </div>
                        <div class="pull-left" style="width: 50%">
                            <span style="color: #efce7c">⬤</span>
                            {{ 'jobs.modal.competenceFuncOption' | translate }}
                        </div>
                        <div class="clearfix"></div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3" style="padding-bottom: 8px">
                        <div style="padding-bottom: 8px">
                            <hr class="panelHr"/>
                        </div>
                        <div class="pull-right"><img src="/assets/buttons/next_deactivated.png"
                                                     alt=">"></div>
                        <div class="pull-right"><img src="/assets/buttons/before_deactivated.png"
                                                     alt="<"></div>
                        <div class="clearfix"></div>
                    </td>
                </tr>
            </table>
        </ng-template>
    </div>
    <div>
        <h3 class="ngorange subTitleRmCai" style="margin-bottom: 40px;">{{ 'commons.cai' | translate }}</h3>
        <ng-container *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['RA']; else noCai">
            <div style="width: 100%; border: 4px solid rgb(206, 206, 206)">
                <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt=""/>
                <app-iac-graph
                    [id]="'jobIac'"
                    [normalizedScore]="null"
                    [jobScore]="7"
                    [mobile]="false"
                ></app-iac-graph>
            </div>
        </ng-container>
        <ng-template #noCai>
            <div style="width: 100%; border: 4px solid rgb(206, 206, 206)">
                <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt=""/>
                <app-iac-graph
                    [id]="'jobIac_sidebar'"
                    [normalizedScore]="null"
                    [jobScore]="null"
                    [mobile]="false"
                ></app-iac-graph>
            </div>
        </ng-template>
    </div>
    <hr class="panelHr"/>
    <mat-tab-group class="analysisTabs rightMatchesTabs"
                   [(selectedIndex)]="rightMatchState['selectedTab']"
                   (selectedIndexChange)="switchEvaluationType($event)">
        <mat-tab label="{{ 'commons.MPOAnalysis' | translate }}">
            <h2 class="ngorange">
                {{ 'rms.report.concordanceAnalysis' | translate }}
            </h2>
            <mat-expansion-panel *ngIf="rightMatch.profilerInfo"
                                 [expanded]="addingPanelOpenState"
                                 (opened)="openPanel()"
            >
                <mat-expansion-panel-header [collapsedHeight]="'25px'" [expandedHeight]="'25px'">
                    <mat-panel-title>
                        <span class="fakeLink">{{ 'rms.report.addPerson' | translate }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <app-mobile-right-matches-person-adding
                    *ngIf="addingPanelOpenState"
                    (closePanel)="closePanel()"
                ></app-mobile-right-matches-person-adding>
            </mat-expansion-panel>
            <ng-container
                *ngFor="let record of this.rightMatch.records; let i=index"
            >
                <app-right-matches-person-card
                    [record]="record"
                    [job]="rightMatch.itemToDisplay.profiler"
                    [minimizeAll]="false"
                    [listView]="false"
                ></app-right-matches-person-card>
                <div class="clearfix"></div>
                <div class="cardSpacer"></div>
            </ng-container>
        </mat-tab>
        <mat-tab label="{{ 'jobs.commons.MPOTargetProfile' | translate }}">
            <app-right-matches-report-job-description
                *ngIf="rightMatch.itemToDisplay && rightMatchState.report['reportType'] == 'MPOTargetProfile'"
            ></app-right-matches-report-job-description>
        </mat-tab>
        <mat-tab label="{{ 'jobs.commons.competenciesTarget' | translate }}">
            <app-right-matches-report-job-competencies
                *ngIf="rightMatch.itemToDisplay && rightMatchState.report['reportType'] == 'competenciesTarget'"
            ></app-right-matches-report-job-competencies>
        </mat-tab>
        <mat-tab label="{{ 'jobs.commons.jobDesign' | translate }}">
            <div *ngIf="rightMatch.itemToDisplay && rightMatchState.report['reportType'] == 'jobDescription'">
                <h2
                >{{ 'jobs.commons.jobDesign' | translate }}</h2>
                <hr/>
                <div *ngIf="rightMatch.profilerInfo">
                    <span *ngIf="rightMatch.profilerInfo['jobTitle']">{{ 'jobs.commons.jobName' | translate }}<span
                        [innerHTML]="titleSep"></span> {{ rightMatch.profilerInfo.jobTitle }}
                        <br></span>
                    <span *ngIf="rightMatch.profilerInfo.location">{{ 'commons.location' | translate }}<span
                        [innerHTML]="titleSep"></span> {{ rightMatch.profilerInfo.location }}
                        <br></span>
                    <span *ngIf="rightMatch.profilerInfo['requiredBy']">{{ 'commons.requestedBy' | translate }}<span
                        [innerHTML]="titleSep"></span> {{ rightMatch.profilerInfo.requiredBy }}
                        <br></span>
                    <span *ngIf="rightMatch.profilerInfo['reportsTo']">{{ 'jobs.report.reportsTo' | translate }}
                        <span [innerHTML]="titleSep"></span> {{ rightMatch.profilerInfo.reportsTo }}
                        <br></span>
                    <hr/>
                </div>

                <div *ngIf="(rightMatch.jobDescriptionTasks && rightMatch.jobDescriptionTasks.length > 0)  ||
                            (rightMatch.jobDescription && rightMatch.jobDescription['purposeOfThePosition']); else noProfiler">
                    <div *ngIf="rightMatch.jobDescription['purposeOfThePosition']">
                        <h4>{{ 'jobs.commons.purposeOfThePosition' | translate }}</h4>
                        <p>{{ rightMatch.jobDescription['purposeOfThePosition'] }}</p>
                    </div>
                    <table *ngIf="rightMatch.jobDescriptionTasks" id="princResp">
                        <thead>
                        <tr>
                            <th class="bd"
                                colspan="2">{{ 'jobs.report.principalResponsibilities' | translate }}</th>
                            <th class="bd">% du temps</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let resp of rightMatch.jobDescriptionTasks">
                            <td class="bd" style="font-weight: bold; width: 2%">{{ resp.priority }}</td>
                            <td style="padding-right: 15px; width: 88%">{{ resp['responsibility'] }}</td>
                            <td style="text-align: center; width: 10%">{{ resp['percentage'] }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <ng-template #noProfiler>
                    <a *ngIf="rightMatch.profilerId"
                       (click)="goToJob(rightMatch.profilerId, rightMatch.id, 'rightMatch', 'mod')"
                    >{{ 'jobs.report.modifyJobDescription' | translate }}</a>
                </ng-template>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
