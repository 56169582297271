import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {StateService} from '../../../../../core/services/state/state.service';
import {ComponentPrimitive} from '../../../../../shared/classes/components/componentPrimitive';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PersonService} from '../../../../../people/services/person.service';
import {UserService} from '../../../../../core/services/user/user.service';
import {RightMatchAnalysisService} from '../../../services/right-match-analysis.service';

@Component({
    selector: 'app-right-matches-person-card',
    templateUrl: './right-matches-person-card.component.html',
    styleUrls: ['./right-matches-person-card.component.scss']
})

export class RightMatchesPersonCardComponent extends ComponentPrimitive implements OnChanges {

    @Input() record: any;
    @Input() job: any;
    @Input() orderedTalents: any;
    @Input() minimizeAll;
    @Input() listView = true;
    @Input() pos;

    // toggles
    informationVisibility = false;

    constructor(
        protected stateService: StateService,
        public translateService: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        public cd: ChangeDetectorRef,
        public user: UserService,
        private person: PersonService,
        public rightMatch: RightMatchAnalysisService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['minimizeAll']) {
            this.informationVisibility = !changes['minimizeAll'].currentValue;
        }
        this.cd.markForCheck();
    }

    toggleInformationVisibility() {
        this.informationVisibility = !this.informationVisibility;
    }

    goToPerson(id: string) {
        this.stateService.people.reportBack = {
            to: this.router.url,
            personToDisplayId: id,
            module: 'people'
        };
        this.person.setPersonToDisplayWithId(id, 'navigate');
    }

    get stateRightMatch() {
        return this.stateService.rightMatch;
    }

    get hasThreeReports() {
        return (
            this.user.hasIacAccess && this.state.displayPreferences.display.cai &&
            this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
            this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality
        );
    }

    get hasTwoReports() {
        return (
            !this.hasThreeReports &&
            (
                (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents
                ) || (
                    this.user.hasPersonalityAccess && this.state.displayPreferences.display.personality &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                ) ||
                (
                    this.user.hasTalentsAccess && this.state.displayPreferences.display.talents &&
                    this.user.hasIacAccess && this.state.displayPreferences.display.cai
                )
            )
        );
    }

    get hasOneReport() {
        // console.log(this.hasThreeReports, this.hasTwoReports);
        return !(this.hasThreeReports || this.hasTwoReports);
    }

    get state() {
        return this.stateService.rightMatch;
    }
}
