<a id="top"></a>
<div
    *ngIf="questionData"
    class="popUpInput"
    [class.displayBlock]="showQuestionDataPopup"
    [class.displayNone]="!showQuestionDataPopup"
>
    <div>
        <div class="modal-header">
            <h4 class="modal-title pull-left fontLight">
                <span
                    *ngIf="questionData['requestType']">
                        {{ 'commons.report' | translate }}
                        {{
                            reportType[questionData['requestType']] ?
                            reportType[questionData['requestType']][translateService.currentLang] :
                            ''
                        }}</span>
                <span
                    *ngIf="questionData['newType']">
                        {{ 'commons.report' | translate }}
                        {{reportType[questionData['newType']] ?
                            reportType[questionData['newType']][translateService.currentLang] :
                            ''
                        }}
                </span>
                <span *ngIf="!questionData['newType'] && !questionData['requestType'] && dataContentPopup.title">
                    {{ dataContentPopup.title | translate }}
                </span>
            </h4>
            <div *ngIf="false" (click)="hideQuestionDataPopup()" aria-label="Close" class="x-close pull-right"
                 style="font-size: 20pt;">
                &#x00D7;
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-body">
            <div>
                <div
                    (click)="(dataContentPopup.click) ? showPopupModal(dataContentPopup.type) : null"
                    [style.textDecoration]="(dataContentPopup.click) ? 'underline' : 'none'"
                    style="cursor: pointer"
                >
                    {{ dataContentPopup.text | translate }}
                </div>

            </div>
            <div *ngIf="questionData['requestType']">
                <ng-container
                    *ngIf="person.checkRequestsContains('typeContains', questionData['requestType'])">
                    <div *ngFor="let request of person.evaluationRequestsContainingOnly(questionData['requestType'])">
                        <a (click)="confirmRequestDelete(request.requestId, reportType[questionData['requestType']][translateService.currentLang])">
                            {{ 'commons.deleteQuestionnaire' | translate }}
                        </a>
                    </div>
                    <br/>
                    <div
                        *ngFor="let request of person.evaluationRequestsContainingOnly(questionData['requestType']); let i = index">
                        <a (click)="testAndReportHandling.getRequestSendData(resendType, i, request['createTempUser'])">
                            {{ 'commons.resendRequest' | translate }}
                            <span
                                *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                        </a>
                    </div>
                    <div class="clearfix"></div>
                </ng-container>
            </div>
            <div *ngIf="questionData['newType']">
                <a *ngIf="questionData['newType'] !== 'iac'"
                   (click)="testAndReportHandling.getRequestSendData('sendMpo')">
                    {{ 'commons.sendMpo' | translate }}
                </a>
                <a *ngIf="questionData['newType'] === 'iac'"
                   (click)="testAndReportHandling.getRequestSendData('sendIac')">
                    {{ 'commons.sendIac' | translate }}
                </a>
            </div>
        </div>
    </div>
</div>
<div class="rightWrapper row" (window:resize)="onResize($event)">
    <ng-container *ngIf="!small">
        <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4"
             [style.width]="(!small) ? sideWidth : null"
             [class.affix]="!small"
        >
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="person">
                    <app-people-sidebar
                            [isLeftAndNotTop]="true"
                            [width]="sideWidth"
                            [config]="'report'"
                    ></app-people-sidebar>
                    <!-- (reportActivation)="openReportActivationModal($event)"
                            (displayFakeReportEvent)="displayFakeReport()"
                            (questionPopupEvent)="openQuestionPopup($event)"
                            (hideFakeReportEvent)="hideFakeReport()"-->
                </div>
            </div>
        </div>
    </ng-container>
    <div class="outerColumnWhite"
         [class.col-md-8]="!small"
         [style.margin-left.px]="(!small) ? '400' : null"
         [class.col-md-12]="small"
         [style.padding-top]="(small) ? null : '40px'"
         [style.width]="(!small) ? reportWidth : null"
    >
        <div>
            <div id="MPOReport">
                <ul *ngIf="!stateService.session.hasSatelliteRole && !small" class="nav nav-tabs"
                    style="border-bottom: 1px solid #ddd;">
                    <li [class.active]="statePeople.report.type === 'personality' && !statePeople.report['fakeReport']">
                        <a class="tdnone"
                           (click)="switchEvaluationType('personality')">{{ 'commons.personality' | translate }}</a>
                    </li>
                    <li [class.active]="statePeople.report.type === 'satellite' && !statePeople.report['fakeReport']">
                        <a class="tdnone"
                           (click)="switchEvaluationType('satellite')">{{ 'commons.satellite' | translate }}</a>
                    </li>
                    <li [class.active]="statePeople.report.type === 'communication' && !statePeople.report['fakeReport']">
                        <a class="tdnone"
                           (click)="switchEvaluationType('communication')">{{ 'commons.communication' | translate }}</a>
                    </li>
                    <li [class.active]="statePeople.report.type === 'talents' && !statePeople.report['fakeReport']">
                        <a class="tdnone"
                           (click)="switchEvaluationType('talents')">{{ 'commons.talents' | translate }}</a>
                    </li>
                    <li *ngIf="raPermission"
                        [class.active]="statePeople.report.type === 'iac' && !statePeople.report['fakeReport']">
                        <a class="tdnone"
                           (click)="switchEvaluationType('iac')">{{ 'commons.iac_short' | translate }}</a>
                    </li>
                </ul>
                <br/>
                <div *ngIf="small && person">
                    <app-people-sidebar
                        [isLeftAndNotTop]="true"
                        [width]="sideWidth"
                        [config]="'report'"
                    ></app-people-sidebar>
                </div>
                <app-fake-report *ngIf="showFakeReport; else noFakeReport"
                                 [for]="statePeople.report.type"
                ></app-fake-report>
                <ng-template #noFakeReport>
                    <div *ngIf="statePeople.report.type === 'personality'">
                        <app-people-report-personality></app-people-report-personality>
                    </div>
                    <div *ngIf="statePeople.report.type === 'communication'">
                        <app-people-report-communication
                            [originReportType]="statePeople.report['originReportType']"
                        ></app-people-report-communication>
                    </div>
                    <div *ngIf="statePeople.report.type === 'satellite'">
                        <app-people-report-satellite></app-people-report-satellite>
                    </div>
                    <div *ngIf="statePeople.report.type === 'talents'">
                        <app-people-report-talents
                            [small]="small"
                        ></app-people-report-talents>
                    </div>
                    <div *ngIf="statePeople.report.type === 'iac'">
                        <app-people-report-iac
                        ></app-people-report-iac>
                    </div>
                </ng-template>
            </div>
            <!--app-individus-report-dit *ngIf="(currentEvaluation$ | async) === 'dit'" [individu]="individu"></app-individus-report-dit-->
        </div>
        <div style="clear:both"></div>
        <div *ngIf="small" style="height: 100px">&nbsp;</div>
    </div>
</div>
