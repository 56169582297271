<div *ngIf="jobService.id">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'commons.resendAccessCode' | translate }}</h4>
        <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
             style="font-size: 20pt;">
            &#x00D7;
        </div>
    </div>
    <ng-container *ngIf="toggle == 1" class="reqContent" [@fadeInOut]>
        <div class="modal-body">
            <p *ngIf="introductionText">{{introductionText}}</p>
            <h3>{{ 'commons.sendingModeSelection' | translate }}</h3>
            <label>
                <select [(ngModel)]="flipValue" (change)="toggleFlip(flipValue)" class="form-control"
                        style="width: 350px">
                    <option value="1">{{ 'commons.select' | translate }}</option>
                    <option *ngIf="language !== 'ar'" value="2">{{ 'commons.sendByMyEmail' | translate }}</option>
                    <option value="3">{{ 'commons.sendByServer' | translate }}</option>
                    <option value="4">{{ 'commons.cutnpaste' | translate }}</option>
                </select>
            </label>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button
                    type="button"
                    class="modalButton pull-right"
                    (click)="closeModal()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="toggle == 2" [@fadeInOut]>
        <div class="modal-body">
            <!-- sendByMyEmail functionality -->
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button
                    type="button"
                    class="modalButton pull-right"
                    (click)="closeModal()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="toggle == 3" [@fadeInOut]>
        <div class="modal-body">
            <!-- sendByServer functionality -->
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button
                    type="button"
                    class="modalButton pull-right"
                    (click)="closeModal()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="toggle == 4" [@fadeInOut]>
        <div class="modal-body">
            <!-- cut&paste functionality -->
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button
                    type="button"
                    class="modalButton pull-right"
                    (click)="closeModal()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
</div>
