<div *ngIf="idwMatrix && stateService.idwMatrix" (window:resize)="onResize($event)">
    <ngx-loading [show]="idwMatrix.loading" [config]="loaderDisplay" [class]="'idwMatrixLoading'"></ngx-loading>
    <div
        [class.top]="isLeftAndNotTop"
        [class.affix]="isLeftAndNotTop"
        [class.tLeft]="!(config == 'list') && isLeftAndNotTop"
        [class.tRight]="config == 'list' && isLeftAndNotTop"
        [style.width]="width"
    >
        <div [class.navbar-fixed-top]="!isLeftAndNotTop" [class.topMenu]="!isLeftAndNotTop">
            <div [class.topHead]="isLeftAndNotTop">
                <div class="pull-left">
                    <h2 class="name">{{ idwMatrix.name.toUpperCase() }}</h2>
                </div>
                <div *ngIf="config == 'list'" (click)="goToNews()" class="x-close pull-right" style="font-size: 20pt">&#x00D7;</div>
                <div class="clearfix"></div>
            </div>
            <div *ngIf="idwMatricesList.archive">
                <span (click)="modStatus('archive', false)" class="fakeLink">{{ 'commons.reactivate' | translate }}</span
                >&nbsp;&nbsp;
                <span *ngIf="canDelete" (click)="modStatus('delete')" class="fakeLink">{{ 'commons.delete' | translate }}</span>
            </div>

            <div *ngIf="config == 'list'; else reportFullButtons">
                <div *ngIf="!idwMatricesList.archive">
                    <app-nav-button (buttonActon)="goToFullReport(idwMatrix.id)">
                        <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                        <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                    </app-nav-button>
                    <app-nav-button [isBlank]="!idwMatrix.records || !idwMatrix.records.length" (buttonActon)="onPdfShare()">
                        <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                        <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                    </app-nav-button>
                    <div class="pull-left dropdown" dropdown>
                        <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">
                            {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                            <li>
                                <a (click)="openEditModal()">
                                    {{ 'commons.editInfo' | translate }}
                                </a>
                            </li>
                            <li role="menuitem">
                                <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                            </li>
                            <li *ngIf="canDelete" role="menuitem">
                                <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <ng-template #reportFullButtons>
                <app-nav-button (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <app-nav-button [isBlank]="!idwMatrix.records || !idwMatrix.records.length" (buttonActon)="onPdfShare()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                    <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                </app-nav-button>
                <div class="pull-left dropdown" dropdown>
                    <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">
                        {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                    </div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                        <li *ngIf="!idwMatricesList.archive">
                            <a (click)="openEditModal()">
                                {{ 'commons.editInfo' | translate }}
                            </a>
                        </li>
                        <li *ngIf="!idwMatricesList.archive" role="menuitem">
                            <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                        </li>
                        <li *ngIf="canDelete" role="menuitem">
                            <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </ng-template>
            <div *ngIf="isLeftAndNotTop">
                <hr class="panelHr" />
            </div>
        </div>
        <div *ngIf="isLeftAndNotTop" style="height: 5px"></div>
        <div *ngIf="!isLeftAndNotTop" style="height: 90px"></div>
        <div>
            <div class="clearfix"></div>

            <div class="infoWrap">
                <table class="infoTableWrap">
                    <tr>
                        <td class="switchButton">
                            <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                <span *ngIf="!informationVisibility">+</span>
                                <span *ngIf="informationVisibility">-</span>
                            </button>
                        </td>
                        <td>
                            <mat-tab-group [(selectedIndex)]="selectedInfoQuestionTab" (selectedIndexChange)="switchTab($event)">
                                <mat-tab label="{{ 'commons.informations' | translate }}">
                                    <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                        <tr>
                                            <td>
                                                {{ 'idwMatrices.sideBar.activityName' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!idwMatrix.name">
                                                    {{ idwMatrix.name || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.creationDate' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!idwMatrix.creationDate">
                                                    {{ idwMatrix.creationDate | date : dateFormat || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.modificationDate' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!idwMatrix.date">
                                                    {{ idwMatrix.date | date : dateFormat || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!idwMatrix.consultant">
                                                    {{ idwMatrix.consultant || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!setSubAccountName(idwMatrix.subAccount)">
                                                    {{ setSubAccountName(idwMatrix.subAccount) || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!idwMatrix.department">
                                                    {{ idwMatrix.department || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <hr class="panelHr" />
            </div>
        </div>

        <div>
            <div class="pull-left" style="margin-top: 15px">
                <h2>{{ 'commons.individus' | translate }}</h2>
            </div>
            <div *ngIf="!idwMatricesList.archive" (click)="openPersonModal()" class="modify pull-right" style="margin-top: 15px"></div>
            <div class="clearfix"></div>
            <div *ngIf="!idwMatrix.records || !idwMatrix.records.length">{{ 'commons.none' | translate }}</div>
            <div *ngIf="idwMatrix.records">
                <table style="width: 100%; table-layout: fixed">
                    <ng-container *ngFor="let i of createRange(actualPage, maxPerPage, true)">
                        <tr>
                            <td style="width: 45%">
                                <div *ngIf="recordsUnique[i * 2]" class="truncate">
                                    <span *ngIf="!idwMatricesList.archive">
                                        <a (click)="goToPerson(recordsUnique[i * 2].id)"
                                            >{{ recordsUnique[i * 2]['firstName'] }} {{ recordsUnique[i * 2]['lastName'] }}</a
                                        >&nbsp;&nbsp;
                                    </span>
                                    <span *ngIf="idwMatricesList.archive">
                                        {{ recordsUnique[i * 2]['firstName'] }} {{ recordsUnique[i * 2]['lastName'] }}
                                    </span>
                                </div>
                                <div *ngIf="!recordsUnique[i * 2]">&nbsp;</div>
                            </td>
                            <td style="padding-right: 12px; width: 5%">
                                <span
                                    *ngIf="recordsUnique[i * 2] && config != 'list'"
                                    (click)="removeItem(recordsUnique[i * 2].id)"
                                    style="font-weight: bold; cursor: pointer"
                                >
                                    x
                                </span>
                            </td>
                            <td style="width: 45%">
                                <div *ngIf="recordsUnique[i * 2 + 1]" class="truncate">
                                    <span *ngIf="!idwMatricesList.archive">
                                        <a (click)="goToPerson(recordsUnique[i * 2 + 1].id)"
                                            >{{ recordsUnique[i * 2 + 1]['firstName'] }} {{ recordsUnique[i * 2 + 1]['lastName'] }}</a
                                        >&nbsp;&nbsp;
                                    </span>
                                    <span *ngIf="idwMatricesList.archive">
                                        {{ recordsUnique[i * 2 + 1]['firstName'] }} {{ recordsUnique[i * 2 + 1]['lastName'] }}
                                    </span>
                                </div>
                            </td>
                            <td style="width: 5%">
                                <span
                                    *ngIf="recordsUnique[i * 2 + 1] && config != 'list'"
                                    (click)="removeItem(recordsUnique[i * 2 + 1].id)"
                                    style="font-weight: bold; cursor: pointer"
                                >
                                    x
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td colspan="4" style="padding-bottom: 8px">
                            <div style="padding-bottom: 8px">
                                <hr class="panelHr" />
                            </div>
                            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)"><img src="/assets/buttons/next.png" alt=">" /></div>
                            <div *ngIf="actualPage >= maxPage" class="pull-right"><img src="/assets/buttons/next_deactivated.png" alt=">" /></div>
                            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img src="/assets/buttons/before.png" alt="<" /></div>
                            <div *ngIf="actualPage == 1" class="pull-right"><img src="/assets/buttons/before_deactivated.png" alt="<" /></div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
