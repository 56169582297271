<div
    class="card"
    [class.leftCard]="listView"
    [class.cardGridView]="!listView"
>
    <div class="cardHeader">
        <div class="floatLeft">
            <div class="cardHeaderMainTitle">
                {{ 'commons.personality' | translate }}
            </div>
            <div class="cardHeaderSubTitle">
                {{ testAndReportHandlingService.renderPortrait(record.type) | translate }}
            </div>
        </div>
        <div class="floatRight">
            <div class="floatLeft">
                <div style="width: 42px; padding: 6px">
                    <app-indicator-graph
                        [id]="record.id + '_personality' + pos"
                        [score]="(record && record['rightMatchScore']) ? record['rightMatchScore'] : null"
                        [over]="5"
                        [color]="traitColor"
                    ></app-indicator-graph>
                </div>
            </div>
            <div *ngIf="!listView" class="floatLeft" style="margin-top: 3px;">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <ng-container *ngIf="informationVisibility">

        <div
            *ngIf="!record['scores'] || !job['scores']"
            class="notCompleted"
        >
            <div
                *ngIf="!job['scores']; else notCompleted"
            >
                <div class="notCompletedTxt">
                    {{ 'people.commons.notCompleted' | translate }}
                </div>
            </div>
            <ng-template
                #notCompleted
            >
                <div class="notCompletedTxt">
                    {{ 'people.commons.notCompleted' | translate }}
                </div>
            </ng-template>
        </div>
        <div class="main-container">
            <h2
                [class.paleGrey]="!record['scores']"
            >Traits</h2>
            <div style="max-width: 425px">
                <app-mpo-graph
                    [id]="'traits_person_'+record['id']+pos"
                    [width]="'100%'"
                    [scores]="(record['scores']) ? record['scores'] : null"
                    [targetScore]="(record['scores'] && this.state.displayPreferences.display.targetPersonalityProfile) ?
                    job['scores'] : null"
                    [messageOverhead]="false"
                    [section]="'rightMatch'"
                    [lang]="translateService.currentLang"
                ></app-mpo-graph>
            </div>
        </div>
    </ng-container>
</div>
