import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    OnChanges,
    Output,
    ViewChild,
    OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {StateService} from 'src/app/core/services/state/state.service';
import {IdwMatrixAnalysisService} from '../../../services/idw-matrix-analysis.service';
import {IdwMatrixListService} from '../../../services/idw-matrix-list.service';
import {ApiIdwMatricesService} from '../../../services/api-idw-matrices.service';
import {IdwMatrix} from '../../../models/idw-matrix.model';
import {Tools} from 'src/app/shared/barrels/misc';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-mobile-idw-matrices-edit',
  templateUrl: './mobile-idw-matrices-edit.component.html',
  styleUrls: ['./mobile-idw-matrices-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileIdwMatricesEditComponent implements OnInit, OnChanges, OnDestroy {

    @Input() status;
    @Input() multiAction;

    @Output() close = new EventEmitter();

    @ViewChild('idwMatrixNgForm') idwMatrixNgForm;

    idwMatrix: IdwMatrix;
    idwMatrixForm: FormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    submitted = false;
    headerText: string;
    loaderDisplay = {
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
    };

    private subscriptions = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private fb: FormBuilder,
                private stateService: StateService,
                private apiIdwMatricesService: ApiIdwMatricesService,
                public idwMatricesList: IdwMatrixListService,
                public idwAssMatrix: IdwMatrixAnalysisService
    ) {
    }

    ngOnInit() {
        this.stateService.idwMatrix.stateChanged$.subscribe(
            (res) => {
                if (this.submitted && res && res.itemToDisplayId) {
                    this.onCancel();
                }
            }
        );
    }

    ngOnChanges() {
        this.requestedBy = this.stateService.session.sessionData.userData.username;
        this.sessionStructure = this.stateService.session.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (!this.idwAssMatrix.itemToDisplay) {
            this.idwAssMatrix.itemToDisplay = {
                subAccount: 0
            };
        }

        if (this.status === 'new') {
            this.idwMatrix = Object.assign({}, new IdwMatrix());
            this.idwMatrix.consultant = this.requestedBy;
        }

        if (this.status === 'edit') {
            this.idwMatrix = Object.assign({}, this.idwAssMatrix.itemToDisplay);
        }

        if (this.multiAction && this.multiAction.ids.length !== 0) {
            this.headerText = (this.multiAction.ids.length === 1) ?
                'analysisCommons.addCandidateToAnalysis' :
                'analysisCommons.addCandidatesToAnalysis';
        } else {
            this.headerText = null;
        }

        this.initForm();
    }

    onCancel() {
        this.resetForm();
        this.close.emit(true);
    }

    initForm() {
        this.submitted = false;

        let subAccount = (this.stateService.session.sessionData.accountData) ?
                    this.stateService.session.sessionData.accountData.masterSubAccountId : '';
        if (this.status === 'edit') {
            subAccount = this.idwAssMatrix.subAccount;
        }

        let departmentId = (this.status === 'edit') ? this.idwAssMatrix.departmentId : '';

        this.idwMatrixForm = this.fb.group({
            name: [this.idwMatrix.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            consultant: [this.idwMatrix.consultant, [Validators.required, Validators.minLength(2)]],
            department: [departmentId]
        });
    }

    onSubmit(model: FormGroup) {
        this.submitted = true;
        if (model.valid) {
            let data = model.value;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    formCheck(specificField = null) {
        if (this.idwMatrixNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.idwMatrixForm.controls[specificField], this.idwMatrixNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount', 'consultant'];
                for (let field of fields) {
                    if (this.validateFormField(this.idwMatrixForm.controls[field], this.idwMatrixNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    saveNew(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiIdwMatricesService.postDitGram(data).subscribe(
            (res) => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    // Get Trait and PRB from id
                    const recordsTrait = this.multiAction.ids.map((id: string) => ({type: 'trait', record: id}));
                    const recordsPrb = this.multiAction.ids.map((id: string) => ({type: 'prb', dash: 0, record: id}));
                    const recordsToPut = {'records': [...recordsTrait, ...recordsPrb]};
                    // Update created Matrix
                    this.subscriptions.add(this.apiIdwMatricesService.putDitGram([res.id], recordsToPut).subscribe(
                        () => {
                            this.idwAssMatrix.setItemToDisplayWithId(res.id);
                            this.idwMatricesList.getIdwMatricesList(true);
                        }
                    ));
                } else {
                    this.idwAssMatrix.setItemToDisplayWithId(res.id);
                    this.idwMatricesList.getIdwMatricesList(true);
                }
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiIdwMatricesService.putDitGram([this.idwAssMatrix.id], data).subscribe(
            () => {
                this.idwAssMatrix.setItemToDisplayWithId(this.idwAssMatrix.id);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    resetForm() {
        this.submitted = false;
        // this.errorForm = null;
        this.idwMatrixForm.reset();
        this.idwMatrixNgForm.submitted = false;
        this.initForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
