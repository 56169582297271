import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { LoadingModule } from 'ngx-loading';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AdministrationModule} from './administration/administration.module';
import {CoreModule} from './core/core.module';
import {PeopleModule} from './people/people.module';
import {ToolsModule} from './tools/tools.module';
import {JobsModule} from './jobs/jobs.module';
import {AnalysisModule} from './analysis/analysis.module';
import {NgenioFormsModule} from './shared/forms/ngenio-forms.module';
import {SharedModule} from './shared/shared.module';

import {HttpClientModule, HttpClient} from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';

import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DragulaModule, DragulaService } from 'ng2-dragula';


import {AdminService} from './app/services/admin.service';
import {AuthGuardService} from './app/services/auth-guard.service';
import {LoginService} from './app/services/login.service';
import {VersionCheckService} from './app/services/version-check.service';
import {StateService} from './core/services/state/state.service';
import { EnvServiceProvider } from './app/services/env.service.provider';
import { NavigationService } from './app/components/navigation/navigation.service';


import {AppComponent} from './app/components/app/app.component';
import {NavigationComponent} from './app/components/navigation/navigation.component';
import {LoginComponent} from './app/components/login/login.component';
import {ArchivesModule} from './archives/archives.module';
import {ResetPassComponent} from './app/components/resetpass/resetpass.component';
import {UserPreferencesService} from './administration/services/user-preferences.service';
import {LocaleService} from './app/services/locale.service';
import { SetpassComponent } from './app/components/setpass/setpass.component';
import { SidebarModule } from 'ng-sidebar';

// import {GlobalExceptionHandlerService} from './app/services/global-exception-handler.service';

import {environment} from '../environments/environment';
import { PermissionsService } from './shared/permissions/permissions.service';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { MobileLoginComponent } from './app/components/login/mobile/mobile-login.component';
import { MobileResetpassComponent } from './app/components/resetpass/mobile/mobile-resetpass.component';
import { MobileSupportComponent } from './app/components/support/mobile/mobile-support/mobile-support.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {JobSatisfactionsModule} from './analysis/jobSatisfactions/job-satisfactions.module';

// Token getter for Auth0 JWT package
export function tokenGetter() {
    return localStorage.getItem('id_token');
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        LoginComponent,
        ResetPassComponent,
        SetpassComponent,
        AppComponent,
        MobileLoginComponent,
        MobileResetpassComponent,
        MobileSupportComponent
    ],
    imports: [
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        BrowserModule,
        SidebarModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgenioFormsModule,
        CoreModule,
        AdministrationModule,
        SharedModule,
        PeopleModule,
        ToolsModule,
        JobsModule,
        AnalysisModule,
        ArchivesModule,
        BrowserAnimationsModule,
        LoadingModule,
        HttpClientModule,
        DragulaModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        TooltipModule.forRoot(),
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['www-sf5.local', 'staging.ngenioconnect.com', 'www.ngenioconnect.com', 'ngenioconnect.com'],
                blacklistedRoutes: ['login_check', 'reset-password']
            }
        }),
        TypeaheadModule.forRoot(),
        // DeviceDetectorModule.forRoot(),
        MatMenuModule,
        MatToolbarModule,
        JobSatisfactionsModule
    ],
    providers: [
        AuthGuardService,
        StateService,
        LoginService, UserPreferencesService,
        AdminService,
        LocaleService,
        VersionCheckService,
        DragulaService,
        // {
        //     provide: ErrorHandler,
        //     useClass: GlobalExceptionHandlerService
        // }
        NavigationService,
        PermissionsService,
        EnvServiceProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
