<ng-sidebar-container style="position: absolute; height: 100vh">
    <ng-sidebar
        [position]="'right'"
        [(opened)]="peopleList._opened"
        [animate]="false"
        [closeOnClickOutside]="false"
        (openedChange)="onSidebarStatusChange($event)"
        [autoFocus]="false"
        #gSidebar
    >
        <div class="outerColumnGrey col-md-4" [style.width]="sideWidth">
            <div *ngIf="peopleList.state && peopleList.sideBarStatus" class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="peopleList.sideBarStatus === 'recentActivities'">
                    <app-infopanel
                        [width]="sideWidth">
                    </app-infopanel>
                </div>
                <div *ngIf="peopleList.sideBarStatus === 'searchFilters'">
                    <app-people-list-search-filters
                        [whereAmI]="'filter'"
                        [width]="sideWidth">
                    </app-people-list-search-filters>
                </div>
                <div *ngIf="peopleList.sideBarStatus === 'reports' && peopleList.person.personToDisplay">
                    <app-people-sidebar
                        [width]="sideWidth"
                        [config]="'list'"
                        (pdfEvent)="openPdfModal()"
                        (refreshList)="refreshList()"
                        (openPeopleSidebar)="peopleList.openPeopleSidebar()"
                        (closeSidebar)="closePeopleSidebar($event)"
                    ></app-people-sidebar>
                </div>
                <div *ngIf="peopleList.sideBarStatus=='clickMode'">
                    <app-people-selected-items
                        [width]="sideWidth"
                        (switchOff)="peopleList.switchOffFilterPanelIndicator($event, true)"
                    >
                    </app-people-selected-items>
                </div>
            </div>
        </div>
    </ng-sidebar>
    <div (window:resize)="onResize()"
         (window:orientationchange)="onResize()"
         ng-sidebar-content
    >
        <div class="rightWrapper peopleListWrapper right-wrapper-tablet"
             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"
             (scrolled)="peopleList.listScrolled()"
        >
            <div class="outerColumnWhite"
                 [class.col-md-8]="!smallSize"
                 [class.clear]="!smallSize"
                 [style.width]="(!smallSize) ? listWidth : null"
            >
                <div
                    id="topMenuWrapper"
                    class="affix"
                    [class.topMenuCalcWidth]="smallSize"
                    [style.width]="(!smallSize) ? listWidth : null"
                >
                    <div class="pull-left">
                        <div class="pull-left">
                            <h1 class="sTitle">
                                <span *ngIf='!actualModule.archive'>{{ 'commons.individus' | translate }}</span>
                                <span *ngIf='actualModule.archive'>{{ 'commons.archives' | translate }}</span>
                            </h1>
                        </div>
                        <br>
                    </div>
                    <div class="pull-right">
                        <app-main-list-search-bar
                            [selectionByFilters]="peopleList.selectionByFilters"
                            (searchEvent)="setSearchFilter($event)"
                            (filterPanel)="peopleList.toggleFilterPanel()"
                        ></app-main-list-search-bar>
                        <div class="clearfix"></div>
                        <div class="searchResults extraPaddingRight">
                            <span *ngIf="peopleList.selectionDone && peopleList.totalListItems !== null && peopleList.totalListItems !== totalItems"
                            >
                                {{ peopleList.totalListItems }}
                                {{ peopleList.resMention | translate }}
                                <span *ngIf="totalItems">
                                    {{ 'commons.of' | translate }} {{totalItems}}
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div id="subMenu">
                        <div class="pull-left" id="subMenuLeft">
                            <table class="nav nav-topbar">
                                <tr class="topMenu">
                                    <td *ngIf='!actualModule.archive' (click)="openSurveyModal()">
                                        <a>
                                            <div>
                                                <img alt="" src="assets/header/send/ico_send.png"/>
                                                <div>
                                                    {{ 'commons.survey' | translate }}
                                                </div>
                                                <img alt="" src="assets/header/send/ico_send_selected.png"/>
                                            </div>
                                        </a>
                                    </td>
                                    <td [class.clickMode]="peopleList.clickMode" (click)="peopleList.switchMode()">
                                        <a>
                                            <div>
                                                <img alt="" src="assets/header/comparer/ico_comparer.png"/>
                                                <div>
                                                    {{ 'commons.toSelect' | translate }}
                                                </div>
                                                <img alt="" src="assets/header/comparer/ico_comparer_selected.png"/>
                                            </div>
                                        </a>
                                    </td>
                                    <td (click)="refreshList()">
                                        <a>
                                            <div style="padding-top: 2px">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20"
                                                     viewBox="0 0 24 20">
                                                    <path
                                                        fill="#AAA"
                                                        d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99
                                                        8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6
                                                        6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
                                                </svg>
                                            </div>
                                        </a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div *ngIf='!actualModule.archive' class="clearfix">
                                <div
                                    class="pull-right"
                                    (click)="peopleList.selectType('candidate')"
                                    [class.typeSelected]="peopleList.typeSelected == 'candidate'">
                                    <div>{{ 'people.commons.candidates' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    (click)="peopleList.selectType('employee')"
                                    [class.typeSelected]="peopleList.typeSelected == 'employee'">
                                    <div>{{ 'people.commons.employeesProfile' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    (click)="peopleList.selectType('all')"
                                    [class.typeSelected]="peopleList.typeSelected == 'all'">
                                    <div>{{ 'commons.all' | translate }} </div>
                                </div>
                            </div>
                            <div *ngIf="actualModule.archive" class="clearfix">
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/rightmatch']">
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasCommunication"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/idwmatrix']">
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/graphboard']">
                                    <div>{{ 'commons.graphboard' | translate }} </div>
                                </div>
                                <div
                                    *ngIf="peopleList.hasJobAccess"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/jobs']">
                                    <div>{{ 'commons.jobs' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right typeSelected">
                                    <div>{{ 'commons.individus' | translate }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div *ngIf="peopleList.toList" [style.height]="(peopleList.toList.count == 0) ? '700px': null" id="listWrap">
                    <ngx-loading
                        [show]="peopleList.loading"
                        [config]="loaderDisplay"
                    ></ngx-loading>
                    <app-main-list
                        [small]="smallSize"
                        [forceWidthTo]="listWidth.substring(0, listWidth.length - 2)"
                        [selectedItemId]="peopleList.state['personToDisplayId']"
                        [tableDefinition]="peopleList.tableDefinition"
                        [view]="peopleList.state['listView']"
                        [clickMode]="peopleList.clickMode"
                        [listsData]="peopleList.toList"
                        [actualModule]="'people'"
                        [archive]="actualModule.archive"
                        (activateReport)="actOnActivation($event)"
                        (requestSendMpoEvent) = "openRequestSendMpoModal($event)"
                        (requestResendMpoEvent)="openRequestResendMpoModal($event)"
                        (requestSendIacEvent) = "openRequestSendIacModal($event)"
                        (requestResendIacEvent)="openRequestResendIacModal($event)"
                        (selectedItem)="personSelected($event)"
                        (reordering)="setOrder($event)"
                    ></app-main-list>
                </div>
            </div>
        </div>
    </div>
</ng-sidebar-container>
