<h3><strong>{{ 'commons.sendByNgenioServer' | translate }}</strong></h3>

<!-- Error message -->
<p
    class="text-danger"
    *ngIf="isFormSubmitted && !sendEmailFormGroup.valid"
>
    {{ 'commons.multiError' | translate }}
</p>

<!-- API error -->
<p *ngIf="apiErrorMessage" class="text-danger">{{ apiErrorMessage | translate }}</p>

<div *ngIf="sendEmailFormGroup" class="form-group" [formGroup]="sendEmailFormGroup">
    <div class="sendContent">
        <table style="width: 700px">
            <tr>
                <td style="width: 75px; padding-bottom: 7px; font-weight: bold"
                    [class.text-danger]="isFormSubmitted && sendEmailFormGroup.get('email').errors"
                >
                    <label for="emailField">{{ 'commons.email' | translate }}<span [innerHTML]="titleSep"></span></label>
                </td>
                <td>
                    <input
                        style="width: 250px; margin-bottom: 7px"
                        type="text"
                        id="emailField"
                        formControlName="email"/>
                </td>
            </tr>
            <tr>
                <td style="width: 75px; padding-bottom: 7px; font-weight: bold"
                    [class.text-danger]="isFormSubmitted && sendEmailFormGroup.get('subject').errors"
                >
                    <label for="emailSubject">{{ 'commons.subject' | translate }}<span [innerHTML]="titleSep"></span></label>
                </td>
                <td>
                    <input
                        style="width: 250px; margin-bottom: 7px"
                        type="text"
                        formControlName="subject"
                        id="emailSubject"
                    />
                </td>
            </tr>
        </table>
        <table style="width: 700px">
            <tr>
                <td style="padding-bottom: 7px; font-weight: bold">
                    <span class="field_title"><label for="emailMessage">{{ 'commons.message' | translate }}</label></span>
                </td>
            </tr>
            <tr>
                <td>
                    <textarea
                        [attr.disabled]="deactivateTa ? 'disabled' : null"
                        style="border: 1px solid #ccc; width: 100%; height: 100px"
                        formControlName="message"
                        id="emailMessage"
                    ></textarea>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-checkbox formControlName="isUserIdentified"
                    >&nbsp;&nbsp;&nbsp;{{ 'people.requestModal.emailLogin' | translate }}
                    </mat-checkbox>
                </td>
            </tr>
        </table>

        <h3 style="margin-bottom: 15px; margin-top: 20px">
            {{ 'people.requestModal.sentText' | translate}}
        </h3>
        <p *ngIf="sendEmailFormGroup.get('isUserIdentified').value">
            {{ instructionsMessages['requesterIdentification'] }}
        </p>
        <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
        <p>{{ sendEmailFormGroup.get('message').value }}</p>
        <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
        <div id="innerTextServer" [class.ar]="instructionsMessages.language == 'ar'">
            <div [innerHTML]="activateInstructions"></div>
        </div>
        <div [class.clearfix]="instructionsMessages.language=='ar'"></div>
    </div>
</div>
