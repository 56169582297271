<div *ngIf="activationData">
    <div class="modal-header">
        <h4 class="modal-title pull-left fontLight">{{
            'commons.reportActivation' | translate }}</h4>
        <div (click)="closeModal(true)" aria-label="Close" class="x-close pull-right"
             style="font-size: 20pt;">
            &#x00D7;
        </div>
    </div>
    <ng-container *ngIf="!proceed">
        <div class="modal-body">
            <p *ngIf="translate.currentLang=='fr'">
                Le nombre de crédits à votre compte est insuffisant pour traiter votre demande.
                Veuillez contacter votre conseiller MPO afin d'ajouter des crédits à votre compte.
            </p>
            <p *ngIf="translate.currentLang=='en'">
                The credits level of your account is insufficient to process your request.
                Please contact your MPO advisor to add credits to your account.
            </p>
        </div>
        <div class="modal-footer">
            <button class="modalButton" (click)="closeModal(true)">{{ 'commons.close' | translate }}</button>
        </div>
    </ng-container>
    <ng-container *ngIf="proceed">
    <div *ngIf="activationData.type!=='satellite'">
        <div class="modal-body">
            <p
                    *ngIf="activationType && unlimitedCredits"
                    [translate]="'people.reportActivationModal.reportActivationUnlimited'"
                    [translateParams]="{
                    reportType: renameReport(activationData.type)
                }"
            ></p>
            <p
                    *ngIf="activationType && !unlimitedCredits"
                    [translate]="'people.reportActivationModal.reportActivationFor'"
                    [translateParams]="{
                    reportType: renameReport(activationData.type),
                    price: pricesDefinitions[activationData.type]
                }"
            ></p>
            <ng-container *ngIf="false">
                <!-- @ilya 2020-11-20 The following 'p' has been replaced by the 'p' above without renameReport & setActivationData inside -->
                <p
                    *ngIf="activationType && !unlimitedCredits"
                    [translate]="'people.reportActivationModal.reportActivationFor'"
                    [translateParams]="{
                    reportType: renameReport(activationData.type),
                    price: pricesDefinitions[activationData.type]
                }"
                >
                    {{ renameReport(activationData.type) }}
                    {{ setActivationData({types: [activationData.type], cost: pricesDefinitions[activationData.type]}) }}
                </p>
            </ng-container>
            <p
                    *ngIf="!activationType && activationData.types && activationData['cost']"
            >
                {{ activationTexts() }}
            </p>
        </div>
        <div class="modal-footer">
            <button class="modalButton" (click)="closeModal(true)">{{ 'commons.cancel' | translate }}</button>&nbsp;&nbsp;
            <button class="modalButton" (click)="reportActivation()">{{ 'commons.activate' | translate }}</button>
        </div>
    </div>
    <div *ngIf="activationData.type==='satellite'">
        <div *ngIf="satelliteList">
            <div class="modal-body">
                <p>
                    <span [class.text-danger]="warningNothingSelected">
                        {{ 'people.reportActivationModal.assocPersonWJob' | translate }}
                    </span>
                    <label>
                        <select [(ngModel)]="bindedProfiler">
                            <option>{{ 'commons.select' | translate }}</option>
                            <option *ngFor="let profiler of satelliteList" [value]="profiler.id">
                                {{ profiler['jobTitle'] }}
                            </option>
                        </select>
                    </label>
                </p>
                <p *ngIf="!unlimitedCredits" [translate]="'people.reportActivationModal.activateSatellite'"
                   [translateParams]="{
                    price: pricesDefinitions[activationData.type]
                }"></p>
                <p *ngIf="unlimitedCredits">
                    <span *ngIf="translate.currentLang == 'fr'">
                        Activer le rapport MPO Satellite ?
                    </span>
                    <span *ngIf="translate.currentLang == 'en'">
                        Activate this MPO Satellite report?
                    </span>
                </p>
            </div>
            <div class="modal-footer">
                <button class="modalButton" (click)="closeModal(true)">{{ 'commons.cancel' | translate }}</button>&nbsp;&nbsp;&nbsp;
                <button class="modalButton"  (click)="reportActivation('satellite')">
                    {{ 'commons.activate' | translate }}
                </button>
            </div>
        </div>
        <div *ngIf="satelliteList===null"></div>
        <div *ngIf="satelliteList === false">
            <div class="modal-body">
                <p>
                    {{ 'people.reportActivationModal.noSatelliteGoSelectJob' | translate }}
                    <a (click)="navToJob()">
                        <span *ngIf="lang()=='en'">here</span>
                        <span *ngIf="lang()=='fr'">ici</span>
                    </a>.
                </p>
            </div>
            <div class="modal-footer">
                <button class="modalButton" (click)="closeModal(true)">{{ 'commons.close' | translate }}</button>
            </div>
        </div>
    </div>
    </ng-container>
</div>
