<div class="formWrapper">
    <h4 class="modal-title">{{ 'commons.sharePdf' | translate }}</h4>
    <div *ngIf="share=='none'">
        <div class="fieldsSection" *ngIf="false">
            <h5
                [class.text-danger]="selectMethod"
            >{{ 'commons.sendingMethod' | translate }}</h5>
            <mat-form-field>
                <mat-select
                    [(ngModel)]="method"
                    (ngModelChange)="changeSelectMethodState()"
                >
                    <mat-option value="pdf">{{ 'commons.pdfReport' | translate }}</mat-option>
                    <mat-option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</mat-option>
                    <mat-option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</mat-option>
                    <mat-option value="server">{{ 'commons.sendByServer' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="handleReport()"
                >{{ 'commons.continue' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- cutnpaste-->
    <div *ngIf="share=='cutpaste'">
        <div *ngIf="pdfReportUrl" class="fieldsSection">
            <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
            <p
                [translate]="'analysisCommons.linkInstruction'"
                [translateParams]="{
                            'analysisName': itemToDisplay.name
                        }"
            ></p>
            <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.finish' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- mailto -->
    <div *ngIf="share=='mailto'">
        <div *ngIf="mailto" class="fieldsSection">
            <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
            <p>{{ 'commons.sendByYourEmailReportInstructions' | translate }}
                <a href="mailto:{{ mailto }}" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
            <p class="mailtoWarning" style="color: darkred">
                {{ 'commons.mailtoWarning' | translate }}
            </p>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.finish' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- server -->
    <div *ngIf="share=='server'">
        <div class="fieldsSection">
            <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

            <div>
                <h3><b>Message</b></h3>
                <div class="form-group">
                    <div class="sendContent">
                        <table>
                            <tr>
                                <td class="sendContentTitle">{{ 'commons.email' | translate }}</td>
                                <td>
                                    <input
                                        id="reportEmailEmailTo"
                                        type="text"
                                        required
                                        [(ngModel)]="pdfEmail['emailTo']"/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td
                                    style="font-size: smaller; padding-bottom: 7px;"
                                ><label for="reportEmailEmailTo">{{ 'commons.emailsSepComma' | translate }}</label></td>
                            </tr>
                            <tr>
                                <td class="sendContentTitle"><label for="reportEmailSubject">{{ 'commons.emailSubject' | translate }}</label></td>
                                <td>
                                    <input
                                        id="reportEmailSubject"
                                        type="text"
                                        [(ngModel)]="pdfEmail.subject"
                                    />
                                </td>
                            </tr>
                        </table>
                        <table style="margin-top: 25px">
                            <tr>
                                <td style="padding-bottom: 7px; font-weight: bold">
                                    <span class="field_title"><label for="reportEmailMessage">{{ 'commons.message' | translate }}</label></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea
                                        id="reportEmailMessage"
                                        rows="7"
                                        style="border: 1px solid #ccc; height: 100px"
                                        [(ngModel)]="pdfEmail.message"></textarea>
                                </td>
                            </tr>
                        </table>
                        <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                        <div id="innerTextServer">
                            <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                            <p>{{ pdfEmail.message }}</p>
                            <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                            <p *ngIf="pdfReportUrl"
                               class="forceBreaks"
                               [innerHTML]="serverText"
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        [disabled]="pdfEmail['emailTo'] === ''"
                        (click)="sendEmailReport()"
                >{{ 'commons.shareReport' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
