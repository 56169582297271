<mat-grid-list cols="5" rowHeight="60px">
    <mat-grid-tile
        colspan="1"
        rowspan="1"
        style="max-width: 87px;"
    >
        <app-mobile-navigation></app-mobile-navigation>
        <a
            class="reloadLink"
            (click)="navigateTo(['m/people/list'])"
        >
            <img alt="N" src="{{logoImg}}">
        </a>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <h1>{{translate.currentLang === 'fr' ? 'Informations utiles' : 'Useful information'}}</h1>
    </mat-grid-tile>
</mat-grid-list>
<h2>
    <span *ngIf="translate.currentLang=='fr'">Options de support</span>
    <span *ngIf="translate.currentLang=='en'">Support options</span>
</h2>
<h3 class="fontLight">
    <span *ngIf="translate.currentLang=='fr'">Par téléphone : </span>
    <span *ngIf="translate.currentLang=='en'">By phone: </span>
    {{ phoneNumber }}
    <br />
    <span *ngIf="translate.currentLang=='fr'" (click)="sendSupportMail()">
        Par courriel : info@ngenioworld.com
    </span>
    <span *ngIf="translate.currentLang=='en'" (click)="sendSupportMail()">
        By E-mail: info@ngenioworld.com
    </span>
</h3>
<h2 *ngIf="!stateService.session.hasSatelliteRole">Documents</h2>
<h3 class="fontLight" *ngIf="!stateService.session.hasSatelliteRole">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/{{
                                    (translate.currentLang == 'fr') ? 'Manuel_NGENIO_CONNECT_3.3.pdf' : 'NGENIO_CONNECT_3.3_Manual.pdf'
                                }}">
        <span *ngIf="translate.currentLang=='fr'">Manuel de l'utilisateur</span>
        <span *ngIf="translate.currentLang=='en'">User's manual</span>
    </a>
    <br />
    <a *ngIf="translate.currentLang=='en'" target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/dictionary/competenciesDictionary.pdf">
        <span>Competencies dictionary</span>
    </a>
    <a *ngIf="translate.currentLang=='fr'" target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/dictionary/dictionnaireDesCompetences.pdf">
        <span>Dictionnaire des compétences</span>
    </a>
</h3>
<h2>
    <span *ngIf="translate.currentLang=='fr'">Questionnaires MPO (PDF)</span>
    <span *ngIf="translate.currentLang=='en'">MPO Questionnaires (PDF)</span>
</h2>
<h3 class="fontLight">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits et perception (FR)</span>
        <span *ngIf="translate.currentLang=='en'">Traits and perception (FR)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO_Traits.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits seulement (FR)</span>
        <span *ngIf="translate.currentLang=='en'">Traits only (FR)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO_Perception.pdf">
        <span *ngIf="translate.currentLang=='fr'">Perception seulement (FR)</span>
        <span *ngIf="translate.currentLang=='en'">Perception only (FR)</span>
    </a>
</h3>
<hr class="panelHr" />
<h3 class="fontLight">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits et perception (ANG)</span>
        <span *ngIf="translate.currentLang=='en'">Traits and perception (ENG)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO_Traits.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits seulement (ANG)</span>
        <span *ngIf="translate.currentLang=='en'">Traits only (ENG)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO_Perception.pdf">
        <span *ngIf="translate.currentLang=='fr'">Perception seulement (ANG)</span>
        <span *ngIf="translate.currentLang=='en'">Perception only (ENG)</span>
    </a>
</h3>
<hr class="panelHr" />
<h3 class="fontLight">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_Complet.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits et perception (ESP CANADA)</span>
        <span *ngIf="translate.currentLang=='en'">Traits and perception (SP CANADA)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_traits.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits seulement (ESP CANADA)</span>
        <span *ngIf="translate.currentLang=='en'">Traits only (SP CANADA)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_perception.pdf">
        <span *ngIf="translate.currentLang=='fr'">Perception seulement (ESP CANADA)</span>
        <span *ngIf="translate.currentLang=='en'">Perception only (SP CANADA)</span>
    </a>
</h3>
<hr class="panelHr" />
<h3 class="fontLight">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Complete.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits et perception (ESP USA)</span>
        <span *ngIf="translate.currentLang=='en'">Traits and perception (SP USA)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Traits.pdf">
        <span *ngIf="translate.currentLang=='fr'">Traits seulement (ESP USA)</span>
        <span *ngIf="translate.currentLang=='en'">Traits only (SP USA)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Perception.pdf">
        <span *ngIf="translate.currentLang=='fr'">Perception seulement (ESP USA)</span>
        <span *ngIf="translate.currentLang=='en'">Perception only (SP USA)</span>
    </a>
</h3>
<hr class="panelHr" />
<h3 class="fontLight">
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/profiler/questionnaire_profil_de_personnalite_cible.pdf">
        <span *ngIf="translate.currentLang=='fr'">Profil de personnalité cible (FR)</span>
        <span *ngIf="translate.currentLang=='en'">Target personality profile (FR)</span>
    </a>
    <br />
    <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/profiler/questionnaire_target_personality_profile.pdf">
        <span *ngIf="translate.currentLang=='fr'">Profil de personnalité cible (ANG)</span>
        <span *ngIf="translate.currentLang=='en'">Target personality profile (ENG)</span>
    </a>
</h3>
