import {Injectable} from '@angular/core';
import {ContentItem} from 'src/app/shared/classes/services/content_item';
import {StateService} from 'src/app/core/services/state/state.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NavigationEnd, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import * as _ from 'lodash';
import {ApiAdministrationService} from './api-administration.service';
import {TranslateService} from '@ngx-translate/core';
import {AccountTypesHelper} from '../commons/AccountTypesHelper';
import * as moment from 'moment';
import {FormsHelper} from '../helpers/FormsHelper';
import {ProductsHelper} from '../helpers/products.helper';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountService extends ContentItem {
    public loading = false;
    private clientAccount;
    private availableProductsList;
    private mainAdminsList;

    readonly persistentVariables = [
        'clientAccount'
    ];

    readonly DATE_FORMAT_FR = 'DD/MM/YYYY';
    readonly DATE_FORMAT_EN = 'MMM DD, YYYY';

    parentAccountsChanged = new BehaviorSubject(null);
    parentAccountsChanged$ = this.parentAccountsChanged.asObservable();

    productDescriptionChanged = new BehaviorSubject(null);
    productDescriptionChanged$ = this.productDescriptionChanged.asObservable();


    usersChanged = new BehaviorSubject(null);
    usersChanged$ = this.usersChanged.asObservable();

    subAccountsCredits;

    constructor(
        private apiAdministration: ApiAdministrationService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        protected translate: TranslateService,
        protected apiAdministrationService: ApiAdministrationService,
        protected translateService: TranslateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('accountService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    getSubAccountCredits(id) {
        this.apiAdministrationService.clientSubaccountsCredits(
            [id]
        ).subscribe(
            res => {
                // In case we are editing a transaction, we need to remove the initial amount from the current amount
                this.subAccountsCredits = res;
            }
        );
    }

    setAccountToDisplayWithId(id, specificState = 'admin', callback = null) {
        let obsResult = {};
        this.loading = true;
        this.getSubAccountCredits(id);
        this.apiAdministration.client([id]).subscribe(
            res => {
                if (res) {
                    this.clientAccount = res;
                    this.fetchAccountDepartments();
                    //  this.fetchPurchases(this.subAccountFromUrl);
                    this.loading = false;
                    this.storeServiceData('accountService', this.persistentVariables, this);
                    this.stateService[specificState].clientToDisplayId = res.id;
                    obsResult['setClient'] = res.id;
                    if (callback) {
                        if (callback === 'fullClient') {
                            this.router.navigate(['admin/account/' + res.id]).then(() => {
                            });
                        } else {
                            // select specific sub-account
                            callback.onSelectSubAccount();
                        }
                    }

                    if (this.parentAccounts) {
                        const parentAccountIndex = this.parentAccounts.findIndex(f => f.name === this.partner);
                        if (parentAccountIndex > -1 && this.parentAccounts[parentAccountIndex]) {
                            if (this.parentAccounts[parentAccountIndex].path) {
                                this.parentPath = this.parentAccounts[parentAccountIndex].path;
                            }
                        }
                    }
                    this.getAccountsUsers();

                    this.stateService[specificState].stateChanged.next(obsResult);
                }
            }
        );
    }

    getAccountData(id) {
        return this.apiAdministration.client([id]).pipe(take(1));
    }

    get availableProducts() {
        return this.availableProductsList;
    }

    get parentAccount() {
        let pathArray = this.path.split(',');
        let pathPositonOfParent = pathArray.length - 3;
        return Number.parseInt(pathArray[pathPositonOfParent], 10);
    }

    get parentAccountNumber() {
        return this.parentAccount;
    }

    rewriteTransactionDescriptive(transaction) {
        if (transaction.sku === 'void') {
            return '';
        }
        if (transaction.sku === 'TRAIT') {
            if (this.translate.currentLang === 'fr') {
                return 'Rapport MPO Personnalité avec traits seulement';
            }
        } else if ([
            'MPOFROMIDW', 'SAT', 'MPOFROMSATELLITE', 'PRB', 'INITIALPERCEPTIONDO', 'SATELLIETEFROMMPO', 'PROFILER',
            'DIT', 'TALENTS', 'FIRST_PRB', 'TRAIT&PRB'
        ].includes(transaction.sku)) {
            if (this.translate.currentLang === 'fr') {
                return transaction.descriptive['en'];
            }
            return transaction.descriptive['fr'];
        }
        return transaction.descriptive[this.translate.currentLang];
    }

    fetchPurchases(subAccount, refresh = false) {
        let params: any = {
            limit: 100,
            order: {date: 'desc'},
            accountNumber: [this.accountNumber],
            subAccount: subAccount
        };
        let purchasesObservable = this.apiAdministration.purchases([params]).subscribe(res => {
            this.purchases = res;
            // Sku to excludes
            const skuToExclude: any = ['SUBACCOUNT_CREATION', 'ADJUSTMENT_MANUALLY', 'DEMO_CREATION'];
            this.purchases.data = this.purchases.data.filter((item) => !skuToExclude.includes(item.sku));
            this.checkUnlimited(this.purchases.data);
            if (refresh) {
                this.stateService.admin.stateChanged.next({refreshComponent: true});
            }
            purchasesObservable.unsubscribe();
        });
    }

    public checkUnlimited(data) {
        // replace '9999' amount by 'Unlimited' mention
        for (let purchase of data) {
            if (purchase && (purchase.amount === '9999' || purchase.amount === 'Unlimited' || purchase.amount === 'Illimités')) {
                this.translate.get('commons.creditsUnlimitedList').subscribe((translated: string) => {
                    purchase.amount = translated;
                });
            }
        }
    }

    fetchAccountDepartments() {
        const params: any = {
            'accountNumber': this.accountNumber
        };
        let depObservable = this.apiAdministration.departments([params]).subscribe(res => {
            this.departments = res;
            this.stateService.session.sessionData.structure.departments = res.data;
            depObservable.unsubscribe();
        });
    }

    resetAccountToDisplay() {
        this.accountToDisplay = {};
        this.stateService.admin.stateChanged.next({
            reset: true
        });
    }

    since(date, hyphenFormat = true) { // hyphenFormat = FORMAT YYYY-mm-dd else mongo 'sec' format
        let days = '';

        let today = (hyphenFormat) ? (new Date()).getTime() : Date.now();
        let datePre = (hyphenFormat) ? date.split('-') : null;
        let refDate = (hyphenFormat) ?
            new Date(datePre[0], datePre[1] - 1, datePre[2]).getTime() :
            date.sec * 1000;

        return Math.round((today - refDate) / (60 * 60 * 24 * 1000)) + days;
    }
    /**
    getParentAccounts() {
        this.parentAccountsChanged = undefined;
        this.parentAccountsChanged$ = undefined;
        this.parentAccountsChanged = new BehaviorSubject(null);
        this.parentAccountsChanged$ = this.parentAccountsChanged.asObservable();
        let structureObservable = this.apiAdministrationService.accountParentsForType([this.accountType || 'demo']).subscribe(
            (res) => {
                let parentAccounts = res.data;
                // let parentAccounts = res.data.sort(
                //    function (a, b) {
                //        let textA = (a && a.name) ? a.name.toUpperCase() : null;
                //        let textB = (b && b.name) ? b.name.toUpperCase() : null;
                //        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                //    });
                this.parentAccountsChanged.next(parentAccounts);
                structureObservable.unsubscribe();

            });

        return this.parentAccountsChanged$;
    }
     */

    get administrator() {
        return this.mpoProducts.administrator;
    }
    /**
    getAccountUsersAsObservable() {
        this.usersChanged = undefined;
        this.usersChanged$ = undefined;
        this.usersChanged = new BehaviorSubject(null);
        this.usersChanged$ = this.usersChanged.asObservable();

        this.getAccountsUsers(true);

        return this.usersChanged$;
    }*/

    getAccountsUsers(asObservable = false, skip = 0, limit = 10) {
        let params: any = {
            limit: 10000,
            order: {firstName: 'asc'},
            accountNumber: [this.accountNumber],
            asList: true
        };
        if ((this.accountNumber) === undefined) { return; }
        let structureObservable = this.apiAdministrationService.users([params]).subscribe(
            (res) => {
                this.users = res;
                if (asObservable) {
                    this.usersChanged.next(res);
                }
                for (let datum of res.data) {
                    if (this.subAccounts && datum && ('principalSubAccount' in datum)) {
                        const subAccountById: any = this.subAccounts.find((subAccount: any) => subAccount.id === datum['principalSubAccount']);
                        let none = (this.translateService.currentLang === 'fr') ? 'Aucun' : 'None';
                        datum['principalSubAccountName'] = subAccountById ? subAccountById.name : none;
                    }
                }

                let mainAdmins = [];
                for (let potentialAdmins of this.users.data) {
                    if (potentialAdmins.role === 'ROLE_MAIN_ADMIN') {
                        mainAdmins.push(potentialAdmins.firstName + ' ' + potentialAdmins.lastName);
                    }
                }
                this.mainAdminsList = mainAdmins;
                structureObservable.unsubscribe();
            });
    }

    /**
    getProductDescription() {
        this.productDescriptionChanged = undefined;
        this.productDescriptionChanged$ = undefined;
        this.productDescriptionChanged = new BehaviorSubject(null);
        this.productDescriptionChanged$ = this.productDescriptionChanged.asObservable();
        let structureObservable = this.apiAdministrationService.productsClient([this.id]).subscribe(
            (res) => {
                if (res) {
                    this.handleProductsData(res);
                    this.productDescriptionChanged.next(res);
                    structureObservable.unsubscribe();
                }
            }
        );

        return this.productDescriptionChanged$;
    }*/


    handleProductsData(res) {
        let productsGroups = Object.assign({}, ProductsHelper.productsGroups());
        let productsData = {
            productCodes: null,
            descriptive: null,
            descriptiveVerbatim: {
                en: null,
                fr: null
            }
        };

        let availableProductsUS: any = [];
        let availableProductsCanada: any = [];

        for (let product of res) {
            if (product.target.indexOf(this.accountType) > -1) {
                // availableProducts.push(product);
                if (product.sku.includes(' US')) {
                    availableProductsUS.push(product);
                } else {
                    availableProductsCanada.push(product);
                }
            }
        }

        for (let country of ['Canada', 'US']) {
            let availableProducts = (country === 'Canada') ?
                ProductsHelper.createSkuOrder(availableProductsCanada) :
                ProductsHelper.createSkuOrder(availableProductsUS);
            if (this.isClientOrDemo) {
                for (let product of availableProducts) {
                    for (let gr of ProductsHelper.productsGroupsNames()) {
                        if (product.hasOwnProperty('skuOrder') && product.skuOrder[0] === gr && productsGroups[country].hasOwnProperty(gr)) {
                            productsGroups[country][gr].push(product);
                        }
                    }
                }
            } else {
                productsGroups[country] = availableProducts;
            }
        }

        productsData.productCodes = productsGroups;

        for (let country of ['Canada', 'US']) {
            if (this.isClientOrDemo) {
                for (let cat of ProductsHelper.productsGroupsNames(country)) {
                    if (productsGroups[country] && productsGroups[country][cat]) {
                        for (let prCat of productsGroups[country][cat]) {
                            if (prCat.sku === this.productCode) {
                                productsData.descriptiveVerbatim = {
                                    en: prCat.descriptive['en'],
                                    fr: prCat.descriptive['fr'],
                                };
                                productsData.descriptive = (this.productCode) ? this.productCode : '';
                            }
                        }
                    }
                }
            } else {
                for (let prCat of productsGroups[country]) {
                    if (prCat.sku === this.productCode) {
                        productsData.descriptiveVerbatim = {
                            en: prCat.descriptive['en'],
                            fr: prCat.descriptive['fr'],
                        };
                        productsData.descriptive = (this.productCode) ? this.productCode : '';
                    }
                }
            }
        }
        return productsData;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    get accountToDisplay(): any {
        return _.get(this, 'clientAccount');
    }

    set accountToDisplay(account) {
        this.clientAccount = account;
        if (account !== {}) {
            this.getAccountsUsers();
        }
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get mainAdmins() {
        return this.mainAdminsList;
    }

    get id(): string {
        return _.get(this, 'clientAccount.id');
    }

    get accountNumber() {
        return _.get(this, 'clientAccount.accountNumber');
    }

    get accountType() {
        return _.get(this, 'clientAccount.type');
    }

    get expiresAt() {
        return _.get(this, 'clientAccount.expiresAt');
    }

    get mpoClient() {
        if (this.productCode) {
            return (this.translateService.currentLang === 'fr') ? 'Oui' : 'Yes';
        }
        return (this.translateService.currentLang === 'fr') ? 'Non' : 'False';
    }

    get typeCode() {
        let typeCodeSuffix = (this.accountType === 'discretionary') ? '_disc' : '_false';
        return this.accountType + typeCodeSuffix;
    }

    get productCode() {
        return (this.productInformation && this.productInformation.productCode) ? this.productInformation.productCode : null;
    }

    get productCodes() {
        return this.productCode;
    }

    get accountTypeName() {
        return AccountTypesHelper.getTranslationStringForType(this.accountType, this.isDiscretionary);
    }

    get accountTypeAlias() {
        return _.get(this, 'clientAccount.accountTypeAlias');
    }

    get isExpired() {
        return _.get(this, 'clientAccount.isExpired');
    }

    get subAccounts() {
        return _.get(this, 'clientAccount.subAccounts');
    }

    get accountsTotalCredits() {
        return _.get(this, 'clientAccount.totalCredits');
    }

    set totalCredits(totalCredits) {
        this.clientAccount.totalCredits = totalCredits;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get masterSubAccountId() {
        return _.get(this, 'clientAccount.masterSubAccountId');
    }

    get parentPath() {
        return _.get(this, 'clientAccount.parentPath');
    }

    set parentPath(parentPath) {
        this.clientAccount.parentPath = parentPath;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get parentPathName(): string {
        if (this.parentAccounts && this.parentPath) {
            let parentPathName = this.parentAccounts.filter(parent => parent.path === this.parentPath);
            return (parentPathName && parentPathName[0]) ? parentPathName[0].name : '';
        }
        return '';
    }

    get parentAccounts() {
        return _.get(this, 'clientAccount.parentAccounts');
    }

    set parentAccounts(parentAccounts) {
        this.clientAccount.parentAccounts = parentAccounts;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get path() {
        return _.get(this, 'clientAccount.path');
    }

    get hierarchy() {
        return _.get(this, 'clientAccount.hierarchy');
    }

    get clientAccountData() {
        return _.get(this, 'clientAccount');
    }

    get parent() {
        return _.get(this, 'clientAccount.parentAccount');
    }

    get subsidiary() {
        return _.get(this, 'clientAccount.subsidiary');
    }

    get distributor() {
        return _.get(this, 'clientAccount.distributor');
    }

    get partner() {
        return _.get(this, 'clientAccount.partner');
    }

    get assets() {
        return _.get(this, 'clientAccount.assets');
    }

    get permissions() {
        // example: {mpo: true, dit: true, talents: true, satellite: true, ra: true}
        return _.get(this, 'clientAccount.permissions');
    }

    get mpoPersonality() {
        return this.permissions.mpo;
    }

    get mpoCommunication() {
        return this.permissions.dit;
    }

    get mpoTalents() {
        return this.permissions.talents;
    }

    get mpoSatellite() {
        return this.permissions.satellite;
    }

    get cai() {
        return this.permissions.ra;
    }

    get ra() {
        return this.permissions.ra;
    }

    get isDiscretionary() {
        return _.get(this, 'clientAccount.discretionary');
    }

    get subAccountFromUrl() {
        let subAccFromUrl = this.router.url.split('subaccount/')[1];
        if (subAccFromUrl) {
            let subAccFromUrlArr = subAccFromUrl.split('#');
            subAccFromUrl = subAccFromUrlArr[0];
        } else {
            subAccFromUrl = this.masterSubAccountId;
        }
        return subAccFromUrl;
    }

    get accountStatus() {
        return _.get(this, 'clientAccount.status');
    }

    get purchases() {
        return _.get(this, 'clientAccount.purchases');
    }

    get productInformation() {
        return _.get(this, 'clientAccount.productInformation');
    }

    set purchases(purchases) {
        this.clientAccount.purchases = purchases;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get departments() {
        return _.get(this, 'clientAccount.departments');
    }

    set departments(departments) {
        this.clientAccount.departments = departments;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get users() {
        return _.get(this, 'clientAccount.users');
    }

    set users(users) {
        this.clientAccount.users = users;
        this.storeServiceData('accountService', this.persistentVariables, this);
    }

    get sessionPermissions() {
        return this.stateService.session.sessionData.permissions;
    }

    get roles() {
        return this.sessionPermissions.roles;
    }

    get canDeleteAccount() {
        return (this.roles.includes('ROLE_SUPER_ADMIN'));
    }

    get canDeleteSubAccount() {
        return (
            this.roles.includes('ROLE_MAIN_ADMIN') ||
            this.roles.includes('ROLE_ADMIN') ||
            this.roles.includes('ROLE_SUPER_ADMIN')
        );
    }

    get accountName() {
        return this.subAccounts[0].name;
    }

    get subsidiaryName() {
        return (this.subsidiary) ? this.subsidiary.name : 'N/A';
    }

    get distributorName() {
        return (this.distributor) ? this.distributor.name : 'N/A';
    }

    get partnerName() {
        return (this.partner) ? this.partner.name : 'N/A';
    }

    get parentName() {
        return (this.parent) ? this.parent.name : 'N/A';
    }

    get isClientOrDemo() {
        return this.clientAccount.accountType === 'client' || this.clientAccount.accountType === 'demo';
    }

    get mpoProducts() {
        let mpoProducts = {
            adoptionDate: '',
            renewalDate: '',
            dropDate: '',
            dropDateNote: '',
            administrator: ''
        };
        for (let field of FormsHelper.fieldsName(FormsHelper.mpoProducts)) {
            if (this.productInformation && this.productInformation[field]) {
                if (this.productInformation[field]['sec']) {
                    mpoProducts[field] = {
                        'fr': moment(this.productInformation[field]['sec'] * 1000).format(this.DATE_FORMAT_FR),
                        'en': moment(this.productInformation[field]['sec'] * 1000).format(this.DATE_FORMAT_EN)
                    };
                } else if (
                    this.productInformation[field].length === 10 &&
                    this.productInformation[field].replace(/[^-]/g, '').length === 2
                ) {
                    const dateArr = this.productInformation[field].split('-');
                    mpoProducts[field] = {
                        'fr': dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0],
                        'en': dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0]
                    };
                } else {
                    mpoProducts[field] = this.productInformation[field] ?? '';
                }
            } else {
                mpoProducts[field] = '';
            }
        }
        return mpoProducts;
    }

    get adoptionDate() {
        return this.mpoProducts.adoptionDate ?? '';
    }

    get renewalDate() {
        return this.mpoProducts.renewalDate ?? '';
    }

    get dropDate() {
        return this.mpoProducts.dropDate;
    }

    get dropDateNote() {
        return this.mpoProducts.dropDateNote;
    }

    get addressInfo() {
        let addressInfo = {};
        for (let field of FormsHelper.fieldsName(FormsHelper.addressInfo)) {
            if (this.subAccounts[0].addressInfo && this.subAccounts[0].addressInfo[field]) {
                addressInfo[field] = this.subAccounts[0].addressInfo[field];
            } else {
                if (this.subAccounts[0].hasOwnProperty(field)) {
                    addressInfo[field] = this.subAccounts[0][field];
                } else {
                    addressInfo[field] = '';
                }
            }
        }
        return addressInfo;
    }

    get creationDateRaw() {
        return this.accountToDisplay?.subAccounts[0]?.creationDate;
    }

    get creationDemoDate() {
        return this.accountToDisplay.subAccounts[0].creationDemoDate ?? 'NA';
    }

    get formatedDemoDates() {
        // Format demo expiration date (only for demo)
        let demoExpiresAt = null;
        let demoExpiresAtLocal = {
            fr: undefined,
            en: undefined
        };
        if (this.accountType === 'demo' && this.productInformation.demoExpirationDate) {
            demoExpiresAt = moment(this.productInformation.demoExpirationDate).format('YYYY-MM-DD');
            demoExpiresAtLocal.fr = moment(this.expiresAt).format(this.DATE_FORMAT_FR);
            demoExpiresAtLocal.en = moment(this.expiresAt).format(this.DATE_FORMAT_EN);
        }
        return {
            expiresAt: demoExpiresAt,
            expiresAtLocal: demoExpiresAtLocal
        };

    }

    get employeesNumber() {
        console.log(this.subAccounts[0].employeesNumber);
        return this.subAccounts[0].employeesNumber;
    }

    get formatedCreationDates() {
        let clientSince;
        let creationDate;
        if (
            this.creationDateRaw
        ) {
            if (this.creationDateRaw.sec) {
                clientSince = this.since(this.creationDateRaw, false);
                // since masterAccountData.creationDate is redefined, the order of the preceeding two blocks is important.
                creationDate = FormsHelper.dateFormatFromArray(
                    this.creationDateRaw,
                    this.translateService.currentLang,
                    (this.translateService.currentLang === 'fr') ? 'fr-CA' : 'en-CA'
                );
            } else {
                clientSince = this.since(this.creationDateRaw);
                creationDate = FormsHelper.dateFormatFromArray(this.creationDateRaw, this.translateService.currentLang);
            }
        } else {
            creationDate = 'NA';
        }
        return {
            clientSince: {
                en: clientSince + ' days',
                fr: clientSince + ' jours',
            },
            creationDate: creationDate
        };
    }

    get creationDate() {
        return this.formatedCreationDates.creationDate;
    }

    get clientSince() {
        return this.formatedCreationDates.clientSince;
    }
}
