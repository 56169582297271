import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input, OnInit, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { Subject, Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PeopleSurveyRequestHelper } from '../../people-survey-request.helper';
import { PeopleSurveyRequestFormService} from '../../../../../services/people-survey-request-form.service';
import { PeopleRequestSendModalCommon } from 'src/app/people/components/commonClasses/people-request-send-modal-common';
import { StateService } from 'src/app/core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiJobsService } from 'src/app/jobs/services/api-jobs.service';
import { ApiAdministrationService } from 'src/app/administration/services/api-administration.service';
import { TestAndReportHandlingService } from '../../../../../services/test-and-report-handling.service';

@Component({
    selector: 'app-people-survey-request-info',
    templateUrl: './survey-request-info.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestInfoComponent extends PeopleRequestSendModalCommon implements OnInit, OnDestroy {
    @Input() surveyRequestFormGroup: FormGroup;
    @Input() sessionData: any;
    @Input() isFormSubmitted: boolean;
    @Input() iacModal = false;
    @Input() mpoModal = false;

    private onDestroy$: Subject<null> = new Subject();
    protected subscriptions = new Subscription();
    public recordTypeList: any[];
    public subAccountList: any[];
    public departmentList: any[];
    public subAccountSelected: any;
    public users: any;
    public usersCount = 0;
    public jobList$: Observable<any>;
    public jobs: any;
    public jobsCount = 0;
    public trackById: Function;

    constructor(
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        protected cd: ChangeDetectorRef,
        protected translateService: TranslateService,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        public stateService: StateService,
        protected fb: FormBuilder,
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(apiJobsService, apiAdministrationService, cd, translateService, testAndReportHandlingService, stateService, fb);
    }

    ngOnInit() {
        let recordTypeListBilingual = {
            'fr': [
                {
                    'name': 'Candidat',
                    'value': 'candidate'
                },
                {
                    'name': 'Employé',
                    'value': 'employee'
                }
            ],
            'en': [
                {
                    'name': 'Candidate',
                    'value': 'candidate'
                },
                {
                    'name': 'Employee',
                    'value': 'employee'
                }
            ]
        };

        this.trackById = PeopleSurveyRequestHelper.trackById;

        this.recordTypeList = recordTypeListBilingual[this.translateService.currentLang];

        this.subAccountList = this.sessionData.structure.subAccounts;
        this.departmentList = this.sessionData.structure.departments;

        this.jobList$ = this.apiJobsService.jobs([{'fields': 'id,jobTitle', 'limit': 500, 'order[date]': 'desc'}]);
        this.jobList$.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                this.jobs = res.data;
                this.jobsCount = res.count;
            }
        );
        this.getSendingTypesList(false, true);
        this.peopleRequestFormService.subAccountSelected$.pipe(takeUntil(this.onDestroy$)).subscribe(
            (res) => {
                this.subAccountSelected = res;
                this.getUsers();
            });
    }

    get account() {
        if (this.sessionData && this.sessionData.accountData) {
            return this.sessionData.accountData;
        }
        return;
    }

    get isSender() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isSender;
        }
        return false;
    }

    getUsers() {
        let params: any = {
            limit: 100,
            order: {firstName: 'asc'},
            accountNumber: [this.account.accountNumber],
            subAccount: [this.subAccountSelected]
        };
        this.subscriptions.add(this.apiAdministrationService.users([params]).subscribe(res => {
            for (let datum of res.data) {
                if (this.account.subAccounts && datum && datum['principalSubAccount']) {
                    const subAccountById: any = this.account.subAccounts.find((subAccount: any) => subAccount.id === datum['principalSubAccount']);
                    datum['principalSubAccountName'] = subAccountById ? subAccountById.name : '';
                }
            }
            if (this.subAccountSelected !== null) {
                this.users = res.data;
                this.usersCount = res.count;
            } else {
                this.users = null;
                this.usersCount = 0;
            }

            this.changeDetectorRef.markForCheck();
        }));
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined  && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
        this.subscriptions.unsubscribe();
    }
}
