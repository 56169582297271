<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'admin.commons.deleteSubAccount' | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right" style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<form [formGroup]="deleteSubAccountForm" (ngSubmit)="onSubmit()" #deleteSubAccountNgForm="ngForm">
    <div class="modal-body">
        <div class="form-group">
            <input id="deleteTrue" type="radio" formControlName="delete" value="1" (change)="deleteChange()"/>
            &nbsp;<label for="deleteTrue">{{ 'commons.delete' | translate }}</label> &nbsp;

            <input id="deleteFalse" type="radio" formControlName="delete" value="0" (change)="deleteChange()"/>
            &nbsp;<label for="deleteFalse">{{ 'admin.commons.move' | translate }}</label>

            <br/>
            <label for="moveToSubAccount">{{ 'commons.subAccount' | translate }}</label>
            <select class="form-control" id="moveToSubAccount"
                    formControlName="moveToSubAccountId"
            >
                <option value="">{{ 'commons.select' | translate }}</option>
                <option *ngFor="let subAcct of subAccounts" [value]="subAcct.id">
                    {{ subAcct.name }}
                </option>
            </select>

            <br/>
            <mat-checkbox formControlName="deleteFiles">{{ 'admin.commons.deleteFiles' | translate }}</mat-checkbox>
        </div>
    </div>
    <div class="modal-footer">
        <div class="pull-right" style="margin-left: 10px">
            <button
                type="submit"
                class="modalButton pull-right"
                [disabled]="!canSubmit()"
            >
                {{ 'commons.save' | translate }}
            </button>
            <button type="button" (click)="closeModal(!deleteSubAccountNgForm)" class="modalButton pull-right"
                    style="margin-right: 10px">
                {{ 'commons.cancel' | translate }}
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
</form>
