<h2>{{ 'people.reportMpo.infoOnMpo' | translate }}</h2>

<div *ngIf="report" [class.mobileReport]="mobile">

    <ul class="plainList">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ person.personalityDateToDisplay }}</li>
        <li *ngIf="person.personalityRequestedByInfo && person.personalityRequestedByInfo.fullName">
            {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> {{ person.personalityRequestedByInfo.fullName }}
        </li>
        <li *ngIf="person.personalityTimeSpent && person.personalityTimeSpent.size == 3">
            {{ 'people.commons.elapsedTime' | translate }}<span [innerHTML]="titleSep"></span>
            S1<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[0] }} -
            S2<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[1] }} -
            Total<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[2] }}
        </li>
        <li *ngIf="person.personalityTimeSpent && person.personalityTimeSpent.size == 5">
            {{ 'people.commons.time' | translate }}<span [innerHTML]="titleSep"></span>
            Total<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[4] }} -
            Section 1<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[0] }} -
            Section 2<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[1] }} -
            Section 3<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[2] }} -
            Section 4<span [innerHTML]="titleSep"></span> {{ person.personalityTimeSpent.data[3] }}
        </li>
        <li>{{ 'people.commons.norm' | translate }}<span [innerHTML]="titleSep"></span> {{ person.personalityNorm }}</li>
        <li *ngIf="person.personalityMethod">
            {{ 'people.commons.method' | translate }}<span [innerHTML]="titleSep"></span>
            <span *ngIf="person.personalityMethod === 0"> {{ 'people.commons.paperQuestionnaire' | translate }}</span>
            <span *ngIf="person.personalityMethod === 1"> {{ 'people.commons.onlineQuestionnaire' | translate }}</span>
        </li>
    </ul>
    <hr/>

    <h2 style="margin-top: 15px">{{ 'commons.personality' | translate }}</h2>

    <!-- Traits Summary -->

    <h2 class="h2personalityReport" style="margin-top: 15px">{{ 'people.reportMpo.traitSummary' | translate }}</h2>
    <hr/>
    <em>{{ 'people.reportMpo.traitSummaryIntro' | translate }}</em>
    <hr/>
    <div>
        <div id="traitSummary" *ngFor="let trait of traitsSummary">
            <div>
                <h4 class="traitsTitle traitsTitlePersonality" style="margin: 19px 0; padding: 0">{{
                    textFields[translate.currentLang][trait[0]].summary }}</h4>
                <app-trait-graph
                        [part]="'trait'"
                        [trait]="trait[0]"
                        [lang]="translate.currentLang"
                        [trait1Score]="person.personalityScores[trait[0]]"
                        [mobile]="mobile"
                        [width]="width"
                ></app-trait-graph>
            </div>
            <p class="georgia">{{ trait[1] }}<span *ngIf="trait[2] && translate.currentLang=='fr'">, et
            </span><span *ngIf="trait[2] && translate.currentLang=='en'">, and</span>
            </p>
            <ul class="georgia" *ngIf="trait[2]">
                <li *ngFor="let traitList of trait[2]">
                    {{ traitList }}
                </li>
            </ul>
        </div>
    </div>
    <!-- Predictable behaviour -->
    <h2 class="h2personalityReport" style="margin-top: 15px">{{ 'people.reportMpo.predictableBehavior' | translate }}</h2>
    <hr/>
    <em>{{ 'people.reportMpo.predictableBehaviorIntro' | translate }}</em>
    <hr/>
    <div>
        <div id="predictableBehavior" *ngFor="let interaction of predictableBehavior">
            <div class="reportMiniGraphHeader">
                <div style="float:left">
                    <app-mini-mpo-graph
                            [id]="interaction[0][0]+'on'+interaction[0][1]"
                            [score]="person.personalityScores"
                            [interaction]="interaction[0]"
                    ></app-mini-mpo-graph>
                </div>
                <div class="reportMiniGraphTitle">
                    <h4 class="innerTitleH4">{{interaction[0][0]}}/{{interaction[0][1]}}</h4>
                    <h5>{{ 'people.reportMpo.relationBetween' | translate }}
                        {{ textFields[translate.currentLang][interaction[0][0]].predictableBehavior }}
                        {{ 'people.reportMpo.and' | translate }}
                        {{ textFields[translate.currentLang][interaction[0][1]].predictableBehavior }}
                    </h5>
                </div>
                <div style="clear: both"></div>
            </div>
            <p class="georgia" *ngFor="let paragraph of interaction[1]">
                {{ paragraph }}
            </p>
        </div>
    </div>


    <!-- Profile -->
    <h2 class="h2personalityReport" style="margin-top: 15px">{{ 'people.reportMpo.portrait' | translate }}</h2>
    <hr/>
    <em>{{ 'people.reportMpo.portraitIntro' | translate }}</em>
    <hr/>
    <div id="portrait" *ngIf="profile">
        <h3 class="ngorange">{{ testAndReportHandlingService.renderPortrait(this.person.portrait, true) | translate }}</h3>
        <p class="georgia">{{ profile.OR }}</p>
        <p class="georgia">{{ profile.SE }}</p>
        <p class="georgia" *ngFor="let paragraph of profile.par">
            {{ paragraph }}
        </p>
        <h3 class="ngorange subTitlesUpperCase">{{ 'commons.leadStyle' | translate }}</h3>
        <ul class="georgia sublistSpacing">
            <li *ngFor="let item of profile.lead">
                {{ item }}
            </li>
        </ul>
        <h3 class="ngorange subTitlesUpperCase">{{ 'commons.commStyle' | translate }}</h3>
        <ul class="georgia sublistSpacing">
            <li *ngFor="let item of profile.comm">
                {{ item }}
            </li>
        </ul>

        <h3 class="ngorange subTitlesUpperCase">{{ 'commons.workEnvironment' | translate }}</h3>
        <ul class="georgia sublistSpacing">
            <li *ngFor="let item of profile.wenv">
                {{ item }}
            </li>
        </ul>

        <h3 class="ngorange subTitlesUpperCase">{{ 'commons.motivationalNeeds' | translate }}</h3>
        <ul class="georgia sublistSpacing">
            <li *ngFor="let item of profile.motv">
                {{ item }}
            </li>
        </ul>
    </div>
    <div>
        <h2 class="h2personalityReport" style="margin-top: 15px">{{ extraPoints['title'] }}</h2>
        <hr/>
        <p *ngFor="let paragraph of extraPoints['headerMain']" style="padding-bottom: 0">
        <em>{{ paragraph }}</em>
        </p>
        <hr style="margin-bottom: 10px"/>
        <div>
            <h3 class="traitsTitlePersonality ngorange">{{ extraPoints['pv']['title'] }}</h3>
            <p *ngFor="let paragraph of extraPoints['pv']['header']">
                <em>{{ paragraph }}</em>
            </p>
            <ul class="sublistSpacing">
                <li *ngFor="let point of extraPoints['pv']['points']">
                    {{ point }}
                </li>
            </ul>
        </div>
        <div>
            <h3 class="traitsTitlePersonality ngorange">{{ extraPoints['pc']['title'] }}</h3>
            <p *ngFor="let paragraph of extraPoints['pc']['header']">
                <em>{{ paragraph }}</em>
            </p>
            <ul class="sublistSpacing">
                <li *ngFor="let point of extraPoints['pc']['points']">
                    {{ point }}
                </li>
            </ul>
        </div>
        <div>
            <h3  class="traitsTitlePersonality ngorange">{{ extraPoints['pm']['title'] }}</h3>
            <p *ngFor="let paragraph of extraPoints['pm']['header']">
                <em>{{ paragraph }}</em>
            </p>
            <ul class="sublistSpacing">
                <li *ngFor="let point of extraPoints['pm']['points']">
                    {{ point }}
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="testAndReportHandlingService.currentPrb.exists">
        <h2 class="h2personalityReport">{{ 'people.reportMpo.prb' | translate }}</h2>
        <hr/>
        <em>{{ 'people.reportMpo.prbIntro' | translate}}</em>
        <br/>
        <span style="color: rgb(227, 94, 36); font-weight: bolder">▬</span>&nbsp;{{ 'commons.traits' | translate}}&nbsp;&nbsp;&nbsp;<span
        style="color: rgb(30.0%,30.0%,30.0%); font-weight: bolder">▬</span>&nbsp;{{ 'commons.behavior' | translate}}
        <hr/>
        <div>
            <div id="prb" *ngFor="let trait of report['prb']; let i=index">
                <div>
                    <h4 class="traitsTitle traitsTitlePersonality">{{ textFields[translate.currentLang][traits[i]].summary }}</h4>
                    <div>
                        <app-trait-graph
                                [part]="'prb'"
                                [trait]="trait[0]"
                                [lang]="translate.currentLang"
                                [trait1Score]="person.personalityScores[traits[i]]"
                                [trait2Score]="person.prbScores[traits[i]]"
                                [mobile]="mobile"
                                [width]="width"
                        ></app-trait-graph>
                    </div>
                </div>
                <p class="georgia">{{ trait[1].text.int }}</p>
                <ul class="georgia">
                    <ng-template ngFor let-item [ngForOf]="trait[1].text.pf">
                        <li *ngIf="(item!=='')">
                            {{ item }}
                        </li>
                    </ng-template>
                </ul>
                <p class="georgia">
                    {{ trait[1].pf }}
                </p>
                <p *ngIf="trait[1].text.cl">
                    <i>{{ trait[1].text.cl }}</i>
                </p>
            </div>
        </div>
    </div>
    <div *ngIf="warnings">
        <div>
            <div *ngIf="translate.currentLang=='fr'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicateurs de questionnaire invalide
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicateur de questionnaire invalide
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>L’indicateur de scores extrêmes est positif.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>L’indicateur d’endossement est positif.</em></p>
                <p>
                    La présence d’un ou deux indicateurs de questionnaire invalide indique qu’il est raisonnable
                    de remettre en doute la validité du résultat. Cependant, il revient à l’utilisateur de décider si
                    le questionnaire du candidat est valide ou si ce dernier doit le refaire.
                </p>
            </div>
            <div *ngIf="translate.currentLang=='en'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicators of an invalid questionnaire
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicator of an invalid questionnaire
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>Extreme Scores Indicator is positive.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>Endorsement Indicator is positive.</em></p>
                <p>
                    The presence of an indicator indicates that it is reasonable to believe that the survey results are
                    invalid. However, the analyst must decide whether the candidate’s questionnaire is valid or if
                    it must be redone.
                </p>
            </div>
        </div>
        <div style="height: 40px">&nbsp;</div>
    </div>
</div>

<button  (click)="moveTo('top')">
    <div id="upButton">
        <img src="assets/buttons/chevronUp.png"/>
    </div>
</button>
