<div class="modal-header">
    <h4 class="modal-title pull-left fontLight"
        [translate]="'jobs.multiselectAction.transferBetween'"
        [translateParams]="{'location':location}">
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<div class="modal-body">
    <ng-container *ngIf="multiselectData['actionType'] == 'transferOtherAcc'">
        <h2 *ngIf="!isAccountsLoaded">
            {{ 'commons.waitingForResults' | translate }}
        </h2>
        <ngx-loading [show]="!isAccountsLoaded"
                     [config]="{
                     backdropBackgroundColour: 'rgba(255, 255, 255, 0.3)',
                     primaryColour: '#CDCDCD',
                     secondaryColour: '#ABABAB',
                     tertiaryColour: '#CDCDCD'
                     }"
        ></ngx-loading>
        <h2 *ngIf="isAccountsLoaded"
            [translate]="'jobs.multiselectAction.accountDestination'"
            [translateParams]="{
            'multiDataIdLength': multiselectData['ids'].length,
            'plural': (multiselectData['ids'].length > 1) ? 's' : ''
            }"
        ></h2>
        <label class="search-wrap" *ngIf="isAccountsLoaded">
            <input [placeholder]="'commons.typeHereToSearch' | translate"
                   [(ngModel)]="container"
                   (keydown)="onSearch($event)"
                   [typeahead]="items"
                   [typeaheadOptionField]="'accountName'"
                   [typeaheadMinLength]="2"
                   (typeaheadOnSelect)="selectedItem($event)"
                   class="form-control"
            />
            <img *ngIf="!container" alt="" src="{{iconsFolder}}search_and_filters/ico_search.png" />
            <img *ngIf="container" alt="" class="closeX" src="{{buttonsFolder}}close_hover.png" (click)="clearSearch()" />
        </label>
        <div class="clickSaveToConfirm">
            <span *ngIf="selectedAccountNumber">{{ 'commons.clickSaveToConfirm' | translate }}</span> &nbsp;
        </div>
    </ng-container>
    <ng-container *ngIf="multiselectData['actionType'] == 'subaccount'">
        <h2
                [translate]="'jobs.multiselectAction.subAccountDestination'"
                [translateParams]="{'multiDataIdLength': multiselectData['ids'].length}"
        ></h2>
        <label style="width: 100%;">
            <select class="form-control" [(ngModel)]="container">
                <option>{{ 'commons.select' | translate }}</option>
                <option *ngFor="let subAcct of subAccounts" [value]="subAcct.id">
                    {{ subAcct.name }}
                </option>
            </select>
        </label>
    </ng-container>
    <ng-container *ngIf="multiselectData['actionType'] == 'department'">
        <h2
                [translate]="'jobs.multiselectAction.departmentDestination'"
                [translateParams]="{'multiDataIdLength': multiselectData['ids'].length}"
        ></h2>
        <label style="width: 100%;">
            <select class="form-control" [(ngModel)]="container">
                <option>{{ 'commons.select' | translate }}</option>
                <option *ngFor="let dpt of departments" [value]="dpt.id">
                    {{ dpt[currentLang] }}
                </option>
            </select>
        </label>
    </ng-container>
</div>
<div class="modal-footer">
    <button (click)="closeModal()" class="modalButton">{{ 'commons.cancel' | translate }}</button>&nbsp;&nbsp;
    <button (click)="transferReports()" [disabled]="!selectedAccountNumber" class="modalButton">{{ 'commons.save' | translate }}</button>
</div>
