
<div class="modal-header">
    <h4 class="modal-title pull-left">{{ modalTitle | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right" style="font-size: 20pt;"> × </div>
</div>
<div class="modal-body">
    <div class="headerText" *ngIf="headerText">
        {{ headerText | translate }}
    </div>
    <div *ngIf="peopleList && !headerText">
        <h4>{{ 'app.navigation.people' | translate }}</h4>
        <ul>
            <li *ngFor="let people of peopleList">
                {{ people.name }}
            </li>
        </ul>
        <hr />
    </div>

    <div *ngIf="analysisList">
        <label [ngClass]="(isSubmitted && selectedAnalysis === '') ? 'text-danger' : ''">{{ 'commons.graphAnalysis' | translate }}
            <select class="form-control" [(ngModel)]="selectedAnalysis">
                <option value="">{{ 'analysisCommons.selectAnalysis' | translate }}</option>
                <option *ngFor="let analysis of analysisList" [value]="analysis.id">
                    {{ analysis.name }}
                </option>
            </select>
        </label>
    </div>
</div>

<div class="modal-footer">
    <button
        type="button"
        class="modalButton pull-right"
        style="margin-top: 15px"
        (click)="save()"
    >
        <span>{{ 'commons.add' | translate }}</span>
    </button>

    <button
        type="button"
        class="modalButton pull-right"
        style="margin-top: 15px; margin-right: 15px"
        (click)="closeModal()"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <div class="clearfix"></div>
</div>
