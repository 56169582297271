<div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="translate.currentLang=='fr'">
        {{ 'commons.filters' | translate }}
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body" *ngIf="filters">
    <h3>{{ 'commons.filters' | translate }}</h3>
    <div style="width: 49%" class="pull-left">
        <p class="flat">{{ 'commons.start' | translate }}</p>
        <my-date-picker
            [locale]="translate.currentLang"
            [options]="datePickerOptions[translate.currentLang]"
            [(ngModel)]="filters.startDate"
            (dateChanged)="setDateFilters($event, 'startDate')"
        ></my-date-picker>
    </div>
    <div style="width: 49%" class="pull-right">
        <p class="flat">{{ 'commons.end' | translate }}</p>
        <my-date-picker
            [locale]="translate.currentLang"
            [options]="datePickerOptions[translate.currentLang]"
            [(ngModel)]="filters.endDate"
            (dateChanged)="setDateFilters($event, 'endDate')"
        ></my-date-picker>
    </div>
    <div class="clearfix"></div>
    <p class="flat" style="margin-top: 10px">{{ 'commons.subAccount' | translate }}</p>
    <div>
        <select
            class="form-control"
            [(ngModel)]="filters.subAccount"
        >
            <option [value]="null">
                {{ 'commons.all' | translate }}
            </option>
            <option
                *ngFor="let subAccount of this.accountService.subAccounts"
                [value]="subAccount.id"
            >
                {{ subAccount.name }}
            </option>
        </select>
    </div>
    <p class="flat" style="margin-top: 10px">{{ 'commons.transactionType' | translate }}
        (<a (click)="selectAllTransactions()">{{ 'commons.toutes' | translate }}</a> | <a (click)="selectNoTransaction()">{{ 'commons.aucune' | translate }}</a>)
    </p>
    <div style="width: 49%" class="pull-left">
        <ng-container *ngFor="let filter of transactionKeys; let i = index">
            <ng-container *ngIf="i + 1 <= Math.ceil(transactionKeys.length / 2)">
                <input type="checkbox" name="sortBy" value="displayTalents"
                       [(ngModel)]="filters.selectedTransactions[filter]"/>
                {{ 'commons.' + filter | translate }}
                <br *ngIf="i != Math.ceil(transactionKeys.length / 2)">
            </ng-container>
        </ng-container>
    </div>
    <div style="width: 49%" class="pull-right">
        <ng-container *ngFor="let filter of transactionKeys; let i = index">
            <ng-container *ngIf="i + 1 > Math.ceil(transactionKeys.length / 2)">
                <input type="checkbox" name="sortBy" value="displayTalents"
                       [(ngModel)]="filters.selectedTransactions[filter]"/>
                {{ 'commons.' + filter | translate }}
                <br *ngIf="i != transactionKeys.length">
            </ng-container>
        </ng-container>
    </div>
    <div class="clearfix"></div>
</div>
<div class="modal-footer">
    <div>
        <button type="submit" class="modalButton pull-right" (click)="saveFilters()"
                style="margin-top: 15px">
            {{ 'commons.save' | translate }}
        </button>
    </div>
    <div>
        <button type="submit" class="modalButton pull-right" (click)="resetFilters()"
                style="margin-top: 15px; margin-right: 15px">
            {{ 'commons.reset' | translate }}
        </button>
    </div>
    <div>
        <button type="submit" class="modalButton pull-right" (click)="closeModal()"
                style="margin-top: 15px; margin-right: 15px">
            {{ 'commons.cancel' | translate }}
        </button>
    </div>
    <div class="clearfix"></div>
</div>
