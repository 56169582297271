<div class="sticky" *ngIf="!isLandscape || showPortraitButtons" style="overflow-y: hidden">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <h2 *ngIf="jobService.id" class="truncate name">{{
                jobService.jobTitle ? jobService.jobTitle.toUpperCase() : '' }}</h2>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="!editRequest"
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest && !sendQByEmail && !resendQ && !activationRequestP && !activationRequestC && !fillQuickRA"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest || sendQByEmail || resendQ || activationRequestP || activationRequestC || fillQuickRA"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels(null)"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                [disabled]="stateService.session.hasSatelliteRole || jobIsBlank()"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="jobEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}</button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr" />
</div>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 *ngIf="jobService.id" class="truncate name">{{
        jobService.jobTitle  ? jobService.jobTitle .toUpperCase() : '' }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="pdfRequest" class="fullView-wrapper-scrollfix" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-jobs-pdf
        [raPermission]="raPermission"
        (close)="closeAllRequestsPanels(null)"
    ></app-mobile-jobs-pdf>
</div>
<div *ngIf="editRequest" class="fullView-wrapper-scrollfix edit-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-jobs-edit
        [status]="'edit'"
        [reportType]="getProperty(state.report, 'reportType')"
        (close)="closeAllRequestsPanels($event)"
    ></app-mobile-jobs-edit>
</div>
<div *ngIf="sendQByEmail"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <app-mobile-jobs-profiler-email
        [profilerEmailData]="profilerEmailData"
        (close)="closeAllRequestsPanels(null)"
    ></app-mobile-jobs-profiler-email>
</div>
<div *ngIf="resendQ"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <app-mobile-jobs-profiler-request-resend
        [resendData]="resendData"
        (close)="closeAllRequestsPanels(null)"
    ></app-mobile-jobs-profiler-request-resend>
</div>
<div *ngIf="activationRequestP"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <app-mobile-jobs-profiler
        [status]="actStatus"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-jobs-profiler>
</div>
<div *ngIf="activationRequestC"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
>
    <app-mobile-jobs-competences
        [status]="actStatus"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-jobs-competences>

</div>
<div *ngIf="fillQuickRA" class="fullView-wrapper-scrollfix" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-jobs-iac
        [status]="actStatus"
        (close)="closeAllRequestsPanels()"
        (saveIacScore)="saveIacScore($event)"
    ></app-mobile-jobs-iac>
</div>
<div *ngIf="!pdfRequest && !editRequest && !sendQByEmail && !resendQ && !activationRequestP && !activationRequestC && !fillQuickRA"
     class="fullView-wrapper-scrollfix"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
     style="overflow-y: hidden"
>
    <div class="informationPanel">
        <mat-expansion-panel [expanded]="expandInfoPanel">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable" *ngIf="jobService.id">
                <tr>
                    <td><strong>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span>&nbsp;</strong>
                        {{ jobService.creationDate | date:jobDateFormat }}
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.location' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!jobService.location">
                            {{ jobService.location || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!setSubAccountName(jobService.subAccount)">
                            {{ setSubAccountName(jobService.subAccount) || notSpecified()  }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.division' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!jobService.department">
                            {{ jobService.department || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!jobService.requiredBy">
                            {{ jobService.requiredBy || notSpecified()}}
                        </span>
                    </td>
                </tr>
                <tr *ngIf="jobService.reportsTo">
                    <td><strong>{{ 'jobs.report.reportsTo' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span>
                            {{ jobService.reportsTo }}
                        </span>
                    </td>
                </tr>
                <tr *ngIf="tasksList || (jobService.jobDescription && jobService.purposeOfThePosition)">
                    <td>
                        <div *ngIf="jobService.purposeOfThePosition">
                            {{ 'jobs.commons.purposeOfThePosition' | translate }}<span [innerHTML]="titleSep"></span>
                            <span>{{ jobService.purposeOfThePosition }}</span>
                        </div>
                        <table *ngIf="tasksList" id="princResp">
                            <thead>
                            <tr>
                                <th class="bd" colspan="2">{{ 'jobs.report.principalResponsibilities' | translate }}</th>
                                <th class="bd">{{ 'jobs.commons.timePercentage' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let resp of jobTasks">
                                <td class="bd" style="font-weight: bold; width: 5%">{{ resp.priority }}</td>
                                <td style="width: 68%">{{ resp['responsibility'] }}</td>
                                <td style="text-align: center; width: 27%">{{ resp['percentage'] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
    </div>
    <div id="MPOSideBarPresentation" class="graph-container">
        <ng-template *ngIf="state.report['reportType'] == 'talents'">
            <ng-container *ngIf="hasTalentsScores">
                <table id="talentsDisplay">
                    <ng-container *ngFor="let i of createRange(actualPage); let count = index;">
                        <ng-container *ngIf="orderedTalents[translate.currentLang][i]">
                            <tr>
                                <td>{{orderedTalents[translate.currentLang][i][0]}}</td>
                                <td>
                                    <span *ngIf="orderedTalents[translate.currentLang][i][1]==1"
                                          style="color: #efce7c">⬤</span>
                                    <span *ngIf="orderedTalents[translate.currentLang][i][1]==2"
                                          style="color: #78c7af">⬤</span>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="orderedTalents[translate.currentLang] &&
                            orderedTalents[translate.currentLang].length > maxPerPage &&
                            actualPage > 1 &&
                            (count+1-getRestItems(orderedTalents[translate.currentLang].length, actualPage)) > 0
                            "
                        >
                            <tr>
                                <td colspan="2">&nbsp;</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td class="justifyLeft alignBottom">
                            <mat-grid-list cols="2" rowHeight="25px">
                                <mat-grid-tile
                                    colspan="1"
                                    rowspan="1"
                                >
                                    <span style="color: #78c7af">⬤</span>
                                    {{ 'jobs.modal.competenceMasteryOption' | translate }}
                                </mat-grid-tile>
                                <mat-grid-tile
                                    colspan="1"
                                    rowspan="1"
                                >
                                    <span style="color: #efce7c">⬤</span>
                                    {{ 'jobs.modal.competenceFuncOption' | translate }}
                                </mat-grid-tile>
                            </mat-grid-list>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="talentsPagination">
                            <hr class="panelHr"/>
                            <div class="justifyRight">
                                <mat-grid-list cols="1" rowHeight="25px">
                                    <mat-grid-tile
                                        colspan="1"
                                        rowspan="1"
                                    >
                                        <div *ngIf="actualPage > 1" (click)="goToPage(-1)"><img alt="<" src="{{buttonsFolder}}before.png"></div>
                                        <div *ngIf="actualPage == 1"><img alt="<" src="{{buttonsFolder}}before_deactivated.png"></div>
                                        <div *ngIf="actualPage < maxPage" (click)="goToPage(1)"><img alt=">" src="{{buttonsFolder}}next.png"></div>
                                        <div *ngIf="actualPage == maxPage"><img alt=">" src="{{buttonsFolder}}next_deactivated.png"></div>
                                    </mat-grid-tile>
                                </mat-grid-list>
                            </div>
                        </td>
                    </tr>
                </table>
            </ng-container>
            <ng-container *ngIf="!hasTalentsScores">
                <div style="height: 350px">
                    <p style="padding: 10px 0 0 5px;">
                        {{ 'jobs.sidebar.noCompetencyProfileForJob' | translate }}
                    </p>
                </div>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="reportType === 'ra' && raPermission">
            <h2 class="bold hide">{{ 'jobs.reportRa.targetTitle' | translate }}</h2>
        </ng-container>
    </div>
    <div class="reportDescription" *ngIf="state.report.display === 'report' && jobService.id">
        <div *ngIf="mpoScores && jobService.score.length > 0 && reportType === 'personality'">
            <app-jobs-report-mpo
                [mobile]="true"
                (openModQuickProfiler)="modQuickProfiler()"
            ></app-jobs-report-mpo>
        </div>
        <div *ngIf="(!mpoScores || jobService.score.length === 0) && reportType === 'personality'">
            <em>{{ 'commons.personalityTraitsJob' | translate }}</em>
            <app-mpo-graph
                [id]="'traits'"
                [mobile]="true"
                [scores]="mpoScores"
                [lang]="translate.currentLang"
            ></app-mpo-graph>
        </div>
        <div *ngIf="jobService.competences && jobService.competences.length > 0 && reportType === 'talents'">
            <app-jobs-report-talents
                [mobile]="true"
                (openModTalent)="modTalent()"
            ></app-jobs-report-talents>
        </div>
        <div *ngIf="jobService.raScore && reportType === 'ra' && raPermission">
            <app-jobs-report-iac
                (openModRa)="modRa()"
            ></app-jobs-report-iac>
        </div>
    </div>
</div>
<div
    *ngIf="(!pdfRequest && !editRequest && !sendQByEmail && !resendQ && !activationRequestP && !activationRequestC && !fillQuickRA) && (!isLandscape || showPortraitButtons)"
    class="sticky footer justifyCenter"
>
    <hr (click)="hidePortraitButtons()" class="panelHr" />
    <div class="mobileFooter">
        <mat-grid-list [cols]="colsNumber" rowHeight="62px">
            <mat-grid-tile *ngIf="stateService.hasPersonality" colspan="1" rowspan="1">
                <div *ngIf="mpoScores; else buyMPO" class="cardEvaluation">
                    <app-mini-mpo-graph
                        [score]="mpoScores"
                        [interaction]="'cards'"
                        [noRemove]="true"
                        (click)="switchEvaluationType('personality')"
                    ></app-mini-mpo-graph>
                </div>
                <ng-template #buyMPO>
                    <div class="cardEvaluation">
                        <app-people-card-no-report
                            [id]="jobService.id"
                            [type]="'activate.4'"
                            [which]="'personality'"
                            [loc] = '"jobs"'
                            (click)="activate('personality')"
                        ></app-people-card-no-report>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="stateService.hasTalents" colspan="1" rowspan="1">
                <div *ngIf="hasTalentsScores; else buyTalents" class="cardEvaluation">
                    <app-mini-competences-graph
                        [id]="'jobCompetenceGraph'"
                        [type]="'job'"
                        (click)="switchEvaluationType('talents')"
                    ></app-mini-competences-graph>
                </div>
                <ng-template #buyTalents>
                    <div class="cardEvaluation">
                        <app-people-card-no-report
                            [id]="jobService.id"
                            [type]="'activate.2'"
                            [which]="'talents'"
                            [loc] = '"jobs"'
                            (click)="activate('talents')"
                        ></app-people-card-no-report>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="stateService.hasIac"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('ra')"
            >
                <div *ngIf="jobService.raScore; else buyIac" class="cardEvaluation">
                    <app-mini-iac-graph
                        [id]="'iac_mini'"
                        [score]="jobService.raScore"
                    ></app-mini-iac-graph>
                </div>
                <ng-template #buyIac>
                    <div class="cardEvaluation">
                        <div class="cardEvaluation">
                            <app-people-card-no-report
                                [id]="jobService.id"
                                [type]="'activate.2'"
                                [which]="'iac'"
                                [loc] = '"jobs"'
                                (click)="activate('ra')"
                            ></app-people-card-no-report>
                        </div>
                    </div>
                </ng-template>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list
            *ngIf="stateService.hasPersonality || stateService.hasTalents || stateService.hasIac"
            [cols]="colsNumber"
            rowHeight="20px"
        >
            <mat-grid-tile *ngIf="stateService.hasPersonality"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('personality')"
            >
                <p class="cardEvaluationTitle">{{ 'commons.personality' | translate }}</p>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="stateService.hasTalents"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('talents')"
            >
                <p class="cardEvaluationTitle">{{ 'jobs.commons.competencies' | translate }}</p>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="stateService.hasIac"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('ra')"
            >
                <p class="cardEvaluationTitle">{{ 'commons.cai' | translate }}</p>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
