<div class="modal-body">
    <!-- Credits warning message -->
    <app-people-survey-request-credits-warning></app-people-survey-request-credits-warning>

    <div class="pull-left leftContent">
        <div class="creditBox">
            <!-- Credits -->
            <div class="creditBoxIn">
                <app-people-survey-request-credits
                    [surveyRequestFormGroup]="surveyRequestFormGroup"
                    [sessionData]="sessionData"
                ></app-people-survey-request-credits>
            </div>
        </div>
        <div class="pull-left wantedReport">
            <!-- Report -->
            <app-people-survey-request-report-choice
                [reportFormGroup]="surveyRequestFormGroup.get('reportGroup')"
                [isFormSubmitted]="isFormSubmitted"
                [currentLanguage]="currentLanguage"
            ></app-people-survey-request-report-choice>
        </div>
        <div class="clearfix"></div>

        <!-- Options -->
        <app-people-survey-request-options-info
            [surveyRequestFormGroup]="surveyRequestFormGroup"
            [sessionData]="sessionData"
            [isFormSubmitted]="isFormSubmitted"
        ></app-people-survey-request-options-info>

        <!-- Participant -->
        <app-people-survey-request-participant-info
            [surveyRequestFormGroup]="surveyRequestFormGroup"
            [isFormSubmitted]="isFormSubmitted"
            [researchAccount]="researchAccount"
        ></app-people-survey-request-participant-info>
    </div>

    <div id="midCol" class="pull-left rightContent">
        <!-- Request -->
        <app-people-survey-request-info
            [surveyRequestFormGroup]="surveyRequestFormGroup"
            [sessionData]="sessionData"
            [isFormSubmitted]="isFormSubmitted"
        ></app-people-survey-request-info>
    </div>
    <div class="clearfix"></div>
</div>

<div class="modal-footer">
    <button type="button" class="modalButton pull-right"
        (click)="sumbitSurveyRequestFormValues()">{{ 'commons.next' | translate }}</button>
    <button type="button" class="modalButton pull-right" style="margin-right: 15px"
        (click)="handleCancelRequestForm()">{{ 'commons.cancel' | translate }}</button>
    <button type="button" class="modalButton  pull-right" style="margin-right: 15px"
        (click)="handleResetRequestForm()">{{ 'commons.reinit' | translate }}</button>
</div>
