<div *ngIf="clientUser.id && clientAccount.accountToDisplay">

    <div class="rightWrapper row" (window:resize)="onResize($event)">
        <ng-container *ngIf="!clientUser.smallSize">
            <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
            <div class="outerColumnGrey col-md-3" [style.width]="sideWidth">
                <div class="innerColumnGrey" [style.width]="sideWidth">
                    <app-administration-user-detail-left-sidebar
                            [accountData]="clientAccount.accountToDisplay"
                            [width]="sideWidth"
                            [userMod]="canEditUsers"
                    ></app-administration-user-detail-left-sidebar>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="clientUser.smallSize">
            <app-administration-user-detail-left-sidebar
                [small]="true"
                [accountData]="clientAccount.accountToDisplay"
                [width]="sideWidth"
                [userMod]="canEditUsers"
            ></app-administration-user-detail-left-sidebar>
        </ng-container>

        <div class="outerColumnWhite"
             [class.col-md-9]="!clientUser.smallSize"
             [class.col-md-12]="clientUser.smallSize"
             [style.margin-top]="(clientUser.smallSize) ? '90px' : null"
             [style.width]="principalWidth"
        >
            <a id="userInfo"></a>
            <div id="userContent" style="margin-bottom: 50px">
                <h2 class="ngorange" style="font-size: 17pt">{{ clientUser.firstName }}
                    {{ clientUser.lastName }}</h2>
                <div>
                    <div class="pull-left">
                        <h3>{{ 'admin.userDetail.infoOnUser' | translate }}</h3>
                    </div>
                    <div class="pull-right" *ngIf="canDeleteUsers">
                        <span (click)="deleteUser()"
                              class="fakeLink">{{ 'admin.commons.deleteUser' | translate }}</span>
                    </div>
                    <div class="clearfix"></div>
                </div>

                <!-- first col -->
                <div class="col-md-6 accntInfo">
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                            title: 'admin.commons.accountName',
                            field: 'name',
                            editable: false,
                            creation: false
                        }"
                        [itemData]="masterSubAccount"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>

                    <ng-container *ngFor="let info of userInfo[0]">
                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="info"
                            [itemData]="clientUser.userToDisplay"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                    </ng-container>
                </div>

                <!-- second col -->
                <div class="col-md-6 accntInfo">
                    <ng-container *ngFor="let info of userInfo[1]">
                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="info"
                            [itemData]="clientUser.userToDisplay"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                    </ng-container>
                    <table style="width: 100%">

                        <!-- subsidiary -->
                        <tr class="actionModify">
                            <td>
                                <label>{{ 'admin.commons.subsidiary' | translate }}</label>
                            </td>
                            <td colspan="2">
                                <div>
                                    <span>{{hierarchy['subsidiary']}}</span>
                                </div>
                            </td>
                        </tr>

                        <!-- distributor -->
                        <tr class="actionModify">
                            <td>
                                <label>{{ 'admin.commons.distributor' | translate }}</label>
                            </td>
                            <td colspan="2">
                                <div>
                                    <span>{{hierarchy['distributor']}}</span>
                                </div>
                            </td>
                        </tr>

                        <!-- partner -->
                        <tr class="actionModify">
                            <td>
                                <label>{{ 'admin.commons.partner' | translate }}</label>
                            </td>
                            <td colspan="2">
                                <div>
                                    <span>{{hierarchy['partner']}}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="clearfix"></div>

                <!-- Address -->
                <div>
                    <a id="addressInfo"></a>
                    <h3>{{ 'admin.commons.addressInfo' | translate }}</h3>
                    <div class="col-md-6 accntInfo">
                        <ng-container *ngFor="let info of addressInfo[0]">
                            <app-administration-user-form-field
                                [section]="'user'"
                                [newItem]="false"
                                [info]="info"
                                [itemData]="clientUser.addressInfo"
                                [subAccountId]="clientUser.principalSubAccount"
                                (closeAllAdminFields)="closeThis($event)"
                                [closeIfNot]="currentEditedField"
                            ></app-administration-user-form-field>
                        </ng-container>
                    </div>
                    <div class="col-md-6 accntInfo">
                        <ng-container *ngFor="let info of addressInfo[1]">
                            <app-administration-user-form-field
                                [section]="'user'"
                                [newItem]="false"
                                [info]="info"
                                [itemData]="clientUser.addressInfo"
                                [subAccountId]="clientUser.principalSubAccount"
                                (closeAllAdminFields)="closeThis($event)"
                                [closeIfNot]="currentEditedField"
                            ></app-administration-user-form-field>
                        </ng-container>
                    </div>
                </div>
                <div class="clearfix"></div>

                <!-- MPO -->
                <div>
                    <a id="trainingMpo"></a>
                    <h3>{{ 'admin.commons.MPOFormations' | translate }}</h3>
                    <div class="col-md-6 accntInfo">
                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3">
                                    <h4>{{ 'commons.personality' | translate }}</h4>
                                </td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.commons.MPOFormation',
                            field: 'training',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="personality"
                            [dataObjectName]="'personality'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.trainingDate',
                            field: 'formationDate',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="personality"
                            [dataObjectName]="'personality'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3">
                                    <h4>{{ 'commons.communication' | translate }}</h4>
                                </td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.commons.MPOFormation',
                            field: 'training',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="communication"
                            [dataObjectName]="'communication'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.trainingDate',
                            field: 'formationDate',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="communication"
                            [dataObjectName]="'communication'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3">
                                    <h4>{{ 'commons.talents' | translate }}</h4>
                                </td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.commons.MPOFormation',
                            field: 'training',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="talents"
                            [dataObjectName]="'talents'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.trainingDate',
                            field: 'formationDate',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="talents"
                            [dataObjectName]="'talents'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3">
                                    <h3 class="mb0">{{ 'admin.userDetail.infoUser' | translate }}</h3>
                                </td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                                title: 'admin.userDetail.userName',
                                field: 'username',
                                editable: true,
                                creation: true,
                                validity: usernameValidity
                            }"
                            [itemData]="clientUser.userToDisplay"
                            [subAccountId]="clientUser.principalSubAccount"
                            (keyup)="validateUsernameEvent()"
                            (change)="validateUsernameEvent()"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.password',
                            field: 'password',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="clientUser.userToDisplay"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                        <p style="font-size: 90%"><a (click)="reinitPassword()" [style.color]="(messageFormSubmit) ? 'green' : null">{{ 'commons.resetPassword' | translate }}</a></p>
                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.userType',
                            field: 'role',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="clientUser.userToDisplay"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                    </div>

                    <div class="col-md-6 accntInfo">
                        <table style="width: 100%">

                            <tr style="height: 24px;">
                                <td colspan="3"></td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userDetail.formationPlace',
                            field: 'location',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="personality"
                            [dataObjectName]="'personality'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userModal.trainer',
                            field: 'trainer',
                            editable: true,
                            creation: true
                        }"
                            [itemData]="personality"
                            [dataObjectName]="'personality'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3"></td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                                title: 'admin.userDetail.formationPlace',
                                field: 'location',
                                editable: true,
                                creation: true
                            }"
                            [itemData]="communication"
                            [dataObjectName]="'communication'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userModal.trainer',
                                field: 'trainer',
                                editable: true,
                                creation: true
                            }"
                            [itemData]="communication"
                            [dataObjectName]="'communication'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <table style="width: 100%">
                            <tr style="height: 24px;">
                                <td colspan="3"></td>
                            </tr>
                            <tr>
                                <td colspan="3"></td>
                            </tr>
                        </table>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                                title: 'admin.userDetail.formationPlace',
                                field: 'location',
                                editable: true,
                                creation: true
                            }"
                            [itemData]="talents"
                            [dataObjectName]="'talents'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>

                        <app-administration-user-form-field
                            [section]="'user'"
                            [newItem]="false"
                            [info]="{
                            title: 'admin.userModal.trainer',
                                field: 'trainer',
                                editable: true,
                                creation: true
                            }"
                            [itemData]="talents"
                            [dataObjectName]="'talents'"
                            [subAccountId]="clientUser.principalSubAccount"
                            (closeAllAdminFields)="closeThis($event)"
                            [closeIfNot]="currentEditedField"
                        ></app-administration-user-form-field>
                    </div>
                </div>
                <div class="clearfix"></div>

                <a id="permissions"></a>
                <h3 class="mb0">Permissions</h3>
                <div class="col-md-6">
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                                title: 'commons.mpoPersonality',
                                field: 'mpo',
                                editable: true,
                                creation: true
                            }"
                        [itemData]="clientUser.permissions"
                        [formPermissions]="formPermissions"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                                title: 'commons.mpoCommunication',
                                field: 'dit',
                                editable: true,
                                creation: true
                            }"
                        [itemData]="clientUser.permissions"
                        [formPermissions]="formPermissions"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                                title: 'commons.mpoTalents',
                                field: 'talents',
                                editable: true,
                                creation: true
                            }"
                        [itemData]="clientUser.permissions"
                        [formPermissions]="formPermissions"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                </div>
                <div class="col-md-6">
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                                title: 'commons.mpoSatellite',
                                field: 'satellite',
                                editable: true,
                                creation: true
                            }"
                        [itemData]="clientUser.permissions"
                        [formPermissions]="formPermissions"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                                title: 'commons.cai',
                                field: 'ra',
                                editable: true,
                                creation: true
                            }"
                        [itemData]="clientUser.permissions"
                        [formPermissions]="formPermissions"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                </div>
                <div class="clearfix"></div>

                <!-- Notes -->
                <div style="width: 100%">
                    <a id="notes"></a>
                    <h3 class="mb0">Notes</h3>
                    <app-administration-user-form-field
                        [section]="'user'"
                        [newItem]="false"
                        [info]="{
                            title: '',
                            field: 'notes',
                            editable: true,
                            creation: true
                        }"
                        [itemData]="clientUser.userToDisplay"
                        [subAccountId]="clientUser.principalSubAccount"
                        (closeAllAdminFields)="closeThis($event)"
                        [closeIfNot]="currentEditedField"
                    ></app-administration-user-form-field>
                </div>

                <!-- Files -->
                <a id="files"></a>
                <app-administration-common-list
                        *ngIf="canEditUsers"
                        [createItemLabel]="heads['createItemLabel']"
                        [list]="clientUser.assets"
                        [listTitle]="heads['listTitle']"
                        [listTitles]="heads['listTitles']"
                        [listMetaData]="[
                            {value: 'extension', type: 'extension'},
                            {value: 'name', type:'string'},
                            {value: 'attachedBy', type:'string'},
                            {value: 'creationDate', type:'date'},
                            {value: 'size', type:'string'}
                        ]"
                        [listType]="'files'"
                        (createItem)="adminModalHandlingService.openFileModal({}, clientUser.id)"
                        (selectItem)="onSelectFile($event)"
                        (deleteFile)="onDeleteFile($event)"
                ></app-administration-common-list>
                <!--(browse)="onBrowseFiles($event)"-->

            </div>
        </div>

    </div>

</div>
