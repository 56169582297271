<mat-form-field class="full-width">
    <input
        matInput
        type="text"
        onfocus="this.select();"
        title="{{ 'commons.search' | translate }}"
        placeholder="{{ 'commons.search' | translate }}"
        [(ngModel)]="searchTerm"
        (keydown)="onSearch($event, searchTerm, 'return')"
    >
    <button
        mat-button
        *ngIf="actual"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <button
        mat-button
        *ngIf="!actual"
        matSuffix
        mat-icon-button
        (click)="onSearch($event, searchTerm, 'click')"
        aria-label="Search"
    >
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
