import {
    ChangeDetectionStrategy, Component, EventEmitter, Input,
    OnInit, OnDestroy
} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {Subscription} from 'rxjs';

import {Job} from '../../../models/job.model';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {StateService} from '../../../../core/services/state/state.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-jobs-duplicate-modal',
    templateUrl: './jobs-duplicate-modal.component.html',
    // styleUrls: ['./jobs-duplicate-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsDuplicateModalComponent implements OnInit, OnDestroy {
    @Input() jobToDisplay: Job = null;
    public close: EventEmitter<any> = new EventEmitter();

    jobDuplicateForm: FormGroup;
    sessionStructure: any = {};
    validSubAccounts: any;

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    myOptions = [];
    optionsModel: any = [];

    private subscriptions = new Subscription();

    constructor(
        public bsModalRef: BsModalRef,
        private apiJobsService: ApiJobsService,
        private stateService: StateService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {

        // Get sub accounts
        this.sessionStructure = this.stateService.session.sessionData.structure;

        // Get only valid sub accounts
        this.validSubAccounts = this.sessionStructure.subAccounts;
        // SubAccount multiselect
        for (let subAccount of this.validSubAccounts) {
            this.myOptions.push({
                id: subAccount.id,
                name: subAccount.name,
                // checked: false
            });
        }

        // Init form
        this.initJobDuplicateForm();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }


    initJobDuplicateForm() {
        this.jobDuplicateForm = this.fb.group({
            subAccounts: [this.jobToDisplay.subAccountsAccess],
            hardCopy: [false]
        });
    }

    closeModal(jobId = this.jobToDisplay.id) {
        this.close.emit({closed: true, id: jobId});
        this.bsModalRef.hide();
    }

    onSubmit() {
        const subAccounts = this.optionsModel ? this.optionsModel : [];
        // const hardCopy = (model.value.hardCopy) ? 1 : 0;

        // Save data
        this.subscriptions.add(this.apiJobsService.copyJob([this.jobToDisplay.id], [{subAccounts: subAccounts, hardCopy: 1}]).subscribe(
            () => {
                this.optionsModel = [];
                this.closeModal();
            }
        ));
    }
}
