import {ActualModule} from '../../core/interfaces/state';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';

export abstract class Global {

    public module = {
        name: 'people',
        archive: false
    };
    protected constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
    }

    get actualModule(): ActualModule {
        let nameIndex = 1;
        if (this.isMobile) {
            nameIndex++;
        }
        let moduleName = this.router.url.split('/')[nameIndex];
        if (moduleName === 'archives') {
            let section = this.router.url.split('/')[3];
            if (section === 'rightmatch' || section === 'idwmatrix') {
                section = section.replace('m', 'M');
            }
            if (section === 'jobsatisfaction') {
                section = 'jobSatisfaction';
            }
            let capitalized = section[0].toUpperCase() +
                section.slice(1);
            moduleName = moduleName + capitalized;
        }
        if (moduleName === 'analysis') {
            // in analysis case the module name is the second part of url
            nameIndex++;
            moduleName = this.router.url.split('/')[nameIndex];
            // we have to use the uppercase letter 'M' for the modules names like 'rightMatch' and 'idwMatrix'
            moduleName = moduleName.replace('m', 'M');
            // rewrite for jobsatisfaction
            if (moduleName === 'jobsatisfaction') {
                moduleName = 'jobSatisfaction';
            }
        }
        // check if we are in the mobile version
        if (moduleName === 'm') {
            // module name in the mobile version is the second part of url (after /m/)
            // in analysis case the module name is the third part of url
            nameIndex++;
            moduleName = this.router.url.split('/')[nameIndex];
        }
        return {
            name: moduleName,
            archive: (moduleName.substring(0, 8) === 'archives')
        };
    }

    get isMobile() {
        return this.deviceService.isMobile();
    }

    get urlReportType() {
        let typeIndex = 4;
        if (this.isMobile) {
            typeIndex++;
        }
        return this.router.url.split('/')[typeIndex];
    }

    get smallSize() {
        return (window.innerWidth < 1235);
    }
}
