<div *ngIf="filters" class="filterButton hidden-xs">
    <a (click)="filterPanelToggle()">
        <div *ngIf="filterPanelState && !selectionByFilters">
            <img alt="" class="filterIdle" src="{{iconsFolder}}search_and_filters/ico_filter_selected.png">
            <img alt="" class="filterHover" src="{{iconsFolder}}search_and_filters/ico_filter_hover.png"/>
        </div>
        <div *ngIf="!filterPanelState || selectionByFilters">
            <img alt="" *ngIf="!selectionByFilters" class="filterIdle" src="{{iconsFolder}}search_and_filters/ico_filter.png"/>
            <img alt="" [class.filterHover]="!selectionByFilters" src="{{iconsFolder}}search_and_filters/ico_filter_hover.png"/>
        </div>
    </a>
</div>
<div class="searchBarComponent">
    <label>
        <input class="searchBar"
               onfocus="this.select();"
               [(ngModel)]="searchTerm"
               (keydown)="onSearch($event, searchTerm, 'return')"
        />
        <!--
           [typeahead]="items"
           [typeaheadOptionField]="'name'"
           [typeaheadMinLength]="3"
           (typeaheadOnSelect)="selectedItem($event)"
        -->
    </label>
    <div *ngIf="!actual" class="magnifyingGlass" (click)="onSearch($event, searchTerm, 'click')">
        <img alt="" src="{{iconsFolder}}search_and_filters/ico_search.png"/>
    </div>
    <div *ngIf="actual" class="closeSearch" (click)="clearSearch()">
        <img alt="" class="closeIdle" src="{{buttonsFolder}}close.png"/>
        <img alt="" class="closeHover" src="{{buttonsFolder}}close_hover.png"/>
    </div>
</div>

