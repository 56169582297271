
<div *ngIf="backdrop" id="backDrop"></div>
<div
        *ngIf="display && visible"

        id="alertFrame"

        [class.vtop]="positions[0] == 'top'"
        [class.vcentered]="positions[0] == 'center'"
        [class.vbottom]="positions[0] == 'bottom'"
        [class.hleft]="positions[1] == 'left'"
        [class.hcentered]="positions[1] == 'center'"
        [class.hright]="positions[1] == 'right'"
        [ngStyle]="{
            'margin-top': margin_top_correction + 'px',
            'margin-right': margin_right_correction + 'px',
            'margin-bottom': margin_bottom_correction + 'px',
            'margin-left': margin_left_correction + 'px',
            'width': width + 'px'
        }"
        @visibilityChanged
>
    <div id="innerFrame"
         [ngStyle]="{
            'width': width + 'px',
            'border-left': border_left,
            'border-radius': '2px'
        }"
    >
        <div class="main-container">
            <div *ngIf="x_confirm" style="position: absolute;top: 15px;" [style.left]="x_left_pos">&#x00D7;</div>
            <div class="icon-container">
                <img class="icon" *ngIf="config.type == 'green'" src="assets/alerts/check.png"/>
                    <img class="icon" *ngIf="config.type == 'yellow'" src="assets/alerts/warn.png"/>
                <img class="icon" *ngIf="config.type == 'red'" src="assets/alerts/alert.png"/>
            </div>
            <div class="title-container">
                <h1 class="title semiBold" *ngIf="config.texth1">{{ config.texth1 }}</h1>
                <h2 class="title" *ngIf="config.texth2">{{ config.texth2 }}</h2>
            </div>
        </div>
        <hr *ngIf="separation" class='separation'/>
        <p *ngIf="config.paragraph">{{ config.paragraph }}</p>
        <div *ngIf="button_confirm || button_ok_freezeAction" style="text-align: center; width: 100%;">
            <div style="display: inline-block">
                <button *ngIf="button_cancel" class='modalButton' (click)="doContinue('cancel')">
                    {{ 'commons.cancel' | translate }}
                </button>&nbsp;&nbsp;
                <button *ngIf="button_confirm" class='modalButton' (click)="doContinue('confirm')">OK</button>
                <button *ngIf="button_ok_freezeAction" class='modalButton' (click)="doContinue('cancel')">OK</button>

            </div>
        </div>
    </div>
</div>
