<div class="sticky" *ngIf="!isLandscape || showPortraitButtons">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <h2 class="name">
                {{ graphBoard.name ? graphBoard.name.toUpperCase() : '' }}
            </h2>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                [disabled]="(!itemList || !itemList['individus'] || itemList['cIndividus']==0) && (!itemList || !itemList['profiler'] || itemList['cProfiler']==0)"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="itemEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}</button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr" />
</div>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 class="name">
        {{ graphBoard.name ? graphBoard.name.toUpperCase() : '' }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="editRequest"
     class="fullView-wrapper"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <app-mobile-graphboards-edit
        [status]="'edit'"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-graphboards-edit>
</div>
<div *ngIf="pdfRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-graphboards-pdf
        [itemToDisplay]="graphBoard.itemToDisplay"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-graphboards-pdf>
</div>
<div *ngIf="!pdfRequest && !editRequest" class="fullView-wrapper stretched" [class.withPortraitButtons]="showPortraitButtons">
    <div class="informationPanel">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable">
                <tr>
                    <td> {{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!graphBoard.name">
                            {{ graphBoard.name || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ 'commons.description' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!graphBoard.description">
                            {{ graphBoard.description || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!setSubAccountName(graphBoard.subAccount)">
                            {{ setSubAccountName(graphBoard.subAccount)
                        || notSpecified()  }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        {{ 'commons.division' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!graphBoard.department">
                            {{ graphBoard.department || notSpecified() }}
                        </span>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
    </div>
    <div class="addingPanel">
        <h2>{{ 'gbs.sidebar.elements' | translate }}</h2>
        <mat-expansion-panel
            [expanded]="addingPanelOpenState"
            (opened)="openPanel()"
        >
            <mat-expansion-panel-header [collapsedHeight]="'25px'" [expandedHeight]="'25px'">
                <mat-panel-title>
                    <button
                        mat-stroked-button
                        matSuffix
                        mat-icon-button
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-mobile-graphboards-person-adding
                *ngIf="addingPanelOpenState"
                [selectedTab]="selectedTab"
                (graphboardTabChange)="switchEvaluationType($event)"
                (closePanel)="closePanel()"
            ></app-mobile-graphboards-person-adding>
        </mat-expansion-panel>
    </div>
    <mat-tab-group
        class="analysisTabs"
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="switchEvaluationType($event)"
    >
        <mat-tab label="{{ 'commons.individus' | translate }}">
            <div *ngIf="graphboardTab=='individus'">
                <div *ngIf="itemList && itemList['individus'] && itemList['cIndividus']!=0">
                    <div [class.jobsSection]="itemList['individus'].length >= maxPerPage">
                        <table id="individusListing">
                            <tr>
                                <th>{{ 'commons.name' | translate }}</th>
                                <th>{{ 'commons.traits' | translate }}</th>
                                <th>{{ 'commons.behavior' | translate }}</th>
                            </tr>
                            <ng-container
                                *ngFor="let i of createRange(actualPage['individus'], maxPerPage)">
                                <tr *ngIf="itemList['individus'][i]">
                                    <td>
                                        <a *ngIf="!actualModule.archive"
                                           (click)="goToPerson(itemList['individus'][i].id)"
                                        >{{showProperty(itemList['individus'][i], 'firstName')}} {{showProperty(itemList['individus'][i], 'lastName')}}</a>
                                        <span *ngIf="actualModule.archive">{{showProperty(itemList['individus'][i], 'firstName')}}
                                            {{showProperty(itemList['individus'][i], 'lastName')}}</span>
                                    </td>
                                    <td><span *ngIf="showProperty(itemList['individus'][i], 'hasTrait')">✓</span></td>
                                    <td><span *ngIf="showProperty(itemList['individus'][i], 'hasPrb')">✓</span></td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div class="justifyRight">
                        <mat-grid-list cols="1" rowHeight="25px">
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                            >
                                <div *ngIf="actualPage['individus'] > 1" (click)="goToPage(-1,'individus')"><img alt="<" src="{{buttonsFolder}}before.png"></div>
                                <div *ngIf="actualPage['individus'] == 1"><img alt="<" src="{{buttonsFolder}}before_deactivated.png"></div>
                                <div *ngIf="actualPage['individus'] < maxPage['individus']" (click)="goToPage(1, 'individus')"><img alt=">" src="{{buttonsFolder}}next.png"></div>
                                <div *ngIf="actualPage['individus'] == maxPage['individus']"><img alt=">" src="{{buttonsFolder}}next_deactivated.png"></div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
                <div *ngIf="!itemList || (itemList['individus'] && itemList['individus'].length === 0)" class="noData">
                    {{ 'commons.none' | translate }}
                    <button
                        mat-stroked-button
                        matSuffix
                        mat-icon-button
                        [disabled]="addingPanelOpenState"
                        (click)="openPanel()"
                    >
                        <mat-icon *ngIf="!addingPanelOpenState">add</mat-icon>
                        <mat-icon *ngIf="addingPanelOpenState">block</mat-icon>
                    </button>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'commons.jobs' | translate }}">
            <div *ngIf="graphboardTab=='profiler'">
                <div *ngIf="itemList && itemList['cProfiler']!=0">
                    <div [class.jobsSection]="itemList['profiler'].length >= maxPerPage">
                        <table id="jobsListing">
                            <ng-container
                                *ngFor="let i of createRange(actualPage['profiler'], maxPerPage)">
                                <tr *ngIf="itemList['profiler'][i]">
                                    <td>
                                        <a *ngIf="!actualModule.archive"
                                           (click)="goToJob(itemList['profiler'][i].id, graphBoard.id, this.specificState())">{{ showProperty(itemList['profiler'][i], 'jobTitle') }}</a>
                                        <span *ngIf="actualModule.archive">{{ showProperty(itemList['profiler'][i], 'jobTitle') }}</span>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                    <div class="justifyRight">
                        <mat-grid-list cols="1" rowHeight="25px">
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                            >
                                <div *ngIf="actualPage['profiler'] > 1" (click)="goToPage(-1,'profiler')"><img alt="<" src="{{buttonsFolder}}before.png"></div>
                                <div *ngIf="actualPage['profiler'] == 1"><img alt="<" src="{{buttonsFolder}}before_deactivated.png"></div>
                                <div *ngIf="actualPage['profiler'] < maxPage['profiler']" (click)="goToPage(1, 'profiler')"><img alt=">" src="{{buttonsFolder}}next.png"></div>
                                <div *ngIf="actualPage['profiler'] == maxPage['profiler']"><img alt=">" src="{{buttonsFolder}}next_deactivated.png"></div>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
                <div *ngIf="!itemList || (itemList['profiler'] && itemList['profiler'].length === 0)" class="noData">
                    {{ 'commons.none' | translate }}
                    <button
                        mat-stroked-button
                        matSuffix
                        mat-icon-button
                        [disabled]="addingPanelOpenState"
                        (click)="openPanel()"
                    >
                        <mat-icon *ngIf="!addingPanelOpenState">add</mat-icon>
                        <mat-icon *ngIf="addingPanelOpenState">block</mat-icon>
                    </button>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
    <div *ngIf="graphBoard.itemToDisplay && !graphBoard.isEmptyAlignment()">
        <div *ngFor="let line of graphBoard.alignment; let i = index">
            <div *ngFor="let item of graphBoard.alignment[i]" [id]="idBuilding(item)">
                <div class="graphs">
                    <div
                        [class.trait]="showProperty(item, 'analysisType') == 'trait'"
                        [class.prb]="showProperty(item, 'analysisType') == 'prb'"
                        [class.profiler]="showProperty(item, 'analysisType') == 'profiler'"
                        class="graphsPad"

                    >
                        <mat-grid-list cols="2" rowHeight="25px">
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                                class="nameGb"
                            >
                                <div class="truncate"
                                     *ngIf="showProperty(item, 'analysisType') == 'trait' || showProperty(item, 'analysisType') == 'prb'">
                                    <!--{{ truncateName(item, 'name') }}-->
                                    {{showProperty(item, 'firstName') + ' ' + showProperty(item, 'lastName')}}
                                </div>
                                <div class="truncate" *ngIf="showProperty(item, 'analysisType') == 'profiler'">
                                    <!--{{ truncateName(item.jobTitle, 'profiler') }}-->
                                    {{showProperty(item, 'jobTitle')}}
                                </div>
                            </mat-grid-tile>
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                                class="typeGb"
                            >
                                <div *ngIf="showProperty(item, 'analysisType') == 'trait'">
                                    Traits
                                </div>
                                <div *ngIf="showProperty(item, 'analysisType') == 'prb'">
                                    Perception
                                </div>
                                <div *ngIf="showProperty(item, 'analysisType') == 'profiler'">
                                    Profil de poste
                                </div>
                            </mat-grid-tile>
                        </mat-grid-list>
                        <app-mpo-graph
                                [id]="analysisTypeForId(item) + '_' + item.id + '_' + showProperty(item, 'analysisType')"
                                [width]="graphWidth+'px'"
                                [lang]="currentLang"
                                [scores]="showProperty(item, 'scores')"
                                [scSize]="true"
                        ></app-mpo-graph>
                        <mat-grid-list cols="2" rowHeight="25px">
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                                class="jobTitle truncate"
                            >
                                <span *ngIf="(showProperty(item, 'analysisType') == 'trait' || showProperty(item, 'analysisType') == 'prb')">
                                    {{ showProperty(item, 'jobTitle') }} &nbsp;
                                </span>
                                <span *ngIf="!(showProperty(item, 'analysisType') == 'trait' || showProperty(item, 'analysisType') == 'prb')">
                                    &nbsp;
                                </span>
                            </mat-grid-tile>
                            <mat-grid-tile
                                colspan="1"
                                rowspan="1"
                                class="itemDate"
                            >
                                {{ itemsDate(item, currentLang) }}
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
