import {timer, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {Location} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {NavigationEnd, Router, NavigationCancel} from '@angular/router';

import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {TranslateService} from '@ngx-translate/core';
import {AdminService} from '../../services/admin.service';
import {UserPreferencesService} from '../../../administration/services/user-preferences.service';

import * as _ from 'lodash';
import {NavigationService} from './navigation.service';
import {EnvService} from '../../services/env.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, OnDestroy {

    @Output() showNews = new EventEmitter();

    actualModule: string;
    credits: string;
    lang: string;

    small;

    principalAccountCredits;

    loggedAccount = '';
    showAccount = false;
    doFetchCredits = true;

    apiBaseUrl: string;
    profilerUrl: string;

    phoneNumber: string;

    creditListing: any = [];

    isList = false;

    private subscriptions = new Subscription();

    constructor(
        private apiAdministrationService: ApiAdministrationService,
        private location: Location,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public stateService: StateService,
        public translate: TranslateService,
        private adminService: AdminService,
        private userPreferencesService: UserPreferencesService,
        private navigationService: NavigationService,
        private environment: EnvService
    ) {
        if (!this.actualModule) {
            this.setActualModuleName('people');
        }

        // Set current locale
        if (this.translate.getDefaultLang()) {
            this.lang = this.translate.getDefaultLang();
        } else {
            let lang = this.translate.getBrowserLang();
            if (lang !== 'fr' && lang !== 'en') {
                lang = 'en';
            }
            this.translate.setDefaultLang(lang);
            this.translate.use(lang);
            this.lang = lang;
        }

        this.subscriptions.add(this.router.events.subscribe(
            (res) => {
                if (res instanceof NavigationEnd || res instanceof NavigationCancel) {
                    let url = res.url;
                    this.isList = !(url.includes('report') || url.includes('account'));
                    this.changeDetectorRef.markForCheck();
                }
            }
        ));

        this.apiBaseUrl = this.environment.apiBaseUrl;
        this.profilerUrl = this.environment.profilerUrl;
    }

    isInUserPrefs() {
        const urlArray = this.location.path().split('/');

        // noinspection OverlyComplexBooleanExpressionJS
        return urlArray[4] === 'user' &&
            urlArray[6] === 'preferences';
    }

    findPhoneNumber() {
        let hierarchy = this.stateService.session.sessionData.accountHierarchy;

        let phoneNumber;
        // Subsidiary
        phoneNumber = _.result(_.find(hierarchy, function (o) {
            return o.accountType === 1;
        }), 'phone');
        // Distributor
        if (phoneNumber) {
            return phoneNumber;
        }
        phoneNumber = _.result(_.find(hierarchy, function (o) {
            return o.accountType === 2;
        }), 'phone');
        if (phoneNumber) {
            return phoneNumber;
        }
        // If all else fails: Ngenio
        return (this.translate.currentLang === 'fr') ? '514 990-4600 poste 125' : '514 990-4600 Ext. 125';
    }

    ngOnInit() {
        this.small = (window.innerWidth < 1235);
        let urlParamsString = this.location.path().split('/');
        if (urlParamsString && urlParamsString[1]) {
            this.setActualModuleName(urlParamsString[1]);
        }
        this.subscriptions.add(this.router.events.subscribe(
            (res) => {
                if (res instanceof NavigationEnd || res instanceof NavigationCancel) {
                    let newModArr = res.url.split('/');
                    let newMod = newModArr[1];
                    if (res && res['urlAfterRedirects']) {
                        newModArr = res['urlAfterRedirects'].split('/');
                    }

                    if (newModArr[1] === 'admin') {
                        newMod = 'administration';
                    }
                    if (newModArr[1] === 'analysis') {
                        if (newModArr[2] === 'rightmatch') {
                            newMod = 'rightMatch';
                        } else if (newModArr[2] === 'idwmatrix') {
                            newMod = 'idwMatrix';
                        } else {
                            newMod = 'graphboard';
                        }
                    }
                    if (this.actualModule !== newMod) {
                        this.setActualModuleName(newMod);
                    }
                }
            }
        ));

        this.phoneNumber = this.findPhoneNumber();
        this.fetchCredit();
        if (this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.accountData
        ) {

            // Timer to get credits
            this.subscriptions.add(timer(1680, 30000).subscribe(() => {
                if (this.doFetchCredits) {
                    this.fetchCredit();
                }
            }));
        }


        this.subscriptions.add(this.stateService.session.sessionCreditUpdateWatch$.subscribe(
            () => {
                this.fetchCredit();
            }
        ));


        this.subscriptions.add(this.navigationService.switchSubAccountName$.pipe(distinctUntilChanged()).subscribe(res => {
            if (res) {
                if (res === 'back') {
                    this.loggedAccount = '';
                    this.showAccount = false;
                } else {
                    this.loggedAccount = res;
                    this.fetchCredit();
                    this.showAccount = true;
                }
            }
        }));
    }

    onShownLoggedAccount(loggedAccountTooltip) {
        // hide the shown tooltip automatically after 3 sec
        setTimeout(() => {
            loggedAccountTooltip.hide();
        }, 3000);
    }

    onResize() {
        this.small = (window.innerWidth < 1235);
    }

    fetchCredit() {

        this.doFetchCredits = false;
        let subAccountAccess = (this.stateService.session.sessionData.userData && this.stateService.session.sessionData.userData.subAccountAccess) ?
            this.stateService.session.sessionData.userData.subAccountAccess : false;
        if (this.stateService.session.sessionData.userData && this.stateService.session.sessionData.userData.username === 'ngadmin') {
            subAccountAccess = [0];
        } else {
            // Get sub-account access from session
            while (subAccountAccess === false) {
                timer(1680, 2000).subscribe(() => {
                    subAccountAccess = (this.stateService.session.sessionData.userData && this.stateService.session.sessionData.userData.subAccountAccess) ?
                        this.stateService.session.sessionData.userData.subAccountAccess : false;
                });
            }
        }

        /**
         * TODO: Fetch all at once...
         */
        this.creditListing = [];
        if (this.stateService.session.sessionData.accountData) {
            // for (let subaccountNumber of subAccountAccess) {
                if (this.stateService.session.sessionData.accountData.subAccounts[0]) {
                    if (this.stateService.session.sessionData.accountData) {
                        this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
                            [this.stateService.session.sessionData.accountData.id, 0]
                        ).subscribe(
                            res => {
                                if (res.unlimited) {
                                    this.creditListing[0] = [res.name, 'unlimited'];
                                } else {
                                    this.creditListing[0] = [res.name, res.credits];
                                }
                                let principalAccount = (this.stateService.session.sessionData.structure && this.stateService.session.sessionData.structure.principalSubAccount) ?
                                    this.stateService.session.sessionData.structure.principalSubAccount : '0';
                                if (!this.creditListing[principalAccount]) { principalAccount = 0; }
                                if (this.creditListing[principalAccount][1] !== 'unlimited') {
                                    let credits = (this.translate.currentLang === 'fr') ? ' crédits' : ' credits';
                                    if (this.creditListing[principalAccount]) {
                                        this.principalAccountCredits = this.creditListing[principalAccount][1] + credits;
                                    } else {
                                        this.principalAccountCredits = credits;
                                    }
                                    this.stateService.session.accountHasUnlimitedCredits = false;
                                } else {
                                    this.principalAccountCredits = (this.translate.currentLang === 'fr') ? 'Illimité' : 'Unlimited';
                                    this.stateService.session.accountHasUnlimitedCredits = true;
                                }
                                this.doFetchCredits = true;
                                this.changeDetectorRef.markForCheck();
                            },
                            err => {
                                console.log(err);
                            }
                        ));
                    }
                }
            //}
        }
    }

    setActualModuleName(modName) {
        this.actualModule = modName;
        this.stateService.session.actualModule = modName;

        this.userPreferencesService.isPreferenceSet(this.stateService.session.actualModule);
        this.changeDetectorRef.markForCheck();
    }

    goToPeople() {
        this.router.navigate(['people/']).then(() => {});
    }

    goToTools() {
        // manage permission
        const permissions = this.stateService.session.sessionData.permissions;

        if (_.includes(permissions.roles, 'ROLE_SUPER_ADMIN')) {
            this.router.navigate(['tools/']);
        }
    }

    goToAdmin() {

        // manage permission
        const permissions = this.stateService.session.sessionData.permissions;

        const accountData = this.sessionData.accountData;

        if (_.includes(permissions.roles, 'ROLE_USER_READONLY')
            || _.includes(permissions.roles, 'ROLE_USER_READWRITE')
        ) {
            // all users go to user detail page
            const userData = this.sessionData.userData;
            this.router.navigate(['admin/account/' + accountData.id + '/user/' + userData.id]);
            return;
        }

        if (_.includes(permissions.roles, 'ROLE_ADMIN') && this.stateService.session.sessionData.permissions.accountType <= 4) {
            this.router.navigate(['admin/account/' + accountData.id]);
            return;
        }

        this.router.navigate(['/admin/list']);
    }

    goToPreferences() {
        if (!this.stateService.session.hasSatelliteRole && this.switchAccountDataId === null) {
            const accountData = this.sessionData.accountData;
            const userData = this.sessionData.userData;
            this.router.navigate(['admin/account/' + accountData.id + '/user/' + userData.id + '/preferences']).then(() => {});
        }
    }

    onLogout() {
        this.doFetchCredits = false;

        // Clear session Data
        sessionStorage.clear();

        // Remove token
        localStorage.removeItem('id_token');

        // Redirect onLoggout
        localStorage.setItem('remember_me', 'false');

        // Clear state
        this.stateService.clearState();

        window.location.href = '/login;lang=' + this.translate.currentLang;
    }

    switchBack(switchAccountDataId) {
        const currentAccountId = this.sessionData.accountData.id;
        this.subscriptions.add(this.apiAdministrationService.clientsSwitch([switchAccountDataId]).subscribe(
            data => {
                // Reset state
                this.stateService.resetState('restoreOrigin');

                // Clear session Data (to have a clean session for the switch user)
                let sessionStorageOrigin: any = sessionStorage.getItem('origin');
                sessionStorageOrigin = JSON.parse(sessionStorageOrigin);
                sessionStorage.clear();
                for (let item in sessionStorageOrigin) {
                    if (sessionStorageOrigin.hasOwnProperty(item)) {
                        if (item) {
                            sessionStorage.setItem(item, sessionStorageOrigin[item]);
                        }
                    }
                }

                // Add key isLoggedIn (why? we already have a `is_logged` var in local storage ...)
                sessionStorage.setItem('session.isLoggedIn', 'true');
                sessionStorage.setItem('reload', 'true');
                // store in local storage
                localStorage.setItem('is_logged', 'yes');
                localStorage.setItem('id_token', data.token);
                this.stateService.session.redirectUrl = '';

                this.navigationService.updateSwitchSubAccountName('back');


                // Init session
                this.subscriptions.add(this.adminService.getSession(this.translate.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;

                        // Init preferences
                        this.userPreferencesService.initUserPreferences();

                        this.router.navigate([`/admin/account/${currentAccountId}`]);

                        this.fetchCredit();
                    }
                ));
            }));
    }

    switchLanguage(locale: string
    ) {
        this.translate.use(locale);
        this.lang = locale;

        this.subscriptions.add(this.adminService.getSession(locale).subscribe(
            sessionData => {
                this.stateService.sessionData = sessionData;
                this.changeDetectorRef.markForCheck();
            }
        ));
        return false;
    }

    sendSupportMail() {
        let date: any = new Date();
        date = date.toDateString();
        let browser = window.navigator.userAgent;
        let suffix = `__________________________________%0A` +
            `Date: ${date}%0A` +
            `Browser: ${browser}`;


        let title = 'Support - Ngenioconnect 3';
        let body = `INSTRUCTIONS%0A%0A` +
            `- Précisez la section de l'application nécessitant du support ainsi qu'un résumé du problème dans la section titre.%0A` +
            `- Décrivez en détail la situation nécessitant du support ou la nature du problème dans la section description.%0A` +
            `- Si nécessaire, veuillez inclure des copies d'écran montrant la nature du problème.%0A%0A` +
            `TITRE%0A%0A%0A` + `DESCRIPTION%0A%0A%0A` + suffix;
        if (this.translate.currentLang === 'en') {
            title = 'Support - Ngenioconnect 3';
            body = `INSTRUCTIONS%0A%0A` +
                `- Specify the section of the application which requires support and a summary of the problem in the title ` +
                `section.%0A` +
                `- Describe in detail the situation requiring support or the nature of the problem in the description of the ` +
                `section.%0A` +
                `- If necessary, please include screenshots clarifying the nature of the problem.%0A%0A` +
                `TITRE%0A%0A%0A` + `DESCRIPTION%0A%0A%0A` + suffix;
        }

        window.location.href = 'mailto:info@ngenioworld.com?subject=' + title + '&body=' + body;

        this.changeDetectorRef.markForCheck();

    }

    checkIsPreferenceSet() {
        let actualModule = (this.stateService.session.actualModule === 'administration') ? 'admin' : this.stateService.session.actualModule;
        return this.userPreferencesService.isPreferenceSet(actualModule);
    }

    showNewsPanel() {
        this.showNews.emit();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get isSuperAdmin() {
        let role;
        // noinspection OverlyComplexBooleanExpressionJS
        if (
            this.stateService &&
            this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.permissions &&
            this.stateService.session.sessionData.permissions.userType
        ) {
            role = this.stateService.session.sessionData.permissions.userType;
        } else {
            role = 'rw';
        }
        return (role === 's_admin');
    }

    get isSender() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isSender;
        }
        return false;
    }

    get isAdmin() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isAdmin;
        }
        return false;
    }

    get sessionData() {
        if (this.stateService.session) {
            return this.stateService.session.sessionData;
        }
        return null;
    }

    get lastAddress() {
        if (this.sessionData && this.sessionData.lastAddress) {
            return this.sessionData.lastAddress;
        }
        return;
    }

    get accountNumber() {
        if (
            this.stateService &&
            this.stateService.session &&
            this.sessionData &&
            this.sessionData.accountData
        ) {
            return this.sessionData.accountData.accountNumber;
        }
        return;
    }

    get switchAccountDataId() {
        if (this.sessionData.switchAccountData && this.sessionData.switchAccountData.accountDataId) {
            return this.sessionData.switchAccountData.accountDataId;
        }
        return null;
    }

    get currentAccountTypeAfterSwitch() {
        if (this.switchAccountDataId &&
            this.sessionData &&
            this.sessionData.accountType) {
                return this.sessionData.accountType;
            }
        return;
    }

}
