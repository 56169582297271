import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PeopleListComponent} from './components/web/people-list/people-list.component';
import {MobilePeopleListComponent} from './components/mobile/people-list/mobile-people-list.component';
import {MobilePeopleFullViewComponent} from './components/mobile/people-full-view/mobile-people-full-view.component';
import {MobileNavigationComponent} from '../app/components/navigation/mobile/mobile-navigation.component';
import {MobilePeopleRequestSendResendComponent} from './components/mobile/mobile-people-request-send-resend/mobile-people-request-send-resend.component';
import {MobilePeopleSurveyRequestFormComponent} from './components/commonClasses/people-survey-request/request-form/mobile/mobile-people-survey-request-form/mobile-people-survey-request-form.component';
import {PeopleSurveyRequestFormComponent} from './components/commonClasses/people-survey-request/request-form/request-form.component';
import {MobileUserListComponent} from './components/commonClasses/people-survey-request/mobile-user-list/mobile-user-list.component';
import {PeopleSurveyRequestReportChoiceComponent} from './components/commonClasses/people-survey-request/request-form/report-choice/report-choice.component';
import {PeopleSurveyRequestCreditsComponent} from './components/commonClasses/people-survey-request/request-form/credits/credits.component';
import {PeopleSurveyRequestParticipantInfoComponent} from './components/commonClasses/people-survey-request/request-form/participant-info/participant-info.component';
import {MobileSurveyRequestInfoComponent} from './components/commonClasses/people-survey-request/request-form/mobile/mobile-survey-request-info/mobile-survey-request-info.component';
import {MobileOptionsInfoComponent} from './components/commonClasses/people-survey-request/request-form/mobile/mobile-options-info/mobile-options-info.component';
import {MobileSendMethodsComponent} from './components/commonClasses/people-survey-request/send-methods/mobile-send-methods/mobile-send-methods.component';
import {MobilePeopleEditComponent} from './components/mobile/people-edit/mobile-people-edit.component';
import {MobilePeoplePdfSharingComponent} from './components/mobile/people-pdf-sharing/mobile-people-pdf-sharing.component';
import {MobilePeopleReportActivationComponent} from './components/mobile/people-report-activation/mobile-people-report-activation.component';
import {PeopleSurveyRequestSendMethodCopyPasteComponent} from './components/commonClasses/people-survey-request/send-methods/copypaste/copypaste.component';
import {PeopleSurveyRequestSendMethodEmailClientComponent} from './components/commonClasses/people-survey-request/send-methods/email-client/email-client.component';
import {PeopleSurveyRequestSendMethodNgenioServerComponent} from './components/commonClasses/people-survey-request/send-methods/ngenio-server/ngenio-server.component';
import {PeopleSurveyRequestSendMethodPaperComponent} from './components/commonClasses/people-survey-request/send-methods/paper/paper.component';
import {PeopleListSearchFiltersComponent} from './components/web/people-list-search-filters/people-list-search-filters.component';
import {PeopleSelectedItemsComponent} from './components/web/people-selected-items/people-selected-items.component';
import {PeopleSidebarComponent} from './components/web/people-sidebar/people-sidebar.component';
import {PeopleRoutingModule} from './people-routing.module';
import {PeopleEditModalComponent} from './components/web/people-edit-modal/people-edit-modal.component';
import {FakeReportComponent} from './components/web/fake-report/fake-report.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingModule} from 'ngx-loading';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {SidebarModule} from 'ng-sidebar';
import {PeopleViewService} from './services/mobile/people-view.service';
import {TestAndReportHandlingService} from './services/test-and-report-handling.service';
import {PersonService} from './services/person.service';
import {TruncatePipe} from '../shared/pipes/truncate.pipe';
import {MainListModule} from '../shared/main-list/main-list.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ApiPeopleService} from './services/api-people.service';
import {MyDatePickerModule} from 'mydatepicker';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PeopleReportComponent} from './components/web/people-report/people-report.component';
import {PeopleReportIacComponent} from './components/web/people-report-iac/people-report-iac.component';
import {PeopleReportCommunicationComponent} from './components/web/people-report-communication/people-report-communication.component';
import {PeopleReportPersonalityComponent} from './components/web/people-report-personality/people-report-personality.component';
import {PeopleReportTalentsComponent} from './components/web/people-report-talents/people-report-talents.component';
import {PeopleReportSatelliteComponent} from './components/web/people-report-satellite/people-report-satellite.component';
import {PeopleRequestSendResendModalComponent} from './components/web/people-request-send-resend-modal/people-request-send-resend-modal.component';
import {PeopleSurveyRequestFormService} from './services/people-survey-request-form.service';
import {PeopleSurveyRequestCreditsWarningComponent} from './components/commonClasses/people-survey-request/request-form/credits/credits-warning.component';
import {PeopleSurveyRequestInfoComponent} from './components/commonClasses/people-survey-request/request-form/survey-request-info/survey-request-info.component';
import {PeopleSurveyRequestOptionsInfoComponent} from './components/commonClasses/people-survey-request/request-form/options-info/options-info.component';
import {PeopleSurveyRequestSendMethodsComponent} from './components/commonClasses/people-survey-request/send-methods/send-methods.component';
import {PeopleReportActivationModalComponent} from './components/web/people-report-activation-modal/people-report-activation-modal.component';
import {PeopleMultiselectActionComponent} from './components/web/people-multiselect-action/people-multiselect-action.component';
import {PeoplePdfModalComponent} from './components/web/people-pdf-modal/people-pdf-modal.component';
import {PeopleModalHandlingService} from './services/people-modal-handling.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


@NgModule({
    declarations: [
        PeopleSidebarComponent,
        PeopleListComponent,
        PeopleEditModalComponent,
        FakeReportComponent,
        MobilePeopleListComponent,
        MobileNavigationComponent,
        MobilePeopleRequestSendResendComponent,
        TruncatePipe,
        PeopleListSearchFiltersComponent,
        PeopleSelectedItemsComponent,
        MobilePeopleSurveyRequestFormComponent,
        MobileOptionsInfoComponent,
        PeopleSurveyRequestReportChoiceComponent,
        PeopleSurveyRequestCreditsComponent,
        PeopleSurveyRequestCreditsWarningComponent,
        PeopleSurveyRequestParticipantInfoComponent,
        MobileSurveyRequestInfoComponent,
        MobileUserListComponent,
        MobileSendMethodsComponent,
        PeopleSurveyRequestSendMethodCopyPasteComponent,
        PeopleSurveyRequestSendMethodEmailClientComponent,
        PeopleSurveyRequestSendMethodNgenioServerComponent,
        PeopleSurveyRequestSendMethodPaperComponent,
        MobilePeopleListComponent,
        MobilePeopleFullViewComponent,
        PeopleReportComponent,
        PeopleReportIacComponent,
        PeopleReportCommunicationComponent,
        PeopleReportPersonalityComponent,
        PeopleReportTalentsComponent,
        PeopleReportSatelliteComponent,
        PeopleRequestSendResendModalComponent,
        PeopleSurveyRequestInfoComponent,
        PeopleSurveyRequestFormComponent,
        PeopleSurveyRequestOptionsInfoComponent,
        PeopleSurveyRequestSendMethodsComponent,
        PeopleReportActivationModalComponent,
        PeopleMultiselectActionComponent,
        PeoplePdfModalComponent,
        MobilePeopleEditComponent,
        MobilePeoplePdfSharingComponent,
        MobilePeopleReportActivationComponent
    ],
    imports: [
        CommonModule,
        PeopleRoutingModule,
        LoadingModule,
        TranslateModule,
        SharedModule,
        SidebarModule,
        MainListModule,
        ReactiveFormsModule,
        MatGridListModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatTableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatExpansionModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatRadioModule,
        TooltipModule.forRoot(),
        FormsModule,
        MyDatePickerModule,
        NgMultiSelectDropDownModule,
        BsDropdownModule,
        CollapseModule,
        ModalModule,
        BrowserAnimationsModule,
        TypeaheadModule.forRoot()
    ],
    providers: [
        TestAndReportHandlingService,
        PersonService,
        ApiPeopleService,
        PeopleViewService,
        PeopleSurveyRequestFormService,
        PeopleModalHandlingService
    ],
    exports: [
        PeopleListSearchFiltersComponent,
        PeopleSelectedItemsComponent,
        MobileNavigationComponent,
        TruncatePipe,
        PeopleListComponent,
        MobilePeopleRequestSendResendComponent
    ],
    entryComponents: [
        PeopleEditModalComponent,
        PeopleRequestSendResendModalComponent,
        PeoplePdfModalComponent,
        PeopleReportActivationModalComponent,
        PeopleMultiselectActionComponent
    ]
})
export class PeopleModule {
}
