<!-- app-right-matches-person.cards will have to be looped in rightMatch report -->
<div id="personFrame">
    <ng-container *ngIf="listView">
        <div class="personFrameHeader" style="position: relative">
            <div class="floatLeft">
                <div class="ngorange bold nameTitle">
                    <a class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
                        {{ record['firstName'] }} {{ record['lastName'] }}
                    </a>
                </div>
            </div>
            <div class="floatRight">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <!-- THREE OR TWO SIDE BY SIDE -->
        <div *ngIf="!hasOneReport" class="mainContent" style="position: relative">
            <div class="subCard"
                 [style.height]="(informationVisibility)? '306px' : '51px' "
            ></div>
            <div
                class="cardContainer"
                [class.col-md-4]="hasThreeReports"
                [class.col-md-6]="hasTwoReports"
                *ngIf="user.hasPersonalityAccess && this.state.displayPreferences.display.personality"
            >
                <app-right-matches-person-sub-card-personality
                    [record]="record"
                    [job]="job"
                    [informationVisibility]="informationVisibility"
                ></app-right-matches-person-sub-card-personality>
            </div>
            <div
                class="cardContainer"
                [class.col-md-4]="hasThreeReports"
                [class.col-md-6]="hasTwoReports"
                *ngIf="user.hasTalentsAccess && this.state.displayPreferences.display.talents"
            >
                <app-right-matches-person-sub-card-talents
                    [record]="record"
                    [job]="job"
                    [informationVisibility]="informationVisibility"
                ></app-right-matches-person-sub-card-talents>
            </div>
            <div
                class="cardContainer"
                [class.col-md-4]="hasThreeReports"
                [class.col-md-6]="hasTwoReports"
                *ngIf="user.hasIacAccess && this.state.displayPreferences.display.cai"
            >
                <app-right-matches-person-sub-card-cai
                    [record]="record"
                    [job]="job"
                    [informationVisibility]="informationVisibility"
                ></app-right-matches-person-sub-card-cai>
            </div>
        </div>
        <!-- ONE REPORT ONLY -->
        <div class="mainContent" *ngIf="hasOneReport" style="position: relative">
            <div class="subCard"
                 [style.height]="(informationVisibility)? '306px' : '51px' "
            ></div>
            <div class="cardContainer col-md-12">
                <ng-container
                    *ngIf="user.hasPersonalityAccess && this.state.displayPreferences.display.personality"
                >
                    <app-right-matches-person-sub-card-personality
                        [record]="record"
                        [pos]="pos"
                        [job]="job"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-personality>
                </ng-container>
                <ng-container
                    *ngIf="user.hasTalentsAccess && this.state.displayPreferences.display.talents"
                >
                    <app-right-matches-person-sub-card-talents
                        [record]="record"
                        [pos]="pos"
                        [job]="job"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-talents>
                </ng-container>
                <ng-container
                    *ngIf="user.hasIacAccess && this.state.displayPreferences.display.cai"
                >
                    <app-right-matches-person-sub-card-cai
                        [record]="record"
                        [pos]="pos"
                        [job]="job"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-cai>
                </ng-container>
            </div>
        </div>
        <div class="clearfix"></div>
    </ng-container>
    <ng-container *ngIf="!listView">
        <div
            class="cards"
            [class.cardsGrid]="!listView"
        >
            <div class="personFrameHeader" style="position: relative">
                <div class="ngorange bold nameTitleCard">
                    <a class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
                        {{ record['firstName'] }} {{ record['lastName'] }}
                    </a>
                </div>
            </div>
            <div>
                <ng-container
                    *ngIf="user.hasPersonalityAccess && this.state.displayPreferences.display.personality"
                >
                    <app-right-matches-person-sub-card-personality
                        [record]="record"
                        [job]="job"
                        [pos]="pos"
                        [listView]="listView"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-personality>
                </ng-container>
                <ng-container
                    *ngIf="user.hasTalentsAccess && this.state.displayPreferences.display.talents"
                >
                    <app-right-matches-person-sub-card-talents
                        [record]="record"
                        [job]="job"
                        [pos]="pos"
                        [listView]="listView"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-talents>
                </ng-container>
                <ng-container
                    *ngIf="user.hasIacAccess && this.state.displayPreferences.display.cai"
                >
                    <app-right-matches-person-sub-card-cai
                        [record]="record"
                        [job]="job"
                        [pos]="pos"
                        [listView]="listView"
                        [informationVisibility]="informationVisibility"
                    ></app-right-matches-person-sub-card-cai>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
