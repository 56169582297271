<div
    #thisCard
    (window:resize)="onResize($event)"
    class="card"
    [class.middleCard]="listView"
    [class.cardGridView]="!listView"
>
    <div class="cardHeader" #container>
        <div class="floatLeft trucateWidth">
            <div class="cardHeaderMainTitle">
                {{ 'commons.talents' | translate }}
            </div>
            <div class="cardHeaderSubTitle">
                {{ 'analysis.rightMatch.keyCompetencies' | translate }}{{
                (translateService.currentLang === 'fr') ? ' ' : ''}}: {{ competenciesTotal }}
            </div>
        </div>
        <div class="floatRight">
            <div class="floatLeft" style="width: 42px; padding: 6px">
                <app-indicator-graph
                    [id]="record.id + '_talent_green'+pos"
                    [score]="competenciesGreenTotal"
                    [over]="competenciesTotal"
                    [color]="'green'"
                >
                </app-indicator-graph>
            </div>
            <div class="floatLeft" style="width: 42px; padding: 6px">
                <app-indicator-graph
                    [id]="record.id + '_talent_yellow'+pos"
                    [score]="competenciesYellowTotal"
                    [over]="competenciesTotal"
                    [color]="'yellow'"
                >
                </app-indicator-graph>
            </div>
            <div class="floatLeft" style="width: 42px; padding: 6px">
                <app-indicator-graph
                    [id]="record.id + '_talent_red'+pos"
                    [score]="competenciesRedTotal"
                    [over]="competenciesTotal"
                    [color]="'red'"
                >
                </app-indicator-graph>
            </div>
            <div *ngIf="!listView" class="floatLeft" style="margin-top: 3px;">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <ng-container *ngIf="informationVisibility">
        <div
            *ngIf="(record['talents'] && record['talents'].length === 0) || competenciesTotal === 0"
            class="notCompleted"
        >
            <div
                *ngIf="competenciesTotal === 0; else notCompleted"
                class="notCompletedTxt"
            >
                {{ 'people.commons.noTargetDefined' | translate }}
            </div>
            <ng-template
                #notCompleted
            >
                <div
                    *ngIf="record['talents'] && record['talents'].length === 0"
                    class="notCompletedTxt"
                >
                    {{ 'people.commons.notCompleted' | translate }}
                </div>
            </ng-template>
        </div>
        <div class="main-container">
            <div style="max-width: 425px">
                <div *ngIf="rightMatch.orderedTalents">
                    <table *ngIf="record['talents'] && record['talents'].length!==0; else fakeList">
                        <tr *ngFor="let item of rightMatch.orderedTalents[translateService.currentLang]">
                            <td class="competenciesTd">{{ item[0] }}</td>
                            <td class="competenciesGraphTd">
                                <app-talent-graph
                                    [id]="'desc_'+record['id']+'_'+item[2]+pos"
                                    [score]="(record['talents'][item[2]]) ? record['talents'][item[2]] : null"
                                    [size]="'rm'"
                                    [target]="(item[1] === 1) ? 'med' : 'high'"
                                ></app-talent-graph>
                            </td>
                        </tr>
                    </table>
                    <ng-template #fakeList>
                        <table class="fakeListTable" *ngIf="rightMatch.orderedTalents">
                            <tr *ngFor="let item of rightMatch.orderedTalents[translateService.currentLang]; let i=index">
                                <td>
                                    <div class="fakeItem" [style.width]="fakeLines[i]+'px'">&nbsp;</div>
                                </td>
                                <td>
                                    <app-talent-graph
                                        [id]="'desc_'+record['id']+'_'+item[2]+pos"
                                        [score]="null"
                                        [size]="'rm'"
                                        [target]="(item[1] === 1) ? 'med' : 'high'"
                                    ></app-talent-graph>
                                </td>
                            </tr>
                        </table>
                    </ng-template>
                </div>
            </div>
        </div>
    </ng-container>
</div>
