import {FormGroup} from '@angular/forms';

export function emailValidator(emailKey: string) {

    return (group: FormGroup): {[key: string]: any} => {
        let email = group.controls[emailKey];
        let emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email.value && !emailRegexp.test(email.value)) {
            return {
                invalidEmail: true
            };
        }
    };
}
