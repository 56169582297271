<div
    class="top tRight affix"
    [style.width]="width"
>
    <div class="pull-left">
        <h2>{{ 'commons.selection' | translate }}</h2>
    </div>
    <div (click)="goToNews()" class="x-close pull-right" style="font-size: 20pt;">
        &#x00D7;
    </div>
    <div class="clearfix"></div>
    <div style="height: 25px"></div>
    <hr>
    <h3><label for="actionsOptions">{{'commons.options' | translate }}</label></h3>
    <select [(ngModel)]="peopleMultiselect.action"
            [disabled]="names.length==0"
            id="actionsOptions"
            class="form-control pull-left"
            style="width: 85%"
    >
        <option>{{ 'commons.select' | translate }}</option>
        <!-- Graphboard -->
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="create_graphboard"
        >
            {{ 'commons.createGb' | translate }}
        </option>
        <!--option value="add_graphboard">{{ 'commons.addToGb' | translate }}</option-->
        <!-- Wellbeing -->
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="create_jobSatisfaction"
        >
            {{ 'commons.createIBT' | translate }}
        </option>
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="add_jobSatisfaction"
        >
            {{ 'commons.addToIBT' | translate }}
        </option>
        <!-- Right match -->
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="create_rightMatch"
        >
            {{ 'commons.createRm' | translate }}
        </option>
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="add_rightMatch"
        >
            {{ 'commons.addToRm' | translate }}
        </option>

        <!-- IDW Matrix -->
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="create_idwMatrix"
        >{{ 'commons.createIdwMatrix' | translate }}</option>
        <option
            *ngIf="!user.isSender && stateService.hasPersonality && !actualModule.archive && !stateService.session.hasSatelliteRole"
            value="add_idwMatrix"
        >{{ 'commons.addToIdwMatrix' | translate }}</option>

        <option *ngIf="user.isSuperAdmin" value="transferOtherAcc">
            {{'commons.transferOtherAcc' | translate }}
        </option>

        <option *ngIf="!actualModule.archive" value="subaccount">{{ 'commons.subAccChange' | translate }}</option>
        <option *ngIf="!actualModule.archive" value="department">{{ 'commons.deptChange' | translate }}</option>
        <!--option value="">Partager</option-->
        <option *ngIf="!user.isSender && !actualModule.archive" value="archive">{{ 'commons.archive' | translate }}</option>
        <option *ngIf="!user.isSender && actualModule.archive" value="reactivate">{{ 'commons.reactivate' | translate }}</option>
        <option *ngIf="!user.isSender" value="delete">{{ 'commons.delete' | translate }}</option>
    </select>
    <button (click)="act()" class="modalButton pull-right" style="width: 13%; padding: 7px 0 6px 0">
        <span class="fontRegular" style="color: #EEE; font-size: 125%;">
            ⟩
        </span>
    </button>
    <div class="clearfix"></div>
    <hr>
    <h3>{{ 'people.selectedItems.selectedPeople' | translate }}</h3>
    <div *ngIf="peopleMultiselect.validityCheck(true)" style="margin-bottom: 8px">
        <span class="unselectable">
            {{ 'commons.unselectablePerson' | translate }}
        </span>
    </div>
    <div *ngIf="peopleMultiselect.noProceed" style="margin-bottom: 8px">
        <span class="unselectable">
            {{ 'commons.selectValidPerson' | translate }}
        </span>
    </div>
    <div>
        <table *ngIf="names" class="table" style="width: 100%; table-layout: fixed;">
            <ng-container *ngFor="let i of createRange(actualPage, 30)">
                <tr *ngIf="names[i]">
                    <td class="listingCell listingCellName" [class.unselectable]="peopleMultiselect.validityCheck(false, i)">
                        {{ names[i][1] }}
<!--                       <div *ngIf="validityCheck(false, i)" class="errorMessage">-->
<!--                            <div style="padding-left: 10px">{{ 'people.multiselectAction.errorMessage' | translate }}</div>-->
<!--                            <div class="errorMark"></div>-->
<!--                       </div>-->
                    </td>
                    <td class="listingCell listingCellX">
                        <span
                            (click)="peopleMultiselect.removePerson(names[i][0])"
                            style="font-weight: bold; cursor: pointer">
                                x
                        </span>
                    </td>
                </tr>
            </ng-container>
        </table>
        <div class="arrowsNavWrap">
            <div class="pull-right">
                <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)"><img alt=">"
                                                                                                src="{{buttonsFolder}}next.png"></div>
                <div *ngIf="actualPage == maxPage || !maxPage" class="pull-right"><img alt=">" src="{{buttonsFolder}}next_deactivated.png"></div>
                <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img alt="<"
                                                                                           src="{{buttonsFolder}}before.png"></div>
                <div *ngIf="actualPage == 1" class="pull-right"><img alt="<" src="{{buttonsFolder}}before_deactivated.png"></div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
