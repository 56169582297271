import {animate, style, transition, trigger} from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiPeopleService} from '../../../services/api-people.service';
import {EvaluationRequestNext} from '../../../models/evaluation-request-next.model';
import {emailValidator} from '../../../validators/email';
import {TranslateService} from '@ngx-translate/core';
import {Subscription, Subject, Observable, combineLatest} from 'rxjs';
import {first, distinctUntilChanged, takeUntil, take} from 'rxjs/operators';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {StateService} from '../../../../core/services/state/state.service';
import {EvaluationRequest} from '../../../models/evaluation-request.model';
import {PeopleSurveyRequestFormService} from '../../../services/people-survey-request-form.service';
import {PersonService} from '../../../services/person.service';
import {PeopleSurveyRequestHelper} from '../../commonClasses/people-survey-request/people-survey-request.helper';
import {PeopleSurveyRequestSendMethodsHelper} from '../../commonClasses/people-survey-request/send-methods/send-methods.helper';
import {PeopleSurveyRequestStep} from '../../commonClasses/people-survey-request/people-survey-request-step.enum';
import {PeopleRequestSendModalCommon} from '../../commonClasses/people-request-send-modal-common';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {HttpClient} from '@angular/common/http';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ApiAdministrationService} from '../../../../administration/services/api-administration.service';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-people-request-send-resend-modal',
    templateUrl: './people-request-send-resend-modal.component.html',
    styleUrls: ['./people-request-send-resend-modal.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ]),
        trigger('fadeInOut2', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ])

    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleRequestSendResendModalComponent extends PeopleRequestSendModalCommon implements OnInit, OnDestroy {

    @Input() data;
    @Input() prbForm = false;
    @Input() raForm = false;
    @Input() resendForm = false;
    @Output() activation = new EventEmitter();

    public close: EventEmitter<any> = new EventEmitter();
    public surveyRequestFormGroup: FormGroup;
    public isFormReady = false;
    public researchAccount = false;
    public anotherRequest = false;
    public sessionData: any;
    public formOptions: any;
    public requestStep: number;
    public stepEnum = PeopleSurveyRequestStep;
    public isRequestFormSubmitted$: Observable<boolean>;
    public requestStep$: Observable<number>;
    public tempUser$: Observable<any>;
    public apiMessageError$: Observable<string>;
    private onDestroy$: Subject<null> = new Subject();

    evaluationRequestResend: EvaluationRequestNext = new EvaluationRequestNext();
    evaluationResendForm: FormGroup;

    variant;
    variantOptionsValue = {
        traitsAndPrb: '1,1',
        traitsOnly: '1,0'
    };

    nativeWindow: any;
    structureCountries: any;
    tempUser: any;
    person: any;
    evaluations: any;
    mailto: any;
    textToSend = '';
    mailHack = '';
    innerHtml;
    modalTitle = 'commons.sendMpo';
    country: string;
    language: string;
    resendType: string;
    introductionText: string;
    questionnaireType: string;
    accountType: any;
    flipValue: number;
    toggle = 0;
    continueClicked = false;
    emptyEmail = false;
    displayWarningMessage = false;
    satelliteReportSelected = false;
    satelliteJobs: any;
    creditAvailable: any = '';
    creditCost = 0;
    currentLanguage: string;
    disableDitReportByDefault = false;
    blurred = false;
    action;

    openOptions = [];

    reportToActivate = {
        mpo: false,
        dit: false,
        satellite: false,
        talents: false
    };

    reports = {
        mpo: false,
        dit: false,
        satellite: false,
        talents: false
    };

    protected subscriptions = new Subscription();

    evaluationFormUrl: string;

    constructor(
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        protected cd: ChangeDetectorRef,
        protected translateService: TranslateService,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        public stateService: StateService,
        protected fb: FormBuilder,
        public bsModalRef: BsModalRef,
        private http: HttpClient,
        private alertService: AlertService,
        public personService: PersonService,
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private apiPeopleService: ApiPeopleService,
        private windowRef: WindowRef,
        private environment: EnvService
    ) {
        super(apiJobsService, apiAdministrationService, cd, translateService, testAndReportHandlingService, stateService, fb);
        this.nativeWindow = windowRef.getNativeWindow();
        this.structureCountries = this.stateService.session.sessionData.structure.countries;
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    ngOnInit() {
        this.toggle = 0;
        this.flipValue = undefined;
        if (this.prbForm) {
            this.modalTitle = 'commons.sendAdditionalPrb';
        }
        if (this.raForm) {
            this.modalTitle = 'commons.sendIac';
        }
        if (this.resendForm) {
            this.modalTitle = 'commons.resendAccessCode';
        }
        if (!this.data) {
            // modal title for a new survey request modal
            this.modalTitle = 'people.requestModal.title';
        }
        this.getLanguages();
        if (this.resendForm) {
            this.getResendIntroductionText();
        }
        this.currentLanguage = this.translateService.currentLang;
        this.sessionData = this.stateService.session ? this.stateService.session.sessionData : null;
        this.accountType = this.sessionData ? this.sessionData.accountType : null;
        this.disableDitReportByDefault = !PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.accountType);

        // if it's not a new survey request
        if (this.targetPerson) {
            // Reinit credits vars
            this.displayWarningMessage = false;
            this.creditAvailable = '';
            // get credits available for this subAccount
            const subAccount = this.targetPerson.subAccount;
            // get current norm (country & language) if exists
            if (this.requestNorm) {
                const currentNorm = this.requestNorm.split(' ');
                if (currentNorm && currentNorm[0] && currentNorm[0].length === 2) {
                    this.country = currentNorm[0].toLowerCase();
                    if (currentNorm[0] === 'FR') {
                        this.country = 'eu';
                    }
                }
                if (currentNorm && currentNorm[1] && currentNorm[1].length === 2) {
                    this.language = currentNorm[1].toLowerCase();
                }
            }

            const department = (this.targetPerson.department && this.targetPerson.department[0]) ? this.targetPerson.department[0].id : null;
            this.emptyEmail = this.targetPerson.email === '';
            let formControls = {
                satelliteJobId: new FormControl(null),
                variant: new FormControl({value: null, disabled: true}, Validators.required),
                country: new FormControl(this.country, Validators.required),
                language: new FormControl(this.language, Validators.required),
                // Participant
                firstName: new FormControl(this.targetPerson.firstName, Validators.required),
                lastName: new FormControl(this.targetPerson.lastName, Validators.required),
                email: new FormControl(this.targetPerson.email, [Validators.required, Validators.email]),

                // Survey request
                jobTitle: new FormControl(this.targetPerson.jobTitle),
                organisation: new FormControl(this.targetPerson.organisation, (this.stateService.session.sessionData.accountData.type === 'bizdev'
                    || this.stateService.session.sessionData.accountData.type === 'discretionary'
                ) ? Validators.required : null),
                recordType: new FormControl(this.targetPerson.recordType),
                subAccount: new FormControl(this.targetPerson.subAccount, Validators.required),
                department: new FormControl(department),
                jobId: new FormControl(this.targetPerson.jobId),
                requestedByName: new FormControl((this.targetPerson.requestedByInfo) ? this.targetPerson.requestedByInfo.value : ''),
                requestedBy: new FormControl(this.sessionData.userData.username),
                requester: new FormControl(),
                sendingType: new FormControl(null, Validators.required),
                autoGen: new FormControl({value: false, disabled: true})
            };
            if (this.raForm || this.prbForm) {
                delete formControls.variant;
                formControls.autoGen = new FormControl({value: true, disabled: false});
            }
            if (this.resendForm) {
                // resend form case
                this.apiPeopleService.evaluationRequestResend([this.targetPerson.id, 0]).pipe(first()).subscribe(
                    resendData => {
                        this.evaluationRequestResend = new EvaluationRequestNext();
                        this.tempUser = (resendData) ? resendData : null;
                        this.person = this.targetPerson;
                        this.evaluations = this.person ? this.person.evaluations : null;
                        this.language = (this.evaluations &&
                            this.evaluations.requests &&
                            this.evaluations.requests[0]) ? this.evaluations.requests[0].language : null;
                        this.country = (this.evaluations &&
                            this.evaluations.requests &&
                            this.evaluations.requests[0]) ? this.evaluations.requests[0].country : null;
                        this.resendType = (this.data && this.data.type) ? this.data.type : null;
                        this.introductionText = this.resendType ? this.introductionTextObj[this.resendType] : null;
                        let raForm = false;
                        if (this.resendType === 'resendIac') {
                            raForm = true;
                        }
                        this.getSendingTypesList(raForm);

                        if (this.resendType === 'resendMpo') {
                            this.questionnaireType = 'MPO';
                        }
                        if (this.resendType === 'resendPrb') {
                            this.questionnaireType = this.introductionTextObj.prbTitle;
                        }
                        if (this.resendType === 'resendIac') {
                            this.questionnaireType = this.introductionTextObj.raTitle;
                        }

                        if (this.tempUser && this.tempUser.email) {
                            this.evaluationRequestResend.emailTo = this.tempUser.email;
                        }

                        // Init
                        this.initEvaluationResendForm();
                        if (this.person && this.tempUser) {
                            this.mailto = this.initMessage('mail', this.person, this.tempUser);
                            this.innerHtml = this.initMessage('cutnpaste', this.person, this.tempUser);
                        }
                        this.cd.markForCheck();
                    }
                );

            } else {
                // MPO / PRB / CAI form case
                this.getSendingTypesList(this.raForm);
                this.surveyRequestFormGroup = new FormGroup(formControls);
                if (!this.raForm && this.targetReport) {
                    this.checkIfActive({checked: true}, this.targetReport);
                }
                this.getCredits(subAccount);
                this.subscriptions.add(this.surveyRequestFormGroup.get('subAccount').valueChanges.subscribe(subAccountId => {
                    if (subAccountId && subAccountId.toString()) {
                        this.getCredits(subAccountId);
                    }
                    this.cd.markForCheck();
                }));
            }
            this.isFormReady = true;
            this.subscriptions.add(this.apiJobsService.jobs([{'isSatellite': 1, 'fields': 'id,jobTitle'}]).subscribe( res => {
                this.satelliteJobs = res.data;
                this.cd.markForCheck();
            }));
            if (this.prbForm || this.raForm) {
                this.surveyRequestFormGroup.get('language').disable();
                this.surveyRequestFormGroup.get('country').disable();
            }
        } else {
            // Form options for a new survey request
            this.formOptions = {
                isSatelliteOnly: this.stateService.session.hasSatelliteRole,
                canCreateDitWithoutMpo: PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.sessionData.accountType)
            };

            // Form structure
            this.surveyRequestFormGroup = this.peopleRequestFormService.initSurveyRequestFormGroup(this.sessionData, this.formOptions);

            // Request step
            this.requestStep$ = this.peopleRequestFormService.requestStep$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
            if (this.requestStep$) {
                this.requestStep$.subscribe((res) => {
                    this.requestStep = res;
                });
            }

            // Is request form submitted
            this.isRequestFormSubmitted$ = this.peopleRequestFormService.isRequestFormSubmitted$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());

            // API Error
            this.apiMessageError$ = this.peopleRequestFormService.apiMessageError$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());

            // Temp user after send the request to the API
            this.tempUser$ = this.peopleRequestFormService.tempUser$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
            if (this.tempUser$) {
                this.tempUser$.subscribe((res) => {
                    this.tempUser = res;
                });
            }

            this.setResearchAccount();
        }
    }

    /**
     * Check if the current account is a research account and
     * execute setup accordingly.
     */
    setResearchAccount() {
        // Lists of research accounts with lang and country parameters.
        // and then:
        this.http.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/researchAccounts', {responseType: 'text'})
            .subscribe(data => {
                const researchAccounts = JSON.parse(data);
                // Checks if current account is in the list of researchAccounts
                const accountNumber = parseInt(this.stateService.session.sessionData.accountNumber, 10);
                for (let researchAccount of researchAccounts) {
                    if (researchAccount.accountNumber === accountNumber) {
                        this.researchAccount = researchAccount;
                        break;
                    }
                }

                // Do the proper setup
                if (!!this.researchAccount) {
                    this.peopleRequestFormService.setResearchQuestionsForm(this.surveyRequestFormGroup, this.researchAccount);
                } else {
                    this.peopleRequestFormService.notAResearchQuestionsForm();
                }
            });
    }

    getCredits(subAccount) {
        this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
            [this.sessionData.accountData.id, subAccount]
        ).subscribe(
            (res) => {
                if (res.unlimited) {

                    this.creditAvailable = 'unlimited';
                    if (this.currentLanguage === 'fr') {
                        this.creditAvailable = 'illimité';
                    }
                    this.displayWarningMessage = false;
                } else {
                    this.creditAvailable = res.credits;
                    this.displayWarningMessage = (this.creditCost > this.creditAvailable);
                }
                if (this.displayWarningMessage) {
                    this.disableAuoGen();
                } else {
                    let hasSelReport = false;
                    if (!this.raForm) {
                        for (let report in this.reports) {
                            if (report && this.reports.hasOwnProperty(report) && this.reports[report]) {
                                hasSelReport = true;
                            }
                        }
                    } else {
                        hasSelReport = true;
                    }
                    if (hasSelReport) {
                        this.enableCheckAutoGen();
                    }
                }
                if (this.raForm) {
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost({'iac': true}, '0,0');
                } else if (this.prbForm) {
                    let hasPrb = !!(this.targetPerson &&
                        this.targetPerson.evaluations &&
                        this.targetPerson.evaluations.prb &&
                        this.targetPerson.evaluations.prb.list[0] &&
                        this.targetPerson.evaluations.prb.list[0].info &&
                        this.targetPerson.evaluations.prb.list[0].info.status);
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost({'mpo': true}, '0,1', !hasPrb);
                } else {
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost(this.reports, '1,1');
                }
                this.cd.markForCheck();
            }
        ));
    }

    computeCredits() {
        this.creditCost = PeopleSurveyRequestHelper.getCreditCost(this.reports, '1,1');
        this.displayWarningMessage = (this.creditCost > this.creditAvailable);
    }

    checkIfActive(event, type) {
        if (type) {
            this.satelliteReportSelected = false;
            if (this.raForm) {
                // CAI form case
                this.reportToActivate[type] = event.checked;
                for (let report in this.reportToActivate) {
                    if (report && report === 'satellite' && this.reportToActivate[report]) {
                        this.satelliteReportSelected = true;
                    }
                }
            } else {
                // MPO form case
                this.reports[type] = event.checked;
                this.computeCredits();
                let enableVariant = false;
                for (let report in this.reports) {
                    if (report && this.reports[report]) {
                        enableVariant = true;
                        if (report === 'satellite') {
                            this.satelliteReportSelected = true;
                        }
                    }
                }
                if (this.reports['mpo'] || this.reports['dit']) {
                    this.openOptions = ['traitsAndPrb', 'traitsOnly'];
                } else {
                    this.openOptions = ['traitsOnly'];
                }

                if (enableVariant) {
                    this.surveyRequestFormGroup.get('variant').enable({onlySelf: true, emitEvent: false});
                    if (this.displayWarningMessage) {
                        this.disableAuoGen();
                    } else {
                        this.enableCheckAutoGen();
                    }
                } else {
                    this.surveyRequestFormGroup.get('variant').disable({onlySelf: true, emitEvent: false});
                    this.disableAuoGen();
                }
            }
        }
    }

    enableCheckAutoGen() {
        this.surveyRequestFormGroup.get('autoGen').setValue(true, {onlySelf: true, emitEvent: false});
        this.surveyRequestFormGroup.get('autoGen').enable();
    }

    disableAuoGen() {
        this.surveyRequestFormGroup.get('autoGen').setValue(false, {onlySelf: true, emitEvent: false});
        this.surveyRequestFormGroup.get('autoGen').disable();
    }

    produceRequest(toggleNumber) {
        if (this.targetPerson) {
            const formattedValues = PeopleSurveyRequestHelper.formatRequestValues(this.surveyRequestFormGroup.value, this.researchAccount);
            let variant;
            let type;
            if (!this.raForm) {
                if (!this.prbForm) {
                    // MPO form case
                    variant = this.surveyRequestFormGroup.get('variant').value;
                    type = [];
                    for (const [key, value] of Object.entries(this.reports)) {
                        if (value === true) {
                            type.push(key);
                        }
                    }
                } else {
                    // Additional PRB form case
                    variant = '0,1';
                    type = ['mpo'];
                }

            } else {
                // CAI form case
                variant = '0,0';
                type = ['iac'];
            }
            let data = new EvaluationRequest({
                recordId: this.targetPerson.id,
                variant: variant,
                country: this.surveyRequestFormGroup.get('country').value,
                language: this.surveyRequestFormGroup.get('language').value,
                firstName: this.surveyRequestFormGroup.get('firstName').value,
                lastName: this.surveyRequestFormGroup.get('lastName').value,
                email: this.surveyRequestFormGroup.get('email').value,
                jobTitle: this.surveyRequestFormGroup.get('jobTitle').value,
                organisation: this.surveyRequestFormGroup.get('organisation').value,
                recordType: this.surveyRequestFormGroup.get('recordType').value,
                subAccount: this.surveyRequestFormGroup.get('subAccount').value,
                autoGen: this.surveyRequestFormGroup.get('autoGen').value,
                department: formattedValues.department,
                jobId: this.surveyRequestFormGroup.get('jobId').value,
                requestedByName: this.surveyRequestFormGroup.get('requestedByName').value,
                requestedBy: this.sessionData.userData.username,
                type: type,
                researchQuestions: false,
                createTempUser: true
                // hasSurveyRa: false <-- not necessary?
            });

            if (this.raForm) {
                for (let report in this.reportToActivate) {
                    if (report && this.reportToActivate[report]) {
                        this.activationData.type = report;
                        if (report === 'satellite') {
                            this.activationData.satelliteJobId = this.surveyRequestFormGroup.get('satelliteJobId').value;
                            this.bindedProfiler = this.surveyRequestFormGroup.get('satelliteJobId').value;
                        }
                        this.reportActivationCommon(report);
                        this.stateService.session.sessionCreditUpdateWatch.next(true);
                    }
                }
            }

            // Generate request
            this.apiPeopleService.evaluationRequest(data).subscribe(
                (res) => {
                    this.evaluationRequestResend = new EvaluationRequestNext();

                    this.tempUser = res;

                    this.person = this.targetPerson;

                    if (this.tempUser && this.tempUser.email) {
                        this.evaluationRequestResend.emailTo = this.targetPerson.email;
                    }

                    // Init
                    this.initEvaluationResendForm();
                    if (this.person && this.tempUser) {
                        this.mailto = this.initMessage('mail', this.person, this.tempUser);
                        this.innerHtml = this.initMessage('cutnpaste', this.person, this.tempUser);
                        this.cd.markForCheck();
                    }

                    // proceed to sending
                    if (toggleNumber === '3') {
                        this.mailHack = this.surveyRequestFormGroup.get('email').value;
                    }
                    this.toggle = toggleNumber;
                    this.cd.markForCheck();
                }
            );
        }
    }

    toggleFlip(sendingType = null) {
        if (sendingType && sendingType !== '1') {
            this.toggle = sendingType;
        } else {
            let n = this.surveyRequestFormGroup.get('sendingType').value;
            if (n !== '0' && n !== '1' && !this.displayWarningMessage) {
                this.produceRequest(n);
            }
        }
        return;
    }

    openEvaluationForm() {
        // Open window to avoid popup blocking

        let questionnaireWindow = this.nativeWindow.open('', '_blank');

        this.subscriptions.add(this.apiPeopleService.requestToken([this.person.id]).subscribe(
            res => {
                // Modification of URL in window open after getting data
                questionnaireWindow.document.location.href = this.evaluationFormUrl + '/autoconnect/' + res.token;
            }
        ));
    }

    initEvaluationResendForm() {
        this.evaluationResendForm = this.fb.group({
                emailTo: [this.evaluationRequestResend.emailTo],
                // subject : [this.evaluationRequestResend.subject],
                message: [(this.evaluationRequestResend.message) ? this.evaluationRequestResend.message : this.textToSend]
            },
            {
                validator: emailValidator('emailTo')
            });
    }

    initMessage(type, person, tempUser) {
        let hasIac = false;
        if (this.prbForm) {
            this.variant = '0,1';
        }
        if (this.raForm) {
            this.variant = '0,0';
            hasIac = true;
        }
        if (this.resendForm) {
            this.variant = this.data.person.evaluations.requests[0].variant;
            hasIac = this.data.person.evaluations.requests[0].type.includes('ra');
        }
        return PeopleSurveyRequestSendMethodsHelper.initMessage(
            type,
            person,
            tempUser,
            this.language,
            this.variant,
            {
                name: this.sessionData.userData.fullName,
                company: this.sessionData.structure.subAccounts[0].name
            },
            hasIac
        );
    }

    finishSending(openMail = false) {
        if (openMail) {
            let subject = (this.currentLanguage === 'fr') ? 'Questionnaire MPO' : 'MPO Questionnaire';
            if (this.raForm) {
                subject = (this.currentLanguage === 'fr') ? 'Questionnaire IAC' : 'CAI Questionnaire';
            }
            window.location.href = 'mailto:' + this.personEmail + '?subject=' + subject + '&body=' + this.mailto;
        }
        this.closeModal();
    }

    closeModal(event = null, xClick = false) {
        if (event === 'ngenio-server') {
            this.displayAlertMessage();
        }
        this.bsModalRef.hide();
        this.delay(1000).then(() => {
            this.resetData(event = null, xClick);
        });
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetData(event = null, xClick = false) {

        // Reset data
        this.toggle = 0;
        this.language = undefined;
        this.country = undefined;
        this.flipValue = undefined;
        this.evaluationRequestResend = new EvaluationRequestNext();
        if (this.targetPerson) {
            this.personService.loading = true;
            let personId = (this.targetPerson && this.targetPerson.id) ? this.targetPerson.id : null;
            if (personId) {
                this.personService.setPersonToDisplayWithId(personId);
            }
            this.close.emit({closed: true, id: personId});
            this.stateService.people.stateChanged.next({
                newPerson: true
            });
        } else {
            this.action = 'closeModal';
            // if X button has been clicked on the step of the sending type choosing
            if (xClick && event === this.stepEnum.SendMethods) {
                let sendingType = this.surveyRequestFormGroup.get('sendingType').value;
                if (sendingType === 'server') {
                    const warningMessage = {
                        texth1: 'Confirmation',
                        paragraph: this.translateService.instant('people.requestModal.cancelSending'),
                        position: 'center-center',
                        template: 'warningConfirm'
                    };
                    // Display alert
                    this.alertService.setConfig(warningMessage);
                    if (this.tempUser && this.tempUser.additionalData && this.tempUser.additionalData.recordId) {
                        this.action = 'delete';
                        this.alertService.nextAction$.subscribe(
                            res => {
                                if (res === 'confirm' && this.action) {
                                    // delete questionnaire
                                    this.personService.loading = true;
                                    this.subscriptions.add(this.apiPeopleService.deleteRecord([this.tempUser.additionalData.recordId]).pipe(take(1)).subscribe(
                                        () => {
                                            this.personService.loading = false;
                                            this.stateService.people.stateChanged.next({
                                                canceled: true
                                            });
                                            this.bsModalRef.hide();
                                        })
                                    );
                                    this.action = undefined;
                                }
                            }
                        );
                    }
                }
            }
            if (this.action === 'closeModal') {
                this.resetRequestForm();
                if (this.tempUser && this.tempUser.additionalData && this.tempUser.additionalData.recordId) {
                    this.personService.setPersonToDisplayWithId(this.tempUser.additionalData.recordId, 'forceSidebarToReport');
                }
                this.stateService.people.stateChanged.next({
                    newPerson: true
                });
                this.bsModalRef.hide();
            }
            if ((event && event.submitted) || (event && event === this.stepEnum.SendMethods)) {
                // Cancel / Close button click on the Send Methods step
                // if (this.tempUser && this.tempUser.additionalData && this.tempUser.additionalData.recordId) {
                //     this.personService.loading = true;
                //     this.subscriptions.add(this.apiPeopleService.deleteRecord([this.tempUser.additionalData.recordId]).pipe(take(1)).subscribe(
                //         () => {
                //             this.personService.loading = false;
                //             this.stateService.people.stateChanged.next({
                //                 canceled: true
                //             });
                //         })
                //     );
                // }
                this.close.emit({submitted: true});
            }
        }
    }

    formValidate() {
        this.continueClicked = true;
        if (this.surveyRequestFormGroup.valid) {
            this.toggleFlip();
        }
    }

    onFormChange(requestForm: any) {
        if (requestForm.form && requestForm.form.value) {
            this.emptyEmail = requestForm.form.value.emailTo === '';
        }
    }

    onSubmit(model: any) {
        // new survey request case
        if (!this.data) {
            this.close.emit({submitted: true});
        } else {
            let email = (this.mailHack) ? this.mailHack : model.value.emailTo;
            this.personService.loading = true;
            this.subscriptions.add(this.apiPeopleService.emailEvaluationSurvey(
                [this.tempUser.id],
                [email, model.value.message, false]
            ).subscribe(
                () => {
                    this.personService.loading = false;
                    this.displayAlertMessage();
                    this.closeModal();
                },
                error => {
                    console.log(error._body);
                }
            ));
        }
    }

    checkFirstQ() {
        if (this.evaluations) {
            let minDate;
            let firstQ;
            for (let ev in this.evaluations) {
                if (ev && ev !== 'requests' && ev !== 'researchQuestions' &&
                    ev !== 'prb' && ev !== 'iac') {
                    if (this.evaluations.hasOwnProperty(ev) && this.evaluations[ev]) {
                        if (!minDate || this.evaluations[ev].info.date < minDate) {
                            minDate = this.evaluations[ev].info.date;
                            firstQ = ev;
                        }
                    }
                }
            }
            if (!firstQ && this.evaluations.requests && this.evaluations.requests[0]) {
                firstQ = this.evaluations.requests[0].type[0];
            }
            return TextHelper.checkReportName(firstQ, this.currentLanguage);
        }
        return;
    }

    getCountryName(countryCode) {
        if (this.structureCountries && this.structureCountries[this.currentLanguage]) {
            const thisCountry = this.structureCountries[this.currentLanguage].filter(country => country.value === countryCode);
            return thisCountry[0].name;
        }
    }

    /**
     * Send another request
     */
    public sendAnotherRequest(): void {
        this.anotherRequest = true;
        this.resetRequestForm();
        this.displayAlertMessage();
    }

    /**
     * Display confirmation message in alert
     */
    private displayAlertMessage(): void {
        // Get translations
        combineLatest(
            this.translateService.get('people.alert.successRequest.title'), this.translateService.get('people.alert.successRequest.paragraph')
        ).pipe(take(1)).subscribe((translationData: any) => {
            // Sending confirmation
            const message = {
                texth1: translationData[0],
                paragraph: translationData[1],
                position: 'top-right',
                type: 'green'
            };
            // Display alert
            this.alertService.setConfig(message);
        });
    }

    /**
     * Reset form values and Observables
     */
    public resetRequestForm(): void {
        this.peopleRequestFormService.sendAnotherProcess(this.surveyRequestFormGroup, this.anotherRequest);
        this.anotherRequest = false;
    }

    get requestNorm() {
        if (this.targetPerson &&
            this.targetPerson.evaluations
        ) {
            let currentNorm = '';
            ['mpo', 'dit', 'talents', 'satelite', 'iac'].forEach(request => {
                if (this.targetPerson.evaluations[request] &&
                    this.targetPerson.evaluations[request].info &&
                    currentNorm === '') {
                    currentNorm = this.targetPerson.evaluations[request].info.norm;
                }
            });
            return currentNorm;
        }
        return;
    }

    get targetPerson() {
        return (this.data && this.data.hasOwnProperty('person')) ? this.data['person'] : null;
    }

    get targetReport() {
        return (this.data && this.data.hasOwnProperty('report')) ? this.data['report'] : null;
    }

    get personEmail() {
        return this.targetPerson ? this.targetPerson.email : null;
    }

    get questionnaireInitialType() {
        if (this.resendType === 'resendIac' && this.person.testStatuses && this.person.testStatuses.mpo !== 'NC') {
            return 'ra';
        }
        if (this.evaluations && this.evaluations.requests &&
            this.evaluations.requests[0] && this.evaluations.requests[0].variant) {
            if ((this.resendType === 'resendMpo' && this.evaluations.requests[0].type.includes('mpo')) ||
                (this.resendType === 'resendPrb' && this.evaluations.requests[0].type.includes('prb'))) {
                this.language = this.evaluations.requests[0].language;
                this.country = this.evaluations.requests[0].country;
                if (this.evaluations.requests[0].type.includes('ra')) {
                    return this.evaluations.requests[0].variant + ',ra';
                }
                return this.evaluations.requests[0].variant;
            }
        }
        return (this.person && this.person.questionnairesData && this.person.questionnairesData.initial) ?
            this.person.questionnairesData.initial.type : null;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        // new survey request case
        if (!this.data) {
            this.onDestroy$.next();
            this.onDestroy$.complete();

            // Clear form data
            this.peopleRequestFormService.resetFormProcess(this.surveyRequestFormGroup);
        }
    }

}
