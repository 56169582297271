<div (window:resize)="onResize($event)" id="container" style="z-index: 10001">
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px' }"></ngx-loading>
    <div class="clearfix"></div>
    <!--
    <div id="alert-login">
        <div
            id="alert-login-container"

        >
            <div
                id="alert-login-box"
            >
                <b>{{ 'login-alerts.warning' | translate }}</b> {{ 'login-alerts.first' | translate }}
                <ul>
                    <li>{{ 'login-alerts.li2' | translate }}</li>
                </ul>

                {{ 'login-alerts.last' | translate }}
            </div>
        </div>
    </div>
    -->
    <div id="loginBox" class="row">
        <div class="col-md-8 col-md-offset-2">
            <div class="row">
                <div class="pull-left">
                    <div id="logoContainer">
                        <img src="../../../../assets/login/logoLogin.png"/>
                    </div>
                </div>
                <div class="pull-right">
                    <div class="pull-right languageSwitch">
                        <a *ngIf="translate.currentLang!='fr'" (click)="changeLang('fr')">Français</a>
                        <a *ngIf="translate.currentLang!='en'" (click)="changeLang('en')">English</a>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="pull-left">
                    <h1>{{ 'login.connect' | translate }}</h1>
                </div>
                <div class="pull-right">
                    <div *ngIf="versionNumber" style="padding-top: 18px;">
                        {{ versionNumber }}
                    </div>
                </div>
                <div class="clearfix"></div>
                <hr/>
            </div>
            <div
                [style.width]="(small) ? 'inherit' : null"
                [style.margin]="(small) ? 'auto' : null"
                [class.row]="!small"
            >
                <div
                    [class.col-md-5]="!small"
                    [class.col-md-offset]="!small"
                    [class.pull-right]="!small"
                >
                    <div
                        id="tagLineContainer"
                    >
                        <p class="pNull">
                            <span id="tagTop">{{ 'login.acquisition' | translate }}</span>
                        </p>
                        <p class="pNull">
                            <span id="tagMid">{{ 'login.development' | translate }}</span>
                            <span class="tagEnd"><em>{{ 'login.and' | translate }}</em></span>
                        </p>
                        <p class="pNull">
                            <span
                                class="tagEnd tagEndBottom"><em>{{ 'login.talentsManagement' | translate }}</em></span>
                        </p>
                    </div>
                </div>
                <div
                    [class.col-md-6]="!small"
                    [class.pull-left]="!small"
                >
                    <div style="height: 33px">
                        <span *ngIf="errorLogin" style="color: #f27b66">{{ errorLogin }}</span>
                        <span *ngIf="passwordChangeSuccess"
                              style="color: springgreen">{{ 'login.passwordChangeSuccess' | translate }}</span>
                    </div>
                    <app-form-table
                        [formDefinition]="loginForm"
                        (formOutput)="handleForm($event)"
                    ></app-form-table>

                    <div *ngIf="baseUrl === 'https://staging.ngenioconnect.com/'">
                        <div>
                            Staging password
                        </div>
                        <div>
                            <input type="password" style='color:black' [(ngModel)]="passwordStaging" name="passwordStaging">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
