<div *ngIf="tempUser && instructionsMessages" [ngSwitch]="sendMethod">
    <div *ngSwitchCase="'cutpaste'">
        <!-- COPY / PASTE -->
        <app-people-survey-request-send-method-copypaste
            [instructionsMessages]="instructionsMessages"
            [surveyLink]="decodedSurveyLink"
            [username]="tempUser.username"
            [password]="tempUser['tmpPwd']"
            [formSubmit$]="formSubmit$"
            (afterSubmission)="afterSubmission()"
        ></app-people-survey-request-send-method-copypaste>
    </div>

    <div *ngSwitchCase="'email'">
        <!-- Email client -->
        <app-people-survey-request-send-method-email-client
            [instructionsMessages]="instructionsMessages"
            [surveyLink]="surveyLink"
            [username]="tempUser.username"
            [password]="tempUser['tmpPwd']"
            [fullName]="tempUser['fullName']"
            [email]="tempUser['email']"
            [formSubmit$]="formSubmit$"
            (afterSubmission)="afterSubmission()"
        ></app-people-survey-request-send-method-email-client>
    </div>

    <div *ngSwitchCase="'server'">
        <!-- Ngenio server -->
        <app-people-survey-request-send-method-ngenio-server
            [instructionsMessages]="instructionsMessages"
            [surveyLink]="decodedSurveyLink"
            [tempUserId]="tempUser.id"
            [username]="tempUser.username"
            [password]="tempUser['tmpPwd']"
            [email]="tempUser['email']"
            [formSubmit$]="formSubmit$"
            (afterSubmission)="afterSubmission($event)"
        ></app-people-survey-request-send-method-ngenio-server>
    </div>

    <div *ngSwitchCase="'paper'">
        <!-- Paper (external survey) -->
        <app-people-survey-request-send-method-paper
            [recordId]="tempUser.additionalData['recordId']"
            [formSubmit$]="formSubmit$"
            (afterSubmission)="afterSubmission()"
        ></app-people-survey-request-send-method-paper>
    </div>

    <div *ngSwitchDefault>ERROR</div>

    <mat-grid-list cols="3" rowHeight="60">
        <mat-grid-tile
            colspan="1"
            rowspan="1">
            <mat-checkbox
                id="report_resend"
                [checked]="sendAnotherSurvey"
                (change)="toggleSendAnotherSurvey()"
            >{{ 'commons.sendAnother' | translate }}</mat-checkbox>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1">
            <button *ngIf="sendMethod !== 'paper' && false"
                    mat-stroked-button
                    type="button"
                    class="modalButton alignRight"
                    (click)="cancelRequest()"
            >{{ 'commons.cancel' | translate }}</button>
            <div class="clear"></div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1">
            <button
                mat-stroked-button
                class="modalButton alignRight"
                (click)="submit()"
            >{{ buttonText | translate }}</button>
            <div class="clear"></div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
