<div
    *ngIf="!this.deviceService.isMobile(); else mobile"
    class="box"
>
    <div class="floatRight"
         [id]="cleanedName(record['firstName'] + ' ' + record['lastName'])"
         style="padding-right: 10px; cursor: pointer"
         (click)="removeThis()"
    >
        {{ 'jobSatisfactions.report.remove' | translate }}
    </div>
    <div class="clearfix"></div>
    <div class="personsHeaderList">
        <div class="floatLeft orangeTitleLink">
    <span class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
        {{ record['firstName'] }} {{ record['lastName'] }}
    </span>
        </div>
        <div class="floatRight" style="margin-left: 10px">
            <button type="button"
                    class="btn-person-card btn btn-default"
                    (click)="toggleInformationVisibility()"
                    style="border-radius: 0"
            >
                <span *ngIf="!informationVisibility">+</span>
                <span *ngIf="informationVisibility">-</span>
            </button>
        </div>
        <div class="floatRight">
            <app-job-satisfaction-graphics
                class="floatRight"
                [background]="'regular'"
                [score]="record.jobSatisfactionScore"
                [id]="'satisfaction_person_' + record['id']"
            >
            </app-job-satisfaction-graphics>
        </div>
        <div class="clearfix"></div>

    </div>
    <div class="body-card-text" *ngIf="informationVisibility">
        <div class="floatLeft" style="width: 40%">
            <app-job-satisfactions-person-sub-card-personality
                [record]="record"
                [informationVisibility]="true"
                [truncate]="true"
            ></app-job-satisfactions-person-sub-card-personality>
        </div>
        <div class="floatRight personContainer">
            <div *ngIf="record.prb[0] && record.prb[0].date" class="personsSubHeader">
                <div class="floatRight">
                    <div class="floatLeft prbDate">
                        {{ 'jobSatisfactions.report.lastPrbsDate' | translate }} {{ record.prb[0].date | date:TextHelper.dateFormat(this.translateService.currentLang) }}
                    </div>
                    <div class="floatRight">
                        <app-job-satisfaction-graphics
                            [background]="'regular'"
                            [score]="redoPcr(record)"
                            [type]="'pcr_redo'"
                            [id]="'pcr_redo_person_' + record['id']"
                        ></app-job-satisfaction-graphics>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="jobSatisfactionText">
                <div class="subsubHeader">
                    {{ 'jobSatisfactions.report.history' | translate }}
                </div>
                <div style="width: 100%">
                    <div style="margin: 15px auto auto auto; width: 350px; margin: auto">
                        <app-job-satisfactions-person-history
                            [record]="record"
                        ></app-job-satisfactions-person-history>
                    </div>
                </div>
            </div>
            <div>
                <div
                    class="buttons"
                >
                    <div class="button floatLeft"
                         (click)="goToPerson(record.id)"
                    >
                        {{ 'jobSatisfactions.report.rapportMPO' | translate }}
                    </div>
                    <div class="button floatRight"
                         [style.pointer-events]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? 'none' : 'auto'"
                         [style.background-color]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? '#888' : 'auto'"
                         (click)="openJobSatisfactionPcrReport({
                                        id: record['id'],
                                        jobSatisfactionScore: record['jobSatisfactionScore'],
                                        personalityScores: (record['scores']) ? record['scores'] : null,
                                        prbScores: (record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? (record['prb'][0]['scores']) : null
                                    })"
                    >
                        {{ 'jobSatisfactions.report.detailedAnalysis' | translate }}
                    </div>
                    <div class="clearfix"></div>
                    <div class="button buttonLarge"
                         [class.redButton]="flagPrbToSend(record)"
                         (click)="openModal('sendAdditionalPrb')"
                    >
                        {{ 'jobSatisfactions.report.sendNewQuestionnaire' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
<ng-template #mobile>
    <div
        class="box"
    >
        <div class="floatRight"
             style="padding-right: 10px; cursor: pointer"
             (click)="removeThis()"
        >
            {{ 'jobSatisfactions.report.remove' | translate }}
        </div>
        <div class="clearfix"></div>
        <div class="personsHeaderList">
            <div class="floatLeft orangeTitleLink">
                <span class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
                    {{ record['firstName'] }} {{ record['lastName'] }}
                </span>
            </div>
            <div class="floatRight" style="margin-left: 10px">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="floatRight">
                <app-job-satisfaction-graphics
                    class="floatRight"
                    [background]="'regular'"
                    [score]="record.jobSatisfactionScore"
                    [id]="'satisfaction_person_' + record['id']"
                >
                </app-job-satisfaction-graphics>
            </div>
            <div class="clearfix"></div>

        </div>
        <div class="body-card-text" *ngIf="informationVisibility">
            <div>
                <app-job-satisfactions-person-sub-card-personality
                    [record]="record"
                    [informationVisibility]="true"
                    [truncate]="true"
                ></app-job-satisfactions-person-sub-card-personality>
            </div>
            <div>
                <div *ngIf="record.prb[0] && record.prb[0].date" class="personsSubHeader" style="height: 55px">
                    <div>
                        <div class="floatLeft"
                             style="font-family: 'MontserratSemiBold',  sans-serif; padding-left: 8px;">
                            {{ 'jobSatisfactions.report.lastPrbsDate' | translate }} <br>
                            {{ record.prb[0].date | date: 'dd/MM/yyyy' }}
                        </div>
                        <div class="floatRight" style="padding-top: 5px">
                            <app-job-satisfaction-graphics
                                [background]="'regular'"
                                [score]="redoPcr(record)"
                                [type]="'pcr_redo'"
                                [id]="'pcr_redo_person_' + record['id']"
                            ></app-job-satisfaction-graphics>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="jobSatisfactionText">
                    <div class="subsubHeader">
                        {{ 'jobSatisfactions.report.history' | translate }}
                    </div>
                    <div style="width: 100%">
                        <div style="margin: 15px auto auto auto">
                            <app-job-satisfactions-person-history
                                [mobile]="true"
                                [record]="record"
                            ></app-job-satisfactions-person-history>
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        class="buttons"
                    >
                        <div class="button floatLeft"
                             (click)="goToPerson(record.id)"
                        >
                            {{ 'jobSatisfactions.report.rapportMPO' | translate }}
                        </div>
                        <div class="button floatRight"
                             [style.pointer-events]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? 'none' : 'auto'"
                             [style.background-color]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? '#888' : 'auto'"
                             (click)="openJobSatisfactionPcrReport({
                                        id: record['id'],
                                        jobSatisfactionScore: record['jobSatisfactionScore'],
                                        personalityScores: (record['scores']) ? record['scores'] : null,
                                        prbScores: (record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? (record['prb'][0]['scores']) : null
                                    })"
                        >
                            {{ 'jobSatisfactions.report.detailedAnalysis' | translate }}
                        </div>
                        <div class="clearfix"></div>
                        <div class="button buttonLarge"
                             [class.redButton]="flagPrbToSend(record)"
                             (click)="openModal('sendAdditionalPrb')"
                        >
                            {{ 'jobSatisfactions.report.sendNewQuestionnaire' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</ng-template>
