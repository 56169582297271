<div *ngIf="rightMatch.id && stateService.rightMatch && displayValuesLoaded && records"
     class="rightWrapper row"
     (window:resize)="onResize($event)">
    <ng-container *ngIf="!small">
        <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4" [style.width]="(!small) ? sideWidth : null"
             style="overflow-y: auto; overflow-x: hidden">
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="rightMatch.itemToDisplay">
                    <app-right-matches-sidebar
                        [width]="sideWidth"
                        [config]="'report'"
                        (sortResults)="sortResults($event)"
                    ></app-right-matches-sidebar>
                </div>
            </div>
        </div>
    </ng-container>
    <div
         [class.col-md-8]="!small"
         [class.col-md-12]="small"
         [style.padding-top]="(small) ? null : '40px'"
         [style.width]="(!small) ? reportWidth : null"
         id="scrollBody"
    >
        <div *ngIf="small && rightMatch.itemToDisplay">
            <app-right-matches-sidebar
                [tabletMode]="true"
                [width]="sideWidth"
                [config]="'report'"
                (sortResults)="sortResults($event)"
            ></app-right-matches-sidebar>
        </div>
        <ul *ngIf="!small" class="nav nav-tabs" style="border-bottom: 1px solid #ddd;">
            <li class="tab" [class.active]="tabName === 'concordanceAnalysis'">
                <a class="tdnone" (click)="switchTab('concordanceAnalysis')">
                    {{ 'rms.report.concordanceAnalysisRegularCase' | translate}}
                </a>
            </li>
            <li class="tab" [class.active]="tabName === 'jobDesign'">
                <a class="tdnone" (click)="switchTab('jobDesign')">
                    {{ 'jobs.commons.jobDesign' | translate }}
                </a>
            </li>
            <li class="tab" [class.active]="tabName === 'targetPersonality'">
                <a class="tdnone" (click)="switchTab('targetPersonality')">
                    {{ 'jobs.commons.targetPersonalityProfile' | translate }}
                </a>
            </li>
            <li class="tab" [class.active]="tabName === 'targetCompetencies'">
                <a class="tdnone" (click)="switchTab('targetCompetencies')">
                    {{ 'jobs.commons.targetCompetenciesProfile' | translate }}
                </a>
            </li>
            <li class="tab" [class.active]="tabName === 'targetCai'">
                <a class="tdnone" (click)="switchTab('targetCai')">
                    {{ 'jobs.commons.targetCaiProfile' | translate }}
                </a>
            </li>
        </ul>
        <div *ngIf="tabName === 'targetPersonality'">
            <app-right-matches-report-job-personality></app-right-matches-report-job-personality>
        </div>
        <div *ngIf="tabName === 'targetCai'">
            <app-right-matches-report-job-iac
            ></app-right-matches-report-job-iac>
        </div>
        <div *ngIf="tabName === 'targetCompetencies'">
            <app-right-matches-report-job-competencies>
            </app-right-matches-report-job-competencies>
        </div>
        <div *ngIf="tabName === 'jobDesign'">
            <app-right-matches-report-job-description
            ></app-right-matches-report-job-description>
        </div>
        <div
            *ngIf="tabName === 'concordanceAnalysis'"
            [style.padding-right]="(small) ? '15px' : 'auto'"
        >
            <h1 class="mainTitleRmSb floatLeft">{{ 'rms.report.concordanceAnalysisRegularCase' | translate }}</h1>
            <div class="floatRight smallTxt">
                <div style="margin-top: 23px">
                <span
                    style="font-family: MontserratSemiBold,  sans-serif">{{ 'people.reportSatellite.concordanceLevel' | translate }}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span style="color: #78c7af">⬤</span> {{ "rms.report.high" | translate }}&nbsp;&nbsp;
                    <span style="color: #efce7c">⬤</span> {{ "rms.report.average" | translate }} &nbsp;&nbsp;
                    <span style="color: #f07b7e">⬤</span> {{ "rms.report.low" | translate }}
                </div>
            </div>
            <div class="clearfix"></div>
            <div style="border-bottom: 2px solid #999; margin-bottom: 10px;">
                <div class="floatLeft">
                    <app-nav-button
                        [backgroundColor]="'rgb(227, 94, 36)'"
                        [textColor]="'white'"
                        [border]="'0'"
                        [swapContent]="true"
                        [isSidebar]="false"
                        [isRightMatch]="true"
                        (click)="rightMatchModalHandlingService.openRightMatchPersonModal()"
                    >
                        <div class="buttonImage">
                            <img
                                src="../../../../../../assets/buttons/rightMatchReport/bouton-ajouter-individu.png"
                                height="22"
                                width="22"
                            />
                        </div>
                        <div class="buttonTitle">{{ 'rms.report.addPerson' | translate }}</div>
                    </app-nav-button>
                    <div class="clearfix"></div>
                </div>
                <div class="floatRight">
                    <div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="'0'"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isRightMatch]="true"
                            style="margin-left: 5px;"
                            (click)="openRightMatchReportsFiltersModal()"
                        >
                            <div class="buttonImage">
                                <img src="../../../../../../assets/buttons/rightMatchReport/bouton-filtrer.png"
                                     height="19" width="19" style="margin-top: 2px"/>
                            </div>
                            <div class="buttonTitle titleRight">{{ 'analysis.rightMatch.filter' | translate }}</div>
                        </app-nav-button>
                    </div>
                    <div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="'0'"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isRightMatch]="true"
                            style="margin-left: 5px"
                            (click)="togglePersonsCards()"
                        >
                            <div class="buttonImage">
                                <img *ngIf="!minimizeState"
                                     src="../../../../../../assets/buttons/rightMatchReport/bouton-minimiser.png"
                                     height="22" width="22"/>
                                <img *ngIf="minimizeState"
                                     src="../../../../../../assets/buttons/rightMatchReport/bouton-maximiser.png"
                                     height="22" width="22"/>
                            </div>
                            <div *ngIf="!minimizeState"
                                 class="buttonTitle titleRight">{{ 'commons.minimize' | translate }}</div>
                            <div *ngIf="minimizeState"
                                 class="buttonTitle titleRight">{{ 'commons.maximize' | translate }}</div>
                        </app-nav-button>
                    </div>
                    <div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="0"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isRightMatch]="true"
                            (click)="toggleListView()"
                        >
                            <div class="buttonImage">
                                <img *ngIf="!listView"
                                     src="../../../../../../assets/buttons/rightMatchReport/bouton-liste.png"
                                     height="22" width="22"/>
                                <img *ngIf="listView"
                                     src="../../../../../../assets/buttons/rightMatchReport/bouton-grille.png"
                                     height="22" width="22"/>
                            </div>
                            <div *ngIf="!listView"
                                 class="buttonTitle titleRight">{{ "rms.report.list" | translate }}</div>
                            <div *ngIf="listView"
                                 class="buttonTitle titleRight">{{ "rms.report.grid" | translate }}</div>
                        </app-nav-button>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <ng-container
                *ngIf="listView"
            >
                <div [style.max-width]="(targetToDisplay === 3) ? '1372px' : '903px'">
                    <ng-container *ngFor="let record of records">
                        <app-right-matches-person-card
                            [record]="record"
                            [job]="rightMatch.itemToDisplay.profiler"
                            [minimizeAll]="minimizeAll"
                            [listView]="listView"
                        ></app-right-matches-person-card>
                    </ng-container>
                </div>
            </ng-container>
            <ng-container
                *ngIf="!listView"
            >

                <div class="first">
                    <ng-container
                        *ngFor="let in of [].constructor(numberOfLines); let i=index"
                    >
                        <app-right-matches-person-card
                            *ngIf="this.records[i*3]"
                            [record]="this.records[i*3]"
                            [pos]="i + '_0'"
                            [job]="rightMatch.itemToDisplay.profiler"
                            [minimizeAll]="minimizeAll"
                            [listView]="listView"
                        ></app-right-matches-person-card>
                        <div class="clearfix"></div>
                        <div class="cardSpacer"></div>
                    </ng-container>

                </div>
                <div class="second">
                    <ng-container
                        *ngFor="let in of [].constructor(numberOfLines); let j=index"
                    >
                        <app-right-matches-person-card
                            *ngIf="this.records[j*3 + 1]"
                            [record]="this.records[j*3 + 1]"
                            [pos]="j + '_1'"
                            [job]="rightMatch.itemToDisplay.profiler"
                            [minimizeAll]="minimizeAll"
                            [listView]="listView"
                        ></app-right-matches-person-card>
                        <div class="clearfix"></div>
                        <div class="cardSpacer"></div>
                    </ng-container>
                </div>
                <div class="third">
                    <ng-container
                        *ngFor="let in of [].constructor(numberOfLines); let k=index"
                    >
                        <app-right-matches-person-card
                            *ngIf="this.records[k*3 + 2]"
                            [record]="this.records[k*3 + 2]"
                            [pos]="k + '_2'"
                            [job]="rightMatch.itemToDisplay.profiler"
                            [minimizeAll]="minimizeAll"
                            [listView]="listView"
                        ></app-right-matches-person-card>
                        <div class="clearfix"></div>
                        <div class="cardSpacer"></div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
