<div class="modal-header">
    <h4 *ngIf="status!='edit'"
        class="modal-title pull-left">{{ 'jobSatisfactions.modal.newJobSatifaction' | translate }}</h4>
    <h4 *ngIf="status=='edit'"
        class="modal-title pull-left">{{ 'jobSatisfactions.modal.modJobSatifaction' | translate }}</h4>
    <div
        class="pull-left text-danger"
        style="margin-left: 15px; margin-top: 2px"
        *ngIf="warning === true"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
<div class="modal-body">
    <div class="headerText" *ngIf="headerText">
    </div>
    <form [formGroup]="jobSatisfactionForm" #jobSatisfactionNgForm="ngForm">
        <div class="form-group">

            <div>
                <div class="pull-left"
                     [class.text-danger]="errorFields.includes('name')"
                >
                    <label class="field_title" for="jobSatisfactionName">
                        {{ 'jobSatisfactions.modal.jobSatifactionName' | translate }}
                    </label>
                </div>
                <div class="clearfix"></div>
                <input type="text" id="jobSatisfactionName" class="form-control" formControlName="name">
            </div>

            <div>
                <div class="pull-left"
                     [class.text-danger]="errorFields.includes('subAccount')"
                >
                    <label class="field_title" for="subAccountSelect">{{ 'commons.subAccount' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="subAccountSelect" formControlName="subAccount">
                    <option>{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <div>
                <div class="pull-left">
                    <label class="field_title" for="consultant">
                        {{ 'commons.requester' | translate }}
                    </label>
                </div>
                <div class="pull-right">
                </div>
                <div class="clearfix"></div>
                <input type="text" class="form-control" id="consultant" formControlName="consultant">
            </div>

            <!-- department -->
            <div *ngIf="departments">
                <div class="pull-left">
                    <label class="field_title" for="division">{{ 'commons.division' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="division" formControlName="department">
                    <option value="">{{ 'commons.none' | translate }}</option>
                    <option *ngFor="let dpt of departments" [value]="dpt.id">
                        {{dpt['fr']}}
                    </option>
                </select>
            </div>
        </div>
    </form>
    <div class="pull-left" style="padding-top: 10px">
        {{ headerText }}
    </div>
    <div class="pull-right">
        <div class="searchBarComponent" style="padding-bottom: 10px">
            <label>
                <input class="searchBar" onfocus="this.select();" [(ngModel)]="searchTerm"
                       (keydown)="onSearch($event, searchTerm, 'return')"/>
            </label>
            <div *ngIf="!actual" class="magnifyingGlass" (click)="onSearch($event, searchTerm, 'click')">
                <img src="../../../../../../assets/header/search_and_filters/ico_search.png" alt=""/>
            </div>
            <div *ngIf="actual" class="closeSearch" (click)="clearSearch()">
                <img class="closeIdle" src="../../../../../../assets/buttons/close.png" alt=""/>
                <img class="closeHover" src="../../../../../../assets/buttons/close_hover.png" alt=""/>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div
        id="candidateList"
        *ngIf="records"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
    >
        <ngx-loading [show]="loading" [config]="{
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
        }"></ngx-loading>
        <table style="width: 100%">
            <tr class="candidateRow" *ngFor="let record of records; let i = index">
                <td style="padding: 4px">
                    <mat-checkbox
                        (change)="onChecked(record.id, $event)"
                        id="record_{{ i }}"
                        [checked]="selectedItems.indexOf(record.id) !== -1"
                    >
                        {{ record['firstName'] }} {{ record['lastName'] }}
                    </mat-checkbox>
                </td>
                <td style="padding: 4px; text-align: center">
                    {{ formatDateWithLang(record.creationDate, translate.currentLang) }}
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="modal-footer">
    <button
        type="submit"
        class="modalButton pull-right"
        [disabled]="!jobSatisfactionForm.valid && jobSatisfactionNgForm.submitted"
        (click)="onSubmit(jobSatisfactionForm)"
        style="margin-top: 15px"
    >
            <span *ngIf="status !== 'edit'">
            {{ 'commons.create' | translate }}
            </span>
        <span *ngIf="status === 'edit'">
            {{ 'commons.save' | translate }}
            </span>
    </button>
    <button (click)="closeModal()" type="button" class="modalButton pull-right"
            style="margin-top: 15px; margin-right: 15px"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <div class="clearfix"></div>
</div>
