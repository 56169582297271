<mat-grid-list *ngIf="!addAnalysis" cols="5" rowHeight="60px">
    <mat-grid-tile
        colspan="1"
        rowspan="1"
        style="max-width: 87px;"
    >
        <app-mobile-navigation></app-mobile-navigation>
        <a
            class="reloadLink"
            (click)="goToPeople()"
        >
            <img alt="N" src="/assets/menu_gauche/MPO_32px.png">
        </a>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <app-mobile-main-list-search-bar
            [initialValue]="searchValue"
            (searchEvent)="onSearchEvent($event)"
            style="width: 85%"
        ></app-mobile-main-list-search-bar>
    </mat-grid-tile>
</mat-grid-list>
