<div id="container" style="z-index: 10000">
    <div id="loginBox" class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="pull-left">
                    <div id="logoContainer">
                        <img alt="MPO" src="../../../../assets/login/logoLogin.png"/>
                    </div>
                    <h1>{{ 'setPass.changePassword' | translate }}</h1>
                </div>
                <div class="pull-right">
                    <div style="margin-top: 25px">
                        <span *ngIf="translate.currentLang=='fr'" (click)="langChange('en')" class="fakeLink">
                            English
                        </span>
                        <span *ngIf="translate.currentLang=='en'" (click)="langChange('fr')" class="fakeLink">
                            Français
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row">
                <hr/>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div style="height: 30px">
                        <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
                        <p *ngIf="emptyUserName" class="text-danger">
                            {{ 'login.enterUsername' | translate }}
                        </p>
                    </div>

                    <!-- Forget password form -->
                    <form

                            *ngIf="!token"
                        [formGroup]="forgetPassForm"
                        (ngSubmit)="onSubmitForgetPassForm(forgetPassForm)"
                    >
                        <div class="form-group">
                            <!-- username -->
                            <div>
                                <div class="pull-left"
                                >
                                    <label class="field_title">
                                        <span [class.text-danger]="badUserName">{{ 'resetPass.userName' | translate }}</span>
                                    </label>
                                </div>
                                <div class="pull-right">
                                </div>
                                <div class="clearfix"></div>
                                <input  type="text" class="form-control" formControlName="username" style="color: #000">
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    class="modalButton pull-right"
                                    style="margin-top: 15px; margin-left: 10px"
                                >
                                    {{ 'commons.finish' | translate }}
                                </button>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    class="modalButton pull-right"
                                    style="margin-top: 15px"
                                    (click)="onCancelForgetPassForm()"
                                >
                                    {{ 'commons.cancel' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>

                    <!-- Change password form -->
                    <form

                        *ngIf="token"
                        [formGroup]="changePassForm"
                        (ngSubmit)="onSubmitChangePassForm(changePassForm)"
                        #changePassNgForm="ngForm"
                    >
                        <div class="form-group">
                            <!-- password -->
                            <div>
                                <div class="pull-left">
                                    <label class="field_title">
                                        {{ 'commons.password' | translate }}
                                    </label>
                                </div>
                                <div class="pull-right">
                                    <small
                                        *ngIf="validateFormField(changePassForm.controls.password, changePassNgForm)"
                                        class="text-danger"
                                    >
                                        <span [hidden]="!instructionOn || !changePassForm.controls.password.errors['required']">
                                            {{ 'commons.passwordMandatory' | translate }}<br />
                                        </span>
                                        <span [hidden]="!instructionOn || !changePassForm.controls.password.errors['minlength']">
                                            {{ 'admin.userForm.pwd8CharsWarning' | translate }}<br />
                                        </span>
                                    </small>
                                    <small *ngIf="instructionOn && changePassForm.hasError('weakPassword') &&
                                        (changePassForm.controls.password.dirty ||
                                        changePassForm.controls.password.touched)"
                                        class="text-danger"
                                    >
                                        <span>
                                            {{ 'admin.userForm.pwdRuleText' | translate }}
                                        </span>
                                    </small>
                                    <small *ngIf="instructionOn && discrepancies &&
                                        (changePassForm.controls.password.dirty ||
                                        changePassForm.controls.password.touched)"
                                           class="text-danger"
                                    >
                                        <span>
                                            <label for="passwordField">{{ 'admin.userForm.pwdDiscrepancy' | translate }}</label>
                                        </span>
                                    </small>
                                </div>
                                <div class="clearfix"></div>
                                <input id="passwordField" class="inputForm form-control" type="password" formControlName="password" style="color: #000">
                                <br>
                                <div>
                                    <label for="pwdConfirmationField">{{ 'admin.userForm.confirmPwd' | translate }}</label>
                                </div>
                                <input id="pwdConfirmationField" class="inputForm form-control" type="password" formControlName="pwdConfirmation" style="color: #000">
                            </div>
                            <input type="hidden" formControlName="token">

                            <div>
                                <button
                                    type="submit"
                                    class="modalButton pull-right"
                                    [disabled]="!changePassForm.valid && changePassNgForm.submitted"
                                    style="margin-top: 15px; margin-left: 10px"
                                >
                                    {{ 'commons.finish' | translate }}
                                </button>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    class="modalButton pull-right"
                                    style="margin-top: 15px"
                                    (click)="onCancelForgetPassForm()"
                                >
                                    {{ 'commons.cancel' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="messageFormSubmit || messagePasswordChange">
                        <p *ngIf="messageFormSubmit">
                            {{ 'commons.pwfSubmitted' | translate }}
                        </p>
                        <p *ngIf="messagePasswordChange">
                            {{ 'commons.pwChanged' | translate }}
                        </p>
                    </div>

                </div>

                <div class="col-md-1">
                </div>

                <div class="col-md-5">
                    <div id="tagLineContainer" class="pull-right">
                        <p class="pNull">
                            <span id="tagTop">{{ 'login.acquisition' | translate }}</span>
                        </p>
                        <p class="pNull">
                            <span id="tagMid">{{ 'login.development' | translate }}</span>
                            <span class="tagEnd"><em>{{ 'login.and' | translate }}</em></span>
                        </p>
                        <p class="pNull">
                            <span class="tagEnd tagEndBottom"><em>{{ 'login.talentsManagement' | translate }}</em></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
        </div>
    </div>
</div>
