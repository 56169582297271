<div (window:resize)="onResize()">
    <h2>{{ 'jobs.commons.jobInfoTalents' | translate }}</h2>

    <div *ngIf="jobService.id">
        <ul class="plainList">
            <li *ngIf="jobService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ jobService.jobTitle }}</li>
            <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (jobService.competencesModificationDate || jobService.creationDate || jobService.date) | date:reportDateFormat}}</li>
        </ul>

        <hr/>

        <span *ngIf="smallSize && talentsScores" class="fakeLink modLink" (click)="modTalent()">{{ 'jobs.sidebar.editTargetCompetencesProfile' | translate }}</span>
        <div class="clear"></div>

        <div id="jobCompetenceReport">
            <h3 style="margin: 15px 0 25px 0">
                {{'people.reportTalents.keyCompetenciesForJob' | translate }}
            </h3>
            <div *ngFor="let competence of competencesOrdered; let i = index" style="margin-bottom: 15px; border-bottom: 1px solid #ccc">
                <h4>{{ competence[0] }}</h4>
                <app-talent-graph
                        [id]="'talent_selector_display_'+i"
                        [size]="'large_masked'"
                        [type]="'selector'"
                        [level]="competence[1]"
                        [mobile]="false"
                ></app-talent-graph>
                <p>{{ competence[2] }}</p>
                <div class="polarity semiBold">
                    {{ 'jobs.report.high' | translate }}
                </div>
                <p>{{ competence[3] }}</p>
                <div class="polarity semiBold">
                    {{ 'jobs.report.low' | translate }}
                </div>
                <p>{{ competence[4] }}</p>
            </div>
            <h3 style="margin: 20px 0 25px 0">
                {{ 'people.reportTalents.interviewQuestions' | translate }}
            </h3>
            <p>
                {{ 'people.reportTalents.questionsUsingLegend' | translate }}
            </p>
            <div *ngFor="let competence of competencesOrdered; let i = index">
                <h4>{{ competence[0] }}</h4>
                <app-talent-graph
                        [id]="'talent_selector_empty_'+i"
                        [size]="'large_masked'"
                        [type]="'empty'"
                        [level]="'none'"
                ></app-talent-graph>
                <ul>
                    <li *ngFor="let question of competence[5]">
                        {{ question }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
