<div *ngIf="jobService.id" class="formWrapper">
    <h4 class="modal-title">{{ 'jobs.profilerModal.fillFastProfiler' | translate }}</h4>
    <mat-form-field class="full-width">
        <h4><label>{{ 'jobs.sidebar.profiles' | translate }}</label></h4>
<!--        <mat-label>-->
<!--            {{ 'jobs.sidebar.profiles' | translate }}-->
<!--        </mat-label>-->
        <mat-select [(ngModel)]="selectedProfile"
                    (ngModelChange)="loadValues(selectedProfile)"
                    panelClass="jobsProfilerOptions"
        >
            <mat-option value=""></mat-option>
            <mat-optgroup *ngFor="let group of profileListGroups">
                {{group.text}}
                <mat-option *ngFor="let text of getProperty(group, 'opt')"
                            [value]="text.value">
                    &nbsp;&nbsp;&nbsp;
                    {{text.text}}
                    ({{getProperty(text, 'score').join(', ')}})
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
    <form [formGroup]="jobProfilerForm" (ngSubmit)="onSubmit(jobProfilerForm)">
        <mat-grid-list class="jobsProfilerLabels" cols="6" rowHeight="20px">
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerOR">OR</label></h4>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerSE">{{ 'commons.ES' | translate }}</label></h4>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerA">A</label></h4>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerE">E</label></h4>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerP">P</label></h4>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <h4><label for="profilerS">S</label></h4>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list class="jobsProfilerInputs" cols="6" rowHeight="35px">
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerOR" formControlName="OR" />
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerSE" formControlName="SE" />
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerA" formControlName="A" />
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerE" formControlName="E" />
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerP" formControlName="P" />
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
                <input matInput onfocus="this.select();" type="text" id="profilerS" formControlName="S" />
            </mat-grid-tile>
        </mat-grid-list>
        <!-- no [mobile] ?? -->
        <app-mpo-graph
                [id]="'profiler-modal'"
                [scores]="cleanScore(jobProfilerForm.value)"
                [lang]="translate.currentLang"
        ></app-mpo-graph>

        <div class="jobsProfilerFooter justifyRight">
            <mat-grid-list cols="1" rowHeight="50px">
                <mat-grid-tile colspan="1" rowspan="1">
                    <button
                        mat-button
                        type="button"
                        class="modalButton"
                        aria-label="Clear"
                        (click)="closeForm()"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                    <button
                        mat-button
                        type="submit"
                        class="modalButton"
                        [disabled]="!jobProfilerForm.valid"
                    >
                        {{ 'commons.save' | translate }}
                    </button>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </form>
</div>
