<h2>{{ 'jobs.commons.jobInfoIac' | translate }}</h2>

<ng-container *ngIf="jobService.id">
    <ul class="plainList">
        <li *ngIf="jobService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ jobService.jobTitle }}</li>
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (jobService.competencesModificationDate || jobService.creationDate || jobService.date) | date:dateFormat}}</li>
    </ul>
    <hr/>
    <div *ngIf="jobService.raScore" id="graphBlock">
        <div style="max-width: 1000px">
            <div class="raJobProfile">
                <em>{{ 'commons.personalityTraitsJob' | translate }}</em>
            </div>
            <span class="fakeLink modLink" (click)="modRa()">{{ 'jobs.sidebar.modQuickRA' | translate }}</span>
            <div class="clearfix"></div>
            <img src="assets/graphicalElements/nCurve.png" alt=""/>
            <app-iac-graph
                style="max-width: 1000px"
                [id]="'report_' + jobService.id"
                [normalizedScore]="null"
                [jobScore]="jobService.raScore"
            ></app-iac-graph>
        </div>
    </div>
    <ng-container
            *ngIf="raTexts"
    >
        <h2 style="margin-top: 15px">{{ 'jobs.sidebar.caiBoxIntro' | translate }}</h2>
        <h3>{{ 'commons.workEnvironment' | translate }}</h3>
        <p>{{ raTexts.env }}</p>
        <h3>{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
        <p>{{ raTexts['job'] }}</p>
    </ng-container>
</ng-container>
