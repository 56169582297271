import {Router} from '@angular/router';
import * as _ from 'lodash';
import {Injectable} from '../../../shared/barrels/angular';
import {StateAdministration} from './state-administration';
import {StatePeople} from './state-people';
import {StateSession} from './state-session';
import {StateJobs} from './state-jobs';
import {StateRightMatch} from './state-right-match';
import {StateIdwMatrix} from './state-idw-matrix';
import {StateGraphboard} from './state-graphboard';
import {StateArchivesPeople} from './state-archives-people';
import {StateArchivesJobs} from './state-archives-jobs';
import {StateArchivesRightMatch} from './state-archives-right-match';
import {StateArchivesIdwMatrix} from './state-archives-idw-matrix';
import {StateArchivesGraphboard} from './state-archives-graphboard';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {OnDestroy} from '@angular/core';
import {AccountService} from '../../../administration/services/account.service';
import {AccountTypesHelper} from '../../../administration/commons/AccountTypesHelper';
import {StateJobSatisfaction} from './state-job-satisfaction';
import {StateArchivesJobSatisfaction} from './state-archives-job-satisfaction';

@Injectable({
    providedIn: 'root',
})
export class StateService implements OnDestroy {

    public stateModule: string;

    private init = true;

    public session: StateSession;
    public people: StatePeople;
    public archivesPeople: StateArchivesPeople;
    public jobs: StateJobs;
    public archivesJobs: StateArchivesJobs;
    public admin: StateAdministration;
    public jobSatisfaction: StateJobSatisfaction;
    public archivesJobSatisfaction: StateArchivesJobSatisfaction;
    public rightMatch: StateRightMatch;
    public archivesRightMatch: StateArchivesRightMatch;
    public idwMatrix: StateIdwMatrix;
    public archivesIdwMatrix: StateArchivesIdwMatrix;
    public graphboard: StateGraphboard;
    public archivesGraphboard: StateArchivesGraphboard;
    public isStateInit = false;
    public origin: any = {};

    private subscriptions = new Subscription();

    constructor(
        private translate: TranslateService
    ) {
        this.initState();
    }

    /**
     * Init application state
     */
    public initState(): void {

        if (!this.isStateInit) {
            this.isStateInit = true;

            // Init states (all constructors states will retrieve the state in URL)
            this.session = new StateSession();
            if (this.session) {
                this.admin = new StateAdministration(this.translate);
                this.people = new StatePeople(this.translate);
                this.archivesPeople = new StateArchivesPeople(this.translate);
                this.jobs = new StateJobs(this.translate);
                this.archivesJobs = new StateArchivesJobs(this.translate);
                this.rightMatch = new StateRightMatch(this.translate);
                this.archivesRightMatch = new StateArchivesRightMatch(this.translate);
                this.jobSatisfaction = new StateJobSatisfaction(this.translate);
                this.archivesJobSatisfaction = new StateArchivesJobSatisfaction(this.translate);
                this.idwMatrix = new StateIdwMatrix(this.translate);
                this.archivesIdwMatrix = new StateArchivesIdwMatrix(this.translate);
                this.graphboard = new StateGraphboard(this.translate);
                this.archivesGraphboard = new StateArchivesGraphboard(this.translate);
                if (this.session.sessionData && this.session.sessionData.structure) {
                    this.jobs.setColumnWithPermissions(this.session.sessionData.structure.testAccessPermissions, this.session.hasSatelliteRole);
                    AccountTypesHelper.setCurrentAccountTypesArray(this.session.sessionData.permissions.accountType);
                    this.admin.accountType = this.session.sessionData.permissions.accountType;
                }

            } else {
                throw('NO STATE');
            }
        }
    }



    /**
     * Clear application state
     */
    public clearState(): void {
        this.isStateInit = false;

        this.admin = null;
        this.people = null;
        this.archivesPeople = null;
        this.jobs = null;
        this.archivesJobs = null;
        this.rightMatch = null;
        this.archivesRightMatch = null;
        this.jobSatisfaction = null;
        this.archivesJobSatisfaction = null;
        this.idwMatrix = null;
        this.archivesIdwMatrix = null;
        this.graphboard = null;
        this.archivesGraphboard = null;

        // Clear session storage
        // ToDo: are there other session storage variables to keep ?
        const isLoggedIn = sessionStorage.getItem('session.isLoggedIn');
        sessionStorage.clear();
        sessionStorage.setItem('session.isLoggedIn', isLoggedIn);
    }

    /**
     * Reset application state
     */
    resetState(action = null) {
        if (action === 'saveOrigin') {
            this.origin = {
                admin: this.admin,
                people: this.people,
                archivesPeople: this.archivesPeople,
                jobs: this.jobs,
                archivesJobs: this.archivesJobs,
                rightMatch: this.rightMatch,
                archivesRightMatch: this.archivesRightMatch,
                jobSatisfaction: this.jobSatisfaction,
                archivesJobSatisfaction: this.archivesJobSatisfaction,
                idwMatrix: this.idwMatrix,
                archivesIdwMatrix: this.archivesIdwMatrix,
                graphboard: this.graphboard,
                archivesGraphboard: this.archivesGraphboard
            };
        }
        this.clearState();
        this.initState();
        if (action === 'restoreOrigin') {
            this.admin = this.origin.admin;
            this.people = this.origin.people;
            this.archivesPeople = this.origin.archivesPeople;
            this.jobs = this.origin.jobs;
            this.archivesJobs = this.origin.archivesJobs;
            this.rightMatch = this.origin.rightMatch;
            this.archivesRightMatch = this.origin.archivesRightMatch;
            this.jobSatisfaction = this.origin.jobSatisfaction;
            this.archivesJobSatisfaction = this.origin.archivesJobSatisfaction;
            this.idwMatrix = this.origin.idwMatrix;
            this.archivesIdwMatrix = this.origin.archivesIdwMatrix;
            this.graphboard = this.origin.graphboard;
            this.archivesGraphboard = this.origin.archivesGraphboard;

            this.origin = {};
        }
    }

    /**
     * This method takes pareters, renders them in url-format update data retrieved with retrieveActualParameters
     * and pass it to the router for navigation.
     *
     * @param router - The router service from where it is called
     * @param url - The full url: prefix url + mendatory extra arguments.
     * @param action
     * @param params - the optional arguments
     * @param stateModule
     * @param suffix
     */

    public navigate(router: Router, url: string, action: string, params: any, stateModule = this.stateModule, suffix = null) {
        let urlNav = url;
        if (url === 'archivesPeople') { urlNav = '/archives/list/people'; }
        if (url === 'archivesJobs') { urlNav = '/archives/list/jobs'; }
        if (url === 'archivesRightMatch') { urlNav = '/archives/list/rightmatch'; }
        if (url === 'archivesJobSatisfaction') { urlNav = '/archives/list/jobsatisfaction'; }
        if (url === 'archivesIdwMatrix') { urlNav = '/archives/list/idwmatrix'; }
        if (url === 'archivesGraphboard') { urlNav = '/archives/list/graphboard'; }

        let target: any = [urlNav, {}];

        if (params.backTo) {
            target[1] = this.setUrl(urlNav);
        } else if (this[stateModule]) {
            target = this.paramsProcessing(action, params, urlNav, stateModule);
        }

        if (this[stateModule]) {
            target[1] = this[stateModule].urlProcessing(target[0], target[1]);
        }

        if (!_.isEmpty(target[1])) {
            target[1] = {state: btoa(JSON.stringify(target[1]))};
        }

        router.navigate([target[0], target[1]]);

        return true;
    }

    /**
     *
     * @param url
     * @returns {any}
     */

    protected setUrl(url: string) {
        let params: any = {};
        if (url.split('/')[1] === 'list') {
            for (let parameter in this[this.stateModule].state) {
                if (this[this.stateModule].state.hasOwnProperty(parameter)) {
                    if (this[this.stateModule].state[parameter] !== null) {
                        params[parameter] = this[this.stateModule].state[parameter];
                    }
                }
            }
        }
        return params;
    }

    /**
     *
     * @param action
     * @param params
     * @param url
     * @param stateModule
     * @returns {any}
     */

    protected paramsProcessing(action: string, params: any, url = null, stateModule = this.stateModule): any {
        // Clone state
        let clonedState = JSON.parse(JSON.stringify(this[stateModule].state));
        if (params.mobile) {
            clonedState = this[stateModule].state;
        }
        // Apply the preProcessing for this action
        let newTarget = this[stateModule].actionPreProcessing(action, params, clonedState, url);
        // clean the new state of nulls or undefined
        for (let paramNew in newTarget[1]) {
            if (newTarget[1].hasOwnProperty(paramNew)) {
                if (newTarget[1][paramNew] === null || typeof(newTarget[1][paramNew]) === 'undefined') {
                    delete newTarget[1][paramNew];
                }
            }
        }
        return newTarget;
    }

    /**
     * Set routing within a smart component at initialization.
     *
     * @param this_ - "this" for that smart component calling this setProgation
     * @param stateModule
     */

    public setPropagation(this_, stateModule = ''): void {

        this.stateModule = (stateModule === '') ? this_.route.snapshot.url[0].path : stateModule;
        this.subscriptions.add(this_.route.url.subscribe(
            () => {
                if (!_.isEmpty(this_.route.snapshot.params)) {
                    // mendatory params is everything which is not state
                    for (let param in this_.route.snapshot.params) {
                        if (this_.route.snapshot.params.hasOwnProperty(param)) {
                            if (param === 'state') {
                                let params = JSON.parse(atob(this_.route.snapshot.params['state']));
                                // Routing
                                for (let paramState in params) {
                                    if (params.hasOwnProperty(paramState)) {
                                        let store = this[this.stateModule].actionPostProcessing('optionalParams', paramState);
                                        if (store) {
                                            this[this.stateModule].stateStore(this.stateModule, paramState, params[paramState]);
                                        }
                                    }
                                }
                            } else {
                                let store = this[this.stateModule].actionPostProcessing('mendatoryParams', param,
                                    this_.route.snapshot.params[param]);
                                if (store) {
                                    this[this.stateModule].stateStore(this.stateModule, param,
                                        this_.route.snapshot.params[param]);
                                }
                            }
                        }
                    }
                }
                this_.changeDetectorRef.markForCheck();
            }
        ));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    set sessionData(value: any) {
        this.session.persistState('sessionData', value);
//        this.session.sessionData = ;
        this.session.sessionDataWatch.next(true);
        if (this.init === true) {
            // TODO: probably not used - refactoring
            // this.people.setColumnWithPermissions(value.structure.testAccessPermissions, this.session.hasSatelliteRole);
            this.jobs.setColumnWithPermissions(value.structure.testAccessPermissions, this.session.hasSatelliteRole);

            this.init = false;
        }

    }

    get hasPrbTestAccess() {
        return (
            !!this.hasPersonality || !!this.hasCommunication || !!this.hasTalents || !!this.hasSatellite
        );
    }

    get hasMpoTestAccess() {
        return (
            !!this.hasPersonality || !!this.hasCommunication || !!this.hasTalents || !!this.hasSatellite
        );
    }

    get hasPersonality() {
        if (this.session.sessionData.structure) { return this.session.sessionData.structure.testAccessPermissions.mpo; }
        return;
    }

    get hasIac() {
        if (this.session.sessionData.structure) { return this.session.sessionData.structure.testAccessPermissions.ra; }
        return;
    }


    get hasCommunication() {
        if (this.session.sessionData.structure) {
            return this.session.sessionData.structure.testAccessPermissions.dit;
        }
        return;
    }
    get hasTalents() {
        if (this.session.sessionData.structure) {
            return this.session.sessionData.structure.testAccessPermissions.talents;
        }
        return;
    }

    get hasSatellite() {
        if (this.session.sessionData.structure) {
            return this.session.sessionData.structure.testAccessPermissions.satellite;
        }
        return;
    }

    get hasJobAccess() {
        return (
            !!this.hasPersonality ||
            !!this.hasTalents ||
            !!this.hasIac
        );
    }

    get hasAnalysisAccess() {
        return (
            !!this.hasPersonality ||
            !!this.hasCommunication
        );
    }
}
