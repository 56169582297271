<div *ngIf="chooseGraphboard">
    <mat-form-field class="full-width">
        <mat-label>{{ 'gbs.personModal.chooseGb' | translate }}</mat-label>
        <mat-select
            [(ngModel)]="gbId"
            (ngModelChange)="assignGbId(gbId)"
        >
            <mat-option value="">--</mat-option>
            <mat-option
                *ngFor="let gb of gbList"
                [value]="gb.id"
            >{{ gb.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <p>{{ headerText }}</p>
</div>
<table class="navTable">
    <tr>
        <td>
            <mat-tab-group
                class="typeTabs"
                [selectedIndex]="selectedTab"
                (selectedIndexChange)="displayType($event)"
            >
                <mat-tab label="{{ 'commons.individus' | translate }}"></mat-tab>
                <mat-tab label="{{ 'commons.jobs' | translate }}"></mat-tab>
            </mat-tab-group>
        </td>
        <td>
            <mat-form-field *ngIf="records[graphboardTab]" class="full-width">
                <input
                    matInput
                    type="text"
                    onfocus="this.select();"
                    title="{{ 'commons.search' | translate }}"
                    placeholder="{{ 'commons.search' | translate }}"
                    [(ngModel)]="searchTerm"
                    (keydown)="onSearch($event, searchTerm, 'return')"
                >
                <button
                    mat-button
                    *ngIf="actual"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    (click)="clearSearch()"
                >
                    <mat-icon>close</mat-icon>
                </button>
                <button
                    mat-button
                    *ngIf="!actual"
                    matSuffix
                    mat-icon-button
                    (click)="onSearch($event, searchTerm, 'click')"
                    aria-label="Search"
                >
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
        </td>
    </tr>
</table>
<mat-form-field class="full-width falseInput">
    <label style="display: none;">
        <input
            matInput
            type="text" />
    </label>
    <div
        id="candidateList"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
    >
        <img alt="loading" *ngIf="!records[graphboardTab]" style="margin: 0 auto;" src="{{loadingGif}}"/>
        <div *ngIf="records[graphboardTab]">
            <div class="candidateRow" *ngFor="let record of records[graphboardTab]; let i = index">
                <div *ngIf="graphboardTab === 'individus'">
                    <table class="twoColumns">
                        <tr>
                            <td>
                                {{showProperty(record, 'firstName')}} {{showProperty(record, 'lastName')}}
                            </td>
                            <td>
                                <mat-checkbox
                                    name="indType_{{record.id}}_trait"
                                    value="traits"
                                    (change)="selectIndType(record.id, 'trait')"
                                    [checked]="check(record, 'trait')"
                                    [disabled]="isDisabledTraitCheck(record)"
                                >
                                    {{ 'commons.traits' | translate }}
                                </mat-checkbox> &nbsp;
                                <mat-checkbox
                                    name="indType_{{record.id}}_prb"
                                    value="comportement"
                                    (change)="selectIndType(record.id, 'prb')"
                                    [checked]="check(record, 'prb')"
                                    [disabled]="isDisabledPrbCheck(record)"
                                >
                                    {{ 'commons.behavior' | translate }}
                                </mat-checkbox> &nbsp;
                                <mat-checkbox
                                    name="indType_{{record.id}}_both"
                                    value="tous"
                                    (change)="selectIndType(record.id, 'both')"
                                    [checked]="check(record, 'both')"
                                    [disabled]="isDisabledTraitCheck(record) || isDisabledPrbCheck(record)"
                                >
                                    {{ 'commons.all' | translate }}
                                </mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="graphboardTab === 'profiler'">
                    <table>
                        <tr>
                            <td>
                                <mat-checkbox
                                    id="record_{{ i }}"
                                    [checked]="predicateCheckBox('profiler', record)"
                                    (change)="onChecked($event, record.id)"
                                >
                                    <span *ngIf="showProperty(record, 'jobTitle')"
                                    >{{ showProperty(record, 'jobTitle') }}</span>
                                    <span *ngIf="!showProperty(record, 'jobTitle')"
                                          class="noValue"
                                    >{{ (currentLang === 'fr') ? 'Aucun nom de poste' : 'No job title' }}</span>
                                </mat-checkbox>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</mat-form-field>
<div *ngIf="records[graphboardTab]" class="addingFooter">
    <button
        mat-button
        type="button"
        class="modalButton"
        aria-label="Clear"
        (click)="closeP()"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <button *ngIf="records[graphboardTab]"
        mat-button
        type="submit"
        class="modalButton"
        (click)="onSubmit()"
    >
        {{ 'commons.save' | translate }}
    </button>
</div>
