import {Injectable} from '@angular/core';
import {FormBuilder, Validators} from '../../shared/barrels/angular';
import {Tools} from '../../shared/misc/tools';

@Injectable()
export class UserFormDefinitionService {
    personalityFormation: {
        training: boolean,
        formationDate: { sec: number, usec: number },
        location: string,
        trainer: string
    };

    communicationFormation: {
        training: boolean,
        formationDate: { sec: number, usec: number },
        location: string,
        trainer: string
    };

    talentsFormation: {
        training: boolean,
        formationDate: { sec: number, usec: number },
        location: string,
        trainer: string
    };

    constructor(
        private fb: FormBuilder
    ) {
    }

    getUserNewDefinition(user: any, userData: any, initValues = false) {
        let username = user.username;
        let password = Tools.passwordGenerator(8);
        let email = '';
        if (userData) {
            if (userData.username) {
                username = userData.username;
            }
            if (userData.password) {
                password = userData.password;
            }
            if (userData.email) {
                email = userData.email;
            }
        }
        return Object.assign(
            {
                username: [username, [Validators.required, Validators.minLength(5)]],
                password: [password, [Validators.required, Validators.minLength(8)]],
                email: [email, [Validators.required, Validators.email]]
            },
            this.getUserEditDefinition(user, userData, initValues)
        );
    }

    getUserEditDefinition(user: any, userData: any, initValues: any = false) {
        let subAccountAccess = userData.subAccountAccess;
        let principalSubAccount = userData.principalSubAccount;

        if (initValues !== false) {
            subAccountAccess = [initValues.subAccountAccess];
            principalSubAccount = initValues.principalSubAccount;
        }

        let permissions = {
            mpo: [(userData.permissions && userData.permissions.mpo) ? userData.permissions.mpo : false],
            dit: [(userData.permissions && userData.permissions.dit) ? userData.permissions.dit : false],
            talents: [(userData.permissions && userData.permissions.talents) ? userData.permissions.talents : false],
            satellite: [(userData.permissions && userData.permissions.satellite) ? userData.permissions.satellite : false],
            ra: [(userData.permissions && userData.permissions.ra) ? userData.permissions.ra : false]
        };

        if (userData.id === undefined) {
            // permissions for a new user
            permissions = {
                mpo: [{
                    value: (userData.permissions && userData.permissions.mpo) ? userData.permissions.mpo : false,
                    disabled: (userData.permissions.mpo === false)
                }],
                dit: [{
                    value: (userData.permissions && userData.permissions.dit) ? userData.permissions.dit : false,
                    disabled: (userData.permissions.dit === false)
                }],
                talents: [{
                    value: (userData.permissions && userData.permissions.talents) ? userData.permissions.talents : false,
                    disabled: (userData.permissions.talents === false)
                }],
                satellite: [{
                    value: (userData.permissions && userData.permissions.satellite) ? userData.permissions.satellite : false,
                    disabled: (userData.permissions.satellite === false)
                }],
                ra: [{
                    value: (userData.permissions && userData.permissions.ra) ? userData.permissions.ra : false,
                    disabled: (userData.permissions.ra === false)
                }]
            };
        }

        this.personalityFormation = {
            training: false,
            formationDate: {sec: undefined, usec: undefined},
            location: '',
            trainer: ''
        };
        this.communicationFormation = {
            training: false,
            formationDate: {sec: undefined, usec: undefined},
            location: '',
            trainer: ''
        };
        this.talentsFormation = {
            training: false,
            formationDate: {sec: undefined, usec: undefined},
            location: '',
            trainer: ''
        };
        if (userData.formation) {
            if (userData.formation.personality) {
                this.personalityFormation = Object.assign({}, userData.formation.personality);
            }
            if (userData.formation.communication) {
                this.communicationFormation = Object.assign({}, userData.formation.communication);
            }
            if (userData.formation.talents) {
                this.talentsFormation = Object.assign({}, userData.formation.talents);
            }
            if (Array.isArray(userData.formation)) {
                let fieldName;
                for (let test of ['personality', 'communication', 'talents']) {
                    fieldName = test + 'Formation';
                    if (userData.formation[0][test]) {
                        this[fieldName] = Object.assign({}, userData.formation[0][test]);
                        if (Array.isArray(userData.formation[0][test])) {
                            this[fieldName] = Object.assign({}, userData.formation[0][test][0]);
                        }
                    }
                }
            }
        }

        if (userData.addressInfo && Array.isArray(userData.addressInfo)) {
            userData.addressInfo = userData.addressInfo[0];
        }

        return {
            role: [user.role, [Validators.required]],
            firstName: [userData.firstName, [Validators.required]],
            lastName: [userData.lastName, [Validators.required]],
            email: [userData.email, [Validators.required, Validators.email]],
            personalEmail: [userData.personalEmail],
            subAccountAccess: [subAccountAccess, [Validators.required]],
            principalSubAccount: [principalSubAccount, [Validators.required]],
            status: [userData.status, [Validators.required]],
            jobTitle: [userData.jobTitle],
            phone: [userData.phone],
            industry: [userData.industry],
            jobType: [userData.jobType],
            isSatellite: [userData.isSatellite],
            formationType: [userData.formationType],
            otherFormation: [userData.otherFormation],
            notes: [userData.notes],
            language: [userData.language, [Validators.required]],
            formation: this.fb.array([
                this.fb.group({
                    personality: this.fb.array([
                        this.fb.group({
                            training: [this.personalityFormation.training],
                            formationDate: [this.personalityFormation.formationDate],
                            location: [this.personalityFormation.location],
                            trainer: [this.personalityFormation.trainer]
                        })
                    ]),
                    communication: this.fb.array([
                        this.fb.group({
                            training: [this.communicationFormation.training],
                            formationDate: [this.communicationFormation.formationDate],
                            location: [this.communicationFormation.location],
                            trainer: [this.communicationFormation.trainer]
                        })
                    ]),
                    talents: this.fb.array([
                        this.fb.group({
                            training: [this.talentsFormation.training],
                            formationDate: [this.talentsFormation.formationDate],
                            location: [this.talentsFormation.location],
                            trainer: [this.talentsFormation.trainer]
                        })
                    ])
                })
            ]),
            addressInfo: this.fb.array([
                this.fb.group({
                    address: [userData.addressInfo ? userData.addressInfo.address : null],
                    city: [userData.addressInfo ? userData.addressInfo.city : null],
                    zipcode: [userData.addressInfo ? userData.addressInfo.zipcode : null],
                    region: [userData.addressInfo ? userData.addressInfo.region : null],
                    country: [userData.addressInfo ? userData.addressInfo.country : null]
                })
            ]),
            permissions: this.fb.array([
                this.fb.group(permissions)
            ]),
        };
    }
}
