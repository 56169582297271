import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ModalModule} from 'ngx-bootstrap/modal';
import {NgenioFormsModule} from '../shared/forms/ngenio-forms.module';
import {SharedModule} from '../shared/shared.module';
import {AdministrationRoutingModule} from './administration-routing.module';
import {AccountComponent} from './components/administration-account/administration-account.component';
import {AdminFilesListComponent} from './components/administration-files-list/administration-files-list.component';
import {AdminFilesModalComponent} from './components/administration-files-modal/administration-files-modal.component';
import {AdministrationListSearchFiltersComponent} from './components/administration-list-search-filters/administration-list-search-filters.component';
import {AdministrationListComponent} from './components/administration-list/administration-list.component';
import {MobileAdministrationListComponent} from './components/mobile/mobile-administration-list/mobile-administration-list.component';
import {MoveCreditModalComponent} from './components/administration-move-credit-modal/administration-move-credit-modal.component';
import {PurchaseAdjustementModalComponent} from './components/administration-purchase-adjustement-modal/administration-purchase-adjustement-modal.component';
import {PurchaseProductModalComponent} from './components/administration-purchase-product-modal/administration-purchase-product-modal.component';
import {AdministrationSidebarComponent} from './components/administration-sidebar/administration-sidebar.component';
import {UserDetailComponent} from './components/administration-user/administration-user.component';
import {AdministrationCommonListComponent} from './components/administration-common-list/administration-common-list.component';
import {UserFormComponent} from './components/administration-user-form/administration-user-form.component';
import {UserPreferencesComponent} from './components/administration-user-preferences/administration-user-preferences.component';
import {PeopleModule} from '../people/people.module';
import {JobsModule} from '../jobs/jobs.module';
import {RightMatchesModule} from '../analysis/right-matches/right-matches.module';
import {IdwMatricesModule} from '../analysis/idw-matrices/idw-matrices.module';
import {GraphboardsModule} from '../analysis/graphboards/graphboards.module';
import {ApiAdministrationService} from './services/api-administration.service';
import {MainListAdministrationService} from './services/main-list-administration.service';
import {AdministrationAccountLeftSidebarComponent} from './components/administration-account-left-sidebar/administration-account-left-sidebar.component';
import {ApiUploadFiles} from '../core/services/api/api-upload-files';
import {UserFormInitService} from './services/user-form-init.service';
import {UserFormDefinitionService} from './services/user-form-definition.service';
import {AdministrationUserLeftSidebarComponent} from './components/administration-user-left-sidebar/administration-user-left-sidebar.component';
import {MomentDateLangPipe, MomentDatePipe, Nl2BrPipe} from '../shared/barrels/pipes';
import {UserPreferencesService} from './services/user-preferences.service';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingModule} from 'ngx-loading';
import {AdministrationDepartmentModalComponent} from './components/administration-department-modal/administration-department-modal.component';
import {DeleteSubAccountModalComponent} from './components/administration-delete-subaccount-modal/administration-delete-subaccount-modal.component';
import {SidebarModule} from 'ng-sidebar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AdministrationAccountFormComponent} from './components/administration-account-form/administration-account-form.component';
import {AdministrationUserFormFieldComponent} from './components/administration-user-form-field/administration-user-form-field.component';
import {ToolsModule} from '../tools/tools.module';
import {AdministrationAccountFormFieldComponent} from './components/administration-account-form-field/administration-account-form-field.component';
import { AdministrationAccountFormFieldHierarchyComponent } from './components/administration-account-form-field-hierarchy/administration-account-form-field-hierarchy.component';
import { AdministrationListTransactionsComponent } from './components/administration-list-transactions/administration-list-transactions.component';
import { AdministrationTransactionsFilterModalComponent } from './components/administration-transactions-filter-modal/administration-transactions-filter-modal.component';
import { AdministrationTransactionsEditModalComponent } from './components/administration-transactions-edit-modal/administration-transactions-edit-modal.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgMultiSelectDropDownModule,
        NgenioFormsModule,
        MyDatePickerModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        AdministrationRoutingModule,
        PeopleModule, JobsModule, RightMatchesModule, IdwMatricesModule, GraphboardsModule,
        TranslateModule,
        LoadingModule,
        TooltipModule.forRoot(),
        SidebarModule.forRoot(), MatGridListModule, MatCheckboxModule,
        ToolsModule
    ],
    declarations: [
        AdministrationListComponent, MobileAdministrationListComponent, AccountComponent,
        DeleteSubAccountModalComponent, UserDetailComponent,
        MoveCreditModalComponent, PurchaseProductModalComponent, PurchaseAdjustementModalComponent,
        AdminFilesListComponent, AdminFilesModalComponent, AdministrationListSearchFiltersComponent,
        AdministrationSidebarComponent, AdministrationAccountLeftSidebarComponent,
        AdministrationUserLeftSidebarComponent, AdministrationCommonListComponent, UserFormComponent,
        UserPreferencesComponent, MomentDatePipe, MomentDateLangPipe, Nl2BrPipe, AdministrationDepartmentModalComponent,
        AdministrationAccountFormComponent, AdministrationUserFormFieldComponent,
        AdministrationAccountFormFieldComponent,
        AdministrationAccountFormComponent,
        AdministrationAccountFormFieldHierarchyComponent,
        AdministrationListTransactionsComponent,
        AdministrationTransactionsFilterModalComponent,
        AdministrationTransactionsEditModalComponent
    ],
    providers: [
        MainListAdministrationService,
        ApiAdministrationService,
        ApiUploadFiles,
        UserFormInitService, UserFormDefinitionService,
        UserPreferencesService
    ],
    entryComponents: [
        AdminFilesModalComponent,
        PurchaseAdjustementModalComponent,
        AdministrationDepartmentModalComponent,
        MoveCreditModalComponent,
        PurchaseProductModalComponent,
        DeleteSubAccountModalComponent,
        AdministrationTransactionsFilterModalComponent,
        AdministrationTransactionsEditModalComponent
    ]
})
export class AdministrationModule {
}
