<div class="modal-header">
    <h4 class="modal-title pull-left fontLight">
        {{ 'commons.sharePdf' | translate }}
    </h4>
    <div
            (click)="closeModal()" aria-label="Close" class="x-close pull-right"
            style="font-size: 20pt;"
    >
        &#x00D7;
    </div>
</div>
<div *ngIf="share=='none'">
    <div class="modal-body">
        <!-- options -->
        <div class="insideCheckboxes">
            <h4>{{ 'commons.options' | translate }}</h4>
            <mat-checkbox [(ngModel)]="pdfStructure['jobDesign']"
                          [disabled]="(!hasJobDesign()) ? '' : null"
                          id="mpoReport"
                          value="1"
            >
                {{ 'jobs.commons.jobDesign' | translate }}
            </mat-checkbox>
            <br>
            <div *ngIf="state.session.sessionData['structure']['testAccessPermissions']['mpo']">
                <mat-checkbox [(ngModel)]="pdfStructure['profile']"
                              [disabled]="(!hasProfile()) ? '' : null"
                              id="mpoReportHomepage"
                              value="1"
                >
                    {{ 'jobs.commons.profile' | translate }}
                </mat-checkbox>
                <br>
            </div>
            <div *ngIf="state.session.sessionData['structure']['testAccessPermissions']['mpo']">
                <mat-checkbox
                    [(ngModel)]="pdfStructure['graph']"
                    [checked]="pdfStructure['profile'] && pdfStructure['graph']"
                    [disabled]="(!hasProfile() || !pdfStructure['profile']) ? '' : null"
                    id="personalityReportGraph"
                    value="1"
                >
                    {{ 'commons.withGraph' | translate }}
                </mat-checkbox>
                <br>
            </div>
            <div *ngIf="state.session.sessionData['structure']['testAccessPermissions']['talents']">
                <mat-checkbox [(ngModel)]="pdfStructure['competences']"
                              [disabled]="!hasCompetencies()"
                              id="talentReportHomepage"
                              value="1"
                >
                    {{ 'jobs.commons.keyCompetencies' | translate }}
                </mat-checkbox>
                <br>
            </div>
            <div *ngIf="state.session.sessionData['structure']['testAccessPermissions']['talents']">
                <mat-checkbox [(ngModel)]="pdfStructure['intQuestions']"
                              [disabled]="!hasCompetencies()"
                              id="intQuestionsHomepage"
                              value="1" 
                >
                    {{ 'people.reportTalents.interviewQuestions' | translate }}
                </mat-checkbox>
                <br>
            </div>
            <div *ngIf="raPermission">
                <mat-checkbox [(ngModel)]="pdfStructure['ra']"
                              [disabled]="(!hasRa()) ? '' : null"
                              id="raHomepage"
                              value="1"
                >
                    {{ 'jobs.pdfModal.raTarget' | translate }}
                </mat-checkbox>
                <br>
            </div>
        </div>
        <div class="insideCheckboxes">
            <h5
                    [class.text-danger]="selectMethod"
            >{{ 'commons.sendingMethod' | translate }}</h5>
            <label>
                <select [(ngModel)]="method" (change)="changeSelectMethodState()">
                    <option>{{ 'commons.select' | translate }}</option>
                    <option value="pdf">{{ 'commons.pdfReport' | translate }}</option>
                    <option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</option>
                    <option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</option>
                    <option value="server">{{ 'commons.sendByServer' | translate }}</option>
                </select>
            </label>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="handleReport()">{{ 'commons.continue' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
<!-- share mode -->
<!-- server -->
<div *ngIf="share=='server'">
    <div class="modal-body">
        <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

        <div>
            <h3><b>Message</b></h3>
            <div class="form-group">
                <div class="sendContent">
                    <table style="width: 700px">
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label for="reportEmailEmailTo">{{ 'commons.email' | translate }}</label></td>
                            <td><input
                                    id="reportEmailEmailTo"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail['emailTo']"/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' |
                                translate }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label for="reportEmailSubject">{{ 'commons.emailSubject' | translate
                                }}</label>
                            </td>
                            <td><input
                                    id="reportEmailSubject"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail.subject"
                            />
                            </td>
                        </tr>
                    </table>
                    <table style="width: 700px; margin-top: 25px">
                        <tr>
                            <td style="padding-bottom: 7px; font-weight: bold">
                                <span class="field_title"><label for="reportEmailMessage">{{ 'commons.message' | translate }}</label></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea
                                        id="reportEmailMessage"
                                        rows="7"
                                        style="border: 1px solid #ccc; width: 565px; height: 100px"
                                        [(ngModel)]="pdfEmail.message"></textarea>
                            </td>
                        </tr>
                    </table>
                    <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                    <div id="innerTextServer">
                        <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                        <p>{{ pdfEmail.message }}</p>
                        <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                        <p *ngIf="pdfReportUrl"
                           class="forceBreaks"
                           [innerHTML]="serverText"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="sendEmailReport()">{{ 'commons.shareReport' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- mailto -->
<div *ngIf="share=='mailto'">
    <div *ngIf="mailto" class="modal-body">
        <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
        <p>{{ 'commons.sendByYourEmailReportInstructions' | translate }}
            <a href="mailto:{{ mailto }}" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- cutnpaste-->
<div *ngIf="share=='cutpaste'">
    <div *ngIf="pdfReportUrl" class="modal-body">
        <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
        <p
                [translate]="'jobs.pdfModal.linkInstruction'"
                [translateParams]="{
                    'jobTitle': jobToDisplay['jobTitle']
                }"
        ></p>
        <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
