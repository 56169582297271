<h4 class="fontLight">{{ 'commons.reportActivation' | translate }}</h4>
<ng-container *ngIf="!proceed">
    <p>
        {{ 'people.reportActivationModal.insufficientCreditLevel' | translate: {subAccountName: subAccountName} }}
    </p>
</ng-container>
<ng-container *ngIf="proceed && toggle == 0" [@fadeInOut]>
    <div *ngIf="activationData.type!=='satellite'">
        <p
            *ngIf="activationType && unlimitedCredits"
            [translate]="'people.reportActivationModal.reportActivationUnlimited'"
            [translateParams]="{
                    reportType: renameReport(activationData.type)
                }"
        ></p>
        <p
            *ngIf="activationType && !unlimitedCredits"
            [translate]="'people.reportActivationModal.reportActivationFor'"
            [translateParams]="{
                    reportType: renameReport(activationData.type),
                    price: pricesDefinitions[activationData.type]
                }"
        >
            <!--            {{renameReport(activationData.type)}}-->
            <!--            {{ setActivationData({types: [activationData.type], cost: pricesDefinitions[activationData.type]}) }}-->

        </p>
        <p
            *ngIf="!activationType && activationData.types && activationCost"
        >
            {{ activationTexts() }}
        </p>
    </div>
    <div *ngIf="activationData.type==='satellite'">
        <p *ngIf="!unlimitedCredits" [translate]="'people.reportActivationModal.activateSatellite'"
           [translateParams]="{
                        price: pricesDefinitions[activationData.type]
                    }"></p>
        <p *ngIf="unlimitedCredits">
            {{ 'people.reportActivationModal.reportActivationUnlimited' | translate: {reportType: 'MPO Satellite'} }}
        </p>
    </div>
    <!--    <div *ngIf="activationData.type !== 'iac'">-->
    <!--        <mat-grid-list cols="2" rowHeight="20px" class="titlesGrid">-->
    <!--            <mat-grid-tile colspan="1" rowspan="1">-->
    <!--                <h3>{{ 'commons.options' | translate }}</h3>-->
    <!--            </mat-grid-tile>-->
    <!--            <mat-grid-tile colspan="1" rowspan="1">-->
    <!--                <div class="creditTitle">-->
    <!--                    <p>-->
    <!--                        <span>{{ 'commons.credits' | translate }}</span>-->
    <!--                    </p>-->
    <!--                </div>-->
    <!--            </mat-grid-tile>-->
    <!--        </mat-grid-list>-->
    <!--        <mat-grid-list cols="2" rowHeight="67px">-->
    <!--            <mat-grid-tile colspan="1" rowspan="1">-->
    <!--                <mat-form-field appearance="fill">-->
    <!--                    <mat-label [class.text-danger]="variantFlag">{{ 'commons.survey' | translate }}</mat-label>-->
    <!--                    <mat-select [(ngModel)]="variant">-->
    <!--                        <mat-option value=""></mat-option>-->
    <!--                        <mat-option *ngFor="let variantOptions of ['traitsAndPrb', 'traitsOnly']" [value]="variantOptionsValue[variantOptions]">-->
    <!--                            {{ 'people.commons.' + variantOptions | translate }}-->
    <!--                        </mat-option>-->
    <!--                    </mat-select>-->
    <!--                </mat-form-field>-->
    <!--            </mat-grid-tile>-->
    <!--            <mat-grid-tile colspan="1" rowspan="3" class="rightTile">-->
    <!--                <div class="creditBoxInner">-->
    <!--                    <div class="creditCost" [style.background-color]="displayWarningMessage ? 'rgb(240, 123, 126)' : 'rgb(208, 238, 207)'">-->
    <!--                        <p>{{ 'people.requestModal.required' | translate }}</p>-->
    <!--                        <p>{{ creditCost || 0 }}</p>-->
    <!--                    </div>-->

    <!--                    <div class="creditsAvailable">-->
    <!--                        <p [class.redWarning]="displayWarningMessage">{{ 'people.requestModal.available' | translate }}</p>-->
    <!--                        <p>{{ creditAvailable || '-' }}</p>-->
    <!--                    </div>-->
    <!--                </div>-->
    <!--            </mat-grid-tile>-->
    <!--            <mat-grid-tile colspan="1" rowspan="1">-->
    <!--                <mat-form-field appearance="fill">-->
    <!--                    <mat-label [class.text-danger]="countryFlag">{{ 'commons.country' | translate }}</mat-label>-->
    <!--                    <mat-select [(ngModel)]="country">-->
    <!--                        <mat-option value=""></mat-option>-->
    <!--                        <mat-option *ngFor="let countryOption of countries" [value]="countryOption.value">-->
    <!--                            {{countryOption.name}}-->
    <!--                        </mat-option>-->
    <!--                    </mat-select>-->
    <!--                </mat-form-field>-->
    <!--            </mat-grid-tile>-->
    <!--            <mat-grid-tile colspan="1" rowspan="1">-->
    <!--                <mat-form-field appearance="fill">-->
    <!--                    <mat-label [class.text-danger]="languageFlag">{{ 'commons.language' | translate }}</mat-label>-->
    <!--                    <mat-select [(ngModel)]="language">-->
    <!--                        <mat-option value=""></mat-option>-->
    <!--                        <mat-option *ngFor="let lang of languages[translate.currentLang]" [value]="lang.value">-->
    <!--                            {{lang.name}}-->
    <!--                        </mat-option>-->
    <!--                    </mat-select>-->
    <!--                </mat-form-field>-->
    <!--            </mat-grid-tile>-->
    <!--        </mat-grid-list>-->
    <!--    </div>-->

    <div *ngIf="activationData.type==='satellite'">
        <p>
            <span [class.text-danger]="warningNothingSelected">
                {{ 'people.reportActivationModal.assocPersonWJob' | translate }}
            </span>
            <mat-form-field appearance="fill" class="actJobSelect">
                <mat-label [class.text-danger]="warningNothingSelected">
                    {{ 'jobs.commons.jobName' | translate }}
                </mat-label>
                <mat-select [(ngModel)]="bindedProfiler">
                    <mat-option value="none"></mat-option>
                    <mat-option *ngFor="let profiler of satelliteList" [value]="profiler.id">
                        {{ getProperty(profiler, 'jobTitle') }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </p>

        <div *ngIf="satelliteList===null"></div>
        <div *ngIf="satelliteList === false">
            <p>
                {{ 'people.reportActivationModal.noSatelliteGoSelectJob' | translate }}
                <a (click)="navToJob()">
                    <span *ngIf="lang()=='en'">here</span>
                    <span *ngIf="lang()=='fr'">ici</span>
                </a>.
            </p>
        </div>
    </div>
    <div class="justifyRight">
        <button mat-stroked-button
                class="modalButton"
                (click)="closeActivationSection()"
        >{{ 'commons.cancel' | translate }}</button>&nbsp;&nbsp;
        <button mat-stroked-button
                class="modalButton"
                (click)="reportActivation(activationData.type)"
        >{{ 'commons.activate' | translate }}</button>
    </div>
</ng-container>
