<ngx-loading [show]="loading" [config]="{
            backdropBorderRadius: '6px',
            primaryColour: '#CDCDCD',
            secondaryColour: '#ABABAB',
            tertiaryColour: '#CDCDCD'
            }"></ngx-loading>

<div class="modal-header">
    <h4 class="modal-title pull-left">
        <span *ngIf="!chooseRightMatch"> {{ 'rms.personModal.rmCandidate' | translate }}</span>
        <span *ngIf="chooseRightMatch"> {{ 'rms.personModal.addToRm' | translate }}</span>
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body">
    <div *ngIf="chooseRightMatch && rmList">
        <h3><label for="rightMatchId">{{ 'rms.personModal.chooseRm' | translate }}</label></h3>
        <select [(ngModel)]="rmId" id="rightMatchId" (change)="assignRmId(rmId)" class="form-control">
            <option>{{ 'commons.select' | translate }}</option>
            <option
                    *ngFor="let rm of rmList"
                    [value]="rm.id"
            >
                {{ rm.name }}
            </option>
        </select>
    </div>
    <div *ngIf="chooseRightMatch">
        <p>{{ headerText }}</p>
    </div>
    <div *ngIf="chooseRightMatch">
        <hr class="panelHr"/>
    </div>
    <div class="pull-right">
        <div class="searchBarComponent" style="padding-bottom: 10px">
            <label>
                <input class="searchBar" onfocus="this.select();" [(ngModel)]="searchTerm" (keydown)="onSearch($event, searchTerm, 'return')"/>
            </label>
            <div *ngIf="!actual" class="magnifyingGlass" (click)="onSearch($event, searchTerm, 'click')">
                <img src="../../../../../../assets/header/search_and_filters/ico_search.png" alt=""/>
            </div>
            <div *ngIf="actual" class="closeSearch" (click)="clearSearch()">
                <img class="closeIdle" src="../../../../../../assets/buttons/close.png" alt=""/>
                <img class="closeHover" src="../../../../../../assets/buttons/close_hover.png" alt=""/>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div
            id="candidateList"
            *ngIf="records"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="500"
            [scrollWindow]="false"
            (scrolled)="onScroll()"
    >
        <ngx-loading [show]="loading" [config]="{
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
        }"></ngx-loading>
        <table style="width: 100%">
            <tr class="candidateRow" *ngFor="let record of records; let i = index">
                <td style="padding: 4px">

                &nbsp;&nbsp;<mat-checkbox
                    (change)="onChecked(record.id, $event)"
                    id="record_{{ i }}"
                    [checked]="rightMatchRecords.indexOf(record.id) !== -1"
                >
                {{ record['firstName'] }} {{ record['lastName'] }}
                </mat-checkbox>
                </td>
                <td style="padding: 4px; text-align: center">
                    {{ formatDateWithLang(record.creationDate, translateService.currentLang) }}
                </td>
            </tr>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="submit"
            class="modalButton pull-right"
            (click)="onSubmit()"
            style="margin-top: 15px"
    >
        {{ 'commons.save' | translate }}
    </button>
    <button (click)="closeModal()" type="button" class="modalButton pull-right"
            style="margin-top: 15px; margin-right: 15px"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <div class="clearfix"></div>
</div>
