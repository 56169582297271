import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as _ from 'lodash';

import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {Tools} from '../../../shared/barrels/misc';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {ProductsHelper} from '../../helpers/products.helper';
import {Router} from '@angular/router';
import {AccountService} from '../../services/account.service';

@Component({
    selector: 'app-administration-purchase-product-modal',
    templateUrl: './administration-purchase-product-modal.component.html',
    styleUrls: ['./administration-purchase-product-modal.component.scss']
})
export class PurchaseProductModalComponent implements OnInit, OnDestroy {
    // ToDo: account model
    @Input() account;

    public close: EventEmitter<any> = new EventEmitter();
    public editRefresh: EventEmitter<any> = new EventEmitter();

    @ViewChild('purchaseProductNgForm') purchaseProductNgForm;
    purchaseProductForm: FormGroup;
    sessionStructure: any = {};
    countryProducts = {
        Canada: 0,
        US: 0
    };

    productsGroupsNames = ['MPOENT', 'MPOENTPR', 'MPOFSA', 'MPOFSAINT', 'MPOFORM', 'MPOPERS', 'MPOPRO'];
    productsGroups = {
        Canada: {
            MPOENT: [],
            MPOENTPR: [],
            MPOFSA: [],
            MPOFSAINT: [],
            MPOFORM: [],
            MPOPERS: [],
            MPOPRO: []
        },
        US: {
            MPOENTPR: []
        }
    };
    products: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    submitted = false;
    private subscriptions = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef,
                private fb: FormBuilder,
                public bsModalRef: BsModalRef,
                private stateService: StateService,
                private apiAdministration: ApiAdministrationService,
                private router: Router,
                private accountService: AccountService,
                public translate: TranslateService) {
    }

    ngOnInit() {
        this.subscriptions.add(this.stateService.session.sessionDataWatch$.subscribe(
            (res) => {
                if (res) {
                    this.sessionStructure = this.stateService.session.sessionData.structure;
                }
            }
        ));

        this.subscriptions.add(this.apiAdministration.productsClient([this.account.id]).subscribe(
            (res) => {
                if (res) {
                    let availableProducts: any = [];
                    let availableProductsUS: any = [];
                    let availableProductsCanada: any = [];
                    for (let product of res) {
                        if (product.target.indexOf(this.account.type) > -1) {
                            availableProducts.push(product);
                            if (product.sku.includes(' US')) {
                                availableProductsUS.push(product);
                            } else {
                                availableProductsCanada.push(product);
                            }
                        }
                    }


                    if (this.isClientOrDemo) {
                        for (let product of ProductsHelper.createSkuOrder(availableProductsCanada)) {
                            for (let gr of this.productsGroupsNames) {
                                if (product.hasOwnProperty('skuOrder') && product.skuOrder[0] === gr && this.productsGroups.Canada.hasOwnProperty(gr)) {
                                    this.productsGroups.Canada[gr].push(product);
                                    this.countryProducts.Canada++;
                                }
                            }
                        }

                        for (let product of ProductsHelper.createSkuOrder(availableProductsUS)) {
                            for (let gr of this.productsGroupsNames) {
                                if (product.hasOwnProperty('skuOrder') && product.skuOrder[0] === gr && this.productsGroups.US.hasOwnProperty(gr)) {
                                    this.productsGroups.US[gr].push(product);
                                    this.countryProducts.US++;
                                }
                            }
                        }
                    } else {
                        this.productsGroups.Canada = availableProductsCanada;
                        this.countryProducts.Canada = availableProductsCanada.length;
                        this.productsGroups.US = availableProductsCanada;
                        this.countryProducts.US = availableProductsUS.length;
                    }

                    this.products = ProductsHelper.createSkuOrder(availableProducts);
                }
            }
        ));

        this.initForm();
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    closeModal() {
        this.resetForm();
        this.bsModalRef.hide();
    }

    initForm() {
        this.submitted = false;
        this.purchaseProductForm = this.fb.group({
            country: ['', [Validators.required]],
            productGroup: [''],
            productId: ['', [Validators.required]],
            subAccount: (this.account.selectedSubAccount) ? [this.account.selectedSubAccount] : [0],
            extraDescription: [''],
            clientId: [this.account.id, [Validators.required]]
        });
    }

    onCountryChange() {
        this.purchaseProductForm.get('productGroup').reset();
        this.purchaseProductForm.get('productId').reset();
    }

    formCheck(specificField = null) {

        if (this.purchaseProductNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.purchaseProductForm.controls[specificField], this.purchaseProductNgForm)) {
                    return true;
                }
            } else {
                let fields = ['productId'];
                for (let field of fields) {
                    if (this.validateFormField(this.purchaseProductForm.controls[field], this.purchaseProductNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    onSubmit(model: FormGroup) {
        this.submitted = true;

        if (model.valid) {
            let data = model.value;

            const index = _.findIndex(this.products, function (o) {
                return o.id === data.productId;
            });
            data.sku = this.products[index].sku;
            // call api to save the modification
            this.subscriptions.add(this.apiAdministration.postPurchaseProduct(data).subscribe(
                () => {
                    this.stateService.admin.stateChanged.next({refreshComponent: true});
                    this.closeModal();
                },
                error => {
                    this.errorForm = error.error;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    // ToDo: get subAccounts -> map to remove unlimited subAccounts

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.purchaseProductForm.reset();
            this.purchaseProductNgForm.submitted = false;
            this.initForm();
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }

    get isClientOrDemo() {
        return !!(this.account.type === 'client' || this.account.type === 'demo');
    }
}
