<ngx-loading
    [show]="submitted"
    [config]="loaderDisplay"
></ngx-loading>
<div class="formWrapper">
    <h4 class="modal-title" *ngIf="status !== 'edit'">
        {{ 'idwMatrices.modal.newIdwMatrix' | translate }}
    </h4>
    <h4 class="modal-title" *ngIf="status === 'edit'">
        {{ 'idwMatrices.modal.editIdwMatrix' | translate }}
    </h4>
    <div
        class="text-danger"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>

    <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

    <form  [formGroup]="idwMatrixForm" (ngSubmit)="onSubmit(idwMatrixForm)" #idwMatrixNgForm="ngForm">
        <div *ngIf="headerText">
            {{ headerText | translate }}
        </div>
        <br />

        <label for="idwMatrixName">{{ 'idwMatrices.modal.idwMatrixName' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="idwMatrixName" formControlName="name" />
            <small *ngIf="validateFormField(idwMatrixForm.controls.name, idwMatrixNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label>{{ 'commons.subAccount' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="sessionStructure">
            <mat-select formControlName="subAccount">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">{{ subAcct.name }}</mat-option>
            </mat-select>
            <small *ngIf="validateFormField(idwMatrixForm.controls.subAccount, idwMatrixNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label for="consultant">{{ 'commons.requester' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="consultant" formControlName="consultant">
        </mat-form-field>

        <label>{{ 'commons.division' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="departments">
            <mat-select formControlName="department">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{ dpt['fr'] }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onCancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="submit"
                        class="modalButton"
                        [disabled]="!idwMatrixNgForm.valid"
                >
                        <span *ngIf="status !== 'edit'">
                        {{ 'commons.create' | translate }}
                        </span>
                    <span *ngIf="status === 'edit'">
                            {{ 'commons.save' | translate }}
                        </span>
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
