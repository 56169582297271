<!-- Credits warning message -->
<app-people-survey-request-credits-warning></app-people-survey-request-credits-warning>
<table class="surveyRequestTable twoCols">
    <tbody>
        <tr>
            <td>
                <!-- Report -->
                <app-people-survey-request-report-choice
                    [reportFormGroup]="surveyRequestFormGroup.get('reportGroup')"
                    [isFormSubmitted]="isFormSubmitted"
                    [mobile]="true"
                ></app-people-survey-request-report-choice>
            </td>
            <td>
                <!-- Credits -->
                <app-people-survey-request-credits
                    [surveyRequestFormGroup]="surveyRequestFormGroup"
                    [sessionData]="sessionData"
                ></app-people-survey-request-credits>
            </td>
        </tr>
    </tbody>
</table>
<!-- Options -->
<app-mobile-options-info
    [surveyRequestFormGroup]="surveyRequestFormGroup"
    [sessionData]="sessionData"
    [isFormSubmitted]="isFormSubmitted"
    ></app-mobile-options-info>

<!-- Participant -->
<app-people-survey-request-participant-info
    [surveyRequestFormGroup]="surveyRequestFormGroup"
    [isFormSubmitted]="isFormSubmitted"
    [researchAccount]="researchAccount"
    [mobile]="true"
></app-people-survey-request-participant-info>

<!-- Request -->
<app-mobile-survey-request-info
    [surveyRequestFormGroup]="surveyRequestFormGroup"
    [sessionData]="sessionData"
    [isFormSubmitted]="isFormSubmitted"
></app-mobile-survey-request-info>
<table class="surveyRequestTable" style="margin-bottom: 50px;">
    <tbody>
        <tr>
            <td class="alignRight">
                <button type="button"
                        mat-stroked-button
                        class="modalButton"
                        (click)="handleCancelRequestForm()"
                >{{ 'commons.cancel' | translate }}</button>

                <button type="button"
                        mat-stroked-button
                        class="modalButton"
                        (click)="handleResetRequestForm()"
                >{{ 'commons.reinit' | translate }}</button>

                <button type="button"
                        mat-stroked-button
                        class="modalButton"
                        (click)="submitSurveyRequestFormValues()"
                >{{ 'commons.next' | translate }}</button>
            </td>
        </tr>
    </tbody>
</table>
