<div id="newsBackDrop" *ngIf="showNewsPanel" ></div>
<div
    id="newsFrame"
    [class]="showNewsPanel ? 'visible' : 'invisible'"

>
    <div id="header">
        <div class="pull-left">
            <h3>{{ 'commons.news' | translate}}</h3>
        </div>
        <div class="pull-right">
            <div (click)="closeNewsWindow()" aria-label="Close" class="x-close pull-right"
                 style="font-size: 20pt;">
                &#x00D7;
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div id="innerFrameContainer">
        <div id="innerFrame">
            <div *ngIf="news && news['newsItems']">
                <ng-container *ngFor="let newsItem of news['newsItems']; let i = index">
                    <div style="font-weight: bold">{{ news.versions[i]['creationDate'] | date:dateFormat }} Version {{ news.versions[i].version }}</div>
                    <ng-container *ngFor="let item of newsItem">
                        <ul>
                            <li>{{ item }}</li>
                        </ul>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div id="footer">
        <div class="pull-right">
            <button (click)="closeNewsWindow()" class="modalButton">
                <span>{{ 'commons.close' | translate }}</span>
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
