import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormService} from '../../../services/dynamic-form.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-form-table-mobile',
  templateUrl: './form-table-mobile.component.html',
  styleUrls: ['./form-table-mobile.component.scss']
})
export class FormTableMobileComponent implements OnInit, OnDestroy {

    @Input() formDefinition: any;
    @Output() formOutput = new EventEmitter();
    hide = true;
    buttonsFolder = 'assets/buttons/';
    language: any;

    form: FormGroup;
    submitLoginBtn: any;
    private subscriptions = new Subscription();

    constructor(
        private dynamicFormService: DynamicFormService,
        public translate: TranslateService
    ) {
    }

  ngOnInit() {
      this.formDefinition.structure.forEach(structure => {
          structure.forEach(
              (structureEl, index) => {
                  if (index > 0) {
                      if (structure[index - 1][0].name && typeof structure[index - 1][0].name === 'object') {
                          structureEl[0].name_ml = structure[index - 1][0].name;
                      }
                  }
                  if (structureEl[0].name === 'submit' &&
                      structureEl[0].display &&
                      structureEl[0].display.type === 'submit_login') {
                      this.submitLoginBtn = structureEl[0].display;
                  }
                  if (structureEl[1] &&
                      structureEl[1].name === 'submit' &&
                      structureEl[1].display &&
                      structureEl[1].display.type === 'submit_login') {
                      this.submitLoginBtn = structureEl[1].display;
                  }
          });
      });
      this.form = this.dynamicFormService.initForm(this.formDefinition);
      this.language = this.translate.currentLang;
      this.subscriptions.add(this.translate.onLangChange.subscribe((event) => {
          this.language = event.lang;
      }));
  }

  onSubmit(data: any) {
     this.formOutput.emit(data);
  }

  /**
     *
     * @param item - an input in the formDefinition
     * @returns {boolean}
   */

  columnDisplay(item: any) {
     return item.options === undefined || (!item.options.reverse && !item.options.merge);
  }

  /**
     *
     * @param form - the form
     * @param item - is either of 'below', 'text' or 'border'
     * @param type - item - an input in the formDefinition
     * @returns {boolean}
   */

  warning(form: any, item: any, type: string) {
      if ((item.display.visualWarnings !== undefined) && item.display.visualWarnings[type]) {
            if (item.states && item.states.touched) {
                if (!form.controls[item.name].valid) { return true; }
            } else {
                if (form.controls[item.name].untouched) {
                    return false;
                } else { return !form.controls[item.name].valid; }
            }
      }

  }

    ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

}
