<div class="top affix tRight" id="infoPanelNews">
    <div class="col-md-12">
        <h2 *ngIf="translateService.currentLang=='fr'">ACTIVITÉS RÉCENTES</h2>
        <h2 *ngIf="translateService.currentLang=='en'">RECENT ACTIVITIES</h2>
        <hr/>
        <div style="margin-top: 65px">
            <div *ngFor="let record of allDataFinal">
                <p *ngIf="translateService.currentLang=='fr'">
                    <span *ngIf="produceDate(record['creationDate'])!=''" class="semiBold">{{ produceDate(record['creationDate']) }}</span><br>
                    <span *ngIf="record.type == 'lastRequests'">
                        <a (click)="activateSidePanel(record.id)">Un questionnaire à été envoyé a {{ record['firstName'] }} {{ record['lastName'] }}</a>
                    </span>
                    <span *ngIf="record.type == 'lastJobs'">
                        <a (click)="goToJob(record.id)">Le poste {{ record['jobTitle'] }} a été créé</a>
                    </span>
                    <span *ngIf="record.type == 'lastCompleted'">
                        <a (click)="goToPerson(record.id)">{{ record['firstName'] }} {{ record['lastName'] }} a complété son questionnaire</a>
                    </span>
                    <span *ngIf="record.type == 'nonActivated'">
                        <a (click)="activateSidePanel(record.id)">Le questionnaire de {{ record['firstName'] }} {{ record['lastName'] }}
                        a été complété mais n'est pas activé</a>
                    </span>
                </p>
                <p *ngIf="translateService.currentLang=='en'">
                    <span *ngIf="produceDate(record['creationDate'])!=''" class="semiBold">{{ produceDate(record['creationDate']) }}</span><br>
                    <span *ngIf="record.type == 'lastRequests'">
                        <a (click)="activateSidePanel(record.id)">A questionnaire has been sent to {{ record['firstName'] }} {{ record['lastName'] }}</a>
                    </span>
                    <span *ngIf="record.type == 'lastJobs'">
                        <a (click)="goToJob(record.id)">The job {{ record['jobTitle'] }} was created</a>
                    </span>
                    <span *ngIf="record.type == 'lastCompleted'">
                        <a (click)="goToPerson(record.id)">{{ record['firstName'] }} {{ record['lastName'] }} completed his questionnaire</a>
                    </span>
                    <span *ngIf="record.type == 'nonActivated'">
                        <a (click)="activateSidePanel(record.id)">The questionnaire of {{ record['firstName'] }} {{ record['lastName'] }}
                        has been completed but is not activated</a>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>

