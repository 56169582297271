
    <div style="overflow: hidden; width: 42px">
        <div style="float: left; width: 15px">{{ talentNumber }}</div>
        <div style="
            float: left;
            margin-top: -1px;
            margin-left: 5px;"
        [class]="talentClass">
            ⬤
        </div>
        <div class="clearfix"></div>
    </div>
    