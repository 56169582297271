import {Component, EventEmitter, ChangeDetectionStrategy, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {GraphBoardsSearchFiltersService} from '../../../services/graphboards-search-filters.service';
import {TranslateService} from '@ngx-translate/core';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {GraphBoardsListService} from '../../../services/graphboards-list.service';
import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-graphboards-search-filters',
    templateUrl: './graphboards-search-filters.component.html',
    styleUrls: ['./graphboards-search-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsSearchFiltersComponent extends ComponentPrimitive implements OnInit {

    @Input() whereAmI: string;
    @Input() width: string;
    @Output() selectPreference = new EventEmitter;

    preferenceUrl: string;
    userDataId: string;

    date: any;

    private subscriptions = new Subscription();

    constructor(
        public graphBoardsSearchFiltersService: GraphBoardsSearchFiltersService,
        public graphBoardsList: GraphBoardsListService,
        public stateService: StateService,
        private userPreferencesService: UserPreferencesService,
        private changeDetectorRef: ChangeDetectorRef,
        protected router: Router,
        public translate: TranslateService,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        this.graphBoardsSearchFiltersService.initDateFormat(this.dateFormat());
        this.graphBoardsSearchFiltersService.setSubAccountsOptions();
        this.graphBoardsSearchFiltersService.setDepartmentsOptions();

        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.graphBoardsSearchFiltersService.initDateFormat(this.dateFormat());
            this.checkPeriodDates();
        }));

        this.graphBoardsList.checkModuleUrl();
        this.graphBoardsSearchFiltersService.checkPreferences(this.whereAmI);

        if (this.whereAmI === 'filter') {
            this.graphBoardsSearchFiltersService.initInputState();
        }

        if (this.whereAmI === 'preferences') {

            let accountDataId = (this.graphBoardsList.accountData) ? this.graphBoardsList.accountData.id : '';
            this.userDataId = (this.graphBoardsList.userData) ? this.graphBoardsList.userData.id : '';
            this.preferenceUrl = 'admin/account/' + accountDataId + '/user/' + this.userDataId + '/preferences';

            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.graphBoardsList.userPreferences, this.preferenceUrl);

            // Init state with session vars
            this.graphBoardsSearchFiltersService.initInputStatePreferences(this);
            this.changeDetectorRef.markForCheck();
        }

        this.graphBoardsSearchFiltersService.initSubAccountFilter();

        this.checkPeriodDates();

        this.graphBoardsSearchFiltersService.initColumnsFilter();
    }

    setFilters(event, filter) {
        switch (filter) {
            case 'graphboardStartDate':
                this.graphBoardsSearchFiltersService.setSearchFilter(event, 'graphboardStartDate');
                break;
            case 'graphboardEndDate':
                this.graphBoardsSearchFiltersService.setSearchFilter(event, 'graphboardEndDate');
                break;
            case 'subAccount':
                let subAccounts = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(subAccounts, 'subAccountCol', this.translate.instant('commons.subAccount'));
                this.graphBoardsSearchFiltersService.subAccount(subAccounts, this, this.graphBoardsList.specificState);
                break;
            case 'department':
                let deps = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(deps, 'depCol', this.translate.instant('commons.division'));
                this.graphBoardsSearchFiltersService.onDepartmentOptionChange(deps, this, this.graphBoardsList.specificState);
                break;
            case 'columnDisplay':
                this.graphBoardsSearchFiltersService.columnDisplay(event, this, this.graphBoardsList.specificState);
                break;
        }
        this.graphBoardsList.getGraphBoardsList(true);
    }

    checkPeriodDates() {
        if (this.graphBoardsList.evaluationStartDate) {
            let dateStartObject = this.graphBoardsList.transformDate(this.graphBoardsList.graphBoardStartDate);
            this.graphBoardsSearchFiltersService.dateStartModel = dateStartObject;
            this.graphBoardsSearchFiltersService.dateStart = dateStartObject;
        }

        if (this.graphBoardsList.evaluationEndDate) {
            let dateEndObject = this.graphBoardsList.transformDate(this.graphBoardsList.graphBoardEndDate);
            this.graphBoardsSearchFiltersService.dateEndModel = dateEndObject;
            this.graphBoardsSearchFiltersService.dateEnd = dateEndObject;
        }
    }

    resetSearchFiltersAction(preferences = null) {
        this.graphBoardsSearchFiltersService.resetSearchFiltersAction();

        if (this.whereAmI === 'filter') {
            this.graphBoardsSearchFiltersService.resetSearchFilters();
        }
        for (let report in this.stateService[this.graphBoardsList.specificState].listSearchFilters) {
            if (report && this.stateService[this.graphBoardsList.specificState].listSearchFilters.hasOwnProperty(report)) {
                if (report !== 'search') {
                    this.stateService[this.graphBoardsList.specificState].listSearchFilters[report] = null;
                }
            }
        }

        if (preferences === null) {
            this.graphBoardsList.setInitialColumnsList();
            this.graphBoardsList.listColumns = JSON.parse(JSON.stringify(this.graphBoardsList.listColumnsInitialConfig));

            // for (let col of this.stateService[this.graphBoardsList.specificState].columns) {
            //     this.checkedColumn[col] = (_.find(this.graphBoardsList.graphBoardState.listColumns, (o) => {
            //         if (o && o.display === true) {
            //             return o.key === col;
            //
            //         }
            //     })) ? true : null;
            // }
            //
            // let colSelected = 0;
            // for (let col of this.stateService[this.graphBoardsList.specificState].columns) {
            //     if (col !== 'creationDate' && (_.find(this.graphBoardsList.graphBoardState.listColumns, (o) => {
            //         if (o && o.display === true) {
            //             return o.key === col;
            //
            //         }
            //     })) ? true : null) {
            //         colSelected++;
            //     }
            // }
        } else {
            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.graphBoardsList.userPreferences, this.preferenceUrl, 'graphboard');
            this.graphBoardsSearchFiltersService.initInputStatePreferences(this);
        }

        this.selectPreference.emit({
            type: 'selectVanilla'
        });

    }

    resetSearchFilters() {
        if (this.whereAmI === 'filter' && this.actualModule.name !== 'graphboard') {
            const preferences = (this.userPreferencesService.isPreferenceSet('graphboard')) ?
                this.stateService.session.preferences['graphboard'] :
                null;
            this.resetSearchFiltersAction(preferences);
        } else {
            this.resetSearchFiltersAction();
            this.userPreferencesService.emptyUserPreferences('graphboard', this.userDataId);
        }
        this.graphBoardsList.getGraphBoardsList(true);
        this.changeDetectorRef.markForCheck();
    }
}
