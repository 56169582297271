import {Component, ChangeDetectionStrategy, Input, OnDestroy, OnChanges} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {PeopleSurveyRequestHelper} from '../../people-survey-request.helper';
import {StateService} from '../../../../../../core/services/state/state.service';

@Component({
    selector: 'app-people-survey-request-report-choice',
    templateUrl: './report-choice.component.html',
    styleUrls: ['./report-choice.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestReportChoiceComponent implements OnChanges {
    @Input() reportFormGroup: FormGroup;
    @Input() isFormSubmitted: boolean;
    @Input() currentLanguage: string;
    @Input() mobile = false;

    disableDitReportByDefault = false;
    sessionData: any;

    constructor(
        private stateService: StateService
    ) {}

    get raPermission() {
        return (this.stateService.session &&
            this.sessionData &&
            this.sessionData.userData &&
            this.sessionData.userData.permissions) ? this.sessionData.userData.permissions.ra : false;
    }

    ngOnChanges(): void {
        let accountType = this.stateService.session.sessionData.accountType;
        this.disableDitReportByDefault = !PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(accountType);
        this.sessionData = this.stateService.session.sessionData;
    }
}
