<ngx-loading
    [show]="idwMatrix.loading || person.loading"
    [config]="loaderDisplay"
    [class]="'idwMatrixLoading'"
></ngx-loading>
<div class="sticky" *ngIf="!isLandscape || showPortraitButtons">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <h2 class="name">
                {{ idwMatrix.name ? idwMatrix.name.toUpperCase() : '' }}
            </h2>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                [disabled]="!recordsUnique || !recordsUnique.length"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="itemEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}</button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr" />
</div>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 class="name">
        {{ idwMatrix.name ? idwMatrix.name.toUpperCase() : '' }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="editRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-idw-matrices-edit
        [status]="'edit'"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-idw-matrices-edit>
</div>
<div *ngIf="pdfRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-idw-matrices-pdf
        (close)="closeAllRequestsPanels()"
    >
    </app-mobile-idw-matrices-pdf>
</div>
<div *ngIf="!pdfRequest && !editRequest"
     class="fullView-wrapper stretched"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <div class="informationPanel">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable">
                <tr>
                    <td> {{ 'idwMatrices.sideBar.activityName' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!idwMatrix.name">
                            {{ idwMatrix.name || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!idwMatrix.consultant">
                            {{ idwMatrix.consultant || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!setSubAccountName(idwMatrix.subAccount)">
                            {{ setSubAccountName(idwMatrix.subAccount) || notSpecified()  }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.division' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!idwMatrix.department">
                            {{ idwMatrix.department || notSpecified() }}
                        </span>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
    </div>
    <hr class="panelHr"/>
    <div class="padding17 withIcon">
        <h2>
            {{ 'commons.individus' | translate }}
        </h2>
        <mat-expansion-panel
            [expanded]="addingPanelOpenState"
            (opened)="openPanel()"
        >
            <mat-expansion-panel-header [collapsedHeight]="'25px'" [expandedHeight]="'25px'">
                <mat-panel-title>
                    <button
                        mat-stroked-button
                        matSuffix
                        mat-icon-button
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-mobile-idw-matrix-person-adding
                *ngIf="addingPanelOpenState"
                (closePanel)="closePanel()"
            ></app-mobile-idw-matrix-person-adding>
        </mat-expansion-panel>
    </div>
    <div *ngIf="recordsUnique && recordsUnique.length" class="padding17">
        <table class="records">
            <ng-container *ngFor="let i of createRange(actualPage, maxPerPage, true)">
                <tr>
                    <td style="width: 42%">
                        <div *ngIf="recordsUnique[i*2]" class="truncate">
                            <span>
                                <a (click)="goToPerson(recordsUnique[i*2].id)">{{ recordsUnique[i*2]['firstName'] }} {{ recordsUnique[i*2]['lastName'] }}</a>&nbsp;&nbsp;
                            </span>
                        </div>
                        <div *ngIf="!recordsUnique[i*2]">&nbsp;</div>
                    </td>
                    <td style="width: 8%"><span
                        *ngIf="recordsUnique[i*2]"
                        (click)="removeItem(recordsUnique[i*2].id)"
                        style="font-weight: bold; cursor: pointer">
                                x
                        </span></td>
                    <td style="width: 42%">
                        <div *ngIf="recordsUnique[i*2+1]"  class="truncate">
                            <span>
                                <a (click)="goToPerson(recordsUnique[i*2+1].id)">{{ recordsUnique[i*2+1]['firstName'] }} {{ recordsUnique[i*2+1]['lastName'] }}</a>&nbsp;&nbsp;
                            </span>
                        </div>
                    </td>
                    <td style="width: 8%">
                        <span
                            *ngIf="recordsUnique[i*2+1]"
                            (click)="removeItem(recordsUnique[i*2+1].id)"
                            style="font-weight: bold; cursor: pointer">
                                    x
                        </span>
                    </td>
                </tr>
            </ng-container>
        </table>
        <div class="justifyRight">
            <mat-grid-list cols="1" rowHeight="25px">
                <mat-grid-tile
                    colspan="1"
                    rowspan="1"
                >&nbsp;
                    <div *ngIf="actualPage > 1" (click)="goToPage(-1)"><img alt="<" src="{{buttonsFolder}}before.png"></div>
                    <div *ngIf="actualPage == 1"><img alt="<" src="{{buttonsFolder}}before_deactivated.png"></div>
                    <div *ngIf="actualPage < maxPage" (click)="goToPage(1)"><img alt=">" src="{{buttonsFolder}}next.png"></div>
                    <div *ngIf="actualPage >= maxPage"><img alt=">" src="{{buttonsFolder}}next_deactivated.png"></div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <hr class="panelHr"/>
    <div class="padding17">
        <mat-tab-group
            (selectedIndexChange)="switchView($event)"
        >
            <mat-tab label="{{ 'commons.traits' | translate }}"></mat-tab>
            <mat-tab label="{{ 'commons.behavior' | translate }}"></mat-tab>
        </mat-tab-group>
        <div class="viewTab">
            <app-idw-matrix
                *ngIf="participants"
                [viewType]="viewType"
                [itemToDisplay]="participants"
                [lang]="currentLang"
            >
            </app-idw-matrix>
            <div *ngIf="participants && viewType === 'prb' && !hasPrb">
                <div class="outlineOverlay"></div>
                <div class="noneTitle">{{ 'commons.none' | translate }}</div>
            </div>
        </div>
    </div>
</div>
