<div id="container" class="mat-grid-list-wrapper" style="z-index: 10000">
    <mat-grid-list cols="3" rowHeight="63px">
        <mat-grid-tile
            colspan="2"
            rowspan="1"
        >
            <div id="logoContainer">
                <img alt="Ngenio-Connect" src="../../../../../assets/login/logoLogin.png"/>
            </div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <div class="languageSwitch">
                <a *ngIf="translate.currentLang!='fr'" (click)="langChange('fr')">Français</a>
                <a *ngIf="translate.currentLang!='en'" (click)="langChange('en')">English</a>
            </div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="3"
            rowspan="1"
        >
            <h1>{{ 'setPass.changePassword' | translate }}</h1>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="30px">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
            <p *ngIf="emptyUserName" class="text-danger">
                {{ 'login.enterUsername' | translate }}
            </p>
        </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1">
        <mat-grid-tile *ngIf="!token"
                       colspan="1"
                       rowspan="1"
        >
            <!-- Forget password form -->
            <form
                *ngIf="!token"
                [formGroup]="forgetPassForm"
                (ngSubmit)="onSubmitForgetPassForm(forgetPassForm)"
                class="topFullWidth"
            >

                <mat-form-field class="full-width">
                    <!-- username -->
                    <input matInput
                           type="text"
                           [class.text-danger]="badUserName"
                           placeholder="{{ 'resetPass.userName' | translate }}"
                           formControlName="username"
                    >
                </mat-form-field>
                <button
                    mat-raised-button
                    type="button"
                    (click)="onCancelForgetPassForm()"
                >
                    {{ 'commons.cancel' | translate }}
                </button> &nbsp;
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="!forgetPassForm.valid"
                >
                    {{ 'commons.finish' | translate }}
                </button>
            </form>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="token"
                       colspan="1"
                       rowspan="1"
        >
            <!-- Change password form -->
            <form
                *ngIf="token"
                [formGroup]="changePassForm"
                (ngSubmit)="onSubmitChangePassForm(changePassForm)"
                #changePassNgForm="ngForm"
                class="topFullWidth"
            >
                <!-- password -->
                <mat-form-field class="full-width">
                    <small
                        *ngIf="validateFormField(changePassForm.controls.password, changePassNgForm)"
                        class="text-danger"
                    >
                                <span [hidden]="!instructionOn || !changePassForm.controls.password.errors['required']">
                                    {{ 'commons.passwordMandatory' | translate }}<br />
                                </span>
                        <span [hidden]="!instructionOn || !changePassForm.controls.password.errors['minlength']">
                                    {{ 'admin.userForm.pwd8CharsWarning' | translate }}<br />
                                </span>
                    </small>
                    <small *ngIf="instructionOn && changePassForm.hasError('weakPassword') &&
                                (changePassForm.controls.password.dirty ||
                                changePassForm.controls.password.touched)"
                           class="text-danger"
                    >
                                <span>
                                    {{ 'admin.userForm.pwdRuleText' | translate }}
                                </span>
                    </small>
                    <small *ngIf="instructionOn && discrepancies &&
                                (changePassForm.controls.password.dirty ||
                                changePassForm.controls.password.touched)"
                           class="text-danger"
                    >
                                <span>
                                    {{ 'admin.userForm.pwdDiscrepancy' | translate }}
                                </span>
                    </small>
                    <input matInput
                           [type]="hide ? 'password' : 'text'"
                           placeholder="{{ 'commons.password' | translate }}"
                           formControlName="password"
                    >
                    <mat-icon
                        matSuffix
                        (click)="hide = !hide"
                    >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>

                <mat-form-field class="full-width">
                    <input matInput
                           [type]="hide ? 'password' : 'text'"
                           placeholder="{{ 'admin.userForm.confirmPwd' | translate }}"
                           formControlName="pwdConfirmation"
                    >
                    <mat-icon
                        matSuffix
                        (click)="hide = !hide"
                    >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </mat-form-field>
                <input type="hidden" formControlName="token">
                <button
                    mat-raised-button
                    type="button"
                    (click)="onCancelForgetPassForm()"
                >
                    {{ 'commons.cancel' | translate }}
                </button> &nbsp;
                <button
                    mat-raised-button
                    type="submit"
                    [disabled]="!changePassForm.valid && changePassNgForm.submitted"
                >
                    {{ 'commons.finish' | translate }}
                </button>
            </form>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="messageFormSubmit || messagePasswordChange">
            <p *ngIf="messageFormSubmit">
                {{ 'commons.pwfSubmitted' | translate }}
            </p>
            <p *ngIf="messagePasswordChange">
                {{ 'commons.pwChanged' | translate }}
            </p>
        </mat-grid-tile>
    </mat-grid-list>
</div>
