<!-- ToDo Style for testing purpose in div below -->
<div
    id="peopleSidebar"
    *ngIf="person && stateService.people"
    (touchmove)="touchmoveAction($event)"
    (scroll)="scrollAction($event)"
    (click)="openSidebar()"
>
    <ngx-loading
        [show]="person.loading"
        [config]="loaderDisplay"
        [class]="'personLoading'"
    ></ngx-loading>
    <!-- ToDo many condition for display here -->
    <div id="peopleSidebarHead">
        <div class="pull-left">
            <h2 class="name">
                {{ person.fullName.toUpperCase() }}
            </h2>
        </div>
        <div *ngIf="config=='list'" (click)="goToNews()" class="x-close pull-right"
             style="font-size: 20pt;"
        >
            &#x00D7;
        </div>
        <div class="clearfix"></div>
        <div *ngIf="person.recordType ==='candidate'"
             class="type">{{ 'people.commons.candidate' | translate
            }}
        </div>
        <div *ngIf="person.recordType ==='employee'"
             class="type">{{ 'people.commons.employee' | translate
            }}
        </div>
        <div *ngIf="person.recordType !== 'employee' && person.recordType !== 'candidate'"
             class="type">&nbsp;
        </div>
    </div>

    <div *ngIf="!user.isSender && actualModule.archive">
        <span (click)="modStatus('archive', false)"
              class="fakeLink">{{ 'commons.reactivate' | translate }}
        </span>&nbsp;&nbsp;
        <span *ngIf="user.canDelete" (click)="modStatus('delete')"
              class="fakeLink">{{ 'commons.deletePerson' | translate }}
        </span>
    </div>
    <!-- Sidebar navigation buttons -->
    <div id="peopleSidebarNavButtons">
        <div *ngIf="!actualModule.archive">
            <div *ngIf="!user.isSender">
                <app-nav-button *ngIf="config === 'list' && (person.hasAnMpoReport || person.hasIac)" (buttonActon)="goToFullReport()">
                    <div class="buttonImage"><img alt="" src="{{buttonsFolder}}fullView.png"/></div>
                    <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                </app-nav-button>
                <app-nav-button *ngIf="config !== 'list'" (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="{{buttonsFolder}}fullView.png"/></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <app-nav-button *ngIf="person.hasAnMpoReport || person.hasIac" (buttonActon)="peopleModalHandlingService.openPdfModal()">
                    <div class="buttonImage"><img alt="" src="{{buttonsFolder}}download.png"/></div>
                    <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                </app-nav-button>
            </div>
            <div class="pull-left dropdown" dropdown>
                <div dropdownToggle
                     class="dropdown-toggle sideBarButton dropdownButton"
                     data-toggle="dropdown"
                     (click)="checkMenu()"
                >
                    {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                </div>
                <ul *dropdownMenu class="dropdown-menu"
                    [class.dropdown-menu-right]="person.hasAnMpoReport || person.hasIac"
                    [class.dropdown-menu-left]="!(person.hasAnMpoReport || person.hasIac)"
                >
                    <li *ngIf="!actualModule.archive">
                        <a (click)="openPeopleEditModal()">
                            {{ 'commons.editInfo' | translate }}
                        </a>
                    </li>
                    <!-- MPO------------------------------------------------------------------->
                    <!-- SEND MPO -->
                    <ng-container
                        *ngIf="person.canTakeMpo">
                        <li>
                            <a (click)="openModal('sendMpo', person.personToDisplay)">
                                {{ 'commons.sendMpo' | translate }}
                            </a>
                        </li>
                    </ng-container>
                    <!-- RESEND/DELETE MPO QUESTIONNAIRE -->
                    <ng-container *ngIf="person.hasMpoRequest">
                        <li>
                            <a>
                                <span>{{ 'people.sidebar.mpoRequestHandling' | translate }}</span> <span
                                class="caret"></span>
                            </a>
                        </li>
                        <li *ngFor="let request of person.evaluationRequestsContainingOnly('personality'); let i = index">
                            <a (click)="openModal('resendMpo', i, request['createTempUser'])">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.resendRequest' | translate }}
                                <span
                                    *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                            </a>
                        </li>
                        <ng-container *ngIf="!user.isSender && person.personToDisplay">
                            <li *ngFor="let request of person.evaluationRequestsContainingOnly('personality')">
                                <a (click)="testAndReportHandling.deleteMpoRequest(request.requestId)">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.deleteQuestionnaire' | translate }}
                                </a>
                            </li>
                        </ng-container>
                    </ng-container>
                    <!-- END MPO -------------------------------------------------------------------------->

                    <!-- RA ------------------------------------------------------------------->
                    <!-- SEND RA QUESTIONNAIRE -->
                    <ng-container *ngIf="stateService.hasIac">
                        <ng-container *ngIf="person.canTakeIac">
                            <li>
                                <a (click)="openModal('sendIac', person.personToDisplay)">
                                    {{ 'commons.sendIac' | translate }}
                                </a>
                            </li>
                        </ng-container>
                        <!-- RESEND/DELETE RA QUESTIONNAIRE -->
                        <ng-container *ngIf="person.hasIacRequest">
                            <li>
                                <a>
                                    <span>{{ 'people.sidebar.raRequestHandling' | translate }}</span> <span
                                    class="caret"></span>
                                </a>
                            </li>
                            <li *ngFor="let request of person.evaluationRequestsContainingOnly('iac'); let i = index">
                                <a (click)="openModal('resendIac', i, request['createTempUser'])">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.resendRequest' | translate }}
                                    <span
                                        *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                </a>
                            </li>
                            <ng-container *ngIf="!user.isSender && person.hasAnMpoReport">
                                <li *ngFor="let request of person.evaluationRequestsContainingOnly('iac')">
                                    <a (click)="testAndReportHandling.deleteIacRequest(request.requestId)">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.deleteQuestionnaire' | translate }}
                                    </a>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <!-- END RA ------------------------------------------------------------------->

                    <!-- PRB ------------------------------------------------------------------->
                    <!-- SEND QUESTIONNAIRE PERCEPTION -->
                    <ng-container *ngIf="person.canTakeExtraPrb">
                        <li>
                            <a (click)="openModal('sendAdditionalPrb', person.personToDisplay)">
                                {{ 'commons.sendAdditionalPrb' | translate }}
                            </a>
                        </li>
                    </ng-container>
                    <!-- RESEND QUESTIONNAIRE MPO/PERCEPTION -->
                    <ng-container
                        *ngIf="!person.checkRequestsContains('variant', '0,1') &&
                                person.evaluationRequestsContainingOnly('personality').length === 0 &&
                                person.evaluationRequestsContainingOnly('iac').length === 0"
                    >
                        <li *ngFor="let request of person.requests; let i = index">
                            <a (click)="openModal('resendMpo', i, request['createTempUser'])">
                                {{ 'commons.resendRequest' | translate }}
                                <span
                                    *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                            </a>
                        </li>
                    </ng-container>
                    <!-- RESEND QUESTIONNAIRE PERCEPTION -->
                    <ng-container *ngIf="person.hasPrbRequest">
                        <li>
                            <a>
                                <span>{{ 'people.sidebar.prbRequestHandling' | translate }}</span> <span
                                class="caret"></span>
                            </a>
                        </li>
                        <li *ngFor="let request of person.evaluationRequestsContainingOnly('prb'); let i = index">
                            <a (click)="openModal('resendPrb', i, request['createTempUser'])">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.resendRequest' | translate }}
                                <span
                                    *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                            </a>
                        </li>
                        <li *ngFor="let request of person.evaluationRequestsContainingOnly('prb')">
                            <a (click)="testAndReportHandling.deletePrbRequest(request.requestId)">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'commons.deleteQuestionnaire' | translate }}
                            </a>
                        </li>
                    </ng-container>
                    <!-- END PRB ------------------------------------------------------------------->
                    <!-- ARCHIVE -->
                    <li *ngIf="!user.isSender && actualModule.name === 'people'" role="menuitem">
                        <a *ngIf="!actualModule.archive &&
                                            !(
                                                person.checkRequestsContains('variant', '0,1') ||
                                                (stateService.hasIac && person.checkRequestsContains('hasSurveyRa', true))
                                            )
                                        "
                           (click)="modStatus('archive', true)"
                        >
                            {{ 'commons.archive' | translate }}</a>
                        <a *ngIf="actualModule.archive"
                           (click)="modStatus('archive', false)">{{ 'commons.reactivate' | translate
                            }}</a>
                    </li>

                    <!-- DELETE PERSON -->
                    <li *ngIf="(!user.isSender && user.canDelete) || (!person.hasReport && user.isSender)"
                        role="menuitem"><a
                        (click)="modStatus('delete')">{{ 'commons.deletePerson' | translate }}</a>
                    </li>
                </ul>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div style="padding-bottom: 5px;">
        <hr class="panelHr"/>
    </div>
    <!-- INFORMATION -->
    <div class="infoWrap">
        <table class="infoTableWrap">
            <tr>
                <td class="switchButton">
                    <button type="button"
                            class="btn btn-default"
                            (click)="toggleInformationVisibility()"
                            style="border-radius: 0"
                    >
                        <span *ngIf="!statePeople.informationVisibility">+</span>
                        <span *ngIf="statePeople.informationVisibility">-</span>
                    </button>
                </td>
                <td>
                    <mat-tab-group
                        [(selectedIndex)]="statePeople.selectedInfoQuestionTab"
                        (selectedIndexChange)="switchTab($event)"
                    >
                        <mat-tab label="{{ 'commons.informations' | translate }}">
                            <div *ngIf="statePeople.informationVisibility">
                                <table class="infoTable"
                                       *ngIf="person.personToDisplay">
                                    <tr>
                                        <td>
                                            <strong>{{ 'commons.creationDate' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            {{ person.creationDate | date:dateFormat || notSpecified() }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>{{ 'commons.modificationDate' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            {{ person.date | date:dateFormat || notSpecified() }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'commons.email' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                                            <span [class.na]="!person.email">
                                                {{ person.email || notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'commons.title' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                                            <span [class.na]="!person.employeePosition">
                                                {{ person.employeePosition || notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'commons.subAccount' | translate }}<span
                                            [innerHTML]="titleSep"></span> </strong>
                                            <span>
                                                {{ setSubAccountName(person.subAccount)
                                            || notSpecified()  }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'commons.organisation' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                                            <span [class.na]="!person.organisation">
                                                {{ person.organisation || notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                                            <span [class.na]="!person.departmentToDisplay">
                                                {{ person.departmentToDisplay || notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{ 'people.requestModal.job' | translate }}<span [innerHTML]="titleSep"></span>&nbsp;</strong>
                                            <a *ngIf="config === 'report' && person.jobsJobTitle && person.jobsId"
                                               (click)="goToJob(person.jobsId, person.id, 'people')">{{ person.jobsJobTitle || notSpecified() }}
                                            </a>
                                            <span *ngIf="config !== 'report' || !person.jobsJobTitle"
                                                  [class.na]="!person.jobsJobTitle">
                                                {{ person.jobsJobTitle || notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-tab>
                        <mat-tab>
                            <ng-template mat-tab-label>
                                <div style="position: relative">
                                    <div>{{ 'commons.survey' | translate }}s</div>
                                    <div *ngIf="person.hasRequest" class="notCompletedMark">
                                    </div>
                                </div>
                            </ng-template>
                            <div *ngIf="statePeople.informationVisibility">
                                <table class="infoTable"
                                       *ngIf="stateService.hasMpoTestAccess">
                                    <tr>
                                        <th style="width:30px;">
                                            <div class="subSectionToggleButton" (click)="toggleInfo('mpo')">
                                                <span *ngIf="!infoTestsVisibility.mpo">+</span>
                                                <span *ngIf="infoTestsVisibility.mpo">-</span>
                                            </div>
                                        </th>
                                        <th class="withLink">
                                            <div class="pull-left qTitle">
                                                <div style="position: relative">
                                                <span
                                                    style="margin: 0; padding: 0">{{ questionnaireBlockTitles.first.title }}</span>
                                                    <div
                                                        *ngIf="person.hasAnMpoQuestionnaireSent"
                                                        class="notCompletedMark">
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!actualModule.archive">
                                                <div class="pull-right"
                                                     *ngIf="person.hasAnMpoQuestionnaireSent && !actualModule.archive">
                                                    <ng-container *ngIf="!user.isSender">
                                                        <span
                                                            *ngFor="let request of person.evaluationRequestsContainingOnly('personality')"
                                                            class="pull-right">
                                                            <a (click)="testAndReportHandling.deleteMpoRequest(request.requestId)">
                                                                {{ 'commons.delete' | translate }}
                                                            </a>
                                                        </span>
                                                    </ng-container>
                                                    <span
                                                        *ngFor="let request of person.evaluationRequestsContainingOnly('personality'); let i = index"
                                                        class="pull-right">
                                                        <a (click)="openModal('resendMpo', i, request['createTempUser'])">
                                                            {{ 'commons.resend' | translate }}
                                                            <span
                                                                *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                                        </a>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </div>

                                                <div class="pull-right" *ngIf="person.hasNoMpo && !person.hasMpoOnHold">
                                                    <a
                                                        (click)="openModal('sendMpo')"
                                                    >
                                                        {{ 'commons.send' | translate}}
                                                    </a>
                                                </div>
                                                <div class="pull-right" *ngIf="person.hasMpoOnHold">
                                                    <a (click)="this.peopleModalHandlingService.openReportsActivationModal(
                                                        this.testAndReportHandling.activateMpoReportsHandling()
                                                    )">
                                                        {{'people.sidebar.activate' | translate }}
                                                    </a>
                                                </div>
                                                <div class="clearfix"></div>

                                            </ng-container>
                                            <div class="clearfix"></div>
                                        </th>
                                    </tr>
                                    <tr *ngIf="questionnaireBlockTitles.first.type">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>Type:</strong>
                                            <span style="text-transform: lowercase;">
                                                <span
                                                    *ngIf="questionnaireBlockTitles.first.type === '1,0'"> {{'people.commons.traitsOnly' | translate}}</span>
                                                <span
                                                    *ngIf="questionnaireBlockTitles.first.type === '1,1'"> {{'people.commons.traitsAndPrb' | translate}}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'people.commons.status' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            {{ testAndReportHandling.produceStatus('mpo') }}
                                        </td>
                                    </tr>

                                    <tr *ngIf="infoTestsVisibility.mpo">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.requestedBy' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span
                                                [class.na]="((!person.requestedByInfo ||
                                                !person.requestedByInfo.value || person.canTakeMpo) &&
                                                !person.hasRequest) ||
                                                (person.hasRequest && !person.mpoRequestRequestedBy && !person.hasMpo)"
                                            >
                                                <span
                                                    *ngIf="person.requestedByInfo &&
                                                        person.requestedByInfo.value && person.hasMpo">
                                                        {{ person.requestedByInfo.value}}
                                                </span>
                                                <span
                                                    *ngIf="(!person.requestedByInfo ||
                                                        !person.requestedByInfo.value || person.canTakeMpo) && !person.hasRequest"
                                                >
                                                        {{ notSpecified() }}
                                                </span>
                                                <span *ngIf="person.hasRequest && !person.hasMpo">
                                                    {{ person.mpoRequestRequestedBy || notSpecified() }}
                                                </span>
                                        </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.mpo">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.sendingDate' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span *ngIf="person.hasMpo">
                                                <span
                                                    *ngIf="person.mpoCompletionDate"> {{ person.mpoCompletionDateToDisplay }}</span>
                                                <span
                                                    *ngIf="!person.mpoCompletionDate"> {{ person.creationDateForDisplay }}</span>
                                            </span>
                                            <span *ngIf="person.hasNoMpo" class="na">
                                                {{ notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.mpo">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                                            <span *ngIf="person.hasMpo">
                                                <span *ngIf="person.mpoCompletionDate">
                                                    {{ person.mpoCompletionDateToDisplay }}
                                                </span>
                                            </span>
                                            <span *ngIf="person.hasNoMpo || !person.mpoCompletionDate" class="na">
                                                {{ notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table class="infoTable"
                                       *ngIf="stateService.hasPrbTestAccess && !person.hasARequestSent('mpo', '1,1')"
                                >
                                    <tr>
                                        <th style="width:30px;">
                                            <div class="subSectionToggleButton" (click)="toggleInfo('prb')">
                                                <span *ngIf="!infoTestsVisibility.prb">+</span>
                                                <span *ngIf="infoTestsVisibility.prb">-</span>
                                            </div>
                                        </th>
                                        <th class="withLink">
                                            <div class="pull-left qTitle">
                                                <div style="position: relative">
                                                <span
                                                    style="margin: 0; padding: 0">{{questionnaireBlockTitles.second.title}}</span>
                                                    <div
                                                        *ngIf="person.hasAPrbQuestionnaireSent ||
                                                     (
                                                        questionnaireBlockTitles.first.type === '1,1' &&
                                                        person.hasAnMpoQuestionnaireSent
                                                     )"
                                                        class="notCompletedMark">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- '0,1' case -->
                                            <ng-container *ngIf="!actualModule.archive">
                                                <div class="pull-right" *ngIf="person.hasAPrbQuestionnaireSent">
                                                    <ng-container *ngIf="!user.isSender">
                                                        <span
                                                            *ngFor="let request of person.evaluationRequestsContainingOnly('prb')"
                                                            class="pull-right">
                                                            <a (click)="testAndReportHandling.deletePrbRequest(request.requestId)">
                                                                {{ 'commons.delete' | translate }}
                                                            </a>
                                                        </span>
                                                    </ng-container>
                                                    <span
                                                        *ngFor="let request of person.evaluationRequestsContainingOnly('prb'); let i = index"
                                                        class="pull-right">
                                                        <a (click)="openModal('resendPrb', i, request['createTempUser'])">
                                                            {{ 'commons.resend' | translate }}
                                                            <span
                                                                *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                                        </a>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="pull-right"
                                                     *ngIf="person.canTakeExtraPrb || person.hasPrbOnHold">
                                                    <span class="pull-right" *ngIf="person.canTakeExtraPrb">
                                                        <a (click)="openModal('sendAdditionalPrb')">
                                                            {{ 'commons.send' | translate}}
                                                        </a>
                                                    </span>
                                                    <span class="pull-right" *ngIf="person.hasPrbOnHold">
                                                        <a (click)="this.peopleModalHandlingService.openReportsActivationModal(
                                                            this.testAndReportHandling.activateMpoReportsHandling()
                                                        )">
                                                            {{'people.sidebar.activate' | translate }}
                                                        </a>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </ng-container>
                                            <div class="clearfix"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'people.commons.status' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            {{ testAndReportHandling.extraPrbStatus }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.prb">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.requestedBy' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span
                                                [class.na]="!person.requestedByInfo || !person.requestedByInfo.value || person.lastPrbReport">
                                                <span
                                                    *ngIf="person.requestedByInfo && person.requestedByInfo.value && person.lastPrbReport"> {{ person.requestedByInfo.value }}</span>
                                                <span
                                                    *ngIf="!person.requestedByInfo || !person.requestedByInfo.value || !person.lastPrbReport"> {{ notSpecified() }}</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.prb && testAndReportHandling.extraPrbStatus !== noneVal()">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.sendingDate' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span *ngIf="person.lastPrbReport">
                                                {{ person.lastPrbCompletionDateToDisplay }}
                                            </span>
                                            <span *ngIf="!person.lastPrbReport" class="na">
                                                {{ notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.prb && testAndReportHandling.extraPrbStatus !== noneVal() && testAndReportHandling.extraPrbStatus !== notCompletedVal()">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                                            <span *ngIf="person.lastPrbCompletionDateToDisplay">
                                                {{ person.lastPrbCompletionDateToDisplay }}
                                            </span>
                                            <span *ngIf="!person.lastPrbCompletionDateToDisplay" class="na">
                                                {{ notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                                <table class="infoTable raQuestionnaire" *ngIf="stateService.hasIac && showIacQuestionnaire">
                                    <tr>
                                        <th style="width:30px;">
                                            <div class="subSectionToggleButton" (click)="toggleInfo('iac')">
                                                <span *ngIf="!infoTestsVisibility.iac">+</span>
                                                <span *ngIf="infoTestsVisibility.iac">-</span>
                                            </div>
                                        </th>
                                        <th class="withLink">
                                            <div class="pull-left qTitle">
                                                <div style="position: relative">
                                                <span
                                                    style="margin: 0; padding: 0">{{questionnaireBlockTitles.third.title}}</span>
                                                    <div *ngIf="person.hasIacRequest"
                                                         class="notCompletedMark">
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="!actualModule.archive">
                                                <div class="pull-right" *ngIf="person.hasIacRequest">
                                                    <ng-container *ngIf="!user.isSender">
                                                        <span
                                                            *ngFor="let request of person.evaluationRequestsContainingOnly('iac')"
                                                            class="pull-right">
                                                            <a (click)="testAndReportHandling.deleteIacRequest(request.requestId)">
                                                                {{ 'commons.delete' | translate }}
                                                            </a>
                                                        </span>
                                                    </ng-container>
                                                    <span
                                                        *ngFor="let request of person.evaluationRequestsContainingOnly('iac'); let i = index"
                                                        class="pull-right">
                                                        <a (click)="openModal('resendIac', i, request['createTempUser'])">
                                                            {{ 'commons.resend' | translate }}
                                                            <span
                                                                *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                                        </a>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </div>
                                                <div class="pull-right"
                                                     *ngIf="(person.canTakeIac || person.hasIacOnHold) && !(person.hasAnMpoQuestionnaireSent || person.hasAPrbQuestionnaireSent)">
                                                    <span class="pull-right" *ngIf="person.canTakeIac">
                                                        <a
                                                            (click)="openModal('sendIac')"
                                                        >
                                                            {{ 'commons.send' | translate}}
                                                        </a>
                                                    </span>
                                                    <span class="pull-right" *ngIf="person.hasIacOnHold">
                                                        <a (click)="peopleModalHandlingService.openReportsActivationModal(
                                                            testAndReportHandling.activateIacReportHandling())
                                                        ">
                                                            {{'people.sidebar.activate' | translate }}
                                                        </a>
                                                    </span>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </ng-container>
                                            <div class="clearfix"></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'people.commons.status' | translate }}:</strong>
                                            {{ testAndReportHandling.produceStatus('iac') }}
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.iac">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.requestedBy' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span
                                                [class.na]="(!person.hasRequest && !person.iacReportRequestedBy) ||
                                                            (person.hasRequest && !person.iacRequestRequestedBy)"
                                            >
                                                <span *ngIf="!person.hasRequest">
                                                    {{ person.iacReportRequestedBy || notSpecified() }}
                                                </span>
                                                <span *ngIf="person.hasRequest">
                                                    {{ person.iacRequestRequestedBy || notSpecified()}}
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.iac">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.sendingDate' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                            <span *ngIf="person.iacDateToDisplay"
                                            >
                                            {{ person.iacDateToDisplay }}
                                        </span>
                                            <span *ngIf="!person.iacDateToDisplay &&
                                                (person.hasAnIacReport || person.hasIacRequest)"
                                            >
                                            {{ !person.iacDateToDisplay }}
                                        </span>
                                            <span *ngIf="!person.hasAnIacReport && !person.hasIacRequest" class="na"
                                            >
                                            {{ notSpecified() }}
                                        </span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="infoTestsVisibility.iac">
                                        <td>&nbsp;</td>
                                        <td>
                                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                                            <span *ngIf="person.iacCompletionDateToDisplay">
                                                {{ person.iacCompletionDateToDisplay }}
                                            </span>
                                            <span *ngIf="!person.iacCompletionDateToDisplay"
                                                  class="na">
                                                {{ notSpecified() }}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </td>
            </tr>
        </table>
        <div>
            <hr class="panelHr"/>
        </div>
    </div>
    <!-- ici ------------------>
    <div *ngIf="!stateService.session.hasSatelliteRole" id="sideBarTestOptions" [class.sideBarTestOptionsListBorder]="config === 'list'">
        <div *ngIf="!informationVisibility && config!='report'" style="height: 10px"></div>
        <div *ngIf="!user.isSender && config!='report'">
            <div
                class="testOption pull-left"
                [class.testUnActivated]="!person.hasPersonality && !actualModule.archive"
                [class.testUnActivatedArchive]="!person.hasPersonality && actualModule.archive"
                [class.testOptionSelected]="statePeople.reportType === 'personality'"
                (click)="switchReportType('personality')"
            >
                {{ 'commons.personality' | translate }}
            </div>
            <div
                class="testOption pull-left"
                [class.testUnActivated]="!person.hasSatellite && !actualModule.archive"
                [class.testUnActivatedArchive]="!person.hasSatellite && actualModule.archive"
                [class.testOptionSelected]="statePeople.reportType === 'satellite'"
                (click)="switchReportType('satellite')"
            >
                Satellite
            </div>
            <div
                class="testOption pull-left"
                [class.testUnActivated]="!person.hasCommunication && !actualModule.archive"
                [class.testUnActivatedArchive]="!person.hasCommunication && actualModule.archive"
                [class.testOptionSelected]="statePeople.reportType === 'communication'"
                (click)="switchReportType('communication')"
            >
                {{ 'commons.dit' | translate }}
            </div>
            <div
                class="testOption pull-left"
                [class.testUnActivated]="!person.hasTalents && !actualModule.archive"
                [class.testUnActivatedArchive]="!person.hasTalents && actualModule.archive"
                [class.testOptionSelected]="statePeople.reportType === 'talents'"
                (click)="switchReportType('talents')"
            >
                Talent
            </div>
            <div
                class="testOption pull-left"
                *ngIf="stateService.hasIac"
                [class.testUnActivated]="!(person.hasAnIacReport &&
                            person.iacReport &&
                            person.iacReport.info.status) &&
                            !actualModule.archive"
                [class.testUnActivatedArchive]="!(person.iacReport &&
                            person.iacReport.info &&
                            person.iacReport.info.status) &&
                            actualModule.archive"
                [class.testOptionSelected]="statePeople.reportType === 'iac'"
                (click)="switchReportType('iac')"
            >
                {{ 'commons.cai' | translate }}
            </div>
            <div
                id="informationAltTitle"
                class="pull-right"
            >
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div *ngIf="person.hasMpoOnHold"
         style="font-size: 10pt; margin-bottom: 10px"
         class="fakeLink"
         (click)="peopleModalHandlingService.openReportActivationModal(this.testAndReportHandling.activateMpoReportsHandling())"
    >
        {{'people.sidebar.activateReports' | translate }}
    </div>

    <div *ngIf="statePeople.reportType === 'personality'" class="graph-container">
        <div *ngIf="user.isSender">
            <button mat-mini-fab
                    *ngIf="testStatusesMpoPersonality"
                    color="{{testStatusesMpoPersonality.color}}"
            >{{testStatusesMpoPersonality.abr}}</button>
        </div>
        <div id="MPOSideBarPresentation" [class.wrapGraphs]="config === 'report'" *ngIf="!user.isSender">
            <h2>{{ 'commons.typicalProfile' | translate }}</h2>
            <h2 class="ngorange" id="sideBarPortrait">{{
                testAndReportHandlingService.renderPortrait(person.portrait) | translate }}
            </h2>
            <!--            <div *ngIf="testStatusesMpoPersonality && person.hasPersonalityOnHold">-->
            <!--                <p>-->
            <!--                    <a-->
            <!--                        (click)="peopleModalHandlingService.openReportActivationModal({ type: 'personality' })"-->
            <!--                    >Activer MPO</a>-->
            <!--                </p>-->
            <!--            </div>-->
            <div class="pull-left"><em>{{ 'commons.personalityTraits' | translate }}</em></div>
            <div class="pull-right" *ngIf="person.hasPersonality">{{ person.personalityDateToDisplay }}</div>
            <div class="clearfix"></div>
            <app-mpo-graph
                [id]="'traits'"
                [width]="width"
                [mobile]="false"
                [scores]="person.personalityScores"
                [lang]="translateService.currentLang"
                [requestArr]="person.requests"
                [request]="person.checkRequestsContainsMpoTraits()"
                [report]="config"
            ></app-mpo-graph>

            <!-- PRB -->
            <div *ngIf="prbToDisplay">
                <div class="pull-left">
                    <em>{{ 'commons.perceptionOfRequiredBehavior' | translate }}</em>
                </div>

                <div *ngIf="person.hasPersonality">
                    <div *ngIf="prbToDisplay.exists" class="pull-right">
                        <div
                            *ngIf="prbToDisplay['hasNext']"
                            class="pull-left"
                            (click)="setPrbIndex(prbToDisplay['nextIndex'])"
                        >
                            <img src="{{buttonsFolder}}before.png" alt="<">
                        </div>
                        <div *ngIf="!prbToDisplay['hasNext']" class="pull-left">
                            <img alt="" src="{{buttonsFolder}}before_deactivated.png">
                        </div>
                        <div class="pull-left" style="margin: 1px 10px auto 10px">
                            {{ prbToDisplay.date | date:translationItem('date') }}
                        </div>
                        <div *ngIf="prbToDisplay['hasPrev']" class="pull-left"
                             (click)="setPrbIndex(prbToDisplay['prevIndex'])"><img
                            src="{{buttonsFolder}}next.png" alt=">"></div>
                        <div *ngIf="!prbToDisplay['hasPrev']" class="pull-left">
                            <img alt="" src="{{buttonsFolder}}next_deactivated.png">
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <app-mpo-graph
                    [id]="'prb'"
                    [width]="width"
                    [lang]="translateService.currentLang"
                    [mobile]="false"
                    [scores]="prbToDisplay['scores']"
                    [comparisonScores]="person.personalityScores"
                    [request]="person.hasARequestSent('prb') || prbToDisplay['scores'] === 'request'"
                    [requestArr]="person.requests"
                    [report]="config"
                ></app-mpo-graph>
            </div>
        </div>
    </div>

    <div *ngIf="!user.isSender && statePeople.reportType === 'communication' && !(statePeople.reportSubType === 'prb')"
         class="graph-container">
        <div *ngIf="person.hasCommunicationOnHold && !person.hasPersonalityOnHold">
            <p><a
                (click)="peopleModalHandlingService.openReportActivationModal({ type: 'communication' })">
                {{ 'commons.activate' | translate }} {{ 'commons.dit' | translate }}
            </a>
            </p>
        </div>
        <div *ngIf="config!='list'" class="pull-left">
            <div
                class="testOption pull-left"
                [class.testOptionSelected]="true"
            >
                {{ 'commons.communicationIndividual' | translate }}
            </div>
            <div *ngIf="currentPrb"
                 class="testOption pull-left"
                 [class.testOptionSelected]="false"
                 (click)="switchReportType('communication', 'prb')"
            >
                {{ 'commons.communicationPerception' | translate }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div *ngIf="person.hasCommunication" class="pull-right">{{ person.communicationDateToDisplay }}
        </div>
        <div class="clearfix"></div>
        <app-idw-graph
            [quad]="person.communicationScore"
            [ad]="person.communicationAd"
            [width]="width"
            [graphType]="person.hasCommunication ? 'active' : 'inactive'"
            [lang]="translateService.currentLang"
            [request]="person.checkRequestsContains('isSurvey', 'mpo')"
            [requestArr]="person.requests"
        ></app-idw-graph>
    </div>

    <div *ngIf="!user.isSender && statePeople.reportType === 'communication' && statePeople.reportSubType === 'prb'"
         class="graph-container">
        <div *ngIf="!person.hasCommunication && !person.hasPersonalityOnHold">
            <p><a (click)="peopleModalHandlingService.openReportActivationModal({ type: 'communication' })">
                {{ 'commons.activate' | translate }} {{ 'commons.dit' | translate }}
            </a>
            </p>
        </div>
        <div *ngIf="config!='list'" class="pull-left">
            <div
                class="testOption pull-left"
                [class.testOptionSelected]="false"
                (click)="switchReportType('communication', 'traits')"
            >
                {{ 'commons.communicationIndividual' | translate }}
            </div>
            <div
                class="testOption pull-left"
                [class.testOptionSelected]="true"
            >
                {{ 'commons.communicationPerception' | translate }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div *ngIf="prbToDisplay && prbToDisplay.exists" class="pull-right">
            <div *ngIf="prbToDisplay['hasPrev']" class="pull-left" (click)="setPrbIndex(prbToDisplay['prevIndex'])"><img
                alt="<"
                src="{{buttonsFolder}}before.png">
            </div>
            <div *ngIf="!prbToDisplay['hasPrev']" class="pull-left"><img alt="<"
                                                                         src="{{buttonsFolder}}before_deactivated.png">
            </div>
            <div class="pull-left" style="margin: 1px 10px auto 10px">{{ prbToDisplay.date |
                date:translationItem('date') }}
            </div>
            <div *ngIf="prbToDisplay['hasNext']" class="pull-left" (click)="setPrbIndex(prbToDisplay['nextIndex'])"><img
                alt=">"
                src="{{buttonsFolder}}next.png">
            </div>
            <div *ngIf="!prbToDisplay['hasNext']" class="pull-left"><img alt=">"
                                                                         src="{{buttonsFolder}}next_deactivated.png">
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
        <app-idw-graph
            [quad]="communicationPrbScore"
            [ad]="communicationPrbAd"
            [width]="width"
            [graphType]="(person.hasCommunication && person.hasPrb) ? 'active' : 'inactive'"
            [lang]="translateService.currentLang"
            [request]="person.checkRequestsContains('isSurvey', 'mpo')"
            [requestArr]="person.requests"
        ></app-idw-graph>
    </div>

    <div *ngIf="!user.isSender && statePeople.reportType === 'talents'" class="graph-container">
        <h2>{{ 'commons.aptitudesSphere' | translate }}
            <span *ngIf="person.hasTalents || person.hasTalentsOnHold"
                  class="pull-right date-display"
            >{{ person.talentsDateToDisplay }}</span>
            <span class="clearfix"></span>
        </h2>
        <div style="width: 100%">
            <div style="width: 95%; margin: auto">
                <app-competences-graph
                    [id]="'talents'"
                    [scores]="person.talentsScore"
                    [width]="width"
                    [lang]="translateService.currentLang"
                    [type]="'global'"
                    [request]="person.checkRequestsContains('isSurvey', 'mpo')"
                    [requestArr]="person.requests"
                ></app-competences-graph>
            </div>
        </div>
    </div>

    <div *ngIf="statePeople.reportType === 'iac' && stateService.hasIac" class="graph-container">
        <div *ngIf="user.isSender">
            <button mat-mini-fab
                    *ngIf="testStatusesIac"
                    color="{{testStatusesIac}}"
            >{{testStatusesIac.abr}}</button>
        </div>
        <div *ngIf="!user.isSender">
            <h2><span class="bold">{{ 'people.reportIac.resultTitle' | translate }}</span>
                <span *ngIf="person.hasIac"
                      class="pull-right date-display"
                >
                    {{ person.iacDateToDisplay }}
                </span>
                <span class="clearfix"></span>
            </h2>
            <app-iac-sidebar-graph
                [jobId]="person.jobId"
                [bruteScore]="person.iacInfoBruteScore"
                [answeredQuestions]="person.iacInfoAnsweredQuestions"
                [time]="person.iacTimeForGraph"
                [normalizedScore]="person.iacNormalizedScore"
            ></app-iac-sidebar-graph>
        </div>
    </div>

    <div *ngIf="statePeople.reportType === 'satellite'">
        <div *ngIf="person.hasSatelliteOnHold && !person.hasPersonalityOnHold">
            <p>
                <a (click)="peopleModalHandlingService.openReportActivationModal({ type: 'satellite' })">
                    {{ 'commons.activate' | translate }} MPO Satellite
                </a>
            </p>
        </div>
        <div>
                <span
                    *ngIf="satelliteJobs && satelliteJobs.length>0">{{ 'people.sidebar.associatedJobProfileSatellite' | translate }}
                    <br></span>
            <div>
                <ng-container *ngIf="satelliteJobs && satelliteJobs.length>0">
                    <div class="pull-left">
                        <label>
                            <select
                                *ngIf="(satelliteJobs && person.satelliteReport)"
                                (change)="person.satelliteJobChange($event)"
                                style="margin-top: 8px"
                            >
                                <option value="none">{{ 'commons.select' | translate }}</option>
                                <option *ngFor="let job of satelliteJobs"
                                        [value]="job.id"
                                        [selected]="person.satelliteJobId &&
                                                job.id === person.satelliteJobId"
                                >
                                    {{ job['jobTitle'] }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div *ngIf="person.satelliteReport"
                         class="pull-right date-display"
                    >
                        {{ person.satelliteDateToDisplay }}
                    </div>
                    <div class="clearfix"></div>
                </ng-container>
                <ng-container *ngIf="satelliteJobs && satelliteJobs.length === 0 || !satelliteJobs">
                    {{ 'commons.noAssignedJob' | translate }}<br/>
                    {{ 'commons.contactAdmin' | translate }}
                </ng-container>
                <div *ngIf="person.loading" class="pull-left">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="(person.loading) ? 'loading' : null"
                    ></app-local-loader>
                </div>
                <div class="clearfix"></div>
            </div>
            <!--[src]="'assets/satellite/M' + personToDisplay.evaluations.satellite.info.satelliteScore + '.png'"-->
            <h2 style="margin-top: 15px"></h2>
            <div class="graph-container">
                <div
                    class="col-md-5"
                >
                    <div style="margin: auto">
                        <app-satellite-score-graph
                            [score]="person.satelliteScore"
                            [satelliteInfo]="person.satelliteInfo"
                            [request]="person.checkRequestsContains('isSurvey', 'mpo')"
                            [requestArr]="person.requests"
                        ></app-satellite-score-graph>
                    </div>
                    <div
                        *ngIf="person.hasSatelliteJobProfile && (satelliteJobs && person.satelliteReport)"
                        style="text-align: center"
                    >
                        {{ testAndReportHandling.satelliteText(
                        person.satelliteScore,
                        translateService.currentLang
                    ).level
                        }}
                    </div>
                    <div *ngIf="!person.satelliteScore"></div>
                    <div
                        *ngIf="!(satelliteJobs && person.satelliteReport)"
                        style="text-align: center"
                    >
                        &nbsp;
                    </div>
                </div>
                <div
                    *ngIf="person.hasSatelliteJobProfile && (satelliteJobs && person.satelliteReport)"
                    class="report-text col-md-7"
                >
                    {{ testAndReportHandling.satelliteText(
                    person.satelliteScore,
                    translateService.currentLang
                ).text
                    }}

                </div>
                <div *ngIf="!person.satelliteScore"></div>
                <div
                    *ngIf="!(satelliteJobs && person.hasSatellite)"
                    class="report-text col-md-7"
                >
                    &nbsp;
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!user.isSender && config==='report'">
        <hr class="panelHr"/>
    </div>
    <!-- all reports -->
    <div *ngIf="!user.isSender && !stateService.session.hasSatelliteRole && config=='report'"
         [class.navbar-fixed-bottom]="!isLeftAndNotTop"
         [class.bottomMenu]="!isLeftAndNotTop"
         [style.margin-top.20px]="isLeftAndNotTop"
         style="padding-top: 10px"
    >
        <div class="puckDisplay"
             [class.five]="stateService.hasIac"
             [tooltip]="'commons.personality' | translate"
             (click)="switchReportType('personality')"
             style="cursor: pointer; margin: auto; padding: 0"
        >
            <div *ngIf="person.personalityScores; else buyMPO" class="cardEvaluation">
                <app-mini-mpo-graph
                    [score]="person.personalityScores"
                    [interaction]="'sidebar'"
                    [noRemove]="true"
                ></app-mini-mpo-graph>
            </div>
            <ng-template #buyMPO>
                <div class="cardEvaluation">
                    <app-people-card-no-report
                        [id]="person.id"
                        [type]="'activate.4'"
                        [which]="'personality'"
                        [hasMpoReport]="person.hasPersonality"
                        [anotherTitle]="personalityIconTitle"
                    ></app-people-card-no-report>

                </div>
            </ng-template>
        </div>

        <div class="puckDisplay"
             [class.five]="stateService.hasIac"
             [tooltip]="'Satellite'"
             (click)="switchReportType('satellite')"
             style="cursor: pointer; margin: auto; padding: 0"
        >
            <div *ngIf="(person.satelliteScore && person.satelliteScore !== 'noJobProfile'); else buySatellite"
                 class="cardEvaluation">
                <app-mini-satellite-graph
                    [score]="person.satelliteScore"
                    [id]="person.id"
                    [from]="'report'"
                ></app-mini-satellite-graph>
            </div>
            <ng-template #buySatellite>
                <div class="cardEvaluation">
                    <app-people-card-no-report
                        [id]="person.id"
                        [type]="'activate.1'"
                        [which]="'satellite'"
                        [hasMpoReport]="person.hasMpo"
                        [anotherTitle]="satelliteIconTitle"
                    ></app-people-card-no-report>
                </div>
            </ng-template>
        </div>

        <div class="puckDisplay"
             [class.five]="stateService.hasIac"
             [tooltip]="'Communication'"
             (click)="switchReportType('communication')"
             style="cursor: pointer; margin: auto; padding: 0"
        >
            <div *ngIf="(person.communicationScore  && person.communicationScore !== 'INV'); else buyIDW"
                 class="cardEvaluation">

                <app-mini-idw-graph
                    [quad]="person.communicationScore"
                    [type]="'sidebar'"
                ></app-mini-idw-graph>
            </div>
            <ng-template #buyIDW>
                <div class="cardEvaluation">
                    <app-people-card-no-report
                        [id]="person.id"
                        [type]="'activate.2'"
                        [which]="'communication'"
                        [hasMpoReport]="person.hasMpo"
                        [anotherTitle]="communicationIconTitle"
                    ></app-people-card-no-report>
                </div>
            </ng-template>
        </div>
        <div class="puckDisplay"
             [class.five]="stateService.hasIac"
             [tooltip]="'Talent'"
             (click)="switchReportType('talents')"
             style="cursor: pointer; margin: auto; padding: 0"
        >
            <div *ngIf="person.talentsScore; else buyTalents" class="cardEvaluation">
                <app-mini-competences-graph
                    [id]="'talents_mini'"
                    [scores]="person.talentsScore"
                    [type]="'sidebar'"
                ></app-mini-competences-graph>
            </div>
            <ng-template #buyTalents>
                <div class="cardEvaluation">
                    <div class="cardEvaluation">
                        <app-people-card-no-report
                            [id]="person.id"
                            [type]="'activate.2'"
                            [which]="'talents'"
                            [hasMpoReport]="person.hasMpo"
                            [anotherTitle]="talentsIconTitle"
                        ></app-people-card-no-report>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="puckDisplay"
             [class.five]="stateService.hasIac"
             *ngIf="stateService.hasIac"
             [tooltip]="'commons.cai' | translate"
             (click)="switchReportType('iac', null, 'puck')"
             style="cursor: pointer; margin: auto; padding: 0"
        >
            <div *ngIf="person.iacScores; else buyIac" class="cardEvaluation">
                <app-mini-iac-graph
                    [id]="'iac_mini'"
                    [score]="person.iacScores['normalized']"
                ></app-mini-iac-graph>
            </div>
            <ng-template #buyIac>
                <div class="cardEvaluation">
                    <div class="cardEvaluation">
                        <app-people-card-no-report
                            [id]="person.id"
                            [type]="'activate.2'"
                            [which]="'iac'"
                            [anotherTitle]="iacIconTitle"
                        ></app-people-card-no-report>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
