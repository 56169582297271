<div
        class="top tRight affix"
        [style.width]="width"
        (window:resize)="onResize($event)"
>
    <div class="pull-left">
        <h2>{{ 'commons.selection' | translate }}</h2>
    </div>
    <div (click)="goToNews()" class="x-close pull-right" style="font-size: 20pt;">
        &#x00D7;
    </div>
    <div class="clearfix"></div>
    <div style="height: 25px"></div>
    <hr>
    <h3>{{ 'commons.options' | translate }}</h3>
    <select [(ngModel)]="action" class="form-control pull-left" style="width: 85%" [disabled]="names.length==0">
        <option>{{ 'commons.select' | translate }}</option>
        <option *ngIf="!actualModule.archive" value="archive">{{ 'commons.archive' | translate }}</option>
        <option *ngIf="actualModule.archive" value="reactivate">{{ 'commons.reactivate' | translate }}</option>
        <option value="delete">{{ 'commons.delete' | translate }}</option>
    </select>
    <button (click)="act()" class="modalButton pull-right" style="width: 13%; padding: 7px 0 6px 0">
        <span class="fontRegular" style="color: #EEE; font-size: 125%;">
            ⟩
        </span>
    </button>
    <div class="clearfix"></div>
    <hr>
    <h3>{{ 'rms.selectedItems.rmsSelected' | translate }}</h3>
    <table *ngIf="names" class="table" style="width: 100%; table-layout: fixed;">
        <ng-container *ngFor="let i of createRange(actualPage, maxPerPage)">
            <tr *ngIf="names[i]">
                <td class="listingCell">
                    {{ names[i][1] }}
                </td>
                <td class="listingCell">
                        <span
                                (click)="removeItem(names[i][0])"
                                style="font-weight: bold; cursor: pointer">
                                x
                        </span>
                </td>
            </tr>
            <tr *ngIf="!names[i]">
                <td class="listingCell">
                    &nbsp;
                </td>
                <td class="listingCell">
                    &nbsp;
                </td>
            </tr>
        </ng-container>
    </table>
    <div>
        <div class="pull-right">
            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)"><img alt=">"
                    src="/assets/buttons/next.png"></div>
            <div *ngIf="actualPage == maxPage || !maxPage" class="pull-right"><img alt=">" src="/assets/buttons/next_deactivated.png"></div>
            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img alt="<"
                    src="/assets/buttons/before.png"></div>
            <div *ngIf="actualPage == 1" class="pull-right"><img alt="<" src="/assets/buttons/before_deactivated.png"></div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
