<div class="mat-grid-list-wrapper" [style.min-height]="wHeight ? wHeight+'px' : '100vh'">
    <mat-grid-list cols="3" rowHeight="63px">
        <mat-grid-tile
            colspan="2"
            rowspan="1"
        >
            <div id="logoContainer">
                <img alt="Ngenio-Connect" src="../../../../../assets/login/logoLogin.png"/>
            </div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <div class="languageSwitch">
                <a *ngIf="translate.currentLang!='fr'" (click)="changeLang('fr')">Français</a>
                <a *ngIf="translate.currentLang!='en'" (click)="changeLang('en')">English</a>
            </div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="2"
            rowspan="1"
        >
            <h1>{{ 'login.connect' | translate }}</h1>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >

            <div *ngIf="versionNumber" class="versionNumber">
                {{ versionNumber }}
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <div *ngIf="errorLogin" class="errorMessage">
        <mat-toolbar color="primary">
            <mat-toolbar-row>
                <span>
                    {{ translate.currentLang === 'fr' ?
                    'Attention. Une erreur est survenue lors de la tentative de traitement de votre demande. ' +
                    'Svp, vérifiez vos identifiants.'
                    : 'An error has occurred trying to process your request. ' +
                    'Please check your credentials.'}}
                </span>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
    <mat-grid-list cols="1">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <app-form-table-mobile
                [formDefinition]="loginForm"
                (formOutput)="handleForm($event)"
                class="topFullWidth"
            ></app-form-table-mobile>
            <div *ngIf="baseUrl === 'https://staging.ngenioconnect.com/'" class="staging-mobile-login">
                <div>
                    Staging password
                </div>
                <div>
                    <input type="password" style='color:black' [(ngModel)]="passwordStaging" name="passwordStaging">
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
    <!-- div id="alert-login">
        <div
            id="alert-login-container"

        >
            <div
                id="alert-login-box"
            >
                <b>{{ 'login-alerts.warning' | translate }}</b> {{ 'login-alerts.first' | translate }}
                <ul>
                    <li>{{ 'login-alerts.li2' | translate }}</li>
                </ul>
                {{ 'login-alerts.last' | translate }}
            </div>
        </div>
    </div-->
</div>
