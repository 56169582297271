import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';

import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

import {JobsRoutingModule} from '../../jobs/jobs-routing.module';

import {PeopleRoutingModule} from '../../people/people-routing.module';

import {SharedModule} from '../../shared/shared.module';

import {LoadingModule} from 'ngx-loading';

import {TranslateModule} from '@ngx-translate/core';
import {SidebarModule} from 'ng-sidebar';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {PeopleModule} from '../../people/people.module';
import {GraphboardsModule} from '../graphboards/graphboards.module';
import {AnalysisSharedModule} from '../shared/analysis-shared.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

// Components ////////////////////////////////////////////////////////////

// Commons
import {
    JobSatisfactionsPersonCardComponent
} from './components/commonClasses/job-satisfactions-person-card/job-satisfactions-person-card.component';
import {
    JobSatisfactionsPersonSubCardCaiComponent
} from './components/commonClasses/job-satisfactions-person-card/sub-card/job-satisfactions-person-sub-card-cai/job-satisfactions-person-sub-card-cai.component';
import {
    JobSatisfactionsPersonSubCardTalentsComponent
} from './components/commonClasses/job-satisfactions-person-card/sub-card/job-satisfactions-person-sub-card-talents/job-satisfactions-person-sub-card-talents.component';
import {
    JobSatisfactionsPersonSubCardPersonalityComponent
} from './components/commonClasses/job-satisfactions-person-card/sub-card/job-satisfactions-person-sub-card-personality/job-satisfactions-person-sub-card-personality.component';
// Web
import {
    JobSatisfactionsModalComponent
} from './components/web/job-satisfactions-modal/job-satisfactions-modal.component';
import {
    JobSatisfactionsPersonModalComponent
} from './components/web/job-satisfactions-person-modal/job-satisfactions-person-modal.component';
import {
    JobSatisfactionsReportsFiltersModalComponent
} from './components/web/job-satisfactions-reports-filters-modal/job-satisfactions-reports-filters-modal.component';
// import { JobSatisfactionsPdfModalComponent } from './components/web/job-satisfactions-pdf-modal/job-satisfactions-pdf-modal.component';
import {JobSatisfactionsListComponent} from './components/web/job-satisfactions-list/job-satisfactions-list.component';
import {
    JobSatisfactionsReportComponent
} from './components/web/job-satisfactions-report/job-satisfactions-report.component';
import {
    JobSatisfactionsListSearchFiltersComponent
} from './components/web/job-satisfactions-list-search-filters/job-satisfactions-list-search-filters.component';
import {
    JobSatisfactionsSidebarComponent
} from './components/web/job-satisfactions-sidebar/job-satisfactions-sidebar.component';
import {
    JobSatisfactionsSelectedItemsComponent
} from './components/web/job-satisfactions-selected-items/job-satisfactions-selected-items.component';
import { JobSatisfactionGraphicsComponent } from './components/commonClasses/job-satisfaction-graphics/job-satisfaction-graphics.component';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';
import { JobSatisfactionAnalysisPopupComponent } from './components/commonClasses/job-satisfaction-analysis-popup/job-satisfaction-analysis-popup.component';
import {JobSatisfactionPcrReportService} from './services/job-satisfaction-pcr-report.service';
import { JobSatisfactionsPersonListDisplayComponent } from './components/commonClasses/job-satisfactions-person-list-display/job-satisfactions-person-list-display.component';
import { JobSatisfactionsPdfModalComponent } from './components/web/job-satisfactions-pdf-modal/job-satisfactions-pdf-modal.component';
import { MobileJobSatisfactionsListComponent } from './components/mobile/mobile-job-satisfactions-list/mobile-job-satisfactions-list.component';
import { MobileJobSatisfactionsEditComponent } from './components/mobile/mobile-job-satisfactions-edit/mobile-job-satisfactions-edit.component';
import { MobileJobSatisfactionsPdfComponent } from './components/mobile/mobile-job-satisfactions-pdf/mobile-job-satisfactions-pdf.component';
import { MobileJobSatisfactionsReportComponent } from './components/mobile/mobile-job-satisfactions-report/mobile-job-satisfactions-report.component';
import {
    MobilePeopleRequestSendResendComponent
} from '../../people/components/mobile/mobile-people-request-send-resend/mobile-people-request-send-resend.component';
import {JobSatisfactionsAnalysisService} from './services/job-satisfactions-analysis.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        ModalModule, MyDatePickerModule,
        NgMultiSelectDropDownModule,
        SharedModule,
        JobsRoutingModule,
        PeopleRoutingModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        LoadingModule,
        TranslateModule,
        SidebarModule.forRoot(),
        MatGridListModule,
        MatButtonModule,
        MatTabsModule,
        PeopleModule, MatExpansionModule, MatIconModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, GraphboardsModule, AnalysisSharedModule, MatSelectModule,
        TooltipModule
    ],
    declarations: [
        JobSatisfactionsModalComponent,
        JobSatisfactionsPersonModalComponent,
        JobSatisfactionsReportsFiltersModalComponent,
        JobSatisfactionsPdfModalComponent,
        JobSatisfactionsListComponent,
        JobSatisfactionsReportComponent,
        // MobileJobSatisfactionsListComponent,
        // MobileJobSatisfactionsReportComponent,
        // MobileJobSatisfactionsEditComponent,
        // MobileJobSatisfactionsPdfComponent,
        // MobileJobSatisfactionsPersonAddingComponent,
        JobSatisfactionsListSearchFiltersComponent,
        JobSatisfactionsSidebarComponent,
        JobSatisfactionsSelectedItemsComponent,
        JobSatisfactionsPersonCardComponent,
        JobSatisfactionsPersonSubCardCaiComponent,
        JobSatisfactionsPersonSubCardTalentsComponent,
        JobSatisfactionsPersonSubCardPersonalityComponent,
        JobSatisfactionGraphicsComponent,
        SafeHtmlPipe,
        JobSatisfactionAnalysisPopupComponent,
        JobSatisfactionsPersonListDisplayComponent,
        JobSatisfactionsPdfModalComponent,
        MobileJobSatisfactionsListComponent,
        MobileJobSatisfactionsEditComponent,
        MobileJobSatisfactionsPdfComponent,
        MobileJobSatisfactionsReportComponent
    ],
    exports: [
        JobSatisfactionAnalysisPopupComponent
    ],
    providers: [
        JobSatisfactionPcrReportService
    ],
    entryComponents: [
        JobSatisfactionsModalComponent,
        JobSatisfactionsPdfModalComponent,
        JobSatisfactionsPersonModalComponent,
        JobSatisfactionsReportsFiltersModalComponent
    ]
})
export class JobSatisfactionsModule {
}
