<div class="rightWrapper row" (window:resize)="onResize($event)">
    <ng-container *ngIf="!smallSize">
        <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4" [style.width]="(!smallSize) ? sideWidth : null">
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="graphBoard.id">
                    <app-graphboards-sidebar
                            [isLeftAndNotTop]="true"
                            [width]="sideWidth"
                            [config]="'report'"
                            [archivedRecords]="archivedRecords"
                    ></app-graphboards-sidebar>
                </div>
            </div>
        </div>
    </ng-container>
    <div
         class="outerColumnWhite"
         [class.col-md-8]="!smallSize"
         [class.col-md-12]="smallSize"
         [style.padding-top]="(smallSize) ? null : '40px'"
         [style.width]="(!smallSize) ? reportWidth : null"
    >
        <div *ngIf="smallSize">
            <app-graphboards-sidebar
                    [isLeftAndNotTop]="false"
                    [width]="sideWidth"
                    [config]="'report'"
                    [archivedRecords]="archivedRecords"
            ></app-graphboards-sidebar>
            <div *ngIf="smallSize" class="clearfix"></div>
        </div>
        <div *ngIf="smallSize && graphBoard.id && !graphBoard.isEmptyAlignment()">
            <div [class.pull-left]="!smallSize"
                 *ngFor="let line of graphBoard.alignment; let i = index"
                 [style.width]="reportWidthNumber/2.5 + 'px'"
                 style="margin-top: 170px;"
            >
                <div>
                    <h2 class="ngorange">
                        {{ 'commons.column' | translate }} {{ i + 1 }}
                    </h2>
                    <div *ngFor="let item of graphBoard.alignment[i]" [id]="idBuilding(item)">
                        <div class="graphs">
                            <div
                                    [class.trait]="item['analysisType']=='trait'"
                                    [class.prb]="item['analysisType']=='prb'"
                                    [class.profiler]="item['analysisType'] == 'profiler'"
                                    class="graphsPad"
                                    [class.graphExtraPad]="smallSize"

                            >
                                <div class="pull-left nameGb">
                                    <div class="truncate"
                                         *ngIf="item['analysisType']=='trait' || item['analysisType']=='prb'">
                                        <!--{{ truncateName(item, 'name') }}-->
                                        {{item['firstName'] + ' ' + item['lastName']}}
                                    </div>
                                    <div class="truncate" *ngIf="item['analysisType']=='profiler'">
                                        <!--{{ truncateName(item.jobTitle, 'profiler') }}-->
                                        {{item['jobTitle']}}
                                    </div>
                                </div>
                                <div class="pull-right">
                                    <div class="typeGb" *ngIf="item['analysisType'] == 'trait'">
                                        Traits
                                    </div>
                                    <div class="typeGb" *ngIf="item['analysisType'] == 'prb'">
                                        Perception
                                    </div>
                                    <div class="typeGb" *ngIf="item['analysisType'] == 'profiler'">
                                        Profil de poste
                                    </div>
                                </div>
                                <div class="clearfix"></div>
                                <app-mpo-graph
                                    [id]="analysisTypeForId(item) + '_' + item.id + '_' + item['analysisType']"
                                    [width]="graphWidth"
                                    [scores]="item['scores']"
                                    [scSize]="true"
                                    [graphboard]="true"
                                    [lang]="currentLang"
                                ></app-mpo-graph>
                                <div class="pull-left jobTitle truncate">
                                    <span *ngIf="(item['analysisType'] == 'trait' || item['analysisType'] == 'prb')">
                                        {{ item['jobTitle'] }} &nbsp;
                                    </span>
                                    <span *ngIf="!(item['analysisType'] == 'trait' || item['analysisType'] == 'prb')">
                                        &nbsp;
                                    </span>
                                </div>
                                <div class="pull-right">
                                    {{ itemsDate(item, currentLang) }}
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div  *ngIf="!smallSize && graphBoard.id && !graphBoard.isEmptyAlignment()">
            <div class='wrapper-dragula'
                 [class.ddborder]="!smallSize">
                <div class='pull-left container-dragula'
                     *ngFor="let line of graphBoard.alignment; let i = index"
                     [style.width]="100/graphboardSize + '%'"
                >
                    <div
                            [dragula]="'first-bag'"
                            [(dragulaModel)]="graphBoard.alignment[i]"
                            style="padding-bottom: 150px"
                    >
                        <div *ngFor="let item of graphBoard.alignment[i]" [id]="idBuilding(item)">
                            <div class="graphs" [style.font-size]="fontSize(graphboardSize)">
                                <div
                                        [class.trait]="item['analysisType']=='trait'"
                                        [class.prb]="item['analysisType']=='prb'"
                                        [class.profiler]="item['analysisType'] == 'profiler'"
                                        class="graphsPad"

                                >
                                    <div class="pull-left nameGb">
                                        <div class="truncate"
                                             *ngIf="item['analysisType']=='trait' || item['analysisType']=='prb'">
                                            <!--{{ truncateName(item, 'name') }}-->
                                            {{item['firstName'] + ' ' + item['lastName']}}
                                        </div>
                                        <div class="truncate" *ngIf="item['analysisType']=='profiler'">
                                            <!--{{ truncateName(item.jobTitle, 'profiler') }}-->
                                            {{item['jobTitle']}}
                                        </div>
                                    </div>
                                    <div class="pull-right">
                                        <div class="typeGb" *ngIf="item['analysisType'] == 'trait'">
                                            Traits
                                        </div>
                                        <div class="typeGb" *ngIf="item['analysisType'] == 'prb'">
                                            Perception
                                        </div>
                                        <div class="typeGb" *ngIf="item['analysisType'] == 'profiler'">
                                            Profil de poste
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <app-mpo-graph
                                        [id]="analysisTypeForId(item) + '_' + item.id + '_' + item['analysisType']"
                                        [width]="reportWidth"
                                        [scores]="item['scores']"
                                        [graphboard]="true"
                                        [lang]="currentLang"
                                    ></app-mpo-graph>
                                    <div class="pull-left jobTitle truncate">
                                    <span *ngIf="(item['analysisType'] == 'trait' || item['analysisType'] == 'prb')">
                                        {{ item['jobTitle'] }} &nbsp;
                                    </span>
                                        <span *ngIf="!(item['analysisType'] == 'trait' || item['analysisType'] == 'prb')">
                                        &nbsp;
                                    </span>
                                    </div>
                                    <div class="pull-right">
                                        {{ itemsDate(item, currentLang) }}
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"></div>
            </div>
            <!--div class="pull-right" id='button' (click)="act()">{{ 'commons.save' | translate }}</div-->
            <div class="clearfix"></div>
        </div>
        <div *ngIf="graphBoard.id && graphBoard.isEmptyAlignment()" style="margin-top: 19px">
            <span class="fakeLink"
                  (click)="graphBoardsModalHandlingService.openGraphBoardPersonModal(this.archivedRecords)">{{ 'analysis.commons.addPeopleAndJobs' | translate }}
            </span>
        </div>
        <div *ngIf="smallSize" style="height: 100px">&nbsp;</div>
    </div>
</div>
