<div (window:resize)="onResize()">
    <h2>{{ 'jobs.commons.jobInfoMpo' | translate }}</h2>

    <div *ngIf="jobService.jobReport">
        <ul class="plainList">
            <li *ngIf="jobService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ jobService.jobTitle }}</li>
            <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (jobService.competencesModificationDate || jobService.creationDate || jobService.date) | date:reportDateFormat}}</li>
        </ul>

        <hr/>
        <div *ngIf="smallSize">
            <div class="pull-left"><em>{{ 'commons.personalityTraitsJob' | translate }}</em></div>
            <div class="pull-right">
                <span *ngIf="mpoScores" class="fakeLink modLink" (click)="modQuickProfiler()">{{ 'jobs.sidebar.modProfiler' | translate }}</span>
            </div>
            <div class="clearfix"></div>
            <div style="width: auto">
                <app-mpo-graph
                    [id]="'traits'"
                    [mobile]="mobile"
                    [scores]="mpoScores"
                    [lang]="currentLang"
                ></app-mpo-graph>
            </div>
        </div>

        <div *ngIf="hasWarning" class="warning">
            <p *ngIf="jobService.jobReport.warning['equalAS']">
                {{ jobService.jobReport.warning['equalAS'] }}
            </p>
            <div *ngIf="jobService.jobReport.warning['equalAS'] && jobService.jobReport.warning.text">
                &nbsp;
            </div>
            <p *ngIf="jobService.jobReport.warning.text">
                {{ jobService.jobReport.warning.text }}
            </p>
        </div>

        <div *ngIf="profile">
            <h3 style="margin-top: 15px">{{ 'jobs.reportMpo.weSearchForSOWho' | translate }}<span [innerHTML]="titleSep"></span></h3>
            <hr/>
            <div *ngIf="profile && profile.par" id="portrait">
                <p>{{ profile.OR }}</p>
                <p>{{ profile.SE }}</p>
                <p *ngFor="let paragraph of profile.par">
                    {{ paragraph }}
                </p>
                <h3>{{ 'jobs.reportMpo.commAndLeadStyle' | translate }}<span [innerHTML]="titleSep"></span></h3>
                <h4>{{ 'commons.leadStyle' | translate }}</h4>
                <ul>
                    <li *ngFor="let item of profile.lead">
                        {{ item }}
                    </li>
                </ul>

                <h4>{{ 'commons.commStyle' | translate }}</h4>

                <ul *ngIf="profile.comm">
                    <li *ngFor="let item of profile.comm">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.coms">
                    <li *ngFor="let item of profile.coms">
                        {{ item }}
                    </li>
                </ul>

                <h3>{{ 'jobs.reportMpo.motvSupport' | translate }}</h3>

                <h4>{{ 'commons.workEnvironment' | translate }}</h4>
                <ul *ngIf="profile.menv">
                    <li *ngFor="let item of profile.menv">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.wenv">
                    <li *ngFor="let item of profile.wenv">
                        {{ item }}
                    </li>
                </ul>

                <h4>{{ 'commons.motivationalNeeds' | translate }}</h4>
                <ul *ngIf="profile.mper">
                    <li *ngFor="let item of profile.mper">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.motv">
                    <li *ngFor="let item of profile.motv">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
