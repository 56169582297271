<div *ngIf="report && person.talentsScore" (window:resize)="onResize($event)" #talentsReportWrap>
    <h2>{{ 'people.reportTalents.infoOnTalents' | translate }}</h2>
    <ul class="plainList">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ person.talentsDateToDisplay }}</li>
        <li *ngIf="person.talentsRequestedByInfo && person.talentsRequestedByInfo.fullName">
            {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> {{ person.talentsRequestedByInfo.fullName }}
        </li>
        <li *ngIf="person.talentsTimeSpent && person.talentsTimeSpent.size == 3">
            {{ 'people.commons.elapsedTime' | translate }}<span [innerHTML]="titleSep"></span>
            S1<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[0] }} -
            S2<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[1] }} -
            Total<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[2] }}
        </li>
        <li *ngIf="person.talentsTimeSpent && person.talentsTimeSpent.size == 5">
            {{ 'people.commons.time' | translate }}<span [innerHTML]="titleSep"></span>
            Total<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[4] }} -
            Section 1<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[0] }} -
            Section 2<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[1] }} -
            Section 3<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[2] }} -
            Section 4<span [innerHTML]="titleSep"></span> {{ person.talentsTimeSpent.data[3] }}
        </li>
        <li>{{ 'people.commons.norm' | translate }}<span [innerHTML]="titleSep"></span> {{ person.talentsNorm }}</li>
        <li *ngIf="person.talentsMethod">
            {{ 'people.commons.method' | translate }}<span [innerHTML]="titleSep"></span>
            <span *ngIf="person.talentsMethod == 0"> {{ 'people.commons.paperQuestionnaire' | translate }}</span>
            <span *ngIf="person.talentsMethod == 1"> {{ 'people.commons.onlineQuestionnaire' | translate }}</span>
        </li>
    </ul>
    <hr/>
    <!-- Rapport général -->
    <div style="height: 20px">&nbsp;</div>
    <div class="pull-left">
        <a id="talentsWithAptitudes"></a>
        <h2>{{ 'people.reportTalents.descriptionViaAptitude' | translate }}</h2>
    </div>
    <div class="pull-right">
        <div class="dropdown">
            <div class="dropbtn dropbtnSmall">{{ 'commons.goto' | translate }} <span class="caret"></span></div>
            <div class="dropdown-content surfInPage dropbtnSmall">
                <div (click)="moveTo('aptitudes')">
                    {{ 'people.reportTalents.sixAptitudeSpheres' | translate }}
                </div>
                <div (click)="moveTo('orderedTalents')">{{'people.reportTalents.talentsOrdered' | translate }}</div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('jobVsPerson')">{{'people.reportTalents.jobVsPerson' | translate }}
                </div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('interviewQuestions')"
                >
                    {{'people.reportTalents.interviewQuestions' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="instructions" style="margin-bottom: 20px">
        <hr>
        <div *ngIf="translate.currentLang=='fr'">
            <p>
                Liste des 46 Talents comportementaux par ordre alphabétique avec la définition de la compétence et le
                degré de talent à développer cette compétence.


            </p>
            <p>Degré de prédisposition&nbsp;:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7-8-9 Avec grande facilité,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4-5-6 Avec facilité,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1-2-3 Avec effort
            </p>
        </div>
        <div *ngIf="translate.currentLang=='en'">
            <p>
                In this section each competency is defined and followed by an indicator stating the degree of talent to
                developing the designated competency.
            </p>
            <p>Level of Predisposition:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7 – 8 – 9 Great ease,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4 – 5 – 6 Normal range,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1 – 2 – 3 Requires effort
            </p>
        </div>
        <hr>
    </div>
    <table class="talentContainer">
            <ng-container *ngFor="let talent of orderPres; let i=index">
                <tr>
                    <td class="boldTitle">{{ talent[0].title}}</td>
                    <td class="boldTitle">{{ talent[1].title}}</td>
                </tr>
                <tr>
                    <td>
                        <app-talent-graph
                            [id]="'desc_'+0+'_'+i"
                            [score]="talent[0].score"
                            [size]="'large'"
                            [mobile]="mobile"
                        ></app-talent-graph>
                    </td>
                    <td>
                        <app-talent-graph
                            [id]="'desc_'+1+'_'+i"
                            [score]="talent[1].score"
                            [size]="'large'"
                            [mobile]="mobile"
                        ></app-talent-graph>
                    </td>
                </tr>
                <tr>
                    <td style="padding-bottom: 15px;">{{ talent[0].text}}</td>
                    <td style="padding-bottom: 15px;">{{ talent[1].text}}</td>
                </tr>
            </ng-container>
    </table>
    <hr/>
    <!-- Indice des talents individuels-->
    <div style="height: 20px">&nbsp;</div>
    <div class="pull-left">
        <a id="orderedTalents"></a>
        <h2>{{'people.reportTalents.talentsOrdered' | translate }}</h2>
    </div>
    <div class="pull-right">
        <div class="dropdown">
            <div class="dropbtn dropbtnLarge">{{ 'commons.goto' | translate }} <span class="caret"></span></div>
            <div class="dropdown-content surfInPage dropbtnLarge">
                <div (click)="moveTo('aptitudes')">
                    {{ 'people.reportTalents.sixAptitudeSpheres' | translate }}
                </div>
                <div (click)="moveTo('talentsWithAptitudes')">
                    {{ 'people.reportTalents.descriptionViaAptitude' | translate }}
                </div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('jobVsPerson')">{{'people.reportTalents.jobVsPerson' | translate }}
                </div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('interviewQuestions')"
                >
                    {{'people.reportTalents.interviewQuestions' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="instructions" style="margin-bottom: 20px">
        <hr>
        <div *ngIf="translate.currentLang=='fr'">
            <p>
                Talents ordonnés en ordre décroissant, selon le degré de facilité à développer à un niveau élevé les
                compétences répertoriées.
            </p>


            <p>Degré de prédisposition&nbsp;:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7-8-9 Avec grande facilité,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4-5-6 Avec facilité,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1-2-3 Avec effort
            </p>

        </div>
        <div *ngIf="translate.currentLang=='en'">
            <p>
                Talents are listed in order from <em>greatest ease</em> to <em>greatest effort</em> required with
                respect to developing the designated competencies.
            </p>
            <p>Level of Predisposition:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7 – 8 – 9 Great ease,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4 – 5 – 6 Normal range,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1 – 2 – 3 Requires effort
            </p>
        </div>
        <hr>
    </div>
    <ng-container *ngIf="orderedScores">
        <div *ngFor="let col of orderedScores; let i = index"
             [class.pull-left]="!small"
             [class.col-md-6]="!small && (12 / maxColsNumber) > 4 && maxColsNumber > 1"
             [class.col-md-4]="!small && (12 / maxColsNumber) === 4"
             [class.col-md-3]="!small && (12 / maxColsNumber) === 3"
             [class.col-md-2]="!small && (12 / maxColsNumber) === 2"
        >
            <table class="talentOrderedContainer tableOrderedContainer">
                <tr *ngFor="let score of orderedScores[i]">
                    <ng-container *ngIf="score">
                        <td class="talentTitle"
                            [class.paddingLeft12]="i > 0"
                            [style.width]="(small) ? '215px' : null"
                        >
                            {{ (score[0]) ? report['texts']['talents']['titles'][score[0]] : '' }}
                        </td>
                        <td style="margin:0; padding: 0">
                            <app-talent-graph
                                *ngIf="score && score[0] && score[1]"
                                [id]="'ordered_' + score[0]"
                                [score]="score[1]"
                                [marginBottom]="false"
                                [mobile]="mobile"
                            ></app-talent-graph>
                        </td>
                    </ng-container>
                </tr>
            </table>
        </div>
    </ng-container>
    <div class="clearfix"></div>
    <hr/>
    <div style="height: 20px">&nbsp;</div>
    <!-- aptitudes sphere -->
    <div class="pull-left">
        <a id="aptitudes"></a>
        <h2>{{ 'people.reportTalents.sixAptitudeSpheres' | translate }}</h2>
    </div>
    <div class="pull-right">
        <div class="dropdown">
            <div class="dropbtn dropbtnLarge">{{ 'commons.goto' | translate }} <span class="caret"></span></div>
            <div class="dropdown-content surfInPage dropbtnLarge">
                <div (click)="moveTo('talentsWithAptitudes')">
                    {{ 'people.reportTalents.descriptionViaAptitude' | translate }}
                </div>
                <div (click)="moveTo('orderedTalents')">{{'people.reportTalents.talentsOrdered' | translate }}</div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('jobVsPerson')">{{'people.reportTalents.jobVsPerson' | translate }}
                </div>
                <div
                    *ngIf="reportHasJobCompetencies"
                    (click)="moveTo('interviewQuestions')"
                >
                    {{'people.reportTalents.interviewQuestions' | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="instructions">
        <hr>
        <div *ngIf="translate.currentLang=='fr'">
            <p>
                Cette section illustre les talents par sphère d’aptitudes. Chaque cercle indique le niveau de
                facilité à développer la compétence équivalente. Le degré de disposition est indiqué par la hauteur
                des arcs et par la couleur.
            </p>
            <p>Degré de prédisposition&nbsp;:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7-8-9 Avec grande facilité,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4-5-6 Avec facilité,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1-2-3 Avec effort
            </p>
        </div>
        <div *ngIf="translate.currentLang=='en'">
            <p>
                In this section, talents are grouped into Aptitude Spheres. Each circle shows how easily the related
                skill might be developed. The degree to which individuals are pre-disposed is revealed by curve
                height and by color.
            </p>
            <p>Level of Predisposition:
                <span style="color: #78c7af; font-style: normal">⬤</span> 7 – 8 – 9 Great ease,
                <span style="color: #efce7c; font-style: normal">⬤</span> 4 – 5 – 6 Normal range,
                <span style="color: #779cc5; font-style: normal">⬤</span> 1 – 2 – 3 Requires effort
            </p>
        </div>
        <hr>
    </div>
    <div>
        <div class="aptitudeContainer">
            <ng-container *ngFor="let aptitude of report.texts.aptitudes.titles; let i=index">
                <ng-container *ngIf="i%2==0">
                    <div [class.pull-left]="!small" [class.normalDisplayLeft]="!small">
                        <table style="width: 100%">
                            <tr>
                                <td style="padding: 1.7em 0 0.9em 0"><span
                                    class="boldTitle bTAptitudes">{{ aptitude }}</span></td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top;" [style.height]="(!isLandscape) ? '8em' : null">
                                    {{ report.texts.aptitudes.description[i] }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style="margin: 0 auto;"
                                         [style.width]="(small && !mobile) ? '350px' : graphWrapperWidth"
                                    >
                                        <app-competences-graph
                                            [id]="'talents_'+i"
                                            [scores]="person.talentsScore"
                                            [lang]="translate.currentLang"
                                            [type]="i"
                                            [mobile]="mobile"
                                        ></app-competences-graph>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </ng-container>
                <ng-container *ngIf="i%2==1">
                    <div [class.pull-right]="!small" [class.normalDisplayLeft]="!small">
                        <table style="width: 100%">
                            <tr>
                                <td style="padding: 1.7em 0 0.9em 0"><span
                                    class="boldTitle bTAptitudes">{{ aptitude }}</span></td>
                            </tr>
                            <tr>
                                <td style="vertical-align: top" [style.height]="(!isLandscape) ? '8em' : null">
                                    {{ report.texts.aptitudes.description[i] }}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style="margin: 0 auto;"
                                         [style.width]="(small && !mobile) ? '350px' : graphWrapperWidth"
                                    >
                                        <app-competences-graph
                                            [id]="'talents_'+i"
                                            [scores]="person.talentsScore"
                                            [lang]="translate.currentLang"
                                            [type]="i"
                                            [mobile]="mobile"
                                        ></app-competences-graph>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="clearfix"></div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <hr/>
    <div style="height: 20px">&nbsp;</div>
    <div *ngIf="jobName && reportHasJobCompetencies">
        <div class="pull-left">
            <a id="jobVsPerson"></a>
            <h2>{{'people.reportTalents.jobVsPerson' | translate }}</h2>
            <p>
                <b *ngIf="translate.currentLang=='fr'">Poste sélectionné : </b>
                <b *ngIf="translate.currentLang=='en'">Job selected: </b>
                <span *ngIf="jobName">{{ jobName }}</span>
            </p>
        </div>
        <div class="pull-right">
            <div class="dropdown">
                <div class="dropbtn dropbtnLarge">{{ 'commons.goto' | translate }} <span class="caret"></span></div>
                <div class="dropdown-content surfInPage dropbtnLarge">
                    <div (click)="moveTo('aptitudes')">
                        {{ 'people.reportTalents.sixAptitudeSpheres' | translate }}
                    </div>
                    <div (click)="moveTo('talentsWithAptitudes')">
                        {{ 'people.reportTalents.descriptionViaAptitude' | translate }}
                    </div>
                    <div (click)="moveTo('orderedTalents')">{{'people.reportTalents.talentsOrdered' | translate }}</div>
                    <div
                        *ngIf="reportHasJobCompetencies"
                        (click)="moveTo('jobVsPerson')">{{'people.reportTalents.jobVsPerson' | translate }}
                    </div>
                    <div
                        *ngIf="reportHasJobCompetencies"
                        (click)="moveTo('interviewQuestions')"
                    >
                        {{'people.reportTalents.interviewQuestions' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="instructions" style="margin-bottom: 20px">
            <hr>
            <div *ngIf="translate.currentLang=='fr'">
                <p>
                    Gradation des talents du candidat selon les principales compétences requises par le poste.<br>
                    Le niveau des compétences requises est illustré par une ellipse et le degré des talents
                    correspondants par
                    un chiffre encerclé sur chacune des échelles.
                </p>
                <p> Le score&nbsp;:
                    <span style="color: #78c7af; font-style: normal">⬤</span> Concorde avec la cible,
                    <span style="color: #efce7c; font-style: normal">⬤</span> Est plus haut que la cible,
                    <span style="color: #f17c7f; font-style: normal">⬤</span> Est plus bas que la cible
                </p>
            </div>
            <div *ngIf="translate.currentLang=='en'">
                <p>
                    Candidate’s talents graphed in accordance with the specific job skill requirements.
                    The required level of competency is shown as an ellipse while the candidate’s degree of pre-disposed
                    talent is shown as a numerical value circled on each of the scales.
                </p>
                <p> The score:
                    <span style="color: #78c7af; font-style: normal">⬤</span> Matches with target,
                    <span style="color: #efce7c; font-style: normal">⬤</span> Is higher than target,
                    <span style="color: #f17c7f; font-style: normal">⬤</span> Is lower than target
                </p>
            </div>
            <hr style="margin-top: -5px">
        </div>
        <div>
            <h2>{{'people.reportTalents.keyCompetenciesForJob' | translate }}</h2>
            <table style="width: 100%">
                <ng-container *ngFor="let jobTalent of jobCompetencies; let i=index">
                    <ng-container *ngIf="i< jobCompetencies.length/2">
                        <tr>
                            <td style="width: 50%; padding-right: 5px">
                                <h3>
                                    {{ fuseTalentName(talents[translate.currentLang][jobTalent[0]]) }}
                                </h3>

                            </td>
                            <td style="width: 50%; padding-left: 5px">
                                <h3 *ngIf="jobCompetencies[
                                        i + ceil(jobCompetencies.length/2)
                                    ] && talents[translate.currentLang][
                                    jobCompetencies[
                                        i + ceil(jobCompetencies.length/2)
                                    ][0]
                                ]">
                                    {{
                                    fuseTalentName(talents[translate.currentLang][
                                        jobCompetencies[
                                        i + ceil(jobCompetencies.length/2)
                                            ][0]
                                        ])
                                    }}
                                </h3>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-top: 0; padding-right: 5px" *ngIf="person.talentsScore && person.talentsScore[0]">
                                <app-talent-graph
                                    [id]="'jobVsInd_' + (jobTalent[0] - 1)"
                                    [score]="person.talentsScore[0][jobTalent[0]]"
                                    [target]="(jobTalent[1]==2) ? 'high' : 'med' "
                                    [size]="'large'"
                                    [mobile]="mobile"
                                ></app-talent-graph>
                            </td>
                            <td style="padding-top: 0; padding-left: 5px" *ngIf="person.talentsScore && person.talentsScore[0]">
                                <div *ngIf="jobCompetencies[
                                                i + ceil(jobCompetencies.length/2)
                                            ] && person.talentsScore[0][jobCompetencies[
                                                i + ceil(jobCompetencies.length/2)
                                            ][0]]">
                                    <app-talent-graph
                                        *ngIf="person.talentsScore && person.talentsScore[0]"
                                        [id]="'jobVsInd_' + (jobCompetencies[
                                                i + ceil(jobCompetencies.length/2)
                                            ][0])"
                                        [score]="person.talentsScore[0][jobCompetencies[
                                                i + ceil(jobCompetencies.length/2)
                                            ][0]]"
                                        [target]="(jobCompetencies[
                                                        i + ceil(jobCompetencies.length/2)
                                                        ][1] == 2) ? 'high' : 'med' "
                                        [size]="'large'"
                                        [mobile]="mobile"
                                    ></app-talent-graph>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="padding-right: 5px">
                                <p>{{ report['texts']['talents'].description[jobTalent[0]] }}</p>
                            </td>
                            <td style="padding-left: 5px">
                                <p *ngIf="jobCompetencies[
                                            i + ceil(jobCompetencies.length/2)
                                        ] && jobCompetencies[
                                            i + ceil(jobCompetencies.length/2)
                                        ][0]">
                                    {{
                                    report['texts']['talents'].description[
                                        jobCompetencies[
                                        i + ceil(jobCompetencies.length/2)
                                            ][0]
                                        ]
                                    }}
                                </p>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </table>
            <hr style="margin-top: -5px">
        </div>
        <div class="clearfix"></div>
        <div>
            <div class="pull-left">
                <a id="interviewQuestions"></a>
                <h2>{{'people.reportTalents.interviewQuestions' | translate }}</h2>
                <p>
                    <b>{{ 'people.reportTalents.jobSelected' | translate }} </b>
                    <span *ngIf="jobName">{{ jobName }}</span>
                </p>
            </div>
            <div class="pull-right">
                <div class="dropdown">
                    <div class="dropbtn dropbtnLarge">{{ 'commons.goto' | translate }} <span class="caret"></span></div>
                    <div class="dropdown-content surfInPage dropbtnLarge">
                        <div (click)="moveTo('aptitudes')">
                            {{ 'people.reportTalents.sixAptitudeSpheres' | translate }}
                        </div>
                        <div (click)="moveTo('talentsWithAptitudes')">
                            {{ 'people.reportTalents.descriptionViaAptitude' | translate }}
                        </div>
                        <div (click)="moveTo('orderedTalents')">{{'people.reportTalents.talentsOrdered' | translate }}</div>
                        <div
                            *ngIf="reportHasJobCompetencies"
                            (click)="moveTo('jobVsPerson')">{{'people.reportTalents.jobVsPerson' | translate }}
                        </div>
                        <div
                            *ngIf="reportHasJobCompetencies"
                            (click)="moveTo('interviewQuestions')"
                        >
                            {{'people.reportTalents.interviewQuestions' | translate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="instructions" style="margin-bottom: 20px">
                <hr>
                <div *ngIf="translate.currentLang=='fr'">
                    <p>
                        Voici une sélection de trois questions pouvant servir à évaluer le niveau de maître de chaque
                        compétence
                        recherchée pour le poste. Prenez le temps d'évaluer le score (1 à 9) associé à chaque compétence
                        en fonction des réponses du candidat (la version PDF vous permet d'ajouter des notes pour chaque
                        compétence).
                    </p>
                </div>
                <div *ngIf="translate.currentLang=='en'">
                    <p>
                        Using the questions or statements below and judging from the candidate’s current experience
                        level,
                        grade the candidate’s capacity (in relation to the designated job competency requirements) based
                        on
                        your impressions.
                    </p>
                </div>
                <hr style="margin-top: -5px">
            </div>
            <div>
                <div *ngFor="let jobTalent of jobCompetencies">
                    <h3>
                        {{ fuseTalentName(talents[translate.currentLang][jobTalent[0]]) }}
                    </h3>
                    <!--app-talent-graph
                            [id]="'talent_selector_empty_'+i"
                            [size]="'large_masked'"
                            [type]="'empty'"
                            [level]="'none'"
                    ></app-talent-graph-->
                    <ul>
                        <li *ngFor="let question of interviewQuestions[translate.currentLang][jobTalent[0]]">
                            {{ question }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div style="height: 40px">&nbsp;</div>
    </div>
    <div *ngIf="warnings">
        <div>
            <div *ngIf="translate.currentLang=='fr'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicateurs de questionnaire invalide
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicateur de questionnaire invalide
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>L’indicateur de scores extrêmes est positif.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>L’indicateur d’endossement est positif.</em></p>
                <p>
                    La présence d’un ou deux indicateurs de questionnaire invalide indique qu’il est raisonnable
                    de remettre en doute la validité du résultat. Cependant, il revient à l’utilisateur de décider si
                    le questionnaire du candidat est valide ou si ce dernier doit le refaire.
                </p>
            </div>
            <div *ngIf="translate.currentLang=='en'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicators of an invalid questionnaire
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicator of an invalid questionnaire
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>Extreme Scores Indicator is positive.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>Endorsement Indicator is positive.</em></p>
                <p>
                    The presence of an indicator indicates that it is reasonable to believe that the survey results are
                    invalid. However, the analyst must decide whether the candidate’s questionnaire is valid or if
                    it must be redone.
                </p>
            </div>
        </div>
        <div style="height: 40px">&nbsp;</div>
    </div>
</div>
<button  (click)="moveTo('top')">
    <span id="upButton">
        <img src="assets/buttons/chevronUp.png" alt=""/>
    </span>
</button>
