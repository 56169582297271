<ng-sidebar-container style="position: absolute; height: 100vh">
    <ng-sidebar
        [position]="'right'"
        [closeOnClickOutside]="false"
        [(opened)]="sidebarOpened"
        [autoFocus]="false"
    >
        <!-- Search filters -->
        <app-administration-list-search-filters
            [whereAmI]="'filter'"
            (closeSearchFilters)="toggleSidebar()"
            (resetSearchFilters)="resetSearchFilters()"
            (setCurrentAccountTypes)="setCurrentAccountType($event)"
            (setStatus)="setStatus($event)"
            (setClientStartDate)="setClientStartDate($event)"
            (setClientEndDate)="setClientEndDate($event)"
            (setSubsidiary)="setSubsidiary($event)"
            (setDistributor)="setDistributor($event)"
            (setPartner)="setPartner($event)"
        ></app-administration-list-search-filters>
    </ng-sidebar>
    <div ng-sidebar-content>
        <div class="rightWrapper adminListWrapper"
             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"

             (scrolled)="listScrolled()"
        >
            <div class="greyBackground gbrRight" [style.width]="sideWidth"></div>
            <div class="outerColumnWhite col-md-12 clear">
                <div id="topMenuWrapper" class="affix" (window:resize)="onResize($event)" [style.width]="listWidth">
                    <div>
                        <div class="pull-left">
                            <h1 class="sTitle">
                                {{ 'commons.administration' | translate }}
                            </h1>
                        </div>
                        <div class="pull-right">
                            <div style="float: right">
                                <a (click)="toggleSidebar()">
                                    <div>
                                        <img *ngIf="!selectionByFilters"
                                             src="assets/header/search_and_filters/ico_filter.png" alt=""/>
                                        <img *ngIf="selectionByFilters"
                                             src="assets/header/search_and_filters/ico_filter_hover.png" alt=""/>
                                    </div>
                                </a>
                            </div>
                            <app-main-list-search-bar
                                [switchOff]="switchOff"
                                [selectionByFilters]="selectionByFilters"
                                (searchEvent)="setSearchFilter($event)"
                            ></app-main-list-search-bar>
                            <div class="clearfix"></div>
                            <div class="searchResults">
                                <span
                                    style="font-weight: bold"
                                    *ngIf="selectionDone && totalListItems !== null"
                                >
                                    {{totalListItems}}
                                    {{ resMention | translate }}
                                    <span *ngIf="totalItems">
                                        {{ 'commons.of' | translate }} {{totalItems}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div id="subMenu">
                        <div class="pull-left">
                            <table class="nav nav-topbar">
                                <tr class="topMenu">
                                    <td *ngIf="canCreateClient() && !showTools">
                                        <button class="addItem-btn" (click)="onCreateClient()">
                                            <i class="material-icons">add</i>
                                            <span class="addItem-title">{{ 'commons.new' | translate }}</span>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div
                                class="pull-right"
                                [class.typeSelected]="!showTools"
                                (click)="goToList()"
                            >
                                <div>{{ 'admin.commons.list' | translate }}</div>
                            </div>
                            <div
                                *ngIf="permissionsAccountType !== 'client' && permissionsAccountType !== 'demo'"
                                class="pull-right"
                                (click)="goToMyAccount()"
                            >
                                <div>{{ 'admin.commons.myAccount' | translate }}</div>
                            </div>
                            <div *ngIf="canGoToTools()"
                                 class="pull-right"
                                 [class.typeSelected]="showTools"
                                 (click)="goToTools()"
                            >
                                <div>{{ 'app.navigation.tools' | translate }}</div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <app-main-tools
                    *ngIf="showTools"
                    [section]="'admin'"
                ></app-main-tools>
                <div
                    *ngIf="this.toList && !showTools"
                    [style.height]="(this.toList['count']==0) ? '700px': null"
                    id="listWrap"
                >
                    <ngx-loading [show]="loading" [config]="{
            backdropBorderRadius: '0pt',
            backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
            primaryColour: '#CDCDCD',
            secondaryColour: '#ABABAB',
            tertiaryColour: '#CDCDCD'
            }"></ngx-loading>
                    <div *ngIf="initList">
                        <app-main-list
                            [small]="small"
                            [selectedItemId]="state.clientToDisplayId"
                            [tableDefinition]="state.tableDefinition"
                            [view]="state.listView"
                            [clickMode]="clickMode"
                            [listsData]="toList"
                            [isAdmin]="true"
                            [actualModule]="'admin'"
                            (listScrolled)="listScrolled()"
                            (selectedItem)="clientSelected($event)"
                            (checkableItem)="checkableHandling($event)"
                            (reordering)="reorderingListsRows($event)"
                        ></app-main-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-sidebar-container>
