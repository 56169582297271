<div class="pull-left">
    <h3>{{ listTitle }}</h3>
</div>

<div class="pull-right" style="padding-top: 40px;">
    <div *ngIf="displaySearch && originalList" class="pull-left">
        <input
            type="text"
            style="margin: 0 5px 5px 0"
            [placeholder]="'commons.search' | translate"
            [(ngModel)]="value"
            (ngModelChange)="onSearch(value)"
        />
    </div>

    <div *ngIf="createItemLabel" class="pull-right" (click)="onCreateItem()">
        <span *ngIf="doCreate()" class="fakeLink smallPLink">{{ createItemLabel }}</span>
    </div>
</div>
<div class="clearfix"></div>
<div class="adminSubsection" *ngIf="checkedList">
    <table class="table" style="width: 100%; table-layout: fixed;">
        <thead>
        <tr>
            <th *ngFor="let title of listTitles; let columnIndex1 = index"
                [style.width]="cellProperties(listType, columnIndex1).width">{{ title }}</th>
            <th class="deleteAction" *ngIf="isSuperAdmin && listType === 'files'"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let i of createRange(actualPage)">
            <tr
                [tooltip]="(checkedList[i] && checkedList[i]['extraDescription']) ||
                            (checkedList[i] && checkedList[i].info && checkedList[i].info['extraDescription']) ||
                            false"
            >

                <ng-container *ngFor="let metadata of listMetaData; let columnIndex2 = index">
                    <td
                        (click)="onSelectItem(list[i])"
                        [style.width]="cellProperties(listType, columnIndex2).width"
                        [style.vertical-align]="cellProperties(listType, columnIndex2).vertical_align"
                        [style.text_align]="cellProperties(listType, columnIndex2).text_align"
                    >
                        <ng-container *ngIf="checkedList[i]; else blank">
                            <!-- type -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'extension'">
                                <img alt="" style="width: 75%" [src]="iconFile(checkedList[i][metadata.value])"/>
                            </ng-container>

                            <!-- sku -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'sku'">
                                {{ formatProductTitles(checkedList[i][metadata.value]) }}
                            </ng-container>
                            <!-- string -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'string'">
                                {{ quantity(checkedList[i][metadata.value]) }}
                            </ng-container>

                            <!-- date -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'date'">
                                {{ checkedList[i][metadata.value] ? (list[i][metadata.value]['sec'] * 1000 | date:'dd/MM/y') : '' }}
                            </ng-container>

                            <!-- date2 -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'date2'">
                                {{ checkedList[i][metadata.value] ? (checkedList[i][metadata.value] | date:'dd/MM/y') : '' }}
                            </ng-container>

                            <!-- name -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'name'">
                                {{ checkedList[i]['firstName'] }} {{ checkedList[i]['lastName'] }}
                            </ng-container>

                            <!-- address -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'address'">
                                {{ checkedList[i].address || '' }} {{ checkedList[i].country || '' }}
                            </ng-container>

                            <!-- filename -->
                            <ng-container *ngIf="checkedList[i][metadata.value] && metadata.type === 'filename'">
                                [{{ checkedList[i]['extension'] }}] {{ checkedList[i]['originalName'] }}
                            </ng-container>
                            <!-- status -->
                            <ng-container *ngIf="metadata.type === 'status'">
                                {{ (!!checkedList[i][metadata.value] ? 'admin.commons.active' : 'admin.commons.inactive') | translate }}
                            </ng-container>

                            <!-- user role -->
                            <ng-container *ngIf="metadata.type === 'userRole'">
                                {{ 'admin.roles.' + checkedList[i].role | translate }}
                            </ng-container>
                        </ng-container>
                        <ng-template #blank>
                            <span>&nbsp;</span>
                        </ng-template>
                    </td>
                </ng-container>
                <td class="deleteAction"
                    *ngIf="isSuperAdmin && listType === 'files' && list.length > i * actualPage">
                    <a (click)="deteleteFile(list[i])">
                        <span style="text-transform: lowercase;">{{ 'commons.delete' | translate }}</span>
                    </a>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
    <div>
        <div class="pull-right">
            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)"><img alt=">"
                                                                                            src="/assets/buttons/next.png">
            </div>
            <div *ngIf="actualPage == maxPage || !maxPage" class="pull-right"><img alt=">"
                                                                                   src="/assets/buttons/next_deactivated.png">
            </div>
            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img alt="<"
                                                                                       src="/assets/buttons/before.png">
            </div>
            <div *ngIf="actualPage == 1" class="pull-right"><img alt="<" src="/assets/buttons/before_deactivated.png">
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
