import {Injectable} from '../../shared/barrels/angular';

@Injectable()
export class WindowRef {
    constructor() {}

    getNativeWindow() {
        return window;
    }
}
