<div class="content">
    <h3>{{ 'admin.profileSearch.sectionTitle' | translate }}</h3>
    <table id="profilesSearchForm">
        <tr>
            <td>
                {{ 'admin.profileSearch.firstName' | translate }}
            </td>
            <td>
                <input
                    type="text"
                    (keydown)="inputOnChange($event)"
                    [(ngModel)]="firstName"
                />
            </td>
        </tr>
        <tr>
            <td>
                {{ 'admin.profileSearch.lastName' | translate }}
            </td>
            <td>
                <input
                    type="text"
                    (keydown)="inputOnChange($event)"
                    [(ngModel)]="lastName"
                />
            </td>
        </tr>
        <tr>
            <td colspan="2" style="text-align: right">
                <button
                    class="regularButton"
                    [disabled]="loading"
                    (click)="searchForRecords()"
                >{{ 'admin.profileSearch.search' | translate }}</button>
            </td>
        </tr>
    </table>
    <img alt="loading" *ngIf="loading" style="margin: 0 auto;" src="{{loadingGif}}"/>
    <div *ngIf="results">
        <table class="recordsInAccount">
        <ng-container *ngFor="let accountsResults of results; let i = index">
            <tr>
                <td colspan="3">
                    <h4>{{ accountsResults.databaseNumber }} &ndash; {{ accountsResults.accountName }}</h4>
                </td>
            </tr>

            <tr class="record" *ngFor="let record of accountsResults.records.values(accountsResults.records)">
                <td><i>{{ record.firstName }} {{ record.lastName }}</i>,</td>
                <td>[sous compte {{ record.subAccount }}]</td>
                <td>{{ record.deleted ? 'SUPPRIMÉ' : '' }}</td>
            </tr>
        </ng-container>
        </table>
    </div>
</div>
