<div [class.wrapper]="whereAmI!=='preferences'"
     [class.affix]="whereAmI!=='preferences'"
     [style.width]="width"
     [style.height]="(whereAmI!=='preferences') ? '100vh' : null"
>
    <div [class.topHead]="whereAmI!=='preferences'">
        <div *ngIf="graphBoardsSearchFiltersService.filter" class="pull-left">
            <h2>
                {{ 'commons.displayManagement' | translate }}
            </h2>
        </div>
        <div *ngIf="graphBoardsSearchFiltersService.filter" (click)="graphBoardsList.goToPrec()" class="x-close pull-right" style="font-size: 20pt;">
            &#x00D7;
        </div>
        <div class="clearfix"></div>
        <div id="cardIcon">
            <div class="pull-right" style="padding-top: 10px">
                <span (click)="resetSearchFilters()" class="fakeLink">
                    {{ 'commons.reset' | translate }}
                </span>
            </div>
            <div class="clearfix"></div>
        </div>
        <hr class="panelHr"/>
        <h3 class="fontLight" style="margin-top: 18px; font-size: 14pt">Filtres</h3>
        <div *ngIf="graphBoardsSearchFiltersService.filter" class="filterFamily">
            <div>
                <hr class="panelHr">
                <div [class.widthEn]="translate.currentLang=='en'"
                     [class.widthFr]="translate.currentLang=='fr'"
                     class="subTitle">{{ 'analysisCommons.analysisDate' | translate }}</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <p class="flat">{{ 'commons.start' | translate }}</p>
                    <div style="width: 95%">
                        <div class="datePicker startdp">
                            <my-date-picker
                                    [locale]="translate.currentLang"
                                    [selDate]="graphBoardsSearchFiltersService.dateStart"
                                    [options]="graphBoardsSearchFiltersService.datePickerOptions"
                                    [(ngModel)]="graphBoardsSearchFiltersService.dateStartModel"
                                    (dateChanged)="setFilters($event, 'graphboardStartDate')"
                                    required
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <p class="flat">{{ 'commons.end' | translate }}</p>
                        <div class="datePicker enddp">
                            <my-date-picker
                                    [locale]="translate.currentLang"
                                    [selDate]="graphBoardsSearchFiltersService.dateEnd"
                                    [options]="graphBoardsSearchFiltersService.datePickerOptions"
                                    [(ngModel)]="graphBoardsSearchFiltersService.dateEndModel"
                                    (dateChanged)="setFilters($event, 'graphboardEndDate')"
                                    required>
                            </my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 76px;" class="subTitle">{{ 'commons.business' | translate }}</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <div class="subAccountCol width95">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.subAccount' | translate"
                            [settings]="graphBoardsSearchFiltersService.dropDownSettings"
                            [data]="graphBoardsSearchFiltersService.subAccountsOptions"
                            [(ngModel)]="graphBoardsSearchFiltersService.subAccountsOptionsModel"
                            (ngModelChange)="setFilters($event, 'subAccount')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <!-- department -->
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right depCol width95">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.division' | translate"
                            [settings]="graphBoardsSearchFiltersService.dropDownSettings"
                            [data]="graphBoardsSearchFiltersService.departmentOptions"
                            [(ngModel)]="graphBoardsSearchFiltersService.departmentOptionsModel"
                            (ngModelChange)="setFilters($event, 'department')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <hr class="panelHr"/>
        <h4 style="margin-top: 15px">{{ 'commons.listsDisplayParameters' | translate }}</h4>
        <div id="columnsDisplay">
            <div class="col-md-6"
                 style="margin: 0; padding: 0"
                 *ngFor="let col of graphBoardsList.columns; let i = index">
                <mat-checkbox id="fieldsFilter{{ i }}"
                              #thisCb
                              [checked]="graphBoardsSearchFiltersService.filtering(graphBoardsList.graphBoardState['listColumns'], col)"
                              (change)="setFilters({ key: col, display: (thisCb.checked) }, 'columnDisplay')"
                              [disabled]="(i==0 || i==1)"
                >{{ graphBoardsList.columnsName[translate.currentLang][col] }}
                </mat-checkbox>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
