<div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="translate.currentLang=='fr'">
        Créer, éditer ou supprimer un département
    </h4>
    <h4 class="modal-title pull-left" *ngIf="translate.currentLang=='en'">
        Create, edit or delete a department
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body">
    <p>
        <label for="deptName">{{ 'admin.departmentModal.departmentsName' | translate }}</label>&nbsp;&nbsp;<input
        id="deptName"
        class="form-control"
        type="text"
        [(ngModel)]="deptName"
        name="deptName"
    />&nbsp;
    </p>
</div>
<div class="modal-footer">
    <div>
        <button type="submit" class="modalButton pull-right" (click)="saveDepartment()"
                style="margin-top: 15px">
            {{ 'commons.save' | translate }}
        </button>
    </div>
    <div *ngIf="departmentModalAction!='add'">
        <button type="submit" class="modalButton pull-right" (click)="deleteDepartment()"
                style="margin-top: 15px; margin-right: 15px">
            {{ 'commons.delete' | translate }}
        </button>
    </div>
    <div>
        <button type="submit" class="modalButton pull-right" (click)="closeModal()"
                style="margin-top: 15px; margin-right: 15px">
            {{ 'commons.cancel' | translate }}
        </button>
    </div>
    <div class="clearfix"></div>
</div>
