import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Subscription} from 'rxjs';
import {StateService} from '../../core/services/state/state.service';
import {AdminService} from './admin.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NavigationService } from '../components/navigation/navigation.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private stateService: StateService,
        private router: Router,
        private adminService: AdminService,
        private jwtHelperService: JwtHelperService,
        private navigationService: NavigationService,
        private translateService: TranslateService
    ) {
    }

    isLogged() {
        if (!this.jwtHelperService.isTokenExpired()) {
            const isLogged = localStorage.getItem('is_logged');
            if (!isLogged) {
                localStorage.setItem('is_logged', 'yes');
            }
            this.stateService.session.redirectUrl = '';

            // If we already have sessionData, we do not call the API
            if (!this.stateService.session.sessionData ||
                Object.keys(this.stateService.session.sessionData).length === 0
            ) {
                let adminServiceSubscription: Subscription = this.adminService.getSession(this.translateService.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;

                        // Set account number if switch account
                        this.pushAccountNumberBanner(sessionData);

                        adminServiceSubscription.unsubscribe();
                    }
                );
            } else {
                // Set account number if switch account
                this.pushAccountNumberBanner(this.stateService.session.sessionData);
            }
            return true;
        }
        return false;
    }

    canActivate(route: ActivatedRouteSnapshot, stateSnap: RouterStateSnapshot): boolean {
        if (this.isLogged()) {
            return true;
        } else {
            localStorage.setItem('is_logged', '');
            this.stateService.session.redirectUrl = stateSnap.url;
            this.router.navigate(['/login']);
            return false;
        }
    }

    /**
     * Set account number if switch account
     * @param sessionData
     */
    pushAccountNumberBanner(sessionData) {
        let accountNameSwitch = null;
        if (sessionData.switchAccountData) {

            accountNameSwitch = sessionData.accountData.subAccounts[0].name;
        }
        // Call observable
        this.navigationService.updateSwitchSubAccountName(accountNameSwitch);
    }
}
