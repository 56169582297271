import {
    Component,
    EventEmitter,
    Input,
    ChangeDetectorRef,
    ViewChild,
    OnInit
} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import {StateService} from '../../../../core/services/state/state.service';
import {ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import * as _ from 'lodash';
import {Job, JobDescriptionData, JobTask, Score} from '../../../models/job.model';
import {JobsCompetencesModalCommon} from '../../commonClasses/jobs-competences-modal-common';
import {JobService} from '../../../services/job.service';
import {ApiJobsService} from '../../../services/api-jobs.service';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {ProfilerHelper} from '../../../helpers/profiler.helper';
import {TextHelper} from 'src/app/shared/misc/text.helper';
import {Tools} from 'src/app/shared/barrels/misc';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-jobs-edit-modal',
    templateUrl: './jobs-edit-modal.component.html',
    styleUrls: ['./jobs-edit-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class JobsEditModalComponent extends JobsCompetencesModalCommon implements OnInit, OnDestroy {
    @Input() status: string;
    @Input() section: string;

    @ViewChild(MatAccordion) accordion: MatAccordion;
    @ViewChild('jobDescriptionPanel') jobDescriptionPanel;
    @ViewChild('personalityPanel') personalityPanel;
    @ViewChild('talentsPanel') talentsPanel;
    @ViewChild('iacPanel') iacPanel;
    @ViewChild('jobNgForm') jobNgForm;
    @ViewChild('jobFormContent') jobFormContent;

    public close: EventEmitter<any> = new EventEmitter();
    public scrollTop: EventEmitter<any> = new EventEmitter();

    apiBaseUrl: string;
    profilerUrl: string;

    jobForm: FormGroup;
    jobFormAsLoaded: any;
    jobFormInitValue: any = {
        jobTitle: null,
        subAccount: null,
        // type: [this.jobObject.type, [Validators.required]],
        // language: [this.jobObject.language, [Validators.required]],
        location: null,
        requiredBy: null,
        reportsTo: null,
        // sharedToSubaccounts: [sharedToSubAccounts],
        purposeOfThePosition: null,
        tasks: [],
        department: null,
        industry: null,
        jobType: null,
        selectedProfile: null,
        OR: null,
        SE: null,
        A: null,
        E: null,
        P: null,
        S: null,
        isSatellite: null,
        RAScore: null
    };
    nextAction = 'none';

    jobObject: Job = new Job();
    requiredBy: string;
    sessionStructure: any = {};
    departments: any;
    validateFormField = Tools.validateFormField;
    errorForm: any;
    afterNewItem: boolean;
    subAccountsOptions: any = [];

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 3,
        enableCheckAll: false
    };

    shToSubAccounts = [];

    selectedProfile = '';
    profileList;
    profileListGroups;
    scoreToDisplay: Score;
    scoreIndex = 0;
    lowScoreTalents = [];
    pProfileTouched = false;
    showProfileWarning = false;
    override = false;
    isSatellite = false;

    subMenu = [];

    RAScore: any;
    pressedKey: number;
    raTexts: any;

    submitted = false;

    private subscriptions = new Subscription();

    constructor(
        public bsModalRef: BsModalRef,
        public jobService: JobService,
        private apiJobsService: ApiJobsService,
        public stateService: StateService,
        private fb: FormBuilder,
        public translate: TranslateService,
        protected alertService: AlertService,
        private cd: ChangeDetectorRef,
        private environment: EnvService
    ) {
        super(translate);
        this.profileList = this.generateProfiles(this.translate.currentLang);
        this.profileListGroups = this.generateProfiles(this.translate.currentLang, true);

        this.apiBaseUrl = this.environment.apiBaseUrl;
        this.profilerUrl = this.environment.apiBaseUrl + 'resources/profiler';
    }

    ngOnInit() {
        this.requiredBy = this.sessionData.userData.fullName;
        this.sessionStructure = this.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.globalInit();
        }

        if (this.status === 'copy') {
            this.jobObject = Object.assign({}, this.jobService.jobToDisplay);
            this.jobObject.jobTitle = '[Copie] ' + this.jobObject.jobTitle;
        }

        // subAccounts multiselect activation
        if (this.subAccounts) {
            for (let subAcct of this.subAccounts) {
                this.subAccountsOptions.push({id: subAcct.id, name: subAcct.name});
            }
        }

        this.selectedProfile = '';

        if (this.status === 'edit') {
            this.afterNewItem = false;
            this.jobObject = Object.assign({}, this.jobService.jobToDisplay);
        }

        // Init purposeOfThePosition
        if (!this.jobObject.jobDescription) {
            this.jobObject.jobDescription = new JobDescriptionData();
        }

        // Score
        this.override = false;
        if (this.status !== 'new' && this.jobService.score && this.jobService.score.length > 0) {
            this.scoreToDisplay = this.numerizeScore(this.jobService.score[this.scoreIndex]);
        } else {
            this.scoreToDisplay = new Score();
        }

        // Competences
        for (let i = 0; i < 46; i++) {
            this.selectedTalents[i] = null;
            this.subMenu[i] = false;
        }
        this.profileDataLoad();

        if (this.status !== 'new') {
            if (this.mpoScores && this.mpoScores.satellite) {
                this.isSatellite = true;
            }
            if (this.talentsScores) {
                for (let competence in this.talentsScores) {
                    if (this.talentsScores.hasOwnProperty(competence)) {
                        this.updateTalent(Number(competence), this.talentsScores[competence], null);
                    }
                }
            }
        }

        this.RAScore = '';
        if (this.status !== 'new') {
            if (this.jobService.raScore) {
                this.RAScore = this.jobService.raScore.toString();
                this.cd.markForCheck();
            }
        }
        this.apiJobsService.jobRaTexts().subscribe(
            (res) => {
                this.raTexts = res['RAtexts'];
                this.cd.markForCheck();
            }
        );

        this.initJobForm();

        this.checkCompetencies();

        this.subscriptions.add(this.translate.onLangChange.subscribe(
            (event) => {
                this.profileList = this.generateProfiles(event.lang);
                this.profileListGroups = this.generateProfiles(event.lang, true);
                this.profileDataLoad();
            }
        ));
    }

    globalInit() {
        this.submitted = false;

        this.jobObject = Object.assign({}, new Job());
        this.jobObject.reportsTo = this.requiredBy;
        this.jobObject.requiredBy = this.requiredBy;
    }

    initJobForm() {

        const departmentId = (this.jobObject.department && this.jobObject.department.length > 0)
            ? this.jobObject.department[0].id
            : '';

        const industry = (this.jobObject.industry)
            ? this.sessionStructure.profilerIndustry.find(item => item.name === this.jobObject.industry)['value']
            : null;

        this.submitted = false;

        const jobType = (this.jobObject.jobType)
            ? this.sessionStructure.profilerJobType.find(item => item.name === this.jobObject.jobType)['value']
            : null;

        let subAccountValue: any = '';
        if (this.jobService.id && this.status !== 'new') {
            subAccountValue = this.jobService.subAccount;
        } else if (this.subAccounts && this.subAccounts[0]) {
            subAccountValue = this.subAccounts[0].id;
        }
        subAccountValue = subAccountValue.toString();

        let currentScore = (this.mpoScores && this.status !== 'new') ?
            [this.mpoScores.A, this.mpoScores.E, this.mpoScores.P, this.mpoScores.S] :
            [];
        const profiles = TextHelper.profiles();
        for (let title of profiles.titles) {
            for (let profile of profiles[title.key]) {
                if (JSON.stringify(currentScore) === JSON.stringify(profile.score)) {
                    this.selectedProfile = profile.key;
                }
            }
        }

        // let sharedToSubAccounts = [];
        // if (this.jobService.sharedToSubAccounts && this.status !== 'new') {
        //     sharedToSubAccounts = this.jobService.sharedToSubAccounts;
        // }

        this.jobForm = this.fb.group({
            jobTitle: [this.jobObject.jobTitle, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccountValue, [Validators.required]],
            // type: [this.jobObject.type, [Validators.required]],
            // language: [this.jobObject.language, [Validators.required]],
            location: [this.jobObject.location],
            requiredBy: [this.jobObject.requiredBy],
            reportsTo: [this.jobObject.reportsTo],
            // sharedToSubaccounts: [sharedToSubAccounts],
            purposeOfThePosition: [this.jobObject.jobDescription.purposeOfThePosition],
            tasks: this.fb.array(this.getTasks()),
            department: [departmentId],
            industry: [industry],
            jobType: [jobType],
            selectedProfile: [this.selectedProfile],
            OR: [this.setFormValue(this.scoreToDisplay.OR)],
            SE: [this.setFormValue(this.scoreToDisplay.SE)],
            A: [this.setFormValue(this.scoreToDisplay.A)],
            E: [this.setFormValue(this.scoreToDisplay.E)],
            P: [this.setFormValue(this.scoreToDisplay.P)],
            S: [this.setFormValue(this.scoreToDisplay.S)],
            isSatellite: this.isSatellite,
            RAScore: [this.RAScore]
        });

        this.jobFormAsLoaded = Object.assign({}, this.jobForm.value);
        this.selectedTalentsInit = [...this.selectedTalents];
    }

    setFormValue(score) {
        let returnVal = String(score);
        if (
            String(score) === 'null' ||
            String(score) === 'undefined'
        ) {
            returnVal = null;
        }
        return returnVal;
    }

    // Create array of FormGroup
    getTasks(): Array<FormGroup> {
        let tasks = [];

        // Init with api data
        if (this.jobObject.jobDescription && this.jobObject.jobDescription.tasks && this.jobObject.jobDescription.tasks.length > 0) {
            for (let task of this.jobObject.jobDescription.tasks) {
                tasks.push(this.setTaskFormGroup(task));
            }
        } else {
            // Init default data
            for (let i = 0; i < 6; i++) {
                tasks.push(this.setTaskFormGroup(new JobTask()));
            }
        }

        return tasks;
    }

    setTaskFormGroup(task: JobTask): FormGroup {
        return this.fb.group({
            responsibility: [(task.responsibility) ? task.responsibility.toString() : ''],
            priority: [(task.priority) ? task.priority.toString() : ''],
            percentage: [(task.percentage) ? task.percentage.toString() : '']
        });
    }

    // task related functions
    getTasksControls(jobForm) {
        if (jobForm) {
            return jobForm.get('tasks').controls;
        }
        return;
    }

    addTask() {
        const control = <FormArray>this.jobForm.controls['tasks'];
        control.push(this.setTaskFormGroup(new JobTask()));
    }

    removeTask(i: number) {
        const control = <FormArray>this.jobForm.controls['tasks'];
        control.removeAt(i);
    }

    resetSection(event, section) {
        event.stopPropagation();
        let elemToReset;
        let formControlName;
        switch (section) {
            case 'jobDescription':
                elemToReset = document.getElementsByClassName('jobDescriptionField');
                let taskIndex = 0;
                for (let i = 0; i < elemToReset.length; i++) {
                    formControlName = elemToReset[i].getAttribute('formcontrolname');
                    if (formControlName === 'responsibility' || formControlName === 'priority' || formControlName === 'percentage') {
                        if (this.jobFormInitValue.tasks[taskIndex]) {
                            elemToReset[i].value = this.jobFormInitValue.tasks[taskIndex][formControlName];
                            if (this.jobForm.value && this.jobForm.value.tasks &&
                                this.jobForm.value.tasks[taskIndex] && this.jobForm.value.tasks[taskIndex][formControlName]) {
                                this.jobForm.value.tasks[taskIndex][formControlName] = this.jobFormInitValue.tasks[taskIndex][formControlName];
                            }
                        } else {
                            elemToReset[i].value = '';
                            if (this.jobForm.value && this.jobForm.value.tasks &&
                                this.jobForm.value.tasks[taskIndex] && this.jobForm.value.tasks[taskIndex][formControlName]) {
                                this.jobForm.value.tasks[taskIndex][formControlName] = '';
                            }
                        }
                        if (formControlName === 'responsibility') {
                            taskIndex++;
                        }
                    } else {
                        elemToReset[i].value = this.jobFormInitValue[formControlName];
                        if (this.jobForm.value && this.jobForm.value[formControlName]) {
                            this.jobForm.value[formControlName] = this.jobFormInitValue[formControlName];
                        }
                    }
                }
                break;
            case 'personality':
                elemToReset = document.getElementsByClassName('personalityField');
                for (let i = 0; i < elemToReset.length; i++) {
                    formControlName = elemToReset[i].getAttribute('formcontrolname');
                    if (formControlName === 'isSatellite') {
                        let isSatChk = elemToReset[i].getElementsByClassName('mat-checkbox-input')[0];
                        isSatChk.checked = this.jobFormInitValue[formControlName];
                        if (!isSatChk.checked) {
                            if (elemToReset[i].classList && elemToReset[i].classList.contains('mat-checkbox-checked')) {
                                elemToReset[i].classList.remove('mat-checkbox-checked');
                            }
                        }
                    } else {
                        elemToReset[i].value = this.jobFormInitValue[formControlName];
                    }
                    if (this.jobForm.value && this.jobForm.value[formControlName]) {
                        this.jobForm.value[formControlName] = this.jobFormInitValue[formControlName];
                        this.jobForm.get(formControlName).setValue(this.jobFormInitValue[formControlName]);
                    }
                }
                this.checkCompetencies(null, true);
                break;
            case 'talents':
                this.selectedTalents = [...this.selectedTalentsInit];
                this.checkCompetencies(null, true);
                break;
            case 'iac':
                elemToReset = document.getElementById('RAScore');
                // formControlName = elemToReset.getAttribute('formcontrolname');
                elemToReset.value = null;
                if (this.jobForm.value && this.jobForm.value[formControlName]) {
                    this.jobForm.value[formControlName] = this.jobFormInitValue[formControlName];
                }
                this.RAScore = this.jobFormInitValue[formControlName];
                this.cd.markForCheck();
                break;
        }
    }

    resetEvaluationForm() {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            this.submitted = false;
            this.selectedProfile = '';
            this.scoreToDisplay = undefined;
            this.RAScore = '';

            this.jobForm.reset();
            this.jobNgForm.submitted = false;
            this.globalInit();
        });
    }

    onSharedToSubAccountsChange() {
        let subAccounts = this.checkDropDownOptions(this.sharedToSubAccounts);
        let subAccountsNames = this.checkDropDownOptions(this.sharedToSubAccounts, 'name');
        let subAccountCol = document.getElementsByClassName('subAccountCol')[0];
        let subAccountDropDown = subAccountCol.getElementsByClassName('multiselect-dropdown')[0];
        let subAccountDropDownText = subAccountDropDown.getElementsByClassName('dropdown-btn')[0];
        let dropDownPlHolder = subAccountDropDownText.getElementsByClassName('dropDownPlHolder')[0];

        if (subAccounts.length) {
            let span = document.createElement('span');
            span.className = 'dropDownPlHolder';
            let subAccountsHolder = this.translate.instant('commons.select');
            if (subAccountsNames) {
                subAccountsHolder = '';
                let subAccountsSep = '';
                let subAccountIndex = 0;
                subAccountsNames.forEach(acc => {
                    subAccountIndex++;
                    if (subAccountIndex <= this.dropDownSettings.itemsShowLimit) {
                        subAccountsHolder += subAccountsSep + acc;
                        subAccountsSep = ', ';
                    }
                });
                if (subAccountIndex > this.dropDownSettings.itemsShowLimit) {
                    subAccountsHolder += ' (+' + (subAccountIndex - this.dropDownSettings.itemsShowLimit).toString() + ')';
                }
            }
            span.append(subAccountsHolder);
            if (dropDownPlHolder) {
                dropDownPlHolder.remove();
            }
            subAccountDropDownText.prepend(span);
        } else if (dropDownPlHolder) {
            dropDownPlHolder.remove();
        }
    }

    checkDropDownOptions(event, result = 'id') {
        let options = [];
        let names = [];
        if (event) {
            event.forEach(item => {
                if (item && typeof item === 'object' && item.hasOwnProperty('id')) {
                    options.push(item.id);
                }
                if (item && typeof item === 'object' && item.hasOwnProperty('name')) {
                    names.push(item.name);
                }
            });
            if (!options.length) {
                options = [...event];
            }
        }
        if (result === 'id') {
            return options;
        }
        return names;
    }

    onDropDownClose() {
        let subAccounts = this.checkDropDownOptions(this.sharedToSubAccounts);
        this.shToSubAccounts = [...subAccounts];
    }

    checkPercentage(jobData: Job) {
        if (jobData.jobDescription && jobData.jobDescription.tasks) {
            for (let taskIndex in jobData.jobDescription.tasks) {
                if (taskIndex && jobData.jobDescription.tasks &&
                    jobData.jobDescription.tasks.hasOwnProperty(taskIndex) && jobData.jobDescription.tasks[taskIndex].percentage) {
                    jobData.jobDescription.tasks[taskIndex].percentage =
                        parseInt(jobData.jobDescription.tasks[taskIndex].percentage.toString().replace('%', ''), 10);
                }
            }
        }
        return jobData;
    }

    numerizeScore(scores): Score {
        return {
            'OR': +scores.OR,
            'SE': +scores.SE,
            'A': +scores.A,
            'E': +scores.E,
            'P': +scores.P,
            'S': +scores.S
        };
    }

    toggleMastering(i) {
        let currentVal = this.subMenu[i];
        for (let j = 0; j < 46; j++) {
            this.subMenu[j] = false;
        }
        this.subMenu[i] = !currentVal;
    }

    checkCompetencies(score = null, modScore = false) {
        if (!score) {
            score = {
                OR: this.jobForm.get('OR').value,
                SE: this.jobForm.get('SE').value,
                A: this.jobForm.get('A').value,
                E: this.jobForm.get('E').value,
                P: this.jobForm.get('P').value,
                S: this.jobForm.get('S').value
            };
        }

        if (score &&
            score['OR'] !== null &&
            score['SE'] !== null &&
            score['A'] !== null &&
            score['E'] !== null &&
            score['P'] !== null &&
            score['S'] !== null
        ) {
            this.subscriptions.add(this.apiJobsService.competenciesLowScore({score: score}).subscribe((res) => {
                if (res) {
                    this.lowScoreTalents = res.lowerTierCompetencies;
                    if (modScore) {
                        this.showProfileWarning = false;

                        // check selected talents id
                        let talentIds = [];
                        this.selectedTalents.forEach((tal, index) => {
                            if (tal === 1 || tal === 2) {
                                talentIds.push(index);
                            }
                        });

                        // get selected talents numbers
                        let talentNumbers = [];
                        talentIds.forEach((talentId) => {
                            if (talentId && this.talentsData[talentId]) {
                                if (this.talentsData.find(talent => talent.id === talentId)) {
                                    talentNumbers.push(this.talentsData.find(talent => talent.id === talentId)['number']);
                                }
                            }
                        });

                        if (this.lowScoreTalents) {
                            this.lowScoreTalents.forEach((lsTalent) => {
                                if (talentNumbers && talentNumbers.includes(lsTalent)) {
                                    this.showProfileWarning = true;
                                }
                            });
                        }
                    }
                }
                this.cd.markForCheck();
            }));
        } else {
            this.showProfileWarning = false;
            this.lowScoreTalents = [];
            this.cd.markForCheck();
        }
    }

    isLowScoreTalent(number) {
        if ((this.lowScoreTalents && this.lowScoreTalents.length) || this.status === 'new') {
            return this.lowScoreTalents.includes(number);
        } else {
            return this.jobService.isLowScoreTalent(number);
        }
    }

    // goToQuestionnaireDef(event) {
    //    event.stopPropagation();
    //    window.open('' + '?lang=' + this.translate.currentLang); // environment.profilerUrl
    // }

    formCheck(specificField = null) {
        if (this.jobNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.jobForm.controls[specificField], this.jobNgForm)) {
                    this.goToDescriptionSection();
                    this.topFunction();
                    return true;
                }
            } else {
                let fields = ['jobTitle', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.jobForm.controls[field], this.jobNgForm)) {
                        this.goToDescriptionSection();
                        this.topFunction();
                        return true;
                    }
                }
            }
        }
        return false;
    }

    useWithSatellite(event) {
        this.pProfileTouched = true;
        this.isSatellite = event.checked;
    }

    goToDescriptionSection() {
        this.personalityPanel.expanded = false;
        this.talentsPanel.expanded = false;
        this.iacPanel.expanded = false;
        this.jobDescriptionPanel.expanded = true;
        this.cd.markForCheck();
    }

    topFunction() {
        this.scrollTop.emit(this.jobFormContent);
    }

    onSubmit(model: FormGroup) {
        this.submitted = true;
        if (!this.jobNgForm.submitted) {
            this.jobNgForm.submitted = true;
        }

        // If form valid
        if (model.valid) {
            let jobData: Job = this.cleanFormValue(model.value);

            let talentsPayload: any = {};
            for (let talent in this.selectedTalents) {
                if (talent && this.selectedTalents.hasOwnProperty(talent) &&
                    (this.selectedTalents[talent] === 1 || this.selectedTalents[talent] === 2)) {
                    talentsPayload['' + talent] = this.selectedTalents[talent];
                }
            }
            jobData.competences = talentsPayload;

            if (this.RAScore) {
                jobData.raScore = this.RAScore;
            }

            // Save a new item after edit
            if (this.status === 'edit' && !this.afterNewItem) {
                this.saveEditJob(jobData);
            } else {
                this.saveNewJob(jobData);
            }
        }
    }

    saveNewJob(jobData: Job) {
        // call api to save the modification
        this.checkPercentage(jobData);
        this.subscriptions.add(this.apiJobsService.postJob(jobData).subscribe(
            res => {
                this.closeModal(res.id);
            },
            error => {
                this.errorForm = error.error;
                this.cd.detectChanges();
            }
        ));
    }

    saveEditJob(jobData: Job) {
        // call api to save the modification
        this.checkPercentage(jobData);
        if (jobData['raScore'] === undefined) {
            jobData['raScore'] = null;
        }
        if (jobData['score'] === undefined) {
            jobData['score'] = null;
        }
        if (jobData['competences'] === undefined) {
            jobData['competences'] = null;
        }

        this.subscriptions.add(this.apiJobsService.putJob([this.jobObject.id], jobData).subscribe(
            () => {
                this.closeModal(this.jobObject.id);
            },
            error => {
                this.errorForm = error.error;
                this.cd.detectChanges();
            }
        ));
    }

    cleanScore(local = false) {
        let scores = {
            OR: this.jobForm.value.OR,
            SE: this.jobForm.value.SE,
            A: this.jobForm.value.A,
            E: this.jobForm.value.E,
            P: this.jobForm.value.P,
            S: this.jobForm.value.S
        };
        return ProfilerHelper.cleanScore(scores, local);
    }

    cleanFormValue(formValue: any): Job {
        let jobData: Job = new Job(formValue);
        // Init jobDescription
        jobData.jobDescription = new JobDescriptionData();

        if (formValue.purposeOfThePosition) {
            jobData.jobDescription.purposeOfThePosition = formValue.purposeOfThePosition;
            // delete formValue.purposeOfThePosition;
        }

        if (formValue && formValue.tasks) {
            let cleanTasks: Array<JobTask> = [];
            formValue.tasks.forEach(function (task, index) {
                if (task.responsibility) {
                    cleanTasks.push(formValue.tasks[index]);
                }
            });

            jobData.jobDescription.tasks = cleanTasks;
        }

        // Department
        jobData.department = (formValue.department) ? [formValue.department] : [];

        // Score
        if (
            formValue.hasOwnProperty('OR') &&
            formValue.hasOwnProperty('SE') &&
            formValue.hasOwnProperty('A') &&
            formValue.hasOwnProperty('E') &&
            formValue.hasOwnProperty('P') &&
            formValue.hasOwnProperty('S')) {
            if (!this.hasAllPersonalityScores) {
                this.resetScoreToNull();
            }
            let scores = this.cleanScore(true);
            if (scores) {
                // Clear AD if exists
                if (scores.AD) {
                    delete scores.AD;
                }
                jobData.score = {
                    A: scores['A'],
                    E: scores['E'],
                    P: scores['P'],
                    S: scores['S'],
                    OR: scores['OR'],
                    SE: scores['SE'],
                    satellite: this.isSatellite
                };
            }
            delete jobData['A'];
            delete jobData['E'];
            delete jobData['P'];
            delete jobData['S'];
            delete jobData['OR'];
            delete jobData['SE'];
        }

        return jobData;
    }

    generateProfiles(lang, group = false) {
        const profiles = TextHelper.profiles();
        let dropdownGroups = [];
        let dropdownContent = [];
        for (let title of profiles.titles) {
            if (group) {
                dropdownContent = [];
            }
            for (let profile of profiles[title.key]) {
                dropdownContent.push({
                    value: profile.key,
                    text: profile.name[lang],
                    score: profile.score
                });
            }
            dropdownGroups.push({
                value: title.key,
                text: title.name[lang],
                opt: dropdownContent
            });
        }
        if (group) {
            return dropdownGroups;
        }
        return dropdownContent;
    }

    onChangePersonalityProfile() {
        if (this.displayGraph) {
            this.pProfileTouched = true;
            this.selectedProfile = '';
            this.jobForm.get('selectedProfile').setValue('');
            this.checkCompetencies(null, true);
        }
        this.cd.markForCheck();
    }

    loadValues() {
        this.pProfileTouched = true;
        let selectedProfile = this.jobForm.get('selectedProfile').value;
        if (selectedProfile !== 'none') {
            const profiles = TextHelper.profiles();
            let score = _.result(_.find(profiles.gens.concat(profiles.specs), function (obj) {
                return obj.key === selectedProfile;
            }), 'score');

            let scoreOR = (this.scoreToDisplay.OR !== null && this.scoreToDisplay.OR !== undefined) ?
                this.scoreToDisplay.OR : 0;
            let scoreSE = (this.scoreToDisplay.SE !== null && this.scoreToDisplay.SE !== undefined) ?
                this.scoreToDisplay.SE : 0;

            if (score) {
                this.jobForm.get('OR').setValue(`${scoreOR}`);
                this.jobForm.get('SE').setValue(`${scoreSE}`);
                this.jobForm.get('A').setValue(`${score[0]}`);
                this.jobForm.get('E').setValue(`${score[1]}`);
                this.jobForm.get('P').setValue(`${score[2]}`);
                this.jobForm.get('S').setValue(`${score[3]}`);
            } else {
                this.resetScoreToNull();
            }
        }
        this.checkCompetencies(null, true);
    }

    resetScoreToNull(): void {
        this.jobForm.get('OR').setValue(null);
        this.jobForm.get('SE').setValue(null);
        this.jobForm.get('A').setValue(null);
        this.jobForm.get('E').setValue(null);
        this.jobForm.get('P').setValue(null);
        this.jobForm.get('S').setValue(null);
        return;
    }

    updateTalent(talent, value, subMenuNumber) {
        let setValue = value;
        if (setValue === 0) {
            setValue = null;
        }
        this.selectedTalents[talent] = setValue;
        if (subMenuNumber !== null) {
            this.subMenu[subMenuNumber] = false;
        }
    }

    checkPressedKey(event) {
        this.pressedKey = event.keyCode;
    }

    refreshGraph() {
        this.RAScore = this.jobForm.get('RAScore').value;
        if (this.RAScore < 1 && this.RAScore !== '') {
            this.RAScore = 1;
            this.jobForm.get('RAScore').setValue(1);
        }
        this.cd.markForCheck();
    }

    closeModal(jobId = null) {
        let isJobFormChanged = !(_.isEqual(this.jobForm.value, this.jobFormAsLoaded));
        let isTalentListChanged = !(JSON.stringify(this.selectedTalents) === JSON.stringify(this.selectedTalentsInit));
        if ((isJobFormChanged || isTalentListChanged) && !jobId) {
            const cancelWarning = {
                paragraph: this.translate.instant('jobs.modal.modWarning'),
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(cancelWarning);
            this.subscriptions.add(this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm') {
                        setTimeout(() => {
                            this.resetEvaluationForm();
                            this.close.emit({closed: true, id: jobId});
                            this.bsModalRef.hide();
                        }, 400);
                    }
                })
            );
        } else {
            this.resetEvaluationForm();
            this.close.emit({closed: true, id: jobId});
            this.bsModalRef.hide();
        }
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    get subAccounts() {
        if (this.sessionData.structure && this.sessionData.structure.subAccounts) {
            return this.sessionData.structure.subAccounts;
        }
        return;
    }

    get mpoScores() {
        if (this.jobService.score) {
            return this.jobService.score[0];
        }
        return;
    }

    get talentsScores() {
        if (this.jobService.competences) {
            return this.jobService.competences[0];
        }
        return;
    }

    get sharedToSubAccounts() {
        return this.jobForm.get('sharedToSubaccounts').value;
    }

    get hasTalentsScores() {
        return !!(this.talentsScores && !(_.isEmpty(this.talentsScores)));
    }

    get displayGraph() {
        return !!(
            this.cleanScore() !== undefined &&
            this.cleanScore() !== null &&
            Object.keys(this.cleanScore()).length === 6
        );
    }

    isIntZeroToTen(val): boolean {

        return (Number.isInteger(parseInt(val, 10)) && val >= 0 && val <= 10);
    }

    get hasAllPersonalityScores(): boolean {
        return (
            this.isIntZeroToTen(this.jobForm.value.OR) &&
            this.isIntZeroToTen(this.jobForm.value.SE) &&
            this.isIntZeroToTen(this.jobForm.value.A) &&
            this.isIntZeroToTen(this.jobForm.value.E) &&
            this.isIntZeroToTen(this.jobForm.value.P) &&
            this.isIntZeroToTen(this.jobForm.value.S)
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

}
