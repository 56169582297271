<div *ngIf="idwMatrix.id" class="rightWrapper" (window:resize)="onResize($event)">
    <ng-container *ngIf="!smallSize">
        <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4" [style.width]="(!smallSize) ? sideWidth : null">
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div>
                    <app-idw-matrix-sidebar
                            [isLeftAndNotTop]="true"
                            [width]="sideWidth"
                            [config]="'report'"
                    ></app-idw-matrix-sidebar>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="outerColumnWhite col-md-8"
         [class.col-md-8]="!smallSize"
         [class.col-md-12]="smallSize"
         [style.padding-top]="(smallSize) ? null : '40px'"
         [style.width]="(!smallSize) ? reportWidth : null"
    >
        <div *ngIf="smallSize">
            <app-idw-matrix-sidebar
                    [isLeftAndNotTop]="false"
                    [width]="sideWidth"
                    [config]="'report'"
            ></app-idw-matrix-sidebar>
        </div>
        <ul class="nav nav-tabs" style="border-bottom: 1px solid #ddd;">
            <li [class.active]="viewType == 'trait'">
                <a class="tdnone" (click)="switchView('trait')">{{ 'commons.traits' | translate }}</a>
            </li>
            <li [class.active]="viewType == 'prb'">
                <a class="tdnone" (click)="switchView('prb')">{{ 'commons.behavior' | translate }}</a>
            </li>
        </ul>
        <div style="margin: 15px;">
            <div style="
                    position: absolute;
                    min-width: 580px;
                    max-width: 800px;
                    width: calc(100% - 70px);
            ">
                <app-idw-matrix
                    [viewType]="viewType"
                    [itemToDisplay]="participants"
                    [lang]="currentLang"
                >
                </app-idw-matrix>
                <div *ngIf="participants && viewType === 'prb' && !hasPrb" class="outlineOverlay">
                    <div class="noneTitle">{{ 'commons.none' | translate }}</div>
                </div>
            </div>
            <div *ngIf="smallSize" style="height: 100px">&nbsp;</div>
        </div>
    </div>
</div>



