<div
    [class.mobile]="deviceDetector.isMobile()"
>
    <div
        style="width: 100%; background-color: #dddddd; padding: 10px"
        *ngIf="info"
    >
        <div class="floatLeft">
            <h3>{{ 'jobSatisfactions.report.title' | translate }} {{ info.fullName }}</h3>
        </div>
        <div class="floatRight">
            <span style="font-size: 3em; cursor: pointer" (click)="closeJobSatisfactionPopup.emit(true)">×</span>
        </div>
        <div class="clearfix"></div>
    </div>
    <div style="padding: 10px; width: 100%; height: calc(100% - (2em + 20px)); overflow-y: scroll; position: absolute;"
         *ngIf="prbReport && personalityScores && prbScores && jsColor"
    >
        <h2>{{ 'jobSatisfactions.report.personInfo' | translate }}</h2>
        <div style="margin-bottom: 20px">
            {{ jobSatisfactionText }}
        </div>
        <h2>{{ 'people.reportMpo.prb' | translate }}</h2>
        <em>{{ 'people.reportMpo.prbIntro' | translate}}</em>
        <br/>
        <span style="color: rgb(30.0%,30.0%,30.0%); font-weight: bolder">▬</span>&nbsp;{{ 'commons.traits' | translate}}&nbsp;&nbsp;&nbsp;<span
        style="color: rgb(85.0%,85.0%,85.0%); font-weight: bolder">▬</span>&nbsp;{{ 'commons.behavior' | translate}}
        <hr/>
        <div>
            <div id="prb" *ngFor="let trait of prbReport; let i=index">
                <div>
                    <h4 class="traitsTitle">{{ textFields[translate.currentLang][traits[i]].summary }}</h4>
                    <div>
                        <app-trait-graph
                            [part]="'prb'"
                            [trait]="trait[0]"
                            [lang]="translate.currentLang"
                            [trait1Score]="personalityScores[traits[i]]"
                            [trait2Score]="prbScores[traits[i]]"
                            [mobile]="false"
                            [autoWidth]="true"
                            [jsColor]="jsColor[traits[i]]"
                            [width]="675"
                        ></app-trait-graph>
                    </div>
                </div>
                <p class="georgia">{{ trait[1].text.int }}</p>
                <ul class="georgia">
                    <ng-template ngFor let-item [ngForOf]="trait[1].text.pf">
                        <li *ngIf="(item!=='')">
                            {{ item }}
                        </li>
                    </ng-template>
                </ul>
                <p class="georgia">
                    {{ trait[1].pf }}
                </p>
                <p *ngIf="trait[1].text.cl">
                    <i>{{ trait[1].text.cl }}</i>
                </p>
            </div>
        </div>
    </div>
</div>
