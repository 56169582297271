<!-- app-job-satisfactions-person.cards will have to be looped in jobSatisfaction report -->
<div id="personFrame">
    <ng-container *ngIf="listView">
        <div class="personFrameHeader" style="position: relative">
            <div class="floatLeft">
                <div class="ngorange bold nameTitle">
                    <a class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
                        {{ record['firstName'] + ' ' +  record['lastName'] | truncate:[22]}}
                    </a>
                </div>
            </div>
            <div class="floatRight">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <!-- THREE OR TWO SIDE BY SIDE -->
        <div *ngIf="!hasOneReport" class="mainContent" style="position: relative">
            <div class="subCard"
                 [style.height]="(informationVisibility)? '306px' : '51px' "
            ></div>
            <div
                class="cardContainer"
                [class.col-md-4]="hasThreeReports"
                [class.col-md-6]="hasTwoReports"
                *ngIf="user.hasPersonalityAccess && this.state.displayPreferences.display.personality"
            >
                <app-job-satisfactions-person-sub-card-personality
                    [record]="record"
                    [informationVisibility]="informationVisibility"
                ></app-job-satisfactions-person-sub-card-personality>
            </div>
        </div>
        <!-- ONE REPORT ONLY -->
        <div class="mainContent" *ngIf="hasOneReport" style="position: relative">
            <div class="subCard"
                 [style.height]="(informationVisibility)? '306px' : '51px' "
            ></div>
            <div class="cardContainer col-md-12">
                <ng-container
                    *ngIf="user.hasPersonalityAccess"
                >
                    <app-job-satisfactions-person-sub-card-personality
                        [record]="record"
                        [pos]="pos"
                        [job]="job"
                        [informationVisibility]="informationVisibility"
                    ></app-job-satisfactions-person-sub-card-personality>
                </ng-container>
            </div>
        </div>
        <div class="clearfix"></div>
    </ng-container>
    <ng-container *ngIf="!listView">
        <div
            class="cards"
            [class.cardsGrid]="!listView"
            style="max-width: 375px"
        >
            <div class="personFrameHeader" style="position: relative">
                <div class="floatLeft ngorange bold nameTitleCard">
                    <a class="ngorange invertUnderline" (click)="goToPerson(record.id)" style="color: rgb(227, 94, 36)">
                        {{ record['firstName'] + ' ' +  record['lastName'] | truncate:[22]}}
                    </a>
                </div>
                <ng-container
                    *ngIf="redoPcr(record) !== false"
                    >
                    <div class="floatRight" style="padding-top: 5px; padding-right: 4px;"
                         [tooltip]="tooltipRePcrText(record)"
                    >
                        <app-job-satisfaction-graphics
                            class="floatRight"
                            [background]="'regular'"
                            [score]="redoPcr(record)"
                            [type]="'pcr_redo'"
                            [id]="'pcr_redo_person_' + record['id']+pos"
                        ></app-job-satisfaction-graphics>
                    </div>
                </ng-container>
                <div class="clearfix"></div>
            </div>
            <div>
                <ng-container
                    *ngIf="user.hasPersonalityAccess"
                >
                    <app-job-satisfactions-person-sub-card-personality
                        [record]="record"
                        [pos]="pos"
                        [listView]="listView"
                        [informationVisibility]="informationVisibility"
                        (openPcrReport)="openPcrReportPopUp($event)"
                    ></app-job-satisfactions-person-sub-card-personality>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
