<div class="formWrapper">
    <h4 class="modal-title">
        {{ 'commons.sharePdf' | translate }}
    </h4>
    <div *ngIf="share=='none'">
        <!-- options -->
        <!-- options -->
        <div class="insideCheckboxes">
            <h3 class="ngorange">{{ 'commons.options' | translate }}</h3>
            <div class="floatLeft">
                <h4>{{ 'commons.job' | translate }}</h4>
                <mat-checkbox id="jobDesign"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['jobDesign']"
                >
                    {{ 'jobs.commons.jobDesign' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasPersonalityAccess" id="personalityProfile"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['personalityProfile']"
                              [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores']) ? true : undefined"
                >
                    {{ 'jobs.commons.targetPersonalityProfile' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasTalentsAccess" id="targetCompetencies"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['targetCompetencies']"
                              [disabled]="(totalCompetencies === 0) ? true : undefined"
                >
                    {{ 'jobs.commons.targetCompetenciesProfile' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasIacAccess" id="caiProfile"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['caiProfile']"
                              [disabled]="(rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? true : undefined"
                >
                    {{ 'jobs.commons.targetCaiProfile' | translate}}
                </mat-checkbox>
            </div>

            <div class="floatLeft" style="margin-left: 100px">
                <h4>{{ 'app.navigation.analysis' | translate }}</h4>

                <mat-checkbox *ngIf="user.hasPersonalityAccess" id="personality"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['personality']"
                              [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores']) ? true : undefined"
                >
                    {{ 'commons.personality' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox *ngIf="user.hasTalentsAccess"  id="talents"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['talents']"
                              [disabled]="(totalCompetencies === 0) ? true : undefined"
                >
                    {{ 'commons.talents' | translate }}
                </mat-checkbox>
                <br/>
                <mat-checkbox *ngIf="user.hasIacAccess"  id="cai"
                              (change)="onChange($event)"
                              [(ngModel)]="pdfStructure['cai']"
                              [disabled]="(rightMatchAnalysis.itemToDisplay.profiler['RA'] === false) ? true : undefined"
                >
                    {{ 'commons.cai' | translate }}
                </mat-checkbox>
            </div>
            <div class="clearfix"></div>
        </div>
        <h4 style="margin-top: 10px">{{ 'rms.modal.sortBy' | translate }}</h4>
        <table class="filterTable">
            <tr *ngIf="user.hasPersonalityAccess">
                <td>
                    <input  type="radio" name="sortBy" value="personality"
                            [(ngModel)]="pdfStructure['sortBy']"
                            [disabled]="!rightMatchAnalysis.itemToDisplay.profiler['scores'] ? true : undefined"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.personality' | translate }}</td>
            </tr>
            <tr  *ngIf="user.hasTalentsAccess">
                <td>
                    <input  type="radio" name="sortBy" value="talents"
                            [(ngModel)]="pdfStructure['sortBy']"
                            [disabled]="(totalCompetencies === 0)  ? true : undefined"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.talents' | translate }}</td>
            </tr>
            <tr  *ngIf="user.hasIacAccess">
                <td>
                    <input type="radio" name="sortBy" value="cai"
                           [(ngModel)]="pdfStructure['sortBy']"
                           [disabled]="rightMatchAnalysis.itemToDisplay.profiler['RA'] === false"
                    >
                </td>
                <td>&nbsp;&nbsp;{{ 'commons.cai' | translate }}</td>
            </tr>
        </table>
        <div style="margin-top: 10px">
            <mat-checkbox  *ngIf="user.hasPersonalityAccess" id="displayTargetPersonalityProfile"
                           [(ngModel)]="pdfStructure['displayTargetPersonalityProfile']"
                           [disabled]="(!rightMatchAnalysis.itemToDisplay.profiler['scores']) ? true : undefined"
            >&nbsp;&nbsp;{{ 'rms.commons.displayTargetPersonalityProfile' | translate }}
            </mat-checkbox>
        </div>
        <div class="fieldsSection" *ngIf="false">
            <h5
                [class.text-danger]="selectMethod"
            >{{ 'commons.sendingMethod' | translate }}</h5>
            <mat-form-field>
                <mat-select
                    [(ngModel)]="method"
                    (ngModelChange)="changeSelectMethodState()"
                >
                    <mat-option value="pdf">{{ 'commons.pdfReport' | translate }}</mat-option>
                    <mat-option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</mat-option>
                    <mat-option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</mat-option>
                    <mat-option value="server">{{ 'commons.sendByServer' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="handleReport()"
                >{{ 'commons.continue' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- cutnpaste-->
    <div *ngIf="share=='cutpaste'">
        <div *ngIf="pdfReportUrl" class="fieldsSection">
            <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
            <p
                [translate]="'analysisCommons.linkInstruction'"
                [translateParams]="{
                            'analysisName': rightMatchAnalysis.name
                        }"
            ></p>
            <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.finish' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- mailto -->
    <div *ngIf="share=='mailto'">
        <div *ngIf="mailto" class="fieldsSection">
            <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
            <p>{{ 'commons.sendByYourEmailReportInstructions' | translate }}
                <a href="mailto:{{ mailto }}" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
            <p class="mailtoWarning" style="color: darkred">
                {{ 'commons.mailtoWarning' | translate }}
            </p>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onComplete()"
                >{{ 'commons.finish' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- server -->
    <div *ngIf="share=='server'">
        <div class="fieldsSection">
            <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

            <div>
                <h3><b>Message</b></h3>
                <div class="form-group">
                    <div class="sendContent">
                        <table>
                            <tr>
                                <td class="sendContentTitle"><label for="reportEmailEmailTo">{{ 'commons.email' | translate }}</label></td>
                                <td>
                                    <input
                                        id="reportEmailEmailTo"
                                        type="text"
                                        required
                                        [(ngModel)]="pdfEmail['emailTo']"/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' | translate }}</td>
                            </tr>
                            <tr>
                                <td class="sendContentTitle"><label for="reportEmailSubject">{{ 'commons.emailSubject' | translate }}</label></td>
                                <td>
                                    <input
                                        id="reportEmailSubject"
                                        type="text"
                                        [(ngModel)]="pdfEmail.subject"
                                    />
                                </td>
                            </tr>
                        </table>
                        <table style="margin-top: 25px">
                            <tr>
                                <td style="padding-bottom: 7px; font-weight: bold">
                                    <span class="field_title"><label for="reportEmailMessage">{{ 'commons.message' | translate }}</label></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea
                                        id="reportEmailMessage"
                                        rows="7"
                                        style="border: 1px solid #ccc; height: 100px"
                                        [(ngModel)]="pdfEmail.message"></textarea>
                                </td>
                            </tr>
                        </table>
                        <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                        <div id="innerTextServer">
                            <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                            <p>{{ pdfEmail.message }}</p>
                            <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                            <p *ngIf="pdfReportUrl"
                               class="forceBreaks"
                               [innerHTML]="serverText"
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="cancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        [disabled]="pdfEmail['emailTo'] === ''"
                        (click)="sendEmailReport()"
                >{{ 'commons.shareReport' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
