<div class="modal-header">
    <div *ngIf="test=='communication'">
        <h2 *ngIf="lang=='fr'">MPO Communication non disponible</h2>
        <h2 *ngIf="lang=='en'">MPO Communication not available</h2>
    </div>
    <div *ngIf="test=='talents'">
        <h2 *ngIf="lang=='fr'">MPO Talents non disponible</h2>
        <h2 *ngIf="lang=='en'">MPO Talents not available</h2>
    </div>
    <div *ngIf="test=='satellite'">
        <h2 *ngIf="lang=='fr'">MPO Satellite non disponible</h2>
        <h2 *ngIf="lang=='en'">MPO Satellite not available</h2>
    </div>
    <div *ngIf="test=='personality'">
        <h2 *ngIf="lang=='fr'">MPO Personnalité non disponible</h2>
        <h2 *ngIf="lang=='en'">MPO Personality not available</h2>
    </div>
    <div *ngIf="test=='iac'">
        <h2 *ngIf="lang=='fr'">IAC non disponible</h2>
        <h2 *ngIf="lang=='en'">CAI not available</h2>
    </div>
</div>
<div class="modal-body" style="padding-top: 2px">
    <div
        *ngIf="test=='communication'"
    >
        <p *ngIf="lang=='fr'">
            Une formation de base est fortement recommandée pour assurer une bonne utilisation
            et communication de l’information contenue dans ces rapports. Pour plus
            d’information, communiquez avec votre représentant MPO.
        </p>
        <p *ngIf="lang=='en'">
            Basic training is strongly recommended to ensure proper use and communication of
            the information contained in these reports. For more information, contact your MPO
            representative.
        </p>
    </div>
    <div
            *ngIf="test=='talents' || test=='personality'"
    >
        <p *ngIf="lang=='fr'">
            Une formation de base est nécessaire pour assurer une bonne interprétation et
            communication de l’informations contenue dans ces rapports. Pour plus d’information,
            communiquez avec votre représentant MPO.
        </p>
        <p *ngIf="lang=='en'">
            Basic training is required to ensure proper interpretation and communication of the
            information contained in these reports. For more information, contact your MPO
            representative.
        </p>
    </div>
    <div
        *ngIf="test=='satellite'"
    >
    <p *ngIf="lang=='fr'">
        Pour obtenir accès à ces rapports, communiquez avec votre représentant MPO.
    </p>
    <p *ngIf="lang=='en'">
        In order to have access to these reports, please contact your MPO representative.
    </p>
</div>
</div>
<div class="modal-footer">

    <div class="pull-right" style="margin-left: 10px">
        <button
                type="submit"
                class="modalButton pull-right"
                (click)="closeModal()"
        >
            <span *ngIf="lang=='fr'">Continuer</span>
            <span *ngIf="lang=='en'">Continue</span>
        </button>
    </div>
</div>
