import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    Component,
    Input,
    Output,
    OnInit,
    OnDestroy
} from '@angular/core';
import {Router} from '@angular/router';
import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from 'src/app/core/services/state/state.service';
import {UserService} from 'src/app/core/services/user/user.service';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {PeopleList} from '../../../services/people_list.service';
import {Subscription} from 'rxjs';
import {PeopleSearchFiltersService} from '../../../services/people-search-filters.service';

@Component({
    selector: 'app-people-list-search-filters',
    templateUrl: './people-list-search-filters.component.html',
    styleUrls: ['./people-list-search-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleListSearchFiltersComponent extends ComponentPrimitive implements OnInit, OnDestroy {

    @Input() whereAmI: string;
    @Input() width: string;
    @Output() selectPreference = new EventEmitter;

    preferenceUrl: string;
    userDataId: string;

    private subscriptions = new Subscription();

    constructor(protected router: Router,
                protected deviceService: DeviceDetectorService,
                private changeDetectorRef: ChangeDetectorRef,
                public user: UserService,
                public peopleList: PeopleList,
                public translate: TranslateService,
                public stateService: StateService,
                private userPreferencesService: UserPreferencesService,
                public peopleSearchFilter: PeopleSearchFiltersService
    ) {
        super(
            stateService,
            translate,
            router,
            deviceService
        );
    }

    ngOnInit() {
        this.peopleSearchFilter.initTestOptions(this);
        this.peopleSearchFilter.initTestsStatusOptions(this);
        this.peopleSearchFilter.initDateFormat(this.peopleList.dateFormat());
        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.peopleSearchFilter.initTestOptions(this);
            this.peopleSearchFilter.initTestsStatusOptions(this);
            this.checkPeriodDates();
            this.peopleSearchFilter.initDateFormat(this.peopleList.dateFormat());
        }));

        this.peopleList.checkArchiveSettings();
        this.peopleSearchFilter.checkPreferences(this.whereAmI);

        if (this.whereAmI === 'filter') {
            this.peopleSearchFilter.initInputState(this);
        } else if (this.whereAmI === 'preferences') {

            let accountDataId = (this.peopleList.accountData) ? this.peopleList.accountData.id : '';
            this.userDataId = (this.peopleList.userData) ? this.peopleList.userData.id : '';
            this.preferenceUrl = 'admin/account/' + accountDataId + '/user/' + this.userDataId + '/preferences';

            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.peopleList.userPreferences, this.preferenceUrl);

            // Init state with session vars
            this.peopleSearchFilter.initInputStatePreferences(this);
            this.changeDetectorRef.markForCheck();
        }

        this.peopleSearchFilter.setSubAccountsOptions();
        this.peopleSearchFilter.setDepartmentsOptions();

        this.checkPeriodDates();

        this.peopleSearchFilter.initSubAccountFilter();
        this.peopleSearchFilter.initColumnsFilter();

        setTimeout(() => {
            this.checkMultiselect(this.peopleSearchFilter.optionsTestsModel, 'reportCol', this.translate.instant('commons.reports'));
            this.checkMultiselect(this.peopleSearchFilter.optionsTestsStatusModel, 'statusMpoCol', this.translate.instant('people.commons.mpoStatus'));
            this.checkMultiselect(this.peopleSearchFilter.optionsTestsStatusRaModel, 'statusCaiCol', this.translate.instant('people.commons.caiStatus'));
            this.checkMultiselect(this.peopleSearchFilter.subAccountsOptionsModel, 'subAccountCol', this.translate.instant('commons.subAccount'));
            this.checkMultiselect(this.peopleSearchFilter.departmentOptionsModel, 'depCol', this.translate.instant('commons.division'));
        });
    }

    checkPeriodDates() {
        if (this.peopleList.evaluationStartDate) {
            let dateStartObject = this.peopleList.transformDate(this.peopleList.evaluationStartDate);
            this.peopleSearchFilter.dateStartModel = dateStartObject;
            this.peopleSearchFilter.dateStart = dateStartObject;
        }

        if (this.peopleList.evaluationEndDate) {
            let dateEndObject = this.peopleList.transformDate(this.peopleList.evaluationEndDate);
            this.peopleSearchFilter.dateEndModel = dateEndObject;
            this.peopleSearchFilter.dateEnd = dateEndObject;
        }
    }

    resetSearchFiltersAction(preferences = null) {
        this.peopleSearchFilter.resetSearchFiltersAction();
        if (this.whereAmI === 'filter') {
            this.peopleSearchFilter.resetSearchFilters();
        }
        for (let report in this.peopleList.state.listSearchFilters) {
            if (report && this.peopleList.state.listSearchFilters.hasOwnProperty(report)) {
                if (report !== 'search') {
                    this.peopleList.state.listSearchFilters[report] = null;
                } else {
                    // in case of the search by recordId
                    if (this.peopleList.toList && this.peopleList.toList.count === 1) {
                        let recordId = (this.peopleList.toList.data && this.peopleList.toList.data[0]) ? this.peopleList.toList.data[0].id : null;
                        this.peopleList.state.listSearchFilters[report] = 'recordId=' + recordId;
                    }
                }
            }
        }

        // Reset columns
        if (preferences === null) {
            this.peopleList.setInitialColumnsList();
            this.peopleList.listColumns = JSON.parse(JSON.stringify(this.peopleList.listColumnsInitialConfig));
        } else {
            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.peopleList.userPreferences, this.preferenceUrl, 'people');
            this.peopleSearchFilter.initInputStatePreferences(this);
        }
    }

    checkMultiselect(model, className, placeHolder) {
        if (model) {
            let arr = this.checkDropDownOptions(model);
            this.checkDropDownPlaceholder(arr, className, placeHolder);
            return arr;
        }
        return model;
    }

    setFilters(event, filter) {
        switch (filter) {
            case 'evaluationStartDate':
                this.peopleSearchFilter.setSearchFilter(event, 'evaluationStartDate');
                break;
            case 'evaluationEndDate':
                this.peopleSearchFilter.setSearchFilter(event, 'evaluationEndDate');
                break;
            case 'tests':
                let tests = this.checkMultiselect(this.peopleSearchFilter.optionsTestsModel, 'reportCol', this.translate.instant('commons.reports'));
                this.peopleSearchFilter.tests(tests, this);
                break;
            case 'testsStatus':
                let testsStatus = this.checkMultiselect(this.peopleSearchFilter.optionsTestsStatusModel, 'statusMpoCol', this.translate.instant('people.commons.mpoStatus'));
                this.peopleSearchFilter.testsStatus(testsStatus, this);
                break;
            case 'testsCaiStatus':
                let testsCaiStatus = this.checkMultiselect(this.peopleSearchFilter.optionsTestsStatusRaModel, 'statusCaiCol', this.translate.instant('people.commons.caiStatus'));
                this.peopleSearchFilter.testsStatus(testsCaiStatus, this, true);
                break;
            case 'subAccount':
                let subAccounts = this.checkMultiselect(this.peopleSearchFilter.subAccountsOptionsModel, 'subAccountCol', this.translate.instant('commons.subAccount'));
                this.peopleSearchFilter.subAccount(subAccounts, this, this.peopleList.specificState);
                break;
            case 'department':
                let deps = this.checkMultiselect(this.peopleSearchFilter.departmentOptionsModel, 'depCol', this.translate.instant('commons.division'));
                this.peopleSearchFilter.onDepartmentOptionChange(deps, this, this.peopleList.specificState);
                break;
            case 'columnDisplay':
                this.peopleSearchFilter.columnDisplay(event, this, this.peopleList.specificState);
                break;
        }
        this.peopleList.getRecordList(true);
    }

    toggleListView() {
        this.peopleSearchFilter.toggleListView(this);
        this.peopleList.getRecordList(true);
    }

    sortCards() {
        this.peopleSearchFilter.sortCards();
        this.peopleList.getRecordList(true);
    }

    resetSearchFilters() {
        if (this.whereAmI === 'filter' && this.actualModule.name !== 'people') {
            const preferences = (this.userPreferencesService.isPreferenceSet('people')) ?
                this.stateService.session.preferences.people :
                null;
            this.resetSearchFiltersAction(preferences);
        } else {
            this.resetSearchFiltersAction();
            this.userPreferencesService.emptyUserPreferences('people', this.userDataId);
        }
        this.removeDropDownPlHolders();
        this.peopleList.getRecordList(true);
        this.changeDetectorRef.markForCheck();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
