<div *ngIf="surveyRequestFormGroup" [formGroup]="surveyRequestFormGroup">
    <div>
        <h4 class="georgia" style="margin-top: 10px">Options</h4>

        <!-- Satellite -->
        <div *ngIf="isSatelliteSelected$ | async">
            <div class="pull-left">
                <label for="jobIdField" class="field_title" [class.label-first]="isSatelliteSelected$ | async">
                    {{ 'people.requestModal.profilerAssociated' | translate }}
                </label>
            </div>

            <div class="clearfix"></div>

            <select id="jobIdField" class="form-control" formControlName="satelliteJobId">
                <option>{{ 'commons.select' | translate }}</option>
                <option *ngFor="let job of satelliteJobList; trackBy: trackById" [value]="job.id">
                    {{ job['jobTitle'] }}
                </option>
            </select>
        </div>

        <!-- Variant -->
        <div *ngIf="!isOnlyIACSelected" class="pull-left">
            <label for="variantField" class="field_title" [class.label-first]="!(isSatelliteSelected$ | async)"
                [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('variant').errors"
            >
                {{ 'commons.survey' | translate }}
            </label>
        </div>
        <div class="clearfix"></div>
        <select *ngIf="!isOnlyIACSelected" id="variantField" class="form-control" formControlName="variant">
            <option>{{ 'commons.select' | translate }}</option>
            <ng-container *ngFor="let variantOptions of variantOptions$ | async">
                <option [value]="variantOptions.value">
                    {{ variantOptions.name | translate }}
                </option>
            </ng-container>
        </select>

        <!-- Variant 0,1 -->
        <input type="hidden" formControlName="recordId" />
    </div>

    <div>
        <!-- Country -->
        <div class="pull-left">
            <label for="countryField" class="field_title"
                [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('country').errors"
            >
                {{ 'commons.country' | translate }}
            </label>
        </div>
        <div class="clearfix"></div>
        <select id="countryField" class="form-control" formControlName="country">
            <option>{{ 'commons.select' | translate }}</option>
            <option *ngFor="let cntry of countryList" [value]="cntry.value">
                {{cntry.name}}
            </option>
        </select>

    </div>

    <div>
        <!-- Lang -->
        <div class="pull-left">
            <label for="langField" class="field_title"
                [class.text-danger]="isFormSubmitted && (surveyRequestFormGroup.get('language').errors ||
                    noLanguageSelected)">
                {{ 'commons.language' | translate }}
            </label>
        </div>
        <div class="clearfix"></div>
        <select id="langField" class="form-control" formControlName="language">
            <option value="none">{{ 'commons.select' | translate }}</option>
            <option *ngFor="let lang of (langList$ | async)" [value]="lang.value">
                {{lang.name}}
            </option>
        </select>
    </div>
</div>
