<div id="wrapper">
    <!-- ngx-loading [show]="loading" [config]="{
            backdropBorderRadius: '6px',
            primaryColour: '#CDCDCD',
            secondaryColour: '#ABABAB',
            tertiaryColour: '#CDCDCD'
            }"></ngx-loading-->
    <div class="formWrapper">
        <h4 *ngIf="status!='edit'" class="modal-title">{{ 'jobSatisfactions.modal.newJobSatifaction' | translate }}</h4>
        <h4 *ngIf="status=='edit'" class="modal-title">{{ 'jobSatisfactions.modal.modJobSatifaction' | translate }}</h4>
        <div
            class="text-danger"
            *ngIf="formCheck()"
        >
            {{ 'commons.multiError' | translate }}
        </div>

        <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

        <form
            [formGroup]="jobSatisfactionForm"
            (ngSubmit)="onSubmit(jobSatisfactionForm)"
            (keydown.enter)="$event.preventDefault()"
              #jobSatisfactionNgForm="ngForm"
        >
            <div *ngIf="headerText">
                {{ headerText }}
            </div>
            <br/>

            <label for="jsName">{{ 'jobSatisfactions.modal.jobSatifactionName' | translate }}</label>
            <mat-form-field appearance="fill">
                <input matInput id="jsName" formControlName="name">
                <small *ngIf="validateFormField(jobSatisfactionForm.controls.name, jobSatisfactionNgForm, submitted)"
                       class="text-danger"
                >
                </small>
            </mat-form-field>

            <label>{{ 'commons.subAccount' | translate }}</label>
            <mat-form-field appearance="fill" *ngIf="sessionStructure">
                <mat-select formControlName="subAccount">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']"
                                [value]="subAcct.id">{{ subAcct.name }}</mat-option>
                </mat-select>
                <small
                    *ngIf="validateFormField(jobSatisfactionForm.controls.subAccount, jobSatisfactionNgForm, submitted)"
                    class="text-danger"
                >
                </small>
            </mat-form-field>

            <label for="consultant">{{ 'commons.requester' | translate }}</label>
            <mat-form-field appearance="fill">
                <input matInput id="consultant" formControlName="consultant">
            </mat-form-field>

            <label>{{ 'commons.division' | translate }}</label>
            <mat-form-field appearance="fill" *ngIf="departments">
                <mat-select formControlName="department">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{ dpt['fr'] }}</mat-option>
                </mat-select>
            </mat-form-field>

            <label for="searchTerm">{{ 'commons.searchPerson' | translate }}</label>
            <mat-form-field appearance="fill">
                <input
                    matInput
                    id="searchTerm"
                    formControlName="searchTerm"
                    (keydown)="onSearch($event, searchTerm, 'return')"
                    [(ngModel)]="searchTerm"
                >
            </mat-form-field>

            <div
                id="candidateList"
                *ngIf="records"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="500"
                [scrollWindow]="false"
                (scrolled)="onListScroll()"
            >
                <ngx-loading [show]="loading" [config]="{
                backdropBorderRadius: '0pt',
                backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
                primaryColour: '#CDCDCD',
                secondaryColour: '#ABABAB',
                tertiaryColour: '#CDCDCD'
            }"></ngx-loading>
                <table>
                    <tr *ngFor="let record of records; let i = index">
                        <td class="nameCell" style="padding: 4px">
                            <mat-checkbox
                                (change)="onChecked(record.id, $event)"
                                id="record_{{ i }}"
                                [checked]="selectedItems.indexOf(record.id) !== -1"
                            >
                                {{ record['firstName'] }} {{ record['lastName'] }}
                            </mat-checkbox>
                        </td>
                    </tr>
                </table>
            </div>
            <hr>
            <mat-grid-list cols="1" rowHeight="60px">
                <mat-grid-tile
                    colspan="1"
                    rowspan="1">
                    <button mat-stroked-button
                            type="button"
                            class="modalButton"
                            (click)="onCancel()"
                    >{{ 'commons.cancel' | translate }}</button>
                    <button mat-stroked-button
                            type="submit"
                            class="modalButton"
                            [disabled]="!jobSatisfactionForm.valid"
                    >
                    <span *ngIf="status !== 'edit'">
                    {{ 'commons.create' | translate }}
                    </span>
                        <span *ngIf="status === 'edit'">
                        {{ 'commons.save' | translate }}
                    </span>
                    </button>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
        <div style="padding-bottom: 55px">&nbsp;</div>
    </div>
</div>
