import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SetpassComponent} from './app/components/setpass/setpass.component';
import {ResetPassComponent} from './app/components/resetpass/resetpass.component';
import {LoginComponent} from './app/components/login/login.component';
import {MobileSupportComponent} from './app/components/support/mobile/mobile-support/mobile-support.component';
import {AuthGuardService} from './app/services/auth-guard.service';

const routes: Routes = [
    {
        path      : '',
        redirectTo: 'login',
        pathMatch : 'full'
    },
    {
        path      : 'login',
        component: LoginComponent
    },
    {
        path     : 'login/:lang',
        component: LoginComponent
    },
    {
        path     : 'login/:pw',
        component: LoginComponent
    },
    {
        path     : 'reset-pass',
        component: ResetPassComponent
    },
    {
        path     : 'reset-pass/:token',
        component: ResetPassComponent
    },
    {
        path     : 'set-pass',
        component: SetpassComponent
    },
    {
        path     : 'm/support',
        component: MobileSupportComponent,
        canActivate: [AuthGuardService]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
