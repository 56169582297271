<!-- DIT PRB selected -->
<!--div *ngIf="originReportType === 'ditprb' && 'this' === 'canceled as per Patrick`s request @ilya 2021-02-23'" style="position: absolute; width: 100%;">
    <div class="prbWarning" style="margin: 200px auto auto auto; background-color: white; width: 300px">
        <div class="prbWarningText">
            <p>
                {{ 'people.reportIdw.prbWarning.text' | translate }} <br />
                <a (click)="gotToCommunication()">{{ 'people.reportIdw.prbWarning.link' | translate }}</a>
            </p>
        </div>
    </div>
</div
-->
<div *ngIf="!profile && showFakeReport" style="position: absolute; width: 100%;">
    <div class="prbWarning" style="margin: 200px auto auto auto; background-color: white; width: 300px">
        <div class="prbWarningText">
            <p>
                {{ 'people.reportIdw.invalidRes' | translate }}
            </p>
        </div>
    </div>
</div>
<app-fake-report *ngIf="(!profile && showFakeReport) || originReportType === 'ditprb'"
                 [for]="'communication'"
></app-fake-report>

<div *ngIf="report && profile && originReportType !== 'ditprb'">
    <h2>{{ 'people.reportIdw.infoOnCommunication' | translate }}</h2>

    <ul class="plainList">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ person.communicationDateToDisplay }}</li>
        <li *ngIf="person.communicationInfoRequestedBy && person.communicationInfoRequestedBy.fullName">
            {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> {{ person.communicationInfoRequestedBy.fullName }}
        </li>
        <li *ngIf="person.communicationTimeSpent && person.communicationTimeSpent.size == 3">
            {{ 'people.commons.elapsedTime' | translate }}<span [innerHTML]="titleSep"></span>
            S1<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[0] }} -
            S2<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[1] }} -
            Total<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[2] }}
        </li>
        <li *ngIf="person.communicationTimeSpent && person.communicationTimeSpent.size == 5">
            {{ 'people.commons.time' | translate }}<span [innerHTML]="titleSep"></span>
            Total<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[4] }} -
            Section 1<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[0] }} -
            Section 2<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[1] }} -
            Section 3<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[2] }} -
            Section 4<span [innerHTML]="titleSep"></span> {{ person.communicationTimeSpent.data[3] }}
        </li>
        <li>{{ 'people.commons.norm' | translate }}<span [innerHTML]="titleSep"></span> {{ person.communicationNorm }}</li>
        <li *ngIf="person.communicationMethod && ((person.communicationMethod)==1 || (person.communicationMethod)==0)">
            {{ 'people.commons.method' | translate }}<span [innerHTML]="titleSep"></span>
            <span *ngIf="person.communicationMethod == 0"> {{ 'people.commons.paperQuestionnaire' | translate }}</span>
            <span *ngIf="person.communicationMethod

             == 1"> {{ 'people.commons.onlineQuestionnaire' | translate }}</span>
        </li>
    </ul>

    <hr/>

    <h2 style="margin-top: 15px">{{ 'commons.dit' | translate }}</h2>

    <!-- Profile -->
    <div *ngIf="profile">
        <h3 style="margin-top: 15px">{{ 'commons.communicationReport' | translate }}</h3>
        <hr/>
        <div id="portrait">
            <ng-template ngFor let-data [ngForOf]="profile" [ngForTrackBy]="trackByProfile">
                <div *ngIf="data">
                    <h4 *ngIf="data.title">
                        {{ data.title }}
                    </h4>
                    <table class="DITInteractionTitleWithImage" *ngIf="data.image">
                        <tr>
                            <td>
                                <app-mini-idw-graph
                                        [quad]="data.image.src"
                                        [type]="'paragraphHeader'"
                                ></app-mini-idw-graph>
                            </td>
                            <td class="DITInteractionTitleContainer"><span>&nbsp;&nbsp;{{ data.image.text }}</span></td>
                        </tr>
                    </table>
                    <p *ngIf="data.paragraph">{{ data.paragraph }}</p>
                    <ul *ngIf="data.list">
                        <li *ngFor="let puce of data.list">{{ puce }}</li>
                    </ul>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="warnings">
        <div>
            <div *ngIf="translate.currentLang=='fr'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicateurs de questionnaire invalide
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicateur de questionnaire invalide
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>L’indicateur de scores extrêmes est positif.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>L’indicateur d’endossement est positif.</em></p>
                <p>
                    La présence d’un ou deux indicateurs de questionnaire invalide indique qu’il est raisonnable
                    de remettre en doute la validité du résultat. Cependant, il revient à l’utilisateur de décider si
                    le questionnaire du candidat est valide ou si ce dernier doit le refaire.
                </p>
            </div>
            <div *ngIf="translate.currentLang=='en'">
                <h2 *ngIf="warnings['extremeScores'] &&
                           warnings['socialDesirability']; else singleWarning">
                    Indicators of an invalid questionnaire
                </h2>
                <ng-template #singleWarning>
                    <h2>
                        Indicator of an invalid questionnaire
                    </h2>
                </ng-template>
                <hr/>
                <p *ngIf="warnings['extremeScores']"><em>Extreme Scores Indicator is positive.</em></p>
                <p *ngIf="warnings['socialDesirability']"><em>Endorsement Indicator is positive.</em></p>
                <p>
                    The presence of an indicator indicates that it is reasonable to believe that the survey results are
                    invalid. However, the analyst must decide whether the candidate’s questionnaire is valid or if
                    it must be redone.
                </p>
            </div>
        </div>
        <div style="height: 40px">&nbsp;</div>
    </div>
</div>

<button  (click)="moveTo('top')">
    <span id="upButton">
        <img src="assets/buttons/chevronUp.png" alt=""/>
    </span>
</button>
