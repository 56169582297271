<!-- set report -->
<div class="modal-header">
    <h4 id="modalTitle" class="modal-title pull-left fontLight">
        {{ 'commons.sharePdf' | translate }}
    </h4>
    <div
        (click)="closeModal()" aria-label="Close" class="x-close pull-right"
        style="font-size: 20pt;"
    >&#x00D7;
    </div>
</div>
<div *ngIf="share=='none'">
    <div class="modal-body">
        <div class="pull-left panel">
            <!-- options -->
            <h4 *ngIf="!stateSession.hasSatelliteRole">{{ 'commons.options' | translate }}</h4>
            <div *ngIf="!stateSession.hasSatelliteRole">
                <h5>{{ 'commons.report' | translate }}</h5>
                <div>
                    <div *ngIf="person.hasPersonality">
                        <input id="report_personality" type="radio"
                               [attr.disabled]="(testAccessPermissions['mpo']) ? null : true"
                               [(ngModel)]="pdfStructure.report"
                               (change)="reportChange()" value="personality" name="personality"
                               [checked]="pdfStructure.report=='personality'"/>&nbsp;
                        <label for="report_personality">{{ 'commons.personality' | translate }}</label>
                    </div>
                </div>
                <div>
                    <div *ngIf="person.hasSatellite">
                        <input id="report_satellite" type="radio"
                               [attr.disabled]="(testAccessPermissions['satellite']) ? null : true"
                               [(ngModel)]="pdfStructure.report"
                               (change)="reportChange()" value="satellite" name="satellite"
                               [checked]="pdfStructure.report=='satellite'"/>&nbsp;
                        <label for="report_satellite">Satellite</label>
                    </div>
                </div>
                <div>
                    <div *ngIf="person.hasCommunication">
                        <input id="report_communication" type="radio"
                               [attr.disabled]="(testAccessPermissions['dit']) ? null : true"
                               [(ngModel)]="pdfStructure.report"
                               (change)="reportChange()" value="communication" name="communication"
                               [checked]="pdfStructure.report=='communication'"/>&nbsp;
                        <label for="report_communication">{{ 'commons.communication' | translate }}</label>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <div>
                    <div *ngIf="person.hasTalents">
                        <input id="report_talents" type="radio"
                               [attr.disabled]="(testAccessPermissions['talents']) ? null : true"
                               [(ngModel)]="pdfStructure.report"
                               (change)="reportChange()" value="talents" name="talents"
                               [checked]="pdfStructure.report=='talents'"/>&nbsp;
                        <label for="report_talents">{{ 'commons.talents' | translate }}</label>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                <div>
                    <div *ngIf="person.hasIac && raPermission">
                        <input id="report_iac" type="radio"
                               [attr.disabled]="(raPermission) ? null : true"
                               [(ngModel)]="pdfStructure.report"
                               (change)="reportChange()" value="iac" name="iac"
                               [checked]="pdfStructure.report=='iac'"/>&nbsp;
                        <label for="report_iac">{{ 'commons.iac' | translate }}</label>&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
            </div>
            <div *ngIf="!stateSession.hasSatelliteRole && pdfStructure.report=='personality'">
                <h5>{{ 'people.pdfModal.reportType' | translate }}</h5>
                <span>
                <input id="analyst" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="analyst"
                       (change)="sideMenuChanges(person.jobId, 'analyst')"
                       name="analyst" [checked]="pdfStructure['reportType'] == 'analyst'"/>&nbsp;<label for="analyst">{{
                    'commons.accreditedAnalysts' | translate }}</label>
                </span><br>
                <span>
                <input id="manager" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="manager"
                       (change)="sideMenuChanges(person.jobId, 'manager')"
                       name="manager"/>&nbsp;<label for="manager">{{ 'commons.manager' | translate
                    }}</label>
                </span><br>
                <span>
                <input id="respondent" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="respondent"
                       (change)="sideMenuChanges(person.jobId, 'respondent')"
                       name="respondent"/>&nbsp;<label for="respondent">{{ 'commons.respondent' | translate
                    }}</label>
                </span>
            </div>
            <div *ngIf="!stateSession.hasSatelliteRole && pdfStructure.report=='talents'">
                <h5>{{ 'people.pdfModal.reportType' | translate }}</h5>
                <span>
                <input id="analystTalent" type="radio" [(ngModel)]="reportTypeTalent"
                       value="analyst"
                       (change)="sideMenuChanges(person.jobId)"
                       name="analyst" [checked]="pdfStructure['reportType'] == 'analyst'"/>&nbsp;<label for="managerTalent">{{
                    'commons.accreditedAnalysts' | translate }}</label>
                </span><br>
                <span>
                <input id="managerTalent" type="radio" [(ngModel)]="reportTypeTalent"
                       value="manager"
                       (change)="sideMenuChanges(person.jobId)"
                       name="analyst"/>&nbsp;<label for="managerTalent">{{ 'commons.manager' | translate
                    }}</label>
                </span><br>
                <span>
                <input id="respondentTalent" type="radio" [(ngModel)]="reportTypeTalent"
                       value="respondent"
                       (change)="sideMenuChanges(person.jobId)"
                       name="analyst"/>&nbsp;<label for="respondentTalent">{{ 'commons.respondent' | translate
                    }}</label>
                </span>
            </div>
        </div>

        <div class="pull-right panel">
            <!-- reports -->
            <h4 *ngIf="!stateSession.hasSatelliteRole">{{ 'commons.reports' | translate }}</h4>

            <div *ngIf="!stateSession.hasSatelliteRole">
                <!-- Personality -->
                <div *ngIf="pdfStructure.report=='personality'">
                    <h5>{{ 'commons.personalityReportSections' | translate }}</h5>
                    <div class="insideCheckboxes">
                        <input id="personalityReport" type="hidden" [(ngModel)]="pdfStructure['personality'].on"
                               value="1"
                        />
                        <mat-checkbox id="personalityReportHomepage"
                                      [(ngModel)]="pdfStructure['personality']['homepage']"
                                      [disabled]="pdfStructure['reportType'] != 'analyst' || pdfStructure['reportType'] != 'analyst' || graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.coverPage' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportWarning"
                                      [(ngModel)]="pdfStructure['personality'].warning"
                                      [disabled]="pdfStructure['reportType'] != 'analyst' || pdfStructure['reportType'] != 'analyst' || graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.warning' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportIndex"
                                      [(ngModel)]="pdfStructure['personality'].index"
                                      [disabled]="graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.traitSummary' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportBehavior"
                                      [(ngModel)]="pdfStructure['personality'].behavior"
                                      [disabled]="graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.predictableBehavior' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportPortrait"
                                      [(ngModel)]="pdfStructure['personality']['portrait']"
                                      [disabled]="graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.portrait' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="extraPoints"
                                      [(ngModel)]="pdfStructure['personality']['extraPoints']"
                                      [disabled]="graphOnly"
                                      value="1"
                        >{{ 'people.pdfModal.extraPoints' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportPRB"
                                      [(ngModel)]="pdfStructure['personality']['prb']"
                                      [disabled]="graphOnly || !person.hasPrb"
                                      value="1"
                        >{{ 'people.pdfModal.prb' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="personalityReportGraph"
                                      [(ngModel)]="pdfStructure['personality']['graph']"
                                      [disabled]="(pdfStructure['reportType'] != 'analyst') ||
                                        graphOnly"
                                      value="1"
                        >{{ 'commons.graphs' | translate }}
                        </mat-checkbox>
                        <br/>
                        &nbsp;<br/>

                        <mat-checkbox id="personalityReportGraphOnly"
                                      [(ngModel)]="graphOnly"
                                      [disabled]="pdfStructure['reportType'] != 'analyst'"
                                      (change)="toggleGraphOnlyChk()"
                                      value="1"
                        >{{ 'commons.graphsOnly' | translate }}
                        </mat-checkbox>

                    </div>
                </div>

                <div *ngIf="pdfStructure.report=='talents'" class="insideCheckboxes">
                    <h5>{{ 'commons.talentsReportSections' | translate }}</h5>
                    <div class="insideCheckboxes">
                        <input id="talentsReport" type="hidden" [(ngModel)]="pdfStructure['talents'].on" value="1"
                        />

                        <mat-checkbox id="homepageTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['homepage']"
                                      [disabled]="pdfStructure['reportType'] != '1'"
                                      value="1"
                        >{{ 'people.pdfModal.coverPage' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="presentationTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['presentation']"
                                      [disabled]="pdfStructure['reportType'] != '1'"
                                      value="1"
                        >{{ 'people.pdfModal.presentation' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="interpretationTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['interpretation']"
                                      [disabled]="pdfStructure['reportType'] != '1'"
                                      value="1"
                        >{{ 'people.pdfModal.interpretation' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="generalViewTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['generalView']"
                                      value="1"
                        >{{ 'people.pdfModal.generalView' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="aptitudeSpheresTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['aptitudeSpheres']"
                                      value="1"
                        >{{ 'people.pdfModal.aptitudeSpheres' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="compReportTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['compReport']"
                                      value="1"
                        >{{ 'people.pdfModal.compReport' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="indexTalentsReport"
                                      [(ngModel)]="pdfStructure['talents'].index"
                                      value="1"
                        >{{ 'people.pdfModal.index' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="jobVsCandidateTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['jobVsCandidate']"
                                      [checked]="hasProfilerAndIsAnalyst"
                                      [disabled]="!hasProfilerAndIsAnalyst"
                        >{{ 'people.pdfModal.jobVsCandidate' | translate }}
                        </mat-checkbox>
                        <br/>

                        <mat-checkbox id="interviewQuestionsTalentsReport"
                                      [(ngModel)]="pdfStructure['talents']['interviewQuestions']"
                                      [checked]="hasProfilerAndIsAnalyst"
                                      [disabled]="!hasProfilerAndIsAnalyst"
                        >{{ 'people.pdfModal.interviewQuestions' | translate }}
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <!-- Satellite -->
            <div *ngIf="pdfStructure.report=='satellite'" class="insideCheckboxes">
                <h5>{{ 'commons.satelliteReportSections' | translate }}</h5>
                <input id="satelliteReport" type="hidden" [(ngModel)]="pdfStructure['satellite'].on" value="1"/>

                <mat-checkbox id="satelliteGraph"
                              [(ngModel)]="pdfStructure['satellite']['graph']"
                              value="1"
                >{{ 'commons.graphs' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="satelliteJobDesc"
                              [(ngModel)]="pdfStructure['satellite']['jobDescription']"
                              value="1"
                >{{ 'people.pdfModal.jobDescription' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="satellitePersProf"
                              [(ngModel)]="pdfStructure['satellite']['personalityProfile']"
                              value="1"
                >{{ 'people.pdfModal.personalityProfile' | translate}}
                </mat-checkbox>
            </div>
            <div class="insideCheckboxes">
                <h5
                    [class.text-danger]="selectMethod"
                >{{ 'commons.sendingMethod' | translate }}</h5>
                <label>
                    <select [(ngModel)]="method"
                            (change)="changeSelectMethodState()"
                    >
                        <option>{{ 'commons.select' | translate }}</option>
                        <option value="pdf">{{ 'commons.pdfReport' | translate }}</option>
                        <option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</option>
                        <option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</option>
                        <option value="server">{{ 'commons.sendByServer' | translate }}</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="handleReport()">{{ 'commons.continue' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
<!-- share mode -->
<!-- server -->
<div *ngIf="share=='server'">
    <div class="modal-body">
        <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

        <div>
            <h3><b>Message</b></h3>
            <div class="form-group">
                <div class="sendContent">
                    <table style="width: 100%">
                        <tr>
                            <td style="width: 75px; font-weight: bold">
                                <label for="reportEmailEmailTo">
                                    {{ 'commons.email' | translate }}
                                </label>
                            </td>
                            <td><input
                                id="reportEmailEmailTo"
                                type="text"
                                style="width: 250px"
                                [(ngModel)]="pdfEmail['emailTo']"/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' |
                                translate }}
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 75px; font-weight: bold">
                                <label for="reportEmailSubject">
                                    {{ 'commons.emailSubject' | translate }}
                                </label>
                            </td>
                            <td><input
                                id="reportEmailSubject"
                                type="text"
                                style="width: 250px"
                                [(ngModel)]="pdfEmail.subject"
                            />
                            </td>
                        </tr>
                    </table>
                    <table style="width: 100%; margin-top: 25px">
                        <tr>
                            <td style="padding-bottom: 7px; font-weight: bold">
                                <label for="reportEmailMessage">
                                    <span class="field_title">{{ 'commons.message' | translate }}</span>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea
                                    id="reportEmailMessage"
                                    rows="7"
                                    style="border: 1px solid #ccc; width: 100%; height: 100px"
                                    [(ngModel)]="pdfEmail.message"></textarea>
                            </td>
                        </tr>
                    </table>
                    <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                    <div id="innerTextServer">
                        <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                        <p>{{ pdfEmail.message }}</p>
                        <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                        <p *ngIf="pdfReportUrl"
                           class="forceBreaks"
                           [innerHTML]="serverText"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="sendEmailReport()">{{ 'commons.shareReport' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- mailto -->
<div *ngIf="share=='mailto'">
    <div *ngIf="mailto" class="modal-body">
        <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
        <p> {{ 'commons.sendByYourEmailReportInstructions' | translate }} <a href="mailto:{{ mailto }}"
                                                                             target="_blank">{{
            'commons.here' | translate }}</a>.</p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- cutnpaste-->
<div *ngIf="share=='cutpaste'">
    <div *ngIf="pdfReportUrl" class="modal-body">
        <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
        <p
            [translate]="'people.pdfModal.linkInstruction'"
            [translateParams]="{
                'reportName': reportName,
                'firstName': person.firstName,
                'lastName': person.lastName
            }"
        ></p>
        <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
