import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GraphboardsListComponent} from './components/web/graphboards-list/graphboards-list.component';
import {GraphboardsSearchFiltersComponent} from './components/web/graphboards-search-filters/graphboards-search-filters.component';
import {GraphboardsReportComponent} from './components/web/graphboards-report/graphboards-report.component';
import {GraphboardsSidebarComponent} from './components/web/graphboards-sidebar/graphboards-sidebar.component';
import {DragulaModule} from 'ng2-dragula';
import {ApiGraphboardsService} from './services/api-graphboards.service';
import {MainListGraphboardsService} from './services/main-list-graphboards.service';
import {SharedModule} from '../../shared/shared.module';
import {MyDatePickerModule} from 'mydatepicker';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {GraphboardsModalComponent} from './components/web/graphboards-modal/graphboards-modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GraphboardsPersonModalComponent} from './components/web/graphboards-person-modal/graphboards-person-modal.component';
import {AnalysisRoutingModule} from '../analysis-routing.module';
import {GraphboardsSelectedItemsComponent} from './components/web/graphboards-selected-items/graphboards-selected-items.component';
import { LoadingModule } from 'ngx-loading';
import {TranslateModule} from '@ngx-translate/core';
import { GraphboardsPdfModalComponent } from './components/web/graphboards-pdf-modal/graphboards-pdf-modal.component';
import { SidebarModule } from 'ng-sidebar';
import {MobileGraphboardsListComponent} from './components/mobile/mobile-graphboards-list/mobile-graphboards-list.component';
import {MobileGraphboardsReportComponent} from './components/mobile/mobile-graphboards-report/mobile-graphboards-report.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {PeopleModule} from '../../people/people.module';
import {MatButtonModule} from '@angular/material/button';
import {AnalysisSharedModule} from '../shared/analysis-shared.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import { MobileGraphboardsPersonAddingComponent } from './components/mobile/mobile-graphboards-person-adding/mobile-graphboards-person-adding.component';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MobileGraphboardsPdfComponent } from './components/mobile/mobile-graphboards-pdf/mobile-graphboards-pdf.component';
import { MobileGraphboardsEditComponent } from './components/mobile/mobile-graphboards-edit/mobile-graphboards-edit.component';


@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        DragulaModule,
        ModalModule,
        FormsModule,
        ReactiveFormsModule,
        MyDatePickerModule,
        NgMultiSelectDropDownModule,
        AnalysisRoutingModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        LoadingModule,
        TranslateModule,
        SidebarModule.forRoot(),
        MatGridListModule,
        PeopleModule,
        MatButtonModule,
        AnalysisSharedModule,
        MatTabsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule
    ],
    declarations: [GraphboardsListComponent, GraphboardsSearchFiltersComponent, GraphboardsReportComponent,
        GraphboardsSidebarComponent,
        GraphboardsModalComponent,
        GraphboardsPersonModalComponent,
        GraphboardsSelectedItemsComponent,
        GraphboardsPdfModalComponent,
        MobileGraphboardsListComponent,
        MobileGraphboardsReportComponent,
        MobileGraphboardsPersonAddingComponent,
        MobileGraphboardsPdfComponent,
        MobileGraphboardsEditComponent],
    providers: [ApiGraphboardsService, MainListGraphboardsService],
    exports: [
        GraphboardsSearchFiltersComponent,
        GraphboardsSidebarComponent,
        GraphboardsListComponent,
        MobileGraphboardsListComponent
    ],
    entryComponents: [ GraphboardsPersonModalComponent, GraphboardsPdfModalComponent, GraphboardsModalComponent ]
})
export class GraphboardsModule {
}
