<h2 class="sharePdfTitle">
    {{ 'commons.sharePdf' | translate }}
</h2>
<mat-grid-list cols="11" rowHeight="35px">
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <h4 *ngIf="!stateService.session.hasSatelliteRole"
            class="alignLeftTop"
        >{{ 'commons.options' | translate }}</h4>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="7"
        rowspan="1"
    >
        <h4 *ngIf="!stateService.session.hasSatelliteRole && pdfStructure.report !== 'communication'"
            class="alignLeftTop"
        >{{ 'commons.reports' | translate }}</h4>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <h5 *ngIf="!stateService.session.hasSatelliteRole"
            class="alignLeftTop"
        >{{ 'commons.report' | translate }}</h5>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="7"
        rowspan="1"
    >
        <h5 *ngIf="pdfStructure.report=='personality'"
            class="alignLeftTop"
        >{{ 'commons.personalityReportSections' | translate }}</h5>
        <h5 *ngIf="pdfStructure.report=='talents'"
            class="alignLeftTop"
        >{{ 'commons.talentsReportSections' | translate }}</h5>
        <h5 *ngIf="pdfStructure.report=='satellite'"
            class="alignLeftTop"
        >{{ 'commons.satelliteReportSections' | translate }}</h5>
        <h5 *ngIf="pdfStructure.report=='iac' && stateService.hasIac"
            class="alignLeftTop"
        >{{ 'commons.iacReportSections' | translate }}</h5>
    </mat-grid-tile>
</mat-grid-list>
<mat-grid-list cols="11" rowHeight="300px">
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <!-- options -->
        <div *ngIf="!stateService.session.hasSatelliteRole" class="alignLeftTop">
            <div *ngIf="person.hasPersonality">
                <input id="report_mpo" type="radio"
                       [attr.disabled]="showProperty(testAccessPermissions, 'mpo') ? null : true"
                       [(ngModel)]="pdfStructure.report"
                       (change)="reportChange()" value="personality" name="mpo"
                       [checked]="pdfStructure.report=='personality'"/>&nbsp;
                <label for="report_mpo">{{ 'commons.personality' | translate }}</label>
            </div>
            <div>
                <div *ngIf="person.hasSatellite">
                    <input id="report_satellite" type="radio"
                           [attr.disabled]="showProperty(testAccessPermissions, 'satellite') ? null : true"
                           [(ngModel)]="pdfStructure.report"
                           (change)="reportChange()" value="satellite"
                           name="satellite" [checked]="pdfStructure.report=='satellite'"/>&nbsp;
                    <label for="report_satellite">Satellite</label>
                </div>
            </div>
            <div>
                <div *ngIf="person.hasCommunication">
                    <input id="report_communication" type="radio"
                           [attr.disabled]="showProperty(testAccessPermissions, 'dit') ? null : true"
                           [(ngModel)]="pdfStructure.report"
                           (change)="reportChange()" value="communication" name="communication"
                           [checked]="pdfStructure.report=='communication'"/>&nbsp;
                    <label for="report_communication">{{ 'commons.communication' | translate }}</label>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div>
                <div *ngIf="person.hasTalents">
                    <input id="report_talents" type="radio"
                           [attr.disabled]="showProperty(testAccessPermissions, 'talents') ? null : true"
                           [(ngModel)]="pdfStructure.report"
                           (change)="reportChange()" value="talents" name="talents"
                           [checked]="pdfStructure.report=='talents'"/>&nbsp;
                    <label for="report_talents">{{ 'commons.talents' | translate }}</label>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <div>
                <div *ngIf="person.hasIac && stateService.hasIac">
                    <input id="report_iac" type="radio" class="topM7"
                           [attr.disabled]="showProperty(testAccessPermissions, 'ra') ? null : true"
                           [(ngModel)]="pdfStructure.report"
                           (change)="reportChange()" value="iac" name="iac"
                           [checked]="pdfStructure.report=='iac'"/>&nbsp;
                    <label for="report_iac">{{ 'commons.iac' | translate }}</label>&nbsp;&nbsp;&nbsp;
                </div>
            </div>
            <br/>
            <div
                *ngIf="!stateService.session.hasSatelliteRole && (pdfStructure.report=='personality' || pdfStructure.report=='talents')">
                <h5>{{ 'people.pdfModal.reportType' | translate }}</h5>
                <span>
                <input id="analyst" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="analyst"
                       (change)="sideMenuChanges(person.jobId, 'analyst')"
                       name="analyst" [checked]="pdfStructure['reportType'] == 'analyst'"/>&nbsp;<label for="analyst">{{
                    'commons.accreditedAnalysts' | translate }}</label>
                </span><br>
                <span>
                <input id="manager" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="manager"
                       (change)="sideMenuChanges(person.jobId, 'manager')"
                       name="manager"/>&nbsp;<label for="manager">{{ 'commons.manager' | translate
                    }}</label>
                </span><br>
                <span>
                <input id="respondent" type="radio" [(ngModel)]="pdfStructure['reportType']"
                       value="respondent"
                       (change)="sideMenuChanges(person.jobId, 'respondent')"
                       name="respondent"/>&nbsp;<label for="respondent">{{ 'commons.respondent' | translate
                    }}</label>
                </span>
            </div>
        </div>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="7"
        rowspan="1"
    >
        <!-- reports -->
        <div *ngIf="!stateService.session.hasSatelliteRole" class="alignLeftTop">
            <!-- MPO -->
            <div *ngIf="pdfStructure.report=='personality' && person.hasPersonality">
                <div class="insideCheckboxes">
                    <input id="mpoReport" type="hidden" [(ngModel)]="pdfStructure['personality'].on" value="1"
                    />
                    <mat-checkbox id="personalityReportHomepage"
                                  [(ngModel)]="pdfStructure['personality']['homepage']"
                                  [disabled]="pdfStructure['analyst'] != '1' || graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.coverPage' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportWarning"
                                  [(ngModel)]="pdfStructure['personality'].warning"
                                  [disabled]="pdfStructure['analyst'] != '1' || graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.warning' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportIndex"
                                  [(ngModel)]="pdfStructure['personality'].index"
                                  [disabled]="graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.traitSummary' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportBehavior"
                                  [(ngModel)]="pdfStructure['personality'].behavior"
                                  [disabled]="graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.predictableBehavior' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportPortrait"
                                  [(ngModel)]="pdfStructure['personality']['portrait']"
                                  [disabled]="graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.portrait' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="extraPoints"
                                  [(ngModel)]="pdfStructure['personality']['extraPoints']"
                                  [disabled]="graphOnly"
                                  value="1"
                    >{{ 'people.pdfModal.extraPoints' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportPRB"
                                  [(ngModel)]="pdfStructure['personality']['prb']"
                                  [disabled]="graphOnly || !person.hasPrb"
                                  value="1"
                    >{{ 'people.pdfModal.prb' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="personalityReportGraph"
                                  [(ngModel)]="pdfStructure['personality']['graph']"
                                  [disabled]="pdfStructure['analyst'] != '1' || graphOnly"
                                  value="1"
                    >{{ 'commons.graphs' | translate }}
                    </mat-checkbox>
                    <br/>
                    &nbsp;<br/>
                    <mat-checkbox id="personalityReportGraphOnly"
                                  [(ngModel)]="graphOnly"
                                  [disabled]="pdfStructure['analyst'] != '1'"
                                  (change)="toggleGraphOnlyChk()"
                                  value="1"
                    >{{ 'commons.graphsOnly' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div *ngIf="pdfStructure.report=='talents' && person.hasTalents" class="insideCheckboxes">
                <div class="insideCheckboxes">
                    <input id="talentsReport" type="hidden" [(ngModel)]="pdfStructure['talents'].on" value="1"
                    />
                    <mat-checkbox id="homepageTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['homepage']"
                                  [disabled]="pdfStructure['analyst'] != '1'"
                                  value="1"
                    >{{ 'people.pdfModal.coverPage' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="presentationTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['presentation']"
                                  [disabled]="pdfStructure['analyst'] != '1'"
                                  value="1"
                    >{{ 'people.pdfModal.presentation' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="interpretationTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['interpretation']"
                                  [disabled]="pdfStructure['analyst'] != '1'"
                                  value="1"
                    >{{ 'people.pdfModal.interpretation' | translate }}
                    </mat-checkbox>

                    <mat-checkbox id="generalViewTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['generalView']"
                                  value="1"
                    >{{ 'people.pdfModal.generalView' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="aptitudeSpheresTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['aptitudeSpheres']"
                                  value="1"
                    >{{ 'people.pdfModal.aptitudeSpheres' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="compReportTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['compReport']"
                                  value="1"
                    >{{ 'people.pdfModal.compReport' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="indexTalentsReport"
                                  [(ngModel)]="pdfStructure['talents'].index"
                                  value="1"
                    >{{ 'people.pdfModal.index' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="jobVsCandidateTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['jobVsCandidate']"
                                  [checked]="hasProfilerAndIsAnalyst"
                                  [disabled]="!hasProfilerAndIsAnalyst"
                    >{{ 'people.pdfModal.jobVsCandidate' | translate }}
                    </mat-checkbox>
                    <br/>

                    <mat-checkbox id="interviewQuestionsTalentsReport"
                                  [(ngModel)]="pdfStructure['talents']['interviewQuestions']"
                                  [checked]="hasProfilerAndIsAnalyst"
                                  [disabled]="!hasProfilerAndIsAnalyst"
                    >{{ 'people.pdfModal.interviewQuestions' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <!-- Satellite -->
            <div *ngIf="pdfStructure.report=='satellite' && person.hasSatellite" class="insideCheckboxes">
                <input id="satelliteReport" type="hidden" [(ngModel)]="pdfStructure['satellite'].on" value="1"/>

                <mat-checkbox id="satelliteGraph"
                              [(ngModel)]="pdfStructure['satellite']['graph']"
                              value="1"
                >{{ 'commons.graphs' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="satelliteJobDesc"
                              [(ngModel)]="pdfStructure['satellite']['jobDescription']"
                              value="1"
                >{{ 'people.pdfModal.jobDescription' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="satellitePersProf"
                              [(ngModel)]="pdfStructure['satellite']['personalityProfile']"
                              value="1"
                >{{ 'people.pdfModal.personalityProfile' | translate}}
                </mat-checkbox>
            </div>
            <div *ngIf="pdfStructure.report=='iac' && person.hasIac && stateService.hasIac" class="insideCheckboxes">
                <input id="iacReport" type="hidden" [(ngModel)]="pdfStructure['iac'].on" value="1"/>
                <mat-checkbox id="iacReportHomepage"
                              [(ngModel)]="pdfStructure['iac']['homepage']"
                              value="1"
                >{{ 'people.pdfModal.coverPage' | translate }}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="iacReportWarning"
                              [(ngModel)]="pdfStructure['iac'].warning"
                              value="1"
                >{{ 'people.pdfModal.warning' | translate}}
                </mat-checkbox>
                <br/>

                <mat-checkbox id="iacReportResults"
                              [(ngModel)]="pdfStructure['iac'].results"
                              value="1"
                >{{ 'people.pdfModal.results' | translate}}
                </mat-checkbox>
            </div>
        </div>
    </mat-grid-tile>
</mat-grid-list>
<mat-grid-list cols="11" rowHeight="62px">
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        &nbsp;
    </mat-grid-tile>
    <mat-grid-tile
        colspan="7"
        rowspan="1"
    >
        <button
            mat-stroked-button
            (click)="cancel()"
            class="modalButton"
        >{{ 'commons.cancel' | translate }}
        </button> &nbsp;
        <button
            mat-stroked-button
            [disabled]="!readyToContinue"
            (click)="handleReport()"
            class="modalButton"
        >{{ 'commons.continue' | translate }}
        </button>
    </mat-grid-tile>
</mat-grid-list>
