<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'jobs.profilerEmailModal.sendProfiler' | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<ng-container *ngIf="toggle == 1" class="reqContent" [@fadeInOut]>
    <div class="modal-body">
        <h3><label for="sendingMode">{{ 'commons.sendingModeSelection' | translate }}</label></h3>
        <select id="sendingMode" [(ngModel)]="flipValue" (change)="toggleFlip(flipValue)" class="form-control">
            <option value="1">{{ 'commons.select' | translate }}</option>
            <option value="2">{{ 'commons.sendByMyEmail' | translate }}</option>
            <option value="3">{{ 'commons.sendByServer' | translate }}</option>
            <option value="4">{{ 'commons.cutnpaste' | translate }}</option>
        </select>
    </div>
    <div class="modal-footer">&nbsp;</div>
</ng-container>
<ng-container *ngIf="toggle == 2" [@fadeInOut]>
    <div class="modal-body">
        <div *ngIf="profilerEmailData" class="modal-body">
            <div style="padding-top: 20px">
                <h3><b>{{ 'commons.sendByMyEmail' | translate }}</b></h3>
                <p>{{ 'commons.sendByYourEmailInstructions_client_1' | translate }}
                    <span style="font-size: 1.3em; color: red">{{ 'commons.sendByYourEmailInstructions_client_2' | translate }}</span>&nbsp;
                    <a style="font-size: 1.3em; color: red"
                       href="mailto:?subject={{'jobs.sidebar.targetPersonalityProfileSubject' | translate}}&body={{ mailToGeneration() }}"
                       class="bbuttonHover" target="_blank"> {{ 'commons.here' | translate }}
                    </a>.
                </p>
                <p class="mailtoWarning" style="color: darkred">
                    {{ 'commons.mailtoWarning' | translate }}
                </p>
            </div>
        </div>
    </div>
    <div class="modal-footer">

        <div class="pull-right" style="margin-left: 25px">
            <button (click)="finishSending()" class="modalButton">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-container>
<ng-container *ngIf="toggle == 3" [@fadeInOut]>
    <form  [formGroup]="jobProfilerEmailNextForm" (ngSubmit)="onSubmitEmailNext(jobProfilerEmailNextForm)">
        <div *ngIf="profilerEmailData" class="modal-body">
            <h3><b>{{ 'commons.sendByNgenioServer' | translate }}</b></h3>
            <div class="form-group">
                <div>
                    <div class="pull-left">
                        <span class="field_title"><label for="emailTo">{{ 'commons.email' | translate }}</label></span>
                    </div>
                    <div class="pull-right">
                        <small *ngIf="blurred && (jobProfilerEmailNextForm.controls.emailTo.dirty ||
                                            jobProfilerEmailNextForm.controls.emailTo.touched)"
                               class="text-danger"
                        >
                                    <span *ngIf="jobProfilerEmailNextForm.hasError('invalidEmail')">
                                        {{ 'commons.invalidEmail' | translate }}
                                    </span>
                        </small>
                    </div>
                    <div class="clearfix"></div>
                    <input (blur)="blurred = true" type="text" id="emailTo" class="form-control" formControlName="emailTo">
                </div>
                <div>
                    <span class="field_title"><label for="emailMessage">Message</label></span><br/>
                    <textarea
                            style="border: 1px solid #ccc; width: 100%; height: 317px"
                            id="emailMessage"
                            formControlName="message"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button type="submit" class="modalButton pull-right"
                        [disabled]="!jobProfilerEmailNextForm.valid">
                    {{ 'commons.send' | translate }}
                </button>
            </div>
            <div style="margin-top: 10px">
                <button type="button" class="modalButton pull-right" style="margin-right: 15px"
                        (click)="closeModal()">
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </form>
</ng-container>
<ng-container *ngIf="toggle == 4" [@fadeInOut]>
    <div *ngIf="profilerEmailData" class="modal-body" [innerHTML]="cutNPasteGeneration()"></div>
    <div class="modal-footer">
        <div style="margin-top: 15px">
            <div class="pull-right" style="margin-left: 25px">
                <button (click)="finishSending()" class="modalButton">{{ 'commons.save' | translate }}</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</ng-container>
