<div *ngIf="resendData" class="formWrapper">
    <h4 class="modal-title">{{ 'commons.resendAccessCode' | translate }}</h4>
    <mat-form-field class="full-width">
        <h3>{{ 'commons.sendingModeSelection' | translate }}</h3>
        <mat-select [(ngModel)]="flipValue"
                    (ngModelChange)="toggleFlip(flipValue)"
        >
            <mat-option value="1">{{ 'commons.select' | translate }}</mat-option>
            <mat-option *ngIf="language !== 'ar'" value="2">{{ 'commons.sendByMyEmail' | translate }}</mat-option>
            <mat-option value="3">{{ 'commons.sendByServer' | translate }}</mat-option>
            <mat-option value="4">{{ 'commons.cutnpaste' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="jobsProfilerFooter justifyRight">
        <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile colspan="1" rowspan="1">
                <button
                    mat-button
                    type="button"
                    class="modalButton"
                    aria-label="Clear"
                    (click)="closeForm()"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
