<ngx-loading [show]="loading" [config]="{
            backdropBorderRadius: '6px',
            primaryColour: '#CDCDCD',
            secondaryColour: '#ABABAB',
            tertiaryColour: '#CDCDCD'
            }"></ngx-loading>
<div class="formWrapper">
    <h4 *ngIf="status!='edit'" class="modal-title">{{ 'rms.modal.newRightMatch' | translate }}</h4>
    <h4 *ngIf="status=='edit'" class="modal-title">{{ 'rms.modal.modRightMatch' | translate }}</h4>
    <div
        class="text-danger"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>

    <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

    <form [formGroup]="rightMatchForm" (ngSubmit)="onSubmit(rightMatchForm)" #rightMatchNgForm="ngForm">
        <div *ngIf="headerText">
            {{ headerText }}
        </div>
        <br />

        <label for="rightMatchName">{{ 'rms.modal.rightMatchName' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="rightMatchName" formControlName="name">
            <small *ngIf="validateFormField(rightMatchForm.controls.name, rightMatchNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label>{{ 'commons.subAccount' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="sessionStructure">
            <mat-select formControlName="subAccount">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">{{ subAcct.name }}</mat-option>
            </mat-select>
            <small *ngIf="validateFormField(rightMatchForm.controls.subAccount, rightMatchNgForm, submitted)"
                   class="text-danger"
            >
            </small>
        </mat-form-field>

        <label for="consultant">{{ 'commons.requester' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="consultant" formControlName="consultant">
        </mat-form-field>

        <label>{{ 'rms.commons.jobProfile' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="profilers">
            <mat-select formControlName="profiler">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let prof of profilers" [value]="prof.id">{{ prof['jobTitle'] }}</mat-option>
            </mat-select>
        </mat-form-field>

        <label>{{ 'commons.division' | translate }}</label>
        <mat-form-field appearance="fill" *ngIf="departments">
            <mat-select formControlName="department">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{ dpt['fr'] }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onCancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="submit"
                        class="modalButton"
                        [disabled]="!rightMatchForm.valid"
                >
                    <span *ngIf="status !== 'edit'">
                    {{ 'commons.create' | translate }}
                    </span>
                    <span *ngIf="status === 'edit'">
                        {{ 'commons.save' | translate }}
                    </span>
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
