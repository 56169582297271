<div id="mainSquare">
    <div
        *ngIf="noTest"
        class="semiBold"
        id="overlayTitle"
    >
        {{ noTestTitle }}
    </div>
    <div [class.overlay]="noTest">
    </div>
    <div id="topDiv">
        <div id="jobTitleBox">
            <p class="jobTitle" style="">
                {{ 'commons.job' | translate }}
            </p>
            <p id="jobName" class="jobSize bold">
                {{ (!noTest && jobTitle) ?  jobTitle : chooseAJob() }}
            </p>
        </div>
        <div id="jobScoreBox">
            <div class="textBox">
                {{ 'jobs.reportRa.target' | translate }}
            </div>
            <div class="textBox jobSize jobScore bold">
                {{ (noTest) ? '&ndash;' : jobIacScore || '&ndash;' }}
            </div>

        </div>
        <div class="clearfix"></div>
    </div>
    <div id="bottomDiv">
        <div id="topRow">
            <div class="block" style="float: left">
                <div class="textBox">
                    {{ 'people.reportIac.bruteResult1' | translate }}<br>
                    {{ 'people.reportIac.bruteResult2' | translate }}
                </div>
                <div class="textBox score bold">
                    {{ (noTest) ? '&ndash;' : bruteScore + ' / 24' }}
                </div>
            </div>
            <div class="block" style="float: right">
                <div class="textBox">
                    {{ 'people.reportIac.answeredQuestions1' | translate }}<br>
                    {{ 'people.reportIac.answeredQuestions2' | translate }}
                </div>
                <div class="textBox score bold">
                    {{ (noTest) ? '&ndash;' : answeredQuestions }}
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div id="bottomRow" style="margin-top: 8px">
            <div class="block" style="float: left">
                <div class="textBox">
                    {{ 'people.commons.elapsedTime1' | translate }}<br>
                    {{ 'people.commons.elapsedTime2' | translate }}
                </div>
                <div class="textBox score bold">
                    {{ (noTest) ? '&ndash;' : time }}
                </div>
            </div>
            <div class="block" style="float: right">
                <div class="textBox">
                    {{ 'people.reportIac.normalizedResult1' | translate }}<br>
                    {{ 'people.reportIac.normalizedResult2' | translate }}
                </div>
                <div class="textBox score bold">
                    {{ (noTest) ? '&ndash;' : normalizedScore }}
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
