<mat-grid-list *ngIf="!addJob"
               cols="5"
               rowHeight="60px"
               class="mobileHeader"
>
    <mat-grid-tile
        colspan="1"
        rowspan="1"
        style="max-width: 87px;"
    >
        <app-mobile-navigation></app-mobile-navigation>
        <a
            class="reloadLink"
            (click)="goToPeople()"
        >
            <img alt="N" src="{{logoImg}}">
        </a>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <app-mobile-main-list-search-bar
            [initialValue]="lastSearch"
            (searchEvent)="setSearchFilter($event)"
            style="width: 85%"
        ></app-mobile-main-list-search-bar>
    </mat-grid-tile>
</mat-grid-list>
<div class="searchResults">
    <span
        style="font-weight: bold" *ngIf="jobsList.selectionDone && jobsList.totalListItems !== null"
    >
            {{ jobsList.totalListItems }}
        {{ jobsList.resMention | translate }}
        <span *ngIf="jobsList.totalItems">
                {{ 'commons.of' | translate }} {{ jobsList.totalItems }}
            </span>
        </span>
</div>
<h1 class="sectionTitle">
    <span *ngIf="!jobsList.archive">{{ 'commons.jobs' | translate }}</span>
    <span *ngIf="jobsList.archive">{{ 'commons.archives' | translate }}</span>
</h1>
<div *ngIf="jobsList.toList && !addJob"
     infiniteScroll
     [infiniteScrollDistance]="4"
     [infiniteScrollThrottle]="300"
     [scrollWindow]="false"
     (scrolled)="jobsList.listScrolled()"
     class="scrollableList"
>
    <ngx-loading [show]="jobsList.loading" [config]="loaderDisplay"></ngx-loading>
    <app-mobile-main-list
        [selectedItemId]="jobService.id"
        [tableDefinition]="jobsList.tableDefinition"
        [listsData]="jobsList.toList"
        [sectionName]="'jobs'"
        (selectedItem)="jobSelected($event);"
        (reordering)="reorderingListsRows($event)"
        [orderedBy]="orderedBy"
    ></app-mobile-main-list>
</div>
<button mat-fab *ngIf="!addJob"
        class="newItem-btn"
        (click)="createNewJob()"
        style="bottom: 20px !important"
><i class="material-icons">add</i></button>
<div *ngIf="addJob">
    <app-mobile-jobs-edit
        [status]="'new'"
        (close)="backToItemsList()"
    ></app-mobile-jobs-edit>
</div>
