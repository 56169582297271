<div class="sticky" *ngIf="!isLandscape || showPortraitButtons">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <h2 class="name">
                {{ jobSatisfaction.itemToDisplay ? jobSatisfaction.itemToDisplay.name.toUpperCase() : '' }}
            </h2>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest && !showPrbRequest && !showJobSatisfactionPopup"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest || showPrbRequest || showJobSatisfactionPopup"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                [disabled]="!jobSatisfaction.records || !jobSatisfaction.records.length"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="itemEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}
            </button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr"/>
</div>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 class="name">
        {{ jobSatisfaction.itemToDisplay ? jobSatisfaction.itemToDisplay.name.toUpperCase() : '' }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="editRequest" class="fullView-wrapper-scrollfix fullView-wrapper-scrollfix-correction" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-job-satisfactions-edit
        [status]="'edit'"
        (close)="closeAllRequestsPanels('refresh')"
    ></app-mobile-job-satisfactions-edit>
</div>
<div *ngIf="pdfRequest" class="fullView-wrapper-scrollfix fullView-wrapper-scrollfix-correction" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-job-satisfactions-pdf
        *ngIf="jobSatisfaction.itemToDisplay"
        (close)="closeAllRequestsPanels()"
    ></app-mobile-job-satisfactions-pdf>
</div>

<div *ngIf="!showJobSatisfactionPopup && !pdfRequest && !editRequest && !showPrbRequest"
     class="fullView-wrapper-scrollfix fullView-wrapper-scrollfix-correction"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <div class="informationPanel">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable">
                <tr>
                    <td> {{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!jobSatisfaction.itemToDisplay.name">
                            {{ jobSatisfaction.itemToDisplay.name || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!jobSatisfaction.itemToDisplay['consultant']">
                            {{ jobSatisfaction.itemToDisplay['consultant'] || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!setSubAccountName(jobSatisfaction.itemToDisplay['subAccount'])">
                            {{ setSubAccountName(jobSatisfaction.itemToDisplay['subAccount'])
                        || notSpecified()  }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td> {{ 'commons.division' | translate }}<span [innerHTML]="titleSep"></span>
                        <span [class.na]="!jobSatisfaction.department || !jobSatisfaction.department.length">
                            {{ (jobSatisfaction.department && jobSatisfaction.department.length) ? jobSatisfaction.department : notSpecified() }}
                        </span>
                    </td>
                </tr>
            </table>
        </mat-expansion-panel>
        <div
            [style.padding-right]="(small) ? '15px' : 'auto'"
        >
            <h1 class="mainTitleRmSb" style="font-size: 18px; padding-top: 10px">{{ 'jobSatisfactions.report.jobSatisfactionAnalysisRegularCase' | translate }}</h1>
            <div>
                <div class="floatLeft" style="width: calc(100% - 115px)">
                    <h2 style="font-family: 'MontserratSemiBold',  sans-serif; padding-top: 17px">{{ 'jobSatisfactions.report.portrait' | translate }}</h2>
                </div>
                <div class="floatRight" [innerHTML]="jobSatisfaction.circle | safeHtml"></div>
                <div class="clearfix"></div>
                <div id="green">
                    <div id="greenTitle" class="colorTitle" style=" background-color: #78c7af">
                        <div class="floatLeft graphTitle">
                            <app-job-satisfaction-graphics
                                [id]="'main_green_white'"
                                [background]="'white'"
                                [score]="2"
                                [size]="35"
                            >
                            </app-job-satisfaction-graphics>
                        </div>
                        <div class="floatLeft countTitle">
                            {{ countAndOver['green'] }}
                        </div>
                        <div class="floatRight subCircleTitle">
                            <div [innerHTML]="jobSatisfaction.subCircle.green | safeHtml"></div>
                        </div>

                        <div class="floatRight personTitle">
                            {{ 'jobSatisfactions.report.personHighJs' | translate }}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div id="nameListingGreen" class="nameListing">
                    <ng-container *ngIf="nameListing['green'].length !== 0">
                        <div class="nameBox" *ngFor="let name of nameListing['green']">
                            <div class="floatLeft listsNames">
                                {{ name }}
                            </div>
                            <!--div class="floatRight">
                                <app-job-satisfaction-graphics
                                    [id]="'green_' + name"
                                    [background]="'regular'"
                                    [score]="2"
                                    [size]="20"
                                ></app-job-satisfaction-graphics>
                            </div-->
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="nameListing['green'].length === 0">
                        <div class="nameBox" style="padding-bottom: 3px">
                            <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                        </div>
                    </ng-container>
                </div>
                <div id="yellow">
                    <div id="yellowTitle" class="colorTitle" style=" background-color: #efce7c">
                        <div class="floatLeft graphTitle">
                            <app-job-satisfaction-graphics
                                [id]="'main_yellow_white'"
                                [background]="'white'"
                                [score]="1"
                                [size]="35"
                            >
                            </app-job-satisfaction-graphics>
                        </div>
                        <div class="floatLeft countTitle">
                            {{ countAndOver['yellow'] }}
                        </div>
                        <div class="floatRight subCircleTitle">
                            <div [innerHTML]="jobSatisfaction.subCircle.yellow | safeHtml"></div>
                        </div>

                        <div class="floatRight personTitle">
                            {{ 'jobSatisfactions.report.personMedJs' | translate }}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div id="nameListingYellow" class="nameListing">
                    <ng-container *ngIf="nameListing['yellow'].length !== 0">
                        <div class="nameBox" *ngFor="let name of nameListing['yellow']">
                            <div class="floatLeft listsNames">
                                {{ name }}
                            </div>
                            <!--div class="floatRight">
                                <app-job-satisfaction-graphics
                                    [id]="'yellow_' + name"
                                    [background]="'regular'"
                                    [score]="1"
                                    [size]="20"
                                ></app-job-satisfaction-graphics>
                            </div-->
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="nameListing['yellow'].length === 0">
                        <div class="nameBox" style="padding-bottom: 3px">
                            <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                        </div>
                    </ng-container>
                </div>
                <div id="red">
                    <div id="redTitle" class="colorTitle" style=" background-color: #F07B7E">
                        <div class="floatLeft graphTitle">
                            <app-job-satisfaction-graphics
                                [id]="'main_red_white'"
                                [background]="'white'"
                                [score]="0"
                                [size]="35"
                            >
                            </app-job-satisfaction-graphics>
                        </div>
                        <div class="floatLeft countTitle">
                            {{ countAndOver['red'] }}
                        </div>
                        <div class="floatRight subCircleTitle">
                            <div [innerHTML]="jobSatisfaction.subCircle.red | safeHtml"></div>
                        </div>

                        <div class="floatRight personTitle">
                            {{ 'jobSatisfactions.report.personLowJs' | translate }}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div id="nameListingRed" class="nameListing">
                    <ng-container *ngIf="nameListing['red'].length !== 0">
                        <div class="nameBox" *ngFor="let name of nameListing['red']">
                            <div class="floatLeft listsNames">
                                {{ name }}
                            </div>
                            <!-- div class="floatRight">
                                <app-job-satisfaction-graphics
                                    [id]="'red_' + name"
                                    [background]="'regular'"
                                    [score]="0"
                                    [size]="20"
                                ></app-job-satisfaction-graphics>
                            </div-->
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="nameListing['red'].length === 0">
                        <div class="nameBox" style="padding-bottom: 3px">
                            <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                        </div>
                    </ng-container>

                </div>
                <div id="grey">
                    <div id="greyTitle" class="colorTitle"
                         style=" background-color: #C7C6C7">
                        <div class="floatLeft graphTitle">
                            <app-job-satisfaction-graphics
                                [id]="'main_grey_white'"
                                [background]="'white'"
                                [score]="null"
                                [size]="35"
                            >
                            </app-job-satisfaction-graphics>
                        </div>
                        <div class="floatLeft countTitle">
                            {{ countAndOver['grey'] }}
                        </div>
                        <div class="floatRight subCircleTitle">
                            <div [innerHTML]="jobSatisfaction.subCircle.grey | safeHtml"></div>
                        </div>

                        <div class="floatRight personTitle">
                            {{ 'jobSatisfactions.report.personUnknownJs' | translate }}
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div id="nameListingUnkown" class="nameListing">
                    <ng-container *ngIf="nameListing['grey'].length !== 0">
                        <div class="nameBox" *ngFor="let name of nameListing['grey']">
                            <div class="floatLeft listsNames">
                                {{ name }}
                            </div>
                            <!-- div class="floatRight">
                                <app-job-satisfaction-graphics
                                    [id]="'grey_' + name"
                                    [background]="'regular'"
                                    [score]="undefined"
                                    [size]="20"
                                ></app-job-satisfaction-graphics>
                            </div-->
                            <div class="clearfix"></div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="nameListing['grey'].length === 0">
                        <div class="nameBox" style="padding-bottom: 3px">
                            <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="smallTxt">
                <h2>
                    <span style="font-family: MontserratSemiBold,  sans-serif">{{ 'jobSatisfactions.report.potential' | translate }}</span>
                </h2>
                <table id="legendUp"  class="legend">
                    <tr>
                        <td>
                            <app-job-satisfaction-graphics
                                class="floatRight"
                                [background]="'regular'"
                                [score]="2"
                                [size]="20"
                                [id]="'legendGreen'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            {{ "jobSatisfactions.report.high" | translate }}
                        </td>
                        <td>
                            <app-job-satisfaction-graphics
                                class="floatRight"
                                [background]="'regular'"
                                [score]="1"
                                [size]="20"
                                [id]="'legendYellow'"
                            ></app-job-satisfaction-graphics>

                        </td>
                        <td>
                            {{ "jobSatisfactions.report.average" | translate }}
                        </td>
                        <td>
                            <app-job-satisfaction-graphics
                                class="floatRight"
                                [background]="'regular'"
                                [score]="0"
                                [size]="20"
                                [id]="'legendRed'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            {{ "jobSatisfactions.report.low" | translate }}
                        </td>
                        <td>
                            <app-job-satisfaction-graphics
                                class="floatRight"
                                [background]="'regular'"
                                [score]="null"
                                [size]="20"
                                [id]="'legendGray'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            {{ "jobSatisfactions.report.nd" | translate }}
                        </td>
                    </tr>
                </table>
                <h2>
                    <span style="font-family: MontserratSemiBold,  sans-serif">{{ 'jobSatisfactions.report.validity' | translate }}</span>
                </h2>
                <table id="legendDown" class="legend">
                    <tr>

                        <td>
                            <app-job-satisfaction-graphics
                                [background]="'regular'"
                                [score]="0"
                                [type]="'pcr_redo'"
                                [size]="'legend'"
                                [id]="'hourGlassRed'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            0 &ndash; 60
                        </td>
                        <td>
                            <app-job-satisfaction-graphics
                                [background]="'regular'"
                                [score]="1"
                                [type]="'pcr_redo'"
                                [size]="'legend'"
                                [id]="'hourGlassyellow'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            61 &ndash; 120
                        </td>
                        <td>
                            <app-job-satisfaction-graphics
                                [background]="'regular'"
                                [score]="2"
                                [type]="'pcr_redo'"
                                [size]="'legend'"
                                [id]="'hourGlassGreen'"
                            ></app-job-satisfaction-graphics>
                        </td>
                        <td>
                            {{ "jobSatisfactions.report.121plus" | translate }}
                        </td>
                        <td colspan="2" style="font-size: 8pt">
                            <!--
                            {{ "jobSatisfactions.report.validityExplanation1" | translate }}<br>
                            {{ "jobSatisfactions.report.validityExplanation2" | translate }}<br>
                            {{ "jobSatisfactions.report.validityExplanation3" | translate }}<br>
                            -->
                        </td>
                    </tr>
                </table>
            </div>
            <div style="border-bottom: 2px solid #999; margin-bottom: 10px;">
                <!-- div class="floatLeft">
                    <app-nav-button
                        [backgroundColor]="'rgb(227, 94, 36)'"
                        [textColor]="'white'"
                        [border]="'0'"
                        [swapContent]="true"
                        [isSidebar]="false"
                        [isJobSatisfaction]="true"
                        (click)="jobSatisfactionModalHandlingService.openJobSatisfactionPersonModal()"
                    >
                        <div class="buttonImage">
                            <img
                                src="../../../../../../assets/buttons/jobSatisfaction/bouton-ajouter-individu.png"
                                height="22"
                                width="22"
                            />
                        </div>
                        <div class="buttonTitle">{{ 'jobSatisfactions.report.addPerson' | translate }}</div>
                    </app-nav-button>
                    <div class="clearfix"></div>
                </div-->
                <div class="floatRight">
                    <!-- div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="'0'"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isJobSatisfaction]="true"
                            style="margin-left: 5px;"
                            (click)="jobSatisfactionModalHandlingService.openJobSatisfactionReportsFiltersModal()"
                        >
                            <div class="buttonImage">
                                <img src="../../../../../../assets/buttons/jobSatisfaction/bouton-filtrer.png"
                                     height="19" width="19" style="margin-top: 2px"/>
                            </div>
                            <div
                                class="buttonTitle titleRight">{{ 'analysis.jobSatisfaction.filter' | translate }}</div>
                        </app-nav-button>
                    </div-->
                    <!-- div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="'0'"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isJobSatisfaction]="true"
                            style="margin-left: 5px;"
                            (click)="togglePersonsCards()"
                        >
                            <div class="buttonImage">
                                <img *ngIf="!minimizeState"
                                     src="../../../../../../assets/buttons/jobSatisfaction/bouton-minimiser.png"
                                     height="22" width="22"/>
                                <img *ngIf="minimizeState"
                                     src="../../../../../../assets/buttons/jobSatisfaction/bouton-maximiser.png"
                                     height="22" width="22"/>
                            </div>
                            <div *ngIf="!minimizeState"
                                 class="buttonTitle titleRight">{{ 'commons.minimize' | translate }}</div>
                            <div *ngIf="minimizeState"
                                 class="buttonTitle titleRight">{{ 'commons.maximize' | translate }}</div>
                        </app-nav-button>
                    </div-->
                    <!-- div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'#cecece'"
                            [border]="0"
                            [textColor]="'white'"
                            [isSidebar]="false"
                            [isJobSatisfaction]="true"
                            (click)="toggleListView()"
                        >
                            <div class="buttonImage">
                                <img *ngIf="!listView"
                                     src="../../../../../../assets/buttons/jobSatisfaction/bouton-liste.png"
                                     height="22" width="22"/>
                                <img *ngIf="listView"
                                     src="../../../../../../assets/buttons/jobSatisfaction/bouton-grille.png"
                                     height="22" width="22"/>
                            </div>
                            <div *ngIf="!listView"
                                 class="buttonTitle titleRight">{{ "jobSatisfactions.report.list" | translate }}</div>
                            <div *ngIf="listView"
                                 class="buttonTitle titleRight">{{ "jobSatisfactions.report.grid" | translate }}</div>
                        </app-nav-button>
                    </div-->
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
            <div class="headerList">
                {{ 'jobSatisfactions.report.teamsJobSatisfaction' | translate }}
            </div>
            <div style="margin: 0 auto 15px auto; border: 4px solid #ddd; padding: 0 15px">
                <h2 class="ngorange"
                    style="
                                    font-family: MontserratSemiBold,  sans-serif;
                                    font-size: 17px;
                                    margin-top: 15px;
                                "
                >
                    {{ jobSatisfaction.name }}
                </h2>
                <app-job-satisfactions-team-graph
                    [display]="'mobile'"
                    [id]="jobSatisfaction.id"
                >
                </app-job-satisfactions-team-graph>
            </div>
            <div class="headerList">
                {{ 'jobSatisfactions.report.personJobSatisfaction' | translate }}
            </div>
            <ng-container>
                <ng-container
                    *ngFor="let record of records"
                >
                    <app-job-satisfactions-person-list-display
                        [record]="record"
                        [minimizeAll]="minimizeAll"
                        (openPcrReport)="toggleJobSatisfactionPopup($event)"
                        (openPrbRequest)="togglePrbRequest($event)"
                        (remove)="removePerson($event)"
                    ></app-job-satisfactions-person-list-display>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
<ng-container
    *ngIf="showJobSatisfactionPopup"
>
    <app-job-satisfaction-analysis-popup
        (closeJobSatisfactionPopup)="toggleJobSatisfactionPopup($event)"
    ></app-job-satisfaction-analysis-popup>
</ng-container>
<ng-container
    *ngIf="showPrbRequest"
>
    <div style="padding: 120px 20px; overflow-y: scroll;height: 100vh;">
        <app-mobile-people-request-send-resend
            [sendAData]="{'person': personToSend}"
            [prbForm]="true"
            [origin]="'jobSatisfaction'"
            (close)="closeAllRequestsPanels()"
        ></app-mobile-people-request-send-resend>
    </div>
</ng-container>
