<button
    mat-icon-button
    class="sidenav-menu-btn"
    [matMenuTriggerFor]="menu"
    aria-label="Menu"
>
    <mat-icon>menu</mat-icon>
</button>
<mat-menu #menu="matMenu" backdropClass="sidenav-menu">
    <span class="menuLogo">
        <a (click)="navigateTo(['m/people'])">
            <img alt="" src="{{assetsMenuFolder}}MPO_Logo_RGB_Inverse_FR.png">
        </a>
    </span>
    <button mat-menu-item [disabled]="stateService.session.actualModule=='people'"
            (click)="navigateTo(['m/people/list'])"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='people'"
                 src="{{assetsMenuFolder}}menu_principal/individus.png"/>
            <img  alt="" *ngIf="stateService.session.actualModule=='people'"
                  src="{{assetsMenuFolder}}menu_principal/individus_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='people'"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'app.navigation.people' | translate }}
            </span>
        </span>
    </button>
    <button mat-menu-item [disabled]="stateService.session.actualModule=='analysis'"
            *ngIf="!isSender && stateService && !stateService.session.hasSatelliteRole && stateService.hasAnalysisAccess"
            (click)="navigateTo([lastAddress ? lastAddress : '/m/analysis/list'])"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='analysis'"
                 src="{{assetsMenuFolder}}menu_principal/analyse.png">
            <img alt="" *ngIf="stateService.session.actualModule=='analysis'"
                 src="{{assetsMenuFolder}}menu_principal/analyse_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='analysis'"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'app.navigation.analysis' | translate }}
            </span>
        </span>
    </button>
    <button mat-menu-item [disabled]="stateService.session.actualModule=='jobs'"
            *ngIf="!isSender && stateService && stateService.hasJobAccess"
            (click)="navigateTo(['/m/jobs/list'])"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='jobs'"
                 src="{{assetsMenuFolder}}menu_principal/postes.png">
            <img alt="" *ngIf="stateService.session.actualModule=='jobs'"
                 src="{{assetsMenuFolder}}menu_principal/postes_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='jobs'"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'commons.jobs' | translate }}
            </span>
        </span>
    </button>
    <!-- this option is temporally hidden. To show the option remove *ngIf='false'  -->
    <button mat-menu-item [disabled]="stateService.session.actualModule=='archives'"
            *ngIf="!isSender && false"
            (click)="navigateTo(['/m/archives/list'])"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='archives'"
                 src="{{assetsMenuFolder}}menu_principal/archives.png">
            <img alt="" *ngIf="stateService.session.actualModule=='archives'"
                 src="{{assetsMenuFolder}}menu_principal/archives_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='archives'"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'commons.archives' | translate }}
            </span>
        </span>
    </button>
    <button mat-menu-item [disabled]="stateService.session.actualModule=='administration' && !isInUserPrefs()"
            *ngIf="!switchAccountDataId && isAdmin && parentAccounts"
            (click)="goToAdmin()"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='administration' || (stateService.session.actualModule=='administration' && isInUserPrefs())"
                 src="{{assetsMenuFolder}}menu_principal/admin.png">
            <img alt="" *ngIf="stateService.session.actualModule=='administration' && !isInUserPrefs()"
                 src="{{assetsMenuFolder}}menu_principal/admin_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='administration' && !isInUserPrefs()"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'app.navigation.admin' | translate }}
            </span>
        </span>
    </button>
    <!-- this option is temporally hidden. To show the option remove '&& false' from *ngIf  -->
    <button mat-menu-item [disabled]="stateService.session.actualModule=='tools'"
            *ngIf="isSuperAdmin && false"
            (click)="goToTools()"
    >
        <span class="mainMenuItemContainer">
            <img alt="" *ngIf="stateService.session.actualModule!='tools' || (stateService.session.actualModule=='tools' && isInUserPrefs())"
                 src="{{assetsMenuFolder}}menu_principal/tools.png">
            <img alt="" *ngIf="stateService.session.actualModule=='tools' && !isInUserPrefs()"
                 src="{{assetsMenuFolder}}menu_principal/tools_selected.png"/>
            <span [class.selectedText]="stateService.session.actualModule=='tools' && !isInUserPrefs()"
                  class="mainMenuButtonTitle text-uppercase">
                {{ 'app.navigation.tools' | translate }}
            </span>
        </span>
    </button>
    <div class="credits">{{ 'commons.credits' | translate }}{{(translate.currentLang === 'fr') ? '&nbsp;' : ''}}: {{ principalAccountCredits }}</div>
    <button mat-menu-item
            class="bottom-btn"
            (click)="navigateTo(['/m/support'])"
            [disabled]="stateService.session.actualModule=='support'"
    >
        <span class="mainMenuItemContainer"
              [class.selectedText]="stateService.session.actualModule=='support'"
        >
            <mat-icon>contact_support</mat-icon>
            Support
        </span>
    </button>
    <button mat-menu-item
            class="bottom-btn"
            (click)="('fr' != lang) ? switchLanguage('fr') : switchLanguage('en')"
    >
        <span class="mainMenuItemContainer">
            <mat-icon>language</mat-icon>
            <span *ngIf='"fr" != lang'>
                Français
            </span>
            <span *ngIf='"fr" == lang'>
                English
            </span>
        </span>
    </button>
    <button mat-menu-item *ngIf="switchAccountDataId"
            matTooltip="{{ 'app.app.connectedTo' | translate }} {{ loggedAccount }}"
            aria-label="Button that displays a tooltip when focused or hovered over"
            class="bottom-btn"
            (click)="switchBack(switchAccountDataId)"
    >
        <span class="mainMenuItemContainer">
            <mat-icon>undo</mat-icon>
            <span class="ngorange">
                {{ 'app.navigation.backToAccount' | translate }}
            </span>
        </span>
    </button>
    <button mat-menu-item
            class="bottom-btn"
            (click)="onLogout()"
    >
        <span class="mainMenuItemContainer">
            <mat-icon>cancel</mat-icon>
            <span>
                {{ 'app.navigation.logout' | translate }}
            </span>
        </span>
    </button>
</mat-menu>
