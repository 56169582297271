<div (window:resize)="onResize($event)">
    <div class="top affix tLeft"
         [style.width]="(!small) ? width : '100%'"
         [style.padding]="(small) ? '20px' : null"
         [style.margin-top]="(!small) ? '35px' : null"
    >
        <app-nav-button (buttonActon)="goToAccount()">
            <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
            <div class="buttonTitle">{{ 'admin.userDetailLeftSidebar.returnToAccount' | translate }}</div>
        </app-nav-button>
        <div class="clearfix"></div>
        <div *ngIf="!small">
            <hr class="panelHr"/>
            <div style="height: 5px;">&nbsp;</div>
            <h3 style="font-size: 20px">{{ 'admin.commons.fastLinks' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('userInfo')">{{ 'admin.leftSidebar.userInfo' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('addressInfo')">{{ 'admin.leftSidebar.addressInfo' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('trainingMpo')">{{ 'admin.leftSidebar.formation' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('permissions')">Permissions</h3>
            <h3 class="h3link" (click)="moveTo('notes')">{{ 'admin.leftSidebar.notes' | translate }}</h3>
            <h3 class="h3link pull-left" (click)="moveTo('files')">{{ 'admin.leftSidebar.files' | translate }}</h3>
            <div class="numberBox pull-left">
                {{ clientUser.assets.length }}
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
