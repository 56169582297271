<div class="PRBSection">
    <mat-form-field class="full-width" style="padding-bottom: 10px">
        <label>
            <input matInput
                   (keydown.enter)="getRecordsBySearch(searchTerm.value);"
                   #searchTerm
            />
        </label>
        <button *ngIf="!lastSearch.value.length"
                mat-button
                matSuffix
                mat-icon-button
                (click)="getRecordsBySearch(searchTerm.value)"
                aria-label="Search"
        >
            <mat-icon>search</mat-icon>
        </button>
        <button *ngIf="lastSearch.value.length"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearch()"
        >
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div id="candidateList">
        <div *ngIf="!loading">
            <div *ngFor="let record of recordsList; let i = index" class="candidateRow pointer" (click)="selectRecord(record)">
                <mat-grid-list cols="2" rowHeight="30px">
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="candidateName"
                    >
                        {{ record['firstName'] }} {{ record['lastName'] }}
                    </mat-grid-tile>
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="candidateDate"
                    >
                        {{ convertDate(record['creationDate']) }}
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
        </div>
        <mat-grid-list cols="1" rowHeight="400px" *ngIf="loading">
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <ngx-loading
                    [show]="loading"
                    [config]="{
                        backdropBorderRadius: '0pt',
                        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
                        primaryColour: '#CDCDCD',
                        secondaryColour: '#ABABAB',
                        tertiaryColour: '#CDCDCD'
                    }"
                ></ngx-loading>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
    <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >&nbsp;
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
            class="candidateDate"
        >
            <button
                mat-button
                type="button"
                class="modalButton"
                (click)="cancelSelectRecord()"
            >{{ 'commons.cancel' | translate }}</button>
        </mat-grid-tile>
    </mat-grid-list>
</div>
