<div class="modal-header">
    <h4 class="modal-title pull-left">
        <span *ngIf="translate.currentLang == 'fr'">Pièces jointes</span>
        <span *ngIf="translate.currentLang == 'en'">Attached files</span>
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<form>
    <div class="modal-body">
        <div class="form-group">
            <!-- files -->
            <!-- Drag and drop (broken) -->
            <!--div class="center">
                <file-drop headertext="" (onFileDrop)="dropped($event)"
                           (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                    <span>Déposer le fichier ici</span>
                </file-drop>
                <div class="upload-table">
                    <table class="table">
                        <thead>
                        <tr>
                            <th><br>{{ 'admin.filesModal.fileName' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody class="upload-name-style">
                        <tr *ngFor="let item of files; let i=index">
                            <td><strong>{{ item.relativePath }}</strong></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div-->
            <label for="file">
                <span *ngIf="translate.currentLang == 'fr'">Veuillez choisir un fichier</span>
                <span *ngIf="translate.currentLang == 'en'">Please choose a file</span>
            </label>

            <p *ngIf="error" class="text-danger">{{ 'commons.upload_error' | translate }}</p>

            <input
                type="file"
                id="file"
                (change)="fileInputHandling($event.target['files'])"
            />
        </div>
    </div>
    <div class="modal-footer">
        <div>
            <button type="submit" class="modalButton pull-right" (click)="addFile()"
                    style="margin-top: 15px">
                {{ 'commons.save' | translate }}
            </button>
        </div>
        <div>
            <button type="submit" class="modalButton pull-right" (click)="closeModal()"
                    style="margin-top: 15px; margin-right: 15px">
                {{ 'commons.cancel' | translate }}
            </button>
        </div>
        <div class="clearfix"></div>
    </div>

</form>
