<div
    class="card"
    [class.rightCard]="listView"
    [class.cardGridView]="!listView"
>
    <div class="cardHeader">
        <div class="floatLeft">
            <div class="cardHeaderMainTitle">
                {{ 'commons.cai' | translate }}
            </div>
            <div class="cardHeaderSubTitle">
                {{ 'jobs.reportRa.target' | translate }}{{
                (translateService.currentLang === 'fr') ? ' ' : ''}}
                : {{ (job['RA']) ? job['RA'] : '&ndash;' }}
            </div>
        </div>
        <div class="floatRight">
            <div class="floatLeft">
                <div style="width: 42px; padding: 6px">
                    <app-indicator-graph
                        [id]="record.id + '_cai'+pos"
                        [display]="record['RA']['scoreNormalized']"
                        [score]="(cai !== null) ? record.displayValues.cai.iacDeltaLevel : null"
                        [over]="3"
                        [color]="(cai !== null) ? record.displayValues.cai.iacColor : null"
                    ></app-indicator-graph>
                </div>
            </div>
            <div *ngIf="!listView" class="floatLeft" style="margin-top: 3px;">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <ng-container *ngIf="informationVisibility">
        <div
            *ngIf="!record['RA']['scoreNormalized'] || job['RA'] === false"
            class="iacOverlay"
        >
        </div>
        <div
            *ngIf="!record['RA']['scoreNormalized'] || job['RA'] === false"
            class="notCompleted"
        >
            <ng-template
                #notCompleted
            >
                <div
                    *ngIf="!record['RA']['scoreNormalized']"
                    class="notCompletedTxt"
                >
                    {{ 'people.commons.notCompleted' | translate }}
                </div>
            </ng-template>
            <div

                *ngIf="job['RA'] === false; else notCompleted"
                class="notCompletedTxt"
            >
                {{ 'people.commons.noTargetDefined' | translate }}
            </div>
        </div>
        <div class="main-container">
            <h2>{{ 'people.reportIac.bruteResultOfQuestionnaire' | translate }}</h2>
            <div style="max-width: 425px">
                <div style="width: 100%; border: 4px solid rgb(206, 206, 206); margin-top: 10px">
                    <table>
                        <tr>
                            <td style="width: 40%; padding: 5px">
                                <p class="bold">{{ 'people.reportIac.normalizedResult1' | translate }}</p>
                                <p class="ngorange">{{ (record['RA']['bruteScore']) ? record['RA']['bruteScore'] + ' / 24' : '' }}</p>
                            </td>
                            <td style="width: 60%; padding: 5px">
                                <p>{{ 'people.reportIac.answeredQuestions' | translate }} <span class="ngorange"
                                >{{ (record['RA']['answeredQuestions']) ? record['RA']['answeredQuestions'] : '' }}</span>
                                </p>
                                <p>{{ 'people.commons.time' | translate }}{{
                                    (translateService.currentLang === 'fr') ? ' ' : ''}}: <span
                                        class="ngorange">{{ (timeSpent) ? timeSpent : '' }}</span></p>
                            </td>
                        </tr>
                    </table>
                </div>
                <div style="width: 100%; border: 4px solid rgb(206, 206, 206); margin-top: 20px">
                    <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt=""/>
                    <app-iac-graph
                        [id]="'iac_person_'+record['id'] + pos"
                        [normalizedScore]="(job['RA'] && record['RA']) ? record['RA']['scoreNormalized'] : null"
                        [jobScore]="(job['RA'] && record['RA']) ? job['RA'] : null"
                        [mobile]="false"
                    ></app-iac-graph>
                </div>
            </div>
        </div>
    </ng-container>
</div>
