<div *ngIf="surveyRequestFormGroup"
     [formGroup]="surveyRequestFormGroup"
     class="requestInfoSection"
>
    <h4 class="georgia">{{ 'commons.informations' | translate }}</h4>

    <label for="jobTitle">{{ 'commons.title' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="jobTitle" formControlName="jobTitle">
    </mat-form-field>

    <label for="organisation"
           [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('recordType').errors"
    >{{ 'commons.organisation' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="organisation" formControlName="organisation">
    </mat-form-field>

    <!-- recordType -->
    <div *ngIf="recordTypeList">
        <label
            [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('recordType').errors"
        >{{ 'people.commons.status' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="recordType">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let rcrdStat of recordTypeList" [value]="rcrdStat.value">
                    {{rcrdStat.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- subAccount -->
    <div *ngIf="subAccountList">
        <label
            [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('subAccount').errors"
        >{{ 'commons.subAccount' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="subAccount">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let subAcct of subAccountList" [value]="subAcct.id">
                    {{subAcct.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- department -->
    <div *ngIf="departmentList">
        <label>{{ 'commons.division' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="department">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let dpt of departmentList" [value]="dpt.id">
                    {{dpt['fr']}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- jobId -->
    <div>
        <label>{{ 'people.requestModal.job' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="jobId">
                <mat-option value=""></mat-option>
                <mat-option *ngFor="let job of jobsList; trackBy: trackById" [value]="job.id">
                    {{job['jobTitle']}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- requestedByName / requestedBy -->
    <div>
        <label for="requestedByName">{{ 'commons.requestedBy' | translate }}</label>
        <mat-form-field appearance="fill">
            <input matInput id="requestedByName" formControlName="requestedByName">
        </mat-form-field>
        <input type="hidden" formControlName="requestedBy" />
    </div>

    <div *ngIf="isSender">
        <label>{{ 'commons.requester' | translate }}
            <span *ngIf="!usersCount && currentLang === 'fr'" class="noRequester"> - Aucun demandeur disponble</span>
            <span *ngIf="!usersCount && currentLang === 'en'" class="noRequester"> - No available requester</span>
        </label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="requester">
                <mat-option></mat-option>
                <mat-option *ngFor="let user of users" [value]="user['firstName'] + ' ' + user['lastName']">
                    {{user['firstName'] + ' ' + user['lastName']}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- sendingType -->
    <div *ngIf="!iacModal && !mpoModal">
        <label
            [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('sendingType').errors"
        >{{ 'commons.sendingModeSelection' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="sendingType">
                <mat-option *ngFor="let sendingTypeItem of sendingTypes" [value]="sendingTypeItem.value">
                    {{ sendingTypeItem.title | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- autoGen -->
    <div *ngIf="!iacModal && !mpoModal" style="margin-top: 10px">
        <mat-checkbox formControlName="autoGen" >
            {{ 'people.requestModal.autogenReport' | translate }}
        </mat-checkbox>
        <img [tooltip]="'people.requestModal.autogenTooltip' | translate"
             src="/assets/other_icons/question.png"
             class="autoGenCheck"
         alt="" />
    </div>

</div>
