<ng-container *ngIf="type==='smiley'">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         [id]="id"
         [attr.width]="size + 'px'"
         viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;" xml:space="preserve">
        <style type="text/css">
            .st0 {
                fill: #F07B7E;
            }

            .st1 {
                fill: #56565A;
            }

            .st2 {
                fill: #C7C6C7;
            }

            .st3 {
                fill: #EECF7B;
            }

            .st4 {
                fill: #77C6AF;
            }

            .st5 {
                fill: #FFFFFF;
            }
        </style>
        <g>
            <circle [attr.class]="backgroundColor" cx="7.5" cy="7.49" r="6.9"/>
            <ng-container *ngIf="backgroundColor === 'st4'">
                <path [attr.class]="strokeColor" d="M7.5,10.31c1.09,0,1.85-0.51,2.3-0.99c0.26-0.28,0.71-0.3,0.99-0.04c0.28,0.26,0.3,0.71,0.04,0.99
                        c-0.64,0.7-1.75,1.45-3.33,1.45s-2.69-0.75-3.33-1.45C3.91,9.99,3.9,9.54,4.21,9.28C4.5,9.01,4.94,9.04,5.2,9.32
                        C5.65,9.8,6.41,10.31,7.5,10.31z M6.11,6.09c0,0.52-0.42,0.94-0.94,0.94c-0.52,0-0.94-0.42-0.94-0.94s0.42-0.94,0.94-0.94
                        C5.68,5.16,6.11,5.58,6.11,6.09z M8.92,6.09c0-0.52,0.42-0.94,0.94-0.94c0.52,0,0.94,0.42,0.94,0.94s-0.42,0.94-0.94,0.94
                        C9.34,7.03,8.92,6.61,8.92,6.09z M15,7.5c0,4.14-3.36,7.5-7.5,7.5S0,11.64,0,7.5S3.36,0,7.5,0S15,3.36,15,7.5z M7.5,1.41
                        c-3.37,0-6.09,2.73-6.09,6.09s2.73,6.09,6.09,6.09s6.09-2.73,6.09-6.09S10.87,1.41,7.5,1.41z"/>
            </ng-container>
            <ng-container *ngIf="backgroundColor === 'st3'">
                <path [attr.class]="strokeColor" d="M4.23,6.09c0-0.52,0.42-0.94,0.94-0.94c0.52,0,0.94,0.42,0.94,0.94S5.68,7.03,5.17,7.03
                C4.65,7.03,4.23,6.61,4.23,6.09z M10.79,6.09c0,0.52-0.42,0.94-0.94,0.94c-0.52,0-0.94-0.42-0.94-0.94s0.42-0.94,0.94-0.94
                C10.37,5.16,10.79,5.58,10.79,6.09z M9.61,9.61c0.39,0,0.7,0.31,0.7,0.7s-0.31,0.7-0.7,0.7H5.39c-0.39,0-0.7-0.31-0.7-0.7
                s0.31-0.7,0.7-0.7H9.61z M15,7.5c0,4.14-3.36,7.5-7.5,7.5S0,11.64,0,7.5S3.36,0,7.5,0S15,3.36,15,7.5z M7.5,13.59
                c3.37,0,6.09-2.73,6.09-6.09S10.87,1.41,7.5,1.41S1.41,4.13,1.41,7.5S4.13,13.59,7.5,13.59z"/>
            </ng-container>
            <ng-container *ngIf="backgroundColor === 'st0'">
                <path [attr.class]="strokeColor" d="M7.5,10.29c-1.09,0-1.85,0.54-2.3,1.02c-0.26,0.28-0.71,0.3-0.99,0.04c-0.31-0.26-0.3-0.71-0.04-0.99
                c0.64-0.7,1.75-1.47,3.33-1.47s2.69,0.77,3.33,1.47c0.26,0.28,0.25,0.73-0.04,0.99c-0.29,0.26-0.73,0.24-0.99-0.04
                C9.35,10.83,8.59,10.29,7.5,10.29L7.5,10.29z M6.11,6.09c0,0.52-0.42,0.94-0.94,0.94c-0.52,0-0.94-0.42-0.94-0.94
                s0.42-0.94,0.94-0.94C5.68,5.16,6.11,5.58,6.11,6.09z M8.92,6.09c0-0.52,0.42-0.94,0.94-0.94c0.52,0,0.94,0.42,0.94,0.94
                s-0.42,0.94-0.94,0.94C9.34,7.03,8.92,6.61,8.92,6.09z M15,7.5c0,4.14-3.36,7.5-7.5,7.5S0,11.64,0,7.5S3.36,0,7.5,0
                S15,3.36,15,7.5z M7.5,1.41c-3.37,0-6.09,2.73-6.09,6.09s2.73,6.09,6.09,6.09s6.09-2.73,6.09-6.09S10.87,1.41,7.5,1.41z"/>
            </ng-container>
            <ng-container *ngIf="backgroundColor === 'st2'">
                <path [attr.class]="strokeColor" d="M7.5,0C3.36,0,0,3.36,0,7.5S3.36,15,7.5,15S15,11.64,15,7.5S11.64,0,7.5,0z M7.5,13.59
            c-3.36,0-6.09-2.73-6.09-6.09S4.14,1.41,7.5,1.41s6.09,2.73,6.09,6.09S10.86,13.59,7.5,13.59z M7.5,9.84
            c-0.53,0-0.94,0.41-0.94,0.94s0.38,0.94,0.94,0.94c0.5,0,0.94-0.41,0.94-0.94S8,9.84,7.5,9.84z M8.47,3.75h-1.5
            c-1.14,0-2.05,0.91-2.05,2.05c0,0.38,0.32,0.7,0.7,0.7s0.7-0.32,0.7-0.7c0-0.35,0.27-0.64,0.62-0.64h1.5c0.38,0,0.7,0.29,0.7,0.64
            c0,0.23-0.12,0.41-0.32,0.53L7.15,7.35C6.91,7.5,6.8,7.73,6.8,7.97v0.47c0,0.38,0.32,0.7,0.7,0.7s0.7-0.32,0.7-0.7V8.38l1.32-0.82
            c0.62-0.38,1-1.05,1-1.76C10.55,4.66,9.64,3.75,8.47,3.75z"/>
            </ng-container>
        </g>
    </svg>
</ng-container>
<ng-container *ngIf="type === 'pcr_redo'">
    <svg version="1.1"
         [id]="id"
         [attr.width]="size + 'px'"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 29 37" style="enable-background:new 0 0 29 37;" xml:space="preserve">
        <style type="text/css">
            .pcr_st0 {
                fill: #57575B;
            }

            .pcr_st1 {
                fill: #EE7C80;
            }

            .pcr_st2 {
                fill: #78C6AE;
            }

            .pcr_st3 {
                fill: #EFCE80;
            }
        </style>
        <ng-container *ngIf="score === 2">
            <g>
                <path class="pcr_st0" d="M0,1.2C0,0.5,0.5,0,1.2,0h26.6C28.5,0,29,0.5,29,1.2c0,0.6-0.5,1.2-1.2,1.2h-1.2v3.2c0,2.3-0.8,4.5-2.2,6.3
                        L19,18.5l5.3,6.6c1.5,1.7,2.2,4,2.2,6.3v3.2h1.2c0.7,0,1.2,0.5,1.2,1.2S28.5,37,27.8,37H1.2C0.5,37,0,36.5,0,35.8s0.5-1.2,1.2-1.2
                        h1.2v-3.2c0-2.3,0.8-4.6,2.2-6.3l5.3-6.6l-5.3-6.6c-1.5-1.8-2.2-4-2.2-6.3V2.3H1.2C0.5,2.3,0,1.8,0,1.2L0,1.2z M4.8,5.5
                        c0,1.8,0.6,3.5,1.7,4.9l5.9,7.3c0.3,0.4,0.3,0.9,0,1.4l-5.9,7.3c-1.1,1.4-1.7,3.1-1.7,4.9v3.2h19.3v-3.2c0-1.8-0.6-3.5-1.8-4.9
                        l-5.8-7.3c-0.3-0.5-0.3-1,0-1.4l5.8-7.3c1.2-1.4,1.8-3.1,1.8-4.9V2.3H4.8V5.5z"/>
                <path class="pcr_st1" d="M21.5,30.5l-6-8.1c-0.2-0.3-0.6-0.5-1-0.5c-0.4,0-0.8,0.2-1,0.5l-6,8.1c-0.3,0.4-0.3,0.8-0.1,1.2
                        c0.2,0.4,0.6,0.6,1.1,0.6h12.1c0.4,0,0.9-0.2,1.1-0.6C21.8,31.3,21.8,30.9,21.5,30.5z"/>
            </g>
        </ng-container>
        <ng-container *ngIf="score === 1">
            <g>
                <path class="pcr_st0" d="M1.2,37C0.5,37,0,36.5,0,35.8s0.5-1.2,1.2-1.2h1.2v-3.2c0-2.3,0.8-4.6,2.2-6.3l5.3-6.6l-5.3-6.6
                        c-1.5-1.8-2.2-4-2.2-6.3V2.3H1.2C0.5,2.3,0,1.8,0,1.2C0,0.5,0.5,0,1.2,0h26.6C28.5,0,29,0.5,29,1.2c0,0.6-0.5,1.2-1.2,1.2h-1.2v3.2
                        c0,2.3-0.8,4.5-2.2,6.3L19,18.5l5.3,6.6c1.5,1.7,2.2,4,2.2,6.3v3.2h1.2c0.7,0,1.2,0.5,1.2,1.2S28.5,37,27.8,37H1.2z M24.2,31.5
                        c0-1.8-0.6-3.5-1.8-4.9l-5.8-7.3c-0.3-0.5-0.3-1,0-1.4l5.8-7.3c1.2-1.4,1.8-3.1,1.8-4.9V2.3H4.8v3.2c0,1.8,0.6,3.5,1.7,4.9l5.9,7.3
                        c0.3,0.4,0.3,0.9,0,1.4l-5.9,7.3c-1.1,1.4-1.7,3.1-1.7,4.9v3.2h19.3V31.5z"/>
                <g>
                    <g>
                        <g>
                            <g>
                                <path class="pcr_st3" d="M13.6,14.5c0.2,0.3,0.6,0.5,0.9,0.5s0.7-0.2,0.9-0.5l4.8-5.8c0.3-0.3,0.3-0.8,0.1-1.2
                                        c-0.2-0.4-0.6-0.7-1.1-0.7H9.7c-0.5,0-0.9,0.3-1.1,0.7C8.4,8,8.4,8.5,8.7,8.8L13.6,14.5z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path class="pcr_st3" d="M21.6,30.7l-2.4-4.6c-0.2-0.4-0.6-0.6-1.1-0.6h-7.3c-0.4,0-0.8,0.2-1,0.6l-2.4,4.6
                                        c-0.2,0.4-0.2,0.8,0.1,1.1c0.2,0.3,0.6,0.5,1,0.5h12.2c0.4,0,0.8-0.2,1-0.5C21.8,31.5,21.8,31.1,21.6,30.7z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </ng-container>
        <ng-container *ngIf="score === 0">
            <g>
                <path class="pcr_st0" d="M29,35.8c0,0.6-0.5,1.2-1.2,1.2H1.2C0.5,37,0,36.5,0,35.8c0-0.6,0.5-1.2,1.2-1.2h1.2v-3.2
                        c0-2.3,0.8-4.5,2.2-6.3l5.3-6.6l-5.3-6.6c-1.5-1.7-2.2-4-2.2-6.3V2.3H1.2C0.5,2.3,0,1.8,0,1.2S0.5,0,1.2,0h26.6
                        C28.5,0,29,0.5,29,1.2s-0.5,1.2-1.2,1.2h-1.2v3.2c0,2.3-0.8,4.6-2.2,6.3L19,18.5l5.3,6.6c1.4,1.8,2.2,4,2.2,6.3v3.2h1.2
                        C28.5,34.7,29,35.2,29,35.8L29,35.8z M24.2,31.5c0-1.8-0.6-3.5-1.7-4.9l-5.9-7.3c-0.3-0.4-0.3-0.9,0-1.4l5.9-7.3
                        c1.1-1.4,1.7-3.1,1.7-4.9V2.3H4.8v3.2c0,1.8,0.6,3.5,1.8,4.9l5.8,7.3c0.3,0.5,0.3,1,0,1.4l-5.8,7.3c-1.2,1.4-1.8,3.1-1.8,4.9v3.2
                        h19.3V31.5z"/>
                <path class="pcr_st2" d="M7.5,6.5l6,8.1c0.2,0.3,0.6,0.5,1,0.5c0.4,0,0.8-0.2,1-0.5l6-8.1c0.3-0.4,0.3-0.8,0.1-1.2
                        c-0.2-0.4-0.6-0.6-1.1-0.6H8.5c-0.4,0-0.9,0.2-1.1,0.6C7.2,5.7,7.2,6.1,7.5,6.5z"/>
            </g>
        </ng-container>
    </svg>
</ng-container>


