<!-- set report -->
<div *ngIf="pdfStructure">
    <div class="modal-header">
        <h4 id="modalTitle" class="modal-title pull-left fontLight">
            {{ 'commons.sharePdf' | translate }}
        </h4>
        <div
            (click)="closeModal()" aria-label="Close" class="x-close pull-right"
            style="font-size: 20pt;"
        >&#x00D7;
        </div>
    </div>
    <div *ngIf="share=='none'">
        <div class="modal-body">
            <h4>Options</h4>
            <h5>{{ 'people.pdfModal.reportType' | translate }}</h5>
            <span>
                <input id="analystTrue" type="radio" [(ngModel)]="pdfStructure['parts']['analyst']"
                       value="1"
                       name="analyst"
                       [checked]="pdfStructure['parts']['analyst'] == '1'" />&nbsp;
                <label for="analystTrue">{{ 'commons.analyst' | translate }}</label>
            </span><br>
            <span>
                <input id="analystFalse" type="radio" [(ngModel)]="pdfStructure['parts']['analyst']"
                       value="0"
                       name="analyst" />&nbsp;
                <label for="analystFalse">{{ 'commons.manager' | translate }}</label>
            </span>

            <h5>{{ 'commons.jobSatisfactionReportSections' | translate }}</h5>
            <div class="insideCheckboxes">
                <mat-checkbox id="jopSatisfactionReportHomepage"
                              [(ngModel)]="pdfStructure['parts']['frontPage']"
                              value="1"
                >{{ 'people.pdfModal.coverPage' | translate }}
                </mat-checkbox>
                <br/>
                <mat-checkbox id="jopSatisfactionReportTeam"
                              [(ngModel)]="pdfStructure['parts']['team']"
                              value="1"
                >{{ 'jobSatisfactions.report.teamsWellBeing' | translate }}
                </mat-checkbox>
                <br/>
                <mat-checkbox id="jopSatisfactionReportPersons"
                              [(ngModel)]="pdfStructure['parts']['person']"
                              (change)="togglePersons()"
                              value="1"
                >{{ 'commons.individus' | translate }}
                </mat-checkbox>
                <br/>
                <div style="padding-left: 20px">
                    <mat-checkbox id="jopSatisfactionReportPersonSummary"
                                  [(ngModel)]="pdfStructure['parts']['personSummary']"
                                  [disabled]="!showPerson"
                                  value="1"
                    >{{ 'jobSatisfactions.report.personSummary' | translate }}
                    </mat-checkbox>
                    <br/>
                    <mat-checkbox id="jopSatisfactionReportPersonTrait"
                                  [(ngModel)]="pdfStructure['parts']['personText']"
                                  [disabled]="!showPerson"
                                  value="1"
                    >{{ 'jobSatisfactions.report.personText' | translate }}
                    </mat-checkbox>
                </div>
                <br/>
                <div class="insideCheckboxes">
                    <h5
                        [class.text-danger]="selectMethod"
                    >{{ 'commons.sendingMethod' | translate }}</h5>
                    <label>
                        <select [(ngModel)]="method"
                                (change)="changeSelectMethodState()"
                        >
                            <option>{{ 'commons.select' | translate }}</option>
                            <option value="pdf">{{ 'commons.pdfReport' | translate }}</option>
                            <option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</option>
                            <option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</option>
                            <option value="server">{{ 'commons.sendByServer' | translate }}</option>
                        </select>
                    </label>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="buttonSubmit pull-right">
                <button class="modalButton" (click)="handleReport()">{{ 'commons.continue' | translate }}
                </button>
            </div>
            <div class="buttonSubmit pull-right" style="margin-right: 15px">
                <button class="modalButton" (click)="closeModal()">{{ 'commons.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
    <!-- share mode -->
    <!-- server -->
    <div *ngIf="share=='server'">
        <div class="modal-body">
            <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

            <div>
                <h3><b>Message</b></h3>
                <div class="form-group">
                    <div class="sendContent">
                        <table style="width: 100%">
                            <tr>
                                <td style="width: 75px; font-weight: bold">
                                    <label for="reportEmailEmailTo">
                                        {{ 'commons.email' | translate }}
                                    </label>
                                </td>
                                <td><input
                                    id="reportEmailEmailTo"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail['emailTo']"/>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' |
                                    translate }}
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 75px; font-weight: bold">
                                    <label for="reportEmailSubject">
                                        {{ 'commons.emailSubject' | translate }}
                                    </label>
                                </td>
                                <td><input
                                    id="reportEmailSubject"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail.subject"
                                />
                                </td>
                            </tr>
                        </table>
                        <table style="width: 100%; margin-top: 25px">
                            <tr>
                                <td style="padding-bottom: 7px; font-weight: bold">
                                    <label for="reportEmailMessage">
                                        <span class="field_title">{{ 'commons.message' | translate }}</span>
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <textarea
                                    id="reportEmailMessage"
                                    rows="7"
                                    style="border: 1px solid #ccc; width: 100%; height: 100px"
                                    [(ngModel)]="pdfEmail.message"></textarea>
                                </td>
                            </tr>
                        </table>
                        <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                        <div id="innerTextServer">
                            <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                            <p>{{ pdfEmail.message }}</p>
                            <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                            <p *ngIf="pdfReportUrl"
                               class="forceBreaks"
                               [innerHTML]="serverText"
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="buttonSubmit pull-right">
                <button class="modalButton" (click)="sendEmailReport()">{{ 'commons.shareReport' | translate }}
                </button>
            </div>
            <div class="buttonSubmit pull-right" style="margin-right: 15px">
                <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
            </div>
        </div>
    </div>
    <!-- mailto -->
    <div *ngIf="share=='mailto'">
        <div *ngIf="mailto" class="modal-body">
            <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
            <p> {{ 'commons.sendByYourEmailReportInstructions' | translate }} <a href="mailto:{{ mailto }}"
                                                                                 target="_blank">{{
                'commons.here' | translate }}</a>.</p>
            <p class="mailtoWarning" style="color: darkred">
                {{ 'commons.mailtoWarning' | translate }}
            </p>
        </div>
        <div class="modal-footer">
            <div class="buttonSubmit pull-right">
                <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
            </div>
            <div class="buttonSubmit pull-right" style="margin-right: 15px">
                <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
            </div>
        </div>
    </div>
    <!-- cutnpaste-->
    <div *ngIf="share=='cutpaste'">
        <div *ngIf="pdfReportUrl" class="modal-body">
            <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
            <p
                [translate]="'people.pdfModal.linkInstruction'"
                [translateParams]="{
                    'reportName': (this.translate.currentLang === 'fr') ? 'Rapport d\' analyse de Bien-être (IBT)' : 'Wellbeing Potential Analysis Report'
                }"
            ></p>
            <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
        </div>
        <div class="modal-footer">
            <div class="buttonSubmit pull-right">
                <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
            </div>
            <div class="buttonSubmit pull-right" style="margin-right: 15px">
                <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
            </div>
        </div>
    </div>
</div>
