import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StateService} from '../../../../core/services/state/state.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../../shared/alert/services/alert.service';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {ApiPeopleService} from '../../../services/api-people.service';
import {PersonService} from '../../../services/person.service';
import {UserService} from '../../../../core/services/user/user.service';
import {ComponentFullView} from '../../../../shared/classes/components/component_full_view';
import {DeviceDetectorService} from 'ngx-device-detector';
import {PeopleModalHandlingService} from '../../../services/people-modal-handling.service';

@Component({
    selector: 'app-people-report',
    templateUrl: './people-report.component.html',
    styleUrls: ['./people-report.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleReportComponent extends ComponentFullView implements OnInit, OnDestroy {

    reportWidth: string;
    sideWidth: string;
    activationData: any;
    small = true;
    questionData: any = {};

    resendType = 'resendMpo';
    resendData;
    hideGraph = false;

    reportType = {
        mpo: {
            fr: 'Personnalité',
            en: 'Personality'
        },
        dit: {
            fr: 'Communication',
            en: 'Communication'
        },
        satellite: {
            fr: 'Satellite',
            en: 'Satellite'
        },
        talents: {
            fr: 'Talents',
            en: 'Talents'
        },
        ra: {
            fr: 'IAC',
            en: 'CAI'
        }
    };

    jobsList;

    private subscriptions = new Subscription();

    constructor(
        private modalService: BsModalService,
        protected route: ActivatedRoute,
        protected router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private apiPeopleService: ApiPeopleService,
        public stateService: StateService,
        public translateService: TranslateService,
        private apiJobs: ApiJobsService,
        private alertService: AlertService,
        public testAndReportHandling: TestAndReportHandlingService,
        public person: PersonService,
        public user: UserService,
        protected deviceService: DeviceDetectorService,
        private peopleModalHandlingService: PeopleModalHandlingService
    ) {
        super(stateService, translateService, router, deviceService);
    }

    ngOnInit() {
        let urlArray = this.router.url.split('/');
        let reportType = urlArray[urlArray.length - 1];
        if (reportType) {
            this.statePeople.reportType = reportType;
        }
        if (this.statePeople.reportType === 'iac' && !this.stateService.hasIac) {
            this.statePeople.reportType = 'personality';
        }
        this.small = (window.innerWidth < 1235);


        if (this.statePeople.goTo && this.person.id) {
            this.person.setPersonToDisplayWithId(this.person.id);
            // reload the person context after coming back from jobs
            if (this.statePeople.goTo === 'jobs') {
                let jobReportType = this.stateService.jobs.report.reportType;
                switch (jobReportType) {
                    case 'mpo':
                        jobReportType = 'personality';
                        break;
                    case 'dit':
                        jobReportType = 'communication';
                        break;
                    case 'ra':
                        jobReportType = 'iac';
                        break;
                }
                this.switchEvaluationType(jobReportType);
            }
            this.statePeople.goTo = null;
        }

        this.stateService.people.stateChanged$.subscribe(
            (res) => {
                if (res) {
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.hideGraph = true;
        this.subscriptions.add(this.apiJobs.jobs([{'fields': 'id'}]).
            subscribe(
            (checkJobs) => {
                this.jobsList = checkJobs.data;
                this.hideGraph = false;
                this.changeDetectorRef.markForCheck();
            })
        );

        // Set list width
        this.computeReportWidth(window);
    }

    get raPermission() {
        return this.stateService.hasIac;
    }

    //////////////////////////////////////////////////////////////////////

    onResize(event) {
        // re-Set list width
        this.computeReportWidth(event.target);
        this.small = (event.target.innerWidth < 1235);
    }

    reportChangeType(event) {
        this.statePeople.reportType = event;
        this.statePeople.stateChanged.next({'reportType': event});
    }

    hideQuestionDataPopup() {
        this.changeDetectorRef.markForCheck();
    }

    switchEvaluationType(newType) {
        if (!this.peopleModalHandlingService.checkReporUnaccessble(newType)) {
            let change = {reportType: newType};
            this.statePeople.reportType = newType;
            this.statePeople.stateChanged.next(change);
        }
    }

    showPopupModal(modal) {
        switch (modal) {
            case 'chooseSatelliteJob':
                return false; // TODO: call proper modal - refactoring
            case 'activateThisReport':
                if (!this.peopleModalHandlingService.checkReporUnaccessble(this.stateService.people.reportType)) {
                    return this.peopleModalHandlingService.openReportsActivationModal({
                            type: this.stateService.people.reportType,
                            invalid: false
                        });
                }
                return;
            case 'activateIacReport':
                return this.peopleModalHandlingService.openReportsActivationModal({
                    type: 'ra',
                    invalid: false
                });
            case 'sendMpo':
                return this.peopleModalHandlingService.openRequestSendMpoModal({person: this.person.personToDisplay, justMpo: true});
            case 'sendIac':
                return this.peopleModalHandlingService.openRequestSendIacModal({person: this.person.personToDisplay});
            case 'resendMpo':
                return this.peopleModalHandlingService.openRequestResendMpoModal({person: this.person.personToDisplay});
            case 'resendIac':
                return this.peopleModalHandlingService.openRequestResendIacModal({person: this.person.personToDisplay});
            case 'activateAllReports':
                return this.peopleModalHandlingService.openReportsActivationModal(
                    this.testAndReportHandling.activateMpoReportsHandling()
                );
        }
    }

    confirmRequestDelete(requestId, requestType) {
        const txtDeletionConf = (this.translateService.currentLang === 'fr') ? {
            paragraph: 'Attention. Vous êtes sur le point de supprimer un questionnaire ' + requestType + '. Désirez-vous continuer?',
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        } : {
            paragraph: 'Warning. You are about to delete a ' + requestType + ' request. Do you want to continue?',
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };
        this.alertService.setConfig(txtDeletionConf);
        const finalConf = {
            p: {
                fr: 'Questionnaire supprimé',
                en: 'Questionnaire deleted'
            },
            h: {
                fr: 'Supprimé',
                en: 'Deleted'
            }
        };
        this.subscriptions.add(this.alertService.nextAction$.subscribe(
            res => {
                if (res === 'confirm') {
                    this.subscriptions.add(this.apiPeopleService.deleteRequest(
                        [this.person.id],
                        {
                            subAccount: this.person.subAccount,
                            requestId: requestId
                        }
                    ).subscribe(
                        () => {
                            this.alertService.setConfig({
                                paragraph: finalConf.p[this.translateService.currentLang],
                                texth1: finalConf.h[this.translateService.currentLang],
                                position: 'top-right',
                                type: 'green'
                            });
                        }
                    ));
                }
            })
        );
    }


    /**
     * @param startWidth
     */
    computeReportWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 2 / 3;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.reportWidth = (innerWidth - leftColumnWidth - 39) * widthRatio + adjustment.left + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth - 39) * (1 - widthRatio) + adjustment.right + 'px';
        // @ilya 2020-10-16 - fix the side width to 400px
        this.sideWidth = '400px';
        this.reportWidth = (innerWidth - 550) + 'px';
        return;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get statePeople(): any {
        return this.stateService.people;
    }

    get sessionData(): any {
        return this.stateService.session.sessionData;
    }

    get showFakeReport(): boolean {
        return !(
            this.person.hasEvaluationsType(this.stateService.people.reportType) &&
            this.person.satelliteScore !== 'noJobProfile'
        );
    }

    get dataContentPopup() {
        if (this.showQuestionDataPopup) {
            const reportType = this.stateService.people.reportType;
            if (this.person.hasSatelliteJobProfile === 'noJobProfile') {
                // Chose a job profile for Satellite
                return {
                    title: 'people.reportSatellite.reportSatellite',
                    type: 'chooseSatelliteJob',
                    text: 'people.reportSatellite.chooseAJob',
                    click: true
                };
            } else if (!this.person.hasEvaluationsType(reportType)) {
                if (reportType !== 'iac' ) {
                    // MPO Reports
                    if (this.person.hasMpoOnHold) {
                        // Activate all reports
                        return {
                            title: 'people.sidebar.mpoReports',
                            type: 'activateAllReports',
                            text: 'people.sidebar.activateReports',
                            click: true
                        };
                    } else if (this.person.hasMpoRequest) {
                        // Enable resending 'MPO' report
                        return {
                            title: 'people.sidebar.mpoRequestHandling',
                            type: 'resendMpo',
                            text: 'commons.resendMpo',
                            click: true
                        };
                    } else if (this.person.hasAnMpoReport) {
                        // Activate this report
                        return {
                            title: 'people.sidebar.mpoReports',
                            type: 'activateThisReport',
                            text: 'people.sidebar.activateReport',
                            click: true
                        };
                    } else {
                        // Send Mpo questionnaire
                        return {
                            title: 'commons.mpoQuestionnaire',
                            type: 'sendMpo',
                            text: 'commons.sendMpo',
                            click: true
                        };
                    }
                } else {
                    // IAC Report
                    if (this.person.hasIacOnHold) {
                        // Activate Iac report
                        return {
                            title: 'people.sidebar.iacQuestionnaire',
                            type: 'activateIacReport',
                            text: 'people.sidebar.activateReport',
                            click: true
                        };
                    } else if (this.person.hasIacRequest) {
                        // Enable resending Iac report
                        return {
                            title: 'people.sidebar.iacQuestionnaire',
                            type: 'resendIac',
                            text: 'commons.resendIac',
                            click: true
                        };
                    } else {
                        // Send Iac questionnaire
                        return {
                            title: 'people.sidebar.iacQuestionnaire',
                            type: 'sendIac',
                            text: 'commons.sendIac',
                            click: true
                        };
                    }
                }
            }
        }
        return {
            type: null,
            text: null,
            click: false
        };
    }

    /** alias */
    get showQuestionDataPopup() {
        return this.showFakeReport;
    }

}
