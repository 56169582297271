import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PeopleSurveyRequestFormService} from '../../../../../../services/people-survey-request-form.service';
import { ApiJobsService } from 'src/app/jobs/services/api-jobs.service';
import { PeopleSurveyRequestHelper } from '../../../people-survey-request.helper';

@Component({
  selector: 'app-mobile-options-info',
  templateUrl: './mobile-options-info.component.html',
  styleUrls: ['./mobile-options-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileOptionsInfoComponent implements OnInit, OnDestroy {
    @Input() surveyRequestFormGroup: FormGroup;
    @Input() sessionData: any;
    @Input() isFormSubmitted: boolean;

    private onDestroy$: Subject<null> = new Subject();
    public isOnlyIACSelected$: Observable<boolean>;
    public isOnlyIACSelected = false;
    public isOnlyMpoSelected$: Observable<boolean>;
    public isSatelliteSelected$: Observable<boolean>;
    public satellidJobList$: Observable<any>;
    public satelliteJobs: any;
    public variantOptions$: Observable<any[]>;
    public trackById: any; // Function;
    public countryList: any[];
    public langList$: Observable<any[]>;

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private apiJobs: ApiJobsService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        this.trackById = PeopleSurveyRequestHelper.trackById;

        this.isOnlyMpoSelected$ = this.peopleRequestFormService.isOnlyMpoSelected$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.isOnlyIACSelected$ = this.peopleRequestFormService.isOnlyIACSelected$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.isOnlyIACSelected$.subscribe((res) => {
            this.isOnlyIACSelected = res;
        });

        this.isSatelliteSelected$ = this.peopleRequestFormService.isSatelliteSelected$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.variantOptions$ = this.peopleRequestFormService.variantOptions$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());

        this.satellidJobList$ = this.apiJobs.jobs([{'isSatellite': 1, 'fields': 'id,jobTitle'}]).pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.satellidJobList$.subscribe((jobs) => {
            this.satelliteJobs = jobs.data;
        });
        this.countryList = this.sessionData.structure.countries[this.translateService.currentLang];
        this.langList$ = this.peopleRequestFormService.langList$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
