<div class="mainContainer" [class.top150]="section == 'admin'">
    <ul class="toolsNav nav nav-tabs" style="border-bottom: 1px solid #ddd;">
        <li [class.active]="actualTool==='profileSearch'">
            <a class="tdnone" (click)="switchTools('profileSearch')">Recherche de profils</a>
        </li>
        <li [class.active]="actualTool==='profileTransfers'">
            <a class="tdnone" (click)="switchTools('profileTransfers')">Transfers de profils</a>
        </li>
        <li [class.active]="actualTool==='stats'">
            <a class="tdnone" (click)="switchTools('stats')">Statistiques</a>
        </li>
    </ul>
    <div class="mainContent">
        <div
            *ngIf="actualTool==='profileSearch'"
        >
            <app-profile-search></app-profile-search>
        </div>
        <!--div
            *ngIf="actualTool==='profileTransfers'"
        >
            <app-profile-transfers></app-profile-transfers>
        </div-->
        <div
            *ngIf="actualTool==='stats'"
        >
            <app-statistics></app-statistics>
        </div>
    </div>
</div>
