import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    ElementRef,
    Component,
    Output,
    ViewChild,
    OnInit,
    OnDestroy, Input
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiPeopleService} from '../../../services/api-people.service';
import {EvaluationRequestNext} from '../../../models/evaluation-request-next.model';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Observable, combineLatest, Subscription} from 'rxjs';
import {distinctUntilChanged, first, take, takeUntil} from 'rxjs/operators';
import {WindowRef} from '../../../../app/services/window-ref.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {EvaluationRequest} from '../../../models/evaluation-request.model';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {PeopleSurveyRequestHelper} from '../../commonClasses/people-survey-request/people-survey-request.helper';
import {PeopleRequestSendModalCommon} from '../../commonClasses/people-request-send-modal-common';
import {PeopleSurveyRequestFormService} from '../../../services/people-survey-request-form.service';
import {PeopleSurveyRequestStep} from '../../commonClasses/people-survey-request/people-survey-request-step.enum';
import {AlertService} from 'src/app/shared/alert/services/alert.service';
import {ApiJobsService} from 'src/app/jobs/services/api-jobs.service';
import {TextHelper} from '../../../../shared/misc/text.helper';
import {HttpClient} from '@angular/common/http';
import {TestAndReportHandlingService} from '../../../services/test-and-report-handling.service';
import {PersonService} from '../../../services/person.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-people-request-send-resend',
    templateUrl: './mobile-people-request-send-resend.component.html',
    styleUrls: ['./mobile-people-request-send-resend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeopleRequestSendResendComponent extends PeopleRequestSendModalCommon implements OnInit, OnDestroy {

    @ViewChild('top') top: ElementRef;
    @Output() activation = new EventEmitter();
    @Output() close = new EventEmitter();
    @Output() goBack = new EventEmitter;
    @Input() origin = 'people';

    private onDestroy$: Subject<null> = new Subject();
    public requestStep$: Observable<number>;
    public isRequestFormSubmitted$: Observable<boolean>;
    public tempUser$: Observable<any>;
    public apiMessageError$: Observable<string>;
    public formOptions: any;
    public requestStep: number;
    public stepEnum = PeopleSurveyRequestStep;
    public anotherRequest = false;
    public surveyRequestFormGroup: FormGroup;
    public isFormReady = false;
    public researchAccount = false;

    continueClicked = false;

    openOptions = [];

    reportToActivate = {
        mpo: false,
        dit: false,
        satellite: false,
        talents: false
    };
    reports = {
        mpo: false,
        talents: false,
        dit: false,
        satellite: false
    };

    flipValue: number;
    toggle = 0;
    nativeWindow: any;

    satelliteReportSelected = false;
    satelliteJobs: any;

    emptyEmail = false;
    structureCountries: any;

    creditAvailable: any = '';
    displayWarningMessage = false;
    creditCost = 0;
    currentLanguage: string;
    accountType: any;
    disableDitReportByDefault = false;
    blurred = false;

    protected subscriptions = new Subscription();

    evaluationFormUrl: string;

    constructor(protected fb: FormBuilder,
                private apiPeopleService: ApiPeopleService,
                private peopleRequestFormService: PeopleSurveyRequestFormService,
                private alertService: AlertService,
                protected translateService: TranslateService,
                private windowRef: WindowRef,
                private el: ElementRef,
                public stateService: StateService,
                protected cd: ChangeDetectorRef,
                protected apiAdministrationService: ApiAdministrationService,
                protected apiJobsService: ApiJobsService,
                protected testAndReportHandlingService: TestAndReportHandlingService,
                private http: HttpClient,
                public personService: PersonService,
                private environment: EnvService
    ) {
        super(
            apiJobsService,
            apiAdministrationService,
            cd,
            translateService,
            testAndReportHandlingService,
            stateService,
            fb
        );
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    ngOnInit() {
        // Reinit dyn vars
        this.toggle = 0;
        this.structureCountries = (this.sessionData && this.sessionData.structure) ? this.sessionData.structure.countries : null;
        this.accountType = this.sessionData ? this.sessionData.accountType : null;
        this.flipValue = undefined;

        this.currentLanguage = this.translateService.currentLang;
        this.disableDitReportByDefault = !PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.accountType);

        this.setModalTitles();

        this.getLanguages();
        if (this.resendForm) {
            this.getResendIntroductionText();
        }
        this.accountType = this.sessionData ? this.sessionData.accountType : null;
        this.currentLanguage = this.translateService.currentLang;
        this.disableDitReportByDefault = !PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.accountType);
        if (this.targetPerson) {
            // Reinit credits vars
            this.displayWarningMessage = false;
            this.creditAvailable = '';
            // get credits available for this subAccount
            const subAccount = this.targetPerson.subAccount;
            // get current norm (country & language) if exists
            if (this.requestNorm) {
                const currentNorm = this.requestNorm.split(' ');
                if (currentNorm && currentNorm[0] && currentNorm[0].length === 2) {
                    this.country = currentNorm[0].toLowerCase();
                    if (currentNorm[0] === 'FR') {
                        this.country = 'eu';
                    }
                }
                if (currentNorm && currentNorm[1] && currentNorm[1].length === 2) {
                    this.language = currentNorm[1].toLowerCase();
                }
            }
            const department = (this.targetPerson.department && this.targetPerson.department[0]) ? this.targetPerson.department[0].id : null;
            this.emptyEmail = this.targetPerson.email === '';
            let formControls = {
                satelliteJobId: new FormControl(null),
                variant: new FormControl({value: null, disabled: true}, Validators.required),
                country: new FormControl(this.country, Validators.required),
                language: new FormControl(this.language, Validators.required),
                // Participant
                firstName: new FormControl(this.targetPerson.firstName, Validators.required),
                lastName: new FormControl(this.targetPerson.lastName, Validators.required),
                email: new FormControl(this.targetPerson.email, [Validators.required, Validators.email]),

                // Survey request
                jobTitle: new FormControl(this.targetPerson.jobTitle),
                organisation: new FormControl(this.targetPerson.organisation, (this.stateService.session.sessionData.accountData.type === 'bizdev'
                    || this.stateService.session.sessionData.accountData.type === 'discretionary'
                ) ? Validators.required : null),
                recordType: new FormControl(this.targetPerson.recordType),
                subAccount: new FormControl(this.targetPerson.subAccount, Validators.required),
                department: new FormControl(department),
                jobId: new FormControl(this.targetPerson.jobId),
                requestedByName: new FormControl((this.targetPerson.requestedByInfo) ? this.targetPerson.requestedByInfo.value : ''),
                requestedBy: new FormControl(this.sessionData.userData.username),
                requester: new FormControl(),
                sendingType: new FormControl(null, Validators.required),
                autoGen: new FormControl({value: false, disabled: true})
            };
            console.log(this.targetPerson);
            if (this.resendForm) {
                formControls.sendingType = new FormControl(this.targetPerson.evaluations.requests[0].type);
                formControls.variant = new FormControl(this.targetPerson.evaluations.requests[0].variant);
                formControls.language = new FormControl(this.targetPerson.evaluations.requests[0].language);
                formControls.country = new FormControl(this.targetPerson.evaluations.requests[0].country);
            }
            if (this.raForm || this.prbForm) {
                delete formControls.variant;
                formControls.autoGen = new FormControl({value: true, disabled: false});
            }
            this.surveyRequestFormGroup = new FormGroup(formControls);
            if (this.resendForm) {
                // resend form case
                this.checkRequestContext();
                this.cd.markForCheck();
            } else {
                // MPO / PRB / CAI form case
                this.getSendingTypesList(this.raForm);
                this.sendingTypes[0].value = '';
                this.sendingTypes[0].title = '';
                if (!this.raForm && this.targetReport) {
                    this.checkIfActive({checked: true}, this.targetReport);
                }
                this.getCredits(subAccount);
                this.subscriptions.add(this.surveyRequestFormGroup.get('subAccount').valueChanges.subscribe(subAccountId => {
                    if (subAccountId && subAccountId.toString()) {
                        this.getCredits(subAccountId);
                    }
                    this.cd.markForCheck();
                }));
            }
            this.isFormReady = true;
            this.subscriptions.add(this.apiJobsService.jobs([{'isSatellite': 1, 'fields': 'id,jobTitle'}]).subscribe( res => {
                this.satelliteJobs = res.data;
                this.cd.markForCheck();
            }));
        }  else {
            // Form options for a new survey request
            this.formOptions = {
                isSatelliteOnly: this.stateService.session.hasSatelliteRole,
                canCreateDitWithoutMpo: PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.accountType)
            };

            // Form structure
            this.surveyRequestFormGroup = this.peopleRequestFormService.initSurveyRequestFormGroup(this.sessionData, this.formOptions);

            // Request step
            this.requestStep$ = this.peopleRequestFormService.requestStep$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
            if (this.requestStep$) {
                this.requestStep$.subscribe((res) => {
                    this.requestStep = res;
                });
            }

            // Is request form submitted
            this.isRequestFormSubmitted$ = this.peopleRequestFormService.isRequestFormSubmitted$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());

            // API Error
            this.apiMessageError$ = this.peopleRequestFormService.apiMessageError$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());

            // Temp user after send the request to the API
            this.tempUser$ = this.peopleRequestFormService.tempUser$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
            if (this.tempUser$) {
                this.tempUser$.subscribe((res) => {
                    this.tempUser = res;
                });
            }

            this.setResearchAccount();
        }
        this.scrollTop();
    }

    scrollTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    getCredits(subAccount) {
        this.subscriptions.add(this.apiAdministrationService.clientSubaccountCredits(
            [this.sessionData.accountData.id, subAccount]
        ).subscribe(
            (res) => {
                if (res.unlimited) {
                    this.creditAvailable = 'unlimited';
                    if (this.currentLanguage === 'fr') {
                        this.creditAvailable = 'illimité';
                    }
                    this.displayWarningMessage = false;
                } else {
                    this.creditAvailable = res.credits;
                    this.displayWarningMessage = (this.creditCost > this.creditAvailable);
                }
                if (this.displayWarningMessage) {
                    this.disableAuoGen();
                } else {
                    let hasSelReport = false;
                    if (!this.raForm) {
                        for (let report in this.reports) {
                            if (report && this.reports.hasOwnProperty(report) && this.reports[report]) {
                                hasSelReport = true;
                            }
                        }
                    } else {
                        hasSelReport = true;
                    }
                    if (hasSelReport) {
                        this.enableCheckAutoGen();
                    }
                }
                if (this.raForm) {
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost({'iac': true}, '0,0');
                } else if (this.prbForm) {
                    let hasPrb = !!(this.targetPerson &&
                        this.targetPerson.evaluations &&
                        this.targetPerson.evaluations.prb &&
                        this.targetPerson.evaluations.prb.list[0] &&
                        this.targetPerson.evaluations.prb.list[0].info &&
                        this.targetPerson.evaluations.prb.list[0].info.status);
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost({'mpo': true}, '0,1', !hasPrb);
                } else {
                    this.creditCost = PeopleSurveyRequestHelper.getCreditCost(this.reports, '1,1');
                }
                this.cd.markForCheck();
            }
        ));
    }

    computeCredits() {
        this.creditCost = PeopleSurveyRequestHelper.getCreditCost(this.reports, '1,1');
        this.displayWarningMessage = (this.creditCost > this.creditAvailable);
    }

    checkIfActive(event, type) {
        if (type) {
            this.satelliteReportSelected = false;
            if (this.raForm) {
                // CAI form case
                this.reportToActivate[type] = event.checked;
                for (let report in this.reportToActivate) {
                    if (report && report === 'satellite' && this.reportToActivate[report]) {
                        this.satelliteReportSelected = true;
                    }
                }
            } else {
                // MPO form case
                this.reports[type] = event.checked;
                this.computeCredits();
                let enableVariant = false;
                for (let report in this.reports) {
                    if (report && this.reports[report]) {
                        enableVariant = true;
                        if (report === 'satellite') {
                            this.satelliteReportSelected = true;
                        }
                    }
                }
                // TODO, remove references to mpo and dit
                if (this.reports['mpo'] || this.reports['dit']) {
                    this.openOptions = ['traitsAndPrb', 'traitsOnly'];
                } else {
                    this.openOptions = ['traitsOnly'];
                }

                if (enableVariant) {
                    this.surveyRequestFormGroup.get('variant').enable({onlySelf: true, emitEvent: false});
                    if (this.displayWarningMessage) {
                        this.disableAuoGen();
                    } else {
                        this.enableCheckAutoGen();
                    }
                } else {
                    this.surveyRequestFormGroup.get('variant').disable({onlySelf: true, emitEvent: false});
                    this.disableAuoGen();
                }
            }
        }
    }

    enableCheckAutoGen() {
        this.surveyRequestFormGroup.get('autoGen').setValue(true, {onlySelf: true, emitEvent: false});
        this.surveyRequestFormGroup.get('autoGen').enable();
    }

    disableAuoGen() {
        this.surveyRequestFormGroup.get('autoGen').setValue(false, {onlySelf: true, emitEvent: false});
        this.surveyRequestFormGroup.get('autoGen').disable();
    }

    produceRequest(toggleNumber) {
        if (this.targetPerson) {
            const formattedValues = PeopleSurveyRequestHelper.formatRequestValues(this.surveyRequestFormGroup.value, this.researchAccount);
            let variant;
            let type;
            if (!this.raForm) {
                if (!this.prbForm) {
                    // MPO form case
                    variant = this.surveyRequestFormGroup.get('variant').value;
                    type = [];
                    for (const [key, value] of Object.entries(this.reports)) {
                        if (value === true) {
                            type.push(key);
                        }
                    }
                } else {
                    // Additional PRB form case
                    variant = '0,1';
                    type = ['mpo'];
                }

            } else {
                // CAI form case
                variant = '0,0';
                type = ['iac'];
            }
            let data = new EvaluationRequest({
                recordId: this.targetPerson.id,
                variant: this.surveyRequestFormGroup.get('variant').value ?? variant,
                country: this.surveyRequestFormGroup.get('country').value,
                language: this.surveyRequestFormGroup.get('language').value,
                firstName: this.surveyRequestFormGroup.get('firstName').value,
                lastName: this.surveyRequestFormGroup.get('lastName').value,
                email: this.surveyRequestFormGroup.get('email').value,
                jobTitle: this.surveyRequestFormGroup.get('jobTitle').value,
                organisation: this.surveyRequestFormGroup.get('organisation').value,
                recordType: this.surveyRequestFormGroup.get('recordType').value,
                subAccount: this.surveyRequestFormGroup.get('subAccount').value,
                autoGen: this.surveyRequestFormGroup.get('autoGen').value,
                department: formattedValues.department,
                jobId: this.surveyRequestFormGroup.get('jobId').value,
                requestedByName: this.surveyRequestFormGroup.get('requestedByName').value,
                requestedBy: this.sessionData.userData.username,
                type: this.surveyRequestFormGroup.get('sendingType').value ?? type,
                researchQuestions: false,
                createTempUser: true
                // hasSurveyRa: false <-- not necessary?
            });

            if (this.raForm) {
                for (let report in this.reportToActivate) {
                    if (report && this.reportToActivate[report]) {
                        this.activationData.type = report;
                        if (report === 'satellite') {
                            this.activationData.satelliteJobId = this.surveyRequestFormGroup.get('satelliteJobId').value;
                            this.bindedProfiler = this.surveyRequestFormGroup.get('satelliteJobId').value;
                        }
                        this.reportActivationCommon(report);
                        this.stateService.session.sessionCreditUpdateWatch.next(true);
                    }
                }
            }

            // Generate request
            if (this.resendForm) {
                // resend form case
                this.apiPeopleService.evaluationRequestResend([this.targetPerson.id, 0]).pipe(first()).subscribe(
                    resendData => {
                        this.evaluationRequestResend = new EvaluationRequestNext();
                        this.tempUser = (resendData) ? resendData : null;
                        this.person = this.targetPerson;
                        this.evaluations = this.person ? this.person.evaluations : null;
                        this.language = (this.evaluations &&
                            this.evaluations.requests &&
                            this.evaluations.requests[0]) ? this.evaluations.requests[0].language : null;
                        this.country = (this.evaluations &&
                            this.evaluations.requests &&
                            this.evaluations.requests[0]) ? this.evaluations.requests[0].country : null;
                        // @ts-ignore
                        this.resendType = (data && data.type) ? data.type : null;
                        this.introductionText = this.resendType ? this.introductionTextObj[this.resendType] : null;
                        let raForm = false;
                        if (this.resendType === 'resendIac') {
                            raForm = true;
                        }
                        this.getSendingTypesList(raForm);

                        if (this.resendType === 'resendMpo') {
                            this.questionnaireType = 'MPO';
                        }
                        if (this.resendType === 'resendPrb') {
                            this.questionnaireType = this.introductionTextObj.prbTitle;
                        }
                        if (this.resendType === 'resendIac') {
                            this.questionnaireType = this.introductionTextObj.raTitle;
                        }

                        if (this.tempUser && this.tempUser.email) {
                            this.evaluationRequestResend.emailTo = this.tempUser.email;
                        }

                        // Init
                        this.initEvaluationResendForm();
                        if (this.person && this.tempUser) {
                            this.mailto = this.initMessage('mail', this.person, this.tempUser);
                            this.innerHtml = this.initMessage('cutnpaste', this.person, this.tempUser);
                        }
                        this.toggle = toggleNumber;
                        this.cd.markForCheck();
                    }
                );
            } else {
                this.apiPeopleService.evaluationRequest(data).subscribe(
                    (res) => {
                        this.checkRequestContext(res, false);
                        if (res && res.additionalData && res.additionalData.recordId) {
                            let reportType = (this.stateService.people.reportType) ? this.stateService.people.reportType :  'personality';
                            if (this.origin === 'people') {
                                this.personService.setPersonToDisplayWithId(res.additionalData.recordId, 'mobileView', 'people', reportType);
                            }
                        }
                        // proceed to sending
                        this.toggle = toggleNumber;
                        this.cd.markForCheck();
                    }
                );
            }
        }
    }

    formValidate() {
        this.continueClicked = true;
        if (this.surveyRequestFormGroup.valid) {
            let sendingType = this.surveyRequestFormGroup.get('sendingType').value ?? null;
            this.toggleFlip(sendingType);
        } else {
            this.surveyRequestFormGroup.markAllAsTouched();
            this.scrollToFirstInvalidControl();
        }
    }

    scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
            '.questionnaireForm .ng-invalid'
        );
        let toScroll = true;
        if (this.surveyRequestFormGroup && this.surveyRequestFormGroup.controls &&
            (
                (this.surveyRequestFormGroup.controls.subAccount &&
                    !this.surveyRequestFormGroup.controls.subAccount.valid
                ) ||
                (this.surveyRequestFormGroup.controls.sendingType &&
                    !this.surveyRequestFormGroup.controls.sendingType.valid)
            )) {
            toScroll = false;
        }
        if (toScroll) {
            firstInvalidControl.parentElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    onFormChange(requestForm: any) {
        if (requestForm.form && requestForm.form.value) {
            this.emptyEmail = requestForm.form.value.emailTo === '';
        }
    }

    toggleFlip(sendingType = null) {
        sendingType = String(sendingType);
        if (sendingType && sendingType !== '0' && sendingType !== '1' && !this.displayWarningMessage) {
            this.produceRequest(sendingType);
        }
        return;
    }

    openEvaluationForm() {
        // Open window to avoid popup blocking

        let questionnaireWindow = this.nativeWindow.open('', '_blank');

        this.subscriptions.add(this.apiPeopleService.requestToken([this.person.id]).subscribe(
            res => {
                // Modification of URL in window open after getting data
                questionnaireWindow.document.location.href = this.evaluationFormUrl + '/autoconnect/' + res.token;
            }
        ));
    }

    setResearchAccount() {
        // Lists of research accounts with lang and country parameters.
        // and then:
        this.http.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/researchAccounts', {responseType: 'text'})
            .subscribe(data => {
                const researchAccounts = JSON.parse(data);
                // Checks if current account is in the list of researchAccounts
                // tslint:disable-next-line:radix
                const accountNumber = parseInt(this.stateService.session.sessionData.accountNumber);
                for (let researchAccount of researchAccounts) {
                    if (researchAccount.accountNumber === accountNumber) {
                        this.researchAccount = researchAccount;
                        break;
                    }
                }

                // Do the proper setup
                if (!!this.researchAccount) {
                    this.peopleRequestFormService.setResearchQuestionsForm(this.surveyRequestFormGroup, this.researchAccount);
                } else {
                    this.peopleRequestFormService.notAResearchQuestionsForm();
                }
            });
    }

    public goToTop() {
        if (this.top) {
            this.top.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
    }

    /**
     * Reset form values and Observables
     */
    public resetRequestForm(): void {
        this.peopleRequestFormService.sendAnotherProcess(this.surveyRequestFormGroup);
    }

    public resetAndGoBack(event = null) {
        this.resetRequestForm();
        this.goBack.emit();
    }

    /**
     * Send another request
     */
    public sendAnotherRequest(): void {
        this.anotherRequest = true;
        this.resetRequestForm();
        this.displayAlertMessage();
    }

    /**
     * Display confirmation message in alert
     */
    private displayAlertMessage(): void {
        // Get translations
        combineLatest(
            this.translateService.get('people.alert.successRequest.title'), this.translateService.get('people.alert.successRequest.paragraph')
        ).pipe(take(1)).subscribe((translationData: any) => {
            // Sending confirmation
            const message = {
                texth1: translationData[0],
                paragraph: translationData[1],
                position: 'top-right',
                type: 'green'
            };
            // Display alert
            this.alertService.setConfig(message);
        });
    }

    finishSending() {
        // console.log('here');
        this.cancelSending();
    }

    cancelSending() {
        // Reset data
        this.toggle = 0;
        this.language = undefined;
        this.country = undefined;
        this.flipValue = undefined;
        this.evaluationRequestResend = new EvaluationRequestNext();
        this.close.emit(true);
    }

    onSubmit(model: any) {
        this.subscriptions.add(this.apiPeopleService.emailEvaluationSurvey(
            [this.tempUser.id],
            [model.value.emailTo, model.value.message, false]
        ).subscribe(
            () => {
                this.cancelSending();
            },
            error => {
                console.log(error._body);
            }
        ));
    }

    checkFirstQ() {
        if (this.evaluations) {
            let minDate;
            let firstQ;
            for (let ev in this.evaluations) {
                if (ev && ev !== 'requests' && ev !== 'researchQuestions' &&
                    ev !== 'prb' && ev !== 'iac') {
                    if (this.evaluations.hasOwnProperty(ev) && this.evaluations[ev]) {
                        if (!minDate || this.evaluations[ev].info.date < minDate) {
                            minDate = this.evaluations[ev].info.date;
                            firstQ = ev;
                        }
                    }
                }
            }
            if (!firstQ && this.evaluations.requests && this.evaluations.requests[0]) {
                firstQ = this.evaluations.requests[0].type[0];
            }
            return TextHelper.checkReportName(firstQ, this.currentLanguage);
        }
        return;
    }

    getCountryName(countryCode) {
        if (this.structureCountries && this.structureCountries[this.currentLanguage]) {
            const thisCountry = this.structureCountries[this.currentLanguage].filter(country => country.value === countryCode);
            return thisCountry[0].name;
        }
    }

    get requestNorm() {
        if (this.targetPerson &&
            this.targetPerson.evaluations
        ) {
            let currentNorm = '';
            ['mpo', 'dit', 'talents', 'satelite', 'iac'].forEach(request => {
                if (this.targetPerson.evaluations[request] &&
                    this.targetPerson.evaluations[request].info &&
                    currentNorm === '') {
                    currentNorm = this.targetPerson.evaluations[request].info.norm;
                }
            });
            return currentNorm;
        }
        return;
    }

    get questionnaireInitialType() {
        if (this.resendType === 'resendIac') {
            return 'ra';
        }
        if (this.evaluations && this.evaluations.requests &&
            this.evaluations.requests[0] && this.evaluations.requests[0].variant) {
            if ((this.resendType === 'resendMpo' && this.evaluations.requests[0].type.includes('mpo')) ||
                (this.resendType === 'resendPrb' && this.evaluations.requests[0].type.includes('prb'))) {
                this.language = this.evaluations.requests[0].language;
                this.country = this.evaluations.requests[0].country;
                if (this.evaluations.requests[0].type.includes('ra')) {
                    return this.evaluations.requests[0].variant + ',ra';
                }
                return this.evaluations.requests[0].variant;
            }
        }
        return (this.person.questionnairesData && this.person.questionnairesData.initial) ?
            this.person.questionnairesData.initial.type : null;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        // new survey request case
        if (!this.sendAData) {
            this.onDestroy$.next();
            this.onDestroy$.complete();

            // Clear form data
            this.peopleRequestFormService.resetFormProcess(this.surveyRequestFormGroup);
        }
    }

}
