<div>
    <div *ngIf="translateService.currentLang == 'fr'">
        Nouvelles
        <!--div class="alert alertPositioningFrench">&nbsp;</div-->
    </div>
    <div *ngIf="translateService.currentLang == 'en'">
        News
        <!--div class="alert alertPositioningEnglish">&nbsp;</div-->
    </div>

</div>
