import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';
import {AccountService} from '../../services/account.service';
import {StateService} from '../../../core/services/state/state.service';
import {Router} from '@angular/router';
import {IMyDpOptions} from 'mydatepicker';

@Component({
    selector: 'app-administration-transactions-filter-modal',
    templateUrl: './administration-transactions-filter-modal.component.html',
    styleUrls: ['./administration-transactions-filter-modal.component.scss']
})
export class AdministrationTransactionsFilterModalComponent implements OnInit {

    @Input() selectedFilters;

    protected readonly Object = Object;
    protected readonly Math = Math;

    public close: EventEmitter<any> = new EventEmitter();

    datePickerOptions: {
        fr: IMyDpOptions,
        en: IMyDpOptions
    } = {
        fr: {dateFormat: 'dd/mm/yyyy'},
        en: {dateFormat: 'mm/dd/yyyy'}
    };

    public filters: any;

    readonly transactionKeys = [
        'adjustment',
        'creation_subAccount',
        'creation_demo',
        'reports',
        'formations',
        'annual_fees',
        'buying_credits',
        'buying_programs'
    ];

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        public cd: ChangeDetectorRef,
        public accountService: AccountService
    ) {
    }

    ngOnInit() {
        this.setFilters(this.selectedFilters);
    }

    selectNoTransaction() {
        this.filters.selectedTransactions = {
            adjustment: false,
            creation_subAccount: false,
            transfer_credits: false,
            creation_demo: false,
            reports: false,
            formations: false,
            annual_fees: false,
            buying_credits: false,
            buying_programs: false
        };
        this.cd.markForCheck();
    }

    selectAllTransactions() {
        this.filters.selectedTransactions = {
            adjustment: true,
            creation_subAccount: true,
            transfer_credits: true,
            creation_demo: true,
            reports: true,
            formations: true,
            annual_fees: true,
            buying_credits: true,
            buying_programs: true
        };
        this.cd.markForCheck();
    }

    setFilters(selectedFilters) {
        this.filters = JSON.parse(JSON.stringify(selectedFilters));
        this.cd.markForCheck();
    }

    setDateFilters(e, dateType) {
        this[dateType] = e.formatted;
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    saveFilters() {
        this.close.emit(this.filters);
        this.closeModal();
    }

    resetFilters() {
        this.filters = {
            selectedTransactions:
                {
                    adjustment: true,
                    creation_subAccount: true,
                    transfer_credits: true,
                    creation_demo: true,
                    reports: true,
                    formations: true,
                    annual_fees: true,
                    buying_credits: true,
                    buying_programs: true
                },
            subAccount: null,
            startDate: null,
            endDate: null
        };
        this.close.emit(this.filters);
        this.closeModal();
    }

}
