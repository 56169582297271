<ng-sidebar-container style="position: absolute; height: 100vh">

    <!-- A sidebar -->
    <ng-sidebar
            [position]="'right'"
            [(opened)]="graphBoardsList._opened"
            [animate]="false"
            [closeOnClickOutside]="false"
            [autoFocus]="false"
            (openedChange)="onSidebarStatusChange($event)"
            #gSidebar
    >

        <div>
            <div class="outerColumnGrey col-md-4">
                <div class="innerColumnGrey" [style.width]="sideWidth">

                    <div *ngIf="!graphBoardsList.sideBarStatus || graphBoardsList.sideBarStatus=='recentActivities' || graphBoardsList.sideBarStatus==''">
                        <app-infopanel
                            [width]="sideWidth"
                        ></app-infopanel>
                    </div>

                    <div *ngIf="graphBoardsList.sideBarStatus=='searchFilters'">
                        <app-graphboards-search-filters
                            [whereAmI]="'filter'"
                            [width]="sideWidth"
                            (switchOff)="graphBoardsList.switchOffFilterPanelIndicator($event)"
                        ></app-graphboards-search-filters>
                    </div>

                    <div *ngIf="graphBoardsList.sideBarStatus=='reports' && graphBoard.id">
                        <app-graphboards-sidebar
                            [width]="sideWidth"
                            [config]="'list'"
                            [archivedRecords]="archivedRecords"
                            (closeSidebar)="closeGraphBoardsSidebar($event)"
                        ></app-graphboards-sidebar>
                    </div>
                    <div *ngIf="graphBoardsList.sideBarStatus=='clickMode'">
                        <app-graphboards-selected-items
                            (switchOff)="graphBoardsList.switchOffFilterPanelIndicator($event, true)"
                            [width]="sideWidth"
                            (actionOnRecords)="actOnRecords($event)"
                        ></app-graphboards-selected-items><!-- (completeAction)="completeAction($event)" -->
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar>

    <div ng-sidebar-content
         (window:resize)="onResize($event)"
         (window:orientationchange)="onResize($event)"
         style="overflow-x: hidden"
    >
        <div class="rightWrapper row right-wrapper-tablet"

             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"

             (scrolled)="graphBoardsList.listScrolled()"
        >
            <div class="outerColumnWhite"
                 [class.col-md-8]="!smallSize"
                 [class.clear]="!smallSize"
                 [style.width]="(!smallSize) ? listWidth : null"
            >
                <div
                        id="topMenuWrapper"
                        class="affix"
                        [class.topMenuCalcWidth]="smallSize"
                        [style.width]="(!smallSize) ? listWidth : null"
                >
                    <div>
                        <div class="pull-left">
                            <div class="pull-left">
                                <h1 class="sTitle">
                                    <span *ngIf='!graphBoardsList.archive'>{{ 'commons.graphboard' | translate }}</span>
                                    <span *ngIf='graphBoardsList.archive'>{{ 'commons.archives' | translate }}</span>
                                </h1>
                            </div>
                        </div>
                        <div class="pull-right">
                            <app-main-list-search-bar
                                [switchOff]="graphBoardsList.switchOff"
                                [selectionByFilters]="graphBoardsList.selectionByFilters"
                                (searchEvent)="setSearchFilter($event)"
                                (filterPanel)="graphBoardsList.toggleFilterPanel()"
                            ></app-main-list-search-bar>
                            <div class="clearfix"></div>
                            <div class="searchResults">
                                <span
                                    style="font-weight: bold"
                                    *ngIf="graphBoardsList.selectionDone && graphBoardsList.totalListItems !== null"
                                >
                                    {{ graphBoardsList.totalListItems }}
                                    {{ graphBoardsList.resMention | translate }}
                                    <span *ngIf="graphBoardsList.totalItems">
                                        {{ 'commons.of' | translate }} {{ graphBoardsList.totalItems }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div id="subMenu">
                        <div class="pull-left" id="subMenuLeft">
                            <div>
                                <table class="nav nav-topbar">
                                    <tr class="topMenu">
                                        <td *ngIf="!graphBoardsList.archive" (click)="graphBoardsModalHandlingService.openGraphBoardModal({status:'new'})">
                                            <a>
                                                <div>
                                                    <div style="float:left">
                                                        <i class="material-icons" style="color: #CCCCCC; margin-top: 2px">add</i>
                                                    </div>
                                                    <div style="float: right; margin-top: 7px;">{{ 'commons.nouvelle' | translate }}</div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </a>
                                        </td>
                                        <td [class.clickMode]="graphBoardsList.clickMode" (click)="graphBoardsList.switchMode()">
                                            <a>
                                                <div>
                                                    <img src="assets/header/comparer/ico_comparer.png" alt=""/>
                                                    <div>
                                                        {{ 'commons.toSelect' | translate }}
                                                    </div>
                                                    <img src="assets/header/comparer/ico_comparer_selected.png" alt=""/>
                                                </div>
                                            </a>
                                        </td>
                                        <td (click)="refreshList()">
                                            <a>
                                                <div style="padding-top: 2px">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                                                        <path
                                                            fill="#AAA"
                                                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99
                                                        8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6
                                                        6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div *ngIf='!graphBoardsList.archive'>
                                <div
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/jobsatisfaction/list'])"
                                >
                                    <div>{{ 'commons.jobSatisfaction' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/rightmatch/list'])"
                                >
                                    <div>Right Match</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasCommunication"
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/idwmatrix/list'])"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    [class.typeSelected]="true"
                                >
                                    <div>{{ 'commons.graphboard' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="graphBoardsList.archive">
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/rightmatch']"
                                >
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasCommunication"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/idwmatrix']"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right typeSelected"
                                >
                                    <div>{{ 'commons.graphboard' | translate }} </div>
                                </div>
                                <div
                                    *ngIf="stateService.hasJobAccess"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/jobs']"
                                >
                                    <div>{{ 'commons.jobs' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    [routerLink]="['/archives/list/people']"
                                >
                                    <div>{{ 'commons.individus' | translate }}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div *ngIf="graphBoardsList.toList" [style.height]="(graphBoardsList.toList['count']==0) ? '700px': null" id="listWrap">
                    <ngx-loading
                        [show]="graphBoardsList.loading"
                        [config]="loaderDisplay"
                    ></ngx-loading>
                    <app-main-list
                        [small]="smallSize"
                        [forceWidthTo]="listWidth.substring(0, listWidth.length - 2)"
                        [selectedItemId]="graphBoard.id"
                        [tableDefinition]="graphBoardsList.tableDefinition"
                        [view]="graphBoardsList.graphBoardState['listView']"
                        [listsData]="graphBoardsList.toList"
                        [clickMode]="graphBoardsList.clickMode"
                        [archive]="graphBoardsList.archive"
                        [actualModule]="'graphboard'"
                        (listScrolled)="graphBoardsList.listScrolled()"
                        (selectedItem)="itemSelected($event)"
                        (reordering)="reorderingListsRows($event)"
                    ></app-main-list>
                </div>
            </div>
        </div>
    </div>

</ng-sidebar-container>
