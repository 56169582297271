<div class="formWrapper">
    <h4 class="modal-title">
        <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Cancel"
            class="backToList"
            (click)="onCancel()"
        >
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <span *ngIf="status != 'copy' && status != 'edit'"
        >{{ 'jobs.commons.jobDesign' | translate }}</span>
        <span *ngIf="status == 'copy'"
        >{{ 'jobs.modal.newJobFromExisting' | translate }}</span>
        <span *ngIf="status == 'edit'">{{ 'jobs.modal.editJob' | translate }}</span>
    </h4>
    <hr class="panelHr"/>
    <div
        class="text-danger"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>

    <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
    <form [formGroup]="jobForm" id="jobForm" #jobNgForm="ngForm" #jobFormContent>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'jobs.commons.jobDesign' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="2" rowHeight="50px">
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="align-left"
                    >
                        <h4>{{ 'commons.informations' | translate }}</h4>
                    </mat-grid-tile>
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                    >
                        <button mat-stroked-button
                                type="button"
                                class="modalButton margin0"
                                (click)="resetSection($event, 'jobDescription')"
                        >
                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>
                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
                <div style="margin-top: 9px">
                    <label for="jobTitle">{{ 'jobs.commons.jobName' | translate }}</label>
                </div>
                <mat-form-field appearance="fill">
                    <input matInput id="jobTitle" class="jobDescriptionField" formControlName="jobTitle">
                    <small *ngIf="validateFormField(jobForm.controls.jobTitle, jobNgForm, submitted)"
                           class="text-danger"
                    >{{ 'jobs.modal.jobNameMandatory' | translate }}
                    </small>
                </mat-form-field>

                <label>{{ 'commons.subAccount' | translate }}</label>
                <mat-form-field appearance="fill" *ngIf="sessionStructure">
                    <mat-select formControlName="subAccount" class="jobDescriptionField">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']"
                                    [value]="subAcct.id">{{ subAcct.name }}</mat-option>
                    </mat-select>
                    <small *ngIf="validateFormField(jobForm.controls.subAccount, jobNgForm, submitted)"
                           class="text-danger"
                    >{{ 'commons.subAccountNameMandatory' | translate }}
                    </small>
                </mat-form-field>

                <!--                <label>{{ 'commons.language' | translate }}</label>-->
                <!--                <mat-form-field appearance="fill" *ngIf="sessionStructure">-->
                <!--                    <mat-select formControlName="language" class="jobDescriptionField">-->
                <!--                        <mat-option value=""></mat-option>-->
                <!--                        <mat-option *ngFor="let lang of sessionStructure.languages[translate.currentLang]" [value]="lang.value">{{lang.name}}</mat-option>-->
                <!--                    </mat-select>-->
                <!--                    <small *ngIf="validateFormField(jobForm.controls.language, jobNgForm, submitted)"-->
                <!--                           class="text-danger"-->
                <!--                    >{{ 'commons.languageMandatory' | translate }}-->
                <!--                    </small>-->
                <!--                </mat-form-field>-->

                <label for="location">{{ 'commons.location' | translate }}</label>
                <mat-form-field appearance="fill">
                    <input matInput id="location" class="jobDescriptionField" formControlName="location">
                </mat-form-field>

                <label>{{ 'commons.division' | translate }}</label>
                <mat-form-field appearance="fill" *ngIf="departments">
                    <mat-select formControlName="department" class="jobDescriptionField">
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{ dpt['fr'] }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <label for="requiredBy">{{ 'commons.requestedBy' | translate }}</label>
                <mat-form-field appearance="fill">
                    <input matInput id="requiredBy" class="jobDescriptionField" formControlName="requiredBy">
                </mat-form-field>

                <label for="reportsTo">{{ 'commons.reportsTo' | translate }}</label>
                <mat-form-field appearance="fill">
                    <input matInput id="reportsTo" class="jobDescriptionField" formControlName="reportsTo">
                </mat-form-field>

                <!--                <div class="subAccountsWrap" *ngIf="subAccounts">-->
                <!--                    <label for="sharedToSubaccounts" class="field_title">{{ 'jobs.sidebar.shareJob' | translate }}</label>-->
                <!--                    <mat-form-field appearance="fill">-->
                <!--                        <mat-label>{{ 'commons.select' | translate }}</mat-label>-->
                <!--                        <mat-select-->
                <!--                            id="sharedToSubaccounts" class="jobDescriptionField" -->
                <!--                            formControlName="sharedToSubaccounts"-->
                <!--                            multiple-->
                <!--                        >-->
                <!--                            <mat-option *ngFor="let subAcc of subAccountsOptions" [value]="subAcc.id">-->
                <!--                                {{subAcc.name}}-->
                <!--                            </mat-option>-->
                <!--                        </mat-select>-->
                <!--                    </mat-form-field>-->
                <!--                </div>-->

                <div class="formSection">
                    <h4><label for="positionPurpose">{{ 'jobs.commons.purposeOfThePosition' | translate }}</label>
                    </h4>
                    <mat-form-field appearance="fill">
                    <textarea matInput
                              formControlName="purposeOfThePosition"
                              id="positionPurpose"
                              class="jobDescriptionField"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="2"
                              cdkAutosizeMaxRows="5"
                    ></textarea>
                    </mat-form-field>
                </div>

                <div formArrayName="tasks" class="formSection">
                    <h4>{{ 'jobs.commons.principalResponsibilities' | translate }}</h4>
                    <div *ngFor="let task of getTasksControls(jobForm); let i=index">
                        <mat-card *ngIf="task">
                            <mat-card-header>
                                <mat-card-title
                                ><span
                                    style="padding-left: 2px;">{{ 'jobs.commons.responsibility' | translate }} {{i + 1}}</span>
                                </mat-card-title>
                                <button
                                    mat-button
                                    *ngIf="getTasksControls(jobForm).length > 1"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="removeTask(i)"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-card-header>
                            <mat-card-content>
                                <table class="responsibilityTable" [formGroupName]="i.toString()">
                                    <tr>
                                        <td colspan="2">
                                            <label
                                                for="responsibilityInput">{{ 'jobs.commons.responsibility' | translate }}</label>
                                            <mat-form-field appearance="fill">
                                            <textarea matInput
                                                      formControlName="responsibility"
                                                      id="responsibilityInput"
                                                      class="jobDescriptionField"
                                                      cdkTextareaAutosize
                                                      cdkAutosizeMinRows="2"
                                                      cdkAutosizeMaxRows="5"
                                            ></textarea>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label
                                                for="priorityInput">{{ 'jobs.commons.priorityOrder' | translate }}</label>
                                            <mat-form-field appearance="fill">
                                                <input matInput id="priorityInput" class="jobDescriptionField"
                                                       maxlength="3" formControlName="priority">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <label
                                                for="percentageInput">{{ 'jobs.commons.timePercentage' | translate }}</label>
                                            <mat-form-field appearance="fill">
                                                <input matInput id="percentageInput" class="jobDescriptionField"
                                                       maxlength="3" formControlName="percentage">
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </table>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
                <a (click)="addTask()">
                    {{ 'jobs.modal.addResponsibility' | translate }}
                </a>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="stateService.hasPersonality"
                [expanded]="this.jobService.id &&
                this.stateService.jobs.report.reportType === 'personality'"
                (click)="this.stateService.jobs.report.reportType = 'personality'"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'jobs.commons.profileNoTarget' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="4" rowHeight="50px">
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="align-left"
                    >
                        <h4 class="field_title"><label
                            for="jobProfile">{{ 'commons.typicalProfile' | translate }}</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile
                        colspan="3"
                        rowspan="1"
                    >
                        <!--button mat-stroked-button
                                type="button"
                                class="modalButton"
                                (click)="goToQuestionnaireDef($event)"
                        >
                            <span>{{ 'commons.surveyDef' | translate }}</span>
                        </button-->
                        <button mat-stroked-button
                                type="button"
                                class="modalButton"
                                (click)="resetSection($event, 'personality')"
                        >
                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>
                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'commons.select' | translate }}</mat-label>
                    <mat-select
                        id="jobProfile"
                        formControlName="selectedProfile"
                        panelClass="jobsProfilerOptions"
                        class="personalityField"
                        (selectionChange)="loadValues()"
                    >
                        <mat-option value=""></mat-option>
                        <mat-optgroup *ngFor="let group of profileListGroups">
                            {{group.text}}
                            <mat-option *ngFor="let text of group['opt']"
                                        [value]="text.value">
                                &nbsp;&nbsp;&nbsp;
                                {{text.text}}
                                ({{text['score'].join(', ')}})
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <h4 class="field_title"><label>Scores</label></h4>
                <mat-grid-list class="jobsProfilerLabels" cols="6" rowHeight="20px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerOR">OR</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerSE">{{ 'commons.ES' | translate }}</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerA">A</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerE">E</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerP">P</label></h4>
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <h4><label for="profilerS">S</label></h4>
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list class="jobsProfilerInputs" cols="6" rowHeight="35px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerOR" class="personalityField"
                               formControlName="OR"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerSE" class="personalityField"
                               formControlName="SE"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerA" class="personalityField"
                               formControlName="A"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerE" class="personalityField"
                               formControlName="E"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerP" class="personalityField"
                               formControlName="P"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                    <mat-grid-tile colspan="1" rowspan="1">
                        <input matInput onfocus="this.select();" type="text" id="profilerS" class="personalityField"
                               formControlName="S"
                               (keydown)="onChangePersonalityProfile()"
                        />
                    </mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list *ngIf="stateService.hasSatellite && mpoScores" class="jobProfilerSatellite" cols="1"
                               rowHeight="30px">
                    <mat-grid-tile colspan="1" rowspan="1">
                        <mat-checkbox formControlName="isSatellite"
                                      class="personalityField"
                                      (change)="useWithSatellite($event)"
                                      id="useMpoSatellite"
                        >{{ 'jobs.sidebar.useWithSatellite' | translate }}</mat-checkbox>
                    </mat-grid-tile>
                </mat-grid-list>
                <div class="graphWrap" *ngIf="displayGraph && cleanScore(); else emptyGraph">
                    <app-mpo-graph
                        [id]="'profiler-modal'"
                        [scores]="cleanScore()"
                        [lang]="translate.currentLang"
                    ></app-mpo-graph>
                </div>
                <ng-template #emptyGraph>
                    <div class="graphWrap">
                        <app-mpo-graph
                            [id]="'profiler-modal'"
                            [scores]="null"
                            [lang]="translate.currentLang"
                        ></app-mpo-graph>
                    </div>
                </ng-template>
                <!--app-mpo-graph
                    *ngIf="jobsList.jobsState['selectedTab'] === 1 && cleanScore()"
                    [id]="'profiler-modal'"
                    [scores]="cleanScore()"
                    [lang]="translate.currentLang"
                ></app-mpo-graph-->
                <div *ngIf="showProfileWarning" class="profileWarning">
                    {{ 'jobs.modal.lowScoreWarning' | translate}}
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="stateService.hasTalents"
                [expanded]="this.jobService.id && this.stateService.jobs.report.reportType === 'talents'"
                (click)="this.stateService.jobs.report.reportType = 'talents'"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'jobs.modal.jobCompetenceProfile' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="2" rowHeight="50px">
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="align-left"
                    >
                        <h4>{{ 'jobs.modal.instructions' | translate }}</h4>
                    </mat-grid-tile>
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                    >
                        <button mat-stroked-button
                                type="button"
                                class="modalButton margin0"
                                (click)="resetSection($event, 'talents')"
                        >
                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>
                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
                <ng-container>
                    <p [innerHTML]="'jobs.modal.instruction1' | translate"></p>
                    <div style="padding-bottom: 10px;">
                        <span class="redBlock">&nbsp;</span> &nbsp;{{ 'jobs.modal.lowScoreMarker' | translate}}
                    </div>
                    <a *ngIf="!showSortOption()" target="_blank"
                       href="{{ apiBaseUrl }}/assets/dictionary/{{'jobs.modal.competenciesDictionaryFilename' | translate}}">
                        {{ 'jobs.modal.competenciesDictionaryLinkTitle' | translate }}
                    </a>
                </ng-container>
                <div *ngIf="showSortOption()" class="jobsCompetencesHeader">
                    <mat-grid-list cols="2" rowHeight="37px">
                        <mat-grid-tile colspan="1" rowspan="1">
                            <a target="_blank"
                               href="{{ apiBaseUrl }}/assets/dictionary/{{'jobs.modal.competenciesDictionaryFilename' | translate}}">
                                {{ 'jobs.modal.competenciesDictionaryLinkTitle' | translate }}
                            </a>
                        </mat-grid-tile>
                        <mat-grid-tile colspan="1" rowspan="1">
                            <div *ngIf="talentsOrder === 'title'">
                                Sort:&nbsp;
                                <span class="boldText">alphabetical</span> |
                                <a (click)="updateSorting('number')">numerical</a>
                            </div>
                            <div *ngIf="talentsOrder === 'number'">
                                Sort:&nbsp;
                                <a (click)="updateSorting('title')">alphabetical</a> |
                                <span class="boldText">numerical</span>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
                <hr class="panelHr"/>
                <table class="jobTalentsData">
                    <ng-container *ngFor="let talent of talentsData; let i=index; trackBy: trackByCompetencieId">
                        <tr [class.darkBg]="i%2 === 0">
                            <td class="talentStatus"
                                [class.boldText]="selectedTalents[talent.id]==1 || selectedTalents[talent.id]==2"
                                [class.red]="isLowScoreTalent(talent['number'])"
                            >
                                <app-talent-status
                                    [selectedValue]="selectedTalents[talent.id]"
                                    [talentNumber]="talent['number']"
                                ></app-talent-status>
                            </td>
                            <td class="talentStatus"
                                [class.boldText]="selectedTalents[talent.id]==1 || selectedTalents[talent.id]==2"
                                [class.red]="isLowScoreTalent(talent['number'])"
                            >
                                <span
                                    [tooltip]="talent.text"
                                    class="compLink"
                                >
                                    {{ talent['fullTitle'] }}&nbsp;
                                </span>
                            </td>
                            <td>
                                <div class="dropdown">
                                    <div class="dropbtn" (click)="toggleMastering(i)">
                                        {{ dropDownValues(talent.id, 0)[0] }} <span class="caret"></span>
                                    </div>
                                    <div class="dropdown-content surfInPage"
                                         [class.show]="subMenu[i]"
                                    >
                                        <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 1)[1], i)">
                                            {{ dropDownValues(talent.id, 1)[0] }}
                                        </div>
                                        <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 2)[1], i)">
                                            {{ dropDownValues(talent.id, 2)[0] }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="stateService.hasIac" [expanded]="this.jobService.id &&
                this.stateService.jobs.report.reportType === 'ra'"
                (click)="this.stateService.jobs.report.reportType = 'ra'"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ 'jobs.sidebar.quickRANoTarget' | translate }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-grid-list cols="2" rowHeight="50px">
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                        class="align-left"
                    >
                        <h4 class="field_title">{{ 'jobs.sidebar.enterQuickRAScore' | translate }}</h4>
                    </mat-grid-tile>
                    <mat-grid-tile
                        colspan="1"
                        rowspan="1"
                    >
                        <button mat-stroked-button
                                type="button"
                                class="modalButton margin0"
                                (click)="resetSection($event, 'iac')"
                        >
                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>
                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
                <div id="scoreBlock" class="scoreBlock">
                    <div [class.error]="RAScore > 9 ? ' error' : null">
                        <mat-form-field class="scoreContainerField">
                            <label>
                                <input matInput
                                       class="scoreContainer"
                                       type="text"
                                       id="RAScore"
                                       formControlName="RAScore"
                                       (keyup)="refreshGraph()"
                                       (keydown)="checkPressedKey($event)"
                                />
                            </label>
                        </mat-form-field>
                    </div>
                </div>
                <div id="graphBlock">
                    <img alt="graph" src="assets/graphicalElements/nCurve.png"/>
                    <app-iac-graph
                        [id]="'modal_' + jobService.id"
                        [normalizedScore]="null"
                        [jobScore]="RAScore"
                        [newJob]="status === 'new'"
                        [mobile]="true"
                    ></app-iac-graph>
                </div>
                <div *ngIf="raTexts && RAScore" id="iacTextsBlock">
                    <h3>{{ 'commons.workEnvironment' | translate }}</h3>
                    <p>
                        {{ raTexts['env'][RAScore] }}
                    </p>
                    <h3>{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
                    <p>
                        {{ raTexts['job'][RAScore] }}
                    </p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        <!--mat-tab-group class="analysisTabs jobTabs"
                       [(selectedIndex)]="jobsList.jobsState['selectedTab']"
                       (selectedIndexChange)="switchEditTabs($event)">
        </mat-tab-group-->
        <mat-grid-list cols="1" rowHeight="60px">
            <mat-grid-tile
                colspan="1"
                rowspan="1">
                <button mat-stroked-button
                        type="button"
                        class="modalButton"
                        (click)="onCancel()"
                >{{ 'commons.cancel' | translate }}</button>
                <button mat-stroked-button
                        type="submit"
                        class="modalButton"
                        [disabled]="!jobForm.valid && jobNgForm.submitted"
                        (click)="onSubmit(jobForm)"
                >
                        <span *ngIf="status !== 'edit'">
                        {{ 'commons.save' | translate }}
                        </span>
                    <span *ngIf="status === 'edit'">
                            {{ 'commons.save' | translate }}
                        </span>
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
