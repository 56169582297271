import {animate, style, transition, trigger} from '@angular/animations';
import {
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EvaluationRequestNext} from 'src/app/people/models/evaluation-request-next.model';
import {emailValidator} from 'src/app/people/validators/email';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {WindowRef} from 'src/app/app/services/window-ref.service';
import {StateService} from '../../../../core/services/state/state.service';
import {JobService} from '../../../services/job.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-job-profiler-request-resend-modal',
    templateUrl: './job-profiler-request-resend-modal.component.html',
    styleUrls: ['./job-profiler-request-resend-modal.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ]),
        trigger('fadeInOut2', [
            transition('void => *', [
                style({opacity: 0}), // style only for transition transition (after transiton it removes)
                animate(500, style({opacity: 1})) // the new state of the transition(after transiton it removes)
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0})) // the new state of the transition(after transiton it removes)
            ])
        ])

    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class JobProfilerRequestResendModalComponent implements OnInit, OnDestroy {

    public close: EventEmitter<any> = new EventEmitter();

    flipValue: number;

    toggle = 1;

    textToSend = '';
    nativeWindow: any;

    evaluationFormUrl: string;

    profilerRequestResend: EvaluationRequestNext = new EvaluationRequestNext();
    profilerResendForm: FormGroup;
    mailto: any;
    tempUser: any;
    person: any;
    country: string;
    language: string;
    blurred = false;

    cutNPasteTexts: any;

    introductionText: string;
    sessionData;
    countryList;

    private subscriptions = new Subscription();

    constructor(
        public jobService: JobService,
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        public translate: TranslateService,
        private windowRef: WindowRef,
        public cd: ChangeDetectorRef,
        private stateService: StateService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    ngOnInit() {
        this.sessionData = this.stateService.session ? this.stateService.session.sessionData : null;
        this.countryList = (this.sessionData &&
            this.sessionData.structure &&
            this.sessionData.structure.countries) ?
            this.sessionData.structure.countries[this.translate.currentLang] : null;
        this.initEvaluationResendForm();
        if (this.jobService.id) {
            // resending API call
        }
    }

    setLang(lang, country) {
        if (lang === 'es') {
            if (country === 'us') {
                return 'en';
            } else {
                return 'fr';
            }
        }
        return lang;
    }

    initEvaluationResendForm() {
        this.profilerResendForm = this.fb.group({
                emailTo: [this.profilerRequestResend.emailTo],
                message: [(this.profilerRequestResend.message) ? this.profilerRequestResend.message : this.textToSend]
            },
            {
                validator: emailValidator('emailTo')
            }
        );
    }

    toggleFlip(n) {
        if (n !== '1') {
            this.toggle = n;
        }
        return;
    }

    closeModal(jobId = null) {
        let delay = new Promise(resolve => setTimeout(resolve, 1000));
        delay.then(() => {
            // Reset data
            this.toggle = 1;
            this.flipValue = 1;
            // this.profilerRequestResend = new EvaluationRequestNext();
        });
        this.close.emit({closed: true, id: jobId});
        this.bsModalRef.hide();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
