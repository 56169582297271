<ngx-loading [show]="loading" [config]="{
    backdropBorderRadius: '6px',
    primaryColour: '#CDCDCD',
    secondaryColour: '#ABABAB',
    tertiaryColour: '#CDCDCD'
    }"></ngx-loading>

<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'gbs.personModal.defGb' | translate }}</h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body">
    <div *ngIf="chooseGraphboard">
        <h3><label for="boardsList">{{ 'gbs.personModal.chooseGb' | translate }}</label></h3>
        <select [(ngModel)]="gbId" (change)="assignGbId(gbId)" id="boardsList" class="form-control">
            <option>{{ 'commons.select' | translate }}</option>
            <option
                    *ngFor="let gb of gbList"
                    [value]="gb.id"
            >
                {{ gb.name }}
            </option>
        </select>
    </div>
    <div *ngIf="chooseGraphboard">
        <p>{{ headerText }}</p>
    </div>
    <div class="pull-left" style="margin-bottom: 0;">
        <ul class="nav nav-tabs" style="border-bottom: none;">
            <li [class.active]="graphboardTab=='individus'" style="background-color: #EEE">
                <a class="tdnone" (click)="displayType('individus')">{{ 'commons.individus' | translate }}</a>
            </li>
            <li [class.active]="graphboardTab=='profiler'">
                <a class="tdnone" (click)="displayType('profiler')">{{ 'commons.jobs' | translate }}</a>
            </li>
        </ul>
    </div>
    <div class="pull-right">
        <div class="searchBarComponent" style="padding-bottom: 5px; margin-bottom: 0">
            <label>
                <input class="searchBar" onfocus="this.select();" [(ngModel)]="searchTerm" (keydown)="onSearch($event, searchTerm, 'return')"/>
            </label>
            <div *ngIf="!actual" class="magnifyingGlass" (click)="onSearch($event, searchTerm, 'click')">
                <img src="../../../../../../assets/header/search_and_filters/ico_search.png" alt=""/>
            </div>
            <div *ngIf="actual" class="closeSearch" (click)="clearSearch()">
                <img class="closeIdle" src="../../../../../../assets/buttons/close.png" alt=""/>
                <img class="closeHover" src="../../../../../../assets/buttons/close_hover.png" alt=""/>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div id="candidateList"
         infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="500"
            [scrollWindow]="false"
            (scrolled)="onScroll()"
    >
        <div *ngIf="records[graphboardTab]">
            <div *ngFor="let record of records[graphboardTab]; let i = index">
                <div class="candidateRow" *ngIf="!record['isArchived']">
                    <div class="pull-left">
                    &nbsp;&nbsp;
                        <mat-checkbox *ngIf="graphboardTab == 'profiler'"
                            id="record_{{ i }}"
                            [checked]="predicateCheckBox('profiler', record)"
                            (change)="onChecked(record.id, $event)"
                        >
                            {{ record['jobTitle']}}
                        </mat-checkbox>
                        <span *ngIf="graphboardTab == 'individus'">
                            {{ record['firstName'] }} {{ record['lastName'] }}
                        </span>
                    </div>
                    <div class="pull-right" *ngIf="graphboardTab == 'individus'"  style="margin-top: 3px">
                        <mat-checkbox
                            name="indType_{{record.id}}_trait"
                            value="traits"
                            (change)="selectIndType(record.id, 'trait')"
                            [checked]="check(record, 'trait')"
                            [disabled]="hasMpoEvaluation(record) ? null : true"
                        >
                            {{ 'commons.traits' | translate }}
                        </mat-checkbox> &nbsp;
                        <mat-checkbox
                            name="indType_{{record.id}}_prb"
                            value="comportement"
                            (change)="selectIndType(record.id, 'prb')"
                            [checked]="check(record, 'prb')"
                            [disabled]="hasPrbEvaluation(record) ? null : true"
                        >
                            {{ 'commons.behavior' | translate }}
                        </mat-checkbox> &nbsp;
                        <mat-checkbox
                            name="indType_{{record.id}}_both"
                            value="tous"
                            (change)="selectIndType(record.id, 'both')"
                            [checked]="check(record, 'both')"
                            [disabled]="(hasMpoEvaluation(record) ? null : true) || (hasPrbEvaluation(record) ? null : true)"
                        >
                            {{ 'commons.all' | translate }}
                        </mat-checkbox> &nbsp;
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="submit" class="modalButton pull-right" (click)="onSubmit()"
            style="margin-top: 15px"
    >
        {{ 'commons.save' | translate }}
    </button>
    <button (click)="closeModal()" type="button" class="modalButton pull-right"
            style="margin-top: 15px; margin-right: 15px"
    >
        {{ 'commons.cancel' | translate }}
    </button>
</div>

<div class="clearfix"></div>
