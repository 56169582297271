<div *ngIf="person" [style.margin-right]="(!mobile) ? '40px' : null">
    <h2>{{ 'people.reportIac.infoOnIac' | translate }}</h2>
    <ul class="plainList" *ngIf="person.iacReport">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ person.iacDateToDisplay }}</li>
        <li>{{ 'people.commons.time' | translate }}<span [innerHTML]="titleSep"></span> {{ person.iacPassationTime }}</li>
        <li>{{ 'people.commons.norm' | translate }}<span [innerHTML]="titleSep"></span> INT {{ person.iacInfo['normNumber'] }}
            <span *ngIf="person.iacNormLanguage">({{person.iacNormLanguage}})</span>
        </li>
        <li *ngIf="person.iacMethod">
            {{ 'people.commons.method' | translate }}<span [innerHTML]="titleSep"></span>
            <span *ngIf="person.iacMethod === 0"> {{ 'people.commons.paperQuestionnaire' | translate }}</span>
            <span *ngIf="person.iacMethod === 1"> {{ 'people.commons.onlineQuestionnaire' | translate }}</span>
        </li>
    </ul>
    <hr/>
    <div style="height: 1em">&nbsp;</div>

    <div *ngIf="person.iacReport" style="max-width: 1000px">
        <div class="legend">
            <div *ngIf="translate.currentLang == 'fr'">
                Score de l'individu &nbsp;<span class="orange">&nbsp;</span> &nbsp;&nbsp;
                Cible pour le poste &nbsp;<span class="blue">&nbsp;</span>
            </div>
            <div *ngIf="translate.currentLang == 'en'">
                Person's score &nbsp;<span class="orange">&nbsp;</span> &nbsp;&nbsp;
                Job target &nbsp;<span class="blue">&nbsp;</span>
            </div>
        </div>
        <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt=""/>
        <app-iac-graph
            [normalizedScore]="person.iacNormalizedScore"
            [jobScore]="person.jobIac"
            [mobile]="mobile"
        ></app-iac-graph>
    </div>
    <div *ngIf="person.iacReport" style="margin-top: 15px">
        <div class="mb10">
            <span *ngIf="translate.currentLang == 'fr'">CONCORDANCE AVEC LE POSTE :</span>
            <span *ngIf="translate.currentLang == 'en'">CONCORDANCE WITH THE POSITION:</span>
            {{person.jobsJobTitle}}
        </div>
        <p *ngIf="report['IacVsRaText']">
              {{ report['IacVsRaText'] }}
        </p>
        <div *ngIf="translate.currentLang == 'fr'">
            <div class="mb10">À CONSIDÉRER</div>
            <div class="mb10">
                <ul>
                    <li>
                        Lorsque le score de la personne est beaucoup plus élevé que le score nécessaire pour le poste, <b>elle
                        pourrait s’ennuyer rapidement dans son poste ou sentir que ses capacités sont sous-utilisées.</b>
                    </li>
                    <li>
                        Lorsque le score de la personne est beaucoup plus bas que le score nécessaire pour le poste, elle
                        <b>pourrait ressentir beaucoup de stress dans son poste ou sentir que ses capacités sont
                            sur-sollicitées.</b>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="translate.currentLang == 'en'">
            <div class="mb10">CONSIDERATIONS</div>
            <div class="mb10">
                <ul>
                    <li>
                        When a person’s score is much higher than the position requires, <b>there is a chance of the person
                        becoming bored or feeling under-utilized in the position</b>.
                    </li>
                    <li>
                        When a person’s score is much lower than the position requires, <b>there is a chance that the person
                        will suffer stress or feel overwhelmed by the expectations</b>.
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="person.iacReport && translate.currentLang === 'fr'">
        <div class="messageBox">
            <h2>NOTICE D'UTILISATION</h2>
            <ul>
                <li>
                    Cet indicateur a été conçu pour une <b>utilisation en milieu de travail.</b>
                </li>
                <li>
                    L’<b>Indicateur d’Aptitudes Cognitives MPO ne mesure pas le quotient intellectuel en soi</b>, mais
                    plutôt les habiletés d’apprentissage et l’aptitude à utiliser des notions complexe au travail.
                </li>
                <li>
                    Les scores de <b>1 à 9 sont normalisés</b> en fonction d’un échantillon représentatif des populations
                    évaluées.
                </li>
                <li>
                    En situation d’embauche, <b>le résultat doit être considéré en fonction des exigences d’un poste
                    précis.</b>
                </li>
                <li>
                    <b>Cette évaluation ne doit jamais servir comme unique critère de sélection</b>, mais plutôt comme
                    un facteur parmi d’autres tels que : l’éducation, la personnalité, l’expérience, etc.
                </li>
                <li>
                    <b>Ces scores doivent être utilisés avec discrétion et bon jugement par des gens qualifiés</b> pour
                    prendre les décisions appropriées.
                </li>
            </ul>
        </div>
        <div class="messageBox {{mobile ? 'last' : null}}">
            <h2 class="ngorange">NOTE IMPORTANTE</h2>
            <p>
                Compte tenu de sa brièveté mais aussi de sa relation avec les mesures d’intelligence, cette échelle
                d’aptitudes cognitives est utile comme mesure rapide et globale des habilités de raisonnement. Cependant,
                elle ne peut se substituer à une batterie complète de tests de Q.I. D’autre part, des scores très bas
                mériteraient une attention particulière lors de l’interprétation des résultats. Par exemple, de tels scores
                peuvent indiquer un manque d’attention, des difficultés d’alphabétisation, une mauvaise interprétation des
                instructions ou une réaction négative au questionnaire.
            </p>
        </div>
    </div>
    <div *ngIf="person.iacReport && translate.currentLang === 'en'">
        <div class="messageBox">
            <h2>USER CONSIDERATIONS</h2>
            <ul>
                <li>
                    This Indicator was designed solely for <b>use in work settings</b>.
                </li>
                <li>
                    While <b>the MPO Cognitive Aptitude Indicator does not directly measure intelligence
                    quotient</b>, it does measure a person’s ability to learn and to put complex notions to use in the
                    workplace.
                </li>
                <li>
                    <b>Scoring from 1 to 9 has been normalized</b> based on a representative sample of the populations
                    being assessed.
                </li>
                <li>
                    When used in hiring endeavors, <b>results must be considered alongside an actual set of job
                    requirements</b>.
                </li>
                <li>
                    <b>This assessment should never be used alone as the sole hiring selection criteria</b>. It should
                    always be used in conjunction with other criteria such as a person’s education and training,
                    personality, experience, and so on.
                </li>
                <li>
                    <b>Discretion and sound judgment must be exercised when considering results</b>. In the interest
                    of making appropriate decisions, scores must be interpreted by a qualified professional.
                </li>
            </ul>
        </div>
        <div class="messageBox {{mobile ? 'last' : null}}">
            <h2 class="ngorange">IMPORTANT</h2>
            <p>
                Given the fact that the assessment is based on a condensed questionnaire and is not a full
                measure of intelligence, this cognitive ability scale should be taken as a brief global overview
                of a person’s reasoning skills.
            </p>
            <p>
                It is in no manner to be considered as a replacement for administering a full battery of I.Q.
                tests. Furthermore, any very low scores should be given careful consideration as they may
                in fact be more indicative of attention issues, low literacy skills, an adverse reaction to the
                questionnaire, or a misunderstanding of the instructions provided.
            </p>
        </div>
    </div>
</div>
