import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProductsListNames} from '../../assets/productsListNames';
import {ActivatedRoute} from '@angular/router';
import {StateService} from '../../../core/services/state/state.service';

@Component({
    selector: 'app-administration-common-list',
    templateUrl: './administration-common-list.component.html',
    styleUrls: ['./administration-common-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdministrationCommonListComponent implements OnInit, OnChanges {

    @Input() createItemLabel;
    @Input() displaySearch = false;
    @Input() list;
    @Input() listTitle;
    @Input() listTitles;
    @Input() listMetaData;
    @Input() listType: string;

    sessionPermissions;

    productTitles;
    originalList: any;

    actualPage = 1;
    maxPerPage = 10;
    /** mod this */
    maxPage: number;
    numberOfLastPageReached = 0;

    value;

    @Output() browse = new EventEmitter();
    @Output() createItem = new EventEmitter();
    @Output() selectItem = new EventEmitter();
    @Output() deleteFile = new EventEmitter();
    @Output() onLastPage = new EventEmitter();

    constructor(
        private translate: TranslateService,
        private route: ActivatedRoute,
        private stateService: StateService
    ) {
        this.productTitles = new ProductsListNames().titles;
    }

    ngOnInit() {
        this.sessionPermissions = this.stateService.session.sessionData.permissions;
    }

    get checkedList() {
        if (this.list.items !== undefined) {
            let listIndex = 0;

            for (let item of this.list.items) {
                if (!item) {
                    // delete an empty item in the list
                    this.list.items.splice(listIndex, 1);
                }
                listIndex++;
            }
        }
        return this.list;
    }

    ngOnChanges() {
        if (this.list) {
            this.maxPage = Math.ceil(this.list.length / this.maxPerPage);
        }

        if (this.list && !this.originalList) {
            this.originalList = this.list;
        }
    }

    onCreateItem() {
        this.createItem.emit();
    }

    onSelectItem(item: any) {
        if (item === undefined) {
            return;
        }
        this.selectItem.emit(item);
    }

    deteleteFile(item: any) {
        this.deleteFile.emit(item);
    }

    onSearch(value: string): void {
        const pattern = new RegExp(value, 'i');

        if (this.listType === 'users') {
            this.list = this.originalList.filter((item: any) => item.firstName.match(pattern) || item.lastName.match(pattern));
        }

        if (this.listType === 'subaccounts') {
            this.list = this.originalList.filter((item: any) => item.name.match(pattern));
        }
    }

    thisList() {
        if (this.listTitle === 'Purchases' || this.listTitle === 'Achats') {
            return 'purchases';
        }
        if (this.listTitle === 'Utilisateurs' || this.listTitle === 'Users') {
            return 'users';
        }
        if (this.listTitle === 'Départements' || this.listTitle === 'Departments') {
            return 'department';
        }
        if (this.listTitle === 'Files' || this.listTitle === 'Fichiers') {
            return 'files';
        }
        if (this.listTitle === 'Sous-comptes' || this.listTitle === 'Sub-accounts') {
            return 'subAccounts';
        }
    }

    doCreate() {
        switch (this.thisList()) {
            case 'purchases':
                // tslint:disable-next-line:radix
                if (this.route.snapshot.url[2] && parseInt(this.route.snapshot.url[2].path) !== 0) {
                    if (this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN')) {
                        return true;
                    }
                    break;
                }
                return false;
            case 'subAccounts':
                return !(!this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN') &&
                    !this.sessionPermissions.roles.includes('ROLE_MAIN_ADMIN') &&
                    !this.sessionPermissions.roles.includes('ROLE_ADMIN'));
            case 'department':
                return !(!this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN') &&
                    !this.sessionPermissions.roles.includes('ROLE_MAIN_ADMIN') &&
                    !this.sessionPermissions.roles.includes('ROLE_ADMIN'));
            default:
                return this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN');
        }
    }

    get isSuperAdmin() {
        return this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN');
    }

    createRange(actualPage) {
        let items: number[] = [];
        let maxPerPage = this.maxPerPage;
        let start = (actualPage - 1) * maxPerPage;
        let end = actualPage * maxPerPage;
        for (let i = start; i < end; i++) {
            items.push(i);
        }
        return items;
    }

    quantity(value) {
        if (value === 9999 || value === 'unlimited') {
            return (this.translate.currentLang === 'fr') ? 'Illimité' : 'Unlimited';
        }
        return value;
    }

    formatProductTitles(sku) {
        if (this.productTitles[sku] && this.productTitles[sku][this.translate.currentLang]) {
            return this.productTitles[sku][this.translate.currentLang] + ' (' + sku + ')';
        } else {
            return sku;
        }

    }

    iconFile(fileType) {
        if (
            fileType === 'csv' ||
            fileType === 'doc' ||
            fileType === 'docx' ||
            fileType === 'html' ||
            fileType === 'jpg' ||
            fileType === 'pdf' ||
            fileType === 'png' ||
            fileType === 'ppt' ||
            fileType === 'pptx' ||
            fileType === 'psd' ||
            fileType === 'rtf' ||
            fileType === 'svg' ||
            fileType === 'txt' ||
            fileType === 'xls' ||
            fileType === 'xlsx'
        ) {
            return '/assets/filesIcons/' + fileType + '.png';
        } else {
            return '/assets/filesIcons/file.png';
        }

    }

    cellProperties(listType, columnIndex) {
        let styleObject = {
            width: '',
            vertical_align: '',
            text_align: ''
        };
        switch (listType) {
            case 'files':
                switch (columnIndex) {
                    case 0:
                        styleObject.width = '45px';
                        return styleObject;
                    case 3:
                    case 4:
                        styleObject.vertical_align = 'middle';
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.vertical_align = 'middle';
                        return styleObject;
                }
            case 'subaccounts':
                switch (columnIndex) {
                    case 2:
                    case 4:
                        styleObject.width = '100px';
                        return styleObject;
                    case 3:
                        styleObject.width = '100px';
                        styleObject.text_align = 'center';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            case 'users':
                switch (columnIndex) {
                    case 2:
                        styleObject.width = '200px';
                        return styleObject;
                    case 4:
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            case 'history':
                switch (columnIndex) {
                    case 1:
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            case 'departments':
                switch (columnIndex) {
                    default:
                        return styleObject;
                }
            case 'purchases':
                switch (columnIndex) {
                    case 1:
                    case 2:
                        styleObject.width = '100px';
                        return styleObject;
                    default:
                        styleObject.width = 'auto';
                        return styleObject;
                }
            default:
                return styleObject;
        }
    }

    goToPage(factor) {
        this.actualPage = this.actualPage + factor;
        const isLastPage = this.isLastPage(this.actualPage, this.maxPage);

        // Last page process
        if (isLastPage) {
            this.numberOfLastPageReached = this.numberOfLastPageReached + 1;
            this.onLastPage.emit({type: this.listType, numberOfLastPageReached: this.numberOfLastPageReached});
        }
    }

    private isLastPage(actualPage: number, maxPage: number): boolean {
        return actualPage === maxPage;
    }

    get isHistory() {
        return !!(this.listTitle === 'Historique' || this.listTitle === 'History');
    }
}
