<div class="pull-left"
     [class.sideBarButton]="isSidebar"
     [class.isAnalysis]="isRightMatch || isJobSatisfaction"
     [class.isBlank]="isBlank" (click)="onButtonClick()"
     [style.background-color]="backgroundColor"
     [style.color]="textColor"
     [style.border]="border"
>
    <div
        [class.pull-left]="!swapContent"
        [class.pull-right]="swapContent"
        [class.sideBarImageContainer]="!swapContent"
    >
        <ng-content select=".buttonImage"></ng-content>
    </div>
    <div
        [class.pull-left]="!swapContent"
        [class.pull-right]="swapContent"
        class="pull-left"
    >
        <ng-content select=".buttonTitle"></ng-content>
    </div>
    <div class="clearfix"></div>
</div>
