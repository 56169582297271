<h3>{{ sectionTitle ? sectionTitle : 'rms.personModal.rmCandidate' | translate }}</h3>
<mat-form-field *ngIf="records" class="full-width">
    <input
        matInput
        type="text"
        onfocus="this.select();"
        title="{{ 'commons.search' | translate }}"
        placeholder="{{ 'commons.search' | translate }}"
        [(ngModel)]="searchTerm"
        (keydown)="onSearch($event, searchTerm, 'return')"
    >
    <button
        mat-button
        *ngIf="actual"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="clearSearch()"
    >
        <mat-icon>close</mat-icon>
    </button>
    <button
        mat-button
        *ngIf="!actual"
        matSuffix
        mat-icon-button
        (click)="onSearch($event, searchTerm, 'click')"
        aria-label="Search"
    >
        <mat-icon>search</mat-icon>
    </button>
</mat-form-field>
<mat-form-field class="full-width falseInput">
    <label style="display: none;">
        <input
            matInput
            type="text" />
    </label>
    <div
        id="candidateList"
        *ngIf="records"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
    >
        <ngx-loading [show]="loading" [config]="{
                    backdropBorderRadius: '0pt',
                    backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
                    primaryColour: '#CDCDCD',
                    secondaryColour: '#ABABAB',
                    tertiaryColour: '#CDCDCD'
                    }"></ngx-loading>
        <div class="candidateRow" *ngFor="let record of records; let i = index">
            <mat-checkbox
                (change)="onChecked($event, record.id)"
                id="record_{{ i }}"
                [checked]="selectedItems.indexOf(record.id) !== -1"
            >
                {{ record['firstName'] }} {{ record['lastName'] }}
            </mat-checkbox>
        </div>
    </div>
</mat-form-field>
<div *ngIf="records" class="addingFooter">
    <button
        mat-button
        type="button"
        class="modalButton"
        aria-label="Clear"
        (click)="closeP()"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <button
        mat-button
        type="submit"
        class="modalButton"
        (click)="onSubmit()"
    >
        {{ 'commons.save' | translate }}
    </button>
</div>
