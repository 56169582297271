<div *ngIf="tempUser && instructionsMessages" [ngSwitch]="sendMethod">

    <div>
        <div *ngSwitchCase="'cutpaste'" class="modal-body whiteBck">
            <!-- COPY / PASTE -->
            <app-people-survey-request-send-method-copypaste
                [instructionsMessages]="instructionsMessages"
                [surveyLink]="decodedSurveyLink"
                [username]="tempUser.username"
                [password]="tempUser['tmpPwd']"
                [formSubmit$]="formSubmit$"
                (afterSubmission)="afterSubmission($event)"
            ></app-people-survey-request-send-method-copypaste>
        </div>

        <div *ngSwitchCase="'email'" class="modal-body">
            <!-- Email client -->
            <app-people-survey-request-send-method-email-client
                [instructionsMessages]="instructionsMessages"
                [surveyLink]="surveyLink"
                [username]="tempUser.username"
                [password]="tempUser['tmpPwd']"
                [fullName]="tempUser.fullName"
                [email]="tempUser['email']"
                [formSubmit$]="formSubmit$"
                (afterSubmission)="afterSubmission($event)"
            ></app-people-survey-request-send-method-email-client>
        </div>

        <div *ngSwitchCase="'server'" class="modal-body">
            <!-- Ngenio server -->
            <app-people-survey-request-send-method-ngenio-server
                [instructionsMessages]="instructionsMessages"
                [surveyLink]="surveyLink"
                [tempUserId]="tempUser.id"
                [username]="tempUser.username"
                [password]="tempUser['tmpPwd']"
                [email]="tempUser['email']"
                [formSubmit$]="formSubmit$"
                (afterSubmission)="afterSubmission($event)"
            ></app-people-survey-request-send-method-ngenio-server>
        </div>

        <div *ngSwitchCase="'paper'" class="modal-body">
            <!-- Paper (external survey) -->
            <app-people-survey-request-send-method-paper
                [recordId]="tempUser.additionalData['recordId']"
                [formSubmit$]="formSubmit$"
                (afterSubmission)="afterSubmission($event)"
            ></app-people-survey-request-send-method-paper>
        </div>

        <div *ngSwitchDefault>ERROR</div>
    </div>
    <div class="modal-footer">
        <div style="margin-top: 15px">
            <div class="pull-right" style="margin-left: 15px">
                <button (click)="submit()" class="modalButton">{{ buttonText | translate }}</button>
            </div>
            <div *ngIf="sendMethod !== 'paper' && false" class="pull-right" style="margin-left: 15px">
                <button (click)="cancelRequest()" type="button" class="modalButton">{{ 'commons.cancel' | translate }}</button>
            </div>
            <div style="margin-top: 10px; float: left;" class="pull-right">
                <mat-checkbox
                    id="report_resend"
                    [checked]="sendAnotherSurvey"
                    (change)="toggleSendAnotherSurvey()"
                >{{ 'commons.sendAnother' | translate }}</mat-checkbox>&nbsp;&nbsp;
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
