import {Injectable} from '../../shared/barrels/angular';
import {Observable} from '../../shared/barrels/rxjs/';
import { take } from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

import {AdminService} from '../../app/services/admin.service';
import {StateService} from '../../core/services/state/state.service';
import {UserPreferencesService} from '../../administration/services/user-preferences.service';
import {UserService} from '../../core/services/user/user.service';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {EnvService} from './env.service';

@Injectable()
export class LoginService {

    private apiBaseUrl: string;
    private consoleBaseUrl: string;
    private loginUrl = 'api/login_check';
    private resetPassUrl = 'api/reset-password';

    constructor(
        private http: HttpClient,
        private adminService: AdminService,
        private stateService: StateService,
        private userPreferencesService: UserPreferencesService,
        private user: UserService,
        private translate: TranslateService,
        private environment: EnvService
    ) {
        this.apiBaseUrl = this.environment.apiBaseUrl;
        this.consoleBaseUrl = this.environment.consoleBaseUrl;
    }

    /**
     * Verifies the login credential and if right, sets the session parameters
     *
     * @param that - "this" object of "that" caller
     * @param model - response from the login form
     * @param language
     * @param mobile
     */
    login(that, model: any, language, mobile?: boolean): void {
        let peoplePage = 'people/list';
        if (mobile) {
            peoplePage = 'm/people';
        }

        let formData: any = new FormData();
        formData.append('username', model.value.username);
        formData.append('password', model.value.password);
        this.checkCredentials(formData).pipe(take(1)).subscribe(
            data => {
                // Init State
                this.stateService.initState();

                localStorage.setItem('is_logged', 'yes');
                localStorage.setItem('id_token', data.token);

                this.stateService.session.redirectUrl = '';

                // Init session
                this.adminService.getSession(language).pipe(take(1)).subscribe(
                    sessionData => {
                        this.initSessionDataOnLogin(sessionData);

                        that.credentials.emit(true);
                        that.loading = false;
                        that.router.navigate([peoplePage]);

                        // TODO: this is refactoring logic:
                        this.user.loggedUser = sessionData;
                    }
                );
            },
            error => {
                that.changeDetectorRef.markForCheck();
                that.loading = false;
                // Generate translate key using response from API
                if (error.status === 401) {
                    that.errorLogin = (this.translate.currentLang === 'fr') ?
                        'Vos informations de connexion sont inexactes.' :
                        'Your login information is incorrect.';
                }
            }
        );
    }

    /**
     * Negociates login with the API.
     * Note: As this is (for now) the only http request in the app, it won't
     *       wont use the same service scheme as the rest of the application
     *
     * @param data
     * @returns {Observable<any>}
     */
    checkCredentials(data: any): Observable<any> {
        return this.http.post(this.apiBaseUrl + this.loginUrl, data);
    }

    /**
     * Get token for a forgotten password
     */
    resetPassword(data: any): Observable<any> {
        const baseUrl = (this.environment && this.environment['docker'] && this.environment['docker'] === true) ? 'http://localhost:4200/' : this.apiBaseUrl;
        const changePassUrl = baseUrl + 'reset-pass/';
        const urlParams = '?username=' + data.username + '&lang=' + data.lang + '&changePasswordUrl=' + changePassUrl;
        return this.http.get(this.apiBaseUrl + this.resetPassUrl + urlParams);
    }

    /**
     * Change forgotten password
     */
    changePassword(data: any): Observable<any> {
        const changePassUrl = this.apiBaseUrl + this.resetPassUrl + '/' + data.token;
        return this.http.put(changePassUrl, {password: data.password});
    }

    // Init data on login
    private initSessionDataOnLogin(sessionData: any): void {
        this.stateService.sessionData = sessionData;
        // Init preferences
        this.userPreferencesService.initUserPreferences();
        // Init account credit
        this.stateService.session.sessionCreditUpdateWatch.next(true);
        // Set recordId to null to avoid call error
        this.stateService.people.report.reportType = null;
        // Side panel: display default side panel
        this.stateService.people.sideBarStatus = 'recentActivities';
    }
}
