<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'jobs.duplicateModal.duplicateModalTitle' | translate }}</h4>

    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body" style="background-color: #EEE">
    <!-- subAccounts -->
    <div *ngIf="validSubAccounts">
        <ng-multiselect-dropdown
            [placeholder]="'commons.subAccount' | translate"
            [settings]="dropDownSettings"
            [data]="myOptions"
            [(ngModel)]="optionsModel"
        >
        </ng-multiselect-dropdown>
    </div>

    <!-- hardCopy -->
    <!--div style="margin: 5px auto 3px auto">
        <input type="checkbox" formControlName="hardCopy" id="dupHadrCopy">
        <label for="dupHadrCopy">
            {{ 'jobs.duplicateModal.localCopy' | translate }}
        </label>
    </div-->
</div>
<!-- Submit -->
<div class="modal-footer">
    <button type="submit" class="modalButton pull-right" (click)="onSubmit()">
        {{ 'commons.save' | translate }}
    </button>
</div>
