import {Component, EventEmitter, ChangeDetectionStrategy, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {Router} from '@angular/router';
import {ComponentPrimitive} from 'src/app/shared/classes/components/componentPrimitive';
import {StateService} from 'src/app/core/services/state/state.service';
import {IdwMatrixListService} from '../../../services/idw-matrix-list.service';
import {IdwMatrixSearchFiltersService} from '../../../services/idw-matrix-search-filters.service';
import {UserPreferencesService} from 'src/app/administration/services/user-preferences.service';
import {TranslateService} from '@ngx-translate/core';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-idw-matrix-search-filters',
    templateUrl: './idw-matrix-search-filters.component.html',
    styleUrls: ['./idw-matrix-search-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatrixSearchFiltersComponent extends ComponentPrimitive implements OnInit {

    @Input() whereAmI: string;
    @Input() width: string;
    @Output() selectPreference = new EventEmitter;

    preferenceUrl: string;
    userDataId: string;

    private subscriptions = new Subscription();

    constructor(
        public idwMatricesList: IdwMatrixListService,
        public idwMatrixSearchFiltersService: IdwMatrixSearchFiltersService,
        public stateService: StateService,
        private userPreferencesService: UserPreferencesService,
        private changeDetectorRef: ChangeDetectorRef,
        public translate: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(
            stateService,
            translate,
            router,
            deviceService
        );
    }

    ngOnInit() {
        this.idwMatrixSearchFiltersService.initDateFormat(this.dateFormat());
        this.idwMatrixSearchFiltersService.setSubAccountsOptions();
        this.idwMatrixSearchFiltersService.setDepartmentsOptions();

        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.idwMatrixSearchFiltersService.initDateFormat(this.dateFormat());
            this.checkPeriodDates();
        }));

        this.idwMatricesList.checkModuleUrl();
        this.idwMatrixSearchFiltersService.checkPreferences(this.whereAmI);

        if (this.whereAmI === 'filter') {
            this.idwMatrixSearchFiltersService.initInputState();
        }

        if (this.whereAmI === 'preferences') {
            let accountDataId = (this.idwMatricesList.accountData) ? this.idwMatricesList.accountData.id : '';
            this.userDataId = (this.idwMatricesList.userData) ? this.idwMatricesList.userData.id : '';
            this.preferenceUrl = 'admin/account/' + accountDataId + '/user/' + this.userDataId + '/preferences';

            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.idwMatricesList.userPreferences, this.preferenceUrl);

            // Init state with session vars
            this.idwMatrixSearchFiltersService.initInputStatePreferences(this);
            this.changeDetectorRef.markForCheck();
        }

        this.idwMatrixSearchFiltersService.initSubAccountFilter();

        this.checkPeriodDates();

        this.idwMatrixSearchFiltersService.initColumnsFilter();
    }

    setFilters(event, filter) {
        switch (filter) {
            case 'idwMatrixStartDate':
                this.idwMatrixSearchFiltersService.setSearchFilter(event, 'idwMatrixStartDate');
                break;
            case 'idwMatrixEndDate':
                this.idwMatrixSearchFiltersService.setSearchFilter(event, 'idwMatrixEndDate');
                break;
            case 'subAccount':
                let subAccounts = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(subAccounts, 'subAccountCol', this.translate.instant('commons.subAccount'));
                this.idwMatrixSearchFiltersService.subAccount(subAccounts, this, this.idwMatricesList.specificState);
                break;
            case 'department':
                let deps = this.checkDropDownOptions(event);
                this.checkDropDownPlaceholder(deps, 'depCol', this.translate.instant('commons.division'));
                this.idwMatrixSearchFiltersService.onDepartmentOptionChange(deps, this, this.idwMatricesList.specificState);
                break;
            case 'columnDisplay':
                this.idwMatrixSearchFiltersService.columnDisplay(event, this, this.idwMatricesList.specificState);
                break;
        }
        this.idwMatricesList.getIdwMatricesList(true, null, this);
    }

    checkPeriodDates() {
            if (this.idwMatricesList.idwMatrixStartDate) {
                let dateStartObject = this.idwMatricesList.transformDate(this.idwMatricesList.idwMatrixStartDate);
                this.idwMatrixSearchFiltersService.dateStartModel = dateStartObject;
                this.idwMatrixSearchFiltersService.dateStart = dateStartObject;
            }

            if (this.idwMatricesList.idwMatrixEndDate) {
                let dateEndObject = this.idwMatricesList.transformDate(this.idwMatricesList.idwMatrixEndDate);
                this.idwMatrixSearchFiltersService.dateEndModel = dateEndObject;
                this.idwMatrixSearchFiltersService.dateEnd = dateEndObject;
            }
        }

    resetSearchFiltersAction(preferences = null) {
        this.idwMatrixSearchFiltersService.resetSearchFiltersAction();

        if (this.whereAmI === 'filter') {
            this.idwMatrixSearchFiltersService.resetSearchFilters();
        }

        this.idwMatricesList.resetFilters();

        if (preferences === null) {
            this.idwMatricesList.setInitialColumnsList();
            this.idwMatricesList.listColumns = JSON.parse(JSON.stringify(this.idwMatricesList.listColumnsInitialConfig));
        } else {
            // Inject info in state
            this.userPreferencesService.setUserPreferences(this.idwMatricesList.userPreferences, this.preferenceUrl, 'idwMatrix');
            this.idwMatrixSearchFiltersService.initInputStatePreferences(this);
        }

        this.selectPreference.emit({
            type: 'selectVanilla'
        });
    }

    resetSearchFilters() {
        if (this.whereAmI === 'filter' && this.actualModule.name !== 'idwMatrix') {
            const preferences = (this.userPreferencesService.isPreferenceSet('idwMatrix')) ?
                this.stateService.session.preferences['idwMatrix'] :
                null;
            this.resetSearchFiltersAction(preferences);
        } else {
            this.resetSearchFiltersAction();
            this.userPreferencesService.emptyUserPreferences('idwMatrix', this.userDataId);
        }
        this.idwMatricesList.getIdwMatricesList(true, null, this);
    }

}

