<div class="modal-header">
    <h4 class="modal-title pull-left">
        {{ 'jobSatisfactions.modal.filterPeople' | translate }}
    </h4>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<div class="modal-body">
    <h2 class="ngorange">{{ 'jobSatisfactions.modal.sortBy' | translate }}</h2>
    <select
        [(ngModel)]="filters.sortBy"
        class="form-control"
    >
        <option value="nameAsc">{{ 'jobSatisfactions.report.nameAsc' | translate }}</option>
        <option value="nameDesc">{{ 'jobSatisfactions.report.nameDesc' | translate }}</option>
        <option value="jobSatisfactionScoreAsc">{{ 'jobSatisfactions.report.jobSatisfactionScoreLevelAsc' | translate }}</option>
        <option value="jobSatisfactionScoreDesc">{{ 'jobSatisfactions.report.jobSatisfactionScoreLevelDesc' | translate }}</option>
        <option value="lastPcrAsc">{{ 'jobSatisfactions.report.lastPcrAsc' | translate }}</option>
        <option value="lastPcrDesc">{{ 'jobSatisfactions.report.lastPcrDesc' | translate }}</option>
    </select>
    <!-- table class="filterTable">
        <tr>
            <td>
                <input type="radio" name="sortBy" value="name"
                       [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                >
            </td>
            <td>{{ 'commons.name' | translate }}</td>
        </tr>
        <tr>
            <td>
                <input type="radio" name="sortBy" value="score"
                       [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                >
            </td>
            <td>{{ 'analysis.jobSatisfaction.jobSatisfactionLevel' | translate }}</td>
        </tr>
        <tr>
            <td>
                <input type="radio" name="sortBy" value="lastPcrDate"
                       [(ngModel)]="filters.sortBy" (change)="sortRecords()"
                >
            </td>
            <td>{{ 'analysis.jobSatisfaction.lastPcrDate' | translate }}</td>
        </tr>
    </table>
    <h2 class="ngorange">Option</h2-->
</div>
<div class="modal-footer">
    <button type="submit"
            class="modalButton pull-right"
            (click)="onSubmit()"
            style="margin-top: 15px"
    >
        {{ 'commons.save' | translate }}
    </button>
    <button (click)="closeModal()" type="button" class="modalButton pull-right"
            style="margin-top: 15px; margin-right: 15px"
    >
        {{ 'commons.cancel' | translate }}
    </button>
    <div class="clearfix"></div>
</div>
