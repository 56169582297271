import {Subscription} from 'rxjs';
import {ChangeDetectorRef, Input} from '@angular/core';
import {EvaluationHelper} from '../../../shared/misc/evaluation.helper';
import {TranslateService} from '@ngx-translate/core';
import {ApiJobsService} from '../../../jobs/services/api-jobs.service';
import {ApiAdministrationService} from '../../../administration/services/api-administration.service';
import {EvaluationRequestNext} from '../../models/evaluation-request-next.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {emailValidator} from '../../validators/email';
import {PeopleSurveyRequestSendMethodsHelper} from './people-survey-request/send-methods/send-methods.helper';
import {StateService} from '../../../core/services/state/state.service';
import {TestAndReportHandlingService} from '../../services/test-and-report-handling.service';

export abstract class PeopleRequestSendModalCommon {

    @Input() sendAData;
    @Input() prbForm = false;
    @Input() raForm = false;
    @Input() resendForm = false;
    @Input() activationData: any;

    evaluationResendForm: FormGroup;
    evaluationRequestResend: EvaluationRequestNext = new EvaluationRequestNext();

    person: any;
    tempUser: any;
    evaluations: any;
    mailto: any;
    sessionData: any;
    resendType: string;
    introductionText: string;
    questionnaireType: string;
    country: string;
    language: string;
    languages;
    modalTitle = 'commons.sendMpo';
    textToSend = '';
    variant;
    variantOptionsValue = {
        traitsAndPrb: '1,1',
        traitsOnly: '1,0'
    };
    innerHtml;
    sendingTypes;
    introductionTextObj;
    warningNothingSelected = false;
    bindedProfiler: string;
    protected subscriptions = new Subscription();

    protected constructor(
        protected apiJobsService: ApiJobsService,
        protected apiAdministrationService: ApiAdministrationService,
        protected cd: ChangeDetectorRef,
        protected translateService: TranslateService,
        protected testAndReportHandlingService: TestAndReportHandlingService,
        public stateService: StateService,
        protected fb: FormBuilder
    ) {
        this.sessionData = (this.stateService.session) ? this.stateService.session.sessionData : null;
    }

    getLanguages() {
        this.languages = {
            fr: [
                {
                    value: 'fr',
                    name: 'Français'
                },
                {
                    value: 'en',
                    name: 'Anglais'
                },
                {
                    value: 'es',
                    name: 'Espagnol'
                },
                {
                    value: 'ar',
                    name: 'Arabe'
                }
            ],
            en: [
                {
                    value: 'fr',
                    name: 'French'
                },
                {
                    value: 'en',
                    name: 'English'
                },
                {
                    value: 'es',
                    name: 'Spanish'
                },
                {
                    value: 'ar',
                    name: 'Arabic'
                }
            ]
        };
    }

    getSendingTypesList(raForm = false, stringValue = false) {
        this.sendingTypes = [
            {
                value: (!stringValue) ? 1 : '',
                title: 'commons.select'
            },
            {
                value: (!stringValue) ? 2 : 'email',
                title: 'commons.sendByMyEmail'
            },
            {
                value: (!stringValue) ? 3 : 'server',
                title: 'commons.sendByServer'
            },
            {
                value: (!stringValue) ? 4 : 'cutpaste',
                title: 'commons.cutnpaste'
            }
        ];
        if (!raForm) {
            this.sendingTypes.push(
                {
                    value: (!stringValue) ? 5 : 'paper',
                    title: 'commons.fillOnline'
                }
            );
        }
    }

    setModalTitles() {
        if (this.prbForm) {
            this.modalTitle = 'commons.sendAdditionalPrb';
        }
        if (this.raForm) {
            this.modalTitle = 'commons.sendIac';
        }
        if (this.resendForm) {
            this.modalTitle = 'commons.resendAccessCode';
        }
        if (!this.sendAData) {
            // modal title for a new survey request modal
            this.modalTitle = 'people.requestModal.title';
        }
    }

    getResendIntroductionText() {
        this.introductionTextObj = {
            resendMpo: this.translateService.instant('people.requestModal.toResendMpo'),
            resendPrb: this.translateService.instant('people.requestModal.toResendPrb'),
            resendRa: this.translateService.instant('people.requestModal.toResendRa'),
            prbTitle: this.translateService.instant('commons.prb'),
            raTitle: this.translateService.instant('commons.cai'),
            language: {
                fr: this.translateService.instant('commons.french'),
                en: this.translateService.instant('commons.english'),
                es: this.translateService.instant('commons.spanish'),
                ar: this.translateService.instant('commons.arabic'),
            }
        };
    }

    checkRequestContext(tempUser = null, init = true) {
        this.evaluationRequestResend = new EvaluationRequestNext();
        if (!tempUser) {
            tempUser = (this.sendAData && this.sendAData.tempUser) ? this.sendAData.tempUser : null;
        }
        this.tempUser = tempUser;
        this.person = this.targetPerson;
        if (init) {
            this.evaluations = this.person ? this.person.evaluations : null;
            this.language = (this.evaluations &&
                this.evaluations.requests &&
                this.evaluations.requests[0]) ? this.evaluations.requests[0].language : null;
            this.country = (this.evaluations &&
                this.evaluations.requests &&
                this.evaluations.requests[0]) ? this.evaluations.requests[0].country : null;
            this.resendType = (this.sendAData && this.sendAData.type) ? this.sendAData.type : null;
            this.introductionText = this.resendType ? this.introductionTextObj[this.resendType] : null;

            let raForm = false;
            if (this.resendType === 'resendIac') {
                raForm = true;
            }
            this.getSendingTypesList(raForm);

            if (this.resendType === 'resendMpo') {
                this.questionnaireType = 'MPO';
            }
            if (this.resendType === 'resendPrb') {
                this.questionnaireType = this.introductionTextObj.prbTitle;
            }
            if (this.resendType === 'resendIac') {
                this.questionnaireType = this.introductionTextObj.raTitle;
            }
        }

        if (this.tempUser && this.tempUser.email) {
            this.evaluationRequestResend.emailTo = this.tempUser.email;
        }

        // Init
        this.initEvaluationResendForm();

        if (this.person && this.tempUser) {
            this.mailto = this.initMessage('mail', this.person, this.tempUser);
            this.innerHtml = this.initMessage('cutnpaste', this.person, this.tempUser);
        }
    }

    initEvaluationResendForm() {
        this.evaluationResendForm = this.fb.group({
                emailTo: [this.evaluationRequestResend.emailTo],
                // subject : [this.evaluationRequestResend.subject],
                message: [(this.evaluationRequestResend.message) ? this.evaluationRequestResend.message : this.textToSend]
            },
            {
                validator: emailValidator('emailTo')
            });
    }

    initMessage(type, person, tempUser) {
        let hasIac = false;
        if (this.prbForm) {
            this.variant = '0,1';
        }
        if (this.raForm) {
            this.variant = '0,0';
            hasIac = true;
        }
        if (this.resendForm) {
            this.variant = this.sendAData.person.evaluations.requests[0].variant;
            hasIac = this.sendAData.person.evaluations.requests[0].type.includes('ra');
        }
        return PeopleSurveyRequestSendMethodsHelper.initMessage(
            type,
            person,
            tempUser,
            this.language,
            this.variant,
            {
                name: this.sessionData.userData.fullName,
                company: this.sessionData.structure.subAccounts[0].name
            },
            hasIac
        );
    }

    reportActivationCommon(type = null) {
        this.warningNothingSelected = (type === 'satellite' && (!this.bindedProfiler || this.bindedProfiler === 'none'));
        if (this.warningNothingSelected) {
            return;
        }

        if (!this.activationData.personSubAccount && this.activationData.subAccount === undefined) {
            this.activationData.subAccount = this.activationData.personToDisplay.subAccount;
        }

        if (!this.activationData.personId && !this.activationData.id) {
            this.activationData.id = this.activationData.personToDisplay.id;
        }

        // PRB related index
        const prbRelatedIndex: number | null = (this.activationData.hasOwnProperty('prbRelated')) ? this.activationData.prbRelated : null;

        let params = {};
        if (this.bindedProfiler) {
            params['satelliteJobId'] = this.bindedProfiler;
        }

        if (this.activationData.type) {
            params['recordId'] = this.activationData.id;
            params['type'] = this.activationData.type;
            params['subAccount'] = this.activationData.subAccount;

            params = {...params, ...EvaluationHelper.getPrbEnableParams(params['type'], prbRelatedIndex)};

            this.testAndReportHandlingService.activateReportByParams(this, params);
        } else if (this.activationData.types) {

            for (let typeOfAd of this.activationData.types) {
                if (typeOfAd === 'satellite') {
                    params['satelliteJobId'] = this.activationData.satelliteJobId;
                }

                params['recordId'] = this.activationData.id;
                params['type'] = typeOfAd;
                params['subAccount'] = this.activationData.subAccount;

                params = {...params, ...EvaluationHelper.getPrbEnableParams(params['type'], prbRelatedIndex)};

                this.testAndReportHandlingService.activateReportByParams(this, params);
            }
        }
    }

    get targetPerson() {
        return (this.sendAData && this.sendAData.hasOwnProperty('person')) ? this.sendAData['person'] : null;
    }

    get targetReport() {
        return (this.sendAData && this.sendAData.hasOwnProperty('report')) ? this.sendAData['report'] : null;
    }

    get personEmail() {
        return this.targetPerson ? this.targetPerson.email : null;
    }
}
