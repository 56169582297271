<div class="modal-header">
    <h4 class="modal-title pull-left" *ngIf="translate.currentLang=='fr'">
        {{ 'commons.transactionType' | translate }}
    </h4>
    <div *ngIf="!proceed" class="pull-left"
        id="insufficientCreditLevelWarning"
    >
        {{ 'commons.insufficientCreditLevel' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<div class="modal-body" *ngIf="transaction">
    <table id="transaction">
        <tr *ngIf="['formations', 'annual_fees', 'buying_credits', 'buying_programs'].includes(transaction.transactionType)">
            <td>Sku{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>{{ transaction.sku }}</td>
        </tr>
        <tr>
            <td>{{ 'commons.description' | translate }}{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>
                <ng-container
                    *ngIf="['formations', 'annual_fees', 'buying_credits', 'buying_programs'].includes(transaction.transactionType) && !transaction.deleted else noTransactionEdit"
                >
                    <select *ngIf="products" class="form-control" id="transactionSelect" [(ngModel)]="transaction.sku"
                        (change)="transactionChanged()"
                    >
                        <option value="">{{ 'commons.select' | translate }}</option>
                        <optgroup label="Canada">
                            <option *ngFor="let product of products['ca']" [value]="product.sku">
                                {{ product.descriptive[translate.currentLang] }}
                            </option>
                        </optgroup>
                        <optgroup label="USA">
                            <option *ngFor="let product of products['us']" [value]="product.sku">
                                {{ product.descriptive[translate.currentLang] }}
                            </option>
                        </optgroup>
                    </select>
                </ng-container>
                <ng-template #noTransactionEdit>
                    {{ accountService.rewriteTransactionDescriptive(transaction) }}
                </ng-template>
            </td>
        </tr>
        <tr>
            <td>{{ 'commons.subAccount' | translate }}{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>
                <ng-container
                    *ngIf="[].includes(transaction.transactionType)  && !transaction.deleted else noSubAccountEdit">
                    <select class="form-control" id="subAccountSelect"
                            [(ngModel)]="transaction.subAccount"
                            (change)="checkCreditSufficiency(transaction.subAccount, transaction.amount)"
                    >
                        <option value="">{{ 'commons.select' | translate }}</option>
                        <option *ngFor="let subAcct of subAccounts" [value]="subAcct.id">
                            {{subAcct.name}}
                        </option>
                    </select>
                </ng-container>
                <ng-template #noSubAccountEdit>
                    {{ transaction.subAccountData.name }}
                </ng-template>
            </td>
        </tr>
        <tr>
            <td>Date{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>
                <ng-container
                    *ngIf="['formations', 'annual_fees', 'buying_credits', 'buying_programs'].includes(transaction.transactionType) && !transaction.deleted; else noDateEdit">
                    <my-date-picker
                        [locale]="translate.currentLang"
                        [options]="datePickerOptions"
                        [(ngModel)]="transaction.date"
                        (dateChanged)="transactionChanged()"
                    >
                    </my-date-picker>
                </ng-container>
                <ng-template #noDateEdit>
                    {{ transaction.date | date:dateFormat }}
                </ng-template>

            </td>
        </tr>
        <tr>
            <td>Credits{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>
                <ng-container
                    *ngIf="[].includes(transaction.transactionType) && !transaction.deleted; else costNoEdit">
                    <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="transaction.amount"
                        (change)="checkCreditSufficiency(transaction.subAccount, transaction.amount)"
                        step="1"
                        style="width: 70px"
                    >
                </ng-container>
                <ng-template #costNoEdit>
                    {{ transaction.amount }}
                </ng-template>
            </td>
        </tr>
        <tr>
            <td style="vertical-align: top;">Note{{ (translate.currentLang === 'fr') ? ' ' : '' }}:</td>
            <td>
                <ng-container
                    *ngIf="['formations', 'annual_fees', 'buying_credits', 'buying_programs', 'adjustment'].includes(transaction.transactionType) && !transaction.deleted; else commentNoEdit"
                >
                    <textarea
                        class="form-control"
                        [(ngModel)]="transaction.extraDescription"
                    >
                        {{ transaction.extraDescription }}
                    </textarea>
                </ng-container>
                <ng-template #commentNoEdit>
                    {{ transaction.extraDescription }}
                </ng-template>
            </td>

        </tr>
        <tr *ngIf="['formations', 'annual_fees', 'buying_credits', 'buying_programs'].includes(transaction.transactionType) || transaction.deleted"  >
            <td></td>
            <td>
                <input type="checkbox" [(ngModel)]="transaction.hardDelete" id="isRecurrent"/> {{ 'commons.hardDelete' | translate }}
            </td>
        </tr>
    </table>
</div>
<div class="modal-footer">
    <ng-container *ngIf="!['reports', 'creation_demo', 'creation_subAccount', 'transfer_credits'].includes(transaction.transactionType)">
        <div>
            <button type="submit" class="modalButton pull-right" (click)="saveEdition()"
                    style="margin-top: 15px"
                    [disabled]="(!proceed || transaction.hardDelete) && !transaction.deleted"
            >
                {{ 'commons.edit' | translate }}
            </button>
        </div>
        <div>
            <button type="submit" class="modalButton pull-right" (click)="deleteTransaction()"
                    style="margin-top: 15px; margin-right: 15px"
                    [disabled]="!proceed || hasChanged || transaction.deleted"
            >
                {{ 'commons.delete' | translate }}
            </button>
        </div>
    </ng-container>
    <div>
        <button type="submit" class="modalButton pull-right" (click)="closeModal()"
                style="margin-top: 15px; margin-right: 15px">
            <ng-container *ngIf="['reports', 'creation_demo', 'creation_subAccount', 'transfer_credits'].includes(transaction.transactionType); else cancel">
                {{ 'commons.close' | translate }}
            </ng-container>
            <ng-template #cancel>
                {{ 'commons.cancel' | translate }}
            </ng-template>
        </button>
    </div>
    <div class="clearfix"></div>
</div>
