<div class="modal-header">
    <h4 class="modal-title pull-left">
        <span *ngIf="status !== 'edit'">
            {{ 'gbs.modal.newGraphboard' | translate }}
        </span>
        <span *ngIf="status === 'edit'">
            {{ 'gbs.modal.editGraphboard' | translate }}
        </span>
    </h4>
    <div
            class="pull-left text-danger"
            style="margin-left: 15px; margin-top: 2px"
            *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

<form [formGroup]="graphboardForm" (ngSubmit)="onSubmit(graphboardForm)" #graphboardNgForm="ngForm">
    <div class="modal-body">
        <div class="headerText" *ngIf="headerText">
            {{ headerText }}
        </div>
        <div class="form-group">
            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(graphboardForm.controls.name, graphboardNgForm, submitted)"
                >
                    <label class="field_title" for="gName">
                        {{ 'gbs.modal.gbName' | translate }}
                    </label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(graphboardForm.controls.name, graphboardNgForm)"
                        class="text-danger"
                    >
                        <span [hidden]="!graphboardForm.controls.name.errors['minlength']">
                            {{ 'forms.warnings.warnMin2Chars' | translate }}
                        </span>
                        <span [hidden]="!graphboardForm.controls.name.errors['required']">
                            {{ 'gbs.modal.gbName' | translate }}
                        </span>
                    </small>
                </div-->
                <div class="clearfix"></div>
                <input type="text" id="gName" class="form-control" formControlName="name">
            </div>

            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(graphboardForm.controls.subAccount, graphboardNgForm, submitted)"
                >
                    <label class="field_title" for="gSubAccount">{{ 'commons.subAccount' | translate }}</label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(graphboardForm.controls.subAccount, graphboardNgForm)"
                        class="text-danger"
                    >
                        {{ 'commons.subAccountMandatory' | translate }}
                    </small>
                </div-->
                <div class="clearfix"></div>
                <select class="form-control" id="gSubAccount" formControlName="subAccount">
                    <option>{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <div>
                    <div class="pull-left"
                         [class.text-danger]="validateFormField(graphboardForm.controls.description, graphboardNgForm, submitted)"
                >
                    <label class="field_title" for="gDescription">
                        {{ 'commons.description' | translate }}
                    </label>
                </div>
                <!--div class="pull-right" --><!-- REPLACE THIS WITH DESCRIPTION!
                    <small
                        *ngIf="validateFormField(graphboardForm.controls.description, graphboardNgForm)"
                        class="text-danger"
                    >
                        <span [hidden]="!graphboardForm.controls.description.errors['required']">
                            {{ 'commons.descriptionMandatory' | translate }}
                        </span>
                    </small>
                </div-->
                <div class="clearfix"></div>
                <textarea type="text" class="form-control" id="gDescription" formControlName="description"></textarea>
            </div>

            <!-- department -->
            <div *ngIf="departments">
                <div class="pull-left">
                    <label class="field_title" for="gDepartment">{{ 'commons.division' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="gDepartment" formControlName="department">
                    <option>{{ 'commons.none' | translate }}</option>
                    <option *ngFor="let dpt of departments" [value]="dpt.id">
                        {{dpt['fr']}}
                    </option>
                </select>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <!--  [disabled] contained && graphboardForm.submitted but caused error at compile-->
        <button
            type="submit"
            class="modalButton pull-right"
            [disabled]="!graphboardForm.valid && graphboardNgForm.submitted"
            style="margin-top: 15px"
        >
            <span *ngIf="status !== 'edit'">
            {{ 'commons.create' | translate }}
            </span>
            <span *ngIf="status === 'edit'">
            {{ 'commons.save' | translate }}
            </span>
        </button>
        <button (click)="closeModal()" type="button" class="modalButton pull-right"
                style="margin-top: 15px; margin-right: 15px"
        >
            {{ 'commons.cancel' | translate }}
        </button>
        <div class="clearfix"></div>
    </div>
</form>
