<div *ngIf="!data" class="newSurveyRequest">
    <!-- new survey request case -->
    <div *ngIf="surveyRequestFormGroup" class="modal-header">

        <h4 class="modal-title pull-left">
            {{ modalTitle | translate }}
        </h4>

        <!-- Error message -->
        <div *ngIf="(isRequestFormSubmitted$ | async) && !surveyRequestFormGroup.valid"
             class="pull-left text-danger"
             style="margin-left: 15px; margin-top: 2px"
        >
            {{ 'commons.multiError' | translate }}
        </div>

        <div (click)="closeModal(requestStep, true)" aria-label="Close" class="x-close pull-right"
             style="font-size: 20pt;">
            &#x00D7;
        </div>
        <div class="clearfix"></div>

        <!-- API error -->
        <div *ngIf="apiMessageError$ | async as apiMessageError">
            <p class="text-danger">{{ apiMessageError | translate }}</p>
        </div>
    </div>

    <!-- Request form -->
    <app-people-survey-request-form
        *ngIf="(requestStep$ | async) === stepEnum.RequestForm"
        [surveyRequestFormGroup]="surveyRequestFormGroup"
        [sessionData]="sessionData"
        [isFormSubmitted]="(isRequestFormSubmitted$ | async)"
        [researchAccount]="researchAccount"
        (cancelRequestForm)="closeModal()"
        (resetRequestForm)="resetRequestForm()"
        (submitRequestForm)="onSubmit(null)"
    ></app-people-survey-request-form>

    <!-- Send methods (after form valid) -->
    <app-people-survey-request-send-methods
        *ngIf="(requestStep$ | async) === stepEnum.SendMethods"
        [sendMethod]="surveyRequestFormGroup.get('sendingType').value"
        [tempUser]="tempUser$ | async"
        [sendAnotherSurvey]="anotherRequest"
        (closeSendMethods)="closeModal($event)"
        (sendAnotherRequest)="sendAnotherRequest()"
    ></app-people-survey-request-send-methods>
</div>
<div *ngIf="data">
    <!-- existing survey request case -->
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ modalTitle | translate }}
            {{resendForm ? questionnaireType : ''}}
        </h4>
        <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
             style="font-size: 20pt;">
            &#x00D7;
        </div>
    </div>
    <!--- new ---->
    <ng-container *ngIf="toggle == 0" class="reqContent" [@fadeInOut]>
        <div class="modal-body">
            <div *ngIf="resendForm">
                <h4 *ngIf="resendType === 'resendPrb'">{{ 'commons.initialQuestionnaire' | translate }}</h4>
                <h4 *ngIf="resendType !== 'resendPrb'">{{ 'commons.survey' | translate }}</h4>
                <div style="padding: 0 0 10px 10px;">Type{{currentLanguage === 'fr' ? ' :' : ':'}}
                    <span *ngIf="questionnaireInitialType === 'ra'">
                        {{ 'commons.iac' | translate }}
                    </span>
                    <span *ngIf="questionnaireInitialType !== 'ra'">
                        {{checkFirstQ()}}
                    </span>
                    <span *ngIf="questionnaireInitialType === '1,1'">
                        ({{ 'people.commons.complete' | translate }})
                    </span>
                    <span *ngIf="questionnaireInitialType === '1,1,ra'">
                        ({{ 'people.commons.complete' | translate }}) +
                        {{ 'people.sidebar.raRequestHandling' | translate }}
                    </span>
                    <span *ngIf="questionnaireInitialType === '1,0'">
                        ({{ 'people.commons.traits' | translate }})
                    </span>
                    <span *ngIf="questionnaireInitialType === '0,1'">
                        ({{ 'people.commons.behavior' | translate }})
                    </span>
                    <div *ngIf="language">{{ 'commons.language' | translate }}{{currentLanguage === 'fr' ? ' :' : ':'}}
                        {{introductionTextObj.language ? introductionTextObj.language[language] : ''}}
                    </div>
                    <div *ngIf="country">{{ 'commons.country' | translate }}{{currentLanguage === 'fr' ? ' :' : ':'}}
                        {{getCountryName(country)}}
                    </div>
                </div>
                <p *ngIf="introductionText">{{introductionText}}</p>
                <h3>{{ 'commons.sendingModeSelection' | translate }}</h3>
                <label>
                    <select [(ngModel)]="flipValue" (change)="toggleFlip(flipValue)" class="form-control"
                            style="width: 350px">
                        <option *ngFor="let t of sendingTypes"
                                [value]="t.value">{{ t.title | translate }}</option>
                    </select>
                </label>
            </div>
            <div *ngIf="!resendForm" class="pull-left" style="width: 50%;">
                <div id="testSelection">
                    <div *ngIf="!prbForm" id="wantedReportsLeft" class="pull-left reportsCheckboxes">
                        <p>
                            <span>{{ 'commons.report' | translate }}</span>
                        </p>
                        <!-- MPO -->
                        <ng-container
                            *ngIf="stateService.hasPersonality"
                        >
                            <mat-checkbox
                                #mpoCheckbox
                                id="report_mpo"
                                [disabled]="(targetPerson && targetPerson['evaluations'] && targetPerson['evaluations']['mpo']) ?
                                                true : null"
                                [checked]="!raForm && (targetPerson['evaluations']['mpo'] || targetReport === 'mpo')"
                                (change)="checkIfActive(mpoCheckbox, 'mpo')"
                            >
                                {{ 'commons.personality' | translate }}
                            </mat-checkbox>
                        </ng-container>

                        <!-- Satellite -->
                        <ng-container
                            *ngIf="stateService.hasSatellite"
                        >
                            <mat-checkbox
                                #satelliteCheckbox
                                id="report_satellite"
                                [disabled]="(targetPerson && targetPerson['evaluations'] && targetPerson['evaluations']['satellite']) ?
                                                true : null"
                                [checked]="!raForm && (targetPerson['evaluations']['satellite'] || targetReport === 'satellite')"
                                (change)="checkIfActive(satelliteCheckbox, 'satellite')"
                            >
                                Satellite
                            </mat-checkbox>
                        </ng-container>

                        <!-- DIT -->
                        <ng-container
                            *ngIf="stateService.hasCommunication"
                        >
                            <mat-checkbox
                                #ditCheckbox
                                id="report_communication"
                                [disabled]="((targetPerson && targetPerson['evaluations'] && targetPerson['evaluations']['dit']) ?
                            true : null) || disableDitReportByDefault"
                                [checked]="!raForm && (targetPerson['evaluations']['dit'] || targetReport === 'dit')"
                                (change)="checkIfActive(ditCheckbox, 'dit')"
                            >
                                <span *ngIf="!raForm" [tooltip]="activationText">{{ 'commons.dit' | translate }}</span>
                                <span *ngIf="raForm">{{ 'commons.dit' | translate }}</span>
                            </mat-checkbox>
                        </ng-container>
                        <ng-template *ngIf="!raForm" #activationText>
                            <span *ngIf="currentLanguage === 'fr'">
                                Pour activer le rapport Communication veuillez cocher le rapport Personnalité.
                            </span>
                            <span *ngIf="currentLanguage === 'en'">
                                In order to enable the Communication report you have to check the Personality report.
                            </span>
                        </ng-template>

                        <!-- Talents -->
                        <ng-container
                            *ngIf="stateService.hasTalents"
                        >
                            <mat-checkbox
                                #talentsCheckbox
                                id="report_talent"
                                [disabled]="(targetPerson && targetPerson['evaluations'] && targetPerson['evaluations']['talents']) ?
                            true : null"
                                [checked]="!raForm && (targetPerson['evaluations']['talents'] || targetReport === 'talents')"
                                (change)="checkIfActive(talentsCheckbox, 'talents')"
                            >
                                {{ 'commons.talents' | translate }}
                            </mat-checkbox>
                        </ng-container>

                        <!-- CAI -->
                        <mat-checkbox *ngIf="raForm"
                                      id="report_iac"
                                      disabled
                                      [checked]="true"
                        >
                            {{ 'commons.iac' | translate }}
                        </mat-checkbox>
                    </div>
                    <div [class.pull-right]="!prbForm">
                        <div class="creditBoxInner">
                            <div>
                                <p>
                                    <span>{{ 'commons.credits' | translate }}</span>
                                </p>
                            </div>

                            <div class="creditCost"
                                 [style.background-color]="displayWarningMessage ? 'rgb(240, 123, 126)' : 'rgb(208, 238, 207)'">
                                <p>{{ 'people.requestModal.required' | translate }}</p>
                                <p>{{ creditCost || 0 }}</p>
                            </div>

                            <div class="creditsAvailable">
                                <p [class.redWarning]="displayWarningMessage">{{ 'people.requestModal.available' | translate }}</p>
                                <p>{{ creditAvailable || '-' }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <h3 *ngIf="!raForm && !prbForm">{{ 'commons.options' | translate }}</h3>
                <div *ngIf="satelliteReportSelected"
                     [formGroup]="surveyRequestFormGroup"
                >
                    <h4 *ngIf="raForm" class="georgia" style="margin-top: 10px">{{ 'commons.options' | translate }}</h4>
                    <div class="pull-left">
                        <label for="jobIdField" class="field_title">
                            {{ 'people.requestModal.profilerAssociated' | translate }}
                        </label>
                    </div>
                    <div class="clearfix"></div>
                    <select id="jobIdField" class="form-control" formControlName="satelliteJobId">
                        <option>{{ 'commons.select' | translate }}</option>
                        <option *ngFor="let job of satelliteJobs" [value]="job.id">
                            {{ job['jobTitle'] }}
                        </option>
                    </select>
                </div>
                <div [formGroup]="surveyRequestFormGroup" id="normSelection" [class.leftColumnInfo]="raForm">
                    <div *ngIf="!raForm && !prbForm" class="pull-left">
                        <label for="variantField" class="field_title"
                               [class.text-danger]="continueClicked && surveyRequestFormGroup.get('variant').errors"
                        >
                            {{ 'commons.survey' | translate }}
                        </label>
                    </div>
                    <div *ngIf="!raForm && !prbForm" class="clearfix"></div>
                    <select *ngIf="!raForm && !prbForm" id="variantField" formControlName="variant"
                            class="form-control">
                        <option>{{ 'commons.select' | translate }}</option>
                        <ng-container *ngFor="let variantOptions of openOptions">
                            <option [value]="variantOptionsValue[variantOptions]">
                                {{ 'people.commons.' + variantOptions | translate }}
                            </option>
                        </ng-container>
                    </select>
                    <div [class.leftColumnInfo]="!raForm">
                        <h3>{{ 'commons.normSelection' | translate }}</h3>
                        <div class="pull-left">
                            <label
                                class="field_title"
                                [class.text-danger]="continueClicked && surveyRequestFormGroup.get('country').errors"
                                for="countryInput"
                            >
                                {{ 'commons.country' | translate }}
                            </label>
                        </div>
                        <div class="clearfix"></div>
                        <select formControlName="country" id="countryInput" class="form-control">
                            <option>{{ 'commons.select' | translate }}</option>
                            <option *ngFor="let cntry of structureCountries[currentLanguage]"
                                    [value]="cntry.value">
                                {{cntry.name}}
                            </option>
                        </select>
                    </div>
                    <div>
                        <div class="pull-left">
                            <label
                                [class.text-danger]="continueClicked && surveyRequestFormGroup.get('language').errors"
                                class="field_title"
                                for="languageInput"
                            >
                                {{ 'commons.language' | translate }}
                            </label>
                        </div>
                        <div class="clearfix"></div>
                        <select
                            formControlName="language"
                            id="languageInput"
                            class="form-control"
                        >
                            <option>{{ 'commons.select' | translate }}</option>
                            <option *ngFor="let lang of languages[currentLanguage]"
                                    [value]="lang.value">
                                {{ lang.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- Participant info -->
                <app-people-survey-request-participant-info
                    [surveyRequestFormGroup]="surveyRequestFormGroup"
                    [isFormSubmitted]="isFormReady"
                    [researchAccount]="researchAccount"
                ></app-people-survey-request-participant-info>
            </div>
            <div *ngIf="!resendForm" class="pull-right" style="width: 48%;">
                <!-- Request -->
                <app-people-survey-request-info
                    [surveyRequestFormGroup]="surveyRequestFormGroup"
                    [sessionData]="sessionData"
                    [isFormSubmitted]="isFormReady"
                    [mpoModal]="!raForm"
                    [iacModal]="raForm"
                ></app-people-survey-request-info>
                <div [formGroup]="surveyRequestFormGroup">
                    <div class="pull-left">
                        <label for="sendingTypeSelect" class="field_title"
                               [class.text-danger]="continueClicked && surveyRequestFormGroup.get('sendingType').errors"
                        >{{ 'commons.sendingModeSelection' | translate }}</label>
                    </div>
                    <div class="clearfix"></div>
                    <select formControlName="sendingType" id="sendingTypeSelect" class="form-control">
                        <option *ngFor="let t of sendingTypes"
                                [value]="t.value">{{ t.title | translate }}
                        </option>
                    </select>
                </div>
                <div [formGroup]="surveyRequestFormGroup" style="margin-top: 10px">
                    <div class="pull-left autoGenWrap">
                        <mat-checkbox formControlName="autoGen"
                        >{{ 'people.requestModal.autogenReport' | translate }}
                        </mat-checkbox>
                    </div>
                    <div class="pull-left">
                        <img alt="" [tooltip]="'people.requestModal.autogenTooltip' | translate"
                             src="assets/other_icons/question.png"
                             style="padding-left: 10px; padding-top: 2px;"
                        />
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <div style="margin-top: 10px">
                    <button *ngIf="!resendForm"
                            type="button"
                            class="modalButton pull-right"
                            (click)="formValidate()"
                            style="margin-left: 10px"
                    >
                        {{ 'commons.continue' | translate }}
                    </button>
                    <button
                        type="button"
                        class="modalButton pull-right"
                        (click)="closeModal()"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
    <!-- is what follow still used??? -->
    <ng-container *ngIf="toggle == 2" [@fadeInOut]>
        <div class="modal-body">
            <div *ngIf="evaluationRequestResend" class="modal-body">
                <div>
                    <h3><b>{{ 'commons.sendByYourEmail' | translate}}</b></h3>
                    <p>{{ 'commons.sendByYourEmailInstructions_client_1' | translate }}
                        <span
                            style="font-size: 1.3em; color: red">{{ 'commons.sendByYourEmailInstructions_client_2' | translate }}</span>&nbsp;
                        <a style="font-size: 1.3em; color: red" href="mailto:{{ this.personEmail }}?subject={{ (currentLanguage === 'fr') ?
                            'Accès au questionnaire MPO' : 'Access to the MPO Questionnaire' }}&body={{ mailto }}"
                           class="bbuttonHover" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
                    <p class="mailtoWarning" style="color: darkred">
                        {{ 'commons.mailtoWarning' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="pull-right" style="margin-left: 15px">
                <button (click)="finishSending()" class="modalButton">{{ 'commons.finish' | translate }}</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </ng-container>
    <ng-container *ngIf="toggle == 3" [@fadeInOut]>
        <form [formGroup]="evaluationResendForm" (ngSubmit)="onSubmit(evaluationResendForm)"
              #requestResendForm="ngForm">
            <div *ngIf="evaluationRequestResend" class="modal-body">
                <h3><b>{{ 'commons.sendByNgenioServer' | translate }}</b></h3>
                <div class="form-group">
                    <div>
                        <div class="pull-left">
                            <span class="field_title">{{ 'commons.email' | translate }}</span>
                        </div>
                        <div class="pull-right">
                            <small *ngIf="blurred && (
                                        evaluationResendForm &&
                                        evaluationResendForm.controls &&
                                        evaluationResendForm.controls.emails &&
                                            (evaluationResendForm.controls.emailTo.dirty || evaluationResendForm.controls.emailTo.touched)
                                   )"
                                   class="text-danger"
                            >
                                <span *ngIf="evaluationResendForm.hasError('invalidEmail') || emptyEmail">
                                    {{ 'commons.invalidEmail' | translate }}
                                </span>
                            </small>
                        </div>
                        <div class="clearfix"></div>
                        <label style="width: 100%;">
                            <input type="text"
                                   *ngIf="(surveyRequestFormGroup && surveyRequestFormGroup.get('email').value); else noMail"
                                   (blur)="blurred = true"
                                   [(ngModel)]="mailHack"
                                   (keyup)="onFormChange(requestResendForm)"
                                   class="form-control" formControlName="emailTo">
                            <ng-template #noMail>
                                <input type="text"
                                       (blur)="blurred = true"
                                       (keyup)="onFormChange(requestResendForm)"
                                       [value]="this.personService.email"
                                       class="form-control" formControlName="emailTo">
                            </ng-template>
                        </label>
                    </div>
                    <div>
                        <label for="textToSendInput"><span
                            class="field_title">{{ 'commons.emailContent' | translate }}</span></label><br/>
                        <textarea
                            style="border: 1px solid #ccc; width: 100%; height: 317px"
                            [(ngModel)]="textToSend"
                            id="textToSendInput"
                            formControlName="message"
                        ></textarea>
                    </div>
                    <h3 style="margin-bottom: 15px; margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                    <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                    <p>{{ textToSend }}</p>
                    <h4>{{ 'people.requestModal.loginData' | translate }}</h4>

                    <div [innerHTML]="innerHtml"></div>
                </div>
            </div>
            <div class="modal-footer">
                <div style="margin-top: 10px">
                    <button type="submit" class="modalButton pull-right"
                            [disabled]="!evaluationResendForm.valid || emptyEmail">
                        {{ 'commons.send' | translate }}
                    </button>
                    <button *ngIf="false" (click)="closeModal()" type="button" class="modalButton pull-right"
                            style="margin-right: 15px"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
    </ng-container>
    <!-- Cut&Paste -->
    <ng-container *ngIf="toggle == 4" [@fadeInOut]>
        <div *ngIf="evaluationRequestResend" class="modal-body whiteBck">
            <h3><b>{{ 'people.requestModal.cutnpasteTitle' | translate }}</b></h3>
            <br>
            <div *ngIf="language !== 'ar'" [innerHTML]="innerHtml"></div>
            <div *ngIf="language === 'ar'" dir="rtl" [innerHTML]="innerHtml"
                 style="float: right"></div>
            <div *ngIf="language === 'ar'" class="clearfix"></div>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 15px">
                <div style="margin-top: 10px">
                    <button (click)="finishSending()" class="modalButton pull-right">
                        {{ 'commons.finish' | translate }}
                    </button>
                    <button *ngIf="false" (click)="closeModal()" type="button" class="modalButton pull-right"
                            style="margin-right: 15px"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </ng-container>
    <!-- Online -->
    <ng-container *ngIf="toggle == 5 && !raForm" [@fadeInOut]>
        <div *ngIf="evaluationRequestResend" class="modal-body">
            <p>{{ 'commons.enterSurveysOnline' | translate }} <a (click)="openEvaluationForm()">{{
                'commons.here' | translate }}</a>
            </p>
            <p>{{ 'commons.completeOnlineFill' | translate }}</p>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 15px">
                <div style="margin-top: 10px">
                    <button (click)="closeModal()" type="button" class="modalButton pull-right"
                            style="margin-right: 15px"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </ng-container>
</div>
