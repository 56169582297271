import {ApplicationRef, Injectable} from '@angular/core';
import {PeopleList} from './people_list.service';
import {StateService} from '../../core/services/state/state.service';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
// import {UserService} from '../../core/services/user/user.service';
// import {PersonService} from './person.service';
// import {TranslateService} from '@ngx-translate/core';
// import {ApiPeopleService} from './api-people.service';
// import {ApiJobsService} from '../../jobs/services/api-jobs.service';
// import {AlertService} from '../../shared/alert/services/alert.service';
// import {TestAndReportHandlingService} from './test-and-report-handling.service';
import * as _ from 'lodash';
import {IMyOptions} from 'mydatepicker';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ContentSearchFilters} from '../../shared/classes/services/content_search_filters';

@Injectable({
    providedIn: 'root'
})
export class PeopleSearchFiltersService extends ContentSearchFilters {

    public sortCardsBy = '';
    public sortCardsOrder = 'asc';

    public preferences: boolean;
    public filter: boolean;

    public datePickerOptions: IMyOptions = {dateFormat: 'dd/mm/yyyy'};
    public dateStart: any;
    public dateEnd: any;
    public dateStartModel = {};
    public dateEndModel = {};
    public hiringDateModel = {};

    public testsOptions: any;
    public optionsTestsModel: any = [];

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    public subAccountsOptions = [];
    public subAccountsOptionsModel: any = [];

    public departmentOptions = [];
    public departmentOptionsModel: any = [];

    public testStatuses: any = {};
    public optionsTestsStatusModel = [];
    public optionsTestsStatusRaModel = [];

    public testsStatusOptions: any;

    public checkedColumn: any = [];

    constructor(
        public stateService: StateService,
        protected router: Router,
        protected applicationRef: ApplicationRef,
        protected deviceService: DeviceDetectorService,
        protected peopleList: PeopleList
    ) {
        super(stateService, router, applicationRef, deviceService);
    }

    initTestStatusesSelect() {
        this.optionsTestsStatusModel = [];
        this.optionsTestsStatusRaModel = [];
        if (this.stateService.people.listSearchFilters.testStatuses) {
            if (this.stateService.people.listSearchFilters.testStatuses.mpo) {
                this.optionsTestsStatusModel = this.stateService.people.listSearchFilters.testStatuses.mpo;
            }
            if (this.stateService.people.listSearchFilters.testStatuses.ra) {
                this.optionsTestsStatusRaModel = this.stateService.people.listSearchFilters.testStatuses.ra;
            }
        }
    }

    testsStatus(event, obj, ra = false) {
        let res = (event.length >= 1) ? event.join(',') : '';
        let statusValues = res.split(',');
        let defaultValues = ['C', 'NC', 'H', 'N'];

        if (ra) {
            this.testStatuses['ra'] = [];
        } else {
            this.testStatuses['mpo'] = [];
        }

        defaultValues.forEach(status => {
            if (statusValues.find(s => s === status)) {
                if (ra) {
                    this.testStatuses.ra.push(status);
                } else {
                    this.testStatuses.mpo.push(status);
                }
            }
        });
        if (!res.length) {
            if (ra) {
                delete this.testStatuses.ra;
            } else {
                delete this.testStatuses.mpo;
            }
        }

        if (obj) {
            if (this.preferences) {
                let payload = {
                    module: 'people',
                    key: 'testStatuses',
                    value: this.testStatuses,
                    type: 'search'
                };
                obj.selectPreference.emit(payload);
            } else {
                this.setFilter('testStatuses', this.testStatuses);
            }
        }
    }

    initReportStatusesSelect() {
        this.optionsTestsModel = [];
        if (this.peopleList.statePeople.listSearchFilters.reportStatuses) {
            for (let report in this.peopleList.statePeople.listSearchFilters.reportStatuses) {
                if (report && this.peopleList.statePeople.listSearchFilters.reportStatuses.hasOwnProperty(report)) {
                    if (this.peopleList.statePeople.listSearchFilters.reportStatuses[report]) {
                        this.optionsTestsModel.push(report);
                    }
                }
            }
        }
    }

    initInputState(obj) {
        if (this.stateService.people.listSearchFilters) {
            // Rapports
            this.initTestStatusesSelect();

            // Status
            this.initReportStatusesSelect();

            // Sub-accounts
            if (this.stateService.people.listSearchFilters && this.stateService.people.listSearchFilters.subAccount) {
                let subAccArr = this.stateService.people.listSearchFilters.subAccount;
                if (typeof this.stateService.people.listSearchFilters.subAccount === 'string') {
                    subAccArr = subAccArr.split(',');
                }
                this.subAccountsOptionsModel = subAccArr
                    .map(function (item) {
                        return parseInt(item, 10);
                    });
            }
            // Departments
            this.setDepartmentsModel(this.peopleList.statePeople);

            let colSelected = 0;
            for (let col of this.columns) {
                if (this.filtering(this.listColumns, col)) {
                    colSelected++;
                }
                this.checkStatusMPO(col, obj);
            }
        }
    }

    initInputStatePreferences(obj) {
        // override these with user preferences
        if (obj && obj.userPreferences && obj.userPreferences.people) {
            // tslint:disable-next-line:forin
            for (let col in obj.userPreferences.people.display) {
                let index = 0;
                for (let o of this.listColumns) {
                    if (
                        obj.userPreferences.people.display.hasOwnProperty(col) &&
                        o.key === obj.userPreferencesService.plusToDot(col)) {
                        this.listColumns[index].display = obj.userPreferences.people.display[col];
                    } else {
                    }
                    index++;
                }
            }
        }

        if (this.stateService.people.listSearchFilters) {
            // Status
            this.initTestStatusesSelect();

            // Rapports
            this.initReportStatusesSelect();

            // Sub-accounts
            this.subAccountsOptionsModel = [];
            if (this.stateService.people.listSearchFilters.subAccount) {
                let subAccArr = this.stateService.people.listSearchFilters.subAccount;
                if (typeof this.stateService.people.listSearchFilters.subAccount === 'string') {
                    subAccArr = subAccArr.split(',');
                }
                this.subAccountsOptionsModel = subAccArr
                    .map(function (item) {
                        return parseInt(item, 10);
                    });
            }
            // Departments
            this.setDepartmentsModel(this.peopleList.statePeople);
            if (obj && obj.changeDetectorRef) {
                obj.changeDetectorRef.markForCheck();
            }
        }

        let colSelected = 0;
        for (let col of this.columns) {
            let pickedColumn = _.find(this.listColumns, function (o) {
                if (o) {
                    return o.key === col;
                }
            });
            if (pickedColumn && pickedColumn.display) {
                colSelected++;
            }
            this.checkStatusMPO(col, obj);
        }
    }

    checkStatusMPO(col, obj) {
        // check the MPO status checkbox if the user has the CAI permission
        if (col === 'reportStatuses.ra') {

            const statusCol =
                this.listColumns.find(cb => {
                    if (cb.key === 'status') {
                        cb.display = true;
                        return true;
                    }
                    return;
                });

            if (!statusCol) {
                this.listColumns.push(
                    {
                        display: true,
                        key: 'status',
                        title: obj.translate.instant('people.commons.mpoStatus')
                    }
                );
            }
        }
    }

    // TODO: remove mpo and dit references in the flow...
    initTestOptions(obj) {
        if (obj) {
            this.testsOptions = [];
            let testOpt;
            if (obj.stateService.hasPersonality) {
                obj.translate.get('commons.personality').subscribe((translated: string) => {
                    testOpt = this.testsOptions.filter(opt => opt.id === 'mpo');
                    if (testOpt.length === 0) {
                        this.testsOptions.push({id: 'mpo', name: translated});
                    }
                });
            }
            if (obj.stateService.hasCommunication) {
                obj.translate.get('commons.communication').subscribe((translated: string) => {
                    testOpt = this.testsOptions.filter(opt => opt.id === 'dit');
                    if (testOpt.length === 0) {
                        this.testsOptions.push({id: 'dit', name: translated});
                    }
                });
            }
            if (obj.stateService.hasTalents) {
                obj.translate.get('commons.talents').subscribe((translated: string) => {
                    testOpt = this.testsOptions.filter(opt => opt.id === 'talents');
                    if (testOpt.length === 0) {
                        this.testsOptions.push({id: 'talents', name: translated});
                    }
                });
            }
            if (obj.stateService.hasSatellite) {
                obj.translate.get('commons.satellite').subscribe((translated: string) => {
                    testOpt = this.testsOptions.filter(opt => opt.id === 'satellite');
                    if (testOpt.length === 0) {
                        this.testsOptions.push({id: 'satellite', name: translated});
                    }
                });
            }
            if (obj.stateService.hasIac) {
                obj.translate.get('commons.cai').subscribe((translated: string) => {
                    testOpt = this.testsOptions.filter(opt => opt.id === 'ra');
                    if (testOpt.length === 0) {
                        this.testsOptions.push({id: 'ra', name: translated});
                    }
                });
            }
        }
    }


    initTestsStatusOptions(obj) {
        if (obj) {
            this.testsStatusOptions = [];
            let testOpt;
            obj.translate.get('commons.onHold').subscribe((translated: string) => {
                testOpt = this.testsStatusOptions.filter(opt => opt.id === 'H');
                if (testOpt.length === 0) {
                    this.testsStatusOptions.push({id: 'H', name: translated});
                }
            });
            obj.translate.get('commons.completed').subscribe((translated: string) => {
                testOpt = this.testsStatusOptions.filter(opt => opt.id === 'C');
                if (testOpt.length === 0) {
                    this.testsStatusOptions.push({id: 'C', name: translated});
                }
            });
            obj.translate.get('people.commons.notCompleted').subscribe((translated: string) => {
                testOpt = this.testsStatusOptions.filter(opt => opt.id === 'NC');
                if (testOpt.length === 0) {
                    this.testsStatusOptions.push({id: 'NC', name: translated});
                }
            });
        }
    }

    tests(event, obj) {
        if (obj) {
            let res = (event.length >= 1) ? event.join(',') : '';
            let defaultValues = ['mpo', 'dit', 'prb', 'talents', 'satellite', 'ra'];
            let reportValues = res.split(',');
            let reportStatuses = {};
            defaultValues.forEach(report => {
                reportStatuses[report] = !!reportValues.find(r => r === report);
            });
            if (!res.length) {
                // when everything has been deselected in the report types selection
                // we have to put the false value for every report type in the payload to save
                defaultValues.forEach(report => {
                    reportStatuses[report] = false;
                });
            }

            if (this.preferences) {
                let payload = {
                    module: 'people', // we don't have to use this.actualModule.name here because it's based on the url value and returns 'admin' in the preferences case
                    key: 'reportStatuses',
                    value: reportStatuses,
                    type: 'search'
                };
                obj.selectPreference.emit(payload);
            } else {
                this.setFilter('reportStatuses', reportStatuses);
            }
        }
    }

    setListView(viewType) {
        if (typeof (viewType) !== 'undefined' &&
            viewType !== 'list' &&
            viewType !== 'cards'
        ) {
            throw 'Illegal view for setter listView';
        }

        if (viewType) {
            this.stateService.people.listView = viewType;
        }
    }

    toggleListView(obj, which = null) {
        const viewType = (which) ? which : (this.stateService.people.listView === 'list') ? 'cards' : 'list';
        if (viewType === 'cards') {
            this.sortCardsOrder = 'asc';
            this.sortCardsBy = '';
        }
        if (this.preferences) {
            obj.selectPreference.emit({
                module: 'people',
                key: null,
                value: viewType,
                type: 'listView'
            });
            this.setListView(viewType);
        } else {
            this.setListView(viewType);
        }
    }

    sortCards() {
        if (this.sortCardsOrder && this.sortCardsBy) {
            this.peopleList.setOrder({
                key: this.sortCardsBy,
                order: this.sortCardsOrder
            });
        }
    }

    get columns() {
        return this.peopleList.columns;
    }

    get listColumns() {
        return this.peopleList.listColumns;
    }


}
