<div *ngIf="surveyRequestFormGroup" [formGroup]="surveyRequestFormGroup">

    <h4 class="georgia">{{ 'commons.informations' | translate }}</h4>

    <!-- jobTitle -->
    <div>
        <label for="jobTitleField" class="field_title label-first">{{ 'commons.title' | translate }}</label>
        <input id="jobTitleField" type="text" class="form-control" formControlName="jobTitle" />
    </div>

    <!-- organisation -->
    <div>
        <label
            [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('organisation').errors"
            for="organisationField" class="field_title"
        >{{ 'commons.organisation' | translate }}</label>
        <input id="organisationField" type="text" class="form-control" formControlName="organisation" />
    </div>

    <!-- recordType -->
    <div *ngIf="recordTypeList">
        <div class="pull-left">
            <label for="recordTypeField" class="field_title"
                [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('recordType').errors"
            >{{ 'people.commons.status' | translate}}</label>
        </div>
        <select id="recordTypeField" class="form-control" formControlName="recordType">
            <option>{{ 'commons.none' | translate }}</option>
            <option *ngFor="let rcrdStat of recordTypeList" [value]="rcrdStat.value">
                {{rcrdStat.name}}
            </option>
        </select>
    </div>

    <!-- subAccount -->
    <div *ngIf="subAccountList">
        <div class="pull-left">
            <label for="subAccountField" class="field_title"
                [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('subAccount').errors"
            >{{ 'commons.subAccount' | translate }}</label>
        </div>
        <div class="clearfix"></div>
        <select id="subAccountField" class="form-control" formControlName="subAccount">
            <option value="">{{ 'commons.select' | translate }}</option>
            <option *ngFor="let subAcct of subAccountList" [value]="subAcct.id">
                {{subAcct.name}}
            </option>
        </select>
    </div>

    <!-- department -->
    <div *ngIf="departmentList">
        <div class="pull-left">
            <label for="departmentField" class="field_title">{{ 'commons.division' | translate }}</label>
        </div>
        <div class="clearfix"></div>
        <select id="departmentField" class="form-control" formControlName="department">
            <option>{{ 'commons.none' | translate }}</option>
            <option *ngFor="let dpt of departmentList" [value]="dpt.id">
                {{dpt['fr']}}
            </option>
        </select>
    </div>

    <!-- jobId -->
    <div>
        <label for="jobIdField" class="field_title">{{ 'people.requestModal.job' | translate }}</label>
        <select id="jobIdField" class="form-control" formControlName="jobId">
            <option>{{ 'commons.none' | translate }}</option>
            <option *ngFor="let job of jobs; trackBy: trackById" [value]="job.id">
                {{ getProperty(job, 'jobTitle') }}
            </option>
        </select>
    </div>

    <!-- requestedByName / requestedBy -->
    <div>
        <label for="requestedByNameField" class="field_title">{{ 'commons.requestedBy' | translate }}</label>
        <input id="requestedByNameField" class="form-control" type="text" formControlName="requestedByName" />
        <input type="hidden" formControlName="requestedBy" />
    </div>

    <div *ngIf="isSender">
        <label for="requester" class="field_title">{{ 'commons.requester' | translate }}
            <span *ngIf="!usersCount" class="noRequester"> - {{ 'people.commons.noAvailableRequester' | translate }}</span>
        </label>
        <select id="requester" class="form-control" formControlName="requester">
            <option>{{ 'commons.none' | translate }}</option>
            <option *ngFor="let user of users" [value]="getProperty(user, 'firstName') + ' ' + getProperty(user,'lastName')">
                {{getProperty(user, 'firstName') + ' ' + getProperty(user,'lastName')}}
            </option>
        </select>
    </div>

    <!-- sendingType -->
    <div *ngIf="!iacModal && !mpoModal">
        <div class="pull-left">
            <label for="sendingTypeField" class="field_title"
                [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('sendingType').errors"
            >{{ 'commons.sendingModeSelection' | translate }}</label>
        </div>
        <div class="clearfix"></div>
        <select id="sendingTypeField" class="form-control" formControlName="sendingType">
            <ng-container *ngFor="let sendingTypeItem of sendingTypes">
                <option [value]="sendingTypeItem.value">
                    {{ sendingTypeItem.title | translate }}
                </option>
            </ng-container>
        </select>
    </div>

    <!-- autoGen -->
    <div *ngIf="!iacModal && !mpoModal" style="margin-top: 10px">
        <div class="pull-left autoGenWrap">
            <mat-checkbox formControlName="autoGen"
            >{{ 'people.requestModal.autogenReport' | translate }}
            </mat-checkbox>
        </div>
        <div class="pull-left">
            <img alt="" [tooltip]="'people.requestModal.autogenTooltip' | translate"
                src="../../../../../../../assets/other_icons/question.png"
                style="padding-left: 10px; padding-top: 2px;"
            />
        </div>
        <div class="clearfix"></div>
    </div>
</div>
