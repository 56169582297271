<div class="modal-header">
    <h3 class="modal-title pull-left" *ngIf="status != 'copy' && status != 'edit'"
    >{{ 'jobs.modal.newJobTitle' | translate }}</h3>
    <h3 class="modal-title pull-left" *ngIf="status == 'copy'"
    >{{ 'jobs.modal.newJobFromExisting' | translate }}</h3>
    <h3 class="modal-title pull-left" *ngIf="status == 'edit'">{{ 'jobs.modal.editJob' | translate }}</h3>
    <div
        class="pull-left text-danger"
        style="margin-left: 15px; margin-top: 9.5px"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<div class="modal-body noBck">
    <form [formGroup]="jobForm" id="jobForm" #jobNgForm="ngForm" #jobFormContent>
        <mat-accordion class="headers-align" [multi]="false">
            <mat-expansion-panel [expanded]="section === 'jobDescription'" #jobDescriptionPanel="matExpansionPanel">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4 *ngIf="status != 'copy'" class="modal-title">{{ 'jobs.commons.jobDesign' | translate }}</h4>
                        <h4 *ngIf="status == 'copy'" class="modal-title">{{ 'jobs.modal.newJobFromExisting' | translate
                            }}</h4>
<!--                        <h4 *ngIf="status == 'edit'" class="modal-title">{{ 'jobs.modal.editJob' | translate }}</h4>-->
                    </mat-panel-title>
                    <mat-panel-description>
<!--                        <button mat-button-->
<!--                                (click)="resetSection($event, 'jobDescription')"-->
<!--                        >-->
<!--                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>-->
<!--                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>-->
<!--                        </button>-->
<!--                        | &nbsp;-->
<!--                        <span *ngIf="status == 'new'">{{ 'commons.add' | translate }}</span>-->
<!--                        <span *ngIf="status != 'new'">{{ 'commons.edit' | translate }}</span> &nbsp;-->
                        <!--mat-icon>insert_chart_outlined</mat-icon-->
                        <img src="assets/jobsModal/jobDescription-icon.png"/>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

                <div class="modal-body">
                    <button mat-button
                            class="rightCorner"
                            (click)="resetSection($event, 'jobDescription')"
                    >
                        <span>{{ 'commons.reset' | translate }}</span>
                    </button>
                    <div class="form-group">
                        <h4 style="margin-top: 10px">{{ 'commons.informations' | translate }}</h4>

                        <div class="pull-left" style="width: 49%">
                            <div>
                                <div class="pull-left"
                                     [class.text-danger]="validateFormField(jobForm.controls.jobTitle, jobNgForm, submitted)"
                                >
                                    <label for="jobTitleInput" class="field_title">
                                        {{ 'jobs.commons.jobName' | translate }}
                                    </label>
                                </div>
                                <div class="clearfix"></div>
                                <input type="text" id="jobTitleInput" class="form-control jobDescriptionField" formControlName="jobTitle">
                            </div>
                            <div>
                                <div class="pull-left"
                                     [class.text-danger]="validateFormField(jobForm.controls.subAccount, jobNgForm, submitted)"
                                >
                                    <label for="subAccountSelect" class="field_title">{{ 'commons.subAccount' | translate }}</label>
                                </div>
                                <div class="clearfix"></div>
                                <select
                                    class="form-control jobDescriptionField"
                                    id="subAccountSelect"
                                    formControlName="subAccount"
                                >
                                    <option value="">{{ 'commons.select' | translate }}</option>
                                    <option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">
                                        {{subAcct.name}}
                                    </option>
                                </select>
                            </div>
<!--                            <div>-->
<!--                                <div class="pull-left"-->
<!--                                     [class.text-danger]="validateFormField(jobForm.controls.language, jobNgForm, submitted)"-->
<!--                                >-->
<!--                                    <label for="languageSelect" class="field_title">{{ 'commons.language' | translate }}</label>-->
<!--                                </div>-->
<!--                                <div class="clearfix"></div>-->
<!--                                <select class="form-control jobDescriptionField" id="languageSelect" formControlName="language">-->
<!--                                    <option>{{ 'commons.select' | translate }}</option>-->
<!--                                    <option *ngFor="let lang of sessionStructure.languages[translate.currentLang]" [value]="lang.value">-->
<!--                                        {{lang.name}}-->
<!--                                    </option>-->
<!--                                </select>-->
<!--                            </div>-->
                            <div>
                                <div class="pull-left">
                                    <label for="locationInput" class="field_title">{{ 'commons.location' | translate }}</label>
                                </div>
                                <div class="clearfix"></div>
                                <input class="form-control jobDescriptionField" id="locationInput" formControlName="location"/>
                            </div>
                        </div>
                        <div class="pull-right" style="width: 49%">
                            <div *ngIf="departments">
                                <div class="pull-left">
                                    <label for="departmentSelect" class="field_title">{{ 'commons.division' | translate }}</label>
                                </div>
                                <div class="clearfix"></div>
                                <select class="form-control jobDescriptionField" id="departmentSelect" formControlName="department">
                                    <option value="">{{ 'commons.none' | translate }}</option>
                                    <option *ngFor="let dpt of departments" [value]="dpt.id">
                                        {{dpt[translate.currentLang]}}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <div class="pull-left">
                                    <label for="requiredByInput" class="field_title">
                                        {{ 'commons.requestedBy' | translate }}
                                    </label>
                                </div>
                                <div class="clearfix"></div>
                                <input type="text" class="form-control jobDescriptionField" id="requiredByInput" formControlName="requiredBy">
                            </div>
                            <div>
                                <div class="pull-left">
                                    <label for="reportsToInput" class="field_title">
                                        {{ 'commons.reportsTo' | translate }}
                                    </label>
                                </div>
                                <div class="clearfix"></div>
                                <input type="text" class="form-control jobDescriptionField" id="reportsToInput" formControlName="reportsTo">
                            </div>
<!--                            <div *ngIf="subAccounts" class="subAccountCol">-->
<!--                                <div class="pull-left">-->
<!--                                    <label for="sharedToSubaccounts" class="field_title">-->
<!--                                        {{ 'jobs.sidebar.shareJob' | translate }}-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                                <div class="clearfix"></div>-->
<!--                                <ng-multiselect-dropdown-->
<!--                                    [placeholder]="'commons.select' | translate"-->
<!--                                    [settings]="dropDownSettings"-->
<!--                                    [data]="subAccountsOptions"-->
<!--                                    (onSelect)="onSharedToSubAccountsChange()"-->
<!--                                    (onDeSelect)="onSharedToSubAccountsChange()"-->
<!--                                    (onDropDownClose)="onDropDownClose()"-->
<!--                                    id="sharedToSubaccounts"-->
<!--                                    formControlName="sharedToSubaccounts"-->
<!--                                >-->
<!--                                </ng-multiselect-dropdown>-->
<!--                            </div>-->
                        </div>
                        <div class="clearfix"></div>
                        <h4 style="margin-top: 10px"><label for="purposeOfThePositionTxt">{{ 'jobs.commons.purposeOfThePosition' | translate }}</label></h4>
                        <div style="margin-bottom: 18px">
                            <textarea class="form-control jobDescriptionField" id="purposeOfThePositionTxt" formControlName="purposeOfThePosition" style="height: 200px"></textarea>
                        </div>

                        <!--tasks-->
                        <h4>{{ 'jobs.commons.principalResponsibilities' | translate }}
                            <span class="pull-right">
                                <a (click)="addTask()" style="cursor: default; font-size: 12px;">
                                    {{ 'commons.add' | translate }}
                                </a>
                            </span>
                            <span class="clearfix"></span>
                        </h4>
                        <div formArrayName="tasks">
                            <div *ngFor="let task of getTasksControls(jobForm); let i=index"
                                 class="panel panel-default"
                                 [class.pull-left]="i%2 == 0"
                                 [class.pull-right]="i%2 > 0"
                            >
                                <div *ngIf="task" class="panel-heading">
                                    <div [formGroupName]="i.toString()">
                                        <div class="pull-left">{{ 'jobs.commons.responsibility' | translate }} {{i + 1}}</div>
                                        <span class="glyphicon glyphicon-remove pull-right"
                                              *ngIf="getTasksControls(jobForm).length > 1"
                                              (click)="removeTask(i)"></span>
                                        <div class="pull-right">
                                            <label for="priority{{i}}">{{ 'jobs.commons.priorityOrder' | translate
                                                }}</label>
                                            <input type="text" class="form-control inline-input jobDescriptionField" id="priority{{i}}" formControlName="priority" maxlength="3">
                                            &nbsp;&nbsp;&nbsp;
                                            <label for="percentage{{i}}">{{ 'jobs.commons.timePercentage' | translate
                                                }}</label>
                                            <input type="text" class="form-control inline-input jobDescriptionField" id="percentage{{i}}" formControlName="percentage" maxlength="3">
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div *ngIf="task" id="responsability" class="panel-body" [formGroupName]="i.toString()">
                                    <textarea type="text" class="form-control jobDescriptionField" id="responsibility{{i}}"
                                              formControlName="responsibility" style="height: 100px"></textarea>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="stateService.hasPersonality"
                                 [expanded]="section === 'personality'"
                                 (opened)="checkCompetencies(null, true)"
                                 (closed)="checkCompetencies(null, true)"
                                 #personalityPanel="matExpansionPanel"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4 class="modal-title">{{ 'jobs.commons.profileNoTarget' | translate }}</h4>
                    </mat-panel-title>
                    <mat-panel-description>
<!--                        <button mat-button-->
<!--                                (click)="goToQuestionnaireDef($event)"-->
<!--                        >-->
<!--                            <span>{{ 'commons.surveyDef' | translate }}</span>-->
<!--                        </button>|-->
<!--                        <button mat-button-->
<!--                                (click)="resetSection($event, 'personality')"-->
<!--                        >-->
<!--                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>-->
<!--                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>-->
<!--                        </button>-->
<!--                        | &nbsp;-->
<!--                        <span *ngIf="status != 'new' && mpoScores">-->
<!--                            {{ 'commons.edit' | translate }}-->
<!--                        </span>-->
<!--                        <span *ngIf="status == 'new' || !mpoScores">-->
<!--                            {{ 'commons.add' | translate }}-->
<!--                        </span> &nbsp;-->
                        <img src="assets/jobsModal/personality-icon.png">
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="modal-body">
                    <div class="rightCorner">
                        <a target="_blank" class="docLink" href="{{ profilerUrl }}/index.php?lang={{ translate.currentLang }}">
                            <span>{{ 'commons.surveyDef' | translate }}</span>
                        </a>&nbsp;&nbsp;|
                        <button mat-button
                                (click)="resetSection($event, 'personality')"
                        >
                            <span>{{ 'commons.reset' | translate }}</span>
                        </button>
                    </div>
                    <h4 class="field_title"><label for="jobProfile">{{ 'commons.typicalProfile' | translate }}</label></h4>
                    <div class="form-group">
                        <p>
                            <select
                                class="form-control personalityField"
                                id="jobProfile"
                                formControlName="selectedProfile"
                                style="width: 330px"
                                (change)="loadValues()"
                            >
                                <option value="">{{ 'commons.select' | translate }}</option>
                                <optgroup *ngFor="let group of profileListGroups" label="{{group.text}}">
                                    <option *ngFor="let text of group['opt']"
                                            [value]="text.value">
                                        &nbsp;&nbsp;&nbsp;
                                        {{text.text}}
                                        ({{text['score'].join(', ')}})
                                    </option>
                                </optgroup>
                            </select>
                        </p>
                        <h4 class="field_title"><label>Scores</label></h4>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerOR">OR</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerOR" class="form-control personalityField"
                                   formControlName="OR"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerSE">{{ 'commons.ES' | translate }}</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerSE" class="form-control personalityField"
                                   formControlName="SE"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerA">A</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerA" class="form-control personalityField"
                                   formControlName="A"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerE">E</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerE" class="form-control personalityField"
                                   formControlName="E"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerP">P</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerP" class="form-control personalityField"
                                   formControlName="P"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="pull-left scoreContainer">
                            <h4 class="field_title"><label for="profilerS">S</label></h4>
                            <input onfocus="this.select();" type="text" id="profilerS" class="form-control personalityField"
                                   formControlName="S"
                                   (keydown)="onChangePersonalityProfile()"
                            >
                        </div>
                        <div class="clearfix"></div>
                        <div *ngIf="stateService.hasSatellite && displayGraph" style="margin-top: 10px">
                            <mat-checkbox formControlName="isSatellite"
                                          class="personalityField"
                                          (change)="useWithSatellite($event)"
                                          id="useMpoSatellite"
                                          [disabled]="(displayGraph) ? null : 'disabled'"
                            >{{ 'jobs.sidebar.useWithSatellite' | translate }}</mat-checkbox>
                        </div>
                        <div class="graphWrap" *ngIf="displayGraph && cleanScore(); else emptyGraph">
                            <app-mpo-graph
                                [id]="'profiler-modal'"
                                [scores]="cleanScore()"
                                [lang]="translate.currentLang"
                            ></app-mpo-graph>
                        </div>
                        <ng-template #emptyGraph>
                            <div class="graphWrap">
                                <app-mpo-graph
                                    [id]="'profiler-modal'"
                                    [scores]="null"
                                    [lang]="translate.currentLang"
                                ></app-mpo-graph>
                            </div>
                        </ng-template>
                        <div *ngIf="showProfileWarning" class="profileWarning">
                            {{ 'jobs.modal.lowScoreWarning' | translate}}
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="stateService.hasTalents"
                [expanded]="section === 'talents'"
                #talentsPanel="matExpansionPanel"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4 class="modal-title">{{ 'jobs.modal.jobCompetenceProfile' | translate }}</h4>
                    </mat-panel-title>
                    <mat-panel-description>
<!--                        <button mat-button-->
<!--                                (click)="resetSection($event, 'talents')"-->
<!--                        >-->
<!--                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>-->
<!--                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>-->
<!--                        </button>-->
<!--                        | &nbsp;-->
<!--                        <span *ngIf="status != 'new' && hasTalentsScores">-->
<!--                            {{ 'commons.edit' | translate }}-->
<!--                        </span>-->
<!--                        <span *ngIf="status == 'new' || !hasTalentsScores">-->
<!--                            {{ 'commons.add' | translate }}-->
<!--                        </span> &nbsp;-->
                        <img src="assets/jobsModal/competences-icon.png">

                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="modal-body"  style="padding: 15px 15px 35px 15px;">
                    <button mat-button
                            class="rightCorner"
                            (click)="resetSection($event, 'talents')"
                    >
                        <span>{{ 'commons.reset' | translate }}</span>
                    </button>
                    <h4>{{ 'jobs.modal.instructions' | translate }}</h4>
                    <div style="margin-bottom: 12px; border-bottom: 1px solid #888;">
                        <ng-container>
                            <p [innerHTML]="'jobs.modal.instruction1' | translate"></p>
                            <div style="padding-bottom: 10px;">
                                <span class="redBlock">&nbsp;</span> &nbsp;{{ 'jobs.modal.lowScoreMarker' | translate}}
                            </div>
                            <div class="pull-left">
                                <a target="_blank" href="{{ apiBaseUrl }}/assets/dictionary/{{'jobs.modal.competenciesDictionaryFilename' | translate}}">
                                    {{ 'jobs.modal.competenciesDictionaryLinkTitle' | translate }}
                                </a>
                            </div>
                        </ng-container>
                        <div *ngIf="translate.currentLang === 'en'" class="pull-right">
                            <div *ngIf="talentsOrder === 'title'">
                                Sort:&nbsp;
                                <span class="bold">alphabetical</span> |
                                <a (click)="updateSorting('number')">numerical</a>
                            </div>
                            <div *ngIf="talentsOrder === 'number'">
                                Sort:&nbsp;
                                <a (click)="updateSorting('title')">alphabetical</a> |
                                <span class="bold">numerical</span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <ng-container *ngFor="let col of [0,1]">
                        <div
                            [class.pull-left]="col==0"
                            [class.pull-right]="col==1"
                            style="width:50%"
                        >
                            <table style="width: 98%">
                                <ng-container *ngFor="let talent of talentsData; let i=index; trackBy: trackByCompetencieId">
                                    <tr *ngIf="(i<23 && col==0) || (i>=23 && col==1)"
                                        [class.darkBg]="(i<23 && i%2===0) ||(i>=23 && i%2===1)"
                                    >
                                        <td
                                            [class.bold]="selectedTalents[talent.id]==1 || selectedTalents[talent.id]==2"
                                            [class.red]="isLowScoreTalent(talent['number'])"
                                            style="width: 340px"
                                        >
                                            <div class="pull-left">
                                                <div style="padding: 0 10px">

                                                    <app-talent-status
                                                        [selectedValue]="selectedTalents[talent.id]"
                                                        [talentNumber]="talent['number']"
                                                    ></app-talent-status>

                                                </div>
                                            </div>
                                            <div
                                                [tooltip]="talent.text"
                                                class="pull-left compLink"
                                            >
                                                {{ talent['fullTitle'] }}&nbsp;
                                            </div>

                                        </td>
                                        <td>
                                            <div class="dropdown" style="color: #888; cursor: pointer">
                                                <div class="dropbtn pull-right" (click)="toggleMastering(i)">
                                                    {{ dropDownValues(talent.id, 0)[0] }} <span class="caret"></span>
                                                </div>
                                                <div class="dropdown-content surfInPage"
                                                     [class.show]="subMenu[i]"
                                                >
                                                    <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 1)[1], i)">
                                                        {{ dropDownValues(talent.id, 1)[0] }}
                                                    </div>
                                                    <div (click)="updateTalent(talent.id, dropDownValues(talent.id, 2)[1], i)">
                                                        {{ dropDownValues(talent.id, 2)[0] }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </ng-container>
                    <div class="clearfix"></div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel
                *ngIf="stateService.hasIac"
                [expanded]="section === 'iac'"
                #iacPanel="matExpansionPanel"
            >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4 class="modal-title">{{ 'jobs.sidebar.quickRANoTarget' | translate }}</h4>
                    </mat-panel-title>
                    <mat-panel-description>
<!--                        <button mat-button-->
<!--                                (click)="resetSection($event, 'iac')"-->
<!--                        >-->
<!--                            <span *ngIf="status == 'new'">{{ 'commons.delete' | translate }}</span>-->
<!--                            <span *ngIf="status != 'new'">{{ 'commons.reset' | translate }}</span>-->
<!--                        </button>-->
<!--                        | &nbsp;-->
<!--                        <span *ngIf="status != 'new' && jobService.raScore">-->
<!--                            {{ 'commons.edit' | translate }}-->
<!--                        </span>-->
<!--                        <span *ngIf="status == 'new' || !jobService.raScore">-->
<!--                            {{ 'commons.add' | translate }}-->
<!--                        </span> &nbsp;-->
                            <img src="assets/jobsModal/iac-icon.png"/>


                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="modal-body">
                    <button mat-button
                            class="rightCorner"
                            (click)="resetSection($event, 'iac')"
                    >
                        <span>{{ 'commons.reset' | translate }}</span>
                    </button>
                    <div id="scoreBlock" style="margin-bottom: 5px">
                        <label for="RAScore">{{ 'jobs.sidebar.enterQuickRAScore' | translate }}</label>&nbsp;&nbsp;&nbsp;
                        <input
                            class="scoreContainer{{ RAScore > 9 ? ' error' : ''}}"
                            type="text"
                            id="RAScore"
                            formControlName="RAScore"
                            (keyup)="refreshGraph()"
                            (keydown)="checkPressedKey($event)"
                        />
                    </div>
                    <div id="graphBlock">
                        <img src="assets/graphicalElements/nCurve.png"  alt=""/>
                        <app-iac-graph
                            [id]="'modal_' + jobService.id"
                            [normalizedScore]="null"
                            [jobScore]="RAScore"
                            [newJob]="status === 'new'"
                        ></app-iac-graph>
                    </div>
                    <div  *ngIf="raTexts && RAScore" id="iacTextsBlock">
                        <h3>{{ 'commons.workEnvironment' | translate }}</h3>
                        <p>
                            {{ raTexts['env'][RAScore] }}
                        </p>
                        <h3>{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
                        <p>
                            {{ raTexts['job'][RAScore] }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
<div class="modal-footer">
    <div class="pull-right" style="margin-left: 10px">
        <button
            type="submit"
            class="modalButton pull-right"
            [disabled]="!jobForm.valid && jobNgForm.submitted"
            form="jobForm"
            (click)="onSubmit(jobForm)"
        >
            {{ 'commons.save' | translate }}
        </button>
        <button type="button" (click)="closeModal()" class="modalButton pull-right" style="margin-right: 10px">
            {{ 'commons.cancel' | translate }}
        </button>
    </div>
</div>
