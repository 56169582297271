import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit,
    OnDestroy
} from '@angular/core';

import {Graphboard} from '../../../models/graphboards.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {StateService} from '../../../../../core/services/state/state.service';
import {ApiGraphboardsService} from '../../../services/api-graphboards.service';
import {GraphBoardsAnalysisService} from '../../../services/graphboards-analysis.service';
import {Tools} from 'src/app/shared/barrels/misc';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-graphboards-modal',
    templateUrl: './graphboards-modal.component.html',
    styleUrls: ['./graphboards-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraphboardsModalComponent implements OnInit, OnDestroy {

    @Input() status = 'new';
    @Input() multiAction;

    @Output() editRefresh = new EventEmitter();
    public close: EventEmitter<any> = new EventEmitter();

    @ViewChild('graphboardNgForm') graphboardNgForm;

    graphboard: Graphboard;
    graphboardForm: FormGroup;
    requestedBy: string;
    sessionStructure: any = {};
    departments: any;
    headerText: string;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    submitted = false;

    private subscriptions = new Subscription();

    constructor(public bsModalRef: BsModalRef,
                public graphBoard: GraphBoardsAnalysisService,
                private changeDetectorRef: ChangeDetectorRef,
                private translate: TranslateService,
                private router: Router,
                private fb: FormBuilder,
                private stateService: StateService,
                private apiGraphboardsService: ApiGraphboardsService) {
    }

    get sessionData() {
        return this.stateService.session.sessionData;
    }

    ngOnInit() {
        this.requestedBy = this.sessionData.userData.username;
        this.sessionStructure = this.sessionData.structure;
        this.departments = this.sessionStructure.departments;

        if (this.status === 'new') {
            this.graphboard = Object.assign({}, new Graphboard());
        }

        if (this.status === 'edit') {
            this.graphboard = Object.assign({}, this.graphBoard.itemToDisplay);
        }

        if (this.multiAction) {
            if (this.multiAction.ids.length !== 0) {
                this.headerText = (this.multiAction.ids.length === 1) ?
                    'L\'item sélectionné précédemment sera ajouté à l\'analyse automatiquement' :
                    'Les items sélectionnés précédemment seront ajoutés à l\'analyse automatiquement';
                if (this.translate.currentLang !== 'fr') {
                    this.headerText = (this.multiAction.ids.length === 1) ?
                        'The previously selected item will be added to the analysis automatically' :
                        'The previously selected items will be added to the analysis automatically';
                }
            }
        }

        this.initForm();
    }

    closeModal(id = null) {
        if (this.multiAction === true) {
            this.router.navigate(['/people/list']).then(() => {});
            return;
        }
        this.resetForm();
        this.close.emit({closed: true, id: id});
        this.bsModalRef.hide();
    }

    formCheck(specificField = null) {
        if (this.graphboardNgForm && this.submitted) {
            if (specificField) {
                if (this.validateFormField(this.graphboardForm.controls[specificField], this.graphboardNgForm)) {
                    return true;
                }
            } else {
                let fields = ['name', 'subAccount'];
                for (let field of fields) {
                    if (this.validateFormField(this.graphboardForm.controls[field], this.graphboardNgForm)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    initForm() {
        this.submitted = false;
        const departmentObj = (this.graphboard.department && this.graphboard.department.length > 0)
            ? this.graphboard.department[0]
            : null;
        let departmentId;
        if (departmentObj) {
            departmentId = departmentObj.id ? departmentObj.id : departmentObj;
        }

        let subAccount = (this.sessionData.structure.principalSubAccount) ?
            this.sessionData.structure.principalSubAccount : '';
        if (this.status === 'edit') {
            subAccount = this.graphboard.subAccount;
        }

        this.graphboardForm = this.fb.group({
            name: [this.graphboard.name, [Validators.required, Validators.minLength(2)]],
            subAccount: [subAccount, [Validators.required]],
            description: [this.graphboard.description],
            department: [departmentId],
        });
    }

    onSubmit(model: FormGroup) {
        this.submitted = true;
        if (model.valid) {
            let data = model.value;

            // Department
            data.department = (data.department) ? [data.department] : [];

            if (this.status === 'edit') {
                this.saveEdit(data);
            } else {
                this.saveNew(data);
            }
        }
    }

    saveNew(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiGraphboardsService.postGraphboard(data).subscribe(
            res => {
                if (this.multiAction && this.multiAction.ids.length > 0) {
                    let alignment = [[], [], []];
                    let i = 0;
                    let type = (this.multiAction.idType === 'people') ? 'trait' : 'profiler';
                    for (let id of this.multiAction.ids) {
                        if (i === 3) {
                            i = 0;
                        }
                        alignment[i].push({
                            type: type,
                            record: id
                        });
                        i++;
                    }
                    // tslint:disable-next-line:no-shadowed-variable
                    const data = {'alignment': alignment};
                    this.subscriptions.add(this.apiGraphboardsService.putGraphboard([res.id], data).subscribe(
                        () => {
                            this.editRefresh.emit({id: res.id});
                            this.closeModal(res.id);
                        }
                    ));
                } else {
                    this.editRefresh.emit({id: res.id});
                    this.closeModal(res.id);

                }

            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEdit(data: any) {
        // call api to save the modification
        this.subscriptions.add(this.apiGraphboardsService.putGraphboard([this.graphboard.id], data).subscribe(
            () => {
                this.editRefresh.emit({id: this.graphboard.id});
                this.closeModal(this.graphboard.id);
            },
            error => {
                this.errorForm = error.error;
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.close.emit({closed: true});
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    resetForm() {
        this.delay(1000).then(() => {
            this.submitted = false;
            // this.errorForm = null;
            this.graphboardForm.reset();
            this.graphboardNgForm.submitted = false;
            this.initForm();
        });
    }
}
