<div [class.wrapper]="whereAmI!=='preferences'"
     [class.affix]="whereAmI!=='preferences'"
     [style.width]="width"
     [style.height]="(whereAmI!=='preferences') ? '100vh' : null"
>
    <div [class.topHead]="whereAmI!=='preferences'">
        <div *ngIf="jobSearchFilterService.filter" class="pull-left">
            <h2>
                {{ 'commons.displayManagement' | translate }}
            </h2>
        </div>
        <div *ngIf="jobSearchFilterService.filter" (click)="jobsList.goToPrec()" class="x-close pull-right" style="font-size: 20pt;">
            &#x00D7;
        </div>
        <div class="clearfix"></div>
        <div id="cardIcon">
            <div class="pull-left">
                <h3 class="fontLight" style="font-size: 12pt">{{ 'commons.display' | translate }}</h3>
            </div>
            <div class="pull-right">
                <span (click)="resetSearchFilters()" class="fakeLink">
                    {{ 'commons.reset' | translate }}
                </span>
            </div>
            <div class="clearfix"></div>
        </div>
        <div *ngIf="jobSearchFilterService.filter" class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 130px;" class="subTitle">{{ 'commons.creationDate' | translate }}</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <p class="flat">{{ 'commons.start' | translate }}</p>
                    <div style="width: 95%">
                        <div class="datePicker startdp">
                            <my-date-picker
                                    [locale]="translate.currentLang"
                                    [selDate]="jobSearchFilterService.dateStart"
                                    [options]="jobSearchFilterService.datePickerOptions"
                                    [(ngModel)]="jobSearchFilterService.dateStartModel"
                                    (dateChanged)="setFilters($event, 'jobStartDate')"
                                    required
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <p class="flat">{{ 'commons.end' | translate }}</p>
                        <div class="datePicker enddp">
                            <my-date-picker
                                    [locale]="translate.currentLang"
                                    [selDate]="jobSearchFilterService.dateEnd"
                                    [options]="jobSearchFilterService.datePickerOptions"
                                    [(ngModel)]="jobSearchFilterService.dateEndModel"
                                    (dateChanged)="setFilters($event, 'jobEndDate')"
                                    required>
                            </my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!--div class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 193px;" class="subTitle">Rapports et questionnaires</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <div style="width: 95%">
                        <select (change)="setSearchFilter('evaluationReport', $event.target.value)" class="form-control">
                            <option value="">Rapports</option>
                            <option
                                    value="mpo"
                                    [selected]="(stateService[specificState()].listSearchFilters.evaluationReport == 'mpo')"
                            >
                                MPO
                            </option>
                            <option
                                    value="dit"
                                    [selected]="(stateService[specificState()].listSearchFilters.evaluationReport == 'dit')"
                            >
                                DIT
                            </option>
                        </select>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <select (change)="setSearchFilter('evaluationType', $event.target.value)" class="form-control">
                            <option value="">Questionnaires</option>
                            <option
                                    value="mpo"
                                    [selected]="(stateService[specificState()].listSearchFilters.evaluationType == 'mpo')"
                            >
                                MPO
                            </option>
                            <option
                                    value="dit"
                                    [selected]="(stateService[specificState()].listSearchFilters.evaluationType == 'dit')"
                            >
                                DIT
                            </option>
                        </select>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div-->
        <div class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 76px;" class="subTitle">{{ 'commons.business' | translate }}</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <div class="subAccountCol">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.subAccount' | translate"
                            [settings]="jobSearchFilterService.dropDownSettings"
                            [data]="jobSearchFilterService.subAccountsOptions"
                            [(ngModel)]="jobSearchFilterService.subAccountsOptionsModel"
                            (ngModelChange)="setFilters($event, 'subAccount')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <!-- department -->
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right depCol">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.division' | translate"
                            [settings]="jobSearchFilterService.dropDownSettings"
                            [data]="jobSearchFilterService.departmentOptions"
                            [(ngModel)]="jobSearchFilterService.departmentOptionsModel"
                            (ngModelChange)="setFilters($event, 'department')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <!--div class="filterFamily" style="display: none">
            <div>
                <hr class="panelHr">
                <div style="width: 66px;" class="subTitle">Employé</div>
                <div class="pull-left" style="width: 50%">
                    <div style="width: 95%">
                        <p class="flat">Date d'embauche</p>
                        <div class="datePicker hiringdp">
                            <my-date-picker
                                    [locale]="'fr'"
                                    [options]="datePickerOptions"
                                    (dateChanged)="hiringDate($event)"
                                    required>
                            </my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%"></div>
                </div>
                <div class="clearfix"></div>
                <div class="pull-left" style="width: 50%">
                    <div style="width: 95%">
                        <select class="form-control">
                            <option>Titre</option>
                            <option></option>
                        </select>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <select class="form-control">
                            <option>Profil d'employé</option>
                            <option></option>
                        </select>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div-->
        <div *ngIf="jobsList.jobsState && jobsList.jobsState['listView'] == 'list'">
            <hr class="panelHr"/>
            <h4 style="margin-top: 15px">{{ 'commons.listsDisplayParameters' | translate }}</h4>
            <div id="columnsDisplay">
                <div style="font-size:90%; padding-bottom: 7px">
                    <!--span *ngIf="columnsToSelect > 0">
                        {{ 'commons.canSelectXColumns' | translate }} {{ columnsToSelect }} {{ 'commons.columns' | translate }}
                    </span>
                    <span *ngIf="columnsToSelect===0" style="color: #e45656">
                        {{ 'commons.maxColumnsSelected' | translate }}
                    </span-->
                </div>
                <div class="col-md-6"
                     style="margin: 0; padding: 0"
                     *ngFor="let col of jobsList.columns; let i = index">
                    <mat-checkbox #thisCb
                                  [checked]="jobSearchFilterService.filtering(jobsList.jobsState['listColumns'], col)"
                                  (change)="setFilters({ key: col, display: (thisCb.checked) }, 'columnDisplay')"
                                  [disabled]="i==0"
                    >{{ jobsList.columnsName[translate.currentLang][col] }}
                    </mat-checkbox>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
