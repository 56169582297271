<div class="rightWrapper row" (window:resize)="onResize($event)">
    <ng-container *ngIf="!small">
    <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4" [style.width]="(!small) ? sideWidth : null"
             style="overflow-y: auto; overflow-x: hidden">
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="jobService.id">
                    <app-jobs-sidebar
                            [isLeftAndNotTop]="true"
                            [reportType]="state.report['reportType']"
                            [width]="sideWidth"
                            [config]="'report'"
                    ></app-jobs-sidebar>
                </div>
            </div>
    </div>
    </ng-container>
    <div class="outerColumnWhite"
         [class.col-md-8]="!small"
         [class.col-md-12]="small"
         [style.padding-top]="(small) ? null : '40px'"
         [style.width]="(!small) ? reportWidth : null"
         id="scrollBody"
    >
        <div style="padding-right: 20px">
            <div id="MPOReport">
                <ul *ngIf="!small" class="nav nav-tabs" style="border-bottom: 1px solid #ddd;">
                    <li class="tab" [class.active]="state.report.display=='description' && state.report['reportType']=='personality'">
                        <a class="tdnone" (click)="switchMain('description', 'personality')">
                            {{ 'jobs.commons.jobDesign' | translate }}
                        </a>
                    </li>
                    <li class="tab" [class.active]="state.report.display=='report' && state.report['reportType']=='personality'">
                        <a class="tdnone" (click)="switchMain('report', 'personality')">
                            {{ 'jobs.commons.targetPersonalityProfile' | translate}}
                        </a>
                    </li>
                    <li *ngIf="!stateService.session.hasSatelliteRole" class="tab" [class.active]="state.report.display=='report' && state.report['reportType']=='talents'">
                        <a class="tdnone" (click)="switchMain('report', 'talents')">
                            {{ 'jobs.commons.targetCompetenciesProfile' | translate }}
                        </a>
                    </li>
                    <li *ngIf="raPermission" class="tab" [class.active]="state.report.display=='report' && state.report['reportType']=='ra'">
                        <a class="tdnone" (click)="switchMain('report', 'ra')">
                            {{ 'jobs.commons.targetCaiProfile' | translate }}
                        </a>
                    </li>
                </ul>
                <div *ngIf="small">
                    <app-jobs-sidebar
                            [isLeftAndNotTop]="false"
                            [reportType]="state.report['reportType']"
                            [width]="sideWidth"
                            [config]="'report'"
                    ></app-jobs-sidebar>
                </div>
                <br/>
                <div *ngIf="state.report.display=='description' && jobService.id && state.report['reportType'] == 'personality'">
                    <h2>{{ 'commons.job' | translate }}</h2>
                    <ul class="plainList">
                        <li *ngIf="jobService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ jobService.jobTitle }}</li>
                        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (jobService.creationDate || jobService.date) | date:dateFormat }}</li>

                    </ul>
                    <hr/>
                    <div *ngIf="!stateService.session.hasSatelliteRole"><a (click)="jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'jobDescription'}, 'report')">{{ 'jobs.report.modifyJobDescription' | translate }}</a></div>
                    <div *ngIf="tasksList || (jobService.jobDescription && jobService.purposeOfThePosition)" style="margin-top: 15px">
                        <div *ngIf="jobService.purposeOfThePosition">
                            <h4>{{ 'jobs.commons.purposeOfThePosition' | translate }}</h4>
                            <p>{{ jobService.purposeOfThePosition}}</p>
                        </div>
                        <table *ngIf="tasksList" id="princResp">
                            <thead>
                            <tr>
                                <th class="bold" colspan="2">{{ 'jobs.report.principalResponsibilities' | translate }}</th>
                                <th class="bold">{{ 'jobs.commons.timePercentage' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let resp of jobTasks">
                                <td class="bold" style="font-weight: bold; width: 2%">{{ resp.priority }}</td>
                                <td style=" padding-right: 15px; width: 88%">{{ resp['responsibility'] }}</td>
                                <td style="text-align: center; width: 10%">{{ resp['percentage'] }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="state.report.display=='report' && jobService.id && state.report['reportType'] == 'personality'">
                    <app-jobs-report-mpo
                        *ngIf="jobService.score && jobService.score.length > 0"
                        (openModQuickProfiler)="jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'personality'}, 'report')"
                    ></app-jobs-report-mpo>
                    <app-job-fake-report
                        *ngIf="!jobService.score || jobService.score.length === 0"
                        [for]="'mpo'"
                    ></app-job-fake-report>
                </div>
                <div *ngIf="state.report.display=='report' && jobService.id && state.report['reportType'] == 'talents'">
                    <app-jobs-report-talents
                        *ngIf="jobService.competences && jobService.competences.length > 0"
                        (openModTalent)="jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'talents'}, 'report')"
                    ></app-jobs-report-talents>
                    <app-job-fake-report
                        *ngIf="!jobService.competences || jobService.competences.length === 0"
                        [for]="'competences'"
                        ></app-job-fake-report>
                </div>
                <div *ngIf="raPermission && state.report.display=='report' && jobService.id && state.report['reportType'] == 'ra'">
                    <app-jobs-report-iac
                        *ngIf="jobService.raScore"
                        [currentLang]="translate.currentLang"
                        (openModRa)="jobsModalHandlingService.jobEditModal({'status': 'edit', 'section': 'iac'}, 'report')"
                    ></app-jobs-report-iac>
                    <app-job-fake-report
                        *ngIf="!jobService.raScore"
                        [for]="'ra'"
                    ></app-job-fake-report>
                </div>
            </div>
            <div style="clear:both"></div>
            <div *ngIf="small" style="height: 100px">&nbsp;</div>
        </div>
    </div>
</div>
