<div class="modal-header">
    <h4 class="modal-title pull-left fontLight">
        {{ 'commons.sharePdf' | translate }}
    </h4>
    <div
            (click)="closeModal()" aria-label="Close" class="x-close pull-right"
            style="font-size: 20pt;"
    >
        &#x00D7;
    </div>
</div>
<div *ngIf="share=='none'">
    <div class="modal-body">
        <!-- options -->
        <div class="insideCheckboxes">
            <h4>{{ 'commons.options' | translate }}</h4>
            <mat-checkbox id="traits"
                (change)="onChange($event)"
                [(ngModel)]="parts['traits']"
            >
                {{ 'commons.traits' | translate }}
            </mat-checkbox>
            <br />

            <mat-checkbox id="prb"
                (change)="onChange($event)"
                [(ngModel)]="parts['prb']"
            >
                {{ 'commons.perceptionOfRequiredBehavior' | translate }}
            </mat-checkbox>
            <br />
        </div>
        <div class="insideCheckboxes">
            <h5
                    [class.text-danger]="selectMethod"
            ><label for="sendingMethod">{{ 'commons.sendingMethod' | translate }}</label></h5>
            <select [(ngModel)]="method" id="sendingMethod" (change)="changeSelectMethodState()">
                <option>{{ 'commons.select' | translate }}</option>
                <option value="pdf">{{ 'commons.pdfReport' | translate }}</option>
                <option value="cutpaste">{{ 'commons.cutnpaste' | translate }}</option>
                <option value="mailto">{{ 'commons.sendByMyEmail' | translate }}</option>
                <option value="server">{{ 'commons.sendByServer' | translate }}</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="handleReport()">{{ 'commons.continue' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
<!-- share mode -->
<!-- server -->
<div *ngIf="share=='server'">
    <div class="modal-body">
        <h4>{{ 'commons.sendByNgenioServer' | translate }}</h4>

        <div>
            <h3><b>Message</b></h3>
            <div class="form-group">
                <div class="sendContent">
                    <table style="width: 700px">
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label for="reportEmailEmailTo">{{ 'commons.email' | translate }}</label></td>
                            <td><input
                                    id="reportEmailEmailTo"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail['emailTo']"/>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="font-size: smaller; padding-bottom: 7px;">{{ 'commons.emailsSepComma' | translate }}</td>
                        </tr>
                        <tr>
                            <td style="width: 75px; font-weight: bold"><label for="reportEmailSubject">{{ 'commons.emailSubject' | translate }}</label></td>
                            <td><input
                                    id="reportEmailSubject"
                                    type="text"
                                    style="width: 250px"
                                    [(ngModel)]="pdfEmail.subject"
                            />
                            </td>
                        </tr>
                    </table>
                    <table style="width: 700px; margin-top: 25px">
                        <tr>
                            <td style="padding-bottom: 7px; font-weight: bold">
                                <span class="field_title"><label for="reportEmailMessage">{{ 'commons.message' | translate }}</label></span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <textarea
                                        id="reportEmailMessage"
                                        rows="7"
                                        style="border: 1px solid #ccc; width: 565px; height: 100px"
                                        [(ngModel)]="pdfEmail.message"></textarea>
                            </td>
                        </tr>
                    </table>
                    <h3 style="margin-top: 20px">{{ 'people.requestModal.sentText' | translate}}</h3>
                    <div id="innerTextServer">
                        <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                        <p>{{ pdfEmail.message }}</p>
                        <h4>{{ 'people.requestModal.loginData' | translate }}</h4>
                        <p *ngIf="pdfReportUrl"
                           class="forceBreaks"
                           [innerHTML]="serverText"
                        ></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="sendEmailReport()">{{ 'commons.shareReport' | translate }}
            </button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- mailto -->
<div *ngIf="share=='mailto'">
    <div *ngIf="mailto" class="modal-body">
        <h4>{{ 'commons.sendByYourEmail' | translate }}</h4>
        <p>{{ 'commons.sendByYourEmailReportInstructions' | translate }}
            <a href="mailto:{{ mailto }}" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
<!-- cutnpaste-->
<div *ngIf="share=='cutpaste'">
    <div *ngIf="pdfReportUrl" class="modal-body">
        <h4>{{ 'commons.cutnpasteLink' | translate}}</h4>
        <p
                [translate]="'analysisCommons.linkInstruction'"
                [translateParams]="{
                    'analysisName': idwMatrix.name
                }"
        ></p>
        <p class="forceBreaks"><a target="_blank" href="{{ pdfReportUrl }}">{{ pdfReportUrl }}</a></p>
    </div>
    <div class="modal-footer">
        <div class="buttonSubmit pull-right">
            <button class="modalButton" (click)="closeModal()">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="buttonSubmit pull-right" style="margin-right: 15px">
            <button class="modalButton" (click)="cancel()">{{ 'commons.cancel' | translate }}</button>
        </div>
    </div>
</div>
