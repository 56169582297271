<div *ngIf="clientAccount.id || newSubAccount">
    <div class="rightWrapper row" (window:resize)="onResize($event)">
        <ng-container *ngIf="!clientAccount.smallSize && !newSubAccount">
            <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
            <div class="outerColumnGrey col-md-3" [style.width]="sideWidth">
                <div class="innerColumnGrey" [style.width]="sideWidth">
                    <app-administration-account-left-sidebar
                        [small]="false"
                        [accountId]="clientAccount.id"
                        [accountNumber]="clientAccount.accountNumber"
                        [subAccountId]="subAccountId"
                        [width]="sideWidth"
                        [accountType]="clientAccount.accountType"
                        [isUnlimited]="(clientAccount && clientAccount.subAccounts) && clientAccount.subAccounts[0]['credits'] == 'unlimited'"
                        (transferCreditsModal)="adminModalHandlingService.openTransferCreditsModal()"
                        (purchaseProductsModal)="adminModalHandlingService.openPurchaseProductsModal()"
                        (adjustmentCreditsModal)="adminModalHandlingService.openAdjustmentCreditsModal()"
                        (extendDemo)="activateExtendDemoWarning()"
                    ></app-administration-account-left-sidebar>
                    <!-- (editRefresh)="editRefresh()" -->
                </div>
            </div>
        </ng-container>
        <div class="outerColumnWhite"
             [class.col-md-9]="!clientAccount.smallSize && !newSubAccount"
             [class.col-md-12]="clientAccount.smallSize || newSubAccount"
             [style.margin-top]="(clientAccount.smallSize) ? '110px' : null"
             [style.width]="principalWidth"
        >
            <app-administration-account-left-sidebar
                *ngIf="clientAccount.smallSize && !newSubAccount"
                [small]="true"
                [accountId]="clientAccount.id"
                [accountNumber]="clientAccount.accountNumber"
                [subAccountId]="subAccountId"
                [width]="sideWidth"
                [accountType]="clientAccount.accountType"
                [isUnlimited]="(clientAccount && clientAccount.subAccounts) && clientAccount.subAccounts[0]['credits'] == 'unlimited'"
                (transferCreditsModal)="adminModalHandlingService.openTransferCreditsModal()"
                (purchaseProductsModal)="adminModalHandlingService.openPurchaseProductsModal()"
                (adjustmentCreditsModal)="adminModalHandlingService.openAdjustmentCreditsModal()"
                (extendDemo)="activateExtendDemoWarning()"
            ></app-administration-account-left-sidebar>
            <!-- (editRefresh)="editRefresh()" -->
            <a id="accountInfo"></a>
            <app-administration-account-form
            ></app-administration-account-form>
        </div>
        <div *ngIf="!newSubAccount" id="upButton" (click)="topFunction()">
            <img alt="^" src="assets/buttons/chevronUp.png"/>
        </div>
    </div>
</div>
