import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

import { ComponentList } from 'src/app/shared/classes/components/component_list';
import { StateService } from 'src/app/core/services/state/state.service';
import { IdwMatrixAnalysisService } from '../../../services/idw-matrix-analysis.service';
import { IdwMatrixListService } from '../../../services/idw-matrix-list.service';
import { ApiIdwMatricesService } from '../../../services/api-idw-matrices.service';
import { AlertService } from 'src/app/shared/alert/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { IdwMatrixModalHandlingService } from '../../../services/idw-matrix-modal-handling.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';
import {take} from 'rxjs/operators';

@Component({
    selector: 'app-idw-matrix-list',
    templateUrl: './idw-matrix-list.component.html',
    styleUrls: ['./idw-matrix-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdwMatrixListComponent extends ComponentList implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('gSidebar') gSidebar;

    displayConstant = {
        adjustment: {left: -5, right: -14},
        widthRatio: 2 / 3,
        leftColumnWidth: 440
    };
    action;
    // The list width (corrected)
    listWidth: any;
    sideWidth: any;

    private subscriptions = new Subscription();

    constructor(public router: Router,
                private route: ActivatedRoute,
                public idwMatrix: IdwMatrixAnalysisService,
                public idwMatricesList: IdwMatrixListService,
                public idwMatrixModalHandlingService: IdwMatrixModalHandlingService,
                public stateService: StateService,
                private apiIdwMatrices: ApiIdwMatricesService,
                private changeDetectorRef: ChangeDetectorRef,
                protected translateService: TranslateService,
                private alertService: AlertService,
                private modalService: BsModalService,
                protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translateService, router, deviceService);

        if (!this.stateService.hasCommunication) {
            if (this.stateService.hasPersonality) {
                this.router.navigate(['/analysis/rightmatch/list']).then(() => {});
            } else {
                this.router.navigate(['/people/list']).then(() => {});
            }
        }

        // set the sidebar status
        this.idwMatricesList._opened = !this.smallSize;

    }

    ngOnInit() {
        this.idwMatricesList.resetList();
        if (!this.idwMatricesList.archive) {
            this.setLastAddress();
        }
        // Close add to analysis modal
        this.subscriptions.add(this.modalService.onHidden.subscribe(() => {
            // Reset Multi-action
            this.idwMatricesList.idwMatrixState.resetMultiAction();
            this.idwMatricesList.multiAction = null;
        }));

        this.idwMatricesList.multiAction = null;
        this.idwMatricesList.checkModuleUrl();
        this.idwMatricesList.checkUserIdwMatrixDisplayPreferences();

        // check items list without selection
        this.idwMatricesList.checkTotalItems();

        if (this.idwMatrix.id) {
            this.idwMatrix.setItemToDisplayWithId(this.idwMatrix.id, this.idwMatricesList.specificState);
        }

        this.idwMatricesList.idwMatrixState.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        this.idwMatricesList.getIdwMatricesList( true);

        this.subscriptions.add(this.translateService.onLangChange.subscribe(
            () => {
                this.idwMatricesList.getIdwMatricesList( true, null, this);
            }
        ));

        this.computeListWidth(window);
    }

    ngAfterViewInit() {
        if (this.stateService.idwMatrix.multiAction.ids !== []) {
            this.idwMatricesList.multiAction = this.stateService.idwMatrix.multiAction;
            this.stateService.idwMatrix.resetMultiAction();

            if (this.idwMatricesList.multiAction['action'] === 'add') {
                // Open modal to add selected users to an existing analysis
                if (this.idwMatricesList.multiAction['data']) {
                    this.idwMatrixModalHandlingService.openIdwMatrixPeopleToAnalysisModal();
                }
            } else if (this.idwMatricesList.multiAction['action'] === 'create') {
                this.openIdwMatrixModal({status: 'new'});
            }
        }
    }

    refreshList() {
        this.idwMatricesList.getIdwMatricesList( true);
    }

    onSidebarStatusChange(opened) {
        if (this.gSidebar && !opened && !this.closed) {
            this.idwMatricesList._opened = true;
            this.gSidebar.open();
        }
    }

    openIdwMatrixModal(config) {
        this.idwMatrixModalHandlingService.openIdwMatrixModal({'status': config.status});
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);

        this.idwMatricesList._opened = !this.smallSize;
        this.closed = this.smallSize;
    }

    computeListWidth(window: Window): void {
        let innerWidth = window.innerWidth;
        if (window.innerWidth < 1235) {
            this.listWidth = innerWidth - this.displayConstant.leftColumnWidth + this.displayConstant.adjustment.left + 'px';
            this.sideWidth = '400px';
        } else {
            this.sideWidth = (innerWidth - this.displayConstant.leftColumnWidth) *
                (1 - this.displayConstant.widthRatio) + this.displayConstant.adjustment.right;
            if (this.sideWidth < 400) {
                this.sideWidth = 400;
            }
            // @ilya 2020-10-16 - fix the side width to 400px
            this.sideWidth = 400;
            this.listWidth = innerWidth - (this.sideWidth * 1.25) + 'px';
            this.sideWidth = this.sideWidth + 'px';
        }
    }

    /**
     * Order list rows on event from shared/main-list/component/main-list
     * @param event
     */
    setOrder(event) {
        this.idwMatricesList.setOrder(event);
        this.refreshList();
    }

    itemSelected(event) {
        this.closed = false;
        this.idwMatricesList.idwMatrixState.lastSideBarStatus = 'reports';
        this.idwMatricesList.itemSelected(event);
    }

    setSearchFilter(event) {
        this.idwMatricesList.setSearchFilter(event);
        this.refreshList();
    }

    actOnRecords(event) {
        const total = event.ids.length;
        let handled = 0;
        let plural = (total > 1) ? 's' : '';
        let alertText;
        this.action = event.actionType;
        if (this.action === 'delete') {
            let pAction = this.translateService.instant('commons.deletionAction');
            let paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                action: pAction,
                total: total,
                plural: plural
            });
            const deleteTxt = {
                paragraph: paragraph,
                texth1: 'Confirmation',
                position: 'center-center',
                template: 'warningConfirm'
            };
            this.alertService.setConfig(deleteTxt);
            this.alertService.nextAction$.subscribe(
                res => {
                    if (res === 'confirm' && this.action === 'delete') {
                        // Delete all selected ids
                        this.apiIdwMatrices.deleteDitGrams(event.ids).subscribe(
                            () => {
                                this.idwMatricesList.loading = true;
                                alertText = this.translateService.instant('analysis.commons.idwMatrixDeletion');
                                paragraph = this.translateService.instant('analysis.commons.idwMatrixDeleted', {
                                    total: total,
                                    plural: plural
                                });
                                const txtDelete = {
                                    paragraph: paragraph,
                                    texth1: alertText,
                                    position: 'top-right',
                                    type: 'green'
                                };
                                this.idwMatricesList.getIdwMatricesList( true, txtDelete, this);
                                this.idwMatricesList.revertToNews();
                                this.idwMatricesList.cleanMultiselectMode(true);
                            },
                            () => {
                                this.idwMatricesList.loading = true;
                                const txtDeleteError = {
                                    paragraph: this.translateService.instant('commons.tryAgain'),
                                    texth1: this.translateService.instant('commons.wasError'),
                                    position: 'center-center',
                                    type: 'red'
                                };
                                this.idwMatricesList.getIdwMatricesList( true, txtDeleteError, this);
                            }
                        );
                        this.action = undefined;
                    }
                }
            );

        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            let pAction;
            let paragraph;
            switch (this.action) {
                case 'archive':
                    putObject = {archive: true};
                    p[0] = this.translateService.instant('analysis.idwMatrix.multipleArchived', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleArchiving');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.archivingError');
                    break;
                case 'reactivate':
                    p[0] = this.translateService.instant('analysis.idwMatrix.multipleReactivated', {total: total, plural: plural});
                    h1[0] = this.translateService.instant('commons.multipleReactivation');
                    p[1] = this.translateService.instant('commons.tryAgain');
                    h1[1] = this.translateService.instant('commons.reactError');
                    putObject = {archive: false};
                    break;
            }
            if (this.action === 'archive') {
                pAction = this.translateService.instant('commons.archivingAction');
                paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtWarningArchive = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtWarningArchive);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'archive') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.idwMatricesList.revertToNews();
                            this.idwMatricesList.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action === 'reactivate') {
                pAction = this.translateService.instant('commons.reactAction');
                paragraph = this.translateService.instant('analysis.idwMatrix.actionConfirmation', {
                    action: pAction,
                    total: total,
                    plural: plural
                });
                const txtArchivingConfirmation = {
                    paragraph: paragraph,
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.action === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.idwMatricesList.revertToNews();
                            this.idwMatricesList.cleanMultiselectMode(true);
                            this.action = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.action) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }

        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.action) {
            let handledCounter = handled;
            for (let id of event.ids) {
                this.apiIdwMatrices.putDitGram([id], putObject).pipe(take(1)).subscribe(
                    () => {
                        handledCounter++;
                        if (handledCounter === total) {
                            this.idwMatricesList.loading = true;
                            this.idwMatricesList.getIdwMatricesList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            }, this);
                        }
                        this.idwMatricesList.cleanMultiselectMode(true);
                    },
                    () => {
                        this.idwMatricesList.loading = true;
                        this.idwMatricesList.getIdwMatricesList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        }, this);
                    }
                );
            }
            this.action = undefined;
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Close right sidebar tablet device
     */
    closeRightSidebar(closeRightSidebar: boolean): void {
        this.idwMatricesList.idwMatrixState.lastSideBarStatus = 'recentActivities';
        if (closeRightSidebar && this.idwMatricesList._opened && this.smallSize) {
            this.idwMatricesList._opened = false;
            this.closed = true;
        }
    }

    protected readonly parseInt = parseInt;
}
