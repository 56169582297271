import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {StateService} from '../../../core/services/state/state.service';
import {AdminService} from '../../../app/services/admin.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {UserPreferencesService} from '../../services/user-preferences.service';

import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {TalentsDefinitions} from '../../../shared/talents/talentsDefinitions';
import {FormBuilder} from '@angular/forms';
import {PeopleList} from '../../../people/services/people_list.service';

@Component({
    selector: 'app-administration-user-preferences',
    templateUrl: './administration-user-preferences.component.html',
    styleUrls: ['./administration-user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit, OnDestroy {

    listWidth: string;
    sideWidth: string;
    subComponentsWidth: any;

    moduleSelected: string;
    userData: any;
    accountData: any;
    accountDataId: string;
    userDataId: string;
    userPreferences: any;

    questions: any;

    loader: any = null;

    completedStatus;

    preference = 'individus';
    smallSize = false;
    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: FormBuilder,
        public peopleList: PeopleList,
        public stateService: StateService,
        private apiAdministration: ApiAdministrationService,
        private adminService: AdminService,
        private userPreferencesService: UserPreferencesService,
        public translateService: TranslateService
    ) {
        this.questions = new TalentsDefinitions();
    }

    ngOnInit() {
        this.moduleSelected = 'people';

        // Init vars
        this.initRouteVars();

        if (!this.isSender) {
            this.getAccountData();
        }

        this.getUser();

        this.computeListWidth(window);

        this.completedStatus = null;
    }

    onResize(event): void {
        // re-Set list width
        this.computeListWidth(event.target);
    }

    computeListWidth(window: Window): void {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 3 / 4;
        let leftColumnWidth = 100;
        let innerWidth = window.innerWidth;
        if (window.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.smallSize = window.innerWidth < 1235;
        this.listWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        this.subComponentsWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left - 60;
        if (this.smallSize) {
            this.subComponentsWidth = this.subComponentsWidth - 193;
        }
        this.subComponentsWidth = this.subComponentsWidth + 'px';
        this.sideWidth = (innerWidth - leftColumnWidth) * (1 - widthRatio) + adjustment.right + 'px';
    }

    initRouteVars() {
        const urlVars = this.route.snapshot.url;
        this.accountDataId = urlVars[2].path;
        this.userDataId = urlVars[4].path;
    }

    getAccountData() {
        this.subscriptions.add(this.apiAdministration.client([this.accountDataId]).subscribe(
            res => {
                this.accountData = res;
            }
        ));
    }

    getUser() {
        const url = 'admin/account/' + this.accountDataId + '/user/' + this.userDataId + '/preferences';

        this.subscriptions.add(this.apiAdministration.user([this.userDataId]).subscribe(
            res => {
                this.userData = res;
                this.userPreferencesService.setUserPreferences(this.userData.preferences, url);
            }
        ));
    }

    back() {
        const lastAddress = this.sessionData.lastAddress;
        this.sessionData.lastAddress = null;
        this.router.navigate([lastAddress]).then(() => {
        });
    }

    onSelectPreference(preference: any) {
        if (preference.type === 'selectVanilla') {
            this.loader = 'completed';
            return;
        }
        this.loader = 'loading';
        this.completedStatus = null;
        let payload = {};
        if (preference.key === null) {
            payload['listView'] = preference.value;
        } else {
            let payloadContent = {};
            // Admin
            if (preference.module === 'admin') {
                payloadContent[preference.key] = preference.value;
            } else {
                /**
                 * TODO: The IF-part of the branching below might be dead code. Verify and
                 *       take appropriate action
                 *
                 */
                if (Array.isArray(preference.key) || Array.isArray(preference.value)) {
                    if (preference.key.length === preference.value.length) {
                        for (let i in preference.key) {
                            if (preference.key.hasOwnProperty(i)) {
                                if (
                                    !payloadContent[this.userPreferencesService.dotToPlus(preference.key[i])] &&
                                    payloadContent[this.userPreferencesService.dotToPlus(preference.key[i])] !== preference.value[i]) {
                                    payloadContent[this.userPreferencesService.dotToPlus(preference.key[i])] = preference.value[i];
                                }
                            }
                        }
                    } else {
                        throw 'Discrepency of type OR of length between preference.key and preference.value';
                    }
                } else {
                    payloadContent[this.userPreferencesService.dotToPlus(preference.key)] = preference.value;
                    payloadContent['evaluationDisable'] = null;
                    payloadContent['evaluationRequestExist'] = null;
                    payloadContent['evaluationExists'] = null;
                }

                // clean payloadContent
                if (typeof payloadContent === 'object') {
                    // noinspection JSDeprecatedSymbols
                    _.compact(payloadContent);
                }
            }

            payload = {
                [preference.type]: payloadContent
            };
        }

        // process data for API
        let preferenceData = {
            [preference.module]: payload
        };

        // Save data (PUT)

        this.subscriptions.add(this.apiAdministration.putUser([this.userDataId], {preferences: preferenceData}).subscribe(
            () => {
                // Reload data session
                this.subscriptions.add(this.adminService.getSession(this.translateService.currentLang).subscribe(
                    sessionData => {
                        this.stateService.sessionData = sessionData;
                        this.accountDataId = this.stateService.session.sessionData.accountData.id;
                        this.userDataId = this.stateService.session.sessionData.userData.id;

                        const preferenceUrl = 'admin/account/' + this.accountDataId + '/user/' + this.userDataId + '/preferences';
                        // Inject info in state
                        this.userPreferencesService.setUserPreferences(this.userPreferences, preferenceUrl);
                        if (preference.module === 'people') {
                            this.peopleList.checkUserPeopleDisplayPreferences();
                        }
                        this.userPreferencesService.initUserPreferences();
                        this.loader = 'completed';
                        setTimeout(() => {
                            this.loader = null;
                            this.changeDetectorRef.markForCheck();
                        }, 2000);
                        this.changeDetectorRef.markForCheck();
                    }
                ));
                this.completedStatus = 'success';
            },
            () => {
                // console.log(error.error);
                this.loader = 'completed';
                setTimeout(() => {
                    this.loader = null;
                    this.changeDetectorRef.markForCheck();
                }, 2000);
                this.completedStatus = 'failure';
            }
        ));
    }

    moveTo(fragment) {
        window.location.hash = '';
        window.location.hash = fragment;
    }

    get sessionData() {
        if (this.stateService.session) {
            return this.stateService.session.sessionData;
        }
        return null;
    }

    get isAdmin() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isAdmin;
        }
        return false;
    }

    get isSender() {
        if (this.stateService && this.stateService.session) {
            return this.stateService.session.isSender;
        }
        return false;
    }

    switchPreference(preference) {
        this.loader = null;
        this.preference = preference;
    }

    ngOnDestroy() {
        this.subscriptions.add();
    }
}
