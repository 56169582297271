<div>
    <h2 style="margin-top: 15px; font-family: MontserratSemiBold,  sans-serif; font-size: 22px;">{{ 'commons.job' | translate }}</h2>
    <ul class="plainList">
        <li *ngIf="rightMatch.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ rightMatch.jobTitle }}</li>
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ rightMatch.jobDescription.date | date:dateFormat }}</li>
    </ul>
    <hr/>
    <div *ngIf="rightMatch.jobDescription && (rightMatch.jobDescription.tasks.length !== 0 || rightMatch.jobDescription.purposeOfThePosition)" style="margin-top: 15px">
        <div *ngIf="rightMatch.jobDescription.purposeOfThePosition">
            <h4>{{ 'jobs.commons.purposeOfThePosition' | translate }}</h4>
            <p>{{ rightMatch.jobDescription.purposeOfThePosition}}</p>
        </div>
        <table *ngIf="rightMatch.jobDescription && rightMatch.jobDescription.tasks.length !== 0" id="princResp">
            <thead>
            <tr>
                <th class="bold" colspan="2">{{ 'jobs.report.principalResponsibilities' | translate }}</th>
                <th class="bold">{{ 'jobs.commons.timePercentage' | translate }}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let resp of rightMatch.jobDescription.tasks">
                <td class="bold" style="font-weight: bold; width: 2%">{{ resp.priority }}</td>
                <td style=" padding-right: 15px; width: 88%">{{ resp['responsibility'] }}</td>
                <td style="text-align: center; width: 10%">{{ resp['percentage'] }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
