<div *ngIf="person">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ 'people.commons.modIndividual' | translate }}</h4>

        <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
             style="font-size: 20pt;">
            &#x00D7;
        </div>
    </div>
    <div class="modal-body">

        <form [formGroup]="peopleEditForm" (ngSubmit)="onSubmit(peopleEditForm)" #personEditForm="ngForm">
            <div class="form-group">

                <div>
                    <div class="pull-left">
                        <span class="field_title">
                            {{ 'commons.firstName' | translate }}
                        </span>
                    </div>
                    <div class="pull-right">
                        <small *ngIf="blurred && peopleEditForm.controls.firstName.errors &&
                                (peopleEditForm.controls.firstName.dirty ||
                                peopleEditForm.controls.firstName.touched)"
                               class="text-danger"
                        >
                            <span [hidden]="!peopleEditForm.controls.firstName.errors['minlength']">
                                {{ 'forms.warning.warnMin2Chars' | translate }}
                            </span>
                            <span [hidden]="!peopleEditForm.controls.firstName.errors['required']">
                                {{ 'commons.firstNameMandatory' | translate }}
                            </span>
                        </small>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <input type="text"
                               (blur)="blurred = true"
                               (keyup)="onFormChange(personEditForm)"
                               class="form-control"
                               formControlName="firstName">
                    </label>
                </div>

                <div>
                    <div class="pull-left">
                        <span class="field_title">
                            {{ 'commons.lastName' | translate }}
                        </span>
                    </div>
                    <div class="pull-right">
                        <small *ngIf="blurred && peopleEditForm.controls.lastName.errors &&
                                (peopleEditForm.controls.lastName.dirty ||
                                peopleEditForm.controls.lastName.touched)"
                               class="text-danger"
                        >
                            <span [hidden]="!peopleEditForm.controls.lastName.errors['minlength']">
                                {{ 'forms.warning.warnMin2Chars' | translate }}
                            </span>
                            <span [hidden]="!peopleEditForm.controls.lastName.errors['required']">
                                {{ 'commons.lastNameMandatory' | translate }}
                            </span>
                        </small>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <input type="text"
                               (blur)="blurred = true"
                               (keyup)="onFormChange(personEditForm)"
                               class="form-control"
                               formControlName="lastName">
                    </label>
                </div>

                <div>
                    <div class="pull-left">
                            <span class="field_title">
                                {{ "commons.email" | translate }}
                            </span>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <input type="text" class="form-control" formControlName="email">
                    </label>
                </div>

                <div *ngIf="recordStatus">
                    <div class="pull-left">
                        <label class="field_title">{{ 'people.commons.status' | translate }}</label>
                    </div>
                    <div class="pull-right">
                        <small *ngIf="peopleEditForm.controls.recordType.errors &&
                              (peopleEditForm.controls.recordType.dirty ||
                              peopleEditForm.controls.recordType.touched)"
                               class="text-danger"
                        >
                            {{ 'people.commons.statusMandatory' | translate }}
                        </small>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <select class="form-control" formControlName="recordType">
                            <option>{{ 'commons.none' | translate }}</option>
                            <option *ngFor="let status of recordStatus" [value]="status.value">
                                {{ 'people.commons.' + status.value | translate }}
                            </option>
                        </select>
                    </label>
                </div>

                <div>
                    <span class="field_title">{{ 'people.commons.title' | translate }} </span><br/>
                    <label style="width: 100%;">
                        <input type="text" class="form-control" formControlName="jobTitle">
                    </label>
                </div>

                <!--div>
                    <span class="field_title">{{ 'people.commons.jobType' | translate }} </span><br/>

                </div-->
                <input type="hidden" class="form-control" formControlName="jobType" value="">

                <div>
                    <span class="field_title">{{ 'people.requestModal.job' | translate }}</span><br/>
                    <label style="width: 100%;">
                        <select class="form-control" formControlName="jobId">
                            <option>{{ 'commons.none' | translate }}</option>
                            <option *ngFor="let job of jobProfiles"
                                    [value]="job.id"
                            >
                                {{ job['jobTitle'] }}
                            </option>
                        </select>
                    </label>
                </div>
                <div>
                    <span class="field_title">{{ 'people.commons.organisation' | translate }} </span><br/>
                    <label style="width: 100%;">
                        <input type="text" class="form-control" formControlName="organisation">
                    </label>
                </div>
                <div>
                    <span class="field_title">{{ 'commons.subAccount' | translate }} </span><br/>
                    <label style="width: 100%;">
                        <select
                            *ngIf="sessionStructure && person"
                            class="form-control"
                            formControlName="subAccount">
                            <option>{{ 'commons.select' | translate }}</option>
                            <option
                                *ngFor="let subAcct of subAccounts"
                                [value]="subAcct.id"
                            >
                                {{subAcct.name}}
                            </option>
                        </select>
                    </label>
                </div>
                <div *ngIf="departments">
                    <div class="pull-left">
                        <label class="field_title">{{ 'commons.division' | translate }}</label>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <select class="form-control" formControlName="department">
                            <option>{{ 'commons.none' | translate }}</option>
                            <option *ngFor="let dpt of departments" [value]="dpt.id">
                                {{dpt['fr']}}
                            </option>
                        </select>
                    </label>
                </div>

                <br/>

                <div>
                    <button type="submit" class="modalButton pull-right"
                            [disabled]="!peopleEditForm.valid || emptyName">
                        {{ 'commons.save' | translate }}
                    </button>
                    <button (click)="closeModal()" type="button" class="modalButton pull-right"
                            style="margin-right: 15px"
                    >
                        {{ 'commons.cancel' | translate }}
                    </button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
    </div>
</div>
