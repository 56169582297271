<div *ngIf="!addAnalysis">
    <div class="sticky0">
        <app-mobile-header
                [addAnalysis]="addAnalysis"
                [searchValue]="lastSearch"
                (reloadAction)="refreshList()"
                (searchAction)="setSearchFilter($event)"
        ></app-mobile-header>
    </div>
    <span class="searchResults">
        <span *ngIf="graphBoardsList.selectionDone && graphBoardsList.totalListItems !== null">{{ graphBoardsList.totalListItems }}
            {{ graphBoardsList.resMention | translate }}
            <span *ngIf="graphBoardsList.totalItems"  style="font-weight: bold">
                {{ 'commons.of' | translate }} {{ graphBoardsList.totalItems }}.
            </span>
        </span>
    </span>
    <h1 class="sectionTitle pb0">
        <span *ngIf="!graphBoardsList.archive">{{ 'app.navigation.analysis' | translate }}</span>
        <span *ngIf="graphBoardsList.archive">{{ 'commons.archives' | translate }}</span>
    </h1>
    <mat-tab-group
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="switchTab($event)"
    >
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.rightMatch' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasCommunication" label="{{ 'commons.idwMatrix' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.graphboard' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.jobSatisfaction' | translate }}"></mat-tab>
    </mat-tab-group>
    <div infiniteScroll
         [infiniteScrollDistance]="4"
         [infiniteScrollThrottle]="300"
         [scrollWindow]="false"
         (scrolled)="graphBoardsList.listScrolled()"
         class="scrollableList"
    >
        <ngx-loading
            [show]="graphBoardsList.loading"
            [config]="loaderDisplay"
        ></ngx-loading>
        <app-mobile-main-list *ngIf="graphBoardsList.toList"
                              [selectedItemId]="graphBoard.id"
                              [tableDefinition]="graphBoardsList.tableDefinition"
                              [sectionName]="'analysis'"
                              [subSection] = "'graphboard'"
                              [listsData]="graphBoardsList.toList"
                              (listScrolled)="graphBoardsList.listScrolled()"
                              (selectedItem)="itemSelected($event);"
                              (reordering)="reorderingListsRows($event)"
                              [orderedBy]="orderedBy"
        ></app-mobile-main-list>
    </div>
</div>
<button mat-fab *ngIf="!addAnalysis"
        class="newItem-btn"
        (click)="createNewAnalysis()"
><i class="material-icons">add</i></button>
<div *ngIf="addAnalysis">
    <app-mobile-graphboards-edit
        [status]="'new'"
        (close)="backToItemsList()"
    ></app-mobile-graphboards-edit>
</div>
