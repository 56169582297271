<div>
    <div
        [class.top]="!small"
        [class.affix]="!small"
        [class.tLeft]="!small"
        [class.topMenu]="small"
        [class.navbar-fixed-top]="small"
        [style.width]="(!small) ? width : 'auto'"
    >
        <div [class.h35]="permissionsAccountType !== 'client' && permissionsAccountType !== 'demo'"
             [class.h55]="permissionsAccountType === 'client' || permissionsAccountType === 'demo'"
             [class.pull-left]="!!(small)"
        >
            <div>
                <app-nav-button *ngIf="permissionsAccountType !== 'client' && permissionsAccountType !== 'demo'"
                                (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <div class="clearfix"></div>
            </div>
        </div>
        <ng-container *ngIf="!small">
            <div *ngIf="permissionsAccountType != 'client' &&
                        permissionsAccountType != 'demo'">
                <hr class="panelHr"/>
            </div>
            <div style="height: 5px;"></div>
        </ng-container>
        <div [class.pull-right]="!!(small)" *ngIf="actionAvailable()">
            <div>
                <h3 *ngIf="!small"><label for="actionSelect">Options</label></h3>
                <select [(ngModel)]="action" id="actionSelect" name="" class="form-control pull-left"
                        style="width: 85%">
                    <option></option>
                    <option *ngIf="canSwitch()"
                            value="connect">{{ 'admin.adLeftSidebar.connectToClientAccount' | translate }}</option>
                    <option *ngIf="canBuyProducts()"
                            value="buyProducts">{{ 'admin.adLeftSidebar.purchases' | translate }}</option>
                    <option *ngIf="canAdjust()&& !isUnlimited"
                            value="ajustCredits">{{ 'admin.adLeftSidebar.creditsAdjustment' | translate }}</option>
                    <option *ngIf="canDoTransaction() && !isUnlimited"
                            value="transferCredits">{{ 'admin.adLeftSidebar.transferCredits' | translate }}</option>
                    <option
                        *ngIf="canExtendDemo()"
                        value="extendDemo"
                    >{{ 'admin.adLeftSidebar.demoDuration' | translate }}</option>
                    <option *ngIf="canSwitch()"
                            value="report"
                    >
                        {{ 'admin.adLeftSidebar.usageReport' | translate }}
                    </option>
                    <!-- option *ngIf="canArchive()" value="archive">{{ 'commons.archive' | translate }}</option-->

                </select>
                <button (click)="act()" class="modalButton pull-right" style="width: 13%; padding: 7px 0 6px 0">
                    <span class="fontRegular" style="font-size: 125%;">
                        ⟩
                    </span>
                </button>
                <div class="clearfix"></div>
                <!--h3>Actions</h3>
                <p *ngIf="canDoTransaction()"><a (click)="activateTransferCreditsModal()">Transférer des crédits</a></p>
                <p *ngIf="canBuyProducts()"><a (click)="activatePurchaseProductsModal()">Achat de produits</a></p>
                <p *ngIf="canAdjust()"><a (click)="activateAdjustmentCreditsModal()">Ajustement de crédits</a></p>
                <p *ngIf="canSwitch()"><a (click)="switchDatabase()">Se connecter en tant que ce client</a></p-->
            </div>
            <div style="height: 5px;">&nbsp;</div>
            <div *ngIf="!small">
                <hr class="panelHr"/>
            </div>
        </div>
        <ng-container *ngIf="!small">
            <div style="height: 5px;">&nbsp;</div>
            <h3 style="font-size: 20px">{{ 'admin.commons.fastLinks' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('accountInfo')">{{ 'admin.commons.accountInfo' | translate }}</h3>
            <h3 class="h3link"
                (click)="moveTo('address')">{{ 'admin.adLeftSidebar.adressInformation' | translate }}</h3>
            <h3 class="h3link" (click)="moveTo('products')">{{ 'admin.adLeftSidebar.mpoProducs' | translate }}</h3>
            <h3 class="h3link pull-left" (click)="moveTo('users')">{{ 'admin.adLeftSidebar.users' | translate }}</h3>
            <div class="numberBox pull-left">
                {{ users['count'] || '0' }}
            </div>
            <div class="clearfix"></div>
            <h3 class="h3link pull-left" (click)="moveTo('subaccounts')">{{ 'commons.subAccounts' | translate }}</h3>
            <div class="numberBox pull-left">
                {{ subAccounts['length'] }}
            </div>
            <div class="clearfix"></div>
            <h3 class="h3link pull-left" (click)="moveTo('files')">{{ 'admin.commons.files' | translate }}</h3>
            <div class="numberBox pull-left">
                {{ assetsFiles }}
            </div>
            <div class="clearfix"></div>
            <h3 class="h3link" (click)="moveTo('transactions')">{{ 'commons.transactionType' | translate }}</h3>
            <h3 class="h3link pull-left" (click)="moveTo('departments')">{{ 'commons.division' | translate }}</h3>
            <div class="numberBox pull-left">
                {{  clientDepartments['count'] || '0' }}
            </div>
            <div class="clearfix"></div>
        </ng-container>
    </div>

</div>
