<div (window:resize)="onResize()" id="menu_wrapper">
    <div
        *ngIf="switchAccountDataId"
        id="connectedToDiv"
        [style.left]="(isList) ? '130px' : '120px'"
    >
        <div style="display: inline-block">{{ 'admin.commons.account' | translate }} {{ loggedAccount }}
        </div>
    </div>
    <div class="main_column">
        <div id="leftMenu" class="affix">
            <ul class="nav nav-sidebar">
                <li class="ngenioMenuButton"
                    style="width: 100%; height: 142px;"
                >
                    <a (click)="goToPeople()"><img
                            alt="N"
                            style="margin: auto; padding-top: 32px;"
                            src="../../../../assets/menu_gauche/N.png"></a>
                </li>

                <li class="firstItem mainMenuButton" [class.selectedButton]="actualModule=='people'">
                    <a routerLink="/people" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='people'"
                                 src="../../../../assets/menu_gauche/menu_principal/individus.png"/>
                            <img alt="" *ngIf="actualModule=='people'"
                                 src="../../../../assets/menu_gauche/menu_principal/individus_selected.png"/>
                            <div [class.selectedText]="actualModule=='people'"
                                 class="mainMenuButtonTitle text-uppercase">
                                {{ 'app.navigation.people' | translate }}
                            </div>
                        </div>
                    </a>
                </li>

                <li *ngIf="stateService && !stateService.session.hasSatelliteRole && stateService.hasAnalysisAccess && !isSender"
                    class="mainMenuButton"
                    [class.selectedButton]="actualModule=='analysis' || actualModule=='graphboard' || actualModule=='rightMatch' || actualModule=='idwMatrix'">
                    <a routerLink="{{lastAddress ? lastAddress : '/analysis/list'}}" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='analysis' && actualModule!='graphboard' && actualModule!='rightMatch' && actualModule!='idwMatrix'"
                                 src="../../../../assets/menu_gauche/menu_principal/analyse.png"/>
                            <img alt="" *ngIf="actualModule=='analysis' || actualModule=='graphboard' || actualModule=='rightMatch' || actualModule=='idwMatrix'"
                                 src="../../../../assets/menu_gauche/menu_principal/analyse_selected.png"/>
                            <div [class.selectedText]="actualModule=='analysis' || actualModule=='graphboard' || actualModule=='rightMatch' || actualModule=='idwMatrix'"
                                 class="mainMenuButtonTitle text-uppercase">
                                {{ 'app.navigation.analysis' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <li *ngIf="stateService && stateService.hasJobAccess && !isSender"
                    class="mainMenuButton"
                    [class.selectedButton]="actualModule=='jobs'">
                    <a routerLink="/jobs/list" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='jobs'"
                                 src="../../../../assets/menu_gauche/menu_principal/postes.png"/>
                            <img alt="" *ngIf="actualModule=='jobs'"
                                 src="../../../../assets/menu_gauche/menu_principal/postes_selected.png"/>
                            <div [class.selectedText]="actualModule=='jobs'" class="mainMenuButtonTitle text-uppercase">
                                {{ 'commons.jobs' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <li *ngIf="!isSender"
                    class="mainMenuButton"
                    [class.selectedButton]="actualModule=='archives'">
                    <a routerLink="/archives/list" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='archives'"
                                 src="../../../../assets/menu_gauche/menu_principal/archives.png"/>
                            <img alt="" *ngIf="actualModule=='archives'"
                                 src="../../../../assets/menu_gauche/menu_principal/archives_selected.png"/>
                            <div [class.selectedText]="actualModule=='archives'"
                                 class="mainMenuButtonTitle text-uppercase">
                                {{ 'commons.archives' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <li *ngIf="(!switchAccountDataId && isAdmin) || (
                   (
                    this.stateService.session.sessionData.type === 'ngenio' ||
                    this.stateService.session.sessionData.type === 'subsidiary' ||
                    this.stateService.session.sessionData.type === 'distributor'
                   ) && (
                        currentAccountTypeAfterSwitch === 3 ||
                        currentAccountTypeAfterSwitch === 2 ||
                        currentAccountTypeAfterSwitch === 1
                    )
                )"
                    class="mainMenuButton"
                    [class.selectedButton]="actualModule=='administration' && !isInUserPrefs()"
                >
                    <a (click)="goToAdmin()" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="Admin" *ngIf="actualModule!='administration' || (actualModule=='administration' && isInUserPrefs())"
                                 src="../../../../assets/menu_gauche/menu_principal/admin.png"/>
                            <img alt="Admin" *ngIf="actualModule=='administration' && !isInUserPrefs()"
                                 src="../../../../assets/menu_gauche/menu_principal/admin_selected.png"/>
                            <div [class.selectedText]="actualModule=='administration' && !isInUserPrefs()"
                                 class="mainMenuButtonTitle text-uppercase">
                                {{ 'app.navigation.admin' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <li *ngIf="isSuperAdmin && false"
                    class="mainMenuButton"
                    [class.selectedButton]="actualModule=='tools'"
                >
                    <a (click)="goToTools()" routerLinkActive="active">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='tools' || (actualModule=='tools' && isInUserPrefs())"
                                 src="../../../../assets/menu_gauche/menu_principal/tools.png"/>
                            <img alt="" *ngIf="actualModule=='tools' && !isInUserPrefs()"
                                 src="../../../../assets/menu_gauche/menu_principal/tools_selected.png"/>
                            <div [class.selectedText]="actualModule=='tools' && !isInUserPrefs()"
                                 class="mainMenuButtonTitle text-uppercase">
                                {{ 'app.navigation.tools' | translate }}
                            </div>
                        </div>
                    </a>
                </li>
                <!--ng-template #creditsListing>
                    <table style="white-space:nowrap;
                                border-collapse:separate;
                                border-spacing:3px;"
                    >
                        <ng-container *ngFor="let account of creditListing">
                            <tr *ngIf="account" style="margin-bottom: 4px">
                                <td style="vertical-align: middle; padding: 2px 15px 2px 2px; ">
                                    {{ account[0] }}
                                </td>
                                <td *ngIf="account[1]!='unlimited'"
                                    style="vertical-align: middle;text-align: center; padding: 2px"
                                    [class.creditsWarning]="account[1]<6"
                                    [class.credits]="account[1]>=6"
                                >
                                    {{ account[1] }}
                                </td>
                                <td *ngIf="account[1]=='unlimited'"
                                    style="vertical-align: middle;text-align: center; padding: 2px"
                                    class="credits"
                                >
                                    <span *ngIf="translate.currentLang=='fr'">Illimité</span>
                                    <span *ngIf="translate.currentLang=='en'">Unlimited</span>
                                </td>
                            </tr>
                        </ng-container>
                    </table>
                    // Credit listing tooltip
                    [tooltip]="creditsListing"
                    [isDisabled]="stateService.session.sessionData['switchAccountData']"
                    [placement]="'right'"
                </ng-template-->
                <li *ngIf="accountNumber !== 19999 && accountNumber !== '19999'"
                    class="mainMenuButtonBottom"
                    style="padding-top: 10px; height: 70px"
                    [class.selectedButton]="actualModule=='administration' && isInUserPrefs()"
                >
                    <a (click)="goToPreferences()" style="text-decoration: none">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="actualModule!='administration' || (actualModule=='administration' && !isInUserPrefs())"
                                 class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte.png"/>
                            <img alt="" *ngIf="actualModule=='administration' && isInUserPrefs()"
                                 class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte_selected.png"/>
                            <div
                                    class="mainMenuButtonTitle"
                                    style="margin-bottom: 10px"
                                    [innerHTML]="'app.navigation.myProfile' | translate"
                            ></div>
                            <div
                                    class="mainMenuItemTextLine2"
                                    style="margin-top: 10px"
                            >
                                {{ principalAccountCredits }}
                            </div>
                            <img alt="" class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte_selected.png"/>
                        </div>
                    </a>
                </li>
                <li *ngIf="accountNumber === 19999 || accountNumber === '19999'"
                    class="mainMenuButtonBottom"
                    style="padding-top: 10px; height: 70px"
                    [class.selectedButton]="actualModule=='administration' && isInUserPrefs()"
                >
                    <a (click)="goToPreferences()" style="text-decoration: none">
                        <div class="mainMenuItemContainer">
                            <img alt="" *ngIf="!checkIsPreferenceSet() &&
                                    (
                                        actualModule!='administration' ||
                                        (actualModule=='administration' && !isInUserPrefs())
                                    )
                                  "
                                 class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte.png"/>
                            <img alt="" *ngIf="
                                    !checkIsPreferenceSet() &&
                                    actualModule=='administration' &&
                                    isInUserPrefs()"
                                 class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte_selected.png"/>
                            <img alt="" *ngIf="checkIsPreferenceSet()"
                                 class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte_orange.png"/>
                            <div
                                    *ngIf="!stateService.session.hasSatelliteRole"
                                    class="mainMenuButtonTitle"
                                    style="margin-bottom: 10px"
                                    [innerHTML]="'app.navigation.myProfile' | translate"
                            ></div>
                            <div
                                    class="mainMenuItemTextLine2"
                                    style="margin-top: 10px"
                            >
                                {{ principalAccountCredits }}
                            </div>
                            <img alt="" class="account_icon"
                                 src="../../../../assets/menu_gauche/menu_compte/compte_selected.png"/>
                        </div>
                    </a>
                </li>
                <li *ngIf="!isSender"
                    class="mainMenuButtonBottom mainMenuButtonBottomCorrector" style="height: 40px;"
                    (click)="showNewsPanel()"
                >
                    <div class="mainMenuItemTextLeft" style="cursor: pointer">
                        <div [class.enNews]="translate.currentLang==='en'">
                            <app-news-icon></app-news-icon>
                        </div>
                    </div>
                </li>
                <li
                        class="mainMenuButton"
                        dropdown
                        [dropup]="true"
                        style="height: 40px; background-color: rgb(49,49,49); border-top: 1px solid rgb(89,89,89);"
                >
                    <div class="pull-left dropdown" style="width: 100%">
                        <div dropdownToggle
                             class="dropdown-toggle sideBarButton"
                             data-toggle="dropdown"
                        >
                            <p style="cursor: pointer; width: 100%; padding-top: 11px; text-align: center; font-size: 11px; color: rgb(215,215,215)">
                                Support&nbsp;&nbsp;<span style="font-size: 65%">▼</span>
                            </p>
                        </div>

                    </div>
                    <ul
                            *dropdownMenu
                            id="dropUpSupport"
                            class="dropdown-menu dropdown-menu-left"
                            style="margin-left: 110px; width: 350px; padding: 10px"
                            [class.mbNormal]="!stateService.session.hasSatelliteRole"
                            [class.mbSatellite]="stateService.session.hasSatelliteRole"
                    >


                        <li style="border-bottom: 1px solid #aaa; font-weight: bold;">
                            <span *ngIf="translate.currentLang=='fr'">Options de support</span>
                            <span *ngIf="translate.currentLang=='en'">Support options</span>
                        </li>
                        <li style="margin-top: 7px">
                            <span *ngIf="translate.currentLang=='fr'">Par téléphone : </span>
                            <span *ngIf="translate.currentLang=='en'">By phone: </span>
                            {{ phoneNumber }}
                        </li>
                        <li>
                                <span *ngIf="translate.currentLang=='fr'" (click)="sendSupportMail()">
                                    Par courriel : info@ngenioworld.com
                                </span>
                            <span *ngIf="translate.currentLang=='en'" (click)="sendSupportMail()">
                                    By E-mail: info@ngenioworld.com
                                </span>
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole" style="border-bottom: 1px solid #aaa; margin-top: 15px; margin-bottom: 7px; font-weight: bold;">
                            Documents
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/{{
                                    (translate.currentLang == 'fr') ? 'Manuel_NGENIO_CONNECT_3.3.pdf' : 'NGENIO_CONNECT_3.3_Manual.pdf'
                                }}">
                                <span *ngIf="translate.currentLang=='fr'">Manuel de l'utilisateur</span>
                                <span *ngIf="translate.currentLang=='en'">User's manual</span>
                            </a>
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole && translate.currentLang=='en'">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/dictionary/competenciesDictionary.pdf">
                                <span>Competencies dictionary</span>
                            </a>
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole && translate.currentLang=='fr'">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/dictionary/dictionnaireDesCompetences.pdf">
                                <span>Dictionnaire des compétences</span>
                            </a>
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole && translate.currentLang=='fr'">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/confidentiality/Ngenio_Politique-Confidentialite.pdf">
                                <span>Politique de confidentialité</span>
                            </a>
                        </li>
                        <li style="border-bottom: 1px solid #aaa; margin-top: 15px; margin-bottom: 7px; font-weight: bold;">
                            <span *ngIf="translate.currentLang=='fr'">Questionnaires MPO (PDF)</span>
                            <span *ngIf="translate.currentLang=='en'">MPO Questionnaires (PDF)</span>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits et perception (FR)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits and perception (FR)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO_Traits.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits seulement (FR)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits only (FR)</span>
                            </a>
                        </li>
                        <li style="border-bottom: 1px solid #ddd; margin-bottom: 7px">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/fr/Sondage_MPO_Perception.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Perception seulement (FR)</span>
                                <span *ngIf="translate.currentLang=='en'">Perception only (FR)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits et perception (ANG)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits and perception (ENG)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO_Traits.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits seulement (ANG)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits only (ENG)</span>
                            </a>

                        </li>
                        <li style="border-bottom: 1px solid #ddd; margin-bottom: 7px">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/en/Survey_MPO_Perception.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Perception seulement (ANG)</span>
                                <span *ngIf="translate.currentLang=='en'">Perception only (ENG)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_Complet.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits et perception (ESP CANADA)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits and perception (SP CANADA)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_traits.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits seulement (ESP CANADA)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits only (SP CANADA)</span>
                            </a>
                        </li>
                        <li style="border-bottom: 1px solid #ddd; margin-bottom: 7px">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_fr/Sondage_MPO_Es_perception.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Perception seulement (ESP CANADA)</span>
                                <span *ngIf="translate.currentLang=='en'">Perception only (SP CANADA)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Complete.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits et perception (ESP USA)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits and perception (SP USA)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Traits.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Traits seulement (ESP USA)</span>
                                <span *ngIf="translate.currentLang=='en'">Traits only (SP USA)</span>
                            </a>
                        </li>
                        <li style="border-bottom: 1px solid #ddd; margin-bottom: 7px">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/es_en/Survey_MPO_Es_Perception.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Perception seulement (ESP USA)</span>
                                <span *ngIf="translate.currentLang=='en'">Perception only (SP USA)</span>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/profiler/questionnaire_profil_de_personnalite_cible.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Profil de personnalité cible (FR)</span>
                                <span *ngIf="translate.currentLang=='en'">Target personality profile (FR)</span>
                            </a>
                        </li>
                        <li *ngIf="!stateService.session.hasSatelliteRole">
                            <a target="_blank" class="docLink" href="{{ apiBaseUrl }}/assets/forms/profiler/questionnaire_target_personality_profile.pdf">
                                <span *ngIf="translate.currentLang=='fr'">Profil de personnalité cible (ANG)</span>
                                <span *ngIf="translate.currentLang=='en'">Target personality profile (ENG)</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <!-- Switch to fr -->
                <li class="mainMenuButtonBottom mainMenuButtonBottomCorrector" style="height: 40px;">
                    <a (click)="('fr' != lang) ? switchLanguage('fr') : switchLanguage('en')">
                        <div class="mainMenuItemContainerLeft">

                            <div *ngIf='"fr" != lang' class="mainMenuItemTextLeft">
                                Français
                            </div>
                            <div *ngIf='"fr" == lang' class="mainMenuItemTextLeft">
                                English
                            </div>

                        </div>
                    </a>
                </li>
                <li *ngIf="switchAccountDataId"
                    class="mainMenuButtonBottom mainMenuButtonBottomCorrector"
                    style="height: 43px"
                >
                    <a (click)="switchBack(switchAccountDataId)">
                        <div class="mainMenuItemContainerLeft">
                            <div class="mainMenuItemTextLeft" style="color: rgb(227, 94, 36)">
                                <div *ngIf="translate.currentLang=='fr'" style="padding-left: 5px">
                                    {{ 'app.navigation.backToAccount' | translate }}
                                </div>
                                <div *ngIf="translate.currentLang=='en'" style="padding-left: 8px">
                                    {{ 'app.navigation.backToAccount' | translate }}
                                </div>
                            </div>
                            <img alt="X" class="langue_icon"
                                 src="../../../../assets/menu_gauche/menu_quitter/quitter_column_mode.png"/>
                            <img alt="X" class="column_mode_off leftMenuIcon leftMenuIconAtRight"
                                 src="../../../../assets/menu_gauche/menu_quitter/quitter_selected.png"/>
                        </div>
                    </a>
                </li>
                <li class="mainMenuButtonBottom mainMenuButtonBottomCorrector">
                    <a (click)="onLogout()">
                        <div class="mainMenuItemContainerLeft">
                            <img alt="X" class="column_mode_off leftMenuIcon leftMenuIconAtRight"
                                 src="../../../../assets/menu_gauche/menu_quitter/quitter.png"/>
                            <div class="mainMenuItemTextLeft">
                                {{ 'app.navigation.logout' | translate }}
                            </div>
                            <img alt="X" class="langue_icon"
                                 src="../../../../assets/menu_gauche/menu_quitter/quitter_column_mode.png"/>
                            <img alt="X" class="column_mode_off leftMenuIcon leftMenuIconAtRight"
                                 src="../../../../assets/menu_gauche/menu_quitter/quitter_selected.png"/>
                        </div>
                    </a>
                </li>
                <!-- Filling the remainder of the column -->
                <li class="mainMenuButtonBottom mainMenuBottomFiller"></li>
            </ul>
        </div>
    </div>
</div>
