<ng-container *ngIf="transactions">
    <div class="pull-left">
        <h3>Transactions</h3>
    </div>
    <div class="pull-right" style="padding-top: 40px;">
        <div class="pull-left"
            style="width: 300px; margin: 0 10px 10px 0"
        >
        </div>
        <div class="pull-right" (click)="openFiltersModal()" style="margin-top: 10px">
            <span class="fakeLink smallPLink">{{ 'commons.filters' | translate }}</span>
        </div>
    </div>
    <div class="clearfix"></div>
    <table class="table" style="width: 100%; table-layout: fixed; position:relative">
        <ngx-loading
            [show]="loading"
            [config]="{
        backdropBorderRadius: '0pt',
        backdropBackgroundColour: 'rgba(255, 255, 255, 0.4)',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
    }"
        ></ngx-loading>
        <thead>
        <tr>
            <th *ngFor="let title of listTitles[translate.currentLang]; let columnIndex = index"
                [style.width]="cellProperties(columnIndex).width">{{ title }}</th>
        </tr>
        </thead>
        <tbody>
        <tr (click)="openTransactionsEditModal(transaction)" *ngFor="let transaction of transactions" style="height: 33px">
            <td [style.width]="cellProperties(0).width">
                <span [style.text-decoration]="(transaction.deleted) ? 'line-through' : 'none'">{{ accountService.rewriteTransactionDescriptive(transaction) }}</span>
                &nbsp;<span *ngIf="transaction.deleted">{{ 'commons.transactionDeleted' | translate }}</span>
            </td>
            <td [style.width]="cellProperties(1).width" [style.text-decoration]="(transaction.deleted) ? 'line-through' : 'none'">{{ transaction.date | date:dateFormat:"UTC" || notSpecified() }}</td>
            <td [style.width]="cellProperties(2).width" [style.text-decoration]="(transaction.deleted) ? 'line-through' : 'none'">{{ transaction.subAccountData.name }}</td>
            <td style="text-align: center" [style.width]="cellProperties(3).width" [style.text-decoration]="(transaction.deleted) ? 'line-through' : 'none'">{{ handleUnlimited(transaction.amount) }}</td>
        </tr>
        </tbody>
    </table>
    <div>
        <div class="pull-right">
            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)"><img alt=">"
                                                                                            src="/assets/buttons/next.png">
            </div>
            <div *ngIf="actualPage == maxPage || !maxPage" class="pull-right"><img alt=">"
                                                                                   src="/assets/buttons/next_deactivated.png">
            </div>
            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img alt="<"
                                                                                       src="/assets/buttons/before.png">
            </div>
            <div *ngIf="actualPage == 1" class="pull-right"><img alt="<" src="/assets/buttons/before_deactivated.png">
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-container>
