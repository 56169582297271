<div id="container" style="z-index: 10000">
    <div id="loginBox" class="row">
        <div class="col-md-2">
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="pull-left">
                    <div id="logoContainer">
                        <img alt="MPO" src="../../../../assets/login/logoLogin.png"/>
                    </div>
                    <h1>{{ 'setPass.changePassword' | translate }}</h1>
                </div>
                <div class="pull-right">
                    <div style="margin-top: 25px">
                        <span *ngIf="translate.currentLang=='fr'" (click)="langChange('en')" class="fakeLink">
                            English
                        </span>
                        <span *ngIf="translate.currentLang=='en'" (click)="langChange('fr')" class="fakeLink">
                            Français
                        </span>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="row">
                <hr/>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div style="height: 30px">
                        <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
                        <p *ngIf="emptyUserName" class="text-danger">
                            <span *ngIf="translate.currentLang=='fr'">Veuillez fournir un nom d'utilisateur</span>
                            <span *ngIf="translate.currentLang=='en'">Please enter a user name</span>
                        </p>
                    </div>

                    <!-- Forget password form -->
                    <form

                            *ngIf="!token && pre"
                            [formGroup]="forgetPassForm"
                            (ngSubmit)="onSubmitForgetPassForm(forgetPassForm)"
                            #forgetPassNgForm="ngForm"
                    >
                        <div class="form-group">
                            <!-- username -->
                            <div *ngIf="translate.currentLang=='fr'">
                                <p style="font-size: 115%">
                                    <b>Veuillez entrer votre nom d'utilisateur.</b> Un courriel vous sera envoyé afin de vous
                                    permettre de choisir un nouveau mot de passe et de vous connecter à votre compte.
                                </p>
                            </div>
                            <div *ngIf="translate.currentLang=='en'">
                                <p style="font-size: 115%">
                                    <b>Please enter your username. </b> You will then receive an email to set a new
                                    password in order to log in to your account.
                                </p>
                            </div>
                            <div>
                                <div class="pull-left"
                                >
                                    <label class="field_title">
                                        <span [class.text-danger]="badUserName">{{ 'setPass.userName' | translate }}</span>
                                    </label>
                                </div>
                                <div class="pull-right">
                                </div>
                                <div class="clearfix"></div>
                                <input type="text" class="form-control" formControlName="username" style="color: #000">
                            </div>

                            <div>
                                <button
                                        type="submit"
                                        class="modalButton pull-right"
                                        style="margin-top: 15px"
                                >
                                    {{ 'commons.send' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                    <div *ngIf="!token && !pre">
                        <div class="framed" *ngIf="translate.currentLang=='en'">
                            An email has been sent to you so you can set a new password to log into
                            your account.
                        </div>
                        <div class="framed" *ngIf="translate.currentLang=='fr'">
                            Un courriel vous a été envoyé afin de vous permettre de choisir un nouveau mot de passe et
                            de vous connecter à votre compte.
                        </div>
                    </div>

                    <!-- Change password form -->
                    <form

                            *ngIf="token"
                            [formGroup]="changePassForm"
                            (ngSubmit)="onSubmitChangePassForm(changePassForm)"
                            #changePassNgForm="ngForm"
                    >
                        <div class="form-group">
                            <!-- password -->
                            <div>
                                <div class="pull-left">
                                    <label class="field_title">
                                        {{ 'commons.password' | translate }}
                                    </label>
                                </div>
                                <div class="pull-right">
                                    <small
                                            *ngIf="validateFormField(changePassForm.controls.password, changePassNgForm)"
                                            class="text-danger"
                                    >
                                        <span [hidden]="!changePassForm.controls.password.errors['required']">
                                            {{ 'commons.passwordMandatory' | translate }}<br/>
                                        </span>
                                        <span [hidden]="!changePassForm.controls.password.errors['minlength']">
                                            {{ 'admin.userForm.pwd8CharsWarning' | translate }}<br/>
                                        </span>
                                    </small>
                                    <small *ngIf="changePassForm.hasError('weakPassword') &&
                                        (changePassForm.controls.password.dirty ||
                                        changePassForm.controls.password.touched)"
                                           class="text-danger"
                                    >
                                        <span>
                                            {{ 'admin.userForm.pwdRuleTitle' | translate }}<br/>
                                            {{ 'admin.userForm.pwdRuleText' | translate }}
                                        </span>
                                    </small>
                                    <small *ngIf="discrepancies &&
                                        (changePassForm.controls.password.dirty ||
                                        changePassForm.controls.password.touched)"
                                           class="text-danger"
                                    >
                                        <span>
                                            {{ 'admin.userForm.pwdDiscrepancy' | translate }}
                                        </span>
                                    </small>
                                </div>
                                <div class="clearfix"></div>
                                <input class="inputForm" type="password" class="form-control" formControlName="password"
                                       style="color: #000">
                                <br>
                                <div>
                                    {{ 'admin.userForm.confirmPwd' | translate }}
                                </div>
                                <input class="inputForm" type="password" class="form-control"
                                       formControlName="pwdConfirmation" style="color: #000">
                            </div>
                            <input type="hidden" formControlName="token">

                            <div>
                                <button
                                        type="submit"
                                        class="modalButton pull-right"
                                        [disabled]="!changePassForm.valid && changePassNgForm.submitted"
                                        style="margin-top: 15px"
                                >
                                    {{ 'commons.finish' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>

                    <div *ngIf="(messageFormSubmit || messagePasswordChange) && pre">
                        <p *ngIf="messageFormSubmit">
                            {{ 'commons.pwfSubmitted' | translate }}
                        </p>
                        <p *ngIf="messagePasswordChange">
                            {{ 'commons.pwChanged' | translate }}
                        </p>
                    </div>

                </div>

                <div class="col-md-1">
                </div>

                <div class="col-md-5">
                    <div id="tagLineContainer" class="pull-right">
                        <p class="pNull">
                            <span id="tagTop">{{ 'login.acquisition' | translate }}</span>
                        </p>
                        <p class="pNull">
                            <span id="tagMid">{{ 'login.development' | translate }}</span>
                            <span class="tagEnd"><em>{{ 'login.and' | translate }}</em></span>
                        </p>
                        <p class="pNull">
                            <span class="tagEnd tagEndBottom"><em>{{ 'login.talentsManagement' | translate }}</em></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
        </div>
    </div>
</div>
