import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges, OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {AccountService} from '../../services/account.service';
import {TextHelper} from '../../../shared/misc/text.helper';
import {IMyOptions} from 'mydatepicker';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {StateService} from '../../../core/services/state/state.service';
import {ProductsHelper} from '../../helpers/products.helper';

@Component({
    selector: 'app-administration-transactions-edit-modal',
    templateUrl: './administration-transactions-edit-modal.component.html',
    styleUrls: ['./administration-transactions-edit-modal.component.scss']
})
export class AdministrationTransactionsEditModalComponent implements OnInit {

    @Input() initialTransaction;

    protected readonly Object = Object;
    protected readonly Math = Math;

    dateEditModel: any;
    public datePickerOptions: IMyOptions = {
            dateFormat: 'dd/mm/yyyy',
        disableSince: {
            year: (new Date()).getFullYear(),
            month: (new Date()).getMonth() + 1,
            day: (new Date()).getDate()
        }
    };

    hasChanged = false;

    countryProducts = {
        Canada: 0,
        US: 0
    };


    products: any = [];

    proceed = true;

    public edit: EventEmitter<any> = new EventEmitter();
    public delete: EventEmitter<any> = new EventEmitter();

    transaction: any;
    subAccounts: any;

    dateFormat;

    productsGroupsNames = ['MPOENT', 'MPOENTPR', 'MPOFSA', 'MPOFSAINT', 'MPOFORM', 'MPOPERS', 'MPOPRO'];
    productsGroups = {
        Canada: {
            MPOENT: [],
            MPOENTPR: [],
            MPOFSA: [],
            MPOFSAINT: [],
            MPOFORM: [],
            MPOPERS: [],
            MPOPRO: []
        },
        US: {
            MPOENTPR: []
        }
    };

    constructor(
        public bsModalRef: BsModalRef,
        public translate: TranslateService,
        public cd: ChangeDetectorRef,
        protected accountService: AccountService,
        protected apiAdministrationService: ApiAdministrationService,
        protected stateService: StateService
    ) {
    }

    ngOnInit(): void {

        this.subAccounts = this.accountService.subAccounts;
        this.transaction = (JSON.parse(JSON.stringify(this.initialTransaction)));
        delete this.initialTransaction;
        this.transaction.initialTransaction = this.transaction;

        this.dateFormat = TextHelper.dateFormat(this.translate.currentLang);
        this.translate.onLangChange.subscribe(
            (res) => {
                this.dateFormat = TextHelper.dateFormat(res.lang);
            }
        );

        if (this.translate.currentLang === 'fr') {
                this.datePickerOptions.dateFormat = 'd/m/yyyy';
        } else {
            this.datePickerOptions.dateFormat = 'd/m/yyyy';
        }
        if (['formations', 'annual_fees', 'buying_credits', 'buying_programs'].includes(this.transaction.transactionType) && !this.transaction.deleted) {
            let dateArray = this.transaction.date.slice(0, 10).split('-');
            this.transaction.date = {date: {year: dateArray[0], month: dateArray[1], day: dateArray[2]}};
        }
        this.fetchProducts();
        this.cd.detectChanges();
    }

    transactionChanged() {
        this.hasChanged = true;
    }

    fetchProducts() {
        let fetchProductObs = this.apiAdministrationService.productsClient([this.accountService.id]).subscribe(
            (res) => {
                if (res) {
                    let availableProducts: any = [];
                    let availableProductsUS: any = [];
                    let availableProductsCanada: any = [];
                    for (let product of res) {
                        if (product.target.indexOf(this.accountService.accountType) > -1) {
                            availableProducts.push(product);
                            if (product.sku.includes(' US')) {
                                availableProductsUS.push(product);
                            } else {
                                availableProductsCanada.push(product);
                            }
                        }
                    }


                    if (this.isClientOrDemo) {
                        for (let product of ProductsHelper.createSkuOrder(availableProductsCanada)) {
                            for (let gr of this.productsGroupsNames) {
                                if (product.hasOwnProperty('skuOrder') && product.skuOrder[0] === gr && this.productsGroups.Canada.hasOwnProperty(gr)) {
                                    this.productsGroups.Canada[gr].push(product);
                                    this.countryProducts.Canada++;
                                }
                            }
                        }

                        for (let product of ProductsHelper.createSkuOrder(availableProductsUS)) {
                            for (let gr of this.productsGroupsNames) {
                                if (product.hasOwnProperty('skuOrder') && product.skuOrder[0] === gr && this.productsGroups.US.hasOwnProperty(gr)) {
                                    this.productsGroups.US[gr].push(product);
                                    this.countryProducts.US++;
                                }
                            }
                        }
                    } else {
                        this.productsGroups.Canada = availableProductsCanada;
                        this.countryProducts.Canada = availableProductsCanada.length;
                        this.productsGroups.US = availableProductsUS;
                        this.countryProducts.US = availableProductsUS.length;
                    }

                    this.products = {
                        ca: ProductsHelper.createSkuOrder(availableProducts),
                        us: ProductsHelper.createSkuOrder(availableProducts)
                    };
                }
                fetchProductObs.unsubscribe();
            }
        );

    }

    checkCreditSufficiency(subaccountNumber, amount) {
        this.transactionChanged();
        let subAccount = subaccountNumber;
        if (this.transaction.sku === 'MOVE_CREDITS_SUBACCOUNT' && amount > 0) {
            subAccount = parseInt(this.transaction.pointer.split(':')[1], 10);
            console.log(subAccount);
        }
        let creditSubscription = this.apiAdministrationService.clientSubaccountCredits(
            [this.accountService.id, subAccount]
        ).subscribe(
            res => {
                // In case we are editing a transaction, we need to remove the initial amount from the current amount
                if (res.credits === 'unlimited' || res.credits === '9999') {
                    this.proceed = true;
                } else {
                    if (this.transaction.initialTransaction.amount) {
                        amount -= this.transaction.initialTransaction.amount;
                    }
                    this.proceed = !(parseInt(res.credits, 10) - parseInt(amount, 10) < 0);
                }
                creditSubscription.unsubscribe();
                this.cd.markForCheck();
            }
        );
    }

    closeModal() {
        this.fetchProducts();
        this.bsModalRef.hide();
    }

    saveEdition() {
        // Note : initialDate may differ from actual date
        delete this.transaction.initialTransaction;
        this.edit.emit(this.transaction);
        this.closeModal();
    }

    deleteTransaction() {
        let creditSubscription = this.apiAdministrationService.clientSubaccountCredits(
            [this.accountService.id, this.transaction.subAccount]
        ).subscribe(
            res => {
                if (res.credits === 'unlimited' || res.credits === '9999') {
                    this.proceed = true;
                } else {
                    // In case we are editing a transaction, we need to remove the initial amount from the current amount
                    this.proceed = !(parseInt(res.credits, 10) - parseInt(this.transaction.amount, 10) < 0);
                }
                creditSubscription.unsubscribe();
                this.cd.markForCheck();
                if (this.proceed) {
                    this.delete.emit({
                        id: this.transaction.id,
                        hardDelete: this.transaction.hardDelete ?? false,
                        origin: this.transaction.origin
                    });
                    this.closeModal();
                }
            }
        );
    }

    get isClientOrDemo() {
        return this.accountService.accountType === 'client' || this.accountService.accountType === 'demo';
    }

}
