<div *ngIf="surveyRequestFormGroup" [formGroup]="surveyRequestFormGroup">
    <div>
        <h4 class="georgia">Options</h4>

        <!-- Satellite -->
        <div *ngIf="isSatelliteSelected$ | async">
            <label>{{ 'people.requestModal.profilerAssociated' | translate }}</label>
            <mat-form-field appearance="fill" class="fullWidth">
                <mat-select formControlName="satelliteJobId">
                    <mat-option *ngFor="let job of this.satelliteJobs; trackBy: trackById" [value]="job.id">
                        {{ job['jobTitle'] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <!-- Variant -->
    <label
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('variant').errors"
    >{{ 'commons.survey' | translate }}</label>
    <mat-form-field *ngIf="!isOnlyIACSelected" appearance="fill" class="fullWidth">
        <mat-select formControlName="variant">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let variantOptions of variantOptions$ | async" [value]="variantOptions.value">
                {{ variantOptions.name | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Variant 0,1 -->
    <input type="hidden" formControlName="recordId" />

    <!-- Country -->
    <label
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('country').errors"
    >{{ 'commons.country' | translate }}</label>
    <mat-form-field appearance="fill" class="fullWidth">
        <mat-select formControlName="country">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let cntry of countryList" [value]="cntry.value">
                {{cntry.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- Lang -->
    <label
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('language').errors"
    >{{ 'commons.language' | translate }}</label>
    <mat-form-field appearance="fill" class="fullWidth">
        <mat-select formControlName="language">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let lang of (langList$ | async)" [value]="lang.value">
                {{lang.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
