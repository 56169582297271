    <div *ngIf="!sendAData" class="formWrapper newSurveyRequest" #top>
    <!-- new survey request case -->
    <h4 class="surveySectionTitle fontLight">
        <button
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Cancel"
            class="backToList"
            (click)="resetAndGoBack(requestStep)"
        >
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
        {{ 'people.requestModal.title' | translate }}
    </h4>
    <hr class="panelHr" />

    <!-- Error message -->
    <div
        class="text-danger"
        *ngIf="(isRequestFormSubmitted$ | async) && !surveyRequestFormGroup.valid"
    >
        {{ 'commons.multiError' | translate }}
    </div>

    <!-- API error -->
    <div *ngIf="apiMessageError$ | async as apiMessageError">
        <p class="text-danger">{{ apiMessageError | translate }}</p>
    </div>

    <!-- Request form -->
    <app-mobile-people-survey-request-form *ngIf="(requestStep$ | async) === stepEnum.RequestForm"
          [surveyRequestFormGroup]="surveyRequestFormGroup"
          [sessionData]="sessionData"
          [isFormSubmitted]="(isRequestFormSubmitted$ | async)"
          [researchAccount]="researchAccount"
          (cancelRequestForm)="resetAndGoBack()"
          (resetRequestForm)="resetRequestForm()"
    ></app-mobile-people-survey-request-form>

    <!-- Record list (only PRB) -->
    <app-mobile-user-list *ngIf="(requestStep$ | async) === stepEnum.RecordListPrbOnly"
         [surveyRequestFormGroup]="surveyRequestFormGroup"
    ></app-mobile-user-list>
    <!-- Send methods (after form valid) -->
    <app-mobile-send-methods *ngIf="(requestStep$ | async) === stepEnum.SendMethods"
         [sendMethod]="surveyRequestFormGroup.get('sendingType').value"
         [tempUser]="tempUser$ | async"
         [sendAnotherSurvey]="anotherRequest"
         (closeSendMethods)="resetAndGoBack($event)"
         (sendAnotherRequest)="sendAnotherRequest()"
    ></app-mobile-send-methods>

    <div *ngIf="(requestStep$ | async) === stepEnum.RequestForm"
         id="upButton"
         (click)="goToTop()"
    >
        <img alt="Go up" src="assets/buttons/chevronUp.png"/>
    </div>
</div>
<div class="questionnaireForm" *ngIf="sendAData">
    <h4 class="modal-title">{{ modalTitle | translate }}
        {{resendForm ? questionnaireType: ''}}
    </h4>
    <ng-container *ngIf="toggle == 0" class="reqContent" [@fadeInOut]>
        <div *ngIf="resendForm">
            <h4 *ngIf="resendType === 'resendPrb'">{{ 'commons.initialQuestionnaire' | translate }}</h4>
            <h4 *ngIf="resendType !== 'resendPrb'">{{ 'commons.survey' | translate }}</h4>
            <div style="padding: 0 0 10px 10px;">Type{{currentLanguage === 'fr' ? ' :' : ':'}}
                <span *ngIf="questionnaireInitialType === 'ra'">
                    {{ 'commons.iac' | translate }}
                </span>
                <span *ngIf="questionnaireInitialType !== 'ra'">
                    {{checkFirstQ()}}
                </span>
                <span *ngIf="questionnaireInitialType === '1,1'">
                    ({{ 'people.commons.complete' | translate }})
                </span>
                <span *ngIf="questionnaireInitialType === '1,1,ra'">
                        ({{ 'people.commons.complete' | translate }}) +
                    {{ 'people.sidebar.raRequestHandling' | translate }}
                </span>
                <span *ngIf="questionnaireInitialType === '1,0'">
                    ({{ 'people.commons.traits' | translate }})
                </span>
                <span *ngIf="questionnaireInitialType === '0,1'">
                    ({{ 'people.commons.behavior' | translate }})
                </span>
                <div *ngIf="language">{{ 'commons.language' | translate }}{{currentLanguage === 'fr' ? ' :' : ':'}}
                    {{introductionTextObj.language ? introductionTextObj.language[language] : ''}}
                </div>
                <div *ngIf="country">{{ 'commons.country' | translate }}{{currentLanguage === 'fr' ? ' :' : ':'}}
                    {{getCountryName(country)}}
                </div>
            </div>
            <p *ngIf="introductionText">{{introductionText}}</p>
            <h3>{{ 'commons.sendingModeSelection' | translate }}</h3>
            <label style="width:100%">
                <select [(ngModel)]="flipValue" (change)="toggleFlip(flipValue)" class="form-control">
                    <option *ngFor="let t of sendingTypes"
                            [value]="t.value">{{ t.title | translate }}</option>
                </select>
            </label>
        </div>
        <div *ngIf="!resendForm" id="testSelection">
            <mat-grid-list
                cols="2"
                rowHeight="140px"
            >
                <mat-grid-tile *ngIf="!prbForm"
                               colspan="1"
                               rowspan="1"
                >
                    <div id="wantedReportsLeft" class="reportsCheckboxes">
                        <p>
                            <span>{{ 'commons.report' | translate }}</span>
                        </p>
                        <!-- MPO -->
                        <ng-container *ngIf="stateService.hasPersonality">
                            <mat-checkbox
                                #mpoCheckbox
                                id="report_mpo"
                                [disabled]="targetPerson['evaluations']['mpo']"
                                [checked]="!raForm && (targetPerson['evaluations']['mpo'] || targetReport === 'mpo')"
                                (change)="checkIfActive(mpoCheckbox, 'mpo')"
                            >
                                {{ 'commons.personality' | translate }}
                            </mat-checkbox><br />
                        </ng-container>

                        <!-- Satellite -->
                        <ng-container *ngIf="stateService.hasSatellite">
                            <mat-checkbox
                                #satelliteCheckbox
                                id="report_satellite"
                                [disabled]="targetPerson['evaluations']['satellite']"
                                [checked]="!raForm && (targetPerson['evaluations']['satellite'] || targetReport === 'satellite')"
                                (change)="checkIfActive(satelliteCheckbox, 'satellite')"
                            >
                                Satellite
                            </mat-checkbox><br />
                        </ng-container>
                        <!-- DIT -->
                        <ng-container *ngIf="stateService.hasCommunication">
                            <mat-checkbox
                                #ditCheckbox
                                id="report_communication"
                                [disabled]="targetPerson['evaluations']['dit'] || disableDitReportByDefault"
                                [checked]="!raForm && (targetPerson['evaluations']['dit'] || targetReport === 'dit')"
                                (change)="checkIfActive(ditCheckbox, 'dit')"
                            >
                                <span [tooltip]="activationText">{{ 'commons.dit' | translate }}</span>
                            </mat-checkbox><br />
                            <ng-template #activationText>
                                {{ 'people.sidebar.inOrderToEnableCommunication' | translate }}
                            </ng-template>
                        </ng-container>
                        <!-- Talents -->
                        <ng-container *ngIf="stateService.hasTalents">
                            <mat-checkbox
                                #talentsCheckbox
                                id="report_talent"
                                [disabled]="targetPerson['evaluations']['talents']"
                                [checked]="!raForm && (targetPerson['evaluations']['talents'] || targetReport === 'talents')"
                                (change)="checkIfActive(talentsCheckbox, 'talents')"
                            >
                                {{ 'commons.talents' | translate }}
                            </mat-checkbox><br />
                        </ng-container>
                        <!-- Talents -->
                        <ng-container *ngIf="raForm && stateService.hasIac">
                            <mat-checkbox
                                id="report_iac"
                                disabled
                                [checked]="true"
                            >
                                {{ 'commons.iac' | translate }}
                            </mat-checkbox>
                        </ng-container>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile
                    colspan="1"
                    rowspan="1"
                >
                    <div class="creditBoxInner">
                        <div>
                            <p>
                                <span>{{ 'commons.credits' | translate }}</span>
                            </p>
                        </div>

                        <div class="creditCost" [style.background-color]="displayWarningMessage ? 'rgb(240, 123, 126)' : 'rgb(208, 238, 207)'">
                            <p>{{ 'people.requestModal.required' | translate }}</p>
                            <p>{{ creditCost || 0 }}</p>
                        </div>

                        <div class="creditsAvailable">
                            <p [class.redWarning]="displayWarningMessage">{{ 'people.requestModal.available' | translate }}</p>
                            <p>{{ creditAvailable || '-' }}</p>
                        </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile *ngIf="prbForm"
                               colspan="1"
                               rowspan="1"
                >
                    &nbsp;
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <h3 *ngIf="!raForm && !prbForm && !resendForm">{{ 'commons.options' | translate }}</h3>
        <div *ngIf="satelliteReportSelected && !resendForm"
             [formGroup]="surveyRequestFormGroup"
        >
            <h4 *ngIf="raForm" class="georgia" style="margin-top: 10px">{{ 'commons.options' | translate }}</h4>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>{{ 'people.requestModal.profilerAssociated' | translate }}</mat-label>
                <mat-select formControlName="satelliteJobId">
                    <mat-option [value]=""></mat-option>
                    <mat-option *ngFor="let job of satelliteJobs" [value]="job.id">
                        {{ job['jobTitle'] }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="!resendForm" [formGroup]="surveyRequestFormGroup" id="normSelection" [style.padding-top]="raForm ? '20px' : null">
            <mat-form-field *ngIf="!raForm && !prbForm" appearance="fill" class="full-width">
                <mat-label
                    [class.text-danger]="continueClicked && surveyRequestFormGroup.get('variant').errors"
                >{{ 'commons.survey' | translate }}</mat-label>
                <mat-select formControlName="variant">
                    <mat-option [value]=""></mat-option>
                    <mat-option *ngFor="let variantOptions of ['traitsAndPrb', 'traitsOnly']"
                                [value]="variantOptionsValue[variantOptions]">
                        {{ 'people.commons.' + variantOptions | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div [class.leftColumnInfo]="!raForm">
                <h3>{{ 'commons.normSelection' | translate }}</h3>
                <label
                    [class.text-danger]="continueClicked && surveyRequestFormGroup.get('country').errors"
                >{{ 'commons.country' | translate }}</label>
                <mat-form-field appearance="fill" class="full-width">
                    <mat-select formControlName="country">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let cntry of structureCountries[currentLanguage]"
                                    [value]="cntry.value">
                            {{cntry.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <label
                    [class.text-danger]="continueClicked && surveyRequestFormGroup.get('language').errors"
                >{{ 'commons.language' | translate }}</label>
                <mat-form-field appearance="fill" class="full-width">
                    <mat-select formControlName="language">
                        <mat-option [value]=""></mat-option>
                        <mat-option *ngFor="let lang of languages[currentLanguage]"
                                    [value]="lang.value">
                            {{ lang.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- Participant info -->
<!--        <app-people-survey-request-participant-info *ngIf="!resendForm"-->
<!--                                                    [surveyRequestFormGroup]="surveyRequestFormGroup"-->
<!--                                                    [isFormSubmitted]="isFormReady"-->
<!--                                                    [researchAccount]="researchAccount"-->
<!--                                                    [mobile]="true"-->
<!--        ></app-people-survey-request-participant-info>-->

        <!-- Request -->
<!--        <app-mobile-survey-request-info-->
<!--            *ngIf="!resendForm"-->
<!--            [surveyRequestFormGroup]="surveyRequestFormGroup"-->
<!--            [sessionData]="sessionData"-->
<!--            [isFormSubmitted]="isFormReady"-->
<!--            [mpoModal]="!raForm"-->
<!--            [iacModal]="raForm"-->
<!--        ></app-mobile-survey-request-info>-->
        <div *ngIf="!resendForm" [formGroup]="surveyRequestFormGroup">
            <label
                [class.text-danger]="continueClicked && surveyRequestFormGroup.get('sendingType').errors"
            >{{ 'commons.sendingModeSelection' | translate }}</label>
            <mat-form-field appearance="fill" class="full-width">
                <mat-select
                    [(ngModel)]="flipValue"
                    formControlName="sendingType"
                >
                    <mat-option
                        *ngFor="let t of sendingTypes"
                        [value]="t.value"
                    >{{ t.title | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="!resendForm" [formGroup]="surveyRequestFormGroup" style="margin-top: 10px">
            <div class="pull-left autoGenWrap">
                <mat-checkbox formControlName="autoGen"
                >{{ 'people.requestModal.autogenReport' | translate }}
                </mat-checkbox>
            </div>
            <div class="pull-left">
                <img alt="" [tooltip]="'people.requestModal.autogenTooltip' | translate"
                     src="assets/other_icons/question.png"
                     style="padding-left: 10px; padding-top: 2px;"
                />
            </div>
            <div class="clearfix"></div>
        </div>

        <table class="mpoRequestTable">
            <tbody>
            <tr>
                <td class="alignRight {{(resendForm) ? 'pt10' : null}}">
                    <button mat-stroked-button
                            class="modalButton"
                            (click)="cancelSending()"
                    >{{ 'commons.cancel' | translate }}</button>
                    <button *ngIf="!resendForm"
                            mat-stroked-button
                            class="modalButton"
                            (click)="formValidate()"
                            style="margin-left: 10px"
                    >
                        {{ 'commons.continue' | translate }}
                    </button>
                    <button *ngIf="resendForm"
                            mat-stroked-button
                            class="modalButton"
                            (click)="formValidate()"
                            style="margin-left: 10px"
                    >
                        {{ 'commons.continue' | translate }}
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="toggle == 2" [@fadeInOut]>
        <div *ngIf="evaluationRequestResend" class="modal-body">
            <div>
                <h3><b>{{ 'commons.sendByYourEmail' | translate}}</b></h3>
                <p>{{ 'commons.sendByYourEmailInstructions_client_1' | translate }}
                    <span style="font-size: 1.3em; color: red">{{ 'commons.sendByYourEmailInstructions_client_2' | translate }}</span>&nbsp;
                    <a style="font-size: 1.3em; color: red" href="mailto:{{ this.personEmail }}?subject={{ (currentLanguage === 'fr') ?
                            'Accès au questionnaire MPO' : 'Access to the MPO Questionnaire' }}&body={{ mailto }}"
                       class="bbuttonHover" target="_blank">{{ 'commons.here' | translate }}</a>.</p>
                <p class="mailtoWarning" style="color: darkred">
                    {{ 'commons.mailtoWarning' | translate }}
                </p>
            </div>
        </div>
        <table class="mpoRequestTable">
            <tbody>
            <tr>
                <td class="alignRight">
                    <button mat-stroked-button
                            class="modalButton"
                            (click)="finishSending()"
                    >{{ 'commons.finish' | translate }}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="toggle == 3" [@fadeInOut]>
        <form [formGroup]="evaluationResendForm" (ngSubmit)="onSubmit(evaluationResendForm)"
              #requestResendForm="ngForm">
            <div *ngIf="evaluationRequestResend" class="modal-body">
                <h3><b>{{ 'commons.sendByNgenioServer' | translate }}</b></h3>
                <div class="form-group">
                    <div>
                        <mat-form-field appearance="fill" class="fullWidth">
                            <label>
                                <span>{{ 'commons.email' | translate }}</span>
                                <small *ngIf="(evaluationResendForm.controls.emailTo.dirty ||
                                     evaluationResendForm.controls.emailTo.touched)"
                                       class="text-danger alRight"
                                >
                                    <span *ngIf="evaluationResendForm.hasError('invalidEmail') || emptyEmail">
                                        {{ 'commons.invalidEmail' | translate }}
                                    </span>
                                </small>
                                <input matInput
                                       formControlName="emailTo"
                                       (blur)="blurred = true"
                                       (keyup)="onFormChange(requestResendForm)"
                                >
                            </label>
                        </mat-form-field>
                    </div>
                    <div>
                        <label for="textToSendInput"><span class="field_title">{{ 'commons.emailContent' | translate }}</span></label><br/>
                        <textarea
                            style="border: 1px solid #ccc; width: 100%; height: 317px"
                            [(ngModel)]="textToSend"
                            id="textToSendInput"
                            formControlName="message"
                        ></textarea>
                    </div>
                    <h3 style="margin-bottom: 15px; margin-top: 20px">{{ 'people.requestModal.sentText' |
                        translate}}</h3>
                    <h4>{{ 'people.requestModal.yourMessage' | translate }}</h4>
                    <p>{{ textToSend }}</p>
                    <h4>{{ 'people.requestModal.loginData' | translate }}</h4>

                    <div [innerHTML]="innerHtml"></div>
                </div>
            </div>
            <table class="mpoRequestTable">
                <tbody>
                <tr>
                    <td class="alignRight">
                        <button mat-stroked-button
                                class="modalButton"
                                (click)="cancelSending()"
                        >{{ 'commons.cancel' | translate }}</button>

                        <button
                            mat-stroked-button
                            type="submit"
                            class="modalButton"
                            [disabled]="!evaluationResendForm.valid || emptyEmail"
                        >
                            {{ 'commons.send' | translate }}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    </ng-container>
    <!-- ok -->
    <ng-container *ngIf="toggle == 4" [@fadeInOut]>
        <div *ngIf="evaluationRequestResend" class="modal-body">
            <h3><b>{{ 'people.requestModal.cutnpasteTitle' | translate }}</b></h3>
            <br>
            <div [innerHTML]="innerHtml"></div>
        </div>
        <table class="mpoRequestTable">
            <tbody>
            <tr>
                <td class="alignRight">
                    <button mat-stroked-button
                            class="modalButton"
                            (click)="cancelSending()"
                    >{{ 'commons.cancel' | translate }}</button>
                    &nbsp;
                    <button mat-stroked-button
                            class="modalButton"
                            (click)="finishSending()"
                    >{{ 'commons.finish' | translate }}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
    <ng-container *ngIf="toggle == 5" [@fadeInOut]>
        <div *ngIf="evaluationRequestResend" class="modal-body">
            <p>{{ 'commons.enterSurveysOnline' | translate }} <a (click)="openEvaluationForm()">{{
                'commons.here' | translate }}</a>
            </p>
            <p>{{ 'commons.completeOnlineFill' | translate }}</p>
        </div>
        <table class="mpoRequestTable">
            <tbody>
            <tr>
                <td class="alignRight">
                    <button mat-stroked-button
                            class="modalButton"
                            (click)="cancelSending()"
                    >{{ 'commons.cancel' | translate }}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </ng-container>
</div>
