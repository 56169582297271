<table style="width: 100%">
    <tr *ngIf="!newItem || info['creation']" class="actionModify">
        <td>
            <label for="{{info['field']}}Field">{{ info.title | translate }}
                <span *ngIf="isFormationField(info['field']) && dataObjectName">
                    <br /> <small>({{ 'commons.' + dataObjectName | translate }}</small>)
                </span>
            </label>
        </td>
        <td
            [attr.colspan]="(editEnable || !info['editable']) ? 2 : null"
            [class.editing]="focused == info['field'] && newItem"
            [class.width50]="formPermissions"
            [class.editEnable]="editEnable"
        >
            <div *ngIf="!isEditable(info['field']) && !newItem && itemData">
                <span *ngIf="(isRegularField(info['field']) && section != 'user') || (isRegularField(info['field']) && !isDateField(info['field']))">
                    {{ itemData[info['field']] }}
                </span>
                <span *ngIf="section == 'user' && info['field'] == 'creationDate'">
                    {{ itemData[info['field']] | momentDateLang }}
                </span>
                <span *ngIf="section == 'user' && info['field'] == 'formationDate'">
                    {{ mongoDateToDp(itemData[info['field']]) }}
                </span>
                <span *ngIf="info['field'] == 'password'">&#8226;&#8226;&#8226;&#8226;&#8226;</span>
                <span *ngIf="info['field'] == 'role'">{{ 'admin.roles.' + itemData[info['field']] | translate }}</span>
                <span *ngIf="info['field'] == 'website'">
                    <!-- website -->
                    <a target='_blank' [href]="itemData[info['field']]"
                    >{{ itemData[info['field']]}}</a>
                </span>
                <label *ngIf="info['field'] == 'status'" for="statusField" class="likeSpan">
                    <!-- Status -->
                    <span *ngIf="translate.currentLang == 'fr'">
                        {{ itemData[info['field']] ? 'Actif' : 'Inactif' }}
                    </span>
                    <span *ngIf="translate.currentLang == 'en'">
                        {{ itemData[info['field']] ? 'Active' : 'Inactive' }}
                    </span>
                </label>
                <span *ngIf="info['field'] === 'parentPath'">
                    <!-- account manager -->
                    {{ clientAccount.parentPathName }}
                </span>
                <span *ngIf="info['field'] === 'demoExpiresAtLocal' || info['field'] === 'adoptionDate' || info['field'] === 'renewalDate' || info['field'] === 'dropDate'">
                    {{ (itemData && info && info['field'] && itemData[info['field']] && (itemData[info['field']]['fr'] || itemData[info['field']]['en'])) ?
                        itemData[info['field']][translate.currentLang] :
                        itemData[info['field']]
                    }}
                </span>
                <span *ngIf="formPermissions">
                    {{ (itemData[info['field']]) ? ('commons.yes' | translate) : ('commons.no' | translate) }}
                </span>
                <span *ngIf="info['field'] == 'subAccountAccess'">
                    {{ subAccountsAccess }}
                </span>
                <span *ngIf="info['field'] == 'principalSubAccount'">
                    {{ subAccountName }}
                </span>
                <span *ngIf="info['field'] == 'language'">
                    {{checkLanguageName()}}
                </span>
                <span *ngIf="isFormationField(info['field']) && info['field'] === 'training'">
                    <span *ngIf="itemData[info['field']]">{{ 'commons.yes' | translate }}</span>
                    <span *ngIf="!itemData[info['field']]">{{ 'commons.no' | translate }}</span>
                </span>
                <span *ngIf="info['field'] == 'name'">
                    {{ itemData['principalAccount'] }}
                </span>
            </div>

            <div *ngIf="isEditable(info['field']) || newItem" class="{{info['field']}}_field">
                <ng-multiselect-dropdown
                    *ngIf="info['field'] == 'subAccountAccess'"
                    [placeholder]="'commons.subAccounts' | translate"
                    [settings]="dropDownSettings"
                    [data]="ssOptions"
                    [(ngModel)]="ssModel"
                    (onSelect)="onSubAccountsChange()"
                    (onDeSelect)="onSubAccountsChange()"
                >
                </ng-multiselect-dropdown>
                <select
                    *ngIf="info['field'] == 'principalSubAccount'"
                    id="principalSubAccountSelect"
                    [(ngModel)]="itemData[info['field']]"
                >
                    <option>{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let subAcct of clientAccount.subAccounts" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
                <my-date-picker *ngIf="info['field'] === 'creationDate'"
                                [locale]="translate.currentLang"
                                [options]="datePickerOptions[translate.currentLang]"
                                [selDate]="itemData[info['field']]"
                ></my-date-picker>

                <my-date-picker *ngIf="info['field'] === 'adoptionDate' || info['field'] === 'renewalDate'"
                    [locale]="translate.currentLang"
                    [options]="datePickerOptions[translate.currentLang]"
                    [selDate]="(!newItem) ? (itemData[info['field']] && itemData[info['field']][translate.currentLang]) : ''"
                    (dateChanged)="setDateValue(info['field'], $event)"
                ></my-date-picker>

                <my-date-picker *ngIf="info['field'] === 'dropDate'"
                    [locale]="translate.currentLang"
                    [options]="datePickerOptions[translate.currentLang]"
                    [selDate]="itemData['dropDate'][translate.currentLang]"
                    (dateChanged)="setDateValue('dropDate', $event)"
                ></my-date-picker>

                <div *ngIf="isFormationField(info['field']) && isDateField(info['field'])">
                    {{ translate.currentLang }}
                </div>
                <my-date-picker *ngIf="isFormationField(info['field']) && isDateField(info['field'])"
                    [locale]="translate.currentLang"
                    [options]="datePickerOptions[translate.currentLang]"
                    [selDate]="mongoDateToDp(itemData[info['field']])"
                    (dateChanged)="onChangeFormationDate($event)"
                ></my-date-picker>

                <div *ngIf="isInputField(info['field'])">
                    <input id="{{info['field']}}Field" type="text"
                           (focus)="focused = info['field']"
                           (focusout)="focused = ''"
                           [(ngModel)]="itemData[info['field']]"
                    />
                </div>

                <!-- role -->
                <select
                    *ngIf="info['field'] == 'role'"
                    id="userTypeSelect"
                    [(ngModel)]="itemData[info['field']]"
                >
                    <option value="">{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let usrType of userRoles"
                            [value]="usrType.value"
                            [disabled]="!canSelectUser(usrType.value)"
                    >
                        {{usrType.name}}
                    </option>
                </select>

                <!-- status -->
                <select
                    *ngIf="info['field'] == 'status'"
                    id="statusField"
                    (focus)="focused = info['field']"
                    (focusout)="focused = ''"
                    [(ngModel)]="itemData[info['field']]"
                >
                    <option value="1">{{ 'admin.commons.active' | translate }}</option>
                    <option value="0">{{ 'admin.commons.inactive' | translate }}</option>
                </select>

                <!-- account manager -->
                <select *ngIf="info['field'] == 'parent'"
                        (focus)="focused = info['field']"
                        (focusout)="focused = ''"
                        [(ngModel)]="itemData['parent']">
                    <option value="">{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let parAcct of parentAccounts" [value]="parAcct.number">{{ parAcct.name }}</option>
                </select>

                <!-- administrator -->
                <select *ngIf="info['field'] == 'administrator'"
                    id="administratorSelect"
                    [(ngModel)]="itemData['administrator']"
                >
                    <option value="">{{ 'commons.none' | translate }}</option>
                    <option *ngFor="let admin of mainAdmins" [value]="admin">
                        {{ admin }}
                    </option>
                </select>

                <!-- language -->
                <select *ngIf="info['field'] == 'language'"
                    [(ngModel)]="itemData['language']"
                >
                    <option>{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let lang of sessionLanguages[translate.currentLang]" [value]="lang.value">
                        {{lang.name}}
                    </option>
                </select>

                <!-- demo expiration date -->
                <my-date-picker *ngIf="info['field'] == 'demoExpiresAtLocal'"
                                [locale]="translate.currentLang"
                                [options]="datePickerOptions[translate.currentLang]"
                                [selDate]="itemData['demoExpiresAtLocal'][translate.currentLang]"
                                (dateChanged)="setExpiresAtValue($event)"
                ></my-date-picker>

                <!-- permissions -->
                <mat-checkbox *ngIf="formPermissions"
                              [(ngModel)]="formPermissions[info['field']]"
                              (change)="checkFormPermission($event, info['field'])"
                >
                </mat-checkbox>

                <!-- formation training -->
                <mat-checkbox *ngIf="isFormationField(info['field']) && info['field'] === 'training'"
                              [(ngModel)]="itemData['training']"
                >
                </mat-checkbox>


            </div>
        </td>
        <td *ngIf="!editEnable && !newItem && info['editable'] && canEditItem; else blankSpace" class="pencilCell">
            <div
                [class.editable]="!editEnable"
                (click)="editField(info['field'], true)"
            >
                <div class="editableIcon"></div>
            </div>
        </td>
        <ng-template
            #blankSpace
        >
            <td
                class="pencilCell"
            ></td>
        </ng-template>
    </tr>
    <tr *ngIf="newItem && info['creation']">
        <td colspan="2" class="padding0">
            <span *ngIf="info['field'] == 'telephone' || info['field'] == 'phone'" class="formats">Format: CA/US 555 555-5555, FR 5 55 55 55 55</span>
            <span *ngIf="info['field'] == 'zipcode'" class="formats">Format: CA H0H 0H0 US 12345, FR 00001</span>&nbsp;
        </td>
    </tr>
    <tr *ngIf="!newItem" class="modifyButtons">
        <td *ngIf="!formPermissions"></td>
        <td colspan="2" [class.modifyEnabled]="isEditable(info['field']) && editEnable">
            <div
                [class.edit]="editEnable"
                *ngIf="isEditable(info['field']) && editEnable"
            >
                <div *ngIf="info['field'] == 'telephone' || info['field'] == 'phone'"><span class="formats pull-left">Format: CA/US 555 555-5555, FR 5 55 55 55 55</span></div>
                <div *ngIf="info['field'] == 'zipcode'"><span class="formats pull-left">Format: CA H0H 0H0 US 12345, FR 00001</span></div>
                <div *ngIf="info['field'] == 'username'"><span class="formats pull-left">{{info['validity']['message']}}</span></div>
                <div class="pull-right">
                    <div *ngIf="!formPermissions && !(info['field'] == 'username' && !info['validity']['valid'])"
                         (click)="updateField(info['field'], itemData[info['field']])"
                         class="pull-left saveText">{{'commons.save' | translate }}
                    </div>
                    <div *ngIf="formPermissions" (click)="updateField('permissions', formPermissions)"
                         class="pull-left saveText">{{'commons.save' | translate }}
                    </div>
                    <div (click)="cancelEditField(info['field'])" class="pull-left cancelText"
                         style="margin-left: 10px">{{ 'commons.cancel' | translate }}
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </td>
    </tr>
</table>
