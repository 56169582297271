<div *ngIf="report && (this.person.satelliteScore !=='noJobProfile')">
    <h2>{{ 'people.reportSatellite.infoOnSatellite' | translate }}</h2>

    <ul class="plainList">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ person.satelliteDateToDisplay }}</li>
        <li *ngIf="person.satelliteRequestedByInfo && person.satelliteRequestedByInfo.fullName">
            {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> {{ person.satelliteRequestedByInfo.fullName }}
        </li>
        <li *ngIf="person.satelliteTimeSpent && person.satelliteTimeSpent.size == 3">
            {{ 'people.commons.elapsedTime' | translate }}<span [innerHTML]="titleSep"></span>
            S1<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[0] }} -
            S2<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[1] }} -
            Total<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[2] }}
        </li>
        <li *ngIf="person.satelliteTimeSpent && person.satelliteTimeSpent.size == 5">
            {{ 'people.commons.time' | translate }}<span [innerHTML]="titleSep"></span>
            Total<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[4] }} -
            Section 1<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[0] }} -
            Section 2<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[1] }} -
            Section 3<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[2] }} -
            Section 4<span [innerHTML]="titleSep"></span> {{ person.satelliteTimeSpent.data[3] }}
        </li>
        <li>{{ 'people.commons.norm' | translate }}<span [innerHTML]="titleSep"></span> {{ person.satelliteNorm }}</li>
        <li *ngIf="person.satelliteMethod">
            {{ 'people.commons.method' | translate }}<span [innerHTML]="titleSep"></span>
            <span *ngIf="person.satelliteMethod === 0"> {{ 'people.commons.paperQuestionnaire' | translate }}</span>
            <span *ngIf="person.satelliteMethod === 1"> {{ 'people.commons.onlineQuestionnaire' | translate }}</span>
        </li>
        <li *ngIf="report['profiler']">
            {{ 'people.reportSatellite.associatedJobProfileSatellite' | translate }}<span [innerHTML]="titleSep"></span>
            &nbsp;<a (click)="goToJob(report['profiler'].id)">{{ report['profiler']['jobTitle'] }}</a>
        </li>
        <li *ngIf="!report['profiler']">
            {{ 'people.reportSatellite.associatedJobProfileSatellite' | translate }}<span [innerHTML]="titleSep"></span>
            {{ 'people.reportSatellite.noJobSelected' | translate }}
        </li>
    </ul>
    <hr/>

    <h2 class="ngorange fontRegular">MPO Satellite</h2>
    <div *ngIf="report['profiler']">
        <h3>{{ 'people.reportSatellite.concordanceLevel' | translate }}<span [innerHTML]="titleSep"></span>
            {{ report['satellite'].level }}</h3>
        <p>{{ report['satellite'].text }}</p>
        <hr>
        <!-- Traits Summary -->


        <!-- Profile -->
        <h3 style="margin-top: 15px">Portrait</h3>
        <!--em>
          Ce descriptif présente une vue d'ensemble du type de personnalité se rapprochant de celle du répondant et ne décrit pas
          précisément chacun des traits de la personne.
          Tous les portraits ont reçu un nom pour les distinguer les uns des autres et pour simplifier la communication entre les
          utilisateurs. Nous avons voulu trouver des
          noms descriptifs qui soient aussi neutres que possible pour ne pas porter au jugement. Note : Chaque portrait est
          présenté dans une variété de textes personnalisés
          selon les traits spécifiques du répondant. Cependant, seul un analyste certifié MPO pourra, par une rétroaction
          personnelle, apporter les nuances appropriées.
        </em-->
        <hr/>
        <div id="portrait">
            <p class="georgia">{{ profile.OR }}</p>
            <p class="georgia">{{ profile.SE }}</p>
            <p class="georgia" *ngFor="let paragraph of profile.par">
                {{ paragraph }}
            </p>
            <h4>{{ 'commons.leadStyle' | translate }}</h4>
            <ul class="georgia">
                <li *ngFor="let item of profile.lead">
                    {{ item }}
                </li>
            </ul>
            <h4>{{ 'commons.commStyle' | translate }}</h4>
            <ul class="georgia">
                <li *ngFor="let item of profile.comm">
                    {{ item }}
                </li>
            </ul>

            <h4>{{ 'commons.workEnvironment' | translate }}</h4>
            <ul>
                <li *ngFor="let item of profile.wenv">
                    {{ item }}
                </li>
            </ul>

            <h4>{{ 'commons.motivationalNeeds' | translate }}</h4>
            <ul class="georgia">
                <li *ngFor="let item of profile.motv">
                    {{ item }}
                </li>
            </ul>
        </div>
    </div>

</div>
<ng-container *ngIf="!(report && (this.person.satelliteScore !=='noJobProfile'))">
    <h2>{{ 'people.reportSatellite.reportSatellite' | translate }}</h2>
    <div #noJobDiv>
        {{ 'people.reportSatellite.noJobProfileSelected' | translate }}
    </div>
</ng-container>
<button (click)="moveTo('top')">
    <span id="upButton">
        <img src="assets/buttons/chevronUp.png" alt=""/>
    </span>
</button>
