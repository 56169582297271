<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'admin.commons.adjustment' | translate }}</h4>
    <div
        class="pull-left text-danger"
        style="margin-left: 15px; margin-top: 2px"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div *ngIf="!proceed" class="pull-left"
         id="insufficientCreditLevelWarning"
    >
        {{ 'commons.insufficientCreditLevel' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<div class="modal-body">

    <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

    <form [formGroup]="purchaseAdjustementForm" (ngSubmit)="onSubmit(purchaseAdjustementForm)"
          #purchaseAdjustementNgForm="ngForm">
        <div class="form-group">

            <!-- credits -->
            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(purchaseAdjustementForm.controls.credits, purchaseAdjustementNgForm, submitted)"
                >
                    <label for="creditsInput"
                           class="field_title">{{ 'admin.adjustmentModal.creditsAmount' | translate }}</label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(purchaseAdjustementForm.controls.credits, purchaseAdjustementNgForm)"
                        class="text-danger"
                    >
                        {{ 'commons.creditsMandatory' | translate }}
                    </small>
                </div-->
                <div class="clearfix"></div>
                <input
                    type="text"
                    id="creditsInput"
                    class="form-control"
                    formControlName="credits"
                    (change)="checkCreditSufficiency(purchaseAdjustementForm.controls.subAccount.value, purchaseAdjustementForm.controls.credits.value)"
                >
            </div>

            <!-- subAccount -->
            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(purchaseAdjustementForm.controls.subAccount, purchaseAdjustementNgForm, submitted)"
                >
                    <label for="subAccountSelect" class="field_title">{{ 'commons.subAccount' | translate }}</label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(purchaseAdjustementForm.controls.subAccount, purchaseAdjustementNgForm)"
                        class="text-danger"
                    >
                        {{ 'commons.subAccountMandatory' | translate }}
                    </small>
                </div-->
                <div class="clearfix"></div>
                <select
                    class="form-control"
                    id="subAccountSelect"
                    formControlName="subAccount"
                    (change)="checkCreditSufficiency(purchaseAdjustementForm.controls.subAccount.value, purchaseAdjustementForm.controls.credits.value)"
                >
                    <option value="">{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let subAcct of subAccounts" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <!-- extraDescription -->
            <div>
                <div class="pull-left">
                    <label for="extraDescriptionInput" class="field_title">
                        {{ 'commons.notes' | translate }}
                    </label>
                </div>
                <textarea class="form-control" id="extraDescriptionInput" formControlName="extraDescription" rows="4"
                          cols="50"></textarea>
            </div>

            <!-- clientId -->
            <input type="hidden" formControlName="clientId">

            <div>
                <button
                    type="submit"
                    class="modalButton pull-right"
                    [disabled]="(!purchaseAdjustementForm.valid && purchaseAdjustementNgForm.submitted) || !proceed"
                    style="margin-top: 15px"
                >
                    {{ 'admin.adjustmentModal.adjust' | translate }}
                </button>
                <button
                    class="modalButton pull-right"
                    (click)="closeModal()"
                    style="margin-top: 15px; margin-right: 15px"
                >
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>

        </div>
    </form>
</div>
