<mat-grid-list *ngIf="!showSurvey && !peopleList.redirectTo"
               cols="5"
               rowHeight="60px"
               class="mobileHeader"
>
    <mat-grid-tile
        colspan="1"
        rowspan="1"
        style="max-width: 87px;"
    >
        <app-mobile-navigation></app-mobile-navigation>
        <a
            class="reloadLink"
            (click)="refreshList()"
        >
            <img alt="N" src="{{logoImg}}">
        </a>
    </mat-grid-tile>
    <mat-grid-tile
        colspan="4"
        rowspan="1"
    >
        <app-mobile-main-list-search-bar
            [initialValue]="peopleList.listSearchFilters"
            (searchEvent)="setSearchFilter($event)"
            style="width: 85%"
        ></app-mobile-main-list-search-bar>
    </mat-grid-tile>
</mat-grid-list>
<div class="searchResults">
    <span
        style="font-weight: bold"
        *ngIf="peopleList.selectionDone && peopleList.totalListItems !== null"
    >
            {{ peopleList.totalListItems }}
        {{ peopleList.resMention | translate }}
        <span *ngIf="peopleList.totalItems">
                {{ 'commons.of' | translate }} {{ peopleList.totalItems }}
            </span>
        </span>
</div>
<h1 class="sectionTitle">
    <span *ngIf='!peopleList.archive'>{{ 'commons.individus' | translate }}</span>
    <span *ngIf='peopleList.archive'>{{ 'commons.archives' | translate }}</span>
</h1>
<div *ngIf="peopleList.toList && !showSurvey && !peopleList.redirectTo"
     infiniteScroll
     [infiniteScrollDistance]="4"
     [infiniteScrollThrottle]="300"
     [scrollWindow]="false"
     (scrolled)="peopleList.listScrolled()"
     class="scrollableList"
>
    <ngx-loading
        [show]="peopleList.loading"
        [config]="loaderDisplay"
    ></ngx-loading>
    <app-mobile-main-list
        [selectedItemId]="peopleList.state['personToDisplayId']"
        [tableDefinition]="peopleList.tableDefinition"
        [listsData]="peopleList.toList"
        (selectedItem)="peopleList.personSelected($event);"
        (reordering)="setOrder($event)"
        [orderedBy]="orderedBy"
    ></app-mobile-main-list>
</div>
<button mat-fab *ngIf="!showSurvey"
        class="questionnaire-btn"
        (click)="onSurveyRequest()"
></button>
<app-mobile-people-request-send-resend
    *ngIf="showSurvey"
    (goBack)="onSurveyRequest()"
></app-mobile-people-request-send-resend>
