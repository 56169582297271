<form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <table id="table_{{formDefinition.name}}" class="formTable">
        <ng-template ngFor let-column [ngForOf]="formDefinition.structure" let-i="index">
            <ng-template ngFor let-line [ngForOf]="column" let-j="index">
                <tr id="{{formDefinition.name}}-l{{i}}"><!-- *ngFor="let item of line; let j = index"-->
                    <ng-template ngFor let-item [ngForOf]="line" let-k="index">
                        <ng-template [ngIf]="item && item.display.type==='pureHtml'">
                            <td id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-blank-html"
                                [attr.colspan]="item.options.colspan"
                            >
                                <span *ngIf="item.name" [innerHTML]="language ? item.name[language] : item.name"></span>
                            </td>
                        </ng-template>
                        <ng-template [ngIf]="item && item.display.type==='pureHtml_a'">
                            <td id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-blank-html-a"
                                [attr.colspan]="item.options.colspan"
                            >
                                <a><span *ngIf="item.name" [innerHTML]="language ? item.name[language] : item.name"></span></a>
                            </td>
                        </ng-template>
                        <ng-template [ngIf]="item && (item.display.type!=='pureText' || item.display.type!=='pureHtml')">
                            <!-- IF NOT mergeD AND NOT reverse => text for the input-->
                            <td *ngIf="columnDisplay(item)"
                                id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-left_field"
                                [attr.colspan]="(item.options) ? item.options['text-colspan'] : null"
                                [class.formWarning]="warning(form,item,'text')"
                            >
                                {{ item.display.text }}
                            </td>
                            <!-- IF merged USE colspan ELSE use input-colspan-->
                            <td id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-middle_field"
                                [attr.colspan]="(item.options && item.options.merge) ?
                                    item.options['colspan'] : (
                                        (item.options) ? item.options['input-colspan'] : null
                                    )"
                            >
                                <!-- if merge AND NOT reverse, display name -->
                                <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-left_text"
                                     class="formInnerDiv"
                                     *ngIf="item.options !== undefined && (!item.options.reverse && item.options.merge)"
                                     [class.formWarning]="warning(form,item,'text')"
                                >
                                    {{ item.display.text }}
                                </div>
                                <!-- end text for the input -->
                                <!-- start input definition -->
                                <div [class.formInnerDiv]="item.display.type!=='submit'">
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-wrap"
                                         *ngIf="item.display.type==='text' || item.display.type==='password'">
                                        <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-tbox_container">
                                            <!-- textbox/password -->
                                            <mat-form-field class="full-width">
                                                <input matInput
                                                       appTextInput
                                                       [definition]="item"
                                                       placeholder="{{ language ? item.name_ml[language] : item.name }}"
                                                       [type]="item.display.type==='password' && hide ? 'password' : 'text'"
                                                       [formControlName]="item.name"
                                                       [class.borderWarning]="warning(form, item, 'border')"
                                                >
                                                <mat-icon
                                                    matSuffix
                                                    *ngIf="item.display.type==='password'"
                                                    (click)="hide = !hide"
                                                >{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </mat-form-field>
                                        </div>
                                        <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-tbox_warning"
                                             class="formWarning"
                                             *ngIf="warning(form, item, 'below')"
                                        >
                                            {{ item.display.warningText }}
                                        </div>
                                    </div>
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-cbox_container"
                                         *ngIf="item.display.type==='checkbox'"
                                         class="padding10"
                                    >
                                        <!-- checkbox -->
                                        <mat-checkbox
                                            appCheckboxInput
                                            [definition]="item"
                                            [formControlName]="item.name"
                                        >
                                            {{ language ? item.display.text[language] : item.display.text }}
                                        </mat-checkbox>
                                    </div><!-- Checkbox don't typically require validation... -->
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-radio_container"
                                         *ngIf="item.display.type==='radio'"
                                    >
                                        <div>
                                            <ng-template ngFor let-items [ngForOf]="item.display.list" let-l="index">
                                                <!-- radio -->
                                                <mat-form-field class="full-width">
                                                    <mat-radio-button
                                                        appRadioInput
                                                        [definition]="items"
                                                        id="{{formDefinition.name}}-l{{i}}-in{{j}}{{k}}&#45;&#45;radio_{{l}}"
                                                        [formControlName]="item.name"
                                                    >{{ items.text }}</mat-radio-button>
                                                </mat-form-field>
                                            </ng-template>
                                        </div>
                                        <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-radiobox_warning"
                                             class="formWarning"
                                             *ngIf="warning(form, item, 'below')">
                                            {{ item.display.warningText }}
                                        </div>
                                    </div>
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-select_container"
                                         *ngIf="item.display.type==='select'"
                                    >
                                        <div>
                                            <!-- select -->
                                            <mat-form-field class="full-width">
                                                <select
                                                    matNativeControl
                                                    appSelectInput
                                                    [definition]="item"
                                                    [formControlName]="item.name"
                                                >
                                                    <option appOptionInput
                                                            *ngFor="let option of item.display.options"
                                                            [definition]="option"
                                                    >{{ option.text }}</option>
                                                </select>
                                            </mat-form-field>
                                        </div>
                                        <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-select_warning"
                                             class="formWarning"
                                             *ngIf="warning(form, item, 'below')">
                                            {{ item.display.warningText }}
                                        </div>
                                    </div>
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-submit_button"
                                         *ngIf="item.display.type==='submit'"
                                         class="submitButton"
                                    >
                                        <button mat-raised-button
                                                type="submit"
                                                [color]="'black'"
                                                [disabled]="form.touched && !form.valid"
                                        >{{
                                            language ? item.display.textButton[language] :  item.display.textButton }}
                                        </button>
                                    </div>
                                    <div id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-submit_login_button"
                                         *ngIf="item.display.type==='submit_login' && !submitLoginBtn"
                                         class="submitButton"
                                    >
                                        <button mat-raised-button
                                                type="submit"
                                                [color]="'black'"
                                                [disabled]="form.touched && !form.valid"
                                        >
                                            <img alt="login" src="{{buttonsFolder}}login.png" class="submitIcon"/>
                                            {{ language ? item.display.textButton[language] :  item.display.textButton }}
                                        </button>
                                    </div>
                                </div>
                                <!-- end of input definition -->
                                <div class="formClear"></div>
                            </td>
                            <!-- if NOT merge AND reverse, set name -->
                            <td id="{{formDefinition.name}}-{{i}}-{{j}}-{{k}}-right_field"
                                *ngIf="item.options !== undefined && (item.options.reverse && !item.options.merge)"
                                [attr.colspan]="(item.options) ? item.options['text-colspan'] : null"
                                [class.formWarning]="warning(form, item, 'text')">
                                {{ language ? item.display.text[language] :  item.display.text }}
                            </td>
                            <!-- stop text for the input -->
                        </ng-template>
                    </ng-template>
                </tr>
            </ng-template>
        </ng-template>
    </table>
    <button *ngIf="submitLoginBtn"
            mat-raised-button
            type="submit"
            [color]="'black'"
            [disabled]="form.touched && !form.valid"
    >
        <img alt="login" src="{{buttonsFolder}}login.png" class="submitIcon"/>
        {{ language ? submitLoginBtn.textButton[language] :  submitLoginBtn.textButton }}
    </button>
</form>
