<h4 *ngIf="status === 'new'" class="modal-title">{{ 'jobs.sidebar.fillQuickRA' |
    translate }}</h4>
<h4 *ngIf="status === 'edit'" class="modal-title">{{ 'jobs.sidebar.editQuickRA' | translate }}</h4>
<div id="scoreBlock">
    <div>
        {{ 'jobs.sidebar.enterQuickRAScore' | translate }}
    </div>
    <div [class.error]="RAScore > 9 ? ' error' : null">
        <mat-form-field class="scoreContainerField">
            <label>
                <input matInput
                       class="scoreContainer"
                       type="text"
                       [(ngModel)]="RAScore"
                       (ngModelChange)="refreshGraph()"
                       (keydown)="checkPressedKey($event)"
                />
            </label>
        </mat-form-field>
    </div>
</div>
<div id="graphBlock">
    <img alt="graph" src="{{assetsFolder}}nCurve.png" />
    <app-iac-graph
        [normalizedScore]="null"
        [jobScore]="RAScore"
        [mobile]="true"
    ></app-iac-graph>
</div>
<mat-grid-list cols="1" rowHeight="60px">
    <mat-grid-tile
        class="buttonsTile"
        colspan="1"
        rowspan="1">
        <button mat-stroked-button
                type="button"
                class="modalButton"
                (click)="finishIac()"
        >{{ 'commons.cancel' | translate }}</button>
        <button mat-stroked-button
                type="submit"
                class="modalButton"
                [disabled]="RAScore > 9"
                (click)="finishIac('save')"
        >{{ 'commons.save' | translate }}</button>
    </mat-grid-tile>
</mat-grid-list>
