<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'admin.moveCreditsModal.creditTransfer' | translate }}</h4>
    <div
        class="pull-left text-danger"
        style="margin-left: 15px; margin-top: 2px"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<form [formGroup]="moveCreditForm" (ngSubmit)="onSubmit(moveCreditForm)"
      #moveCreditNgForm="ngForm">
    <div class="modal-body">
        <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
        <div class="form-group">
            <!-- subAccountFrom -->
            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(moveCreditForm.controls.subAccountFrom, moveCreditNgForm, submitted)"
                >
                    <label for="subAccountFromSelect"
                           class="field_title">{{ 'admin.moveCreditsModal.subAccountOrigin' | translate
                        }}</label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(moveCreditForm.controls.subAccountFrom, moveCreditNgForm)"
                        class="text-danger"
                    >
                        {{ 'commons.sepcSubAccount' | translate }}
                    </small>
                </div-->
                <div class="clearfix"></div>
                <select class="form-control"
                        id="subAccountFromSelect"
                        formControlName="subAccountFrom"
                        (change)="displayMaxCredits()"
                >
                    <option value=""></option>
                    <option *ngFor="let subAcct of account['subAccounts']" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <!-- subAccountTo -->
            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(moveCreditForm.controls.subAccountTo, moveCreditNgForm, submitted)"
                >
                    <label for="subAccountToSelect"
                           class="field_title">{{ 'admin.moveCreditsModal.subAccountTarget' | translate
                        }}</label>
                </div>
                <!--div class="pull-right">
                    <small
                        *ngIf="validateFormField(moveCreditForm.controls.subAccountTo, moveCreditNgForm)"
                        class="text-danger"
                    >
                        {{ 'commons.sepcSubAccount' | translate }}
                    </small>
                </div-->
                <div class="clearfix"></div>
                <select class="form-control" id="subAccountToSelect" formControlName="subAccountTo">
                    <option value=""></option>
                    <option *ngFor="let subAcct of account['subAccounts']" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <!-- credits -->
            <div>
                <div>
                    <div class="pull-left"
                         [class.text-danger]="validateFormField(moveCreditForm.controls.credits, moveCreditNgForm, submitted)"
                    >
                        <label class="field_title">
                            {{ 'admin.moveCreditsModal.creditsToTransfer' | translate }}
                        </label>
                    </div>
                    <div class="pull-right">
                        <label for="creditsInput"
                               *ngIf="doDisplayMaxCredits"
                               [class.text-danger]="lowCredits"
                        >
                            (max. {{ fromCredits }} credits)
                        </label>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <input id="creditsInput"
                       type="text"
                       class="form-control"
                       formControlName="credits"
                       (blur)="computeRemainingCredits()"
                >
            </div>
            <!-- clientId -->
            <input type="hidden" formControlName="clientId">
        </div>
    </div>
    <div class="modal-footer">
        <div class="pull-right" style="margin-left: 10px">
            <button
                type="submit"
                class="modalButton pull-right"
                [disabled]="(!moveCreditForm.valid && moveCreditNgForm.submitted) || this.lowCredits"
            >
                {{ 'commons.save' | translate }}
            </button>
            <button type="button" (click)="closeModal()" class="modalButton pull-right"
                    style="margin-right: 10px">
                {{ 'commons.cancel' | translate }}
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
</form>
