<ngx-loading [show]="loading" [config]="{
        backdropBorderRadius: '6px',
        primaryColour: '#CDCDCD',
        secondaryColour: '#ABABAB',
        tertiaryColour: '#CDCDCD'
        }"></ngx-loading>

<div class="modal-header">
    <h4 *ngIf="status!='edit'" class="modal-title pull-left">{{ 'rms.modal.newRightMatch' | translate }}</h4>
    <h4 *ngIf="status=='edit'" class="modal-title pull-left">{{ 'rms.modal.modRightMatch' | translate }}</h4>
    <div
            class="pull-left text-danger"
            style="margin-left: 15px; margin-top: 2px"
            *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>

<p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>

<form  [formGroup]="rightMatchForm" (ngSubmit)="onSubmit(rightMatchForm)" #rightMatchNgForm="ngForm">
    <div class="modal-body">
        <div class="headerText" *ngIf="headerText">
            {{ headerText | translate }}
        </div>
        <div class="form-group">

            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(rightMatchForm.controls.name, rightMatchNgForm, submitted)"
                >
                    <label class="field_title" for="rightMatchName">
                        {{ 'rms.modal.rightMatchName' | translate }}
                    </label>
                </div>
                <div class="clearfix"></div>
                <input type="text" id="rightMatchName" class="form-control" formControlName="name">
            </div>

            <div>
                <div class="pull-left"
                     [class.text-danger]="validateFormField(rightMatchForm.controls.subAccount, rightMatchNgForm, submitted)"
                >
                    <label class="field_title" for="subAccountSelect">{{ 'commons.subAccount' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="subAccountSelect" formControlName="subAccount">
                    <option>{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">
                        {{subAcct.name}}
                    </option>
                </select>
            </div>

            <div>
                <div class="pull-left">
                    <label class="field_title" for="consultant">
                        {{ 'commons.requester' | translate }}
                    </label>
                </div>
                <div class="pull-right">
                </div>
                <div class="clearfix"></div>
                <input type="text" class="form-control" id="consultant" formControlName="consultant">
            </div>

            <div *ngIf="profilers">
                <div class="pull-left">
                    <label class="field_title" for="jobProfile">{{ 'rms.commons.jobProfile' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="jobProfile" formControlName="profiler">
                    <option value="">{{ 'commons.select' | translate }}</option>
                    <option *ngFor="let prof of profilers" [value]="prof.id">
                        {{prof['jobTitle']}}
                    </option>
                </select>
            </div>

            <!-- department -->
            <div *ngIf="departments">
                <div class="pull-left">
                    <label class="field_title" for="division">{{ 'commons.division' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <select class="form-control" id="division" formControlName="department">
                    <option value="">{{ 'commons.none' | translate }}</option>
                    <option *ngFor="let dpt of departments" [value]="dpt.id">
                        {{dpt['fr']}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="submit"
            class="modalButton pull-right"
            [disabled]="!rightMatchForm.valid && rightMatchNgForm.submitted"
            style="margin-top: 15px"
        >
            <span *ngIf="status !== 'edit'">
            {{ 'commons.create' | translate }}
            </span>
            <span *ngIf="status === 'edit'">
            {{ 'commons.save' | translate }}
            </span>
        </button>
        <button (click)="closeModal()" type="button" class="modalButton pull-right"
                style="margin-top: 15px; margin-right: 15px"
        >
            {{ 'commons.cancel' | translate }}
        </button>
        <div class="clearfix"></div>
    </div>
</form>
