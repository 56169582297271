<ngx-loading
    [show]="displayLoader"
    [config]="loaderDisplay"
></ngx-loading>
<div [id]="(!new) ? 'accountContent' : 'newAccountContent'"
    *ngIf="(accountInfo && addressInfo && (new || accountService))"
>
    <div *ngIf="subAccountService.id != 0 && !new" class="pull-left" (click)="goToSubAccount0()"
         style="cursor: pointer">
        <img src="assets/buttons/leftArrow.png" alt="<-"/>
    </div>
    <div class="pull-left"
         [class.Margin14px]="subAccountService.id != 0 && !new"
         [class.LeftMargin0Margin14px]="subAccountService.id == 0 || new"
    >
        <span class="ngorange adminFormTitle">
            <ng-container *ngIf="!new; else newSubAccount">
                {{ accountService.accountName  }}
            </ng-container>
            <ng-template #newSubAccount>
                {{  'admin.accountForm.accountCreation' | translate }}
            </ng-template>
        </span>
    </div>
    <div class="clearfix"></div>

    <div class="pull-left">
        <h3>{{ 'admin.commons.accountInfo' | translate }}</h3>
    </div>
    <div class="pull-right" *ngIf="!new">
        <span *ngIf="subAccountService.id === 0 && accountService.canDeleteAccount" (click)="deleteAccount()"
              class="fakeLink">{{ 'admin.commons.deleteAccount' | translate }}</span>
        <span *ngIf="subAccountService.id !== 0 && accountService.canDeleteSubAccount"
              (click)="adminModalHandlingService.deleteSubAccount(subAccountService.id)"
              class="fakeLink">{{ 'admin.commons.deleteSubAccount' | translate }}</span>
    </div>
    <div class="clearfix"></div>

    <!-- first col -->
    <div *ngIf="accountInfo">
        <div class="col-md-6 accntInfo">
            <ng-container *ngFor="let info of accountInfo[0]">
                <app-administration-account-form-field
                    [info]="info"
                    [new]="new"
                    [missedFields]="missedFields"
                    (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                    [closeAllFieldsExcept]="closeAllFieldsExcept"
                    (editionsResult)="handleEdition('accountInfo', $event, info.fieldsName, new)"
                ></app-administration-account-form-field>
            </ng-container>
        </div>
        <!-- second col -->
        <div class="col-md-6 accntInfo">
            <ng-container *ngFor="let info of accountInfo[1]">
                <app-administration-account-form-field
                    [info]="info"
                    [new]="new"
                    [missedFields]="missedFields"
                    (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                    [closeAllFieldsExcept]="closeAllFieldsExcept"
                    (editionsResult)="handleEdition('accountInfo', $event, info.fieldsName, new)"
                ></app-administration-account-form-field>
            </ng-container>
        </div>
    </div>
    <div class="clearfix"></div>
    <a id="address"></a>
    <h3>{{ 'admin.commons.addressInfo' | translate }}</h3>
    <div *ngIf="addressInfo">
        <div class="col-md-6 accntInfo">
            <ng-container *ngFor="let info of addressInfo[0]">
                <app-administration-account-form-field
                    [info]="info"
                    [new]="new"
                    [missedFields]="missedFields"
                    (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                    [closeAllFieldsExcept]="closeAllFieldsExcept"
                    (editionsResult)="handleEdition('addressInfo', $event, info.fieldsName, new)"
                ></app-administration-account-form-field>
            </ng-container>
        </div>
        <div class="col-md-6 accntInfo">
            <ng-container *ngFor="let info of addressInfo[1]">
                <app-administration-account-form-field
                    [info]="info"
                    [new]="new"
                    [missedFields]="missedFields"
                    (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                    [closeAllFieldsExcept]="closeAllFieldsExcept"
                    (editionsResult)="handleEdition('addressInfo', $event, info.fieldsName, new)"
                ></app-administration-account-form-field>
            </ng-container>
        </div>
        <div class="clearfix"></div>
    </div>
    <ng-template #isSubAccount>
        <div>
            <a id="products"></a>
            <h3>{{ 'admin.commons.products' | translate }} &ndash; MPO</h3>
            <div class="col-md-6">
                <table *ngIf="!new && accountService.accountsTotalCredits" class="field">
                    <tr>
                        <td>
                            {{'admin.forms.subAccountTotalCredits' | translate}}
                        </td>
                        <td *ngIf="accountService && accountService.subAccountsCredits && accountService.subAccountsCredits[this.subAccountService.id]">
                            <ng-container
                                *ngIf="!accountService.subAccountsCredits[this.subAccountService.id].unlimited">
                                {{ accountService.subAccountsCredits[this.subAccountService.id].credits }}
                                <span *ngIf="translateService.currentLang === 'en'">credits</span>
                                <span *ngIf="translateService.currentLang === 'fr'">crédits</span>
                            </ng-container>
                            <ng-container
                                *ngIf="accountService.subAccountsCredits[this.subAccountService.id].unlimited">
                                <span *ngIf="translateService.currentLang === 'en'">Unlimited</span>
                                <span *ngIf="translateService.currentLang === 'fr'">Illimité</span>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-template>
    <ng-container *ngIf="isPrincipalSubAccount || (new === 'account'); else isSubAccount">
        <div>
            <a id="products"></a>
            <h3>{{ 'admin.commons.products' | translate }} &ndash; MPO</h3>
            <div class="col-md-6 accntInfo">
                <ng-container *ngFor="let info of mpoProductsDemo">
                    <app-administration-account-form-field
                        [info]="info"
                        [new]="new"
                        [missedFields]="missedFields"
                        (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                        [closeAllFieldsExcept]="closeAllFieldsExcept"
                        (editionsResult)="handleEdition('mpoProducts', $event, info.fieldsName, new)"
                    ></app-administration-account-form-field>
                </ng-container>
                <ng-container *ngFor="let info of mpoProducts[0]">
                    <app-administration-account-form-field
                        [info]="info"
                        [new]="new"
                        [missedFields]="missedFields"
                        (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                        [closeAllFieldsExcept]="closeAllFieldsExcept"
                        (editionsResult)="handleEdition('mpoProducts', $event, info.fieldsName, new)"
                    ></app-administration-account-form-field>
                </ng-container>
                <table *ngIf="!new && accountService.accountsTotalCredits" class="field">
                    <tr>
                        <td>
                            {{'admin.forms.accountsTotalCredits' | translate}}
                        </td>
                        <td>
                            <ng-container *ngIf="accountService.accountsTotalCredits !== 'unlimited'">
                                {{ accountService.accountsTotalCredits }}
                                <span *ngIf="translateService.currentLang === 'en'">credits</span>
                                <span *ngIf="translateService.currentLang === 'fr'">crédits</span>
                            </ng-container>
                            <ng-container *ngIf="accountService.accountsTotalCredits === 'unlimited'">
                                <span *ngIf="translateService.currentLang === 'en'">Unlimited</span>
                                <span *ngIf="translateService.currentLang === 'fr'">Illimité</span>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-6 accntInfo">
                <ng-container *ngFor="let info of mpoProducts[1]">
                    <app-administration-account-form-field
                        [info]="info"
                        [new]="new"
                        [missedFields]="missedFields"
                        (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                        [closeAllFieldsExcept]="closeAllFieldsExcept"
                        (editionsResult)="handleEdition('mpoProducts', $event, info.fieldsName, new)"
                    ></app-administration-account-form-field>
                </ng-container>
                <table *ngIf="!new && accountService.accountsTotalCredits" class="field">
                    <tr>
                        <td>
                            {{'admin.forms.subAccountTotalCredits' | translate}}
                        </td>
                        <td>
                            <ng-container
                                *ngIf="!accountService.subAccountsCredits[this.subAccountService.id].unlimited">
                                {{ accountService.subAccountsCredits[this.subAccountService.id].credits }}
                                <span *ngIf="translateService.currentLang === 'en'">credits</span>
                                <span *ngIf="translateService.currentLang === 'fr'">crédits</span>
                            </ng-container>
                            <ng-container
                                *ngIf="accountService.subAccountsCredits[this.subAccountService.id].unlimited">
                                <span *ngIf="translateService.currentLang === 'en'">Unlimited</span>
                                <span *ngIf="translateService.currentLang === 'fr'">Illimité</span>
                            </ng-container>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
    <div class="clearfix"></div>
    <div *ngIf="isPrincipalSubAccount ||  (new === 'account')">
        <h3>
            Permissions
        </h3>
        <div class="col-md-6">
            <app-administration-account-form-field
                *ngFor="let info of permissions[0]"
                [info]="info"
                [new]="new"
                [missedFields]="missedFields"
                (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                [closeAllFieldsExcept]="closeAllFieldsExcept"
                (editionsResult)="handleEdition('permissions', $event, info.fieldsName, new)"
            ></app-administration-account-form-field>
        </div>
        <div class="col-md-6">
            <app-administration-account-form-field
                *ngFor="let info of permissions[1]"
                [info]="info"
                [new]="new"
                [missedFields]="missedFields"
                (closeAllAdminFields)="closeAllAdminFields(info.fieldsName)"
                [closeAllFieldsExcept]="closeAllFieldsExcept"
                (editionsResult)="handleEdition('permissions', $event, info.fieldsName, new)"
            ></app-administration-account-form-field>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>

    <!-- LISTs ------------------------------------------------------->

    <ng-container *ngIf="!new">
        <div>
            <!-- Users -->
            <a id="users"></a>
            <app-administration-common-list
                *ngIf="users && users.data"
                [createItemLabel]="heads['users']['createItemLabel']"
                [displaySearch]="true"
                [list]="users.data"
                [listTitle]="heads['users']['listTitle']"
                [listTitles]="heads['users']['listTitles']"
                [listMetaData]="[
                {value: 'firstName', type:'name'}, {value: 'principalSubAccountName', type:'string'},
                {value: 'role', type:'userRole'}, {value: 'jobTitle', type:'string'}, {value: 'status', type:'status'}
            ]"
                [listType]="'users'"
                (createItem)="onCreateUser()"
                (selectItem)="onSelectUser($event)"
            ></app-administration-common-list>

            <!-- SubAccounts -->
            <ng-container
                *ngIf="subAccountService.id === 0 || subAccountService.id === undefined"
            >
                <a id="subaccounts"></a>
                <app-administration-common-list
                    *ngIf="accountService.subAccounts"
                    [createItemLabel]="heads['subAccounts']['createItemLabel']"
                    [displaySearch]="true"
                    [list]="accountService.subAccounts"
                    [listTitle]="heads['subAccounts']['listTitle']"
                    [listTitles]="heads['subAccounts']['listTitles']"
                    [listMetaData]="[
                    {value: 'name', type:'string'},
                    {value: 'address', type:'address'},
                    {value: 'telephone', type:'string'},
                    {value: 'credits', type:'string'},
                    {value: 'creationDate', type:'date'}
                ]"
                    [listType]="'subaccounts'"
                    (createItem)="onCreateSubAccount()"
                    (selectItem)="onSelectSubAccount($event)"
                ></app-administration-common-list>
            </ng-container>
            <!-- Files -->
            <a id="files"></a>
            <app-administration-common-list
                [createItemLabel]="heads.files['createItemLabel']"
                [list]="assets"
                [listTitle]="heads.files['listTitle']"
                [listTitles]="heads.files['listTitles']"
                [listMetaData]="[
                {value: 'extension', type: 'extension'},
                {value: 'name', type:'string'},
                {value: 'attachedBy', type:'string'},
                {value: 'creationDate', type:'date'},
                {value: 'size', type:'string'}
            ]"
                [listType]="'files'"
                (createItem)="adminModalHandlingService.openFileModal({subAccountId: subAccountService.id})"
                (selectItem)="onSelectFile($event, 'clientAssets')"
                (deleteFile)="onDeleteFile($event)"
            ></app-administration-common-list>

            <!-- Purchases -->
            <!-- a id="purchases"></a>
            <app-administration-common-list
                *ngIf="accountService.purchases && accountService.purchases.data"
                [list]="accountService.purchases.data"
                [createItemLabel]="heads['purchases']['createItemLabel']"
                [listTitle]="heads['purchases']['listTitle']"
                [listTitles]="heads['purchases']['listTitles']"
                [listMetaData]="[
                {value: 'sku', type:'sku'},
                {value: 'amount', type:'string'},
                {value: 'date', type:'date2'}
            ]"
                [listType]="'purchases'"
                (createItem)="adminModalHandlingService.openPurchaseProductsModal(subAccountService.id)"
            ></app-administration-common-list-->

            <!-- ActionLogs -->
            <!-- a id="history"></a>
            <app-administration-common-list
                *ngIf="actionLogs"
                [list]="actionLogs.data"
                [listTitle]="heads.history['listTitle']"
                [listTitles]="heads.history['listTitles']"
                [listMetaData]="[
                {value: 'message', type:'string'},
                {value: 'creationDate', type:'date2'}
            ]"
                [listType]="'history'"
                (onLastPage)="getMoreData($event)"
            ></app-administration-common-list-->
            <a id="transactions"></a>
            <app-administration-list-transactions
                [accountNumber]="accountService.accountNumber"
                [subAccountNumber]="subAccountService.id"
            ></app-administration-list-transactions>


            <!-- Departments -->
            <a id="departments"></a>
            <app-administration-common-list
                *ngIf="accountService.departments"
                [createItemLabel]="heads['department']['createItemLabel']"
                [list]="accountService.departments.data"
                [listTitle]="heads['department']['listTitle']"
                [listTitles]="heads['department']['listTitles']"
                [listMetaData]="[
                    {value: 'fr', type:'string'}
                ]"
                [listType]="'departments'"
                (createItem)="adminModalHandlingService.openDepartmentModal()"
                (selectItem)="adminModalHandlingService.openDepartmentModal($event,'edit')"
            ></app-administration-common-list>
        </div>
    </ng-container>
    <div *ngIf="new" class="col-md-12">
        <div class="pull-right">
            <button
                type="submit"
                class="modalButton pull-right"
                style="margin-top: 15px"
                [disabled]="submitDisable"
                (click)="onSubmit()"
            >
                {{ 'commons.save' | translate }}
            </button>
            <button
                type="button"
                class="modalButton pull-right"
                style="margin-top: 15px; margin-right: 15px"
                (click)="goBack()"
            >
                {{ 'commons.cancel' | translate }}
            </button>
        </div>
    </div>
</div>
