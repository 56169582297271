<h4 class="modal-title">{{ 'people.commons.modIndividual' | translate }}</h4>
<form [formGroup]="peopleEditForm" (ngSubmit)="onSubmit(peopleEditForm)" class="peopleEditForm">
    <label for="firstName">{{ 'commons.firstName' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="firstName" formControlName="firstName">
        <small *ngIf="peopleEditForm.controls.firstName.errors &&
                                    (peopleEditForm.controls.firstName.dirty ||
                                    peopleEditForm.controls.firstName.touched)"
               class="text-danger"
        >
            <span [hidden]="!peopleEditForm.controls.firstName.errors['minlength']">
                {{ 'forms.warnings.warnMin2Chars' | translate }}
            </span>
            <span [hidden]="!peopleEditForm.controls.firstName.errors['required']">
                {{ 'commons.firstNameMandatory' | translate }}
            </span>
        </small>
    </mat-form-field>

    <label for="lastName">{{ 'commons.lastName' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="lastName" formControlName="lastName">
        <small *ngIf="peopleEditForm.controls.lastName.errors &&
                      (peopleEditForm.controls.lastName.dirty ||
                      peopleEditForm.controls.lastName.touched)"
               class="text-danger"
        >
            <span [hidden]="!peopleEditForm.controls.lastName.errors['minlength']">
                {{ 'forms.warnings.warnMin2Chars' | translate }}
            </span>
            <span [hidden]="!peopleEditForm.controls.lastName.errors['required']">
                {{ 'commons.lastNameMandatory' | translate }}
            </span>
        </small>
    </mat-form-field>

    <label for="email">{{ 'commons.email' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="email" formControlName="email">
    </mat-form-field>
    <div *ngIf="recordStatus">
        <label>{{ 'people.commons.status' | translate }}</label>
        <mat-form-field appearance="fill">
            <mat-select formControlName="recordType">
                <mat-option *ngFor="let status of recordStatus" [value]="status.value">
                    {{ 'people.commons.' + status.value | translate }}
                </mat-option>
            </mat-select>
            <small *ngIf="peopleEditForm.controls.recordType.errors &&
                                  (peopleEditForm.controls.recordType.dirty ||
                                  peopleEditForm.controls.recordType.touched)"
                   class="text-danger"
            >
                {{ 'people.commons.statusMandatory' | translate }}
            </small>
        </mat-form-field>
    </div>

    <label for="jobTitle">{{ 'people.commons.title' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="jobTitle" formControlName="jobTitle">
    </mat-form-field>

    <label>{{ 'people.requestModal.job' | translate }}</label>
    <mat-form-field appearance="fill">
        <mat-select formControlName="jobId">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let job of jobProfiles" [value]="job.id">{{ job['jobTitle'] }}</mat-option>
        </mat-select>
    </mat-form-field>

    <label for="organisation">{{ 'people.commons.organisation' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="organisation" formControlName="organisation">
    </mat-form-field>

    <label>{{ 'commons.subAccount' | translate }}</label>
    <mat-form-field appearance="fill" *ngIf="sessionStructure && person.id">
        <mat-select formControlName="subAccount">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let subAcct of sessionStructure['subAccounts']" [value]="subAcct.id">{{ subAcct.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <label>{{ 'commons.division' | translate }}</label>
    <mat-form-field appearance="fill" *ngIf="departments">
        <mat-select formControlName="department">
            <mat-option value=""></mat-option>
            <mat-option *ngFor="let dpt of departments" [value]="dpt.id">{{dpt['fr']}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="peopleEditFooter justifyRight">
        <mat-grid-list cols="1" rowHeight="50px">
            <mat-grid-tile colspan="1" rowspan="1">
                <button
                    mat-stroked-button
                    type="button"
                    (click)="closeEditPanel()"
                    class="modalButton"
                    style="margin-right: 15px"
                >{{ 'commons.cancel' | translate }}
                </button>
                <button
                    mat-stroked-button
                    type="submit"
                    class="modalButton"
                    [disabled]="!peopleEditForm.valid || emptyName"
                >{{ 'commons.save' | translate }}
                </button>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</form>
