<ng-sidebar-container style="position: absolute; height: 100vh">

    <!-- A sidebar -->
    <ng-sidebar
        [position]="'right'"
        [(opened)]="jobSatisfactionList._opened"
        [animate]="false"
        [closeOnClickOutside]="false"
        (openedChange)="onSidebarStatusChange($event)"
        [autoFocus]="false"
        #gSidebar
    >
        <div>
            <div class="outerColumnGrey col-md-4" [style.width]="sideWidth" style="overflow: scroll">
                <div class="innerColumnGrey" [style.width]="sideWidth">

                    <div *ngIf="!jobSatisfactionList.sideBarStatus || jobSatisfactionList.sideBarStatus=='recentActivities' || jobSatisfactionList.sideBarStatus==''">
                        <app-infopanel [width]="sideWidth"></app-infopanel>
                    </div>

                    <div *ngIf="jobSatisfactionList.sideBarStatus=='searchFilters'">
                        <app-job-satisfactions-list-search-filters
                            [whereAmI]="'filter'"
                            [width]="sideWidth"
                            (switchOff)="jobSatisfactionList.switchOffFilterPanelIndicator($event)"
                        ></app-job-satisfactions-list-search-filters>
                    </div>

                    <div *ngIf="jobSatisfactionList.sideBarStatus=='reports'">
                        <app-job-satisfactions-sidebar
                            [width]="sideWidth"
                            [config]="'list'"
                            (closeSidebar)="closeJobSatisfactionSidebar($event)"
                        ></app-job-satisfactions-sidebar>
                    </div>
                    <div *ngIf="jobSatisfactionList.sideBarStatus=='clickMode'">
                        <app-job-satisfactions-selected-items
                            (switchOff)="jobSatisfactionList.switchOffFilterPanelIndicator($event, true)"
                            [width]="sideWidth"
                            (actionOnRecords)="actOnRecords($event)"
                        ></app-job-satisfactions-selected-items>
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar>

    <div ng-sidebar-content
         (window:resize)="onResize($event)"
         (window:orientationchange)="onResize($event)"
         style="overflow-x: hidden"
    >
        <div class="rightWrapper row right-wrapper-tablet"

             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"

             (scrolled)="jobSatisfactionList.listScrolled()"
        >
            <div class="outerColumnWhite"
                 [class.col-md-8]="!smallSize"
                 [class.clear]="!smallSize"
                 [style.width]="(!smallSize) ? listWidth : null"
            >
                <div
                    id="topMenuWrapper"
                    class="affix"
                    [class.topMenuCalcWidth]="smallSize"
                    [style.width]="(!smallSize) ? listWidth : null"
                >
                    <div>
                        <div class="pull-left">
                            <h1 class="sTitle">
                                <span *ngIf='!jobSatisfactionList.archive'>{{ 'commons.jobSatisfaction' | translate }}</span>
                                <span *ngIf='jobSatisfactionList.archive'>{{ 'commons.archives' | translate }}</span>
                            </h1>
                        </div>
                        <div class="pull-right">
                            <app-main-list-search-bar
                                [switchOff]="jobSatisfactionList.switchOff"
                                [selectionByFilters]="jobSatisfactionList.selectionByFilters"
                                (searchEvent)="setSearchFilter($event)"
                                (filterPanel)="jobSatisfactionList.toggleFilterPanel()"
                            ></app-main-list-search-bar>
                            <div class="clearfix"></div>
                            <div class="searchResults">
                                <span
                                    style="font-weight:
                                    bold" *ngIf="jobSatisfactionList.selectionDone && jobSatisfactionList.totalListItems !== null"
                                >
                                    {{ jobSatisfactionList.totalListItems }}
                                    {{ jobSatisfactionList.resMention | translate }}
                                    <span *ngIf="jobSatisfactionList.totalItems">
                                        {{ 'commons.of' | translate }} {{jobSatisfactionList.totalItems}}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div id="subMenu">
                        <div class="pull-left" id="subMenuLeft">

                            <div>
                                <table class="nav nav-topbar">
                                    <tr class="topMenu">
                                        <td *ngIf="!jobSatisfactionList.archive">
                                            <button class="addItem-btn" (click)="jobSatisfactionModalHandlingService.openJobSatisfactionModal({status:'new'})">
                                                <i class="material-icons">add</i>
                                                <span class="addItem-title">{{ 'commons.nouvelle' | translate }}</span>
                                            </button>
                                        </td>
                                        <td [class.clickMode]="jobSatisfactionList.clickMode">
                                            <a>
                                                <div (click)="jobSatisfactionList.switchMode()">
                                                    <img src="assets/header/comparer/ico_comparer.png" alt=""/>
                                                    <div>
                                                        {{ 'commons.toSelect' | translate }}
                                                    </div>
                                                    <img src="assets/header/comparer/ico_comparer_selected.png" alt=""/>
                                                </div>
                                            </a>
                                        </td>
                                        <td (click)="refreshList()">
                                            <a>
                                                <div style="padding-top: 2px">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
                                                        <path
                                                            fill="#AAA"
                                                            d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99
                                                        8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6
                                                        6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path></svg>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div *ngIf='!jobSatisfactionList.archive'>
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right"
                                    [class.typeSelected]="true"
                                >
                                    <div>{{ 'commons.jobSatisfaction' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/rightmatch/list'])"
                                >
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasCommunication"
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/idwmatrix/list'])"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasPersonality && stateService.hasJobAccess"
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/graphboard/list'])"
                                >
                                    <div>{{ 'commons.graphboard' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="jobSatisfactionList.archive">
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right typeSelected"
                                >
                                    <div>{{ 'commons.jobSatisfaction' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasCommunication"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/idwmatrix']"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                    *ngIf="stateService.hasPersonality"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/graphboard']"
                                >
                                    <div>{{ 'commons.graphboard' | translate }} </div>
                                </div>
                                <div
                                    *ngIf="stateService.hasJobAccess"
                                    class="pull-right"
                                    [routerLink]="['/archives/list/jobs']"
                                >
                                    <div>{{ 'commons.jobs' | translate }}</div>
                                </div>
                                <div
                                    class="pull-right"
                                    [routerLink]="['/archives/list/people']"
                                >
                                    <div>{{ 'commons.individus' | translate }}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div *ngIf="jobSatisfactionList.toList" [style.height]="(jobSatisfactionList.toList['count']==0) ? '700px': null" id="listWrap">
                    <ngx-loading
                        [show]="jobSatisfactionList.loading"
                        [config]="loaderDisplay"
                    ></ngx-loading>
                    <app-main-list
                        [small]="smallSize"
                        [forceWidthTo]="listWidth.substring(0, listWidth.length - 2)"
                        [selectedItemId]="jobSatisfactionAnalysis.id"
                        [tableDefinition]="jobSatisfactionList.tableDefinition"
                        [view]="jobSatisfactionList.state['listView']"
                        [clickMode]="jobSatisfactionList.clickMode"
                        [listsData]="jobSatisfactionList.toList"
                        [actualModule]="'jobSatisfaction'"
                        [archive]="jobSatisfactionList.archive"
                        (listScrolled)="jobSatisfactionList.listScrolled()"
                        (selectedItem)="itemSelected($event)"
                        (reordering)="setOrder($event)"
                    ></app-main-list>
                </div>
            </div>
        </div>
    </div>
</ng-sidebar-container>
