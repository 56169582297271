import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {TranslateService} from '@ngx-translate/core';
import {IMyDateModel, IMyDpOptions} from 'mydatepicker';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '../../../core/services/state/state.service';
import {FormsHelper} from '../../helpers/FormsHelper';
import * as _ from 'lodash';
import {ApiAdministrationService} from '../../services/api-administration.service';

@Component({
    selector: 'app-administration-account-form-field',
    templateUrl: './administration-account-form-field.component.html',
    styleUrls: ['./administration-account-form-field.component.scss']
})
export class AdministrationAccountFormFieldComponent implements OnChanges {

    @Input() info: any;
    @Input() new: any = false;
    @Input() missedFields: any = [];
    @Input() closeAllFieldsExcept: string;
    @Input() formHelper: any;

    pristine = true;

    error: boolean;

    @Output() closeAllAdminFields = new EventEmitter();
    @Output() editionsResult = new EventEmitter();

    editEnable = false;
    fieldsValue: any;
    fieldsValueText: any;

    fieldsName: string;
    fieldsType: string;
    fieldsExternalValue: any;
    editable = true;
    fieldsOptions: any = null; // Used in multiselect
    validation: any = null; // Used for validating user name.
    format: string = null; // Zip: CA H0H 0H0 US 12345, FR 00001 ||| Phone: CA/US 555 555-5555, FR 5 55 55 55 55

    myDatePickerOptions: {
        fr: IMyDpOptions,
        en: IMyDpOptions
    } = {
        fr: {dateFormat: 'dd/mm/yyyy'},
        en: {dateFormat: 'mm/dd/yyyy'}
    };
    currentDatePickerOptions;
    selDate;

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        public translate: TranslateService,
        protected cd: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes): void {
        if (changes) {
            if (changes.fieldsExternalValue && changes.fieldsExternalValue.currentValue !== changes.fieldsExternalValue.previousValue) {
                this.fieldsValue = changes.fieldsExternalValue.currentValue;
            } else if (
                changes.closeAllFieldsExcept && this.info &&
                changes.closeAllFieldsExcept.currentValue !== changes.closeAllFieldsExcept.previousValue &&
                changes.closeAllFieldsExcept.currentValue !== this.info.fieldsName
            ) {
                this.closeEdition();
                return;
            }
        }

        for (let input of ['fieldsName', 'fieldsValueText', 'fieldsType', 'fieldsExternalValue', 'editable', 'fieldsOptions', 'validation', 'format']) {
            this[input] = (this.info && (this.info[input] !== null || this.info[input] !== undefined)) ? this.info[input] : null;
        }
        if (this.fieldsName === 'parentAccountNumber') {
            let parentAccount = _.find(this.info.fieldsOptions.selectOptions, {value: this.fieldsValueText});
            this.fieldsValueText = (parentAccount) ? parentAccount.text[this.translate.currentLang] : null;
        }

        // Preserve initial value
        if (!this.new) {
            this.fieldsValue = this.fieldsExternalValue;
        } else if (this.pristine && ['mpoPersonality', 'mpoCommunication', 'mpoTalents', 'mpoSatellite', 'cai'].includes(this.fieldsName)) {
            this.fieldsValue = true;
            this.pristine = false;
        }

        // Specific input fields
        if (this.fieldsType === 'date-picker') {
            this.setDatePickerFields(this.translate.currentLang);
            // Make dates bilingual
            this.translate.onLangChange.subscribe(
                (e) => {
                    this.setDatePickerFields(e.lang);
                }
            );
        }
        if (this.new) {
            this.error = this.missedFields.includes(this.fieldsName);
        }
        this.cd.markForCheck();
    }

    setDatePickerFields(lang) {
        if (this.fieldsExternalValue && this.fieldsExternalValue[lang] && !this.new) {
            this.fieldsValue = <IMyDateModel>{jsdate: new Date(this.fieldsExternalValue[lang])};
        } else {
            this.fieldsExternalValue = null;
            this.fieldsValue = null;
        }

        this.currentDatePickerOptions = this.myDatePickerOptions[lang];

    }

    handleCountry(country) {
        let countryOutput = country;
        if (country === 'Canada') {
            countryOutput = 'Canada-Europe';
        } else {
            countryOutput = (this.translate.currentLang === 'fr') ? 'États-Unis' : 'USA';
        }
        return countryOutput;
    }

    setDatePickerFieldsToDisplay(lang, fieldsValue) {
        this.fieldsValueText = {
            fr: null,
            en: null
        };
        if (this.fieldsExternalValue !== null) {
            let prefix = (fieldsValue && fieldsValue.jsdate) ? fieldsValue.jsdate : fieldsValue;
            let dateArray =  prefix.toISOString().substring(0, 10).split('-');
            this.fieldsValueText = {
                fr: dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0],
                en: dateArray[1] + '/' + dateArray[2] + '/' + dateArray[0]
            };
        }
    }

    handleValueChange() {
        if (this.new) {
            this.editionsResult.emit(this.fieldsValue);
        }
    }

    updateField() {
        this.editionsResult.emit({
            fieldsName: this.fieldsName,
            fieldsValue: this.fieldsValue,
            fieldsType: this.fieldsType
        });
        // Set text value
        this.fieldsExternalValue = this.fieldsValue;
        switch (this.fieldsType) {
            case 'text':
                this.fieldsValueText = this.fieldsValue;
                break;
            case 'multiselect-dropdown':
                break;
            case 'date-picker':
                this.setDatePickerFieldsToDisplay(this.translate.currentLang, this.fieldsValue);
                break;
            case 'select':
                let value = FormsHelper.convertValueToDataValue(this.fieldsName, this.fieldsValue);
                if (this.fieldsName === 'productCodes') {
                    let optionsSet = _.find(this.fieldsOptions.selectOptions['Canada'], {sku: value});
                    if (!optionsSet) {
                        optionsSet = _.find(this.fieldsOptions.selectOptions['US'], {sku: value});
                    }
                    this.fieldsValueText = optionsSet.descriptive;
                } else if (this.fieldsName === 'administrator') {
                    this.fieldsValueText = value;
                } else {
                    this.fieldsValueText = _.find(this.fieldsOptions.selectOptions, {value: value}).text;
                }


                break;
            case 'checkbox':
                // Permission checkboxes
                this.fieldsValueText = FormsHelper.setCheckboxText(this.fieldsName, this.fieldsValue);
                break;
            default:
                break;
        }
        this.cd.markForCheck();
        this.closeEdition();
    }

    startEdition() {
        this.editEnable = true;
        this.closeAllAdminFields.emit(this.fieldsName);
    }

    cancelEditField() {
        this.closeEdition();
    }

    handleOptionTexts(text, title: any = false) {
        if (title) {
            if (text === 'subsidiary') {
                return (this.translate.currentLang === 'fr') ? 'SUBSIDIAIRE' : 'SUBSIDIARY';
            }
            if (text === 'distributor') {
                return (this.translate.currentLang === 'fr') ? 'DISTRIBUTEUR' : 'DISTRIBUTOR';
            }
            if (text === 'partner') {
                return (this.translate.currentLang === 'fr') ? 'PARTENAIRE' : 'PARTNER';
            }
        } else {
            return text.toUpperCase();
        }
    }

    closeEdition() {
        this.editEnable = false;
    }

    get isPermission() {
        return (this.fieldsName.split('_')[0] === 'permission');
    }

    get canEdit() {
        return this.stateService.session.sessionData.permissions.roles.includes('ROLE_SUPER_ADMIN') ?? false;
    }

}
