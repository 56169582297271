import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
    EventEmitter,
    OnInit,
    OnDestroy
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {PeopleSurveyRequestFormService} from '../../../../../../services/people-survey-request-form.service';
import {ApiPeopleService} from '../../../../../../services/api-people.service';
import {PeopleSurveyRequestHelper} from '../../../people-survey-request.helper';
import {takeUntil} from 'rxjs/operators';
import {PeopleSurveyRequestStep} from '../../../people-survey-request-step.enum';
import {SharedErrorHelper} from '../../../../../../../shared/helpers/error.helper';

@Component({
  selector: 'app-mobile-people-survey-request-form',
  templateUrl: './mobile-people-survey-request-form.component.html',
  styleUrls: ['./mobile-people-survey-request-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeopleSurveyRequestFormComponent implements OnInit, OnDestroy {
    @Input() surveyRequestFormGroup: FormGroup;
    @Input() sessionData: any;
    @Input() isFormSubmitted: boolean;
    @Input() researchAccount: any;

    @Output() cancelRequestForm = new EventEmitter();
    @Output() resetRequestForm = new EventEmitter();

    private onDestroy$: Subject<null> = new Subject();

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        private translateService: TranslateService,
        private apiPeopleService: ApiPeopleService
    ) { }

    /**
     * On cancel button click
     */
    public handleCancelRequestForm(): void {
        this.cancelRequestForm.emit();
    }

    /**
     * On reset button click
     */
    public handleResetRequestForm(): void {
        this.resetRequestForm.emit();
    }

    /**
     * Get requester data
     * Used to identify the requester
     *
     * @param formattedValues - formatted form values
     * @param sessionData - session data
     */
    private getRequesterData(formattedValues: any, sessionData: any): any {
        const requesterName = formattedValues.requestedByName || sessionData.userData.fullName;
        const requesterCompany = sessionData.structure.subAccounts[formattedValues.subAccount] ? sessionData.structure.subAccounts[formattedValues.subAccount].name : '';
        return {name: requesterName, company: requesterCompany};
    }

    /**
     * On submit button click
     */
    public submitSurveyRequestFormValues(): void {
        this.peopleRequestFormService.updateRequestFormSubmission(true);

        // Process if form is valid
        if (this.surveyRequestFormGroup.valid) {
            const isSatelliteOnly = PeopleSurveyRequestHelper.isOnlySatelliteSelected(this.surveyRequestFormGroup.get('reportGroup').value);
            const mergedValues = {...this.peopleRequestFormService.getPrbOnlyFormValues(), ...this.surveyRequestFormGroup.value};
            const formattedValues = PeopleSurveyRequestHelper.formatRequestValues(mergedValues, this.researchAccount);

            this.peopleRequestFormService.updateApiErrorMessage('');

            // Send the form data to the API
            this.apiPeopleService.evaluationRequest(formattedValues).pipe(takeUntil(this.onDestroy$)).subscribe(
                (tempUser: any) => {
                    // Add info for the temp user
                    const additionalUserData = {
                        variant: formattedValues.variant,
                        country: formattedValues.country,
                        language: formattedValues.language,
                        isSatelliteOnly: isSatelliteOnly,
                        fullName: `${formattedValues.firstName} ${formattedValues.lastName}`
                    };
                    this.peopleRequestFormService.updateTempUser({...tempUser, ...additionalUserData});

                    // Add requester data
                    this.peopleRequestFormService.updateExtraData(this.getRequesterData(formattedValues, this.sessionData));

                    // Request step
                    this.peopleRequestFormService.updateRequestStep(PeopleSurveyRequestStep.SendMethods);
                },
                (error: any) => {
                    const apiMessageError = `errors.${SharedErrorHelper.getErrorId(error.error.message)}`;
                    this.peopleRequestFormService.updateApiErrorMessage(apiMessageError);
                }
            );
        }
    }

    ngOnInit() {
        const options = {
            accountId: this.sessionData.accountData.id,
            subAccounts: this.sessionData.structure.subAccounts,
            languages: this.sessionData.structure.languages[this.translateService.currentLang],
            canCreateDitWithoutMpo: PeopleSurveyRequestHelper.canAccountTypeCreateDitWithoutMpo(this.sessionData.accountType)
        };

        // On form values change
        this.peopleRequestFormService.onValuesChange(this.surveyRequestFormGroup, this.onDestroy$, options);

        // Update default sub account
        if (this.sessionData.userData.hasOwnProperty('principalSubAccount')) {
            this.surveyRequestFormGroup.get('subAccount').setValue(`${this.sessionData.userData.principalSubAccount}`);
        }
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

}
