import { Component, ChangeDetectionStrategy, Input, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiPeopleService } from 'src/app/people/services/api-people.service';
import { PeopleSurveyRequestSendMethodsHelper} from '../send-methods.helper';
import { SharedErrorHelper } from 'src/app/shared/helpers/error.helper';
import { TextHelper } from '../../../../../../shared/misc/text.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-people-survey-request-send-method-ngenio-server',
    templateUrl: './ngenio-server.component.html',
    styleUrls: ['./ngenio-server.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestSendMethodNgenioServerComponent implements OnInit, OnDestroy {
    @Input() instructionsMessages: any;
    @Input() surveyLink: string;
    @Input() tempUserId: string;
    @Input() username: string;
    @Input() password: string;
    @Input() email: string;
    @Input() formSubmit$: Observable<any>;
    @Output() afterSubmission: EventEmitter<any> = new EventEmitter();

    public onDestroy$: Subject<any> = new Subject();
    public sendEmailFormGroup: FormGroup;
    public isFormSubmitted = false;
    public apiErrorMessage = '';
    public deactivateTa = false;
    public titleSep: string;
    public activateInstructions: string;

    constructor (
        private cd: ChangeDetectorRef,
        private apiPeopleService: ApiPeopleService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.titleSep = TextHelper.checkTitleSep(this.translate.currentLang);
        this.sendEmailFormGroup = this.getForm();
        this.activateInstructions = PeopleSurveyRequestSendMethodsHelper.copyPasteText(
            this.instructionsMessages, this.username, this.password, this.surveyLink.replace('%26', '&'), this.instructionsMessages.language
        );

        this.formSubmit$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.isFormSubmitted = true;
            this.apiErrorMessage = '';
            this.cd.markForCheck();

            if (this.sendEmailFormGroup.valid) {
                const formValues = this.sendEmailFormGroup.value;

                // Send email with API
                const isUserIdentified = formValues.isUserIdentified ? 1 : 0;
                this.apiPeopleService.emailEvaluationSurvey(
                    [this.tempUserId], [formValues.email, formValues.message, isUserIdentified]
                ).pipe(takeUntil(this.onDestroy$)).subscribe(
                    () => {
                        this.afterSubmission.emit('ngenio-server');
                    },
                    (error: any) => {
                        this.apiErrorMessage = `errors.${SharedErrorHelper.getErrorId(error.error.message)}`;
                        this.cd.markForCheck();
                    }
                );
            }
        });

        this.translate.onLangChange.subscribe(
            (res) => {
                if (res) {
                    this.titleSep = TextHelper.checkTitleSep(res.lang);
                }
            }
        );
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    private getForm(): FormGroup {
        return new FormGroup({
            email: new FormControl(this.email, [Validators.required, Validators.email]),
            subject: new FormControl(this.instructionsMessages.emailSubject, Validators.required),
            message: new FormControl(),
            isUserIdentified: new FormControl(false)
        });
    }
}
