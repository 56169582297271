<div *ngIf="graphBoard.id" (window:resize)="onResize($event)">
    <div
        [class.top]="isLeftAndNotTop"
        [class.affix]="isLeftAndNotTop"
        [class.tLeft]="!(config == 'list') && isLeftAndNotTop"
        [class.tRight]="config == 'list' && isLeftAndNotTop"
        [style.width]="width"
    >
        <div class="topHead">
            <div [class.navbar-fixed-top]="!isLeftAndNotTop" [class.topMenu]="!isLeftAndNotTop">
                <div [class.topHead]="isLeftAndNotTop">
                    <div class="pull-left">
                        <h2 class="name" *ngIf="graphBoard.name">{{ graphBoard.name.toUpperCase() }}</h2>
                    </div>
                    <div *ngIf="config == 'list'" (click)="goToNews()" class="x-close pull-right" style="font-size: 20pt">&#x00D7;</div>
                </div>
                <div *ngIf="urlPath !== 'graphboard'">
                    <span (click)="modStatus('archive', false)" class="fakeLink">
                        {{ 'commons.reactivate' | translate }} </span
                    >&nbsp;&nbsp;
                    <span *ngIf="canDelete" (click)="modStatus('delete')" class="fakeLink">
                        {{ 'commons.delete' | translate }}
                    </span>
                </div>

                <div *ngIf="config == 'list'; else reportFullButtons">
                    <div *ngIf="!actualModule.archive">
                        <app-nav-button (buttonActon)="goToFullReport(graphBoard.id)">
                            <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                            <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                        </app-nav-button>
                        <app-nav-button
                            [isBlank]="(!itemList || itemList['cIndividus'] == 0) && (!itemList || itemList['cProfiler'] == 0)"
                            (buttonActon)="onPdfShare()"
                        >
                            <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                            <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                        </app-nav-button>
                        <div class="pull-left dropdown" dropdown>
                            <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">Options&nbsp;<span class="caret"></span></div>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                                <li *ngIf="!actualModule.archive">
                                    <a (click)="openEditModal()">
                                        {{ 'commons.editInfo' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="urlPath == 'graphboard'" role="menuitem">
                                    <a *ngIf="!actualModule.archive" (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                                    <a *ngIf="actualModule.archive" (click)="modStatus('archive', false)">{{ 'commons.ractivate' | translate }}</a>
                                </li>
                                <li *ngIf="canDelete" role="menuitem">
                                    <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <ng-template #reportFullButtons>
                    <div *ngIf="!isLeftAndNotTop" style="margin-top: 40px"></div>
                    <div class="clearfix">
                        <app-nav-button (buttonActon)="goToList()">
                            <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                            <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                        </app-nav-button>
                        <app-nav-button
                            [isBlank]="(!itemList || itemList['cIndividus'] == 0) && (!itemList || itemList['cProfiler'] == 0)"
                            (buttonActon)="onPdfShare()"
                        >
                            <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                            <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                        </app-nav-button>
                        <div class="pull-left dropdown" dropdown>
                            <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">Options&nbsp;<span class="caret"></span></div>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                                <li *ngIf="!actualModule.archive">
                                    <a (click)="openEditModal()">
                                        {{ 'commons.editInfo' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="urlPath == 'graphboard'" role="menuitem">
                                    <a *ngIf="!actualModule.archive" (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                                    <a *ngIf="actualModule.archive" (click)="modStatus('archive', false)">{{ 'commons.reactivate' | translate }}</a>
                                </li>
                                <li *ngIf="canDelete" role="menuitem">
                                    <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </ng-template>

                <div *ngIf="isLeftAndNotTop">
                    <hr class="panelHr" />
                </div>
            </div>
            <div *ngIf="isLeftAndNotTop" style="height: 5px"></div>
            <div *ngIf="!isLeftAndNotTop" style="height: 105px"></div>
            <div class="infoWrap">
                <table class="infoTableWrap">
                    <tr>
                        <td class="switchButton">
                            <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                <span *ngIf="!informationVisibility">+</span>
                                <span *ngIf="informationVisibility">-</span>
                            </button>
                        </td>
                        <td>
                            <mat-tab-group>
                                <mat-tab label="{{ 'commons.informations' | translate }}">
                                    <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                        <tr>
                                            <td style="width: 37%">{{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                <span [class.na]="!graphBoard.name">
                                                    {{ graphBoard.name || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width: 37%">{{ 'commons.creationDate' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                <span [class.na]="!graphBoard.date">
                                                    {{ graphBoard.creationDate | date : dateFormat || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'commons.modificationDate' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                {{ graphBoard.date | date : dateFormat || notSpecified() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: top">{{ 'commons.description' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                <div [class.na]="!graphBoard.description" style="max-height: 5em; overflow-y: scroll">
                                                    {{ graphBoard.description || notSpecified() }}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                <span [class.na]="!graphBoard.subAccount">
                                                    {{ setSubAccountName(graphBoard.subAccount) || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span></td>
                                            <td>
                                                <span [class.na]="!graphBoard.department">
                                                    {{ graphBoard.department || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="clearfix" style="margin-bottom: 10px"></div>
            <div class="pull-left">
                <h2>{{ 'gbs.sidebar.elements' | translate }}</h2>
            </div>
            <div *ngIf="!actualModule.archive" (click)="openPersonModal()" class="modify pull-right"></div>
            <div class="clearfix"></div>

            <ul class="nav nav-tabs" style="border-bottom: 1px solid #ddd">
                <li [class.active]="graphBoardsList.graphboardTab == 'individus'">
                    <a class="tdnone" (click)="graphBoardsList.changeGraphBoardTab('individus')">{{ 'commons.individus' | translate }}</a>
                </li>

                <li [class.active]="graphBoardsList.graphboardTab == 'profiler'">
                    <a class="tdnone" (click)="graphBoardsList.changeGraphBoardTab('profiler')">{{ 'commons.job' | translate }}</a>
                </li>
            </ul>

            <div style="margin-top: 15px">
                <div *ngIf="graphBoardsList.graphboardTab == 'individus'">
                    <div *ngIf="itemList && itemList['cIndividus'] != 0">
                        <table id="individusListing">
                            <tr>
                                <th class="semiBold">{{ 'commons.name' | translate }}</th>
                                <th class="semiBold">{{ 'commons.traits' | translate }}</th>
                                <th class="semiBold">{{ 'commons.behavior' | translate }}</th>
                            </tr>
                            <ng-container *ngFor="let i of createRange(gActualPage['individus'], maxPerPage)">
                                <tr *ngIf="itemList['individus'][i]">
                                    <td>
                                        <a *ngIf="!actualModule.archive" (click)="goToPerson(itemList['individus'][i].id)"
                                            >{{ itemList['individus'][i]['firstName'] }} {{ itemList['individus'][i]['lastName'] }}</a
                                        >
                                        <span *ngIf="actualModule.archive"
                                            >{{ itemList['individus'][i]['firstName'] }} {{ itemList['individus'][i]['lastName'] }}</span
                                        >
                                    </td>
                                    <td><span *ngIf="itemList['individus'][i]['hasTrait']">✓</span></td>
                                    <td><span *ngIf="itemList['individus'][i]['hasPrb']">✓</span></td>
                                </tr>
                                <tr *ngIf="!itemList['individus'][i]">
                                    <td colspan="3">&nbsp;</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td colspan="3" style="padding-bottom: 8px">
                                    <div style="padding-bottom: 8px">
                                        <hr class="panelHr" />
                                    </div>
                                    <div *ngIf="gActualPage['individus'] < gMaxPage['individus']" class="pull-right" (click)="goToPage(1, 'individus')">
                                        <img src="/assets/buttons/next.png" alt=">" />
                                    </div>
                                    <div *ngIf="gActualPage['individus'] == gMaxPage['individus']" class="pull-right">
                                        <img src="/assets/buttons/next_deactivated.png" alt=">" />
                                    </div>
                                    <div *ngIf="gActualPage['individus'] > 1" class="pull-right" (click)="goToPage(-1, 'individus')">
                                        <img src="/assets/buttons/before.png" alt="<" />
                                    </div>
                                    <div *ngIf="gActualPage['individus'] == 1" class="pull-right">
                                        <img src="/assets/buttons/before_deactivated.png" alt="<" />
                                    </div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="!itemList || itemList['cIndividus'] == 0" style="padding-left: 15px">
                        {{ 'commons.none' | translate }}
                    </div>
                </div>
                <div *ngIf="graphBoardsList.graphboardTab == 'profiler'">
                    <div *ngIf="itemList && itemList['cProfiler'] != 0">
                        <table style="width: 100%">
                            <ng-container *ngFor="let i of createRange(gActualPage['profiler'], maxPerPage)">
                                <tr *ngIf="itemList['profiler'] && itemList['profiler'][i]">
                                    <td>
                                        <a *ngIf="!actualModule.archive" (click)="goToJob(itemList['profiler'][i].id, graphBoard.id, 'graphboard')">{{
                                            itemList['profiler'][i]['jobTitle']
                                        }}</a>
                                        <span *ngIf="actualModule.archive">{{ itemList['profiler'][i]['jobTitle'] }}</span>
                                    </td>
                                </tr>
                                <tr *ngIf="itemList['profiler'] && !itemList['profiler'][i]">
                                    <td colspan="3">&nbsp;</td>
                                </tr>
                            </ng-container>
                            <tr>
                                <td style="padding-bottom: 8px">
                                    <div style="padding-bottom: 8px">
                                        <hr class="panelHr" />
                                    </div>
                                    <div *ngIf="gActualPage['profiler'] < gMaxPage['profiler']" class="pull-right" (click)="goToPage(1, 'profiler')">
                                        <img src="/assets/buttons/next.png" alt=">" />
                                    </div>
                                    <div *ngIf="gActualPage['profiler'] == gMaxPage['profiler']" class="pull-right">
                                        <img src="/assets/buttons/next_deactivated.png" alt=">" />
                                    </div>
                                    <div *ngIf="gActualPage['profiler'] > 1" class="pull-right" (click)="goToPage(-1, 'profiler')">
                                        <img src="/assets/buttons/before.png" alt="<" />
                                    </div>
                                    <div *ngIf="gActualPage['profiler'] == 1" class="pull-right">
                                        <img src="/assets/buttons/before_deactivated.png" alt="<" />
                                    </div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div *ngIf="!itemList || itemList['cProfiler'] == 0" style="padding-left: 15px">
                        {{ 'commons.none' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
