import {
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EvaluationRequestNext} from 'src/app/people/models/evaluation-request-next.model';
import {emailValidator} from 'src/app/people/validators/email';
import {TranslateService} from '@ngx-translate/core';
import {WindowRef} from 'src/app/app/services/window-ref.service';
import {StateService} from '../../../../core/services/state/state.service';
import {EnvService} from '../../../../app/services/env.service';

@Component({
    selector: 'app-mobile-jobs-profiler-request-resend',
    templateUrl: './mobile-jobs-profiler-request-resend.component.html',
    styleUrls: ['./mobile-jobs-profiler-request-resend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileJobsProfilerRequestResendComponent implements OnInit {
    @Input() resendData;
    @Output() close = new EventEmitter();

    flipValue: number;

    toggle = 1;

    textToSend = '';
    nativeWindow: any;

    evaluationFormUrl: string;

    profilerRequestResend: EvaluationRequestNext = new EvaluationRequestNext();
    profilerResendForm: FormGroup;
    mailto: any;
    tempUser: any;
    person: any;
    country: string;
    language: string;
    currentLanguage: string;
    blurred = false;

    cutNPasteTexts: any;

    sessionData;
    countryList;

    constructor(
        private fb: FormBuilder,
        protected translate: TranslateService,
        private windowRef: WindowRef,
        public cd: ChangeDetectorRef,
        private stateService: StateService,
        private environment: EnvService
    ) {
        this.nativeWindow = windowRef.getNativeWindow();
        this.evaluationFormUrl = this.environment.evaluationFormUrl;
    }

    ngOnInit() {
        this.currentLanguage = this.translate.currentLang;
        this.sessionData = this.stateService.session ? this.stateService.session.sessionData : null;
        this.countryList = (this.sessionData &&
            this.sessionData.structure &&
            this.sessionData.structure.countries) ?
            this.sessionData.structure.countries[this.currentLanguage] : null;
        if (this.resendData) {
            this.initEvaluationResendForm();
            // resending API call
        }
    }

    setLang(lang, country) {
        if (lang === 'es') {
            if (country === 'us') {
                return 'en';
            } else {
                return 'fr';
            }
        }
        return lang;
    }

    initEvaluationResendForm() {
        this.profilerResendForm = this.fb.group({
                emailTo: [this.profilerRequestResend.emailTo],
                message: [(this.profilerRequestResend.message) ? this.profilerRequestResend.message : this.textToSend]
            },
            {
                validator: emailValidator('emailTo')
            }
        );
    }

    toggleFlip(n) {
        if (n !== '1') {
            this.toggle = n;
        }
        return;
    }

    closeForm() {
        this.toggle = 1;
        this.flipValue = 1;
        this.close.emit(true);
    }

}
