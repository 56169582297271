<div class="rightWrapper row" (window:resize)="onResize($event)" [style.min-width]="smallSize ? '862px' : '900px'">
    <div *ngIf="!smallSize" class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
    <div *ngIf="!smallSize" class="outerColumnGrey col-md-3">
        <div class="affix innerColumnGrey" [style.width]="sideWidth">
            <div style="margin-top: 30px">
                <div>&nbsp;

                    <h2 class="h2Pref" *ngIf="translateService.currentLang=='fr'">Préférences</h2>
                    <h2 class="h2Pref" *ngIf="translateService.currentLang=='en'">Preferences</h2>

                </div>
                <!--div class="pull-left sideBarButton" (click)="back()">
                    <div class="pull-left sideBarImageContainer">
                        <img src="assets/buttons/fullView.png"/>
                    </div>
                    <div class="pull-left">
                        {{ 'commons.back' | translate }}
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div-->
            </div>
            <div>
                <hr class="panelHr"/>
            </div>
            <div style="height: 5px;">&nbsp;</div>
            <div>
                <h3 *ngIf="translateService.currentLang=='fr'" class="h3link">
                    Affichage
                </h3>
                <h3 *ngIf="translateService.currentLang=='en'" class="h3link">
                    Display
                </h3>
            </div>
        </div>
    </div>
    <div class="outerColumnWhite col-md-9 clear" style="padding-top: 31px">
        <div id="mainSubject">
            <ul *ngIf="!stateService.session.hasSatelliteRole" class="nav nav-tabs"
                style="border-bottom: 1px solid #ddd;">
                <li [class.active]="preference == 'individus'">
                    <a class="tdnone" (click)="switchPreference('individus')">{{ 'commons.individus' | translate }}</a>
                </li>
                <li *ngIf="!isSender && stateService && stateService.hasJobAccess"
                    [class.active]="preference == 'jobs'">
                    <a class="tdnone" (click)="switchPreference('jobs')">{{ 'commons.jobs' | translate }}</a>
                </li>
                <li *ngIf="!isSender && stateService && !stateService.session.hasSatelliteRole && stateService.hasPersonality"
                    [class.active]="preference == 'rightMatch'">
                    <a class="tdnone"
                       (click)="switchPreference('rightMatch')">{{ 'commons.rightMatch' | translate }}</a>
                </li>
                <li *ngIf="!isSender && stateService && !stateService.session.hasSatelliteRole && stateService.hasCommunication"
                    [class.active]="preference == 'idwMatrix'">
                    <a class="tdnone" (click)="switchPreference('idwMatrix')">{{ 'commons.idwMatrix' | translate }}</a>
                </li>
                <li *ngIf="!isSender && stateService && !stateService.session.hasSatelliteRole && stateService.hasPersonality"
                    [class.active]="preference == 'graphboard'">
                    <a class="tdnone"
                       (click)="switchPreference('graphboard')">{{ 'commons.graphboard' | translate }}</a>
                </li>
                <li *ngIf="!isSender && stateService && !stateService.session.hasSatelliteRole && isAdmin"
                    [class.active]="preference == 'administration'">
                    <a class="tdnone"
                       (click)="switchPreference('administration')">{{ 'commons.administraton' | translate }}</a>
                </li>
            </ul>
            <div *ngIf="preference == 'individus'">
                <h2 class="title ngorange pull-left">{{ 'commons.individus' | translate }}</h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <!-- people -->
                <app-people-list-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-people-list-search-filters>
            </div>
            <div *ngIf="!isSender && preference == 'jobs' && (stateService && stateService.hasJobAccess)">
                <!-- jobs -->
                <h2 class="title ngorange pull-left">{{ 'commons.jobs' | translate }}</h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <app-jobs-list-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-jobs-list-search-filters>
                <div [style.width]="subComponentsWidth">
                    <hr/>
                </div>
            </div>
            <div *ngIf="!isSender && preference == 'rightMatch' && (stateService && stateService.hasPersonality)">
                <!-- rightMatch -->
                <h2 class="title ngorange pull-left">{{ 'commons.rightMatch' | translate }} </h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <app-right-matches-list-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-right-matches-list-search-filters>
                <div [style.width]="subComponentsWidth">
                    <hr/>
                </div>
            </div>
            <div *ngIf="!isSender && preference == 'idwMatrix' && (stateService && stateService.hasCommunication)">
                <!-- idwMatrix -->
                <h2 class="title ngorange pull-left">{{ 'commons.idwMatrix' | translate }} </h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <app-idw-matrix-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-idw-matrix-search-filters>
                <div [style.width]="subComponentsWidth">
                    <hr/>
                </div>
            </div>
            <div *ngIf="!isSender && preference == 'graphboard' && (stateService && stateService.hasPersonality)">
                <!-- graphboard -->
                <h2 class="title ngorange pull-left">{{ 'commons.graphboard' | translate }}</h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <app-graphboards-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-graphboards-search-filters>
            </div>
            <div *ngIf="!isSender && preference == 'administration' && isAdmin">
                <!-- administration -->
                <h2 class="title ngorange pull-left">{{ 'commons.administration' | translate }}</h2>
                <div *ngIf="loader" class="pull-left" style="padding-top: 12px">
                    <app-local-loader
                        [lang]="translateService.currentLang"
                        [state]="loader"
                    ></app-local-loader>
                </div>
                <app-administration-list-search-filters
                    [whereAmI]="'preferences'"
                    [width]="subComponentsWidth"
                    (selectPreference)="onSelectPreference($event)"
                ></app-administration-list-search-filters>
            </div>
        </div>
    </div>
</div>
