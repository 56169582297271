<div *ngIf="!addAnalysis">
    <div class="sticky0">
        <app-mobile-header
            [addAnalysis]="addAnalysis"
            [searchValue]="lastSearch"
            (reloadAction)="refreshList()"
            (searchAction)="setSearchFilter($event)"
        ></app-mobile-header>
    </div>
    <div class="searchResults">
        <span *ngIf="jobSatisfactionListService.selectionDone && jobSatisfactionListService.totalListItems !== null"
              style="font-weight: bold"
        >
            {{ jobSatisfactionListService.totalListItems }}
            {{ jobSatisfactionListService.resMention | translate }}
            <span *ngIf="jobSatisfactionListService.totalItems">
                {{ 'commons.of' | translate }} {{jobSatisfactionListService.totalItems}}
            </span>
        </span>
    </div>
    <h1 class="sectionTitle pb0">
        <span *ngIf='!jobSatisfactionListService.archive'>{{ 'app.navigation.analysis' | translate }}</span>
        <span *ngIf='jobSatisfactionListService.archive'>{{ 'commons.archives' | translate }}</span>
    </h1>
    <mat-tab-group
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="switchTab($event)"
    >
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.rightMatch' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasCommunication" label="{{ 'commons.idwMatrix' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.graphboard' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.jobSatisfaction' | translate }}"></mat-tab>
    </mat-tab-group>
    <div infiniteScroll
         [infiniteScrollDistance]="4"
         [infiniteScrollThrottle]="300"
         [scrollWindow]="false"
         (scrolled)="jobSatisfactionListService.listScrolled()"
         class="scrollableList"
    >
        <ngx-loading
            [show]="jobSatisfactionListService.loading"
            [config]="loaderDisplay"
        ></ngx-loading>
        <app-mobile-main-list
            *ngIf="jobSatisfactionListService.toList"
            [selectedItemId]="jobSatisfaction.id"
            [tableDefinition]="jobSatisfactionListService.state['tableDefinition']"
            [sectionName]="'analysis'"
            [subSection] = "'jobSatisfaction'"
            [listsData]="jobSatisfactionListService.toList"
            (selectedItem)="jobSatisfactionListService.itemSelected($event)"
            (reordering)="setOrder($event)"
            [orderedBy]="orderedBy"
        ></app-mobile-main-list>
    </div>
</div>
<button mat-fab *ngIf="!addAnalysis"
        class="newItem-btn"
        (click)="createNewJobSatisfaction()"
><i class="material-icons">add</i></button>
<div *ngIf="addAnalysis">
    <app-mobile-job-satisfactions-edit
        [status]="'new'"
        (close)="backToItemsList()"
    ></app-mobile-job-satisfactions-edit>
</div>
