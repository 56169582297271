<div *ngIf="records"
     class="rightWrapper row"
     (window:resize)="onResize($event)"
>
    <a id="top"></a>
    <ng-container *ngIf="!small">
        <div class="greyBackground gbrLeft" [style.width]="sideWidth"></div>
        <div class="outerColumnGrey col-md-4" [style.width]="(!small) ? sideWidth : null"
             style="overflow-y: auto; overflow-x: hidden">
            <div class="innerColumnGrey" [style.width]="sideWidth">
                <div *ngIf="jobSatisfaction.itemToDisplay">
                    <app-job-satisfactions-sidebar
                        [width]="sideWidth"
                        [config]="'report'"
                        (sortResults)="sortResults($event)"
                    ></app-job-satisfactions-sidebar>
                </div>
            </div>
        </div>
    </ng-container>
    <div
        [class.col-md-8]="!small"
        [class.col-md-12]="small"
        [style.padding-top]="(small) ? null : '40px'"
        [style.width]="(!small) ? reportWidth : null"
        id="scrollBody"
        style="min-width: 700px"
    >
        <div *ngIf="small && jobSatisfaction.itemToDisplay">
            <app-job-satisfactions-sidebar
                [tabletMode]="true"
                [width]="sideWidth"
                [config]="'report'"
                (sortResults)="sortResults($event)"
            ></app-job-satisfactions-sidebar>
        </div>
        <div id="reportPopUp" *ngIf="showJobSatisfactionPopup"
            [style.width]="(small) ? 'calc(100% - 400px)' : 'calc(100% - 800px)'"
        >
            <app-job-satisfaction-analysis-popup
                (closeJobSatisfactionPopup)="toggleJobSatisfactionPopup($event)"
            ></app-job-satisfaction-analysis-popup>
        </div>
        <!-- ul *ngIf="!small" class="nav nav-tabs" style="border-bottom: 1px solid #ddd;">
            <li class="tab" [class.active]="state.report['reportType']=='analysis'">
                <a class="tdnone" (click)="switchMain('analysis')">
                    {{ 'jobSatisfactions.report.jobSatisfactionAnalysisRegularCase' | translate}}
                </a>
            </li>
            <li class="tab" [class.active]="state.report['reportType']=='history'">
                <a class="tdnone" (click)="switchMain('history')">
                    {{ 'jobSatisfactions.report.history' | translate}}
                </a>
            </li>
        </ul-->
        <ng-container
            *ngIf="state.report['reportType']=='analysis'"
        >
            <div
                [style.padding-right]="(small) ? '15px' : 'auto'"
            >
                <h1 class="mainTitleRmSb floatLeft">{{ 'jobSatisfactions.report.jobSatisfactionAnalysisRegularCase' | translate }}</h1>
                <div class="floatRight smallTxt">
                    <table id="legend">
                        <tr>
                            <td>
                                <span
                                    style="font-family: MontserratSemiBold,  sans-serif">{{ 'jobSatisfactions.report.potential' | translate }}</span>
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    class="floatRight"
                                    [background]="'regular'"
                                    [score]="2"
                                    [size]="20"
                                    [id]="'legendGreen'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                {{ "jobSatisfactions.report.high" | translate }}
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    class="floatRight"
                                    [background]="'regular'"
                                    [score]="1"
                                    [size]="20"
                                    [id]="'legendYellow'"
                                ></app-job-satisfaction-graphics>

                            </td>
                            <td>
                                {{ "jobSatisfactions.report.average" | translate }}
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    class="floatRight"
                                    [background]="'regular'"
                                    [score]="0"
                                    [size]="20"
                                    [id]="'legendRed'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                {{ "jobSatisfactions.report.low" | translate }}
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    class="floatRight"
                                    [background]="'regular'"
                                    [score]="null"
                                    [size]="20"
                                    [id]="'legendGray'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                {{ "jobSatisfactions.report.nd" | translate }}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span
                                    style="font-family: MontserratSemiBold,  sans-serif">{{ 'jobSatisfactions.report.validity' | translate }}</span>
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    [background]="'regular'"
                                    [score]="0"
                                    [type]="'pcr_redo'"
                                    [size]="'legend'"
                                    [id]="'hourGlassRed'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                0 &ndash; 60
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    [background]="'regular'"
                                    [score]="1"
                                    [type]="'pcr_redo'"
                                    [size]="'legend'"
                                    [id]="'hourGlassyellow'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                61 &ndash; 120
                            </td>
                            <td>
                                <app-job-satisfaction-graphics
                                    [background]="'regular'"
                                    [score]="2"
                                    [type]="'pcr_redo'"
                                    [size]="'legend'"
                                    [id]="'hourGlassGreen'"
                                ></app-job-satisfaction-graphics>
                            </td>
                            <td>
                                {{ "jobSatisfactions.report.121plus" | translate }}
                            </td>
                            <td colspan="2" style="font-size: 8pt">
                                <!--
                                {{ "jobSatisfactions.report.validityExplanation1" | translate }}<br>
                                {{ "jobSatisfactions.report.validityExplanation2" | translate }}<br>
                                {{ "jobSatisfactions.report.validityExplanation3" | translate }}<br>
                                -->
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="clearfix"></div>
                <div style="border-bottom: 2px solid #999; margin-bottom: 10px;">
                    <div class="floatLeft">
                        <app-nav-button
                            [backgroundColor]="'rgb(227, 94, 36)'"
                            [textColor]="'white'"
                            [border]="'0'"
                            [swapContent]="true"
                            [isSidebar]="false"
                            [isJobSatisfaction]="true"
                            (click)="jobSatisfactionModalHandlingService.openJobSatisfactionPersonModal()"
                        >
                            <div class="buttonImage">
                                <img
                                    src="../../../../../../assets/buttons/jobSatisfaction/bouton-ajouter-individu.png"
                                    height="22"
                                    width="22"
                                />
                            </div>
                            <div class="buttonTitle">{{ 'jobSatisfactions.report.addPerson' | translate }}</div>
                        </app-nav-button>
                        <div class="clearfix"></div>
                    </div>
                    <div class="floatRight">
                        <div class="floatLeft">
                            <app-nav-button
                                [backgroundColor]="'#cecece'"
                                [border]="'0'"
                                [textColor]="'white'"
                                [isSidebar]="false"
                                [isJobSatisfaction]="true"
                                style="margin-left: 5px;"
                                (click)="jobSatisfactionModalHandlingService.openJobSatisfactionReportsFiltersModal()"
                            >
                                <div class="buttonImage">
                                    <img src="../../../../../../assets/buttons/jobSatisfaction/bouton-filtrer.png"
                                         height="19" width="19" style="margin-top: 2px"/>
                                </div>
                                <div
                                    class="buttonTitle titleRight">{{ 'analysis.jobSatisfaction.filter' | translate }}</div>
                            </app-nav-button>
                        </div>
                        <div class="floatLeft">
                            <app-nav-button
                                [backgroundColor]="'#cecece'"
                                [border]="'0'"
                                [textColor]="'white'"
                                [isSidebar]="false"
                                [isJobSatisfaction]="true"
                                style="margin-left: 5px;"
                                (click)="togglePersonsCards()"
                            >
                                <div class="buttonImage">
                                    <img *ngIf="!minimizeState"
                                         src="../../../../../../assets/buttons/jobSatisfaction/bouton-minimiser.png"
                                         height="22" width="22"/>
                                    <img *ngIf="minimizeState"
                                         src="../../../../../../assets/buttons/jobSatisfaction/bouton-maximiser.png"
                                         height="22" width="22"/>
                                </div>
                                <div *ngIf="!minimizeState"
                                     class="buttonTitle titleRight">{{ 'commons.minimize' | translate }}</div>
                                <div *ngIf="minimizeState"
                                     class="buttonTitle titleRight">{{ 'commons.maximize' | translate }}</div>
                            </app-nav-button>
                        </div>
                        <div class="floatLeft">
                            <app-nav-button
                                [backgroundColor]="'#cecece'"
                                [border]="0"
                                [textColor]="'white'"
                                [isSidebar]="false"
                                [isJobSatisfaction]="true"
                                (click)="toggleListView()"
                            >
                                <div class="buttonImage">
                                    <img *ngIf="!listView"
                                         src="../../../../../../assets/buttons/jobSatisfaction/bouton-liste.png"
                                         height="22" width="22"/>
                                    <img *ngIf="listView"
                                         src="../../../../../../assets/buttons/jobSatisfaction/bouton-grille.png"
                                         height="22" width="22"/>
                                </div>
                                <div *ngIf="!listView"
                                     class="buttonTitle titleRight">{{ "jobSatisfactions.report.list" | translate }}</div>
                                <div *ngIf="listView"
                                     class="buttonTitle titleRight">{{ "jobSatisfactions.report.grid" | translate }}</div>
                            </app-nav-button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="clearfix"></div>
                <div class="headerList">
                    {{ 'jobSatisfactions.report.teamsJobSatisfaction' | translate }}
                </div>
                <div style="margin: 0 auto 15px auto; border: 4px solid #ddd; padding: 0 15px">
                    <h2 class="ngorange"
                        style="
                                    font-family: MontserratSemiBold,  sans-serif;
                                    font-size: 17px;
                                    margin-top: 15px;
                                "
                    >
                        {{ jobSatisfaction.name }}
                    </h2>
                    <app-job-satisfactions-team-graph
                        [id]="jobSatisfaction.id"
                    >
                    </app-job-satisfactions-team-graph>
                </div>
                <div class="headerList">
                    {{ 'jobSatisfactions.report.personJobSatisfaction' | translate }}
                </div>
                <ng-container
                    *ngIf="listView"
                >
                    <ng-container
                        *ngFor="let record of records"
                    >
                        <app-job-satisfactions-person-list-display
                            [record]="record"
                            [minimizeAll]="minimizeAll"
                            (openPcrReport)="toggleJobSatisfactionPopup($event)"
                            (remove)="removePerson($event)"
                        ></app-job-satisfactions-person-list-display>
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngIf="!listView"
                >
                    <div class="first">
                        <ng-container
                            *ngFor="let in of [].constructor(numberOfLines); let i=index"
                        >
                            <app-job-satisfactions-person-card
                                *ngIf="records[i*3]"
                                [record]="records[i*3]"
                                [pos]="i + '_0'"
                                [minimizeAll]="minimizeAll"
                                [listView]="listView"
                                [type]="'jobSatisfaction'"
                                (openPcrReport)="toggleJobSatisfactionPopup($event)"
                            ></app-job-satisfactions-person-card>
                            <div class="clearfix"></div>
                        </ng-container>

                    </div>
                    <div class="second">
                        <ng-container
                            *ngFor="let in of [].constructor(numberOfLines); let j=index"
                        >
                            <app-job-satisfactions-person-card
                                *ngIf="records[j*3 + 1]"
                                [record]="records[j*3 + 1]"
                                [pos]="j + '_1'"
                                [minimizeAll]="minimizeAll"
                                [listView]="listView"
                                [type]="'jobSatisfaction'"
                                (openPcrReport)="toggleJobSatisfactionPopup($event)"
                            ></app-job-satisfactions-person-card>
                            <div class="clearfix"></div>
                        </ng-container>
                    </div>
                    <div class="third">
                        <ng-container
                            *ngFor="let in of [].constructor(numberOfLines); let k=index"
                        >
                            <app-job-satisfactions-person-card
                                *ngIf="records[k*3 + 2]"
                                [record]="records[k*3 + 2]"
                                [pos]="k + '_2'"
                                [minimizeAll]="minimizeAll"
                                [listView]="listView"
                                [type]="'jobSatisfaction'"
                                (openPcrReport)="toggleJobSatisfactionPopup($event)"
                            ></app-job-satisfactions-person-card>
                            <div class="clearfix"></div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container
            *ngIf="state.report['reportType']=='history'">
            <div
                [style.padding-right]="(small) ? '15px' : 'auto'"
            >
                <h1 class="mainTitleRmSb">Graph tests</h1>
            </div>
        </ng-container>
    </div>
</div>


