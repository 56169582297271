import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { PeopleList } from '../../../services/people_list.service';
import { ComponentList } from 'src/app/shared/classes/components/component_list';
import { StateService } from 'src/app/core/services/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs';
import {PeopleSearchFiltersService} from '../../../services/people-search-filters.service';

@Component({
    selector: 'app-mobile-people-list',
    templateUrl: './mobile-people-list.component.html',
    styleUrls: ['./mobile-people-list.component.scss'],
    animations: [
        trigger('selected', [
            state('none', style({
                display: 'none',
                transform: 'scale(0)'
            })),
            state('some', style({
                display: '',
                transform: 'scale(1)'
            })),
            transition('none => some', animate('120ms ease-in')),
            transition('some => none', animate('120ms ease-out'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobilePeopleListComponent extends ComponentList implements OnInit, OnDestroy {
    showSurvey = false;
    logoImg = '/assets/menu_gauche/MPO_32px.png';
    totalItems = 0;
    public orderedBy;

    private subscriptions = new Subscription();

    constructor(
        public peopleList: PeopleList,
        protected translate: TranslateService,
        protected stateService: StateService,
        protected router: Router,
        protected route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        protected deviceService: DeviceDetectorService,
        public peopleSearchFilters: PeopleSearchFiltersService
    ) {
        super(stateService, translate, router, deviceService);
        this.peopleList.checkReportType();
        this.orderedBy = this.peopleList.state.listColumns.filter(col => col.order !== undefined);
    }

    ngOnInit() {
        this.peopleList.checkArchiveSettings();
        this.peopleList.testAccessPermissions();

        // check items list without selection
        let initList = this.peopleList.getList({listSearchFilters: {}}, 0,
            null, false, false, this.actualModule.archive);
        initList.listObservable.subscribe(list => {
            this.totalItems = list.count;
            this.changeDetectorRef.markForCheck();
        });

        this.peopleList.getRecordList(true);
        this.peopleList.state.stateChanged$.subscribe(
            () => {
                this.changeDetectorRef.markForCheck();
            }
        );

        // Translate list content when needed
        this.subscriptions.add(this.translate.onLangChange.subscribe(() => {
            this.peopleList.getRecordList(true);
        }));

    }

    setOrder(event) {
        this.peopleList.setOrder(event);
        this.peopleList.getRecordList(true);
    }

    refreshList(): void {
        this.peopleList.getRecordList();
    }

    /**
     * Set search parameter on event from shared/main-list/component/main-list-search-bar
     * @param event
     */
    setSearchFilter(event: string): void {
        this.peopleSearchFilters.setSearchFilter(event);
        this.peopleList.getRecordList(true);

        let activeElement = document.activeElement as HTMLElement;
        // remove focus from the search input
        activeElement.blur();

        // check the case when the button Search/Clear was clicked
        setTimeout(() => {
            if (document.activeElement.tagName === 'INPUT') {
                activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
            }
        }, 0 );
    }

    onSurveyRequest() {
        this.showSurvey = !this.showSurvey;
        if (!this.showSurvey) {
            this.refreshList();
        }
    }

    ngOnDestroy(): void {
        // this.peopleList.redirectTo = undefined;
        this.subscriptions.unsubscribe();
    }

}
