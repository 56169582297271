<ng-sidebar-container style="position: absolute; height: 100vh">

    <!-- A sidebar -->
    <ng-sidebar
            [position]="'right'"
            [(opened)]="idwMatricesList._opened"
            [animate]="false"
            [closeOnClickOutside]="false"
            [autoFocus]="false"
            (openedChange)="onSidebarStatusChange($event)"
            #gSidebar
    >

        <div>
            <div class="outerColumnGrey col-md-4">
                <div class="innerColumnGrey" [style.width]="sideWidth">
                    <div *ngIf="!idwMatricesList.sideBarStatus || idwMatricesList.sideBarStatus=='recentActivities' || idwMatricesList.sideBarStatus==''">
                        <app-infopanel [width]="sideWidth"></app-infopanel>
                    </div>

                    <div *ngIf="idwMatricesList.sideBarStatus=='searchFilters'">
                        <app-idw-matrix-search-filters
                                [whereAmI]="'filter'"
                                [width]="sideWidth"
                        ></app-idw-matrix-search-filters>
                    </div>

                    <div *ngIf="idwMatricesList.sideBarStatus=='reports'">
                        <app-idw-matrix-sidebar
                                [width]="sideWidth"
                                [config]="'list'"
                                (closeSidebar)="closeRightSidebar($event)"
                        ></app-idw-matrix-sidebar>
                    </div>
                    <div *ngIf="idwMatricesList.sideBarStatus=='clickMode'">
                        <app-idw-matrix-selected-items
                                (switchOff)="idwMatricesList.switchOffFilterPanelIndicator($event, true)"
                                [width]="sideWidth"
                                (actionOnRecords)="actOnRecords($event)"
                        ></app-idw-matrix-selected-items>
                        <!--(completeAction)="completeAction($event)"-->
                    </div>
                </div>
            </div>
        </div>
    </ng-sidebar>

    <div ng-sidebar-content
         (window:resize)="onResize($event)"
         (window:orientationchange)="onResize($event)"
         style="overflow-x: hidden"
    >

        <div class="rightWrapper row right-wrapper-table"
             infiniteScroll
             [infiniteScrollDistance]="4"
             [infiniteScrollThrottle]="300"
             [scrollWindow]="false"
             (scrolled)="idwMatricesList.listScrolled()"
        >
            <div class="outerColumnWhite"
                 [class.col-md-8]="!smallSize"
                 [class.clear]="!smallSize"
                 [style.width]="(!smallSize) ? listWidth : null"
            >
                <div
                        id="topMenuWrapper"
                        class="affix"
                        [class.topMenuCalcWidth]="smallSize"
                        [style.width]="(!smallSize) ? listWidth : null"
                >
                    <div>
                        <div class="pull-left">
                            <div class="pull-left">
                                <h1 class="sTitle">
                                    <span *ngIf='!idwMatricesList.archive'>{{ 'commons.idwMatrix' | translate }}</span>
                                    <span *ngIf='idwMatricesList.archive'>{{ 'commons.archives' | translate }}</span>
                                </h1>
                            </div>
                        </div>
                        <div class="pull-right">
                            <app-main-list-search-bar
                                    [switchOff]="idwMatricesList.switchOff"
                                    [selectionByFilters]="idwMatricesList.selectionByFilters"
                                    (searchEvent)="setSearchFilter($event)"
                                    (filterPanel)="idwMatricesList.toggleFilterPanel()"
                            ></app-main-list-search-bar>
                            <div class="clearfix"></div>
                            <div class="searchResults">
                                <span
                                    style="font-weight: bold"
                                    *ngIf="idwMatricesList.selectionDone && idwMatricesList.totalListItems !== null"
                                >
                                    {{ idwMatricesList.totalListItems }}
                                    {{ idwMatricesList.resMention | translate }}
                                    <span *ngIf="idwMatricesList.totalItems">
                                        {{ 'commons.of' | translate }} {{ idwMatricesList.totalItems }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div id="subMenu">
                        <div class="pull-left" id="subMenuLeft">
                            <div>
                                <table class="nav nav-topbar">
                                    <tr class="topMenu">
                                        <td *ngIf="!idwMatricesList.archive" (click)="openIdwMatrixModal({status:'new'})">
                                            <a>
                                                <div>
                                                    <div style="float:left">
                                                        <i class="material-icons" style="color: #CCCCCC; margin-top: 2px">add</i>
                                                    </div>
                                                    <div style="float: right; margin-top: 7px;">{{ 'commons.nouvelle' | translate }}</div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </a>
                                        </td>
                                        <td [class.clickMode]="idwMatricesList.clickMode" (click)="idwMatricesList.switchMode()">
                                            <a>
                                                <div>
                                                    <img src="assets/header/comparer/ico_comparer.png" alt=""/>
                                                    <div>
                                                        {{ 'commons.toSelect' | translate }}
                                                    </div>
                                                    <img src="assets/header/comparer/ico_comparer_selected.png" alt=""/>
                                                </div>
                                            </a>
                                        </td>
                                        <td (click)="refreshList()">
                                            <a>
                                                <div style="padding-top: 2px">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20"
                                                         viewBox="0 0 24 20">
                                                        <path
                                                                fill="#AAA"
                                                                d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99
                                                    8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6
                                                    6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"></path>
                                                    </svg>
                                                </div>
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div id="typeMenu" class="pull-right">
                            <div *ngIf='!idwMatricesList.archive && stateService.hasPersonality'>
                                <div
                                    class="pull-right"
                                    (click)="router.navigate(['/analysis/jobsatisfaction/list'])"
                                >
                                    <div>{{ 'commons.jobSatisfaction' | translate }}</div>
                                </div>
                                <div
                                        class="pull-right"
                                        (click)="router.navigate(['/analysis/rightmatch/list'])"
                                >
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                        class="pull-right"
                                        [class.typeSelected]="true"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                        class="pull-right"
                                        (click)="router.navigate(['/analysis/graphboard/list'])"
                                >
                                    <div>{{ 'commons.graphboard' | translate }}</div>
                                </div>
                            </div>
                            <div *ngIf="idwMatricesList.archive">
                                <div
                                        *ngIf="stateService && stateService.hasPersonality"
                                        class="pull-right"
                                        [routerLink]="['/archives/list/rightmatch']"
                                >
                                    <div>{{ 'commons.rightMatch' | translate }}</div>
                                </div>
                                <div
                                        *ngIf="stateService && stateService.hasCommunication"
                                        class="pull-right typeSelected"
                                >
                                    <div>{{ 'commons.idwMatrix' | translate }}</div>
                                </div>
                                <div
                                        *ngIf="stateService && stateService.hasPersonality"
                                        class="pull-right"
                                        [routerLink]="['/archives/list/graphboard']"
                                >
                                    <div>{{ 'commons.graphboard' | translate }} </div>
                                </div>
                                <div
                                        *ngIf="stateService && stateService.hasJobAccess"
                                        class="pull-right"
                                        [routerLink]="['/archives/list/jobs']"
                                >
                                    <div>{{ 'commons.jobs' | translate }}</div>
                                </div>
                                <div
                                        class="pull-right"
                                        [routerLink]="['/archives/list/people']"
                                >
                                    <div>{{ 'commons.individus' | translate }}</div>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div *ngIf="idwMatricesList.toList" [style.height]="(idwMatricesList.toList['count']==0) ? '700px': null" id="listWrap">
                    <ngx-loading
                        [show]="idwMatricesList.loading"
                        [config]="loaderDisplay"
                    ></ngx-loading>
                    <app-main-list
                            [small]="smallSize"
                            [forceWidthTo]="listWidth.substring(0, listWidth.length - 2)"
                            [selectedItemId]="idwMatrix.id"
                            [tableDefinition]="idwMatricesList.tableDefinition"
                            [view]="idwMatricesList.idwMatrixState['listView']"
                            [listsData]="idwMatricesList.toList"
                            [clickMode]="idwMatricesList.clickMode"
                            [actualModule]="'idwMatrix'"
                            [archive]="idwMatricesList.archive"
                            (listScrolled)="idwMatricesList.listScrolled()"
                            (selectedItem)="itemSelected($event)"
                            (reordering)="setOrder($event)"
                    ></app-main-list>
                </div>
            </div>
        </div>
    </div>
</ng-sidebar-container>
