<div
    class="card"
    [class.leftCard]="listView"
    [class.cardGridView]="!listView"
>
    <div class="cardHeader">
        <div class="floatLeft">
            <div class="cardHeaderMainTitle">
                {{ "jobSatisfactions.report.pcrVsTraits" | translate }}
            </div>
        </div>
        <div class="floatRight">
            <div class="floatLeft">
                <div *ngIf="!truncate" style="width: auto; padding: 6px">
                    <app-job-satisfaction-graphics
                        class="floatRight"
                        [background]="'regular'"
                        [score]="record.jobSatisfactionScore"
                        [id]="'satisfaction_person_' + record['id']+pos"
                    >
                    </app-job-satisfaction-graphics>
                    <div class="clearfix"></div>
                </div>
            </div>
            <div *ngIf="!listView" class="floatLeft" style="margin-top: 3px;">
                <button type="button"
                        class="btn-person-card btn btn-default"
                        (click)="toggleInformationVisibility()"
                        style="border-radius: 0"
                >
                    <span *ngIf="!informationVisibility">+</span>
                    <span *ngIf="informationVisibility">-</span>
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <ng-container *ngIf="informationVisibility">
        <div class="main-container"
             [class.listDisplay]="truncate && !isSmallMobile"
             [class.smallMobile] = "isSmallMobile"
             [class.main-container-truncate]="truncate"
             style="overflow-y: hidden"
        >
            <div style="position: relative">
                <div style="max-width: 310px">
                    <div [class.cover]="
                    !(record && record['scores']) ||
                    !(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores'])
                    ">
                        <!-- div
                            *ngIf="!(record && record['scores']) ||
                                    !(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores'])"
                            class="sendPcr" (click)="openModal('sendAdditionalPrb', record)">
                            {{ 'commons.sendAdditionalPrb' | translate }}
                        </div-->
                    </div>
                    <div *ngIf="truncate" style="height: 5px"></div>
                    <app-mpo-graph
                        [id]="'traits_person_'+record['id']+pos"
                        [width]="'100%'"
                        [targetScore]="(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? (record['prb'][0]['scores']) : null"
                        [scores]="(record['scores']) ? record['scores'] : null"
                        [messageOverhead]="false"
                        [section]="'jobSatisfaction'"
                        [lang]="translateService.currentLang"
                    ></app-mpo-graph>
                </div>
                <div class="card-footer">
                    <div [class.coverBottom]="
                    !(record && record['scores']) ||
                    !(record && record['prb'] && record['prb'][0] && record['prb'][0]['scores'])
                    "></div>
                    <!--div class="floatLeft">
                        <span>⬤ {{ 'commons.behavior' | translate }}</span><br>
                        <span style="color: #aaaaaa">⬤ {{ 'commons.traits' | translate }}</span>
                    </div>
                    <div class="floatRight">
                        <div
                            (click)="openJobSatisfactionPcrReport({
                                        id: record['id'],
                                        personalityScores: (record['scores']) ? record['scores'] : null,
                                        prbScores: (record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? (record['prb'][0]['scores']) : null
                                    })"
                        >
                            <div class="floatLeft">
                                <a class="orangeLink">
                                    <mat-icon class="emailIcon">info</mat-icon>
                                </a>
                            </div>
                            <div class="floatLeft">
                                <a class="orangeLink">{{ 'jobSatisfactions.report.more' | translate }}</a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                        <div (click)="openModal('sendAdditionalPrb', record)">
                            <div class="floatLeft">
                                <a class="orangeLink">
                                    <mat-icon class="emailIcon">email</mat-icon>
                                </a>
                            </div>
                            <div class="floatLeft">
                                <a class="orangeLink">Questionnaire</a>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="clearfix"></div-->
                    <div class="legende">
                        <span style="color: #aaaaaa">⬤ {{ 'commons.behavior' | translate }}</span>&nbsp;&nbsp;&nbsp;
                        <span>⬤ {{ 'commons.traits' | translate }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="!truncate">
                <div class="buttons">
                    <div class="button floatLeft"
                         [style.pointer-events]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? 'none' : 'auto'"
                         [style.background-color]="!(record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? '#888' : 'auto'"
                         (click)="openJobSatisfactionPcrReport({
                                        id: record['id'],
                                        personalityScores: (record['scores']) ? record['scores'] : null,
                                        prbScores: (record['prb'] && record['prb'][0] && record['prb'][0]['scores']) ? (record['prb'][0]['scores']) : null
                                    })"
                    >
                        {{ 'jobSatisfactions.report.detailedAnalysis' | translate }}
                    </div>
                    <div class="button floatRight"
                         [class.redButton]="flagPrbToSend(record)"
                         (click)="openModal('sendAdditionalPrb')"
                    >
                        Questionnaire
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
