import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Rest} from 'src/app/core/services/api/rest';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {EnvService} from '../../../app/services/env.service';

@Injectable()
export class ApiRightMatchesService extends Rest {
    constructor(
        protected http: HttpClient,
        protected translate: TranslateService,
        protected environment: EnvService
    ) {
        super(http, environment);
    }

    rightMatches(dataParams: Array<any>): Observable<any> {
        dataParams[0]['lang'] = this.translate.currentLang;
        return this.apiGet(
            'rightmatches',
            null,
            dataParams,
            ['@fromObject']
        );
    }

    reportRightMatch<T, U>(urlParams: Array<string>, dataParams: any): Observable<any> {
        return this.apiGet(
            'report/rightmatch',
            urlParams,
            dataParams,
            ['parts', 'language', 'sortBy', 'displayTargetPersonalityProfile']
        );
    }

    rightMatch(urlParams: Array<string>): Observable<any> {
        return this.apiGet(
            'rightmatch',
            urlParams,
            null,
            null
        );
    }

    reportEmail(dataParams: Array<any>): Observable<any> {
        return this.apiGet(
            'email/report',
            null,
            dataParams,
            ['emailTo', 'subject', 'message', 'pdfLink', 'name', 'origin', 'lang']
        );
    }

    putRightMatch(urlParams: [string], item: any): Observable<any> {
        return this.apiPut(
            'rightmatch',
            urlParams,
            item
        );
    }

    postRightMatch(data): Observable<any> {
        return this.apiPost(
            'rightmatch',
            null,
            data
        );
    }

    deleteRightMatches(idList: string[]): Observable<any> {
        return this.apiPost(
            'rightmatches/delete',
            null,
            {idList: idList}
        );
    }

    deleteRightMatch(urlParams: [string]): Observable<any> {
        return this.apiDelete(
            'rightmatch',
            urlParams
        );
    }
}
