<div *ngIf="user">
    <div *ngIf="tabletMode">
        <div class="affix topBar">
            <h2 class="name">{{ rightMatch.name.toUpperCase() }}</h2>
            <div>
                <app-nav-button (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <app-nav-button [isBlank]="!rightMatch.records || !rightMatch.records.length" (buttonActon)="openPdfModal()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                    <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                </app-nav-button>
                <div class="pull-left dropdown" dropdown>
                    <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">Options&nbsp;<span class="caret"></span></div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                        <li *ngIf="!rightMatchService.archive">
                            <a (click)="openEditModal()">
                                {{ 'commons.editInfo' | translate }}
                            </a>
                        </li>
                        <li *ngIf="!rightMatchService.archive" role="menuitem">
                            <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                        </li>
                        <li *ngIf="canDelete" role="menuitem">
                            <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div style="height: 100px"></div>
        <div>
            <div class="infoWrap">
                <table class="infoTableWrap">
                    <tr>
                        <td class="switchButton">
                            <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                <span *ngIf="!informationVisibility">+</span>
                                <span *ngIf="informationVisibility">-</span>
                            </button>
                        </td>
                        <td>
                            <mat-tab-group [(selectedIndex)]="selectedInfoQuestionTab" (selectedIndexChange)="switchTab($event)">
                                <mat-tab label="{{ 'commons.informations' | translate }}">
                                    <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                        <tr>
                                            <td>
                                                {{ 'analysisCommons.analysisName' | translate }}
                                                <span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.name">
                                                    {{ rightMatch.name || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.consultant">
                                                    {{ rightMatch.consultant || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.subAccount">
                                                    {{ setSubAccountName(rightMatch.subAccount) || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'rms.commons.jobProfile' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.jobTitle"
                                                    >&nbsp;
                                                    <a
                                                        *ngIf="config == 'report' && rightMatch.jobTitle"
                                                        (click)="goToJob(rightMatch.profilerId, rightMatch.id, 'rightMatch')"
                                                        >{{ rightMatch.jobTitle || notSpecified() }}</a
                                                    >
                                                    <span
                                                        *ngIf="config != 'report' || !rightMatch.jobTitle"
                                                        [class.attention]="rightMatch.profilerInfo && !rightMatch.profilerId"
                                                        >{{ rightMatch.jobTitle || notSpecified(rightMatch.profilerInfo) }}</span
                                                    >
                                                </span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="rightMatch.profilerInfo">
                                            <td>
                                                {{ 'rms.sideBar.numberOfPerson' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span *ngIf="!rightMatchService.archive"
                                                    >&nbsp;<a (click)="rightMatchModalHandlingService.openRightMatchPersonModal()">{{
                                                        rightMatch.records != null ? rightMatch.records.length : 0
                                                    }}</a></span
                                                >
                                                <span *ngIf="rightMatchService.archive"> {{ rightMatch.records != null ? rightMatch.records.length : 0 }}</span>
                                            </td>
                                        </tr>
                                        <tr *ngIf="rightMatch.profilerInfo">
                                            <td>
                                                {{ 'rms.sideBar.surveyType' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.profilerInfo.type">
                                                    {{ renderType(rightMatch.profilerInfo.type) | translate }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!rightMatch.department || !rightMatch.department.length">
                                                    {{ rightMatch.department && rightMatch.department.length ? rightMatch.department : notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <hr class="panelHr" />
            </div>
        </div>
        <h1 class="mainTitleRmSb">{{ 'rms.sideBar.targetProfiles' | translate }}</h1>
        <div class="MPOSideBarPresentation col-md-4">
            <h3 class="ngorange subTitleRmSb" style="margin-bottom: 15px">{{ 'commons.personality' | translate }}</h3>
            <div class="clearfix"></div>
            <ng-container *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['scores']; else noTraits">
                <app-mpo-graph
                    [id]="'traits'"
                    [width]="'100%'"
                    [mobile]="false"
                    [scores]="rightMatch.profilerInfo['scores']"
                    [lang]="currentLang"
                ></app-mpo-graph>
            </ng-container>
            <ng-template #noTraits>
                <app-mpo-graph [id]="'traits'" [width]="'100%'" [mobile]="false" [scores]="null" [lang]="currentLang"></app-mpo-graph>
            </ng-template>
        </div>
        <div class="competenciesRm col-md-4">
            <h3 class="ngorange subTitleRmSb">{{ 'jobs.commons.competencies' | translate }}</h3>
            <ng-container *ngIf="rightMatch.orderedTalents && rightMatch.profilerInfo && rightMatch.profilerInfo['competences']; else noCompetencies">
                <table class="talentsDisplay">
                    <ng-container *ngFor="let i of createRange(actualPage, maxPerPage)">
                        <ng-container
                            *ngIf="rightMatch.orderedTalents[translateService.currentLang] && rightMatch.orderedTalents[translateService.currentLang][i]"
                        >
                            <tr style="height: 20px">
                                <td>{{ rightMatch.orderedTalents[translateService.currentLang][i][0] }}</td>
                                <td>
                                    <span *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1] == 1" style="color: #efce7c">⬤</span>
                                    <span *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1] == 2" style="color: #78c7af">⬤</span>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container
                            *ngIf="rightMatch.orderedTalents[translateService.currentLang] && !rightMatch.orderedTalents[translateService.currentLang][i]"
                        >
                            <tr style="height: 20px">
                                <td>&nbsp;</td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    <tr>
                        <td colspan="3" style="padding-top: 8px; padding-bottom: 8px">
                            <!--div style="padding-bottom: 8px">
                                <hr class="panelHr"/>
                            </div-->
                            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)">
                                <img src="/assets/buttons/next.png" alt=">" />
                            </div>
                            <div *ngIf="actualPage == maxPage" class="pull-right"><img src="/assets/buttons/next_deactivated.png" alt=">" /></div>
                            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img src="/assets/buttons/before.png" alt="<" /></div>
                            <div *ngIf="actualPage == 1" class="pull-right"><img src="/assets/buttons/before_deactivated.png" alt="<" /></div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                </table>
            </ng-container>
            <ng-template #noCompetencies>
                <div style="height: 300px">
                    <table class="fakeListTable">
                        <tr *ngFor="let line of [100, 110, 120, 130, 90, 100, 120, 140, 110, 120]">
                            <td>
                                <div class="fakeItem" [style.width]="line + 'px'">&nbsp;</div>
                            </td>
                            <td>
                                <span class="fakeIndicator">⬤</span>
                            </td>
                        </tr>
                    </table>
                </div>
                <table class="fakeListTable">
                    <tr>
                        <td style="padding-top: 5px">
                            <div class="pull-left" style="width: 50%">
                                <span style="color: #78c7af">⬤</span>
                                {{ 'jobs.modal.competenceMasteryOption' | translate }}
                            </div>
                            <div class="pull-left" style="width: 50%">
                                <span style="color: #efce7c">⬤</span>
                                {{ 'jobs.modal.competenceFuncOption' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" style="padding-top: 8px; padding-bottom: 8px">
                            <!--div style="padding-bottom: 8px">
                                <hr class="panelHr"/>
                            </div-->
                            <div class="pull-right"><img src="/assets/buttons/next_deactivated.png" alt=">" /></div>
                            <div class="pull-right"><img src="/assets/buttons/before_deactivated.png" alt="<" /></div>
                            <div class="clearfix"></div>
                        </td>
                    </tr>
                </table>
            </ng-template>
        </div>
        <div class="caiRm col-md-4" style="padding-bottom: 20px">
            <h3 class="ngorange subTitleRmCai" style="margin-bottom: 40px">{{ 'commons.cai' | translate }}</h3>
            <ng-container *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['RA']; else noCai">
                <div style="width: 100%; border: 4px solid rgb(206, 206, 206)">
                    <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt="" />
                    <app-iac-graph [id]="'jobIac'" [normalizedScore]="null" [jobScore]="rightMatch.profilerInfo['RA']" [mobile]="false"></app-iac-graph>
                </div>
            </ng-container>
            <ng-template #noCai>
                <div style="width: 100%; border: 4px solid rgb(206, 206, 206); position: relative">
                    <div
                        style="
                            width: 100%;
                            height: 100% !important;
                            background-color: rgba(255, 255, 255, 0.6);
                            position: absolute;
                            text-align: center;
                            padding-top: 26px;
                            font-size: 17pt;
                            z-index: 100000 !important;
                            font-family: sans-serif;
                            color: black;
                        "
                    >
                        {{ 'people.commons.notCompleted' | translate }}
                    </div>
                    <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt="" />
                    <app-iac-graph [id]="'jobIac_sidebar'" [normalizedScore]="null" [jobScore]="null" [mobile]="false"></app-iac-graph>
                </div>
            </ng-template>
        </div>
        <div class="clearfix"></div>
        <div>
            <hr class="panelHr" />
        </div>
    </div>
    <div *ngIf="!tabletMode && rightMatch.id && stateService.rightMatch" (window:resize)="onResize($event)">
        <ngx-loading [show]="!rightMatch.id" [config]="loaderDisplay" [class]="'itemLoading'"></ngx-loading>
        <div class="top">
            <div class="topHead">
                <div class="topHead">
                    <div class="pull-left">
                        <h2 class="name">{{ rightMatch.name.toUpperCase() }}</h2>
                    </div>
                    <div *ngIf="config == 'list'" (click)="goToNews()" class="x-close pull-right" style="font-size: 20pt">&#x00D7;</div>
                    <div class="clearfix"></div>
                </div>
                <div *ngIf="rightMatchService.archive">
                    <span (click)="modStatus('archive', false)" class="fakeLink">{{ 'commons.reactivate' | translate }}</span
                    >&nbsp;&nbsp;
                    <span *ngIf="canDelete" (click)="modStatus('delete')" class="fakeLink">{{ 'commons.delete' | translate }}</span>
                </div>
                <div *ngIf="config == 'list'; else reportFullButtons">
                    <div *ngIf="!rightMatchService.archive">
                        <app-nav-button (buttonActon)="goToFullReport(rightMatch.id)">
                            <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                            <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                        </app-nav-button>
                        <app-nav-button
                            *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['scores']"
                            [isBlank]="!rightMatch.records || !rightMatch.records.length"
                            (buttonActon)="openPdfModal()"
                        >
                            <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                            <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                        </app-nav-button>
                        <div class="pull-left dropdown" dropdown>
                            <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">
                                {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                            </div>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                                <li *ngIf="!rightMatchService.archive">
                                    <a (click)="openEditModal()">
                                        {{ 'commons.editInfo' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="!rightMatchService.archive" role="menuitem">
                                    <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                                </li>
                                <li *ngIf="canDelete" role="menuitem">
                                    <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <ng-template #reportFullButtons>
                    <app-nav-button (buttonActon)="goToList()">
                        <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png" /></div>
                        <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                    </app-nav-button>

                    <app-nav-button [isBlank]="!rightMatch.records || !rightMatch.records.length" (buttonActon)="openPdfModal()">
                        <div class="buttonImage"><img alt="" src="assets/buttons/download.png" /></div>
                        <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                    </app-nav-button>
                    <div class="pull-left dropdown" dropdown>
                        <div dropdownToggle class="dropdown-toggle sideBarButton" data-toggle="dropdown">Options&nbsp;<span class="caret"></span></div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                            <li *ngIf="!rightMatchService.archive">
                                <a (click)="openEditModal()">
                                    {{ 'commons.editInfo' | translate }}
                                </a>
                            </li>
                            <li *ngIf="!rightMatchService.archive" role="menuitem">
                                <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                            </li>
                            <li *ngIf="canDelete" role="menuitem">
                                <a (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </ng-template>

                <div>
                    <hr class="panelHr" />
                </div>
                <div style="height: 5px"></div>
                <div class="infoWrap">
                    <table class="infoTableWrap">
                        <tr>
                            <td class="switchButton">
                                <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                    <span *ngIf="!informationVisibility">+</span>
                                    <span *ngIf="informationVisibility">-</span>
                                </button>
                            </td>
                            <td>
                                <mat-tab-group>
                                    <mat-tab label="{{ 'commons.informations' | translate }}">
                                        <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                            <tr>
                                                <td>
                                                    {{ 'analysisCommons.analysisName' | translate }}
                                                    <span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.name">
                                                        {{ rightMatch.name || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.consultant">
                                                        {{ rightMatch.consultant || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.subAccount">
                                                        {{ setSubAccountName(rightMatch.subAccount) || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ 'rms.commons.jobProfile' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.jobTitle"
                                                        >&nbsp;
                                                        <a
                                                            *ngIf="config == 'report' && rightMatch.jobTitle"
                                                            (click)="goToJob(rightMatch.profilerId, rightMatch.id, 'rightMatch')"
                                                            >{{ rightMatch.jobTitle || notSpecified() }}</a
                                                        >
                                                        <span
                                                            *ngIf="config != 'report' || !rightMatch.jobTitle"
                                                            [class.attention]="rightMatch.profilerInfo && !rightMatch.profilerId"
                                                            >{{ rightMatch.jobTitle || notSpecified(rightMatch.profilerInfo) }}</span
                                                        >
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="rightMatch.profilerInfo">
                                                <td>
                                                    {{ 'rms.sideBar.numberOfPerson' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span *ngIf="!rightMatchService.archive"
                                                        >&nbsp;<a (click)="rightMatchModalHandlingService.openRightMatchPersonModal()">{{
                                                            rightMatch.records != null ? rightMatch.records.length : 0
                                                        }}</a></span
                                                    >
                                                    <span *ngIf="rightMatchService.archive">
                                                        {{ rightMatch.records != null ? rightMatch.records.length : 0 }}</span
                                                    >
                                                </td>
                                            </tr>
                                            <tr *ngIf="rightMatch.profilerInfo">
                                                <td>
                                                    {{ 'rms.sideBar.surveyType' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.profilerInfo.type">
                                                        {{ renderType(rightMatch.profilerInfo.type) | translate }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!rightMatch.department || !rightMatch.department.length">
                                                        {{ rightMatch.department && rightMatch.department.length ? rightMatch.department : notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                    </mat-tab>
                                </mat-tab-group>
                            </td>
                        </tr>
                    </table>
                    <div>
                        <hr class="panelHr" />
                    </div>
                </div>
                <!-- Graph profiler -->
                <h1 class="mainTitleRmSb">{{ 'rms.sideBar.targetProfiles' | translate }}</h1>
                <div *ngIf="user.hasPersonalityAccess" class="personalityRm">
                    <h3 class="ngorange subTitleRmSb">{{ 'commons.personality' | translate }}</h3>
                    <div class="graph-container MPOSideBarPresentation">
                        <div class="clearfix"></div>
                        <ng-container *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['scores']; else noTraits">
                            <app-mpo-graph
                                [id]="'traits'"
                                [width]="width"
                                [mobile]="false"
                                [scores]="rightMatch.profilerInfo['scores']"
                                [lang]="currentLang"
                            ></app-mpo-graph>
                        </ng-container>
                        <ng-template #noTraits>
                            <app-mpo-graph [id]="'traits'" [width]="width" [mobile]="false" [scores]="null" [lang]="currentLang"></app-mpo-graph>
                        </ng-template>
                    </div>
                </div>
                <div *ngIf="user.hasTalentsAccess" class="competenciesRm">
                    <h3 class="ngorange subTitleRmSb">{{ 'jobs.commons.competencies' | translate }}</h3>
                    <ng-container *ngIf="rightMatch.orderedTalents && rightMatch.profilerInfo && rightMatch.profilerInfo['competences']; else noCompetencies">
                        <table class="talentsDisplay">
                            <ng-container *ngFor="let i of createRange(actualPage, maxPerPage)">
                                <ng-container
                                    *ngIf="
                                        rightMatch.orderedTalents[translateService.currentLang] && rightMatch.orderedTalents[translateService.currentLang][i]
                                    "
                                >
                                    <tr style="height: 20px">
                                        <td>{{ rightMatch.orderedTalents[translateService.currentLang][i][0] }}</td>
                                        <td>
                                            <span *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1] == 1" style="color: #efce7c">⬤</span>
                                            <span *ngIf="rightMatch.orderedTalents[translateService.currentLang][i][1] == 2" style="color: #78c7af">⬤</span>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-container
                                    *ngIf="
                                        rightMatch.orderedTalents[translateService.currentLang] && !rightMatch.orderedTalents[translateService.currentLang][i]
                                    "
                                >
                                    <tr style="height: 20px">
                                        <td>&nbsp;</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <tr>
                                <td style="padding-top: 5px">
                                    <div class="pull-left" style="width: 50%">
                                        <span style="color: #78c7af">⬤</span>
                                        {{ 'jobs.modal.competenceMasteryOption' | translate }}
                                    </div>
                                    <div class="pull-left" style="width: 50%">
                                        <span style="color: #efce7c">⬤</span>
                                        {{ 'jobs.modal.competenceFuncOption' | translate }}
                                    </div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="padding-top: 8px; padding-bottom: 8px">
                                    <!--div style="padding-bottom: 8px">
                                        <hr class="panelHr"/>
                                    </div-->
                                    <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)">
                                        <img src="/assets/buttons/next.png" alt=">" />
                                    </div>
                                    <div *ngIf="actualPage == maxPage" class="pull-right"><img src="/assets/buttons/next_deactivated.png" alt=">" /></div>
                                    <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img src="/assets/buttons/before.png" alt="<" /></div>
                                    <div *ngIf="actualPage == 1" class="pull-right"><img src="/assets/buttons/before_deactivated.png" alt="<" /></div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                        </table>
                    </ng-container>
                    <ng-template #noCompetencies>
                        <div style="height: 300px">
                            <table class="fakeListTable">
                                <tr *ngFor="let line of [100, 110, 120, 130, 90, 100, 120, 140, 110, 120]">
                                    <td>
                                        <div class="fakeItem" [style.width]="line + 'px'">&nbsp;</div>
                                    </td>
                                    <td>
                                        <span class="fakeIndicator">⬤</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <table class="fakeListTable">
                            <tr>
                                <td style="padding-top: 5px">
                                    <div class="pull-left" style="width: 50%">
                                        <span style="color: #78c7af">⬤</span>
                                        {{ 'jobs.modal.competenceMasteryOption' | translate }}
                                    </div>
                                    <div class="pull-left" style="width: 50%">
                                        <span style="color: #efce7c">⬤</span>
                                        {{ 'jobs.modal.competenceFuncOption' | translate }}
                                    </div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3" style="padding-top: 8px; padding-bottom: 8px">
                                    <!--div style="padding-bottom: 8px">
                                        <hr class="panelHr"/>
                                    </div-->
                                    <div class="pull-right"><img src="/assets/buttons/next_deactivated.png" alt=">" /></div>
                                    <div class="pull-right"><img src="/assets/buttons/before_deactivated.png" alt="<" /></div>
                                    <div class="clearfix"></div>
                                </td>
                            </tr>
                        </table>
                    </ng-template>
                </div>
                <div *ngIf="user.hasIacAccess" class="caiRm" style="padding-bottom: 20px">
                    <h3 class="ngorange subTitleRmCai">{{ 'commons.cai' | translate }}</h3>
                    <ng-container *ngIf="rightMatch.profilerInfo && rightMatch.profilerInfo['RA']; else noCai">
                        <div style="width: 100%; border: 4px solid rgb(206, 206, 206)">
                            <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt="" />
                            <app-iac-graph [id]="'jobIac'" [normalizedScore]="null" [jobScore]="rightMatch.profilerInfo['RA']" [mobile]="false"></app-iac-graph>
                        </div>
                    </ng-container>
                    <ng-template #noCai>
                        <div style="width: 100%; border: 4px solid rgb(206, 206, 206); position: relative">
                            <div
                                style="
                                    width: 100%;
                                    height: 100% !important;
                                    background-color: rgba(255, 255, 255, 0.6);
                                    position: absolute;
                                    text-align: center;
                                    padding-top: 26px;
                                    font-size: 17pt;
                                    z-index: 100000 !important;
                                    font-family: sans-serif;
                                    color: black;
                                "
                            >
                                {{ 'people.commons.notCompleted' | translate }}
                            </div>
                            <img src="assets/graphicalElements/nCurve.png" style="width: 100%" alt="" />
                            <app-iac-graph [id]="'jobIac_sidebar'" [normalizedScore]="null" [jobScore]="null" [mobile]="false"></app-iac-graph>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
