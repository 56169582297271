<ng-container *ngIf="!dataTable.data.length; else results">
    <table mat-table
           matSort
           cdkDropListGroup
           *ngIf="listsData.count !== undefined"
           [dataSource]="dataTable"
           (matSortChange)="sortColumn($event)"
           [matSortActive]="matSortActive"
           [matSortDirection]="matSortDirection"
           class="mainList mat-elevation-z8"
    >
        <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns; let i = index;">
            <th mat-header-cell mat-sort-header
                *matHeaderCellDef
                cdkDropList
                cdkDropListLockAxis="x"
                cdkDropListOrientation="horizontal"
                (cdkDropListDropped)="dropListDropped($event, i)"
                cdkDrag
                (cdkDragStarted)="dragStarted($event, i)"
                [cdkDragData]="{name: item, colIndex: i}"
                [disabled]="!canSort(item) && 'we will active the sorting for each' === 'column'"
                [class.dateByDefault]="item === 'creationDate' && matSortActive === item && matSortDirection === 'desc'"
            >
                <div *ngIf="item !== 'creationDate'">{{ item === 'fullName' ? lastNameTitle + ', ' + firstNameTitle : getDefinitionTitle(item) }}</div>
                <div *ngIf="item === 'creationDate'">{{ dateTitle }}</div>
            </th>
        </ng-container>
        <ng-container matColumnDef="noRecord">
            <td mat-footer-cell
                *matFooterCellDef
                [attr.colspan]="(tableDefinition.length) ? tableDefinition.length : 3"
                class="noResult noBorder"
            >
                {{ 'commons.noResults' | translate }}
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </table>
</ng-container>
<ng-template #results>
    <table mat-table
           matSort
           cdkDropListGroup
           id="mainList"
           [dataSource]="dataTable"
           (matSortChange)="sortColumn($event)"
           [matSortActive]="matSortActive"
           [matSortDirection]="matSortDirection"
           class="mat-elevation-z8"
    >
        <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns; let i = index;">
            <th mat-header-cell mat-sort-header
                *matHeaderCellDef
                cdkDropList
                cdkDropListLockAxis="x"
                cdkDropListOrientation="horizontal"
                (cdkDropListDropped)="dropListDropped($event, i)"
                cdkDrag
                (cdkDragStarted)="dragStarted($event, i)"
                [cdkDragData]="{name: item, colIndex: i}"
                [disabled]="!canSort(item) && 'we will active the sorting for each' === 'column'"
                [class.dateByDefault]="item === 'creationDate' && matSortActive === item && matSortDirection === 'desc'"
            >
                <div *ngIf="item !== 'creationDate'">{{ item === 'fullName' ? lastNameTitle + ', ' + firstNameTitle : getDefinitionTitle(item) }}</div>
                <div *ngIf="item === 'creationDate'">{{ dateTitle }}</div>
            </th>
            <td mat-cell
                *matCellDef="let element"
                (click)="onSelectItem([element, 'current'])"
                [class.ngorange]="element.id == selectedItemId"
            >
                <div *ngIf="(item === 'status' || item === 'statusPrb' || item === 'statusIac') else textEl">
                    <button *ngIf="item === 'status'" mat-mini-fab color="{{statusIcon(element, 'mpo')}}">{{statusAbr(element, 'mpo')}}</button>
                    <button *ngIf="item === 'statusPrb'" mat-mini-fab color="{{statusIcon(element, 'prb')}}">{{statusAbr(element, 'prb')}}</button>
                    <button *ngIf="item === 'statusIac'" mat-mini-fab color="{{statusIcon(element, 'ra')}}">{{statusAbr(element, 'ra')}}</button>
                </div>
                <ng-template #textEl>
                    <div *ngIf="getDefinitionTitle(item) === 'Date'">
                        <span *ngIf="admData(element)">
                            {{admData(element)  | listFilter:clear(item)}}
                        </span>
                        <span *ngIf="!admData(element)">--</span>
                    </div>
                    <div *ngIf="getDefinitionTitle(item) !== 'Date'">
                        {{loGet(element, item)}}
                    </div>
                </ng-template>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>
