<div
    [style.width]="(!small) ? listWidth + 'px' : null"
     [style.margin-left]="(small) ? '9px':'29px'"
     [style.margin-right]="(small) ? '9px':'25px'"
     (window:resize)="onResize()"
     (window:orientationchange)="onResize()"
     (window:mousewheel)="checkScroll()"
     (window:mouseover)="checkScroll()"
>
    <ng-template [ngIf]="view == 'list'">
        <div>
            <ng-container *ngIf="listsData.data.length === 0; else results">
                <table mat-table
                       matSort
                       cdkDropListGroup
                       *ngIf="listsData.count !== undefined"
                       [dataSource]="dataTable"
                       (matSortChange)="sortColumn($event)"
                       [matSortActive]="matSortActive"
                       [matSortDirection]="matSortDirection"
                       [class.admin]="isAdmin"
                       class="fixed_headers mainList"
                >
                    <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns; let i = index;">
                        <th mat-header-cell mat-sort-header
                            *matHeaderCellDef
                            [disabled]="!canSort(item)"
                            [class.dateByDefault]="item === 'creationDate' && matSortActive === item && matSortDirection === 'desc'"
                        >{{ getColumnTitle(item) }}</th>
                    </ng-container>
                    <ng-container matColumnDef="noRecord">
                        <td mat-footer-cell
                            *matFooterCellDef
                            [attr.colspan]="(tableDefinition.length) ? tableDefinition.length : 3"
                            class="noResult noBorder"
                        >
                            <span *ngIf="!loading">{{ 'commons.noResults' | translate }}</span>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
                </table>
            </ng-container>
            <ng-template #results>
                <table mat-table
                       matSort
                       cdkDropListGroup
                       id="mainList"
                       [dataSource]="dataTable"
                       (matSortChange)="sortColumn($event)"
                       [matSortActive]="matSortActive"
                       [matSortDirection]="matSortDirection"
                        *ngIf='listsData'
                        class="fixed_headers"
                        [class.admin]="isAdmin"
                        (window:keydown)="onKeyList($event)"
                    >
                    <ng-container matColumnDef="{{item}}" *ngFor="let item of displayedColumns; let i = index;">
                        <th mat-header-cell mat-sort-header
                            *matHeaderCellDef
                            [disabled]="!canSort(item)"
                            [class.dateByDefault]="item === 'creationDate' && matSortActive === item && matSortDirection === 'desc'"
                            [class.ascDate]="item === 'creationDate' && matSortActive === item && matSortDirection === 'asc'"
                        >{{ getColumnTitle(item) }}</th>
                        <td mat-cell
                            *matCellDef="let element;  let j = index;"
                            (click)="onSelectItem([element, 'current'])"
                            [class.ngorange]="element.id == selectedItemId"
                            [class.selItem]="element.id == selectedItemId"
                            [id]="'tr_' + j"
                            [class.selectedItem]="clickMode && isMultiselected(element.id)"
                        >
                            <div *ngIf="((this.actualModule === 'people') && (item === 'status' || item === 'statusPrb' || item === 'statusIac')); else textEl">
                                <span *ngIf="item === 'status'">
                                    {{statusAbr(element, 'mpo')}}
                                </span>
                                <span *ngIf="item === 'statusPrb'">
                                    {{statusAbr(element, 'prb')}}
                                </span>
                                <span *ngIf="item === 'statusIac'">
                                    {{statusAbr(element, 'ra')}}
                                </span>
                                <span *ngIf="(getColumnTitle(item) === 'Statut' || getColumnTitle(item) === 'Status')"
                                >
                                    {{loGet(element, item)}}
                                </span>
                                <div *ngIf="getColumnTitle(item) === 'Questionnaire'">
                                    {{getQuestionnaireStatus(element)}}
                                </div>
                            </div>
                            <ng-template #textEl>
                                <div *ngIf="!isVContent(getColumnTitle(item))">
<!--                                    <div *ngIf="getColumnTitle(item) === 'Date'" class="truncate">-->
<!--                                    <div *ngIf="['creationDate', 'renewalDate'].includes(item)" class="truncate">-->
<!--                                        <span *ngIf="admData(element, item)">-->
<!--                                            {{admData(element, item)  | listFilter:clear(item)}}-->
<!--                                        </span>-->
<!--                                        <span *ngIf="!admData(element, item)">&#45;&#45;</span>-->
<!--                                    </div>-->
<!--                                    <div *ngIf="getColumnTitle(item) !== 'Date'" class="truncate">-->
                                        <ng-container *ngIf="item === 'totalCredits' || item === 'lastTransactionLevel'; else noColorDisplay">
                                            <ng-container *ngIf="item === 'lastTransactionLevel'">
                                                <div [tooltip]="ouputTransactionTooltip(loGet(element, item), lastTransactionWarningLevelColor(element, item))" [class]="lastTransactionWarningLevelColor(element, item)">
                                                    {{ loGet(element, item) }}
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item === 'totalCredits'">
                                                <div  [tooltip]="ouputLowCreditTooltip(loGet(element, item))" [class]="lowCreditWarningColor(element, item)">
                                                    {{ loGet(element, item) }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                        <ng-template #noColorDisplay>
                                            {{ loGet(element, item) }}
                                        </ng-template>
<!--                                    </div>-->
                                </div>
                                <div *ngIf="isVContent(getColumnTitle(item))">
                                    <div [style.paddingRight.em]="computePadding(getColumnTitle(item))">
                                        <span *ngIf="loGet(element, item)" class="checkedItem">✓</span>
                                    </div>
                                </div>
                            </ng-template>
                        </td>

                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-template>
        </div>
    </ng-template>
    <ng-template [ngIf]="view == 'cards'">
        <div>
            <div
                    *ngIf='listsData'
                    class="cards_container"
                    (window:keydown)="onKeyCards($event)"
            >
                <div>
                    <ng-template ngFor let-item [ngForOf]="listsData.data">
                        <div
                             #cardColumn
                             [class.col-md-6]="md === 6"
                             [class.col-md-4]="md === 4"
                             [class.col-md-3]="md === 3"
                             [class.col-md-2]="md === 2"
                             style="margin-bottom: 20px;
                                padding: 0 2px 0 2px"
                             (click)="onSelectItem([item, 'current'])"
                        >
                            <div style="margin: auto">
                                    <app-people-card
                                            [personOnCard]="item"
                                            [selectionArray]="peopleMultiselect.selectedItems"
                                            [selectedItemId]="selectedItemId"
                                            [decalageLeft]="decalageLeft"
                                            (activateReport)="transitActivateReport($event)"
                                            (requestSendMpoEvent) = "transitRequestSendMpoEvent($event)"
                                            (requestResendMpoEvent) = "transitRequestResendMpoEvent($event)"
                                            (requestSendIacEvent) = "transitRequestSendIacEvent($event)"
                                            (requestResendIacEvent) = "transitRequestResendIacEvent($event)"
                                            (onSelectedItem)="onSelectItem($event)"
                                            (checkableItem)="isChecked($event.id, $event.state)"
                                    ></app-people-card>
                                </div>
                            </div>
                    </ng-template>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </ng-template>
    <!--
        (click)="onSelectItem(item)"
        [class.ngorange]="item.id == selectedItemId"
    -->
</div>
