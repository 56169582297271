<div
    [class.slidingSidebar]="isFilter"
    [style.width]="width"
>
    <div *ngIf="isFilter" class="topHead">
        <div class="pull-left">
            <h2>
                {{ 'commons.displayManagement' | translate }}
            </h2>
        </div>
        <div (click)="handleCloseSearchFilters()" class="x-close pull-right" style="font-size: 20pt;">
            &#x00D7;
        </div>
        <div class="clearfix"></div>
        <h3 class="filtersPrefs">{{ 'commons.display' | translate }}</h3>
        <span (click)="handleResetSearchFilters()" class="fakeLink">
            {{ 'commons.reset' | translate }}
        </span>
    </div>
    <div class="clearfix"></div>
    <hr class="panelHr"/>
    <h3 class="fontLight" style="margin-top: 18px; font-size: 13pt">{{ 'commons.filters' | translate }}</h3>
    <div class="filterFamily">
        <div>
            <hr class="panelHr">
            <div style="width: 120px;" class="subTitle" [style.backgroundColor]="isFilter ? '#eee' : '#fff'">
                {{ 'admin.commons.accountType' | translate }}
            </div>
        </div>
        <div
            *ngIf="permissionsAccountType !== 'consultant' && permissionsAccountType !== 'client' && permissionsAccountType !== 'demo'"
            class="accountTypeContainer"
        >
            <ng-multiselect-dropdown
                [placeholder]="'commons.select' | translate"
                [settings]="dropDownSettings"
                [data]="levels[translate.currentLang]"
                [(ngModel)]="currentAccountTypes"
                (onSelect)="handleSetCurrentAccountTypes()"
                (onDeSelect)="handleSetCurrentAccountTypes()"
            >
            </ng-multiselect-dropdown>
        </div>
        <div class="margin-for-container">
            <hr class="panelHr">
            <div style="width: 50px;" class="subTitle" [style.backgroundColor]="isFilter ? '#eee' : '#fff'">
                {{ 'admin.listSearchFilters.status' | translate }}
            </div>
        </div>
        <div class="radio-container">
            <div class="pull-left">
                <div class="pull-left radio-container">
                    <input
                        type="radio"
                        id="activeStatus"
                        name="status"
                        class="radioAdmin firstRadioAdmin"
                        (change)="handleSetStatus(true)"
                        [checked]="status === true"
                    />
                </div>
                <div class="pull-left">
                    <label for="activeStatus">{{ 'admin.listSearchFilters.active' | translate }}</label>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="pull-left">
                <div class="pull-left radio-container">
                    <input
                        type="radio"
                        id="inactiveStatus"
                        name="status"
                        class="radioAdmin"
                        (change)="handleSetStatus(false)"
                        [checked]="status === false"
                    />
                </div>
                <div class="pull-left">
                    <label for="inactiveStatus">{{ 'admin.listSearchFilters.inactive' | translate }}</label>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="pull-left">
                <div class="pull-left radio-container">
                    <input
                        type="radio"
                        id="noStatus"
                        name="status"
                        class="radioAdmin"
                        (change)="handleSetStatus(null)"
                        [checked]="status === null"
                    />
                </div>
                <div class="pull-left">
                    <label for="noStatus">{{ 'admin.listSearchFilters.noStatus' | translate }}</label>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="clearfix"></div>
        </div>
        <ng-container *ngIf="isFilter">
            <div class="margin-for-container">
                <hr class="panelHr">
                <div style="width: 125px;" class="subTitle">{{ 'admin.listSearchFilters.creationDate' | translate }}
                </div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <p class="flat">{{ 'commons.start' | translate }}</p>
                    <div style="width: 95%">
                        <div class="datePicker startdp">
                            <my-date-picker
                                [locale]="translate.currentLang"
                                [selDate]="dateStart"
                                [options]="datePickerOptions"
                                [(ngModel)]="dateStartModel"
                                (dateChanged)="handleSetClientStartDate($event)"
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <p class="flat">{{ 'commons.end' | translate }}</p>
                        <div class="datePicker enddp">
                            <my-date-picker
                                [locale]="translate.currentLang"
                                [selDate]="dateEnd"
                                [options]="datePickerOptions"
                                [(ngModel)]="dateEndModel"
                                (dateChanged)="handleSetClientEndDate($event)"
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="whereAmI !== 'preferences'">
        <ng-container
            *ngIf="isNgenio"
        >

            <div class="margin-for-container">
                <hr class="panelHr">
                <div style="width: 88px;" class="subTitle">{{ 'admin.commons.subsidiary' | translate }}</div>
            </div>
            <div class="accountTypeContainer">
                <select class="form-control"
                        [(ngModel)]="subsidiarySelected"
                        (change)="handleSetSubsidiary(subsidiarySelected)"
                >
                    <ng-container *ngIf="subsidiaryList">
                        <option *ngFor="let subsidiary of subsidiaryList"
                                [value]="subsidiary['accountNumber']"
                        >{{ subsidiary.name }}</option>
                    </ng-container>
                </select>
            </div>
        </ng-container>
        <ng-container
            *ngIf="isOverDistributor"
        >
            <div class="margin-for-container">
                <hr class="panelHr">
                <div style="width:95px;" class="subTitle" [style.backgroundColor]="isFilter ? '#eee' : '#fff'">
                    <label for="distributorSel">{{ 'admin.commons.distributor' | translate }}</label>
                </div>
            </div>
            <div class="accountTypeContainer">
                <select id="distributorSel" class="form-control" style="max-width: 400px;"
                        [(ngModel)]="distributorSelected"
                        (change)="handleSetDistributor(distributorSelected)"
                        [disabled]="subsidiarySelected === '' && isOverDistributor"
                >
                    <option value=""
                            [selected]="distributorSelected === null || distributorSelected === ''">{{ 'commons.all' | translate }}</option>
                    <ng-container *ngIf="distributorsList">
                        <option *ngFor="let distributor of distributorsList"
                                [value]="distributor['accountNumber']"
                        >{{ distributor.name }}</option>
                    </ng-container>
                </select>
            </div>
        </ng-container>
        <ng-container
            *ngIf="isOverPartner"
        >
            <div class="margin-for-container">
                <hr class="panelHr">
                <div style="width: 85px;" class="subTitle" [style.backgroundColor]="isFilter ? '#eee' : '#fff'">
                    <label for="partnerSel">{{ 'admin.commons.partner' | translate }}</label>
                </div>
            </div>
            <div class="accountTypeContainer">
                <select id="partnerSel" class="form-control" style="max-width: 400px;"
                        [(ngModel)]="partnerSelected"
                        (change)="handleSetPartner(partnerSelected)"
                        [disabled]="distributorSelected === '' && isOverPartner"
                >
                    <option value=""
                            [selected]="partnerSelected === null || partnerSelected === ''">{{ 'commons.all' | translate }}</option>
                    <ng-container *ngIf="partnersList">
                        <option *ngFor="let partner of partnersList"
                                [value]="partner['accountNumber']"
                        >{{ partner.name }}</option>
                    </ng-container>
                </select>
            </div>
        </ng-container>
    </ng-container>
</div>
