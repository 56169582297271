<div class="sticky" (window:resize)="onResize($event)" *ngIf="!isLandscape || showPortraitButtons">
    <mat-grid-list cols="1" rowHeight="45px" class="fullView-header">
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <div>
                <h2 class="name" *ngIf="person.id">
                    {{ person.lastName + ' ' + person.firstName }}
                </h2>
                <div id="recordTypeSubTitle">
                    <span *ngIf="person.recordType ==='candidate'"
                    >{{ 'people.commons.candidate' | translate }}
                    </span>
                    <span *ngIf="person.recordType ==='employee'"
                    >{{ 'people.commons.employee' | translate }}
                    </span>
                </div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile
            colspan="1"
            rowspan="1"
        >
            <button *ngIf="!pdfRequest && !editRequest && !activationRequest && !mpoSendRequest && !prbSendRequest && !iacSendRequest && !resendRequest"
                    mat-stroked-button
                    (click)="goToList()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button *ngIf="pdfRequest || editRequest || activationRequest || iacSendRequest || mpoSendRequest || prbSendRequest || resendRequest"
                    mat-stroked-button
                    (click)="closeAllRequestsPanels()"
                    class="fullView-btn"
            ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

            <button
                mat-stroked-button
                *ngIf="!user.isSender"
                (click)="onPdfShare()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">launch</i> {{ 'commons.pdfReport' | translate }}</button>

            <button
                mat-stroked-button
                (click)="peopleEdit()"
                class="fullView-btn"
            ><i class="material-icons fullView-icon">edit</i> {{ 'commons.editInfo' | translate | truncate:[8, '']}}
            </button>
        </mat-grid-tile>
    </mat-grid-list>
    <hr (click)="hidePortraitButtons()" class="panelHr"/>
</div>
<a id="top"></a>
<div *ngIf="isLandscape" style="padding: 8px 0 0 17px;">
    <h2 class="name" *ngIf="person.id">
        {{ person.lastName + ' ' + person.firstName }}
    </h2>
    <button mat-mini-fab
            class="landscape-menu-btn"
            (click)="togglePortraitButtons()"
    >
        <mat-icon>menu</mat-icon>
    </button>
</div>
<div *ngIf="!user.isSender && pdfRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-pdf-sharing
        *ngIf="person.id"
        [report]="peopleList.state.report.type"
        (cancelPdf)="onPdfShare()"
    ></app-mobile-people-pdf-sharing>
</div>
<div *ngIf="editRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-edit
        *ngIf="person.id"
        (cancelEdit)="peopleEdit()"
    ></app-mobile-people-edit>
</div>
<div *ngIf="activationRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-report-activation
        [activationData]="activationData"
        (close)="closeAllRequestsPanels($event)"
    ></app-mobile-people-report-activation>
</div>
<div *ngIf="iacSendRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-request-send-resend
        [sendAData]="{'person': person.personToDisplay}"
        [raForm]="true"
        (close)="closeResendRequest($event)"
    ></app-mobile-people-request-send-resend>
</div>
<div *ngIf="mpoSendRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-request-send-resend
        [sendAData]="{'person': person.personToDisplay, report: mpoSendType}"
        (close)="closeAllRequestsPanels($event)"
    ></app-mobile-people-request-send-resend>
</div>
<div *ngIf="prbSendRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-request-send-resend
        [sendAData]="{'person': person.personToDisplay}"
        [prbForm]="true"
        (close)="closeAllRequestsPanels($event)"
    ></app-mobile-people-request-send-resend>
</div>
<div *ngIf="resendRequest" class="fullView-wrapper" [class.withPortraitButtons]="showPortraitButtons">
    <app-mobile-people-request-send-resend
        [sendAData]="resendData"
        [resendForm]="true"
        (close)="closeResendRequest($event)"
    ></app-mobile-people-request-send-resend>
</div>
<div *ngIf="!pdfRequest && !editRequest && !activationRequest && !mpoSendRequest && !prbSendRequest && !iacSendRequest && !resendRequest"
     class="fullView-wrapper"
     [class.withPortraitButtons]="showPortraitButtons"
     (click)="hidePortraitButtons()"
>
    <div class="informationPanel">
        <mat-expansion-panel [expanded]="expandInfoPanel" #matInfoPanel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h2>
                        {{ 'commons.informations' | translate }}
                    </h2>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <table class="infoTable" *ngIf="person.id">
                <tr>
                    <td><strong>{{ 'commons.email' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!person.email">
                            {{ person.email || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.title' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!person.jobTitle">
                            {{ person.jobTitle || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span
                            [class.na]="person.subAccount === null || person.subAccount === false"
                        >
                                {{ setSubAccountName(person.subAccount) || notSpecified() }}
                            </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.organisation' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!person.organisation">
                                {{ person.organisation || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!person.departmentToDisplay">
                            {{ person.departmentToDisplay || notSpecified() }}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td><strong>{{ 'people.requestModal.job' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                        <span [class.na]="!person.jobsJobTitle">
                            {{ person.jobsJobTitle || notSpecified()}}
                        </span>
                    </td>
                </tr>
                <ng-container *ngIf="stateService.hasMpoTestAccess">
                    <tr>
                        <td>
                            <hr/>
                            <h2>{{ 'people.reportMpo.infoOnMpo' | translate }}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Type<span [innerHTML]="titleSep"></span></strong>
                            <span style="text-transform: lowercase;">
                                <span *ngIf="questionnaireBlockTitles.first.type === '1,0'"> {{'people.commons.traitsOnly' | translate}}</span>
                                <span *ngIf="questionnaireBlockTitles.first.type === '1,1'"> {{'people.commons.traitsAndPrb' | translate}}</span>
                            </span>
                            <span *ngIf="person.requests"> {{questionnaireBlockTitles.first.type}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="withLink">
                            <strong>{{ 'people.commons.status' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            {{ testAndReportHandling.produceStatus('mpo') }}
                            <ng-container *ngIf="displayResendAndDeleteMpo">
                                <ng-container *ngIf="!user.isSender">
                                    <span *ngFor="let request of person.evaluationRequestsContainingOnly('personality')"
                                          class="pull-right padBorder0">
                                        <a (click)="testAndReportHandling.deleteMpoRequest(request.requestId)">
                                            {{ 'commons.delete' | translate }}
                                        </a>
                                    </span>
                                </ng-container>
                                <span *ngFor="let request of person.evaluationRequestsContainingOnly('mpo'); let i = index"
                                      class="pull-right sepBorderR">
                                    <a (click)="resendQuestionnaire(person.id, isNeededTempUser('mpo'))">
                                        {{ 'commons.resend' | translate }}
                                        <span *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                    </a>
                                </span>
                                <div class="clearfix"></div>
                            </ng-container>
                            <ng-container *ngIf="person.hasNoMpo || person.hasMpoOnHold">
                                <span class="pull-right padBorder0" *ngIf="person.hasNoMpo && !person.hasMpoOnHold">
                                    <a
                                        (click)="showMpoSendRequest('mpo', true)"
                                    >
                                        {{ 'commons.send' | translate}}
                                    </a>
                                </span>
                                <span *ngIf="person.hasMpoOnHold"
                                      class="pull-right {{person.hasNoMpo ? 'sepBorderR' : 'padBorder0'}}"
                                >
                                    <a (click)="activate('mpo', true)">
                                        {{'people.sidebar.activate' | translate }}
                                    </a>
                                </span>
                                <div class="clearfix"></div>
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.requestedBy' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span [class.na]="((!person.requestedByInfo ||
                                              !person.requestedByInfo.value ||
                                              person.canTakeMpo) && !person.hasRequest) ||
                                              (person.hasRequest && !person.mpoRequestRequestedBy)"
                            >
                                <span *ngIf="person.requestedByInfo &&
                                             person.requestedByInfo.value && person.hasMpo">
                                    {{ person.requestedByInfo.value}}
                                </span>
                                <span *ngIf="(!person.requestedByInfo ||
                                             !person.requestedByInfo.value || person.canTakeMpo) && !person.hasRequest">
                                    {{ notSpecified() }}
                                </span>
                                <span *ngIf="person.hasRequest">
                                    {{ person.mpoRequestRequestedBy || notSpecified() }}
                                </span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.sendingDate' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span *ngIf="person.hasMpo">
                                <span
                                    *ngIf="person.mpoCompletionDate"> {{ person.mpoCompletionDateToDisplay }}
                                </span>
                                <span
                                    *ngIf="!person.mpoCompletionDate"> {{ person.creationDateForDisplay }}
                                </span>
                            </span>
                            <span *ngIf="person.hasNoMpo" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                            <span *ngIf="person.mpoCompletionDate && person.mpoCompletionDate !== ''">
                                {{ person.mpoCompletionDate | date:translationItem('date') }}
                            </span>
                            <span *ngIf="!person.mpoCompletionDate || person.mpoCompletionDate === ''" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="person.personalityRequestedByInfo && person.personalityRequestedByInfo.fullName">
                        <td>
                            <strong>{{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span> </strong> {{ person.personalityRequestedByInfo.fullName }}
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="stateService.hasPrbTestAccess && !person.hasARequestSent('mpo', '1,1')">
                    <tr>
                        <td>
                            <hr/>
                            <h2>{{ 'people.reportMpo.infoOnPrb' | translate }}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td class="withLink">
                            <strong>{{ 'people.commons.status' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            {{ testAndReportHandling.extraPrbStatus }}
                            <!-- '0,1' case -->
                            <ng-container *ngIf="person.hasAPrbQuestionnaireSent">
                                <ng-container *ngIf="!user.isSender">
                                    <span *ngFor="let request of person.evaluationRequestsContainingOnly('prb')"
                                          class="pull-right padBorder0">
                                        <a (click)="testAndReportHandling.deletePrbRequest(request.requestId)">
                                            {{ 'commons.delete' | translate }}
                                        </a>
                                    </span>
                                </ng-container>
                                <span *ngFor="let request of person.evaluationRequestsContainingOnly('prb')"
                                      class="pull-right sepBorderR">
                                    <a (click)="resendQuestionnaire(person.id, isNeededTempUser('prb'), 'resendPrb')">
                                        {{ 'commons.resend' | translate }}
                                        <span *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                    </a>
                                </span>
                                <div class="clearfix"></div>
                            </ng-container>
                            <ng-container *ngIf="person.canTakeExtraPrb || person.hasPrbOnHold">
                                <span *ngIf="person.canTakeExtraPrb"
                                      class="pull-right padBorder0"
                                >
                                    <a (click)="showPrbSendRequest(true)">
                                        {{ 'commons.send' | translate}}
                                    </a>
                                </span>
                                <span *ngIf="person.hasPrbOnHold"
                                      class="pull-right {{person.canTakeExtraPrb ? 'sepBorderR' : 'padBorder0'}}"
                                >
                                    <a (click)="activate('mpo', true)">
                                        {{'people.sidebar.activate' | translate }}
                                    </a>
                                </span>
                                <div class="clearfix"></div>
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.requestedBy' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span
                                [class.na]="!person.requestedByInfo || !person.requestedByInfo.value || person.lastPrbReport">
                                <span
                                    *ngIf="person.requestedByInfo && person.requestedByInfo.value && person.lastPrbReport"> {{ person.requestedByInfo.value }}</span>
                                <span
                                    *ngIf="!person.requestedByInfo || !person.requestedByInfo.value || !person.lastPrbReport"> {{ notSpecified() }}</span>
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="testAndReportHandling.extraPrbStatus !== noneVal()">
                        <td>
                            <strong>{{ 'commons.sendingDate' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span *ngIf="person.lastPrbReport">
                                {{ person.lastPrbCompletionDateToDisplay }}
                            </span>
                            <span *ngIf="!person.lastPrbReport" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                    <tr *ngIf="testAndReportHandling.extraPrbStatus !== noneVal() && testAndReportHandling.extraPrbStatus !== notCompletedVal()">
                        <td>
                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                            <span *ngIf="prbCompletionDate && isExtraPrbSent">
                                {{ prbCompletionDate | date:translationItem('date') }}
                            </span>
                            <span *ngIf="!isExtraPrbSent" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="stateService.hasIac">
                    <tr>
                        <td>
                            <hr/>
                            <h2>{{ 'people.reportIac.infoOnIac' | translate }}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td class="withLink">
                            <strong>{{ 'people.commons.status' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            {{ testAndReportHandling.produceStatus('iac') }}
                            <ng-container *ngIf="displayResendAndDeleteIac">
                                <ng-container *ngIf="!user.isSender">
                                <span *ngFor="let request of person.evaluationRequestsContainingOnly('iac')"
                                      class="pull-right padBorder0">
                                    <a (click)="testAndReportHandling.deleteIacRequest(request.requestId)">
                                        {{ 'commons.delete' | translate }}
                                    </a>
                                </span>
                                </ng-container>
                                <span *ngFor="let request of person.evaluationRequestsContainingOnly('ra')"
                                      class="pull-right sepBorderR">
                                <a (click)="resendQuestionnaire(person.id, isNeededTempUser('ra'), 'resendIac')">
                                    {{ 'commons.resend' | translate }}
                                    <span *ngIf="!request['createTempUser']">{{ 'commons.externalQuestionnaire' | translate }}</span>
                                </a>
                            </span>
                                <div class="clearfix"></div>
                            </ng-container>
                            <ng-container
                                *ngIf="person.canTakeIac || person.hasIacOnHold"
                            >
                                <span class="pull-right padBorder0" *ngIf="person.canTakeIac">
                                    <a
                                        (click)="iacRequest()"
                                    >
                                        {{ 'commons.send' | translate}}
                                    </a>
                                </span>
                                <span *ngIf="person.hasIacOnHold"
                                      class="pull-right {{person.canTakeIac ? 'sepBorderR' : 'padBorder0'}}"
                                >
                                    <a (click)="activate('iac', true)">
                                        {{'people.sidebar.activate' | translate }}
                                    </a>
                                </span>
                                <div class="clearfix"></div>
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.requestedBy' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span
                                [class.na]="(!person.hasRequest && !person.iacReportRequestedBy) ||
                                            (person.hasRequest && !person.iacRequestRequestedBy)"
                            >
                                <span *ngIf="!person.hasRequest">
                                    {{ person.iacReportRequestedBy || notSpecified() }}
                                </span>
                                <span *ngIf="person.hasRequest">
                                    {{ person.iacRequestRequestedBy || notSpecified()}}
                                </span>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.sendingDate' | translate }}<span [innerHTML]="titleSep"></span> </strong>
                            <span *ngIf="person.iacDateToDisplay">
                                {{ person.iacDateToDisplay }}
                            </span>
                            <span *ngIf="!person.hasAnIacReport && !person.hasIacRequest" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>{{ 'commons.completionDate' | translate }}</strong>
                            <span *ngIf="person.iacCompletionDateToDisplay">
                                {{ person.iacCompletionDateToDisplay }}
                            </span>
                            <span *ngIf="!person.iacCompletionDateToDisplay" class="na">
                                {{ notSpecified() }}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </mat-expansion-panel>
    </div>
    <div *ngIf="user.isSender && (reportType === 'personality' || reportType === 'iac')" class="reportStatus">
        <button mat-mini-fab
                color="{{statusIcon(person.personToDisplay, reportType)}}">{{statusAbr(person.personToDisplay, reportType)}}</button>
    </div>
    <div *ngIf="!user.isSender && reportType === 'personality'">
        <h2>{{ 'commons.typicalProfile' | translate }}</h2>
        <h2 class="ngorange" id="sideBarPortrait"><span class="fontLight">{{testAndReportHandling.renderPortrait(person.portrait) | translate }}</span>
        </h2>
        <mat-grid-list cols="2" rowHeight="30px">
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <em class="alignLeft">{{ 'commons.personalityTraits' | translate }}</em>
            </mat-grid-tile>
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <div class="alignRight" *ngIf="person.hasPersonality">{{ person.personalityDateToDisplay }}</div>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="1" rowHeight="{{graphHeight}}px">
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <app-mpo-graph
                    [id]="'traits'"
                    [width]="graphWidth+'px'"
                    [mobile]="true"
                    [lang]="translate.currentLang"
                    [section]="'personality'"
                    [request]="person.checkRequestsContainsMpoTraits()"
                ></app-mpo-graph>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="{{prbGridCols}}" rowHeight="30px">
            <mat-grid-tile
                colspan="{{prbGridTile1}}"
                rowspan="1"
            >
                <em class="alignLeft">{{ 'commons.perceptionOfRequiredBehavior' | translate }}</em>
            </mat-grid-tile>
            <mat-grid-tile
                colspan="{{prbGridCols - prbGridTile1}}"
                rowspan="1"
            >
                <div *ngIf="prbToDisplay.exists" class="alignRight">
                    <span *ngIf="prbToDisplay['hasNext']" class="floatLeft"
                          (click)="setPrbIndex(prbToDisplay['nextIndex'])"><img alt=">"
                                                                                    src="{{buttonsFolder}}before.png"></span>
                    <span *ngIf="!prbToDisplay['hasNext']" class="floatLeft"><img alt=">"
                                                                                      src="{{buttonsFolder}}before_deactivated.png"></span>
                    <span class="floatLeft prbDate">{{ prbToDisplay.date | date:translationItem('date') }}</span>
                    <span *ngIf="prbToDisplay['hasPrev']" class="floatLeft"
                          (click)="setPrbIndex(prbToDisplay['prevIndex'])"><img alt=">"
                                                                                    src="{{buttonsFolder}}next.png"></span>
                    <span *ngIf="!prbToDisplay['hasPrev']" class="floatLeft"><img alt=">"
                                                                                      src="{{buttonsFolder}}next_deactivated.png"></span>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list *ngIf="prb" cols="1" rowHeight="{{graphHeight}}px">
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <app-mpo-graph
                    [id]="'prb'"
                    [width]="graphWidth+'px'"
                    [mobile]="true"
                    [lang]="translate.currentLang"
                    [section]="'personPrb'"
                    [scores]="prbToDisplay['scores']"
                    [comparisonScores]="person.personalityScores"
                    [request]="person.hasARequestSent('prb') || prbToDisplay['scores'] === 'request'"
                    [requestArr]="person.requests"
                ></app-mpo-graph>
            </mat-grid-tile>
        </mat-grid-list>
        <app-people-report-personality
            *ngIf="person.id && mpoGraphType !== 'inactive'"
            [width]="graphWidth"
            [mobile]="true"
        ></app-people-report-personality>
    </div>
    <div *ngIf="!user.isSender && person.id && reportType === 'satellite'">
        <div *ngIf="person.evaluations && person.satelliteReport &&
                    !person.hasSatellite && person.hasSatellite !== undefined">
            <p><a (click)="activate('satellite')">{{ 'commons.activate' | translate }} MPO Satellite</a></p>
        </div>
        <div class="satelliteJobsChoice">
            <ng-container *ngIf="satelliteJobs && satelliteJobs.length>0">
                <ng-container
                    *ngIf="!person.satelliteScore || person.satelliteScore=='noJobProfile'"
                >
                    <label>{{ 'people.sidebar.associatedJobProfileSatellite' | translate }}</label>
                    <mat-form-field
                        *ngIf="(satelliteJobs && person.satelliteReport)"
                    >
                        <mat-select
                            [(ngModel)]="person.satelliteScore && person.satelliteScore=='noJobProfile' ? '' : jobToDisplay"
                            (ngModelChange)="changeProfilersSatellite($event)"
                        >
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor="let job of satelliteJobs" [value]="job.id">
                                {{getProperty(job, 'jobTitle')}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="person.satelliteReport && person.satelliteReport.info" class="floatRight">
                        {{ person.satelliteReport.info.date | date:translationItem('date') }}
                    </span>
                    <span class="clear"></span>
                </ng-container>
                <ng-container
                    *ngIf="!(!person.satelliteScore || person.satelliteScore=='noJobProfile')"
                >
                    <label>{{ 'people.sidebar.associatedJobProfileSatellite' | translate }}</label>
                    <mat-form-field
                        *ngIf="(satelliteJobs && person.satelliteReport)"
                    >
                        <mat-select
                            [(ngModel)]="(person.hasSatelliteJobProfile && satelliteJob) ? satelliteJob : jobToDisplay"
                            (ngModelChange)="changeProfilersSatellite($event)"
                        >
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor="let job of satelliteJobs" [value]="job.id">
                                {{getProperty(job, 'jobTitle')}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span *ngIf="person.satelliteReport && person.satelliteReport.info" class="floatRight">
                        {{ person.satelliteReport.info.date | date:translationItem('date') }}
                    </span>
                    <span class="clear"></span>
                    <span *ngIf="loader" class="loaderSpan">
                        <app-local-loader
                            [lang]="translate.currentLang"
                            [state]="loader"
                        ></app-local-loader>
                    </span>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="(satelliteJobs && satelliteJobs.length == 0) || !satelliteJobs">
                {{'commons.noAssignedJob' | translate }} <br />
                {{'commons.contactAdmin' | translate }}
            </ng-container>
            <mat-grid-list cols="2" rowHeight="155px">
                <mat-grid-tile
                    colspan="1"
                    rowspan="1">
                    <div style="width:150px;">
                        <app-satellite-score-graph
                            [score]="person.satelliteScore"
                            [satelliteInfo]="person.satelliteInfo"
                            [request]="person.checkRequestsContains('isSurvey', 'mpo')"
                            [requestArr]="person.requests"
                        ></app-satellite-score-graph>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile
                    colspan="1"
                    rowspan="1"
                    *ngIf="person.hasSatelliteJobProfile && (satelliteJobs && person.satelliteReport)"
                    class="satelliteDescription"
                >
                    {{ satelliteDescription[translate.currentLang] }}
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="2" rowHeight="20px">
                <mat-grid-tile
                    colspan="1"
                    rowspan="1"
                    *ngIf="person.hasSatelliteJobProfile && (satelliteJobs && person.satelliteReport)"
                >
                    {{ satelliteLevel[translate.currentLang] }}
                </mat-grid-tile>
                <mat-grid-tile
                    colspan="1"
                    rowspan="1"
                >
                    &nbsp;
                </mat-grid-tile>
            </mat-grid-list>
            <br/>
            <div class="reportWrapper">
                <app-people-report-satellite
                    [satelliteScore]="person.satelliteScore"
                    [mobile]="true"
                ></app-people-report-satellite>
            </div>
        </div>
    </div>
    <div *ngIf="!user.isSender && reportType === 'communication'" class="graph-container">
        <div *ngIf="person.hasMpoOnHold">
            <p><a (click)="activate('communication')">{{ 'people.sidebar.activate' | translate }} {{ 'commons.communication' | translate }}</a></p>
        </div>
        <div *ngIf="config!='list'">
            <div *ngIf="communicationGraphType != 'inactive'" class="dateDiv">
                {{ person.communicationDateToDisplay }}
            </div>
            <div *ngIf="!communicationPrb">
                <mat-tab-group *ngIf="person.communicationReport; else communicationInactive"
                               (selectedIndexChange)="switchEvaluationType('communicationPrb')">
                    <mat-tab label="{{ 'commons.communicationIndividual' | translate }}">
                        <br/>
                        <app-idw-graph
                            [quad]="person.communicationReport.info['quad']"
                            [ad]="person.communicationReport.info['adaptability']"
                            [width]="graphWidth+'px'"
                            [graphType]="'active'"
                            [lang]="translate.currentLang"
                            [mobile]="true"
                        ></app-idw-graph>
                        <div class="reportWrapper">
                            <app-people-report-communication
                                [originReportType]="peopleList.state.report['originReportType']"
                            ></app-people-report-communication>
                        </div>
                    </mat-tab>
                    <mat-tab label="{{ 'commons.communicationPerception' | translate }}"></mat-tab>
                </mat-tab-group>
                <ng-template #communicationInactive>
                    <h2>{{ 'commons.communication' | translate }}</h2>
                    <br/>
                    <app-idw-graph
                        [quad]="communicationQuad"
                        [ad]="communicationAd"
                        [width]="graphWidth+'px'"
                        [graphType]="communicationGraphType"
                        [lang]="translate.currentLang"
                    ></app-idw-graph>
                </ng-template>
            </div>
            <div *ngIf="communicationPrb">
                <mat-tab-group
                    [selectedIndex]="1"
                    (selectedIndexChange)="switchEvaluationType('communication')"
                >
                    <mat-tab label="{{ 'commons.communicationIndividual' | translate }}"></mat-tab>
                    <mat-tab label="{{ 'commons.communicationPerception' | translate }}">
                        <br/>
                        <div *ngIf="prb && prb.exists">
                            <mat-grid-list cols="1" rowHeight="30px">
                                <mat-grid-tile
                                    colspan="1"
                                    rowspan="1"
                                >
                                    <div *ngIf="prb && prb.date" class="alignRight">
                                        <span *ngIf="getProperty(prb, 'hasPrev')" class="floatLeft"
                                              (click)="setPrbIndex(getProperty(prb, 'prevIndex'))"><img alt=">"
                                                                                                        src="{{buttonsFolder}}before.png"></span>
                                        <span *ngIf="!getProperty(prb, 'hasPrev')" class="floatLeft"><img alt=">"
                                                                                                          src="{{buttonsFolder}}before_deactivated.png"></span>
                                        <span class="floatLeft prbDate">{{ prb.date | date:translationItem('date') }}</span>
                                        <span *ngIf="getProperty(prb, 'hasNext')" class="floatLeft"
                                              (click)="setPrbIndex(getProperty(prb, 'nextIndex'))"><img alt=">"
                                                                                                        src="{{buttonsFolder}}next.png"></span>
                                        <span *ngIf="!getProperty(prb, 'hasNext')" class="floatLeft"><img alt=">"
                                                                                                          src="{{buttonsFolder}}next_deactivated.png"></span>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                        <app-idw-graph
                            [quad]="communicationPrbQuad"
                            [ad]="communicationPrbAd"
                            [width]="graphWidth+'px'"
                            [graphType]="communicationPrbGraphType"
                            [lang]="translate.currentLang"
                        ></app-idw-graph>
                        <div class="mobileReport">&nbsp;</div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
    <div *ngIf="!user.isSender && reportType === 'talents'" class="graph-container">
        <h2 class="floatLeft">{{ 'commons.aptitudesSphere' | translate }}</h2>
        <div *ngIf="person.hasTalents || person.hasTalentsOnHold" class="floatRight">
            {{ person.talentsDateToDisplay }}
        </div>
        <div class="clear"></div>
        <div [class.landscapeCenter]="isLandscape">
            <app-competences-graph
                [id]="'talents'"
                [scores]="person.talentsScore"
                [width]="graphWidth+'px'"
                [lang]="translate.currentLang"
                [type]="'global'"
                [mobile]="true"
            ></app-competences-graph>
        </div>
        <div class="reportWrapper">
            <app-people-report-talents
                [small]="true"
                [mobile]="true"
            ></app-people-report-talents>
        </div>
    </div>
    <div *ngIf="!user.isSender && reportType === 'iac' && (person.iacScores || person.hasMpoOnHold)" class="graph-container">
        <h2 class="floatLeft bold">{{ 'people.reportIac.resultTitle' | translate }}</h2>
        <div *ngIf="person.hasIac" class="floatRight">
            {{ person.iacDateToDisplay }}
        </div>
        <div class="clearfix"></div>
        <div id="iacBox">
            <div *ngIf="person.hasMpoOnHold; else notOnHold" style="position: relative;">
                <div class="iacOutlineOverlay"></div>
                <div class="iacOutlineTest">
                    {{ 'commons.onHold' | translate }}
                </div>
                <h2 *ngIf="person.jobsJobTitle" style="font-size: 14pt">{{ 'commons.job' | translate }}<span
                    [innerHTML]="titleSep"></span> &ndash; </h2>
                <h2 style="font-size: 14pt">
                    {{ 'people.reportIac.bruteResult' | translate }}
                    <span
                        class="ngorange"> &ndash; / 24 </span>
                </h2>
                <div>
                    <p class="bold">
                        <span class="mb10">
                            {{ 'people.reportIac.answeredQuestions' | translate }}
                            <span
                                class="ngorange"> &ndash; </span>
                        </span>
                        {{ 'people.reportIac.time' | translate }}<span
                        class="ngorange">  &ndash; </span>
                    </p>
                </div>
                <h2 style="font-size: 14pt">
                    {{ 'people.reportIac.normalizedResult' | translate }}
                    <span
                        class="ngorange"> &ndash; </span>
                </h2>
                <h2 style="font-size: 14pt">
                    {{'commons.jobTarget' | translate }}
                    <span style="color:#779cc5;">&nbsp; &ndash; </span>
                </h2>
            </div>
            <ng-template #notOnHold>
                <h2 *ngIf="person.jobsJobTitle" style="font-size: 14pt; margin-bottom: 10px;">{{ 'commons.job' | translate }}<span [innerHTML]="titleSep"></span> {{ person.jobsJobTitle }}</h2>
                <h2 class="resultBlock">
                    {{ 'people.reportIac.bruteResult' | translate }}
                    <span class="ngorange"> {{ person.iacInfo ? person.iacInfo['bruteScore'] + ' / 24' : '&ndash;' }} </span>
                </h2>
                <div>
                    <p class="bold">
                        {{ 'people.reportIac.answeredQuestions' | translate }} <span
                        class="ngorange">{{ person.iacInfo ? person.iacInfo['answeredQuestions'] : '&ndash;' }}</span><br/>
                        {{ 'people.reportIac.time' | translate }}<span
                        class="ngorange"> {{ person.iacTimeToString ? person.iacTimeToString : '&ndash;' }}</span>
                    </p>
                </div>
                <h2 class="resultBlock">
                    {{ 'people.reportIac.normalizedResult' | translate }} <span
                    class="ngorange">{{ person.iacScores ? person.iacScores['normalized'] : '&ndash;' }}</span>
                </h2>
                <h2 style="font-size: 14pt">
                    {{'commons.jobTarget' | translate }}
                    <span style="color:#779cc5;">&nbsp;{{ person.jobIac ? person.jobIac : '&ndash;' }}</span>
                </h2>
            </ng-template>
        </div>

        <hr/>
        <div *ngIf="person.id && !person.hasMpoOnHold">
            <app-people-report-iac
                [mobile]="true"
            ></app-people-report-iac>
        </div>
    </div>
    <div *ngIf="!user.isSender && reportType === 'iac' && !person.iacScores && !person.hasMpoOnHold" class="graph-container">
        <mat-grid-list cols="1" rowHeight="30px">
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <h2>{{ 'commons.cai' | translate }}</h2>
            </mat-grid-tile>
            <mat-grid-tile
                colspan="1"
                rowspan="1"
            >
                <h2>{{ 'people.commons.notCompleted' | translate }}</h2>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
<div
    *ngIf="(hasReportAccess || user.isSender) && (!isLandscape || showPortraitButtons)"
    class="sticky footer justifyCenter"
>
    <hr *ngIf="!pdfRequest && !editRequest && !activationRequest && !mpoSendRequest && !prbSendRequest && !iacSendRequest && !resendRequest"
        class="panelHr"
        (click)="hidePortraitButtons()"
    />
    <div *ngIf="!pdfRequest && !editRequest && !activationRequest && !mpoSendRequest && !prbSendRequest && !iacSendRequest && !resendRequest"
         class="mobileFooter">
        <mat-grid-list *ngIf="!user.isSender" cols="{{colsNumber}}" rowHeight="62px">
            <mat-grid-tile
                *ngIf="stateService.hasPersonality"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('personality')"
            >
                <div *ngIf="person.personalityScores; else buyMPO" class="cardEvaluation">
                    <app-mini-mpo-graph
                        [score]="person.personalityScores"
                        [interaction]="'cards'"
                        [noRemove]="true"
                    ></app-mini-mpo-graph>
                </div>
                <ng-template #buyMPO>
                    <div class="cardEvaluation" *ngIf="personalityIconTitle">
                        <app-people-card-no-report
                            [id]="person.id"
                            [type]="'activate.4'"
                            [which]="'personality'"
                            [hasMpoReport]="person.hasPersonality"
                            [anotherTitle]="personalityIconTitle"
                        ></app-people-card-no-report>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasSatellite"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('satellite')"
            >
                <div *ngIf="person.satelliteScore; else buySatellite" class="cardEvaluation">
                    <app-mini-satellite-graph
                        [score]="person.satelliteScore"
                        [from]="'report'"
                    ></app-mini-satellite-graph>
                </div>
                <ng-template #buySatellite>
                    <div class="cardEvaluation" *ngIf="satelliteIconTitle">
                        <app-people-card-no-report
                            [id]="person.id"
                            [type]="'activate.1'"
                            [which]="'satellite'"
                            [hasMpoReport]="person.hasMpo"
                            [anotherTitle]="satelliteIconTitle"
                        ></app-people-card-no-report>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasCommunication"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('communication')"
            >
                <div *ngIf="(person.communicationReport && person.communicationReport.info['quad'] && !testAndReportHandling.borderlineCase); else buyIDW" class="cardEvaluation">

                    <app-mini-idw-graph
                        [quad]="person.communicationReport.info['quad']"
                        [type]="'cards'"
                    ></app-mini-idw-graph>
                </div>
                <ng-template #buyIDW>
                    <div class="cardEvaluation" *ngIf="communicationIconTitle">
                        <app-people-card-no-report
                            [id]="person.id"
                            [type]="'activate.2'"
                            [which]="'communication'"
                            [anotherTitle]="communicationIconTitle"
                        ></app-people-card-no-report>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasTalents"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('talents')"
            >
                <div *ngIf="person.talentsScore; else buyTalents" class="cardEvaluation">

                    <app-mini-competences-graph
                        [id]="'talents_mini'"
                        [scores]="person.talentsScore"
                    ></app-mini-competences-graph>
                </div>
                <ng-template #buyTalents>
                    <div class="cardEvaluation">
                        <div class="cardEvaluation" *ngIf="talentsIconTitle">
                            <app-people-card-no-report
                                [id]="person.id"
                                [type]="'activate.2'"
                                [which]="'talents'"
                                [hasMpoReport]="person.hasMpo"
                                [anotherTitle]="talentsIconTitle"
                            ></app-people-card-no-report>
                        </div>
                    </div>
                </ng-template>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasIac"
                colspan="1"
                rowspan="1"
                (click)="switchEvaluationType('iac')"
            >
                <div *ngIf="person.iacScores; else buyIac" class="cardEvaluation">
                    <app-mini-iac-graph
                        [id]="'iac_mini'"
                        [score]="person.iacScores['normalized']"
                    ></app-mini-iac-graph>
                </div>
                <ng-template #buyIac>
                    <div class="cardEvaluation">
                        <div class="cardEvaluation">
                            <app-people-card-no-report
                                *ngIf="iacIconTitle"
                                [id]="person.id"
                                [type]="'activate.2'"
                                [which]="'iac'"
                                [anotherTitle]="iacIconTitle"
                            ></app-people-card-no-report>
                            <app-people-card-no-report
                                *ngIf="!iacIconTitle"
                                [id]="person.id"
                                [type]="'activate.2'"
                                [which]="'iac'"
                            ></app-people-card-no-report>
                        </div>
                    </div>
                </ng-template>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list *ngIf="person.personalityScores ||
                         person.satelliteScore ||
                         communicationQuad ||
                         person.talentsScore ||
                         (person.iacScores && stateService.hasIac) ||
                         user.isSender"
                       [cols]="user.isSender ? 2 : colsNumber" [rowHeight]="user.isSender ? '62px' : '20px'">
            <mat-grid-tile
                *ngIf="stateService.hasPersonality"
                colspan="1"
                rowspan="1"
            >
                <div style="width: 100%">
                    <p *ngIf="!user.isSender" class="cardEvaluationTitle">{{ 'commons.personality' | translate }}</p>
                    <p *ngIf="user.isSender"
                       (click)="switchEvaluationType('personality')"
                       class="cardEvaluationTitle"
                    ><span
                        [class]="reportType === 'personality' ? 'activeTab' : null"
                    >{{ 'people.commons.status' | translate }} MPO</span></p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasSatellite && !user.isSender"
                colspan="1"
                rowspan="1"
            >
                <div style="width: 100%">
                    <p class="cardEvaluationTitle">Satellite</p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasCommunication && !user.isSender"
                colspan="1"
                rowspan="1"
            >
                <div
                    style="width: 100%;
                    position: fixed"
                >
                    <p
                        class="cardEvaluationTitle"
                        style="z-index: 2;
                       position: relative;"
                    >{{ 'commons.communication' | translate }}</p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile
                *ngIf="stateService.hasTalents && !user.isSender"
                colspan="1"
                rowspan="1"
            >
                <div style="width: 100%">
                    <p class="cardEvaluationTitle">{{ 'commons.talents' | translate }}</p>
                </div>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="stateService.hasIac"
                           colspan="1"
                           rowspan="1"
            >
                <div style="width: 100%">
                    <p *ngIf="!user.isSender"
                       class="cardEvaluationTitle">{{ 'commons.cai' | translate }}</p>
                    <p *ngIf="user.isSender"
                       (click)="switchEvaluationType('iac')"
                       class="cardEvaluationTitle"
                    ><span
                        [class]="reportType === 'iac' ? 'activeTab' : null"
                    >{{'people.commons.status' | translate}} {{ 'commons.cai' | translate }}</span>
                    </p>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
