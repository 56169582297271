<h2 style="margin-top: 15px; font-family: MontserratSemiBold,  sans-serif; font-size: 22px;">{{ 'jobs.commons.jobInfoIac' | translate }}</h2>

<ng-container *ngIf="rightMatchService.profilerInfo.id && rightMatchService.profilerInfo.RA; else noIac">
    <ul class="plainList">
        <li *ngIf="rightMatchService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span
            [innerHTML]="titleSep"></span> {{ rightMatchService.jobTitle }}</li>
        <li>{{ 'commons.date' | translate }}<span
            [innerHTML]="titleSep"></span> {{ (rightMatchService.profilerInfo.creationDate || rightMatchService.profilerInfo.date) | date:dateFormat}}
        </li>
    </ul>
    <hr/>
    <div *ngIf="rightMatchService.profilerInfo.RA" id="graphBlock">
        <div class="raJobProfile">
            <em>{{ 'commons.personalityTraitsJob' | translate }}</em>
        </div>
        <div class="clearfix"></div>
        <div style="max-width: 1000px">
            <img src="assets/graphicalElements/nCurve.png" alt=""/>
            <app-iac-graph
                [id]="'report_' + rightMatchService.profilerInfo.id"
                [normalizedScore]="null"
                [jobScore]="rightMatchService.profilerInfo.RA"
            ></app-iac-graph>
        </div>
    </div>
    <ng-container
        *ngIf="raTexts"
    >
        <h2 style="margin-top: 15px">{{ 'jobs.sidebar.caiBoxIntro' | translate }}</h2>
        <h3>{{ 'commons.workEnvironment' | translate }}</h3>
        <p>{{ raTexts.env }}</p>
        <h3>{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
        <p>{{ raTexts['job'] }}</p>
    </ng-container>
</ng-container>
<ng-template #noIac>
    <img src="assets/graphicalElements/nCurve.png" style="opacity: 0.35;" alt=""/>
    <svg id="iacMainGraph" preserveAspectRatio="xMidYMid meet" viewBox="0 0 980 55">
        <rect x="0" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="50" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="110" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="160" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="220" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="270" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="330" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="380" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="440" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="490" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="550" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="600" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="660" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="710" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="770" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="820" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
        <rect x="880" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
        <circle cx="930" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
    </svg>
    <br/>
    <h2 class="fullWidth"><span>&nbsp;</span></h2>
    <ng-container *ngFor="let i of [0,1]">
        <h3 class="reportTitle"><span>&nbsp;</span></h3>
        <p class="georgia">
            <span *ngFor="let j of [0,1]">&nbsp;</span>
        </p>
    </ng-container>
</ng-template>
