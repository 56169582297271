<h2><span>&nbsp;</span></h2>
<ul class="plainList {{for}}Ul">
    <li *ngFor="let i of [1,2,3,4]">
        <div>&nbsp;</div>
    </li>
    <li *ngIf="for === 'satellite'" class="lastLi">
        <div>&nbsp;</div>
    </li>
</ul>

<hr/>

<ng-container *ngIf="for === 'personality'">

    <h2 class="reportTitle"><span>&nbsp;</span></h2>

    <!-- Traits Summary -->
    <h3 class="reportTitle"><span>&nbsp;</span></h3>
    <hr/>

    <div class="emFirstLine">&nbsp;</div>
    <hr/>

    <div id="traitSummary">
        <ng-container *ngFor="let trait of traitSummary; let i = index">
            <h4 class="traitsTitle"><span>&nbsp;</span></h4>
            <svg id="traitGraph_trait_OR" width="506.25" height="70" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 675 65">
                <rect x="0" y="50" width="650" height="10" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
                <circle cy="55" r="18.5" stroke="rgb(242, 214, 199)" stroke-width="3" style="fill: white;"
                        [style.cx]="trait.cx"></circle>
                <rect x="0" y="16" width="100" height="18" style="fill:rgb(238, 238, 238);"/>
                <rect x="550" y="16" width="100" height="18" style="fill:rgb(238, 238, 238);"/>
            </svg>
            <p class="georgia">
                <span class="fw">&nbsp;</span>
                <span *ngIf="i > 0">&nbsp;</span>
                <span *ngIf="i > 1">&nbsp;</span>
            </p>
        </ng-container>
    </div>

    <!-- Predictable behaviour -->
    <h3 class="reportTitle"><span>&nbsp;</span></h3>
    <hr/>
    <div class="emFirstLine">&nbsp;</div>
    <div class="emSecLine">&nbsp;</div>
    <hr/>
    <div id="predictableBehavior">
        <ng-container *ngFor="let i of [1,2,3]">
            <div class="reportMiniGraphHeader">
                <div style="float:left">
                    <svg preserveAspectRatio="xMidYMid meet" width="60" height="60" viewBox="0 0 240 240"
                         style="background-color: rgb(238, 238, 238);">
                        <circle cx="120" cy="120" r="110"
                                style="fill: white; stroke-width: 6; stroke: rgb(226, 226, 226);"></circle>
                        <line x1="165" y1="66" x2="165" y2="102" stroke-width="2" stroke="black"></line>
                        <line x1="165" y1="102" x2="75" y2="138" stroke-width="2" stroke="black"></line>
                        <line x1="75" y1="138" x2="120" y2="174" stroke-width="2" stroke="black"></line>
                        <circle cx="165" cy="66" r="14" stroke-width="2" stroke="#232323"
                                style="fill: rgb(222, 172, 146);"></circle>
                        <circle cx="165" cy="102" r="14" stroke-width="2" stroke="#232323"
                                style="fill: rgb(255, 255, 255);"></circle>
                        <circle cx="75" cy="138" r="14" stroke-width="2" stroke="#232323"
                                style="fill: rgb(222, 172, 146);"></circle>
                        <circle cx="120" cy="174" r="14" stroke-width="2" stroke="#232323"
                                style="fill: rgb(255, 255, 255);"></circle>
                    </svg>
                </div>
                <div class="reportMiniGraphTitle">
                    <h4 class="innerTitleH4"><span>&nbsp;</span></h4>
                    <h5><span>&nbsp;</span></h5>
                </div>
                <div class="clearfix"></div>
            </div>
            <p class="georgia" *ngFor="let j of [1,2,3]">
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </p>
        </ng-container>
    </div>

    <!-- Profile -->
    <h3 class="reportTitle"><span>&nbsp;</span></h3>
    <hr/>
    <div class="emFirstLine" *ngFor="let i of [1,2,3]">&nbsp;</div>
    <div class="emSecLine">&nbsp;</div>
    <hr/>
    <div id="portrait">
        <h4><span>&nbsp;</span></h4>
        <p class="georgia">
            <span *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]">&nbsp;</span>
        </p>
        <ng-container *ngFor="let i of [1,2,3,4]">
            <h4><span>&nbsp;</span></h4>
            <ul class="georgia">
                <li *ngFor="let j of [1,2,3,4]">
                    <div class="portraitList">&nbsp;</div>
                </li>
            </ul>
        </ng-container>
    </div>

    <h2><span>&nbsp;</span></h2>
    <div class="emFirstLine" *ngFor="let i of [1,2]">&nbsp;</div>
    <div class="emSecLine">&nbsp;</div>
    <hr/>
    <div id="prb">
        <ng-container *ngFor="let i of traitPrb">
            <h4 class="traitsTitle"><span>&nbsp;</span></h4>
            <svg id="traitGraph_trait_OR" width="506.25" height="67" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 675 65">
                <rect x="0" y="50" width="650" height="10" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
                <circle cy="55" r="18.5" stroke="rgb(222, 172, 146)" stroke-width="3" style="fill: white;"
                        [style.cx]="i.cx[0]"></circle>
                <rect x="0" y="16" width="100" height="18" style="fill:rgb(184, 184, 184);"/>
                <rect x="550" y="16" width="100" height="18" style="fill:rgb(184, 184, 184);"/>
            </svg>
            <svg id="traitGraph_trait_OR" width="506.25" height="50" preserveAspectRatio="xMidYMid meet"
                 viewBox="0 0 675 65">
                <rect x="0" y="15" width="650" height="10" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
                <circle cy="20" r="18.5" stroke="rgb(222, 172, 146)" stroke-width="3" style="fill: white;"
                        [style.cx]="i.cx[1]"></circle>
            </svg>
            <p class="georgia"><span class="fw">&nbsp;</span></p>
            <ul class="georgia">
                <li *ngFor="let j of [1,2,3]">
                    <div class="portraitList">&nbsp;</div>
                </li>
            </ul>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="for === 'satellite'">
    <h4><span>&nbsp;</span></h4>
    <h3><span>&nbsp;</span></h3>
    <p>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
    </p>

    <hr/>
    <h3 class="reportTitle"><span>&nbsp;</span></h3>
    <hr/>

    <p class="georgia" *ngFor="let i of [1,2,3,4,5,6]">
        <span *ngFor="let j of [1,2,3]">&nbsp;</span>
    </p>

    <div id="portraitSat">
        <ng-container *ngFor="let i of [1,2,3,4]">
            <h4><span>&nbsp;</span></h4>
            <ul class="georgia">
                <li *ngFor="let j of [1,2,3,4]">
                    <div class="portraitList">&nbsp;</div>
                </li>
            </ul>
        </ng-container>
    </div>

</ng-container>

<ng-container *ngIf="for === 'communication'">
    <h2 class="reportTitle"><span>&nbsp;</span></h2>

    <h3 class="reportTitle"><span>&nbsp;</span></h3>
    <hr/>

    <div id="{{portraitId}}">
        <h4><span>&nbsp;</span></h4>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>

        <ng-container *ngFor="let i of [1,2,3]">
            <p><span>&nbsp;</span></p>
            <ul class="communicationUl">
                <li *ngFor="let j of [1,2,3,4,5,6,7]">
                    <div class="portraitList">&nbsp;</div>
                </li>
            </ul>
        </ng-container>

        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>

        <p><span class="fw">&nbsp;</span></p>

        <ng-container *ngFor="let i of [1,2,3,4,5,6,7]">
            <h4><span>&nbsp;</span></h4>
            <p *ngFor="let j of [1,2,3]"><span class="fw">&nbsp;</span></p>
        </ng-container>

        <table class="DITInteractionTitleWithImage">
            <tr>
                <td>
                    <svg preserveAspectRatio="xMidYMid meet" width="30" height="30" viewBox="0 0 40 40">
                        <circle cx="20" cy="20" r="18"
                                style="fill: white; stroke-width: 1; stroke: rgb(226, 226, 226);"></circle>
                        <path transform="translate(20,20)" class="arc"
                              d="M18,0A18,18,0,0,1,1.102182119232618e-15,18L7.347880794884119e-16,12A12,12,0,0,0,12,0Z"
                              fill="rgb(238,207,123)"></path>
                    </svg>
                </td>
                <td class="DITInteractionTitleContainer"><span>&nbsp;</span></td>
            </tr>
        </table>
        <p><span *ngFor="let i of [1,2,3]">&nbsp;</span></p>

        <p><span>&nbsp;</span></p>
        <ul class="communicationUl">
            <li *ngFor="let j of [1,2,3,4]">
                <div class="portraitList">&nbsp;</div>
            </li>
        </ul>

        <table class="DITInteractionTitleWithImage">
            <tr>
                <td>
                    <svg preserveAspectRatio="xMidYMid meet" width="30" height="30" viewBox="0 0 40 40">
                        <circle cx="20" cy="20" r="18"
                                style="fill: white; stroke-width: 1; stroke: rgb(226, 226, 226);"></circle>
                        <path transform="translate(20,20)" class="arc"
                              d="M1.102182119232618e-15,18A18,18,0,0,1,-18,2.204364238465236e-15L-12,1.4695761589768238e-15A12,12,0,0,0,7.347880794884119e-16,12Z"
                              fill="rgb(119,156,197)"></path>
                    </svg>
                </td>
                <td class="DITInteractionTitleContainer"><span>&nbsp;</span></td>
            </tr>
        </table>
        <p><span *ngFor="let i of [1,2,3]">&nbsp;</span></p>

        <p><span>&nbsp;</span></p>
        <ul class="communicationUl">
            <li *ngFor="let j of [1,2,3,4]">
                <div class="portraitList">&nbsp;</div>
            </li>
        </ul>

        <table class="DITInteractionTitleWithImage">
            <tr>
                <td>
                    <svg preserveAspectRatio="xMidYMid meet" width="30" height="30" viewBox="0 0 40 40">
                        <circle cx="20" cy="20" r="18"
                                style="fill: white; stroke-width: 1; stroke: rgb(226, 226, 226);"></circle>
                        <path transform="translate(20,20)" class="arc"
                              d="M1.102182119232618e-15,-18A18,18,0,0,1,18,0L12,0A12,12,0,0,0,7.347880794884119e-16,-12Z"
                              fill="rgb(240,123,126)"></path>
                    </svg>
                </td>
                <td class="DITInteractionTitleContainer"><span>&nbsp;</span></td>
            </tr>
        </table>
        <p><span *ngFor="let i of [1,2,3]">&nbsp;</span></p>

        <p><span>&nbsp;</span></p>
        <ul class="communicationUl">
            <li *ngFor="let j of [1,2,3,4]">
                <div class="portraitList">&nbsp;</div>
            </li>
        </ul>

        <table class="DITInteractionTitleWithImage">
            <tr>
                <td>
                    <svg preserveAspectRatio="xMidYMid meet" width="30" height="30" viewBox="0 0 40 40">
                        <circle cx="20" cy="20" r="18"
                                style="fill: white; stroke-width: 1; stroke: rgb(226, 226, 226);"></circle>
                        <path transform="translate(20,20)" class="arc"
                              d="M-18,2.204364238465236e-15A18,18,0,0,1,-3.3065463576978533e-15,-18L-2.204364238465236e-15,-12A12,12,0,0,0,-12,1.4695761589768238e-15Z"
                              fill="rgb(119,198,175)"></path>
                    </svg>
                </td>
                <td class="DITInteractionTitleContainer"><span>&nbsp;</span></td>
            </tr>
        </table>
        <p><span *ngFor="let i of [1,2,3]">&nbsp;</span></p>

        <p><span>&nbsp;</span></p>
        <ul class="communicationUl">
            <li *ngFor="let j of [1,2,3,4]">
                <div class="portraitList">&nbsp;</div>
            </li>
        </ul>
    </div>
</ng-container>

<ng-container *ngIf="for === 'talents'">
    <h2 class="pull-left talentH2 m0"><span>&nbsp;</span></h2>
    <span class="pull-right caret mt8"></span>
    <h2 class="pull-right talentH2 m0"><span>&nbsp;</span></h2>
    <div class="clearfix"></div>
    <div class="instructions">
        <hr>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>
    </div>
    <hr/>
    <table style="width: 100%">
        <tr>
            <td *ngFor="let i of [0,1]" style="width: 50%; padding: 1.7em 0 0.9em 0;">
                <span class="boldTitle bTAptitudes">&nbsp;</span>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" style="height: 8em; vertical-align: top;" [class.paddingL9]="i === 0">
                <p>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </p>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" class="alignCenter">
                <app-competences-graph
                    [id]="'talents_'+i"
                    [scores]="competencesScores"
                    [lang]="translate.currentLang"
                    [type]="i"
                    [fake]="true"
                    [mobile]="false"
                ></app-competences-graph>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" style="width: 50%; padding: 1.7em 0 0.9em 0;">
                <span class="boldTitle bTAptitudes">&nbsp;</span>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" style="height: 8em; vertical-align: top;" [class.paddingL9]="i === 0">
                <p>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </p>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [2,3]" class="alignCenter">
                <app-competences-graph
                    [id]="'talents_'+i"
                    [scores]="competencesScores"
                    [lang]="translate.currentLang"
                    [type]="i"
                    [fake]="true"
                    [mobile]="false"
                ></app-competences-graph>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" style="width: 50%; padding: 1.7em 0 0.9em 0;">
                <span class="boldTitle bTAptitudes">&nbsp;</span>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [0,1]" style="height: 8em; vertical-align: top;" [class.paddingL9]="i === 0">
                <p>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </p>
            </td>
        </tr>
        <tr>
            <td *ngFor="let i of [4,5]" class="alignCenter">
                <app-competences-graph
                    [id]="'talents_'+i"
                    [scores]="competencesScores"
                    [lang]="translate.currentLang"
                    [type]="i"
                    [fake]="true"
                    [mobile]="false"
                ></app-competences-graph>
            </td>
        </tr>
    </table>

    <hr/>
    <h2 class="pull-left talentH2"><span>&nbsp;</span></h2>
    <span class="pull-right caret"></span>
    <h2 class="pull-right talentH2"><span>&nbsp;</span></h2>
    <div class="clearfix"></div>
    <div class="instructions">
        <hr>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>
    </div>
    <hr/>
    <table class="talentContainer">
        <tr *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
            <td *ngFor="let j of [1,2]" [class.paddingL9]="j === 1">
                <h3 class="reportTitle"><span>&nbsp;</span></h3>
                <svg width="237.5" height="36.25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 750 105">
                    <rect x="0" y="40" width="750" height="20" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
                    <circle cx="221" cy="45" r="32.5" stroke="#779cc5" stroke-width="9" style="fill: white;"></circle>
                </svg>
                <p>
                    <span>&nbsp;</span>
                    <span>&nbsp;</span>
                </p>
            </td>
        </tr>
    </table>

    <hr/>
    <h2 class="pull-left talentH2"><span>&nbsp;</span></h2>
    <span class="pull-right caret"></span>
    <h2 class="pull-right talentH2"><span>&nbsp;</span></h2>
    <div class="clearfix"></div>
    <div class="instructions">
        <hr>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>
    </div>
    <hr/>
    <table class="talentContainer">
        <tr *ngFor="let i of [1,2,3,4,5,6,7,8,9]">
            <td *ngFor="let j of [1,2,3,4]" [class.paddingL9]="j%2 > 0" style="width: 25%;">
                <div *ngIf="j%2 > 0" style="margin-top: 10px;"><span>&nbsp;</span></div>
                <svg *ngIf="j%2 === 0" width="180" height="26" preserveAspectRatio="xMidYMid meet" viewBox="0 0 650 80">
                    <rect x="0" y="40" width="650" height="20" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
                    <circle *ngIf="j === 2" cx="516" cy="45" r="32.5" stroke="#78c7af" stroke-width="9"
                            style="fill: white;"></circle>
                    <circle *ngIf="j === 4" cx="321" cy="45" r="32.5" stroke="#efce7c" stroke-width="9"
                            style="fill: white;"></circle>
                </svg>
            </td>
        </tr>
    </table>

    <hr/>
    <h2 class="pull-left talentH2"><span>&nbsp;</span></h2>
    <span class="pull-right caret"></span>
    <h2 class="pull-right talentH2"><span>&nbsp;</span></h2>
    <div class="clearfix"></div>
    <p><span>&nbsp;</span></p>
    <div class="instructions">
        <hr>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>
    </div>
    <hr/>
    <h2><span>&nbsp;</span></h2>
    <table class="talentContainer">
        <tr *ngFor="let i of [1,2]">
            <td *ngFor="let j of [1,2]" [class.paddingL9]="j === 1">
                <ng-container *ngIf="i === 1 || (i > 1 && j < 2)">
                    <h3 class="reportTitle"><span>&nbsp;</span></h3>
                    <svg width="282.5" height="36.25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 930 105">
                        <rect x="0" y="40" width="930" height="20" stroke="none"
                              style="fill: rgb(217, 217, 217);"></rect>
                        <rect *ngIf="j%2 > 0" x="613" y="0" rx="50" ry="50" width="285" height="90" fill="none"
                              stroke-width="6" stroke="#f17c7f"></rect>
                        <rect *ngIf="j%2 === 0" x="328" y="0" rx="50" ry="50" width="285" height="90" fill="none"
                              stroke-width="6" stroke="#78c7af"></rect>
                        <circle *ngIf="i === 1" cx="376" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)"
                                stroke-width="9" style="fill: white;"></circle>
                        <circle *ngIf="i > 1" cx="566" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" stroke-width="9"
                                style="fill: white;"></circle>
                    </svg>
                    <p>
                        <span>&nbsp;</span>
                        <span>&nbsp;</span>
                    </p>
                </ng-container>
            </td>
        </tr>
    </table>

    <hr/>
    <h2 class="pull-left talentH2"><span>&nbsp;</span></h2>
    <span class="pull-right caret"></span>
    <h2 class="pull-right talentH2"><span>&nbsp;</span></h2>
    <div class="clearfix"></div>
    <p><span>&nbsp;</span></p>
    <div class="instructions">
        <hr>
        <p>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
        </p>
    </div>
    <hr/>
    <ng-container *ngFor="let i of [1,2,3]">
        <h3 class="reportTitle"><span>&nbsp;</span></h3>
        <ul class="communicationUl">
            <li *ngFor="let j of [1,2,3,4]">
                <div class="portraitList">&nbsp;</div>
            </li>
        </ul>
    </ng-container>
</ng-container>

<ng-container *ngIf="for === 'iac'">
    <div style="max-width: 1000px">
        <div class="legend">
            <span>&nbsp;</span> &nbsp;<span class="orange">&nbsp;</span> &nbsp;&nbsp;
            <span>&nbsp;</span> &nbsp;<span class="blue">&nbsp;</span>
        </div>
        <img src="assets/graphicalElements/nCurve.png" style="opacity: 0.35;" alt=""/>
        <svg id="iacMainGraph" preserveAspectRatio="xMidYMid meet" viewBox="0 0 980 55">
            <rect x="0" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="50" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7" style="fill: white;"></circle>
            <rect x="110" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="160" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="220" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="270" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="330" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="380" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="440" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="490" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="550" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="600" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="660" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="710" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="770" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="820" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
            <rect x="880" y="12" width="100" height="28" stroke="none" style="fill: rgb(238, 238, 238);"></rect>
            <circle cx="930" cy="25" r="15.5" stroke="rgb(238, 238, 238)" stroke-width="7"
                    style="fill: white;"></circle>
        </svg>
    </div>
    <h2><span>&nbsp;</span></h2>
    <p><span *ngFor="let i of [1,2]">&nbsp;</span></p>

    <h2><span>&nbsp;</span></h2>
    <ul class="communicationUl">
        <li *ngFor="let i of [1,2]">
            <div class="portraitList">&nbsp;</div>
        </li>
    </ul>
    <br/>

    <h2><span>&nbsp;</span></h2>
    <ul class="communicationUl">
        <li *ngFor="let i of [1,2,3,4,5,6]">
            <div class="portraitList">&nbsp;</div>
        </li>
    </ul>
    <br/>

    <h4><span>&nbsp;</span></h4>
    <p><span *ngFor="let i of [1,2,3]">&nbsp;</span></p>
</ng-container>
