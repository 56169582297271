<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'admin.commons.purchases' | translate }}</h4>
    <div
        class="pull-left text-danger"
        style="margin-left: 15px; margin-top: 2px"
        *ngIf="formCheck()"
    >
        {{ 'commons.multiError' | translate }}
    </div>
    <div (click)="closeModal()" aria-label="Close" class="x-close pull-right"
         style="font-size: 20pt;">
        &#x00D7;
    </div>
</div>
<form [formGroup]="purchaseProductForm" (ngSubmit)="onSubmit(purchaseProductForm)"
      #purchaseProductNgForm="ngForm">
    <div class="modal-body">
        <p *ngIf="errorForm" class="text-danger">{{ errorForm.message }}</p>
        <div class="form-group">
            <div *ngIf="products">
                <!-- country -->
                <div class="pull-left"
                     [class.text-danger]="validateFormField(purchaseProductForm.controls.country, purchaseProductNgForm, submitted)"
                >
                    <label class="field_title">{{ 'commons.country' | translate }}</label>
                </div>
                <div class="clearfix"></div>
                <label style="width: 100%;">
                    <select class="form-control" (change)="onCountryChange()" formControlName="country">
                        <option value="">{{ 'commons.select' | translate }}</option>
                        <option value="Canada">Canada-Europe</option>
                        <option value="US">{{ (translate.currentLang === 'fr') ? 'États-Unis' : 'USA' }}</option>
                    </select>
                </label>
                <div *ngIf="purchaseProductForm.value.country !== '' && isClientOrDemo">
                    <!-- productGroup-->
                    <div class="pull-left"
                         [class.text-danger]="validateFormField(purchaseProductForm.controls.productGroup, purchaseProductNgForm, submitted)"
                    >
                        <label class="field_title">{{ translate.currentLang === 'fr' ? 'Groupe' : 'Group' }}</label>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <select class="form-control" formControlName="productGroup">
                            <option value="">{{ 'commons.select' | translate }}</option>
                            <option *ngFor="let productGroup of productsGroupsNames"
                                    [style.display]="!(productsGroups[purchaseProductForm.value.country][productGroup] && productsGroups[purchaseProductForm.value.country][productGroup].length) ? 'none': null"
                                    [value]="productGroup"
                            >{{productGroup}}</option>
                        </select>
                    </label>
                </div>
                <div *ngIf="purchaseProductForm.value.country !== ''">
                    <!-- productId -->
                    <div class="pull-left"
                         [class.text-danger]="validateFormField(purchaseProductForm.controls.productId, purchaseProductNgForm, submitted)"
                    >
                        <label class="field_title">{{ 'admin.purchaseProductModal.product' | translate }}</label>
                    </div>
                    <div class="clearfix"></div>
                    <label style="width: 100%;">
                        <select class="form-control" formControlName="productId">
                            <option value="">{{ 'commons.select' | translate }}</option>
                            <ng-container
                                *ngIf="isClientOrDemo && getProperty(purchaseProductForm.value, 'productGroup')">
                                <option
                                    *ngFor="let pr of productsGroups[purchaseProductForm.value.country][getProperty(purchaseProductForm.value, 'productGroup')]"
                                    [value]="pr.id">
                                    {{getProperty(getProperty(pr, 'descriptive'), translate.currentLang)}}
                                </option>
                            </ng-container>
                            <ng-container *ngIf="!isClientOrDemo">
                                <option *ngFor="let pr of productsGroups[purchaseProductForm.value.country]"
                                        [value]="pr.id">
                                    {{getProperty(getProperty(pr, 'descriptive'), translate.currentLang)}} {{purchaseProductForm.value.country}}
                                </option>
                            </ng-container>
                        </select>
                    </label>
                </div>
            </div>
            <input type="hidden" class="form-control" formControlName="subAccount"
                   [value]="account['selectedSubAccount']">
            <!-- extraDescription -->
            <div>
                <div class="pull-left">
                    <label class="field_title">
                        {{ 'admin.purchaseProductModal.extraDescription' | translate }}
                    </label>
                </div>
                <label style="width: 100%;">
                    <input type="text" class="form-control" formControlName="extraDescription">
                </label>
            </div>
            <!-- clientId -->
            <input type="hidden" formControlName="clientId">
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="pull-right" style="margin-left: 10px">
            <button
                type="submit"
                class="modalButton pull-right"
                [disabled]="!purchaseProductForm.valid && purchaseProductNgForm.submitted"
            >
                {{ 'admin.purchaseProductModal.buy' | translate }}
            </button>
            <button type="button" (click)="closeModal()" class="modalButton pull-right"
                    style="margin-right: 10px">
                {{ 'commons.cancel' | translate }}
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
</form>
