<div *ngIf="jobService.id" class="jobContentWrapper" (window:resize)="onResize()">
    <ngx-loading
        [show]="jobService.loading"
        [config]="loaderDisplay"
        [class]="'jobLoading'"
    ></ngx-loading>
    <div
        [class.top]="isLeftAndNotTop"
        [class.affix]="isLeftAndNotTop"
        [class.tLeft]="!(config=='list') && isLeftAndNotTop"
        [class.tRight]="config=='list' && isLeftAndNotTop"
        [style.width]="width"
    >
        <div
            [class.navbar-fixed-top]="!isLeftAndNotTop"
            [class.topMenu]="!isLeftAndNotTop && !mobile"
        >
            <div
                [class.topHead]="isLeftAndNotTop"
            >
                <div class="pull-left"><h2 [style.width]="widthTitle" [class.na]="!jobService.jobTitle"
                                           class="truncate name">{{
                    jobService.jobTitle ? jobService.jobTitle.toUpperCase() : notSpecified()
                    }}</h2></div>
                <div *ngIf="config=='list'" (click)="goToNews()" class="x-close pull-right"
                     style="font-size: 20pt;">
                    &#x00D7;
                </div>
                <div class="clearfix"></div>
            </div>
            <div
                *ngIf="snapshotUrl[0].path=='archives'"
            >
                <span (click)="modStatus('archive', false)"
                      class="fakeLink">{{ 'commons.reactivate' | translate }}</span>&nbsp;&nbsp;
                <span *ngIf="canDelete" (click)="modStatus('delete')"
                      class="fakeLink">{{ 'commons.delete' | translate }}</span>
            </div>
            <!--div (click)="displayReport(individu)" class="pull-left"-->
            <div *ngIf="config=='list'; else reportFullButtons">
                <div *ngIf="false"><!--!(mpoScores || talentsScores) && !archive"-->
                    <div class="pull-left dropdown" dropdown>
                        <div dropdownToggle
                             class="dropdown-toggle sideBarButton"
                             data-toggle="dropdown"
                        >
                            {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            style="max-width: 330px; right: auto; left: 0">
                            <li><a (click)="openJobProfilerEmailModal()">{{ 'jobs.profilerEmailModal.sendProfiler' |
                                translate }}</a></li>
                            <li><a (click)="openProfilerForm()">{{ 'jobs.sidebar.fillExternalQuestionnaire' | translate
                                }}</a></li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div *ngIf="!actualModule.archive">
                    <app-nav-button *ngIf="mpoScores || talentsScores || jobService.raScore"
                                    (buttonActon)="goToFullReport(jobService.id)">
                        <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                        <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                    </app-nav-button>

                    <app-nav-button
                        *ngIf="!stateService.session.hasSatelliteRole && (mpoScores || talentsScores || jobService.raScore)"
                        [isBlank]="jobIsBlank()"
                        (buttonActon)="onPdfShare()"
                    >
                        <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                        <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                    </app-nav-button>
                    <div *ngIf="!stateService.session.hasSatelliteRole" class="pull-left dropdown" dropdown>
                        <div dropdownToggle
                             class="dropdown-toggle sideBarButton"
                             data-toggle="dropdown"
                        >
                            {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" style="max-width: 330px;">
                            <li>
                                <a *ngIf="!actualModule.archive" (click)="openJobEditModal()">
                                    {{ 'jobs.modal.editJob' | translate }}
                                </a>
                            </li>
                            <ng-container *ngIf="false">
                                <!-- @ilya This option has been removed from the menu -->
                                <li *ngIf="jobType != 'survey' || isCorrectLanguage()">
                                    <a>
                                        <span *ngIf="mpoScores">{{ 'jobs.commons.MPOTargetProfile' | translate }}</span>
                                        <span
                                            *ngIf="!mpoScores">{{ 'jobs.sidebar.addTargetProfile' | translate }}</span>
                                        <span
                                            class="caret"></span>
                                    </a>
                                </li>
                            </ng-container>
                            <li *ngIf="false">
                                <!-- @ilya This option has been removed from the menu -->
                                <a *ngIf="isCorrectLanguage() && jobType !== 'survey' && !actualModule.archive "
                                   (click)="openJobProfilerEmailModal()">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.sendQuestionnaireByMail' | translate }}
                                </a>
                                <a *ngIf="isCorrectLanguage() && jobType == 'survey' && !actualModule.archive"
                                   (click)="openProfilerForm()">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.editQuestionnaire' | translate }}
                                </a>
                            </li>
                            <li *ngIf="false">
                                <!-- @ilya This option has been removed from the menu -->
                                <a *ngIf="isCorrectLanguage() && jobType !== 'survey' && !actualModule.archive "
                                   (click)="openProfilerForm()">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.fillOnlineProfiler' | translate }}
                                </a>
                            </li>
                            <li>
                                <a *ngIf="!actualModule.archive" (click)="openJobCopyModal()">
                                    {{ 'jobs.sidebar.copyJob' | translate }}
                                </a>
                            </li>
                            <li>
                                <a *ngIf="!actualModule.archive" (click)="modStatus('archive', true)">
                                    {{ 'jobs.sidebar.archiveJob' | translate }}
                                </a>
                                <a *ngIf="actualModule.archive" (click)="modStatus('archive', false)">
                                    {{ 'commons.reactivate' | translate }}
                                </a>
                            </li>

                            <!-- Delete -->
                            <ng-container *ngIf="false">
                                <!-- @ilya This option has been removed from the menu -->
                                <li *ngIf="!actualModule.archive && (canDelete || mpoScores || talentsScores)">
                                    <a>
                                        <span>{{ 'commons.delete' | translate }}</span>
                                        <span class="caret"></span>
                                    </a>
                                </li>
                            </ng-container>
                            <li *ngIf="!actualModule.archive && canDelete" (click)="modStatus('delete')">
                                <a>
                                    {{ 'jobs.sidebar.deleteJob' | translate }}
                                </a>
                            </li>
                            <ng-container *ngIf="false">
                                <!-- @ilya This option has been removed from the menu -->
                                <li *ngIf="!actualModule.archive && mpoScores" (click)="modStatus('deleteProfiler')">
                                    <a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteProfiler' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="!actualModule.archive && talentsScores"
                                    (click)="modStatus('deleteJobCompetence')">
                                    <a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteCompetence' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="user.hasIacAccess && !actualModule.archive && jobService.raScore"
                                    (click)="modStatus('deleteJobCai')">
                                    <a>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteIac' | translate }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
            <ng-template #reportFullButtons>
                <app-nav-button *ngIf="!mobile" (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <button *ngIf="mobile"
                        mat-stroked-button
                        (click)="goToList()"
                        class="fullView-btn"
                ><i class="material-icons">navigate_before</i>{{ 'commons.backToList' | translate }}</button>

                <app-nav-button
                    *ngIf="!stateService.session.hasSatelliteRole && !mobile"
                    [isBlank]="jobIsBlank()"
                    (buttonActon)="onPdfShare()"
                >
                    <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                    <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                </app-nav-button>
                <div *ngIf="!stateService.session.hasSatelliteRole && !mobile" class="pull-left dropdown" dropdown>
                    <div dropdownToggle
                         class="dropdown-toggle sideBarButton"
                         data-toggle="dropdown"
                    >
                        {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                    </div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-left">
                        <li>
                            <a *ngIf="!actualModule.archive" (click)="openJobEditModal()">
                                {{ 'jobs.modal.editJob' | translate }}
                            </a>
                        </li>
                        <ng-container *ngIf="false">
                            <!-- @ilya This option has been removed from the menu -->
                            <li *ngIf="jobType != 'survey' || isCorrectLanguage()">
                                <a>
                                    <span *ngIf="mpoScores">{{ 'jobs.commons.MPOTargetProfile' | translate }}</span>
                                    <span *ngIf="!mpoScores">{{ 'jobs.sidebar.addTargetProfile' | translate }}</span>
                                    <span
                                        class="caret"></span>
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="false">
                            <!-- @ilya This option has been removed from the menu -->
                            <a *ngIf="isCorrectLanguage() && jobType !== 'survey' && !actualModule.archive "
                               (click)="openJobProfilerEmailModal()">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.sendQuestionnaireByMail' | translate }}
                            </a>
                            <a *ngIf="isCorrectLanguage() && jobType == 'survey' && !actualModule.archive"
                               (click)="openProfilerForm()">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.editQuestionnaire' | translate }}
                            </a>
                        </li>
                        <li *ngIf="false">
                            <!-- @ilya This option has been removed from the menu -->
                            <a *ngIf="isCorrectLanguage() && jobType !== 'survey' && !actualModule.archive "
                               (click)="openProfilerForm()">
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.fillOnlineProfiler' | translate }}
                            </a>
                        </li>
                        <li>
                            <a *ngIf="!actualModule.archive" (click)="openJobCopyModal()">
                                {{ 'jobs.sidebar.copyJob' | translate }}
                            </a>
                        </li>
                        <li>
                            <a *ngIf="!actualModule.archive" (click)="modStatus('archive', true)">
                                {{ 'jobs.sidebar.archiveJob' | translate }}
                            </a>
                            <a *ngIf="actualModule.archive" (click)="modStatus('archive', false)">
                                {{ 'commons.reactivate' | translate }}
                            </a>
                        </li>

                        <!-- Delete -->
                        <ng-container *ngIf="false">
                            <!-- @ilya This option has been removed from the menu -->
                            <li *ngIf="!actualModule.archive && (canDelete || mpoScores || talentsScores)">
                                <a>
                                    <span>{{ 'commons.delete' | translate }}</span>
                                    <span class="caret"></span>
                                </a>
                            </li>
                        </ng-container>
                        <li *ngIf="!actualModule.archive && canDelete" (click)="modStatus('delete')">
                            <a>
                                {{ 'jobs.sidebar.deleteJob' | translate }}
                            </a>
                        </li>
                        <ng-container *ngIf="false">
                            <!-- @ilya This option has been removed from the menu -->
                            <li *ngIf="!actualModule.archive && mpoScores" (click)="modStatus('deleteProfiler')">
                                <a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteProfiler' | translate }}
                                </a>
                            </li>
                            <li *ngIf="!actualModule.archive && talentsScores"
                                (click)="modStatus('deleteJobCompetence')">
                                <a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteCompetence' | translate }}
                                </a>
                            </li>
                            <li *ngIf="!actualModule.archive && jobService.raScore" (click)="modStatus('deleteJobCai')">
                                <a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'jobs.sidebar.deleteIac' | translate }}
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </ng-template>
            <div *ngIf="isLeftAndNotTop">
                <hr class="panelHr"/>
            </div>
        </div>
        <div *ngIf="isLeftAndNotTop" style="height: 5px;"></div>
        <div *ngIf="!isLeftAndNotTop" style="height: 90px;"></div>
        <div class="infoWrap" [class.sidebarJobInfo]="config !== 'list'">
            <div class="pull-left" class="information-sidebar" style="padding-bottom: 10px">
                {{ 'commons.informations' | translate }}
            </div>
            <div>
                <table class="infoTableWrap">

                    <tr>
                        <td class="switchButton">
                            <button type="button"
                                    class="btn btn-default"
                                    (click)="toggleInformationVisibility()"
                            >
                                <span *ngIf="!informationVisibility">+</span>
                                <span *ngIf="informationVisibility">-</span>
                            </button>
                        </td>
                        <!--                    <td class="modifyButton">-->
                        <!--                        <div (click)="openJobEditModal()" class="modify"></div>-->
                        <!--                    </td>-->
                        <td>
                            <mat-tab-group
                                [(selectedIndex)]="selectedInfoQuestionTab"
                                (selectedIndexChange)="switchTab($event)"
                            >
                                <mat-tab label="{{ 'commons.informations' | translate }}">
                                    <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                        <tr>
                                            <td>
                                                <strong>{{ 'commons.date' | translate }}<span
                                                    [innerHTML]="titleSep"></span> </strong>
                                                {{ jobService.creationDate | date:dateFormat || notSpecified() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>{{ 'commons.modificationDate' | translate }}<span
                                                    [innerHTML]="titleSep"></span> </strong>
                                                {{ jobService.date | date:dateFormat || notSpecified() }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'commons.location' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                                <span [class.na]="!jobService.location">
                                            {{ jobService.location || notSpecified() }}
                                        </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'commons.subAccount' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                                <span [class.na]="!setSubAccountName(jobService.subAccount)">
                                            {{ setSubAccountName(jobService.subAccount) || notSpecified()  }}
                                        </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'commons.department' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                                <span [class.na]="!jobService.department">
                                            {{ jobService.department || notSpecified() }}
                                        </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'jobs.report.reportsTo' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                                <span [class.na]="!jobService.reportsTo">
                                            {{ jobService.reportsTo || notSpecified() }}
                                        </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'commons.requester' | translate }}<span
                                                [innerHTML]="titleSep"></span> </strong>
                                                <span [class.na]="!jobService.requiredBy">
                                            {{ jobService.requiredBy || notSpecified()}}
                                        </span>
                                            </td>
                                        </tr>
                                        <!--                                    <tr>-->
                                        <!--                                        <td><strong>{{ 'jobs.sidebar.shareJob' | translate }}<span [innerHTML]="titleSep"></span> </strong>-->
                                        <!--                                            <span [class.na]="!jobService.sharedToSubAccounts">-->
                                        <!--                                                {{ jobService.sharedToSubAccounts || notSpecified()}}-->
                                        <!--                                            </span>-->
                                        <!--                                        </td>-->
                                        <!--                                    </tr>-->
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <hr class="panelHr"/>
            </div>
        </div>
        <div *ngIf="!stateService.session.hasSatelliteRole">
            <div *ngIf="config!='report'" [class.sideBarTestOptionsListBorder]="config === 'list'">
                <div
                    *ngIf="stateService.hasPersonality"
                    class="testOption pull-left"
                    [class.testUnActivated]="!mpoScores && !actualModule.archive"
                    [class.testUnActivatedArchive]="!mpoScores && actualModule.archive"
                    [class.testOptionSelected]="stateReportType=='personality'"
                    (click)="switchEvaluationType('personality')"
                >
                    {{ 'commons.personality' | translate }}
                </div>
                <div
                    *ngIf="stateService.hasTalents"
                    class="testOption pull-left"
                    [class.testUnActivated]="!hasTalentsScores && !actualModule.archive"
                    [class.testUnActivatedArchive]="!hasTalentsScores && actualModule.archive"
                    [class.testOptionSelected]="stateReportType=='talents'"
                    (click)="switchEvaluationType('talents')"
                >
                    {{ 'jobs.commons.competencies' | translate }}
                </div>
                <div *ngIf="user.hasIacAccess"
                     class="testOption pull-left"
                     [class.testUnActivated]="!jobService.raScore && !actualModule.archive"
                     [class.testUnActivatedArchive]="!jobService.raScore && actualModule.archive"
                     [class.testOptionSelected]="stateReportType=='ra'"
                     (click)="switchEvaluationType('ra')"
                >
                    {{ 'commons.cai' | translate }}
                </div>
                <div
                    id="informationAltTitle"
                    class="pull-right"
                >
                </div>
                <div class="clearfix"></div>
            </div>
            <div style="margin-top: 10px;">
                <div id="MPOSideBarPresentation">
                    <div *ngIf="stateReportType=='personality'" class="graph-container"
                         [class.sidebarJobInfo]="config !== 'list'">
                        <div class="pull-left"><em>{{ 'commons.personalityTraitsJob' | translate }}</em></div>
                        <div class="clearfix"></div>
                        <app-mpo-graph *ngIf="!jobService.loading && (config === 'list' || !smallSize)"
                                       [id]="'traits'"
                                       [scores]="mpoScores"
                                       [lang]="translateService.currentLang"
                        ></app-mpo-graph>
                    </div>
                    <ng-container *ngIf="stateReportType=='talents'">
                        <div [class.sidebarJobInfo]="config !== 'list'">
                            <h3>
                                {{'people.reportTalents.keyCompetenciesForJob' | translate }}
                            </h3>
                            <ng-container *ngIf="hasTalentsScores">
                                <table id="talentsDisplay">
                                    <ng-container *ngFor="let i of createRange(actualPage, maxPerPage)">
                                        <ng-container
                                            *ngIf="orderedTalents[translateService.currentLang] && orderedTalents[translateService.currentLang][i]">
                                            <tr style="height: 20px">
                                                <td>{{orderedTalents[translateService.currentLang][i][0]}}</td>
                                                <td>
                                                <span *ngIf="orderedTalents[translateService.currentLang][i][1]==1"
                                                      style="color: #efce7c">⬤</span>
                                                    <span *ngIf="orderedTalents[translateService.currentLang][i][1]==2"
                                                          style="color: #78c7af">⬤</span>

                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="orderedTalents[translateService.currentLang] && !orderedTalents[translateService.currentLang][i]">
                                            <tr style="height: 20px">
                                                <td>&nbsp;</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <tr>
                                        <td style="padding-top: 5px">
                                            <div class="pull-left" style="width: 50%">
                                                <span style="color: #78c7af">⬤</span>
                                                {{ 'jobs.modal.competenceMasteryOption' | translate }}
                                            </div>
                                            <div class="pull-left" style="width: 50%">
                                                <span style="color: #efce7c">⬤</span>
                                                {{ 'jobs.modal.competenceFuncOption' | translate }}
                                            </div>
                                            <div class="clearfix"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="padding-bottom: 8px">
                                            <div *ngIf="actualPage < maxPage" class="pull-right" (click)="goToPage(1)">
                                                <img
                                                    src="/assets/buttons/next.png" alt=">"></div>
                                            <div *ngIf="actualPage == maxPage" class="pull-right"><img
                                                src="/assets/buttons/next_deactivated.png" alt=">"></div>
                                            <div *ngIf="actualPage > 1" class="pull-right" (click)="goToPage(-1)"><img
                                                src="/assets/buttons/before.png" alt="<"></div>
                                            <div *ngIf="actualPage == 1" class="pull-right"><img
                                                src="/assets/buttons/before_deactivated.png" alt="<"></div>
                                            <div class="clearfix"></div>
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                            <ng-container *ngIf="!talentsScores">
                                <div style="height: 300px">
                                    <table class="fakeListTable">
                                        <tr *ngFor="let line of [100, 110, 120, 130, 90, 100, 120, 140, 110, 120]">
                                            <td>
                                                <div class="fakeItem" [style.width]="line+'px'">&nbsp;</div>
                                            </td>
                                            <td>
                                                <span class="fakeIndicator">⬤</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <table class="fakeListTable">
                                    <tr>
                                        <td style="padding-top: 5px">
                                            <div class="pull-left" style="width: 50%">
                                                <span style="color: #78c7af">⬤</span>
                                                {{ 'jobs.modal.competenceMasteryOption' | translate }}
                                            </div>
                                            <div class="pull-left" style="width: 50%">
                                                <span style="color: #efce7c">⬤</span>
                                                {{ 'jobs.modal.competenceFuncOption' | translate }}
                                            </div>
                                            <div class="clearfix"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="padding-bottom: 8px">
                                            <div class="pull-right"><img src="/assets/buttons/next_deactivated.png"
                                                                         alt=">"></div>
                                            <div class="pull-right"><img src="/assets/buttons/before_deactivated.png"
                                                                         alt="<"></div>
                                            <div class="clearfix"></div>
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="user.hasIacAccess && stateReportType=='ra'" class="graph-container">
                        <div class="iacResultsBox" [class.sidebarJobInfo]="config !== 'list'">
                            <h2 class="bold">{{ 'jobs.reportRa.targetTitle' | translate }}</h2>
                            <h2 style="margin-top: 20px;font-size: 14pt">
                                {{ 'jobs.reportRa.target' | translate }}
                                <span class="ngorange">
                                {{ (jobService.raScore) ? jobService.raScore + ' / 9' : '&ndash;' }}
                            </span>
                            </h2>
                            <ng-container *ngIf="config === 'list' || !raTexts">
                                <h2 style="margin: 15px auto" *ngIf="config === 'list'">
                                    {{ 'jobs.sidebar.caiBoxIntro' | translate }}
                                </h2>
                                <h3 *ngIf="config === 'list'">{{ 'commons.workEnvironment' | translate }}</h3>
                                <p style="min-height: 30px;" *ngIf="config === 'list'"
                                   [class.ngorange]="!raTexts">{{ raTexts ? raTexts.env : '&ndash;' }}</p>
                                <h3 *ngIf="config === 'list'">{{ 'jobs.sidebar.caiPosition' | translate }}</h3>
                                <p style="min-height: 30px;" *ngIf="config === 'list'"
                                   [class.ngorange]="!raTexts">{{ raTexts ? raTexts['job'] : '&ndash;' }}</p>
                                <div *ngIf="!(jobService.raScore) || !user.hasIacAccess"
                                     class="iacOutlineOverlay"></div>
                                <div *ngIf="!(jobService.raScore) || !user.hasIacAccess"
                                     class="iacOutlineTest">{{ 'commons.none' | translate }}</div>
                            </ng-container>
                        </div>
                        <div [class.sidebarJobInfo]="config !== 'list'">
                            <hr class="panelHr"/>
                        </div>
                    </ng-container>
                    <div *ngIf="config!='list'"
                         [class.navbar-fixed-bottom]="!isLeftAndNotTop"
                         [class.bottomMenu]="!isLeftAndNotTop && !mobile"
                         [style.margin-top.20px]="isLeftAndNotTop"
                    >
                        <div class="col-md-{{user.hasIacAccess ? 4 : 6}}"
                             (click)="switchEvaluationType('personality')"
                             [tooltip]="'commons.personality' | translate"
                             style="cursor: pointer; margin: auto;"
                        >
                            <div *ngIf="mpoScores; else buyMPO" class="cardEvaluation"
                                 style="text-align: center; margin: auto">
                                <app-mini-mpo-graph
                                    [id]="'jobMpoGraph'"
                                    [score]="mpoScores"
                                    [noRemove]="true"
                                    [interaction]="'sidebar'"
                                ></app-mini-mpo-graph>
                            </div>
                            <ng-template #buyMPO>
                                <div class="cardEvaluation" style="text-align: center; margin: auto">
                                    <app-people-card-no-report
                                        [id]="jobService.id"
                                        [type]="'activate.4'"
                                        [which]="'personality'"
                                        [anotherTitle]="{en: 'Add', fr: 'Ajouter', y : 33}"
                                    ></app-people-card-no-report>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-md-{{user.hasIacAccess ? 4 : 6}}"
                             (click)="switchEvaluationType('talents')"
                             [tooltip]="'jobs.commons.competencies' | translate"
                             style="cursor: pointer; margin: auto;"
                        >
                            <div *ngIf="talentsScores; else buyTalents" class="cardEvaluation"
                                 style="text-align: center; margin: auto">
                                <app-mini-competences-graph
                                    [id]="'jobCompetenceGraph'"
                                    [type]="'job'"
                                ></app-mini-competences-graph>
                            </div>
                            <ng-template #buyTalents>
                                <div class="cardEvaluation" style="text-align: center; margin: auto">
                                    <app-people-card-no-report
                                        [id]="jobService.id"
                                        [type]="'activate.2'"
                                        [which]="'talents_jobs'"
                                        [anotherTitle]="{en: 'Add', fr: 'Ajouter', y : 33}"
                                    ></app-people-card-no-report>
                                </div>
                            </ng-template>
                        </div>
                        <div class="col-md-{{user.hasIacAccess ? 4 : 6}}"
                             *ngIf="user.hasIacAccess"
                             (click)="switchEvaluationType('ra')"
                             [tooltip]="'commons.cai' | translate"
                             style="cursor: pointer; margin: auto;"
                        >
                            <div *ngIf="jobService.raScore; else buyIac" class="cardEvaluation"
                                 style="text-align: center; margin: auto">
                                <app-mini-iac-graph
                                    [id]="'iac_mini'"
                                    [score]="jobService.raScore"
                                ></app-mini-iac-graph>
                            </div>
                            <ng-template #buyIac>
                                <div class="cardEvaluation">
                                    <div class="cardEvaluation">
                                        <app-people-card-no-report
                                            [id]="jobService.id"
                                            [type]="'activate.2'"
                                            [which]="'iac'"
                                            [anotherTitle]="{en: 'Add', fr: 'Ajouter', y : 33}"
                                        ></app-people-card-no-report>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
