<div *ngIf="account && user && userForm">
    <div class="rightWrapper row">
        <div class="outerColumnWhite col-md-12">
            <div id="userContent">
                <!-- Edit user -->
                <ng-container *ngIf="!newUser">
                    <h2 class="ngorange" style="font-size: 17pt">
                        {{ userData.firstName }} {{ userData.lastName }}
                    </h2>
                    <p>{{ userData.jobTitle }}</p>
                    <h2 class="ngorange" style="font-size: 17pt">
                        {{ 'admin.userForm.editUser' | translate }}
                    </h2>
                </ng-container>

                <!-- New user -->
                <ng-container *ngIf="newUser">
                    <h2 class="ngorange" style="font-size: 17pt">
                        {{ 'admin.userForm.createUser' | translate }}
                    </h2>
                </ng-container>

                <div
                        class="pull-left text-danger"
                        *ngIf="!userForm.valid && userFormDir.touched && submitTry"
                >
                    {{ 'commons.multiError' | translate }}
                </div>
                <div *ngIf="dupeUser" class="pull-left text-danger">
                    {{ 'commons.dupeUser' | translate }}
                </div>
                <!-- init Form -->
                <form  [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)" #userFormDir="ngForm">
                    <h3>{{ 'admin.userForm.generalInfo' | translate }}</h3>
                    <div class="col-md-6 accntInfo">
                        <table>
                            <!-- Prénom -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.firstName, userFormDir, submitTry)"
                                >{{ 'commons.firstName' | translate }}
                                </td>
                                <td [class.editing]="focused=='firstName'">
                                    <label>
                                        <input
                                                (focus)="focused = 'firstName'"
                                                (focusout)="focused = ''"
                                                type="text" class=" firstname" formControlName="firstName">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Nom -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.lastName, userFormDir, submitTry)"
                                >{{ 'commons.name' | translate }}
                                </td>
                                <td [class.editing]="focused=='lastName'">
                                    <label>
                                        <input
                                                (focus)="focused = 'lastName'"
                                                (focusout)="focused = ''"
                                                type="text" class=" lastname" formControlName="lastName">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Courriel -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.email, userFormDir, submitTry) ||
                                        (userForm.hasError('invalidEmail') &&
                                                (
                                                    userForm.controls.password.dirty ||
                                                    userForm.controls.password.touched
                                                )
                                            )
                                        "
                                >{{ 'commons.email' | translate }}
                                </td>
                                <td [class.editing]="focused=='email'">
                                    <label>
                                        <input
                                                (focus)="focused = 'email'"
                                                (focusout)="focused = ''"
                                                type="text" class=" email" formControlName="email">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- personalEmail -->
                            <tr>
                                <td>{{ 'commons.personalEmail' | translate }}</td>
                                <td [class.editing]="focused=='personalEmail'">
                                    <label>
                                        <input
                                                (focus)="focused = 'personalEmail'"
                                                (focusout)="focused = ''"
                                                type="text" class=" email" formControlName="personalEmail">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Phone -->
                            <tr>
                                <td>{{ 'admin.commons.phoneNumber' | translate }}</td>
                                <td [class.editing]="focused=='phone'">
                                    <label>
                                        <input
                                                (focus)="focused = 'phone'"
                                                (focusout)="focused = ''"
                                                type="text" class=" phone" formControlName="phone">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2">
                                    <span class="formats">Format: CA/US 555 555-5555, FR 5 55 55 55 55</span>
                                </td>
                            </tr>
                            <!-- Subaccount access -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.subAccountAccess, userFormDir, submitTry)"
                                        style="vertical-align: top">{{ 'admin.commons.subAccountAccess' | translate }}
                                </td>
                                <td *ngIf="account['subAccounts'].length>1" class="subAccountAccess" [class.editing]="focused=='subAccountAccess'">
                                    <ng-multiselect-dropdown
                                        [placeholder]="'commons.subAccounts' | translate"
                                        [settings]="dropDownSettings"
                                        [data]="ssOptions"
                                        formControlName="subAccountAccess"
                                        (onSelect)="onSubAccountsChange()"
                                        (onDeSelect)="onSubAccountsChange()"
                                    >
                                    </ng-multiselect-dropdown>
                                </td>
                                <td *ngIf="account['subAccounts'].length==1">
                                    {{account['subAccounts'][0].name}}
                                    <input type="hidden" formControlName="subAccountAccess" [value]="account['subAccounts'][0].id"/>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Subaccount access -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.principalSubAccount, userFormDir, submitTry)"
                                        style="vertical-align: top">{{ 'admin.commons.principalSubAccount' | translate }}
                                </td>
                                <td *ngIf="account['subAccounts'].length>1" [class.editing]="focused=='principalSubAccount'">
                                    <label style="width: 100%;">
                                        <select
                                                (focus)="focused = 'principalSubAccount'"
                                                (focusout)="focused = ''"
                                                *ngIf='account' formControlName="principalSubAccount"
                                        >
                                            <option>{{ 'commons.none' | translate }}</option>
                                            <option *ngFor="let subAcct of account['subAccounts']" [value]="subAcct.id">
                                                {{subAcct.name}}
                                            </option>
                                        </select>
                                    </label>
                                </td>
                                <td *ngIf="account['subAccounts'].length==1">
                                    {{account['subAccounts'][0].name}}
                                    <input type="hidden" formControlName="principalSubAccount" [value]="account['subAccounts'][0].id"/>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-md-6 accntInfo">
                        <table>
                            <!-- jobTitle -->
                            <tr>
                                <td>{{ 'commons.title' | translate }}</td>
                                <td [class.editing]="focused=='jobTitle'" style="padding-left: 4px">
                                    <label>
                                        <input
                                                (focus)="focused = 'jobTitle'"
                                                (focusout)="focused = ''"
                                                type="text" class="jobtitle" formControlName="jobTitle">
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Status -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.status, userFormDir, submitTry)"
                                >{{ 'admin.userForm.contactStatus' | translate }}
                                </td>
                                <td [class.editing]="focused=='status'">
                                    <label style="width: 100%;">
                                        <select
                                                (focus)="focused = 'status'"
                                                (focusout)="focused = ''"
                                                formControlName="status">
                                            <option>{{ 'commons.select' | translate }}</option>
                                            <option [value]="1">{{ 'admin.commons.active' | translate }}</option>
                                            <option [value]="0">{{ 'admin.commons.inactive' | translate }}</option>
                                        </select>
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                            <!-- Language -->
                            <tr>
                                <td
                                        [class.text-danger]="validateFormField(userForm.controls.language, userFormDir, submitTry)"
                                >{{ 'commons.language' | translate }}
                                </td>
                                <td [class.editing]="focused=='language'">
                                    <label style="width: 100%;">
                                        <select
                                                (focus)="focused = 'language'"
                                                (focusout)="focused = ''"
                                                [attr.disabled]="freezedPRB" formControlName="language">
                                            <option>{{ 'commons.select' | translate }}</option>
                                            <option *ngFor="let lang of sessionStructure.languages[translate.currentLang]" [value]="lang.value">
                                                {{lang.name}}
                                            </option>
                                        </select>
                                    </label>
                                </td>
                            </tr>
                            <tr class="spacer">
                                <td colspan="2"></td>
                            </tr>
                        </table>
                    </div>
                    <div class="clearfix"></div>
                    <!-- Address -->
                    <div>
                        <h3>{{ 'admin.commons.addressInfo' | translate }}</h3>
                        <div formArrayName="addressInfo" class="col-md-6 accntInfo">
                            <table formGroupName="0">
                                <tr>
                                    <!-- address -->
                                    <td>{{ 'commons.address' | translate }}</td>
                                    <td [class.editing]="focused=='address'">
                                        <label>
                                            <input
                                                    (focus)="focused = 'address'"
                                                    (focusout)="focused = ''"
                                                    type="text"
                                                    class=" addressline1"
                                                    formControlName="address"
                                                    [value]="addressInfo.address"
                                            >
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <!-- ville -->
                                    <td>{{ 'commons.city' | translate }}</td>
                                    <td [class.editing]="focused=='city'">
                                        <label>
                                            <input
                                                    (focus)="focused = 'city'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="city"
                                                    [value]="addressInfo.city"
                                            >
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>
                                <tr>
                                    <!-- Code postal -->
                                    <td>{{ 'commons.zipcode' | translate }}</td>
                                    <td [class.editing]="focused=='zipcode'">
                                        <label>
                                            <input
                                                    (focus)="focused = 'zipcode'"
                                                    (focusout)="focused = ''"
                                                    type="text" class=" zipcode" formControlName="zipcode"
                                                    [value]="addressInfo.zipcode"
                                            >
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2">
                                        <div><span class="formats pull-left">Format: CA H0H 0H0 US 12345, FR 00001</span></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div formArrayName="addressInfo" class="col-md-6 accntInfo">
                            <table formGroupName="0">
                                <!-- region -->
                                <tr>
                                    <td>{{ 'commons.region' | translate }}</td>
                                    <td [class.editing]="focused=='region'">
                                        <label>
                                            <input
                                                    (focus)="focused = 'region'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="region"
                                                    [value]="addressInfo.region"
                                            >
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>
                                <!-- country -->
                                <tr>
                                    <td>{{ 'commons.country' | translate }}</td>
                                    <td [class.editing]="focused=='country'">
                                        <label>
                                            <input
                                                    (focus)="focused = 'country'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="country"
                                                    [value]="addressInfo.country"
                                            >
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>
                            </table>
                        </div>
                        <div class="clearfix"></div>
                    </div>

                    <div class="clearfix"></div>

                    <!-- MPO -->
                    <div>
                        <h3>{{translate.currentLang === 'fr' ? 'Formations MPO' : 'MPO trainings'}}</h3>

                        <div class="col-md-6 accntInfo">
                            <table>
                                <tr class="spacer" style="height: 24px;">
                                    <td colspan="2">
                                        <h4>{{ 'commons.personality' | translate }}</h4>
                                    </td>
                                </tr>
                                <tbody formArrayName="formation">
                                    <!-- Personality training-->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.commons.MPOFormation' | translate }}
                                            <br /> <small>({{ 'commons.personality' | translate }}</small>)</td>
                                        <td formArrayName="personality">
                                            <span formGroupName="0">
                                                <mat-checkbox formControlName="training"></mat-checkbox>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Personality formationDate -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.trainingDate' | translate }}
                                            <br /> <small>({{ 'commons.personality' | translate }})</small>
                                        </td>
                                        <td [class.editing]="focused=='personalityFormationDate'">
                                            <my-date-picker
                                                    (focus)="focused = 'personalityFormationDate'"
                                                    (focusout)="focused = ''"
                                                    [locale]="translate.currentLang"
                                                    [selDate]="mongoDateToDp(personalityFormationDate)"
                                                    [options]="datePickerOptions"
                                                    (dateChanged)="onChangeFormationDate($event, 'personality')"
                                            ></my-date-picker>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <tr class="spacer" style="height: 24px;">
                                        <td colspan="2">
                                            <h4>{{ 'commons.communication' | translate }}</h4>
                                        </td>
                                    </tr>
                                    <!-- Formation  Communication-->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.commons.MPOFormation' | translate }}
                                            <br /> <small>({{ 'commons.communication' | translate }})</small>
                                        </td>
                                        <td formArrayName="communication">
                                            <span formGroupName="0">
                                                <mat-checkbox formControlName="training"></mat-checkbox>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Communication formationDate -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.trainingDate' | translate }}
                                            <br /> <small>({{ 'commons.communication' | translate }})</small>
                                        </td>
                                        <td [class.editing]="focused=='communicationFormationDate'">
                                            <my-date-picker
                                                (focus)="focused = 'communicationFormationDate'"
                                                (focusout)="focused = ''"
                                                [locale]="translate.currentLang"
                                                [selDate]="mongoDateToDp(communicationFormationDate)"
                                                [options]="datePickerOptions"
                                                (dateChanged)="onChangeFormationDate($event, 'communication')"
                                            ></my-date-picker>
                                        </td>
                                    </tr>


                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <tr class="spacer" style="height: 24px;">
                                        <td colspan="2">
                                            <h4>{{ 'commons.talents' | translate }}</h4>
                                        </td>
                                    </tr>
                                    <!-- Formation  talents -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.commons.MPOFormation' | translate }}
                                            <br /> <small>({{ 'commons.talents' | translate }})</small>
                                        </td>
                                        <td formArrayName="talents">
                                            <span formGroupName="0">
                                                <mat-checkbox formControlName="training"></mat-checkbox>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Talents formationDate -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.trainingDate' | translate }}
                                            <br /> <small>({{ 'commons.talents' | translate }})</small>
                                        </td>
                                        <td [class.editing]="focused=='talentsFormationDate'">
                                            <my-date-picker
                                                (focus)="focused = 'talentsFormationDate'"
                                                (focusout)="focused = ''"
                                                [locale]="translate.currentLang"
                                                [selDate]="mongoDateToDp(talentsFormationDate)"
                                                [options]="datePickerOptions"
                                                (dateChanged)="onChangeFormationDate($event, 'talents')"
                                            ></my-date-picker>
                                        </td>
                                    </tr>
                                </tbody>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2">
                                        <h4>{{ 'admin.leftSidebar.userInfo' | translate }}</h4>
                                    </td>
                                </tr>

                                <!-- Role for existing user -->
                                <tr *ngIf="sessionAccessPermission && !newUser">
                                    <td
                                        [class.text-danger]="validateFormField(userForm.controls.role, userFormDir, submitTry)"
                                    >{{ 'admin.userDetail.userType' | translate }}
                                    </td>
                                    <td [class.editing]="focused=='role'">
                                        <label style="width: 100%;">
                                            <select
                                                (focus)="focused = 'role'"
                                                (focusout)="focused = ''"
                                                (ngModelChange)="checkPermissions($event)"
                                                formControlName="role">
                                                    <option>{{ 'commons.select' | translate }}</option>
                                                    <option
                                                        *ngFor="let usrType of userRoles"
                                                        [value]="usrType.value"
                                                        [disabled]="!canSelectUser(usrType.value)"
                                                    >
                                                        {{usrType.name}}
                                                    </option>
                                            </select>
                                        </label>
                                    </td>
                                </tr>
                                <tr class="spacer">
                                    <td colspan="2"></td>
                                </tr>

                                <!-- username / role / password for a new user -->
                                <ng-container *ngIf="newUser">
                                    <!-- username -->
                                    <tr>
                                        <td
                                                [class.text-danger]="(validateFormField(userForm.controls.username, userFormDir, submitTry) || !usernameValidity['valid']) && !usernameValidity['init']"
                                        >{{ 'admin.userDetail.userName' | translate }}
                                        </td>
                                        <td [class.editing]="focused=='username'">
                                            <label>
                                                <input
                                                        (focus)="focused = 'username'"
                                                        (focusout)="focused = ''"
                                                        (blur)="validateUsernameEvent()"
                                                        type="text" class=" username" formControlName="username"
                                                >
                                            </label>
                                        </td>
                                    </tr>
                                    <tr class="message" *ngIf="!usernameValidity['valid'] && !usernameValidity['init']">
                                        <td></td>
                                        <td>
                                            {{ usernameValidity['message'] }}
                                        </td>
                                    </tr>
                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>
                                    <!-- role -->
                                    <tr *ngIf="sessionAccessPermission">
                                        <td
                                            [class.text-danger]="validateFormField(userForm.controls.role, userFormDir, submitTry)"
                                        >{{ 'admin.userDetail.userType' | translate }}
                                        </td>
                                        <td [class.editing]="focused=='role'">
                                            <label class="left4">
                                                <select
                                                    (focus)="focused = 'role'"
                                                    (focusout)="focused = ''"
                                                    (ngModelChange)="checkPermissions($event)"
                                                    formControlName="role">
                                                        <option>{{ 'commons.select' | translate }}</option>
                                                        <option
                                                            *ngFor="let usrType of userRoles"
                                                            [value]="usrType.value"
                                                            [disabled]="!canSelectUser(usrType.value)"
                                                        >
                                                            {{usrType.name}}
                                                        </option>
                                                </select>
                                            </label>
                                        </td>
                                    </tr>
                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>
                                    <!-- password -->
                                    <tr>
                                        <td>{{ 'commons.password' | translate }}</td>
                                        <td [class.editing]="focused=='password'">
                                            <label>
                                                <input
                                                        (focus)="focused = 'password'"
                                                        (focusout)="focused = ''"
                                                        type="text" class=" password" formControlName="password">
                                            </label>
                                        </td>
                                    </tr>
                                    <tr class="noBottomBorder">
                                        <td colspan="2">
                                            <small
                                                    *ngIf="validateFormField(userForm.controls.password, userFormDir, submitTry)"
                                                    class="text-danger"
                                            >
                                        <span [hidden]="!userForm.controls.password.errors['minlength']">
                                            {{ 'admin.userForm.pwd8CharsWarning' | translate }}
                                        </span>
                                                <span [hidden]="!userForm.controls.password.errors['required']">
                                            {{ 'admin.userForm.pwdMandatory' | translate }}
                                        </span>
                                            </small>
                                            <small *ngIf="userForm.hasError('weakPassword') &&
                                        (userForm.controls.password.dirty ||
                                        userForm.controls.password.touched)"
                                                   class="text-danger"
                                            >
                                        <span>
                                            {{ 'admin.userForm.pwdRuleTitle' | translate }}<br/>
                                            {{ 'admin.userForm.pwdRuleText' | translate }}
                                        </span>
                                            </small>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                        <div class="col-md-6 accntInfo">
                            <table>
                                <tbody formArrayName="formation">
                                    <tr class="spacer" style="height: 24px;">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Personality location -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.formationPlace' | translate }}
                                            <br /> <small>({{ 'commons.personality' | translate }})</small>
                                        </td>
                                        <td formArrayName="personality" [class.editing]="focused=='personalityLocation'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                        (focus)="focused = 'personalityLocation'"
                                                        (focusout)="focused = ''"
                                                        type="text" formControlName="location">
                                            </label>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Personality trainer-->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userModal.trainer' | translate }}
                                            <br /> <small>({{ 'commons.personality' | translate }})</small>
                                        </td>
                                        <td formArrayName="personality" [class.editing]="focused=='personalityTrainer'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                        (focus)="focused = 'personalityTrainer'"
                                                        (focusout)="focused = ''"
                                                        type="text" formControlName="trainer">
                                            </label>
                                        </td>
                                    </tr>
                                    <tr class="spacer" style="height: 24px;">
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>
                                    <!-- Communication location -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.formationPlace' | translate }}
                                            <br /> <small>({{ 'commons.communication' | translate }})</small>
                                        </td>
                                        <td formArrayName="communication" [class.editing]="focused=='communicationLocation'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                    (focus)="focused = 'communicationLocation'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="location">
                                            </label>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Communication trainer-->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userModal.trainer' | translate }}
                                            <br /> <small>({{ 'commons.communication' | translate }})</small>
                                        </td>
                                        <td formArrayName="communication" [class.editing]="focused=='communicationTrainer'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                    (focus)="focused = 'communicationTrainer'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="trainer">
                                            </label>
                                        </td>
                                    </tr>

                                    <tr class="spacer" style="height: 24px;">
                                        <td colspan="2"></td>
                                    </tr>
                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Talents location -->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userDetail.formationPlace' | translate }}
                                            <br /> <small>({{ 'commons.talents' | translate }})</small>
                                        </td>
                                        <td formArrayName="talents" [class.editing]="focused=='talentsLocation'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                    (focus)="focused = 'talentsLocation'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="location">
                                            </label>
                                        </td>
                                    </tr>

                                    <tr class="spacer">
                                        <td colspan="2"></td>
                                    </tr>

                                    <!-- Talents trainer-->
                                    <tr formGroupName="0">
                                        <td>{{ 'admin.userModal.trainer' | translate }}
                                            <br /> <small>({{ 'commons.talents' | translate }})</small>
                                        </td>
                                        <td formArrayName="talents" [class.editing]="focused=='talentsTrainer'" style="padding-left: 4px">
                                            <label formGroupName="0">
                                                <input
                                                    (focus)="focused = 'talentsTrainer'"
                                                    (focusout)="focused = ''"
                                                    type="text" formControlName="trainer">
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <div formArrayName="permissions" *ngIf="canUpdateAccess" class="col-md-12 col-xs-6">
                        <h3>Permissions</h3>
                        <div formGroupName="0">
                            <div class="floatLeft20P">
                                <mat-checkbox formControlName="mpo"
                                              [checked]="formPermissions['mpo']"
                                              (change)="checkFormPermission($event, 'mpo')"
                                >{{ 'commons.mpoPersonality' | translate }}</mat-checkbox>
                            </div>

                            <div class="floatLeft20P">
                                <mat-checkbox formControlName="dit"
                                              [checked]="formPermissions['dit']"
                                              (change)="checkFormPermission($event, 'dit')"
                                >{{ 'commons.mpoCommunication' | translate }}</mat-checkbox>
                            </div>
                            <div class="floatLeft20P">
                                <mat-checkbox formControlName="talents"
                                              [checked]="formPermissions['talents']"
                                              (change)="checkFormPermission($event, 'talents')"
                                >{{ 'commons.mpoTalents' | translate }}</mat-checkbox>
                            </div>

                            <div class="floatLeft20P">
                                <mat-checkbox formControlName="satellite"
                                              [checked]="formPermissions['satellite']"
                                              (change)="checkFormPermission($event, 'satellite')"
                                >{{ 'commons.mpoSatellite' | translate }}</mat-checkbox>
                            </div>
                            <div class="floatLeft20P">
                                <mat-checkbox formControlName="ra"
                                              [checked]="formPermissions['ra']"
                                              (change)="checkFormPermission($event, 'ra')"
                                ><span *ngIf="translate.currentLang==='fr'">IAC</span><span *ngIf="translate.currentLang==='en'">CAI</span>
                                </mat-checkbox>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div class="clearfix"></div>

                    <h3><label for="notes">{{ 'commons.notes' | translate }}</label></h3>
                    <div class="col-md-12 col-xs-6">
                        <!-- notes -->
                        <textarea style="
                    width: 100%;
                    height: 50px
                " id="notes" formControlName="notes"></textarea>
                    </div>

                    <div class="clearfix"></div>

                    <!-- Submit -->
                    <div class="col-md-12" style="padding-bottom: 20px;">
                        <div class="pull-right">
                            <button
                                    type="submit"
                                    class="modalButton pull-right"
                                    [disabled]="!userForm.valid && userFormDir.submitted"
                                    style="margin-left: 15px; margin-top: 15px"
                            >
                                {{ 'commons.save' | translate }}
                            </button>
                            <button
                                    type="button"
                                    class="modalButton pull-right"
                                    (click)="goBack()"
                                    style="margin-top: 15px"
                            >
                                {{ 'commons.cancel' | translate }}
                            </button>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
