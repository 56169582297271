<table *ngIf="fieldsName">
    <tr class="actionModify">
        <td class="{{error ? 'text-danger' : null}} title">
            <label for="{{fieldsName}}Field">
                {{ 'admin.forms.' + fieldsName | translate }}
            </label>
        </td>
        <td
            [attr.colspan]="(editEnable || editable) ? 2 : null"
            [class.editing]="editEnable"
            [class.width50]="isPermission"
            [class.content]="!isPermission"
            [class.editEnable]="editEnable"
        >
            <div *ngIf="!((editEnable && editable) || new); else editionField">
                <span>
                    {{
                    (fieldsValueText && fieldsValueText[translate.currentLang] !== undefined) ?
                        fieldsValueText[translate.currentLang] :
                        fieldsValueText
                    }}
                </span>
            </div>
            <ng-template #editionField>
                <div
                    *ngIf="(editEnable && editable) || new"
                    id="{{fieldsName}}Field"
                    class="{{ fieldsName }}_field"
                >
                    <input
                        *ngIf="fieldsType === 'text'" type="text"
                        (focus)="editEnable"
                        [(ngModel)]="fieldsValue"
                        (ngModelChange)="handleValueChange()"
                    />

                    <my-date-picker
                        *ngIf="fieldsType === 'date-picker'"
                        [locale]="translate.currentLang"
                        [options]="currentDatePickerOptions"
                        [(ngModel)]="fieldsValue"
                        (ngModelChange)="handleValueChange()"
                    ></my-date-picker>
                    <select
                        *ngIf="fieldsType === 'select'"
                        [(ngModel)]="fieldsValue"
                        (ngModelChange)="handleValueChange()"
                    >
                        <option *ngIf='fieldsOptions.default === "select"'
                                value="">{{ 'commons.select' | translate }}</option>
                        <option *ngIf='fieldsOptions.default === "none"'
                                value="">{{ 'commons.none' | translate }}</option>
                        <option *ngIf='fieldsOptions.default === "blank"'
                                value=""></option>

                        <ng-container
                            *ngIf="fieldsName !== 'productCodes' && fieldsName !== 'accountType' && fieldsName !== 'administrator' && fieldsName !== 'parentAccountNumber'"
                        >

                            <option *ngFor="let option of fieldsOptions.selectOptions"
                                    [value]="option.value">{{ option.text[translate.currentLang] }}</option>
                        </ng-container>
                        <ng-container *ngIf="fieldsName === 'parentAccountNumber'">
                            <option *ngFor="let option of fieldsOptions.selectOptions"
                                        [style.font-weight]="(option.value === 'title') ? 'bold' : undefined"
                                        [disabled]="(option.value === 'title')"
                                        [value]="(option.value !== 'title') ? option.value : undefined"
                            >
                                <ng-container *ngIf="option.value === 'title'">
                                    {{ handleOptionTexts(option.text[translate.currentLang], true) }}
                                </ng-container>
                                <ng-container *ngIf="option.value !== 'title'">
                                    &nbsp;&nbsp;&nbsp;&nbsp;{{ handleOptionTexts(option.text[translate.currentLang]) }}
                                </ng-container>
                            </option>
                        </ng-container>
                        <ng-container
                            *ngIf="fieldsName === 'administrator'"
                        >
                            <option *ngFor="let option of fieldsOptions.selectOptions"
                                    [value]="option">{{ option }}</option>
                        </ng-container>
                        <ng-container
                            *ngIf="fieldsName === 'productCodes'"
                        >
                            <ng-container *ngFor="let country of ['Canada', 'US']">
                                <option disabled>{{handleCountry(country)}}</option>
                                <ng-container *ngFor="let prCat of fieldsOptions.selectOptions[country]">
                                    <option *ngIf="prCat.hasOwnProperty('sku')" [value]="prCat['sku']">
                                        &nbsp;&nbsp;{{ prCat['sku'] }}
                                        - {{prCat['descriptive'][translate.currentLang]}}
                                    </option>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container
                            *ngIf="fieldsName === 'accountType'"
                        >
                            <ng-container *ngFor="let acctType of fieldsOptions.selectOptions">
                                <option [value]="acctType.value + '_' + (!!(acctType['discretionary']) ? 'disc' : 'false')">
                                    {{ acctType.text[translate.currentLang] }}
                                </option>
                            </ng-container>

                        </ng-container>
                    </select>
                    <mat-checkbox
                        *ngIf="fieldsType === 'checkbox'"
                        [(ngModel)]="fieldsValue"
                        (ngModelChange)="handleValueChange()"
                    ></mat-checkbox>
                </div>
            </ng-template>
        </td>
        <td *ngIf="!editEnable && editable && !new && canEdit; else voidSpace" class="pencilCell">
            <div
                [class.editable]="!editEnable"
                (click)="startEdition()"
            >
                <div class="editableIcon"></div>
            </div>
        </td>
        <ng-template #voidSpace>
            <td class="pencilCell">
                <div
                    [class.editable]="!editEnable"
                >
                </div>
            </td>
        </ng-template>
    </tr>
    <tr *ngIf="editEnable && editable && !new" class="modifyButtons">
        <td *ngIf="!isPermission"></td>
        <td
            colspan="2"
            [class.modifyEnabled]="editEnable"
        >
            <div
                class="editEnable"
            >
                <div *ngIf="format && format !== null">
                    <span
                        class="formats pull-left">Format{{ (translate.currentLang === 'fr') ? ' :' : ':' }}{{ format }}</span>
                </div>
                <div *ngIf="fieldsName === 'username'"><span
                    class="formats pull-left">{{validation['message']}}</span></div>
                <div class="pull-right">
                    <div *ngIf="!isPermission && !(fieldsName === 'username' && !validation['valid'])"
                         (click)="updateField()"
                         class="pull-left saveText"
                    >
                        {{'commons.save' | translate }}
                    </div>
                    <div (click)="cancelEditField()"
                         class="pull-left cancelText"
                         style="margin-left: 10px"
                    >
                        {{ 'commons.cancel' | translate }}
                    </div>
                    <div class="clearfix"></div>
                </div>
            </div>
        </td>
    </tr>
</table>
