<div
    [class.wrapper]="whereAmI!=='preferences'"
    [class.affix]="whereAmI!=='preferences'"
    [style.width]="width"
    [style.height]="(whereAmI!=='preferences') ? '100vh' : null"
>
    <div [class.topHead]="whereAmI!=='preferences'">
        <div *ngIf="peopleSearchFilter.filter" class="pull-left">
            <h2>
                {{ 'commons.displayManagement' | translate }}
            </h2>
        </div>
        <div *ngIf="peopleSearchFilter.filter" (click)="peopleList.goToPrec()" class="x-close pull-right" style="font-size: 20pt;">
            &#x00D7;
        </div>
        <div class="clearfix"></div>
        <h3 class="filtersPrefs">{{ 'commons.display' | translate }}</h3>
        <div id="cardIcon">
            <div *ngIf="!user.isSender" (click)="toggleListView()" class="pull-left">
                <div *ngIf="!peopleList.archive && !stateService.session.hasSatelliteRole">
                    <img alt="" *ngIf="peopleList.statePeople && peopleList.statePeople['listView'] == 'list'"
                         src="{{buttonsFolder}}list_selected_card.png"/>
                    <img alt="" *ngIf="peopleList.statePeople && peopleList.statePeople['listView'] == 'cards'"
                         src="{{buttonsFolder}}list_card_selected.png"/>
                </div>
            </div>
            <div class="pull-right" style="padding-top: 10px">
                <span (click)="resetSearchFilters()" class="fakeLink">
                    {{ 'commons.reset' | translate }}
                </span>
            </div>
            <div class="clearfix"></div>
        </div>
        <hr class="panelHr"/>
        <h3 class="fontLight" style="margin-top: 18px; font-size: 13pt">{{ 'commons.filters' | translate }}</h3>
        <div *ngIf="peopleSearchFilter.filter" class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 160px;" class="subTitle">{{ 'people.listSearchFilters.surveysDate' | translate }}
                </div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <p class="flat">{{ 'commons.start' | translate }}</p>
                    <div style="width: 95%">
                        <div class="datePicker startdp">
                            <my-date-picker
                                [locale]="translate.currentLang"
                                [selDate]="peopleSearchFilter.dateStart"
                                [options]="peopleSearchFilter.datePickerOptions"
                                [(ngModel)]="peopleSearchFilter.dateStartModel"
                                (dateChanged)="setFilters($event, 'evaluationStartDate')"
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <p class="flat">{{ 'commons.end' | translate }}</p>
                        <div class="datePicker enddp">
                            <my-date-picker
                                [locale]="translate.currentLang"
                                [selDate]="peopleSearchFilter.dateEnd"
                                [options]="peopleSearchFilter.datePickerOptions"
                                [(ngModel)]="peopleSearchFilter.dateEndModel"
                                (dateChanged)="setFilters($event, 'evaluationEndDate')"
                            ></my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 193px;" class="subTitle">{{ 'people.listSearchFilters.reportsAndQuestionnaires' | translate }}</div>
            </div>
            <div>
                <div *ngIf="!stateService.session.hasSatelliteRole" class="pull-left" style="width: 50%">
                    <div *ngIf="peopleSearchFilter.testsOptions.length > 0" class="reportCol width95">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.reports' | translate"
                            [settings]="peopleSearchFilter.dropDownSettings"
                            [data]="peopleSearchFilter.testsOptions"
                            [(ngModel)]="peopleSearchFilter.optionsTestsModel"
                            (onSelect)="setFilters($event, 'tests')"
                            (onDeSelect)="setFilters($event, 'tests')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div *ngIf="peopleSearchFilter.testsStatusOptions.length > 0" [class.thisNotFirst]="!stateService.session.hasSatelliteRole"
                         class="statusMpoCol width95"
                    >
                        <ng-multiselect-dropdown
                            [placeholder]="'people.commons.mpoStatus' | translate"
                            [settings]="peopleSearchFilter.dropDownSettings"
                            [data]="peopleSearchFilter.testsStatusOptions"
                            [(ngModel)]="peopleSearchFilter.optionsTestsStatusModel"
                            (onSelect)="setFilters($event, 'testsStatus')"
                            (onDeSelect)="setFilters($event, 'testsStatus')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div *ngIf="stateService.hasIac" class="pull-left" style="width: 50%">
                    <div *ngIf="peopleSearchFilter.testsStatusOptions.length > 0" [class.thisNotFirst]="!stateService.session.hasSatelliteRole"
                         class="statusCaiCol width95"
                    >
                        <ng-multiselect-dropdown
                            [placeholder]="'people.commons.caiStatus' | translate"
                            [settings]="peopleSearchFilter.dropDownSettings"
                            [data]="peopleSearchFilter.testsStatusOptions"
                            [(ngModel)]="peopleSearchFilter.optionsTestsStatusRaModel"
                            (onSelect)="setFilters($event, 'testsCaiStatus')"
                            (onDeSelect)="setFilters($event, 'testsCaiStatus')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="filterFamily">
            <div>
                <hr class="panelHr">
                <div style="width: 76px;" class="subTitle">{{ 'commons.business' | translate }}</div>
            </div>
            <div>
                <div class="pull-left" style="width: 50%">
                    <div class="subAccountCol width95">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.subAccount' | translate"
                            [settings]="peopleSearchFilter.dropDownSettings"
                            [data]="peopleSearchFilter.subAccountsOptions"
                            [(ngModel)]="peopleSearchFilter.subAccountsOptionsModel"
                            (onSelect)="setFilters($event, 'subAccount')"
                            (onDeSelect)="setFilters($event, 'subAccount')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <!-- department -->
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right depCol width95">
                        <ng-multiselect-dropdown
                            [placeholder]="'commons.division' | translate"
                            [settings]="peopleSearchFilter.dropDownSettings"
                            [data]="peopleSearchFilter.departmentOptions"
                            [(ngModel)]="peopleSearchFilter.departmentOptionsModel"
                            (onSelect)="setFilters($event, 'department')"
                            (onDeSelect)="setFilters($event, 'department')"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div class="filterFamily" style="display: none">
            <div>
                <hr class="panelHr">
                <div style="width: 66px;" class="subTitle">{{ 'commons.employee' | translate }}</div>
                <div class="pull-left" style="width: 50%">
                    <div style="width: 95%">
                        <p class="flat">{{ 'people.commons.hiringDate' | translate }}</p>
                        <div class="datePicker hiringdp">
                            <my-date-picker
                                [locale]="translate.currentLang"
                                [options]="peopleSearchFilter.datePickerOptions"
                                [(ngModel)]="peopleSearchFilter.hiringDateModel"
                                required>
                            </my-date-picker>
                        </div>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%"></div>
                </div>
                <div class="clearfix"></div>
                <div class="pull-left" style="width: 50%">
                    <div style="width: 95%">
                        <select class="form-control">
                            <option>Titre</option>
                            <option></option>
                        </select>
                    </div>
                </div>
                <div class="pull-left" style="width: 50%">
                    <div class="pull-right" style="width: 95%">
                        <select class="form-control">
                            <option>{{ 'people.listSearchFilters.employeesProfile' | translate }}</option>
                            <option></option>
                        </select>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div *ngIf="peopleList.statePeople && peopleList.statePeople['listView'] == 'list'"><!-- columnsToSelect!==null && -->
            <hr class="panelHr"/>
            <h4 style="margin-top: 15px">{{ 'commons.listsDisplayParameters' | translate }}</h4>
            <div id="columnsDisplay">
                <div style="font-size:90%; padding-bottom: 7px">
                    <!--span *ngIf="columnsToSelect > 0">
                        {{ 'commons.canSelectXColumns' | translate }} {{ columnsToSelect }} {{ 'commons.columns' | translate }}
                    </span>
                    <span *ngIf="columnsToSelect===0" style="color: #e45656">
                        {{ 'commons.maxColumnsSelected' | translate }}
                    </span-->
                </div>
                <ng-container
                    *ngFor="let col of peopleList.columns; let i = index"
                >
                    <div class="col-md-6"
                         style="margin: 0; padding: 0"
                         *ngIf="col!= 'statusPrb' && (!stateService.session.hasSatelliteRole ||
                                (col!='reportStatuses.mpo' &&
                                col!='reportStatuses.dit' &&
                                col!='reportStatuses.talents'))"
                    >
                        <mat-checkbox id="fieldsFilter_{{ col }}"
                                      #thisCb
                                      [checked]="peopleSearchFilter.filtering(peopleList.listColumns, col)"
                                      (change)="setFilters({ key: col, display: (thisCb.checked) }, 'columnDisplay')"
                                      [disabled]="(i==0 || i==1)"
                        >{{ peopleList.columnsName[translate.currentLang][col] }}
                        </mat-checkbox>
                    </div>
                </ng-container>
                <div class="clearfix"></div>
            </div>
        </div>
        <!-- div class="filterFamily" *ngIf="peopleList.statePeople && peopleList.statePeople['listView'] == 'cards'">
            <div>
                <hr class="panelHr">
                <div style="width: 22px;" class="subTitle">{{ 'people.listSearchFilters.sort' | translate }}</div>
            </div>
            <label for="sortCardsBy">{{ 'people.listSearchFilters.sortCardsBy' | translate }}</label>&nbsp;
            <select [(ngModel)]="peopleSearchFilter.sortCardsBy" (change)="sortCards()" id="sortCardsBy">
                <option>{{ 'commons.none' | translate }}</option>
                <option value="firstName">
                    {{ 'commons.firstName' | translate }}
                </option>
                <option value="lastName">
                    {{ 'commons.lastName' | translate }}
                </option>
                <option value="creationDate">
                    Date
                </option>
                <option value="recordType">
                    Type
                </option>
                <option value="jobTitle">
                    {{ 'commons.title' | translate }}
                </option>
            </select>&nbsp;&nbsp;
            <label>
                <select [(ngModel)]="peopleSearchFilter.sortCardsOrder" (change)="sortCards()">
                    <option value="">{{ 'commons.select' | translate }}</option>
                    <option value="asc">
                        {{ 'people.listSearchFilters.ascending' | translate }}
                    </option>
                    <option value="desc">
                        {{ 'people.listSearchFilters.descending' | translate }}
                    </option>
                </select>
            </label>
        </div -->
    </div>
</div>
