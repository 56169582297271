<div *ngIf="user && jobSatisfaction">
    <div *ngIf="tabletMode">
        <div class="affix topBar">
            <h2 class="name">{{ jobSatisfaction.name.toUpperCase() }}</h2>
            <div>
                <app-nav-button (buttonActon)="goToList()">
                    <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
                    <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                </app-nav-button>
                <app-nav-button
                    [isBlank]="!jobSatisfaction.records || !jobSatisfaction.records.length"
                    (buttonActon)="openPdfModal()"
                >
                    <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                    <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                </app-nav-button>
                <div class="pull-left dropdown" dropdown>
                    <div dropdownToggle
                         class="dropdown-toggle sideBarButton"
                         data-toggle="dropdown"
                    >
                        Options&nbsp;<span class="caret"></span>
                    </div>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                        <li *ngIf="!jobSatisfaction.archive">
                            <a (click)="openEditModal()">
                                {{ 'commons.editInfo' | translate }}
                            </a>
                        </li>
                        <li *ngIf="!jobSatisfaction.archive" role="menuitem">
                            <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                        </li>
                        <li *ngIf="canDelete" role="menuitem"><a
                            (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                        </li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
        <div style="height: 100px"></div>
        <div>
            <div class="infoWrap">
                <table class="infoTableWrap">
                    <tr>
                        <td class="switchButton">
                            <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                <span *ngIf="!informationVisibility">+</span>
                                <span *ngIf="informationVisibility">-</span>
                            </button>
                        </td>
                        <td>
                            <mat-tab-group [(selectedIndex)]="selectedInfoQuestionTab">
                                <mat-tab label="{{ 'commons.informations' | translate }}">
                                    <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                        <tr>
                                            <td> {{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!jobSatisfaction.name">
                                                    {{ jobSatisfaction.name || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!jobSatisfaction.date">
                                                    {{ jobSatisfaction.date || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!jobSatisfaction.consultant">
                                                    {{ jobSatisfaction.consultant || notSpecified() }}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!jobSatisfaction.subAccount">
                                                        {{ setSubAccountName(jobSatisfaction.subAccount)
                                                || notSpecified()  }}
                                                    </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> {{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span>
                                                <span [class.na]="!jobSatisfaction.department || !jobSatisfaction.department.length">
                                                        {{ (jobSatisfaction.department && jobSatisfaction.department.length) ? jobSatisfaction.department : notSpecified() }}
                                                    </span>
                                            </td>
                                        </tr>
                                        
                                    </table>
                                </mat-tab>
                            </mat-tab-group>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <hr class="panelHr"/>
            </div>
            <div *ngIf="nameListing">
                <div class="floatLeft" style="width: calc(100% - 115px)">
                    <h1 class="mainTitleRmSb">{{ 'jobSatisfactions.report.portrait' | translate }}</h1>
                </div>
                <div class="floatRight" [innerHTML]="jobSatisfaction.circle | safeHtml"></div>
                <div class="clearfix"></div>
                <div class="floatLeft" style="width: calc(50% - 2px)">
                    <div id="greenTablet">
                        <div id="greenTitleTablet" class="colorTitle" style=" background-color: #78c7af">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_green_white'"
                                    [background]="'white'"
                                    [score]="2"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['green'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.green | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personHighJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingGreenTablet" class="nameListing">
                        <ng-container *ngIf="nameListing['green'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['green']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.green[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!--div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'green_' + name"
                                        [background]="'regular'"
                                        [score]="2"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['green'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="floatRight" style="width: calc(50% - 2px)">
                    <div id="yellowTablet">
                        <div id="yellowTitleTablet" class="colorTitle" style=" background-color: #efce7c">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_yellow_white'"
                                    [background]="'white'"
                                    [score]="1"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['yellow'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.yellow | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personMedJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingYellowTablet" class="nameListing" style="float: right">
                        <ng-container *ngIf="nameListing['yellow'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['yellow']; let i = index">
                                <div class="floatLeft listsNames" >
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.yellow[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!--div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'yellow_' + name"
                                        [background]="'regular'"
                                        [score]="1"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['yellow'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="clearfix"></div>
                <div class="floatLeft" style="width: calc(50% - 2px)">
                    <div id="redTablet">
                        <div id="redTitleTablet" class="colorTitle" style=" background-color: #F07B7E">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_red_white'"
                                    [background]="'white'"
                                    [score]="0"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['red'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.red | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personLowJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingRedTablet" class="nameListing">
                        <ng-container *ngIf="nameListing['red'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['red']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)"  [class.ngred]="redoPcrListing.red[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!-- div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'red_' + name"
                                        [background]="'regular'"
                                        [score]="0"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['red'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>

                    </div>
                </div>
                <div class="floatRight" style="width: calc(50% - 2px)">
                    <div id="greyTablet">
                        <div id="greyTitleTablet" class="colorTitle"
                             style=" background-color: #C7C6C7">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_grey_white'"
                                    [background]="'white'"
                                    [score]="null"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['grey'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.grey | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personUnknownJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingUnkownTablet" class="nameListing">
                        <ng-container *ngIf="nameListing['grey'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['red']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.red[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!-- div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'grey_' + name"
                                        [background]="'regular'"
                                        [score]="undefined"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['grey'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
    <div *ngIf="!tabletMode && jobSatisfaction.id && stateService.jobSatisfaction" (window:resize)="onResize($event)">
        <ngx-loading
            [show]="!jobSatisfaction.id"
            [config]="loaderDisplay"
            [class]="'itemLoading'"
        ></ngx-loading>
        <div
            class="top"
        >

            <div class="topHead">
                <div
                    class="topHead"
                >
                    <div class="pull-left"><h2 class="name">{{ jobSatisfaction.name.toUpperCase() }}</h2></div>
                    <div *ngIf="config=='list'" (click)="goToNews()" class="x-close pull-right"
                         style="font-size: 20pt;">
                        &#x00D7;
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div *ngIf="jobSatisfactionListService.archive">
                    <span (click)="modStatus('archive', false)"
                          class="fakeLink">{{ 'commons.reactivate' | translate }}</span>&nbsp;&nbsp;
                    <span *ngIf="canDelete" (click)="modStatus('delete')"
                          class="fakeLink">{{ 'commons.delete' | translate }}</span>
                </div>
                <div *ngIf="config=='list'; else reportFullButtons">
                    <div *ngIf="!jobSatisfactionListService.archive">
                        <app-nav-button (buttonActon)="goToFullReport(jobSatisfaction.id)"
                                        *ngIf="jobSatisfaction.records && jobSatisfaction.records.length !== 0"
                        >
                            <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
                            <div class="buttonTitle">{{ 'commons.fullView' | translate }}</div>
                        </app-nav-button>
                        <app-nav-button
                            *ngIf="jobSatisfaction.records && jobSatisfaction.records.length !== 0"
                            [isBlank]="!jobSatisfaction.records || !jobSatisfaction.records.length"
                            (buttonActon)="openPdfModal()"
                        >
                            <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                            <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                        </app-nav-button>
                        <div class="pull-left dropdown" dropdown>
                            <div dropdownToggle
                                 class="dropdown-toggle sideBarButton"
                                 data-toggle="dropdown"
                            >
                                {{ 'commons.options' | translate }}&nbsp;<span class="caret"></span>
                            </div>
                            <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                                <li *ngIf="!jobSatisfactionListService.archive">
                                    <a (click)="openEditModal()">
                                        {{ 'commons.editInfo' | translate }}
                                    </a>
                                </li>
                                <li *ngIf="!jobSatisfactionListService.archive" role="menuitem">
                                    <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                                </li>
                                <li *ngIf="canDelete" role="menuitem"><a
                                    (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a></li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <ng-template #reportFullButtons>
                    <app-nav-button (buttonActon)="goToList()">
                        <div class="buttonImage"><img alt="" src="assets/buttons/fullView.png"/></div>
                        <div class="buttonTitle">{{ 'commons.backToList' | translate }}</div>
                    </app-nav-button>

                    <app-nav-button
                        [isBlank]="!jobSatisfaction.records || !jobSatisfaction.records.length"
                        (buttonActon)="openPdfModal()"
                    >
                        <div class="buttonImage"><img alt="" src="assets/buttons/download.png"/></div>
                        <div class="buttonTitle">{{ 'commons.pdfReport' | translate }}</div>
                    </app-nav-button>
                    <div class="pull-left dropdown" dropdown>
                        <div dropdownToggle
                             class="dropdown-toggle sideBarButton"
                             data-toggle="dropdown"
                        >
                            Options&nbsp;<span class="caret"></span>
                        </div>
                        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
                            <li *ngIf="!jobSatisfactionListService.archive">
                                <a (click)="openEditModal()">
                                    {{ 'commons.editInfo' | translate }}
                                </a>
                            </li>
                            <li *ngIf="!jobSatisfactionListService.archive" role="menuitem">
                                <a (click)="modStatus('archive', true)">{{ 'commons.archive' | translate }}</a>
                            </li>
                            <li *ngIf="canDelete" role="menuitem"><a
                                (click)="modStatus('delete')">{{ 'commons.delete' | translate }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="clearfix"></div>
                </ng-template>

                <div>
                    <hr class="panelHr"/>
                </div>
                <div style="height: 5px;"></div>
                <div class="infoWrap">
                    <table class="infoTableWrap">
                        <tr>
                            <td class="switchButton">
                                <button type="button" class="btn btn-default" (click)="toggleInformationVisibility()">
                                    <span *ngIf="!informationVisibility">+</span>
                                    <span *ngIf="informationVisibility">-</span>
                                </button>
                            </td>
                            <td>
                                <mat-tab-group>
                                    <mat-tab label="{{ 'commons.informations' | translate }}">
                                        <table class="infoTable" id="infoTable" *ngIf="informationVisibility">
                                            <tr>
                                                <td> {{ 'analysisCommons.analysisName' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!jobSatisfaction.name">
                                                        {{ jobSatisfaction.name || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!jobSatisfaction.date">
                                                        {{ jobSatisfaction.date || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> {{ 'commons.requester' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!jobSatisfaction.consultant">
                                                        {{ jobSatisfaction.consultant || notSpecified() }}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>{{ 'commons.subAccount' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!jobSatisfaction.subAccount">
                                                            {{ setSubAccountName(jobSatisfaction.subAccount)
                                                    || notSpecified()  }}
                                                        </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> {{ 'commons.department' | translate }}<span [innerHTML]="titleSep"></span>
                                                    <span [class.na]="!jobSatisfaction.department || !jobSatisfaction.department.length">
                                                            {{ (jobSatisfaction.department && jobSatisfaction.department.length) ? jobSatisfaction.department : notSpecified() }}
                                                        </span>
                                                </td>
                                            </tr>
                                            
                                        </table>
                                    </mat-tab>
                                </mat-tab-group>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="nameListing">
                    <div class="floatLeft" style="width: calc(100% - 115px)">
                        <h2 style="font-family: 'MontserratSemiBold',  sans-serif; padding-top: 17px">{{ 'jobSatisfactions.report.portrait' | translate }}</h2>
                    </div>
                    <div class="floatRight" [innerHTML]="jobSatisfaction.circle | safeHtml"></div>
                    <div class="clearfix"></div>
                    <div id="green">
                        <div id="greenTitle" class="colorTitle" style=" background-color: #78c7af">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_green_white'"
                                    [background]="'white'"
                                    [score]="2"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['green'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.green | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personHighJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingGreen" class="nameListing">
                        <ng-container *ngIf="nameListing['green'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['green']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.green[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!--div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'green_' + name"
                                        [background]="'regular'"
                                        [score]="2"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['green'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                    <div id="yellow">
                        <div id="yellowTitle" class="colorTitle" style=" background-color: #efce7c">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_yellow_white'"
                                    [background]="'white'"
                                    [score]="1"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['yellow'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.yellow | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personMedJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingYellow" class="nameListing">
                        <ng-container *ngIf="nameListing['yellow'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['yellow']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.yellow[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!--div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'yellow_' + name"
                                        [background]="'regular'"
                                        [score]="1"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['yellow'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                    <div id="red">
                        <div id="redTitle" class="colorTitle" style=" background-color: #F07B7E">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_red_white'"
                                    [background]="'white'"
                                    [score]="0"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['red'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.red | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personLowJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingRed" class="nameListing">
                        <ng-container *ngIf="nameListing['red'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['red']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.red[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!-- div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'red_' + name"
                                        [background]="'regular'"
                                        [score]="0"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['red'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>

                    </div>
                    <div id="grey">
                        <div id="greyTitle" class="colorTitle"
                             style=" background-color: #C7C6C7">
                            <div class="floatLeft graphTitle">
                                <app-job-satisfaction-graphics
                                    [id]="'main_grey_white'"
                                    [background]="'white'"
                                    [score]="null"
                                    [size]="35"
                                >
                                </app-job-satisfaction-graphics>
                            </div>
                            <div class="floatLeft countTitle">
                                {{ countAndOver['grey'] }}
                            </div>
                            <div class="floatRight subCircleTitle">
                                <div [innerHTML]="jobSatisfaction.subCircle.grey | safeHtml"></div>
                            </div>

                            <div class="floatRight personTitle">
                                {{ 'jobSatisfactions.report.personUnknownJs' | translate }}
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>
                    <div id="nameListingUnkown" class="nameListing">
                        <ng-container *ngIf="nameListing['grey'].length !== 0">
                            <div class="nameBox" *ngFor="let name of nameListing['grey']; let i = index">
                                <div class="floatLeft listsNames">
                                    <a (click)="scrollToView(name)" [class.ngred]="redoPcrListing.grey[i] === 2" style="text-decoration: none">{{ name }}</a>
                                </div>
                                <!-- div class="floatRight">
                                    <app-job-satisfaction-graphics
                                        [id]="'grey_' + name"
                                        [background]="'regular'"
                                        [score]="undefined"
                                        [size]="20"
                                    ></app-job-satisfaction-graphics>
                                </div-->
                                <div class="clearfix"></div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="nameListing['grey'].length === 0">
                            <div class="nameBox" style="padding-bottom: 3px">
                                <div class="fakeItem listsNames" [style.width]="'150px'">&nbsp;</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>