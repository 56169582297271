<div id="newsBackDrop" *ngIf="visible" (click)="closeNewsWindow()"></div>
<div
    id="newsFrame"
    [@visibilityChanged]="visible"
    [@displayChanged]="visible"
>
    <div (click)="closeNewsWindow()" aria-label="Close" class="x-close pull-right">
        &#x00D7;
    </div>
    <div class="clearfix"></div>
    <div id="innerFrameContainer">
        <div id="innerFrame">
            <mat-carousel
                #carousel
                [autoplay]="false"
                color="accent"
                maxWidth="auto"
                slides="{{carouselSlides.length}}"
                [maintainAspectRatio]="false"
                [hideArrows]="false"
                [hideIndicators]="true"
                [useKeyboard]="true"
                [useMouseWheel]="false"
                orientation="ltr"
            >
                <mat-carousel-slide
                    *ngFor="let slide of carouselSlides"
                    [hideOverlay]="true"
                >
                    <div>
                        <img src="{{ slide.img }}"/>
                        <div id="close">
                            <span (click)="closeNewsWindow(true)" class="fakeLink" style="color: #eee">
                                        <span *ngIf="translateService.currentLang === 'en'">Don’t show this message next time</span>
                                        <span *ngIf="translateService.currentLang === 'fr'">Ne plus voir ce message à la prochaine connexion</span>
                            </span>
                        </div>
                    </div>
                </mat-carousel-slide>
            </mat-carousel>
        </div>
    </div>
</div>
