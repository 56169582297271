<div *ngIf="surveyRequestFormGroup && !mobile" [formGroup]="surveyRequestFormGroup">
    <h4 style="margin-top: 20px" class="georgia">
        {{ 'people.requestModal.participantTitle' | translate }}
    </h4>

    <div>
        <div class="pull-left">
            <label for="firstNameField" class="label-first">
                <span class="field_title"
                    [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('firstName').errors"
                >{{ 'commons.firstName' | translate }}</span>
            </label>
        </div>
        <div class="clearfix"></div>
        <input id="firstNameField" type="text" class="form-control" formControlName="firstName" />
    </div>

    <div>
        <div class="pull-left">
            <label for="lastNameField">
                <span class="field_title"
                    [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('lastName').errors"
                >{{ 'commons.lastName' | translate }}</span>
            </label>
        </div>
        <div class="clearfix"></div>
        <input id="lastNameField" type="text" class="form-control" formControlName="lastName" />
    </div>

    <div>
        <div class="pull-left">
            <label for="emailField">
                <span class="field_title"
                    [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('email').errors"
                >{{ 'commons.email' | translate }}</span>
            </label>
        </div>
        <div class="clearfix"></div>
        <input id="emailField" type="text" class="form-control" formControlName="email" />
    </div>

    <div *ngIf="researchAccount">
        <h4 style="margin-top: 15px">{{ 'people.requestModal.complementaryOptions' | translate }}</h4>
        <div>
            <mat-checkbox id="research_questions"
                          formControlName="researchQuestions"
            >&nbsp;{{ 'people.requestModal.researchQuestions' | translate }}
            </mat-checkbox>
        </div>
    </div>
</div>
<div *ngIf="surveyRequestFormGroup && mobile"
     [formGroup]="surveyRequestFormGroup"
     class="mobileParticipantSection"
>
    <h4 class="georgia">
        {{ 'people.requestModal.participantTitle' | translate }}
    </h4>

    <label for="firstNameMField"
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('firstName').errors"
    >{{ 'commons.firstName' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="firstNameMField" formControlName="firstName">
    </mat-form-field>

    <label for="lastNameMField"
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('lastName').errors"
    >{{ 'commons.lastName' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="lastNameMField" formControlName="lastName">
    </mat-form-field>

    <label for="emailMField"
        [class.text-danger]="isFormSubmitted && surveyRequestFormGroup.get('email').errors"
    >{{ 'commons.email' | translate }}</label>
    <mat-form-field appearance="fill">
        <input matInput id="emailMField" formControlName="email">
    </mat-form-field>

    <div *ngIf="researchAccount">
        <h4>{{ 'people.requestModal.complementaryOptions' | translate }}</h4>
        <div>
            <mat-checkbox id="researchQuestions"
                          formControlName="researchQuestions"
            >&nbsp;{{ 'people.requestModal.researchQuestions' | translate }}
            </mat-checkbox>
        </div>
    </div>
</div>
