import * as form_definition from '../../../assets/form-definitions/login';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter,
    Input,
    OnInit,
    Output, Renderer2
} from '@angular/core';
import {LoginService} from '../../../services/login.service';
import {ApiAdministrationService} from 'src/app/administration/services/api-administration.service';
import {JwtHelperService} from '@auth0/angular-jwt';
import {StateService} from '../../../../core/services/state/state.service';
import {Location} from '@angular/common';
import {filter} from 'rxjs/operators';
import { EnvService } from 'src/app/app/services/env.service';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileLoginComponent implements OnInit, AfterViewInit {
    loginForm: any;
    public loading = false;
    pw: string;
    lang: string;
    language: string;
    wHeight: number;
    versionNumber;
    passwordChangeSuccess = false;
    errorLogin: any;

    baseUrl = '';

    passwordStaging = null;

  @Input() redirect = false;
  @Output() credentials = new EventEmitter();
  constructor(
      public state: StateService,
      private router: Router,
      private route: ActivatedRoute,
      private el: ElementRef,
      private renderer: Renderer2,
      private location: Location,
      private apiAdministrationService: ApiAdministrationService,
      private loginService: LoginService,
      public translate: TranslateService,
      private jwtHelperService: JwtHelperService,
      private changeDetectorRef: ChangeDetectorRef,
      public environment: EnvService
  ) {
      this.loginForm = form_definition.login_form;
      if (!this.language) {
          this.setLanguage();
      }

  }

  changeLang(lang) {
      localStorage.setItem('sessionLang', lang);
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
  }

  checkToken() {
      if (!this.jwtHelperService.isTokenExpired()) {
        this.router.navigate(['m/people']).then(() => {});
      }
  }

  setLanguage(language = this.language) {
      if (language) {
          this.language = language;
      } else if (localStorage.getItem('sessionLang')) {
          this.language = localStorage.getItem('sessionLang');
      } else {
          if (!this.language) {
              this.language = this.translate.getBrowserLang();
          }
      }
      this.language = (this.language !== 'en' &&  this.language !== 'fr' ) ? 'en' : this.language;
      this.translate.setDefaultLang(this.language);
      this.translate.use(this.language);
  }

  ngOnInit() {
    this.baseUrl = (this.environment && this.environment.apiBaseUrl) ? this.environment.apiBaseUrl : '';
      this.wHeight = window.innerHeight;
      this.apiAdministrationService.frontVersion().subscribe(
          res => {
              this.versionNumber = 'version ' + res.version;
              this.changeDetectorRef.markForCheck();
          }
      );    

      let urlParamsString = this.location.path().split('/');
      let urlParams = (typeof urlParamsString[1] !== 'undefined'
          && typeof (urlParamsString[1].split('?'))[1] !== 'undefined'
      ) ?
          (urlParamsString[1].split('?'))[1].split(';') :
          '';
      for (let urlParam of urlParams) {
          if ('undefined' !== typeof urlParam) {
              let urlParamArr = urlParam.split('=');
              if (urlParamArr[0] === 'pw') { this.pw = urlParamArr[1]; }
              if (urlParamArr[0] === 'lang') {
                  this.lang = urlParamArr[1];
                  this.language = this.lang;
                  this.translate.setDefaultLang(this.lang);
                  this.translate.use(this.lang);
              }
          }
      }

      this.passwordChangeSuccess = !!this.pw;
      this.router.events.pipe(
          filter(event => event instanceof NavigationStart))
          .subscribe((event: NavigationStart) => {
              let setLangTo = (localStorage.getItem('sessionLang')) ? localStorage.getItem('sessionLang') : this.translate.getBrowserLang();
              let urlArr = event.url.split(';');
              urlArr.forEach((q) => {
                  if (q.includes('lang=')) {
                      setLangTo = q.replace('lang=', '');
                  }
              });
              if (urlArr[0] === 'login') {
                  this.setLanguage(setLangTo);
                  localStorage.setItem('logOutSessionLang', this.language);
              }
              this.changeDetectorRef.markForCheck();
          }
      );

      // Default redirect if authenticated
      // @ilya 2020-09-30 it was canceled
      // this.checkToken();
  }

    ngAfterViewInit() {
        const link = this.el.nativeElement.querySelector(
            'app-form-table-mobile form table#table_loginForm tr td#loginForm-0-4-0-blank-html-a a'
        );
        this.renderer.listen(link, 'click', () => {
            this.router.navigate(['reset-pass']);
            this.changeDetectorRef.markForCheck();
        });
        const checkbox = this.el.nativeElement.querySelector(
            'div#loginForm-0-5-0-cbox_container input'
        );
        this.renderer.setAttribute(checkbox, 'value', 'checked');
        this.renderer.listen(checkbox, 'change', (val) => {
            if (val.target.checked) {
                localStorage.setItem('remember_me', 'true');
            } else {
                localStorage.setItem('remember_me', 'false');
            }
        });
    }

    handleForm(event) {
        if (
            this.baseUrl === 'https://staging.ngenioconnect.com/' &&
            this.passwordStaging !== 'QAMaster!'
        ) {
            return;
        }
        this.loading = true;
        this.loginService.login(this, event, this.language, true);
    }

}
