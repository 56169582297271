<div (window:resize)="onResize()">
    <h2 style="margin-top: 15px; font-family: MontserratSemiBold,  sans-serif; font-size: 22px;">{{ 'jobs.commons.jobInfoMpo' | translate }}</h2>

    <div>
        <ul class="plainList">
            <li *ngIf="rightMatchService.jobDescription.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ rightMatchService.jobDescription.jobTitle }}</li>
            <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (rightMatchService.jobDescription.competencesModificationDate || rightMatchService.jobDescription.creationDate || rightMatchService.jobDescription.date) | date:reportDateFormat}}</li>
        </ul>

        <hr/>

        <div *ngIf="hasWarning" class="warning">
            <p *ngIf="rightMatchService.jobDescription.jobReport.warning['equalAS']">
                {{ rightMatchService.jobDescription.jobReport.warning['equalAS'] }}
            </p>
            <div *ngIf="rightMatchService.jobDescription.jobReport.warning['equalAS'] && rightMatchService.jobDescription.jobReport.warning.text">
                &nbsp;
            </div>
            <p *ngIf="rightMatchService.jobDescription.jobReport.warning.text">
                {{ rightMatchService.jobDescription.jobReport.warning.text }}
            </p>
        </div>

        <div *ngIf="profile">
            <h3 style="margin-top: 15px">{{ 'jobs.reportMpo.weSearchForSOWho' | translate }}<span [innerHTML]="titleSep"></span></h3>
            <hr/>
            <div *ngIf="profile && profile.par" id="portrait">
                <p>{{ profile.OR }}</p>
                <p>{{ profile.SE }}</p>
                <p *ngFor="let paragraph of profile.par">
                    {{ paragraph }}
                </p>
                <h3>{{ 'jobs.reportMpo.commAndLeadStyle' | translate }}<span [innerHTML]="titleSep"></span></h3>
                <h4>{{ 'commons.leadStyle' | translate }}</h4>
                <ul>
                    <li *ngFor="let item of profile.lead">
                        {{ item }}
                    </li>
                </ul>

                <h4>{{ 'commons.commStyle' | translate }}</h4>

                <ul *ngIf="profile.comm">
                    <li *ngFor="let item of profile.comm">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.coms">
                    <li *ngFor="let item of profile.coms">
                        {{ item }}
                    </li>
                </ul>

                <h3>{{ 'jobs.reportMpo.motvSupport' | translate }}</h3>

                <h4>{{ 'commons.workEnvironment' | translate }}</h4>
                <ul *ngIf="profile.menv">
                    <li *ngFor="let item of profile.menv">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.wenv">
                    <li *ngFor="let item of profile.wenv">
                        {{ item }}
                    </li>
                </ul>

                <h4>{{ 'commons.motivationalNeeds' | translate }}</h4>
                <ul *ngIf="profile.mper">
                    <li *ngFor="let item of profile.mper">
                        {{ item }}
                    </li>
                </ul>
                <ul *ngIf="profile.motv">
                    <li *ngFor="let item of profile.motv">
                        {{ item }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
