import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Subscription} from '../../../../shared/barrels/rxjs/';
import {Location} from '@angular/common';
import {
    ActivatedRoute, Router
} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../services/login.service';
import {passwordStrengthValidator} from '../../../validators/password-strength';
import {Tools} from '../../../../shared/barrels/misc';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-mobile-resetpass',
    templateUrl: './mobile-resetpass.component.html',
    styleUrls: ['./mobile-resetpass.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileResetpassComponent implements OnInit, OnDestroy {
    token: any;
    message: string;
    forgetPassForm: FormGroup;
    changePassForm: FormGroup;
    validateFormField = Tools.validateFormField;
    errorForm: any;

    discrepancies = false;

    badUserName = false;

    messageFormSubmit = false;
    messagePasswordChange = false;
    emptyUserName = false;

    instructionOn = false;

    language;
    hide = true;

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        private changeDetectorRef: ChangeDetectorRef, // Used in the service
        private fb: FormBuilder,
        private location: Location,
        public translate: TranslateService,
    ) {
        this.language = (this.translate.currentLang) ? this.translate.currentLang : this.translate.getBrowserLang();
        if (this.language !== 'fr' && this.language !== 'en') {
            this.language = 'en';
        }
        this.translate.setDefaultLang(this.language);
        this.translate.use(this.language);
    }

    ngOnInit() {
        this.discrepancies = false;
        this.instructionOn = false;
        // Token
        let urlParams = this.location.path().split('/');

        if ('undefined' === typeof urlParams[2]) {
            this.initForgetPassForm();
        } else {
            this.token = urlParams[2];
            this.initChangePassForm(this.token);
        }
    }

    initForgetPassForm() {
        this.forgetPassForm = this.fb.group(
            {
                username: ['', [Validators.required]]
            }
        );
        return this.forgetPassForm;
    }

    langChange(lang) {
        this.translate.use(lang);
    }

    onCancelForgetPassForm() {
        this.router.navigate(['/login'], {queryParams: {lang: this.translate.currentLang}}).then(() => {});
    }

    onSubmitForgetPassForm(model: FormGroup) {
        this.emptyUserName = false;
        if (!model.value['username'] || model.value['username'] === '') {
            this.emptyUserName = true;
            return;
        }
        if (model.valid) {
            model.value['lang'] = this.translate.currentLang;
            this.subscriptions.add(this.loginService.resetPassword(model.value).subscribe(
                () => {
                    this.messageFormSubmit = true;

                    this.changeDetectorRef.markForCheck();

                    setTimeout(() => {
                        this.router.navigate(['/login']);
                    }, 5000);
                },
                () => {
                    this.badUserName = true;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }

    }

    initChangePassForm(token) {
        this.messageFormSubmit = false;
        this.messagePasswordChange = false;
        this.discrepancies = false;
        this.changePassForm = this.fb.group(
            {
                password: ['', [Validators.required, Validators.minLength(8)]],
                pwdConfirmation: ['', [Validators.required]],
                token: [token, [Validators.required]]
            },
            {
                validator: passwordStrengthValidator('password')
            }
        );

    }

    get password() {
        return this.changePassForm.get('password').value;
    }

    get pwdConfirmation() {
        return this.changePassForm.get('pwdConfirmation').value;
    }

    onSubmitChangePassForm(model: FormGroup) {
        this.instructionOn = true;
        // console.log(this.password, this.pwdConfirmation, this.password == this.pwdConfirmation);
        // return;
        this.discrepancies = (this.password === this.pwdConfirmation);
        if (model.valid) {
            this.subscriptions.add(this.loginService.changePassword(model.value).subscribe(
                () => {
                    window.location.href = '/login;pw=1';
                },
                error => {
                    this.errorForm = error.error;
                    this.changeDetectorRef.detectChanges();
                }
            ));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}

