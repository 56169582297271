<h2 style="margin-top: 15px; font-family: MontserratSemiBold,  sans-serif; font-size: 22px;">{{ 'jobs.commons.jobInfoTalents' | translate }}</h2>

<div *ngIf="rightMatchService.id && competencesOrdered.length !== 0; else noCompetences">
    <ul class="plainList">
        <li>{{ 'commons.date' | translate }}<span [innerHTML]="titleSep"></span> {{ (rightMatchService.profilerInfo.date || rightMatchService.profilerInfo.creationDate) | date:reportDateFormat}}</li>
        <li *ngIf="rightMatchService.jobTitle">{{ 'jobs.commons.jobName' | translate }}<span [innerHTML]="titleSep"></span> {{ rightMatchService.jobTitle }}</li>
    </ul>

    <hr/>
    <div class="clear"></div>

    <div id="jobCompetenceReport">
        <h3 style="margin: 15px 0 25px 0">
            {{'people.reportTalents.keyCompetenciesForJob' | translate }}
        </h3>
        <div *ngFor="let competence of competencesOrdered; let i = index" style="margin-bottom: 15px; border-bottom: 1px solid #ccc">
            <h4>{{ competence[0] }}</h4>
            <app-talent-graph
                [id]="'talent_selector_display_'+i"
                [size]="'large_masked'"
                [type]="'selector'"
                [level]="competence[1]"
                [mobile]="false"
            ></app-talent-graph>
            <p>{{ competence[2] }}</p>
            <div class="polarity semiBold">
                {{ 'jobs.report.high' | translate }}
            </div>
            <p>{{ competence[3] }}</p>
            <div class="polarity semiBold">
                {{ 'jobs.report.low' | translate }}
            </div>
            <p>{{ competence[4] }}</p>
        </div>
        <h3 style="margin: 20px 0 25px 0">
            {{ 'people.reportTalents.interviewQuestions' | translate }}
        </h3>
        <p>
            {{ 'people.reportTalents.questionsUsingLegend' | translate }}
        </p>
        <div *ngFor="let competence of competencesOrdered; let i = index">
            <h4>{{ competence[0] }}</h4>
            <app-talent-graph
                [id]="'talent_selector_empty_'+i"
                [size]="'large_masked'"
                [type]="'empty'"
                [level]="'none'"
            ></app-talent-graph>
            <ul>
                <li *ngFor="let question of competence[5]">
                    {{ question }}
                </li>
            </ul>
        </div>
    </div>

</div>
<ng-template #noCompetences>
    <ng-container *ngFor="let i of [0,1,2]">
        <h4><span>&nbsp;</span></h4>
        <svg width="282.5" height="36.25" preserveAspectRatio="xMidYMid meet" viewBox="0 0 930 105">
            <rect x="0" y="40" width="930" height="20" stroke="none" style="fill: rgb(217, 217, 217);"></rect>
            <rect x="622" y="0" rx="50" ry="50" width="285" height="90" fill="none" stroke-width="6"
                  stroke="#78c7af"></rect>
            <circle cx="669" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white"
                    stroke-width="9"></circle>
            <text class="text" x="670" y="61" text-anchor="middle" font-size="46"></text>
            <circle cx="764" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white"
                    stroke-width="9"></circle>
            <text class="text" x="765" y="61" text-anchor="middle" font-size="46"></text>
            <circle cx="859" cy="45" r="32.5" stroke="rgb(85.0%,85.0%,85.0%)" fill="white"
                    stroke-width="9"></circle>
            <text class="text" x="860" y="61" text-anchor="middle" font-size="46"></text>
        </svg>
        <p class="georgia">
            <span *ngFor="let j of [0,1]">&nbsp;</span>
        </p>
        <b>&nbsp;</b>
        <p *ngFor="let j of [0,1]" class="georgia">
            <span *ngFor="let k of [0,1]">&nbsp;</span>
        </p>
        <hr class="h4sep"/>
    </ng-container>
</ng-template>
