import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {PeopleMultiselectService} from 'src/app/people/services/people-multiselect.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {CdkDragDrop, CdkDragStart, moveItemInArray} from '@angular/cdk/drag-drop';
import {AccountTypesHelper} from '../../../../../administration/commons/AccountTypesHelper';

@Component({
    selector: 'app-main-list',
    templateUrl: './main-list.component.html',
    styleUrls: ['./main-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainListComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('cardColumn') private cardColumn: ElementRef;

    @Input() fields;
    @Input() small;
    @Input() listsData;
    @Input() forceWidthTo;
    @Input() view;
    @Input() loading = false;
    @Input() checkboxes = false;
    @Input() selectedItemId: string;
    @Input() tableDefinition: any;
    @Input() clickMode: boolean;
    @Input() archive = false;
    @Input() removeCheckedElmtId: string;
    @Input() unselect: boolean;
    @Input() isAdmin = false;
    @Input() poste = false;
    @Input() currentLang = 'fr';
    @Input() actualModule = 'people';

    @Output() listScrolled = new EventEmitter();
    @Output() selectedItem = new EventEmitter();
    @Output() checkableItem = new EventEmitter();
    @Output() reordering = new EventEmitter();
    @Output() activateReport = new EventEmitter();
    @Output() requestSendMpoEvent = new EventEmitter();
    @Output() requestResendMpoEvent = new EventEmitter();
    @Output() requestSendIacEvent = new EventEmitter();
    @Output() requestResendIacEvent = new EventEmitter();

    // colWidth: Array<number> = [];
    colNumRatio;
    dataTable;
    columns: any[] = [];
    displayedColumns: string[] = [];
    previousIndex: number;
    // order the table by the creationDate column from recent to old by default
    // it will be rewritten if orderedBy state object contains the last ordered column
    matSortActive = 'creationDate';
    matSortDirection: 'desc' | 'asc' | '' = 'desc';
    orderedBy;

    // Cards
    listWidth: number;
    innerWidth;
    rightDistance;
    md = 2;

    decalageLeft;

    constructor(
        private translate: TranslateService,
        public cd: ChangeDetectorRef,
        public state: StateService,
        public peopleMultiselect: PeopleMultiselectService
    ) {
    }

    transitActivateReport(event) {
        if (!this.clickMode) {
            this.activateReport.emit(event);
        }
    }

    transitRequestSendMpoEvent(event) {
        this.requestSendMpoEvent.emit(event);
    }

    transitRequestResendMpoEvent(event) {
        this.requestResendMpoEvent.emit(event);
    }

    transitRequestSendIacEvent(event) {
        this.requestSendIacEvent.emit(event);
    }

    transitRequestResendIacEvent(event) {
        this.requestResendIacEvent.emit(event);
    }

    statusColor(item, colTitle) {
        if (item && item['testStatuses']) {
            let columnName = colTitle.slice(colTitle.length - 3);
            if (columnName === 'CAI' || columnName === 'IAC') {
                columnName = 'ra';
            } else {
                columnName = 'mpo';
            }
            if (item['testStatuses'][columnName] === 'H') {
                return 'grayIcon';
            }
            if (item['testStatuses'][columnName] === 'C') {
                return 'orangeIcon';
            }
            if (item['testStatuses'][columnName] === 'NC') {
                return 'darkGrayIcon';
            }
        }
        // Same as (item['testStatuses']['mpo'] === 'H' || item['testStatuses']['ra'] === 'N')
        return 'grayIcon';
    }

    statusAbr(obj, key) {
        let statusTxt ;
        if (key === 'iac') {
            key = 'ra';
        }
        if (key && obj.testStatuses && obj.testStatuses[key]) {
            statusTxt = obj.testStatuses[key];
        }
        // if (key === 'prb' && obj.testStatuses && obj.testStatuses.mpo === 'H') {
        //    statusTxt = 'H';
        // }
        // VERY DIRTY FIX :: As we are refactoring, this will do for now
        if (key === 'ra' && obj.reportStatuses.ra === false && obj.testStatuses.ra === 'N') {
            statusTxt = 'OH';
        }
        // End dirty fix
        switch (statusTxt) {
            case 'NC':
                return this.translate.instant('people.commons.notCompleted');
            case 'C':
                return this.translate.instant('commons.completed');
            case 'N':
                return this.translate.instant('commons.none');
            default:
                return this.translate.instant('commons.onHold');
        }
    }

    isChecked(itemId, event) {
        let state = event.target.checked ? 'checked' : 'unchecked';
        this.checkableItem.emit({
            record: itemId,
            state: state
        });
    }

    clear(rowKey) {
        if (rowKey === 'recordType') {
            return 'recordTypeTxt';
        } else if (!this.isAdmin && rowKey === 'status') {
            return 'statusTxt';
        } else {
            return rowKey;
        }
    }

    ouputTransactionTooltip(days, warningLevel) {
        if (warningLevel === 'bblack') {
            if (this.translate.currentLang === 'fr') {
                return 'Aucune transaction depuis l\'ouverture du compte';
            } else {
                return 'No transaction since the account was opened';
            }
        }
        if (this.translate.currentLang === 'fr') {
            if (days > 120) {
                return 'Dernière transaction il y a plus de 121 jours';
            } else if (days > 60) {
                return 'Dernière transaction il y a entre 61 et 120 jours';
            } else {
                return 'Dernière transaction il y a moins de 60 jours';
            }
        } else {
            if (days > 120) {
                return 'The last transaction occured more than 120 days ago.';
            } else if (days > 60) {
                return 'The last transaction occured between 61 and 120 days ago';
            } else {
                return 'The last transaction occured less than 60 days ago';
            }
        }
    }

    ouputLowCreditTooltip(amount) {
        if (this.translate.currentLang === 'fr') {
            if (amount > 20 || amount === 'Unlimited' || amount === 'Illimité') {
                return 'Plus de 20 crédits au compte';
            } else {
                return '20 crédits ou moins au compte';
            }

        } else {
            if (amount > 20 || amount === 'Unlimited' || amount === 'Illimité') {
                return 'More than 20 credits on account';
            } else {
                return '20 credits or less on account';
            }
        }
    }

    setTableWidth() {
        this.innerWidth = window.innerWidth;
        let widthRatio: number = (this.isAdmin || window.innerWidth < 1235) ? 1 : 2 / 3;

        let leftColumnWidth = 100;

        const tWidth = (window.innerWidth < 710) ? 710 : window.innerWidth; // (startWidth.innerWidth > 1280) ? startWidth.innerWidth : 1280;
        this.listWidth = (tWidth - leftColumnWidth) * widthRatio + 3 - 66;
        if (this.forceWidthTo) {
            this.listWidth = this.forceWidthTo - 56;
        }
        this.rightDistance = window.innerWidth / 3 + 'px';

        this.colNumRatio = (1 / this.tableDefinition.length) * 100;

        this.cd.markForCheck();

    }



    ngAfterViewInit() {
        this.decalageLeft = this.computeDecalageLeft();
        /**
        const selectedItem = document.querySelector('.selItem');
        if (selectedItem && selectedItem.id && this.listsData && this.listsData.data) {
            const selItemIndex = Number.parseInt(selectedItem.id.split('tr_')[1], 10);
            const itemsRest =  this.listsData.data.length - selItemIndex;
            if (itemsRest > 0) {
                const nextIndex = selItemIndex + 26;
                let scrollToItem;
                for (let i = nextIndex; i > selItemIndex; i--) {
                    scrollToItem = document.querySelector('#tr_' + i);
                    if (scrollToItem) {
                        scrollToItem.scrollIntoView(false);
                        i = selItemIndex;
                    }
                }
            } else {
                selectedItem.scrollIntoView(false);
            }
        }*/
    }

    ngOnChanges(changes) {
        if (changes.clickMode && !changes.clickMode.currentValue && this.peopleMultiselect) {
            this.peopleMultiselect.selectedItems = [];
        }
        if (!this.selectedItemId && changes && changes.selectedItemId && changes.selectedItemId.currentValue) {
            this.selectedItemId = changes.selectedItemId.currentValue;
        }

        this.tableDefinition = this.cleanTableDefinition(this.tableDefinition);
        if (this.cleanTableDefinition(changes.tableDefinition)) {
            if (changes.clickMode === true) {
                this.selectedItemId = null;
            }
            if (changes.clickMode === true && !changes.clickMode.currentValue) {
                this.peopleMultiselect.selectedItems = [];
            }
            this.setTableWidth();
        }

        this.initMatColumns();
        this.setMatOrder();

        this.dataTable = new MatTableDataSource(this.listsData.data);
        this.dataTable.sort = this.sort;

        this.decalageLeft = this.computeDecalageLeft();

    }

    setMatOrder() {
        this.orderedBy = this.state[this.actualModuleName()].listColumns.filter(col => col.order !== undefined);

        // show the ordered column (key and order) from state if it exists
        if (this.orderedBy[0] && this.orderedBy[0].key) {
            this.matSortActive = this.orderedBy[0].key;
        }
        if (this.orderedBy[0] && this.orderedBy[0].order) {
            this.matSortDirection = this.orderedBy[0].order;
        }
    }

    cleanTableDefinition(tableDefinition) {
        return _.reject(tableDefinition, function (o) {
            if (o) {
                return o.display === false;
            }
        });

    }

    canSort(item) {
        if (
            item !== 'hierarchy[\'partner\'].accountName' &&
            item !== 'hierarchy[\'distributor\'].accountName' &&
            item !== 'departmentName'
        ) {
            return true;
        }
        return;
    }

    setDisplayedColumns() {
        if (this.actualModule === 'admin') {
            this.columns = _.filter(this.columns, function(o) {return o.field !== 'statusIac'; });
            this.columns = _.filter(this.columns, function(o) {return 'field' in o; });
        }
        this.columns.forEach(( col, index) => {
            col.index = index;
            this.displayedColumns[index] = col.field;
        });
    }

    dragStarted(event: CdkDragStart, index: number ) {
        this.previousIndex = index;
    }

    dropListDropped(event: CdkDragDrop<any>, index: number) {
        if (event) {
            moveItemInArray(this.columns, this.previousIndex, index);
            this.setDisplayedColumns();
            this.state[this.actualModuleName()].columnsOrder = this.columns;
        }
    }

    initMatColumns() {
        if (this.tableDefinition.length !== this.columns.length) {
            this.columns = [];
            this.displayedColumns = [];
        }
        if (this.state && this.state[this.actualModuleName()].columnsOrder) {
            this.columns = this.state[this.actualModuleName()].columnsOrder;
        }
        this.tableDefinition.forEach((row) => {
            if (!this.columns.find(el => el.field === row.key)) {
                this.columns.push({ field: row.key });
            }
        });
        this.columns.forEach((row, index) => {
            if (!this.tableDefinition.find(el => el.key === row.field)) {
                // remove column from the columns list if it was unselected in the display preferences
                this.columns.splice(index, 1);
            }
        });
        this.setDisplayedColumns();
    }

    ngOnInit() {
        this.tableDefinition = this.cleanTableDefinition(this.tableDefinition);
        this.initMatColumns();
        this.setMatOrder();

        this.dataTable = new MatTableDataSource(this.listsData.data);
        this.dataTable.sort = this.sort;

        this.setTableWidth();
        if (window.innerWidth > 1550) {
            this.md = 3;
        } else if (window.innerWidth <= 1550 && window.innerWidth >= 1000) {
           this.md = 4;
        } else if (window.innerWidth < 1000) {
           this.md = 6;
        }
    }

    sortColumn(sort: Sort) {
        if (sort.direction === '') {
            let defaultDirection = 'desc';
            // default sorting by date if we cancel any other sorting
            if (sort.active === 'creationDate' || sort.active === 'date') {
                defaultDirection = 'asc';
            }

            if (this.actualModuleName() === 'people' ||
                this.actualModuleName() === 'archivesPeople' ||
                this.actualModuleName() === 'admin') {
                this.order('creationDate', defaultDirection);
            } else {
                this.order('date', defaultDirection);
            }
        } else {
            this.order(sort.active, sort.direction);
        }
    }

    order(key, order) {
        let event = {key: key, order: order};
        this.reordering.emit(event);
    }

    onResize() {
        // this.innerwidth = event.target.innerWidth;
        this.setTableWidth();
        this.md = 2;
        if (window.innerWidth > 1550) {
            this.md = 3;
        } else if (window.innerWidth <= 1550 && window.innerWidth >= 1000) {
            this.md = 4;
        } else if (window.innerWidth < 1000) {
            this.md = 6;
        }
        this.decalageLeft = this.computeDecalageLeft();
        this.cd.markForCheck();
    }

    get raPermission() {
        return this.state.hasIac;
    }

    computeDecalageLeft() {
        if (this.view === 'cards' && this.cardColumn) {
            let cardWidth = this.cardColumn.nativeElement.offsetWidth - 8.25;
            if (this.raPermission) {
                return [
                    0,
                    (cardWidth - 60) * .25,
                    (cardWidth - 60) * .50,
                    (cardWidth - 60) * .75,
                    cardWidth - 60,
                ];
            } else {
                return [
                    0,
                    (cardWidth - 60) * .33,
                    (cardWidth - 60) * .66,
                    cardWidth - 60,
                ];
            }
        }
    }

    actualModuleName() {
        let actualModule = this.actualModule;
        if (this.archive) {
            let module = this.actualModule;
            let capitalized = module[0].toUpperCase() +
                module.slice(1);
            actualModule = 'archives' + capitalized;
        }
        return actualModule;
    }

    onSelectItem(item) {
        if (this.clickMode) {
            if (this.isSelected(item[0])) {
                this.state[this.actualModuleName()].multiselect.splice(
                    this.peopleMultiselect.selectedItems.indexOf(item[0]),
                    1
                );
            } else {
                this.state[this.actualModuleName()].multiselect.push(item[0]);
            }
            this.peopleMultiselect.selectedItems = this.state[this.actualModuleName()].multiselect;
            this.state[this.actualModuleName()].multiselectWatch.next(true);
        } else {
            this.selectedItemId = item[0].id;
            this.selectedItem.emit(item);
        }
        this.cd.markForCheck();
    }

    computePadding(word) {
        let divider = 6;
        let wordLength = word.length;
        if (wordLength > 5) {
            divider = 8;
        } else if (wordLength < 4) {
            divider = 2;
        }
        return wordLength / divider;
    }

    isMultiselected(itemId) {
        return (_.indexOf(this.peopleMultiselect.selectedItems.map(item => {return item.id; }), itemId) > -1);
    }

    isSelected(item) {
        return (this.peopleMultiselect.selectedItems.indexOf(item) > -1);
    }

    onKeyList(event) {
        if (document.activeElement.tagName === 'BODY') {
            let mainListWrap = document.getElementById('listWrap');
            let topMenuWrap = document.getElementById('topMenuWrapper');
            let firstTh = document.getElementById('cdk-drop-list-0');
            let topMenuHeight = topMenuWrap ? topMenuWrap.offsetHeight : 161;
            let rowHeight = firstTh ? firstTh.offsetHeight : 28;
            // get the height of the visible zone of the items list (we also have to subtract the height of the titles row)
            let visibleListHeight = window.innerHeight - topMenuHeight - rowHeight;
            // get the number of the visible list rows
            let visibleRowsNumber = Math.floor(visibleListHeight / rowHeight);
            let fixList = true;
            let index = _.findIndex(this.listsData.data, {'id': this.selectedItemId});
            if (event.key === 'ArrowDown' && (index + 2) > visibleRowsNumber) {
                fixList = false;
            }
            if (event.key === 'ArrowUp' && (index - 1) >= visibleRowsNumber) {
                fixList = false;
            }
            if (mainListWrap && mainListWrap.classList) {
                if (!fixList) {
                    if (mainListWrap.classList.contains('affix')) {
                        mainListWrap.classList.remove('affix');
                    }
                } else if (!mainListWrap.classList.contains('affix')) {
                    mainListWrap.classList.add('affix');
                }
            }
            if (event.key === 'ArrowDown') {
                if (this.listsData.data[index + 1]) {
                    this.selectedItemId = this.listsData.data[index + 1].id;
                    this.selectedItem.emit([this.listsData.data[index + 1], 'current']);
                }
                return;
            } else if (event.key === 'ArrowUp') {
                if (this.listsData.data[index - 1]) {
                    this.selectedItemId = this.listsData.data[index - 1].id;
                    this.selectedItem.emit([this.listsData.data[index - 1], 'current']);
                }
                return;
            }
        }
    }

    checkScroll() {
        let mainListWrap = document.getElementById('listWrap');
        if (mainListWrap && mainListWrap.classList && mainListWrap.classList.contains('affix')) {
            mainListWrap.classList.remove('affix');
        }
    }

    // noinspection JSUnusedLocalSymbols
    onKeyCards(event) {

    }

    isVContent(title) {
        if (title === 'Personality' ||
            title === 'Personnalité' ||
            title === 'Communication' ||
            title === 'Talent' ||
            title === 'Talents' ||
            title === 'Satellite' ||
            title === 'Compétences' ||
            title === 'IAC' ||
            title === 'CAI' ||
            title === 'Rapport IAC' ||
            title === 'CAI Report' ||
            (title === 'Portrait' && this.poste)
        ) {
            return true;
        }
        return;
    }

    getQuestionnaireStatus(obj) {
        let reportStatuses = ``;
        let reportSep = '';
        const reportsNames = {
            mpo: {
                fr: 'Personnalité',
                en: 'Personality'
            },
            prb: {
                fr: 'Perception',
                en: 'Perception'
            },
            dit: {
                fr: 'Communication',
                en: 'Communication'
            },
            talents: {
                fr: 'Talent',
                en: 'Talent'
            },
            satellite: {
                fr: 'Satellite',
                en: 'Satellite'
            },
            ra: {
                fr: 'IAC',
                en: 'CAI'
            }
        };

        for (let report in obj.reportStatuses) {
            if (report &&
                obj.reportStatuses.hasOwnProperty(report) &&
                obj.reportStatuses[report] &&
                reportsNames.hasOwnProperty(report)
            ) {
                reportStatuses += reportSep + reportsNames[report][this.translate.currentLang];
                reportSep = ', ';
            }
        }
        return reportStatuses;
    }

    getDefinitionObject(keyValue) {
        return this.tableDefinition.find(el => el.key === keyValue);
    }

    getColumnTitle(keyValue) {
        if (this.getDefinitionObject(keyValue)) {
            return this.getDefinitionObject(keyValue).title;
        }
        return;
    }

    loGet(obj, key) {
        switch (key) {
            case 'department':
                return (obj[key] && obj[key].length > 0) ? obj[key][0][this.currentLang] : '';
            case 'accountType' :
                return this.translate.instant(AccountTypesHelper.getTranslationStringForType(obj.accountType));
            case 'creationDate': // put other dates here
            case 'renewalDate':
            case 'date':
                return this.formatDate(_.get(obj, key));
            case 'lastTransactionLevel':
                return this.lastTransactionWarningLevelTest(obj, key);
            default:
                return _.get(obj, key);
        }
    }

    today() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm: any = today.getMonth() + 1; // Months start at 0!
        let dd: any = today.getDate();

        if (dd < 10) { dd = '0' + dd; }
        if (mm < 10) { mm = '0' + mm; }

        return dd + '-' + mm + '-' + yyyy;
    }

    daysBetweenDates(date1, date2) {
        const date1Arr = date1.split('-');
        const date2Arr = date2.split('-');
        const date1Obj = new Date(date1Arr[2], date1Arr[1], date1Arr[0]);
        const date2Obj = new Date(date2Arr[2], date2Arr[1], date2Arr[0]);
        const timeDiff = Math.abs(date2Obj.getTime() - date1Obj.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));
    }

    lastTransactionWarningLevelTest(obj, key) {
        if (parseInt(_.get(obj, key), 10) === -1) {
            return this.daysBetweenDates(this.today(), obj['creationDate']);
        }
        return _.get(obj, key);
    }

    lastTransactionWarningLevelColor(obj, key) {
        let warningLevel = _.get(obj, key);
        if (parseInt(warningLevel, 10) === -1) {
            return 'bblack';
        }
        if (parseInt(warningLevel, 10) > 120) {
            return 'bred';
        } else if (parseInt(warningLevel, 10) > 60) {
            return 'byellow';
        } else {
            return 'bgreen';
        }
    }
    lowCreditWarningColor(obj, key) {
        if (obj['type'] === 'demos') {
            return 'bblack';
        }

        return (_.get(obj, key) > 20 || _.get(obj, key) === 'Unlimited' || _.get(obj, key) === 'Illimité') ? 'bgreen' : 'bred';
    }

    formatDate(date) {
        if (date !== undefined) {
            let dateArr = date.split('-');
            if (this.isAdmin) {
                if (this.translate.currentLang === 'fr') {
                    return dateArr[0] + '/' + dateArr[1] + '/' + dateArr[2];
                } else {
                    return dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
                }
            } else {
                if (this.translate.currentLang === 'fr') {
                    return dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];
                } else {
                    return dateArr[1] + '/' + dateArr[2] + '/' + dateArr[0];
                }
            }
        }
        return;

    }

    // admData(item, key) {
    //     // console.log('item: ' + item);
    //     // let dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])$/;
    //     // let dateRegex1 = /^([0-3][0-9])\/([0-1][0-9])\/([0-9]{4})$/;
    //
    //     console.log(key, item[key]);
    //
    //     // Check for ISO Date
    //     let dateRegex = /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])T([0-9]{2}):([0-9]{2}):([0-9]{2}.([0-9]{3})Z)$/;
    //     if (dateRegex.test(item[key])) {
    //         return item[key];
    //     }
    //     return;
    // }
}
