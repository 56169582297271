<div class="floatLeft"
     [class.navbuttons]="!isMobile"
     [class.navbuttonsMobile]="isMobile"
     [class.hasNoPredecessor]="!hasPredecessor"
     (click)="onPreviousYear()"
>
    ◀&#xFE0E; {{ precText() }}
</div>
<div class="floatRight"
     [class.navbuttons]="!isMobile"
     [class.navbuttonsMobile]="isMobile"
     [class.hasNoSuccessor]="!hasSuccessor"
     (click)="onNextYear()"
>
     {{ nextText() }} ▶&#xFE0E;
</div>
<div class="clearfix"></div>
<div>
    <div class="d3-chart" #jobSatisfactionTeamGraph></div>
</div>
