import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IMyOptions} from 'mydatepicker';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {ComponentPrimitive} from '../../../shared/classes/components/componentPrimitive';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {StateService} from 'src/app/core/services/state/state.service';
import {AccountService} from '../../services/account.service';
import {UserService} from '../../services/user.service';
import {PermissionHelper} from '../../../shared/misc/permission.helper';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';

@Component({
    selector: 'app-administration-user-form-field',
    templateUrl: './administration-user-form-field.component.html',
    styleUrls: ['./administration-user-form-field.component.scss']
})
export class AdministrationUserFormFieldComponent extends ComponentPrimitive implements OnInit, OnChanges {
    @Input() newItem = false;
    @Input() section = 'account';
    @Input() info: any;
    @Input() itemData: any;
    @Input() mainAdmins: any;
    @Input() subAccountId: number;
    @Input() dataObjectName: string;
    @Input() formPermissions: any;
    @Input() closeIfNot: any;

    @Output() loader = new EventEmitter();
    @Output() closeAllAdminFields = new EventEmitter();

    edit: any = {};
    editEnable = false;
    focused = '';
    originFieldValue: any;
    addressInfoFields: any = ['address', 'city', 'zipcode', 'region', 'country'];
    mpoProductsFields: any = ['adoptionDate', 'productCode', 'renewalDate', 'administrator', 'dropDate', 'dropDateNote'];
    formationFields = [
        'personality.training',
        'personality.formationDate',
        'personality.location',
        'personality.trainer',
        'communication.training',
        'communication.formationDate',
        'communication.location',
        'communication.trainer',
        'talents.training',
        'talents.formationDate',
        'talents.location',
        'talents.trainer',
    ];
    notRegularFields = [
        'password',
        'role',
        'website',
        'accountType',
        'expiresAt',
        'demoExpiresAtLocal',
        'status',
        'parentPath',
        'adoptionDate',
        'renewalDate',
        'dropDate',
        'mpo',
        'dit',
        'talents',
        'satellite',
        'ra',
        'subAccountAccess',
        'principalSubAccount',
        'language',
        'training'
    ];
    notInputFields = [
        'creationDate',
        'role',
        'accountType',
        'expiresAt',
        'demoExpiresAtLocal',
        'status',
        'parentPath',
        'adoptionDate',
        'productCode',
        'renewalDate',
        'administrator',
        'dropDate',
        'mpo',
        'dit',
        'talents',
        'satellite',
        'ra',
        'subAccountAccess',
        'principalSubAccount',
        'language',
        'training',
        'formationDate'
    ];

    ssModel: any = [];
    ssOptions = [];

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    public datePickerOptions: {[key: string]: IMyOptions} = {
        'fr': {dateFormat: 'dd/mm/yyyy'},
        'en': {dateFormat: 'mm/dd/yyyy'},
    };

    private subscriptions = new Subscription();

    constructor(
        private apiAdministrationService: ApiAdministrationService,
        protected stateService: StateService,
        public clientAccount: AccountService,
        public user: UserService,
        public translate: TranslateService,
        protected router: Router,
        protected deviceService: DeviceDetectorService
    ) {
        super(stateService, translate, router, deviceService);
    }


    ngOnInit() {
        if (!this.itemData) {
            this.itemData = {};
        }
        if (!this.itemData && this.formPermissions) {
            for (let perm of ['mpo', 'dit', 'talents', 'satellite', 'ra']) {
                this.itemData[perm] = this.formPermissions[perm];
            }
        }
        if (this.section === 'user' && this.info && this.info.field === 'status') {
            this.itemData.status = (this.itemData.status) ? 1 : 0;
        }
        if (this.clientAccount.subAccounts && this.user.subAccountAccess) {
            this.ssModel = this.clientAccount.subAccounts
                .filter(subAccount => this.user.subAccountAccess.includes(subAccount.id))
                .map(subAccount => subAccount.id);
        }

        for (let subAcct of this.clientAccount.subAccounts) {
            this.ssOptions.push({id: subAcct.id, name: subAcct.name});
        }
    }

    isEditable(field: string) {
        let isEditable = this.editEnable;
        if (this.section === 'account') {
            isEditable = (this.edit && this.edit[this.subAccountId] && this.edit[this.subAccountId][field]);
        }
        return isEditable;
    }

    isRegularField(field) {
        return !(this.notRegularFields.includes(field));
    }

    isInputField(field) {
        return !(this.notInputFields.includes(field));
    }

    isFormationField(field) {
        if (this.dataObjectName && this.dataObjectName !== '') {
            field = this.dataObjectName + '.' + field;
        }
        return !!(this.formationFields.includes(field));
    }

    isDateField(field) {
        return !!(field.includes('Date'));
    }

    setExpiresAtValue(value) {
        let dateToUse;
        if (value && value.date) {
            dateToUse = value.date.year + '-' + value.date.month + '-' + value.date.day;
            this.itemData.expiresAt = dateToUse;
        }
    }

    process(item, imteMondod) {
        console.log('item', item);
        console.log('imteMondod', imteMondod);
        return item;
    }

    setDateValue(which, value) {
        let split = value.formatted.split('/');
        this.itemData[which] = {fr: value.formatted, en: value.formatted};
        if (split.length > 1) {
            if (this.translate.currentLang === 'fr') {
                this.itemData[which]['en'] = split[1] + '/' + split[0] + '/' + split[2];
                return;
            }
            if (this.translate.currentLang === 'en') {
                this.itemData[which]['fr'] = split[1] + '/' + split[0] + '/' + split[2];
                return;
            }
        }
    }

    get userHasRoleSatellite() {
        return (this.user.role === 'ROLE_USER_SATELLITE');
    }

    checkFormPermission(event, report) {
        if (report) {
            this.formPermissions[report] = event.checked;
        }
    }

    checkLanguageName() {
        if (this.sessionLanguages && this.sessionLanguages[this.itemData[this.info.field]]) {
            const langArr = this.sessionLanguages[this.itemData[this.info.field]].filter(lang => lang.value === this.itemData[this.info.field]);
            return langArr[0].name;
        }
    }

    ngOnChanges(change) {
        if (change.closeIfNot && change.closeIfNot.currentValue &&
            change.closeIfNot.currentValue !== this.info.field
        ) {
            this.cancelEditField(this.info['field'], true);
        }
    }

    editField(field: string, activeEdit = true) {
        this.originFieldValue = this.itemData[field];

        this.editEnable = activeEdit;
        this.edit = Object.assign(this.edit, {[this.subAccountId]: {[field]: activeEdit}});
        if (field === 'subAccountAccess') {
            setTimeout(() => {
                this.onSubAccountsChange();
            });
        }

        this.closeAllAdminFields.emit(field);
    }

    cancelEditField(field: string, autClose = false) {
        const activeEdit = false;

        // if (field === 'productCode') {
        // empty the description field
        //    this.itemData.productCode = '';
        // }
        if (!autClose) {
            this.itemData[field] = this.originFieldValue;
        }
        this.editEnable = activeEdit;
        this.edit = Object.assign(this.edit, {[this.subAccountId]: {[field]: activeEdit}});
    }

    updateField(field: string, value: any) {
        this.editField(field, false);

        let payload = {};
        if (['permissions'].includes(field)) {
            payload = {
                permissions: this.formPermissions
            };
            this.subscriptions.add(this.apiAdministrationService.putUser([this.user.id], payload)
                .subscribe
                (
                    () => {
                        this.user.setUserToDisplayWithId(this.user.id);
                        this.editEnable = false;
                    }
                ));
        } else {
            let processedValue = value;
            let data = {};

            // users case
            if (field === 'status') {
                processedValue = this.processBooleanField(value);
            }

            if (processedValue === 'true' || processedValue === 'false') {
                processedValue = processedValue === 'true';
            }


            // address
            if (this.addressInfoFields.includes(field)) {
                let addressInfoUpdated = this.itemData;
                addressInfoUpdated[field] = processedValue;
                data = {'addressInfo': [addressInfoUpdated], clientId: this.clientAccount.id};
            } else if (this.isFormationField(field)) {
                let formationUpdated = this.user.formation;
                if (this.dataObjectName && this.dataObjectName !== '') {
                    formationUpdated[this.dataObjectName] = this.itemData;
                } else {
                    formationUpdated = this.itemData;
                }
                if (field === 'formationDate' && processedValue) {
                    if (this.translate.currentLang === 'fr') {
                        processedValue = moment(processedValue, 'DD/MM/YYYY');
                        processedValue = moment(processedValue).format('MM/DD/YYYY');
                    }
                    processedValue = new Date(processedValue).getTime() / 1000;
                    processedValue = {sec: processedValue, usec: 0};
                }
                if (formationUpdated[this.dataObjectName]) {
                    formationUpdated[this.dataObjectName][field] = processedValue;
                } else {
                    formationUpdated[field] = processedValue;
                }
                data = {'formation': formationUpdated, clientId: this.clientAccount.id};
            } else {
                data = {[field]: processedValue, clientId: this.clientAccount.id};
            }

            if (field === 'role' && value === 'ROLE_USER_SATELLITE') {
                data['permissions'] = {
                    mpo: false,
                    dit: false,
                    talents: false,
                    satellite: true,
                    ra: false
                };
            }

            // call api to save the modification
            this.subscriptions.add(this.apiAdministrationService.putUser([this.user.id], data).subscribe(
                () => {
                    this.user.setUserToDisplayWithId(this.user.id);
                    this.editEnable = false;
                })
            );
        }
    }

    processBooleanField(value: boolean) {
        return (value) ? value : 0;
    }

    onSubAccountsChange() {
        let currentAccounts = this.checkDropDownOptions(this.ssModel);
        let colClass = this.info.field + '_field';
        let accList = this.translate.instant('commons.select');
        if (this.ssOptions) {
            accList = '';
            let accListSep = '';
            let accCounter = 0;
            this.ssOptions.forEach((opt) => {
                if (opt && currentAccounts.includes(opt.id)) {
                    if (accCounter < 2) {
                        accList += accListSep + opt.name;
                    }
                    accListSep = ', ';
                    accCounter++;
                }
            });
            if (accCounter > 2) {
                accList += ' (+' + (accCounter - 2).toString() + ')';
            }
        }
        this.checkDropDownPlaceholder(currentAccounts, colClass, accList);
        this.itemData.subAccountAccess = currentAccounts;
    }

    onChangeFormationDate(dateData) {
        this.itemData.formationDate = dateData.formatted;
    }

    mongoDateToDp(date) {
        if (date && date !== 'Invalid date') {
            if (!date.hasOwnProperty('sec')) {
                return date;
            }
            if (date.hasOwnProperty('sec') && !date.sec) {
                return;
            }
            if (date.sec) {
                date = date.sec * 1000;
            }
            return ('en' === this.translate.currentLang) ?
                moment(new Date(date)).format('MM/DD/YYYY') :
                moment(new Date(date)).format('DD/MM/YYYY');
        } else {
            return;
        }
    }

    canSelectUser(roleName: string): boolean {
        return PermissionHelper.canSelectSatelliteUser(roleName, this.clientAccount.permissions);
    }

    get canEditItem() {
        if (this.section === 'account') {
            return !!(this.permissionsRoles.includes('ROLE_SUPER_ADMIN'));
        } else if (['mpo', 'dit', 'talents', 'ra'].includes(this.info['field']) && this.userHasRoleSatellite) {
            return false;
        } else {
            if (this.info && (this.info.field === 'subAccountAccess' || this.info.field === 'principalSubAccount')) {
                return !!(
                    this.permissionsRoles.includes('ROLE_SUPER_ADMIN') ||
                    this.permissionsRoles.includes('ROLE_MAIN_ADMIN') ||
                    this.permissionsRoles.includes('ROLE_ADMIN')
                );
            } else {
                return !!(this.permissionsRoles.includes('ROLE_SUPER_ADMIN') || this.user.username === this.sessionData.userData.username);
            }
        }
    }

    get sessionData() {
        return _.get(this.stateService, 'session.sessionData');
    }

    get sessionStructure() {
        return _.get(this.sessionData, 'structure');
    }

    get sessionLanguages() {
        return _.get(this.sessionStructure, 'languages');
    }

    get userRoles() {
        return this.sessionStructure.userTypes.filter(
            userType => userType.value !== 'ROLE_SUPER_ADMIN'
        );
    }

    get sessionPermissions() {
        return _.get(this.sessionData, 'permissions');
    }

    get permissionsRoles() {
        return _.get(this.sessionPermissions, 'roles');
    }

    get parentAccounts() {
        return (this.clientAccount.parentAccounts) ? this.clientAccount.parentAccounts : this.itemData.parentAccounts;
    }

    get subAccountName() {
        for (let account of this.clientAccount.subAccounts) {
            if (account.id === parseInt(this.user.principalSubAccount, 10)) {
                return account.name;
            }
        }
        return;
    }

    get subAccountsAccess() {
        if (this.user.subAccountAccess) {
            return this.clientAccount.subAccounts
                .filter(subAccount => this.user.subAccountAccess.includes(subAccount.id))
                .map(subAccount => subAccount.name)
                .join(', ');
        }
        return;
    }

}
