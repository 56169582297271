<div *ngIf="reportFormGroup" [formGroup]="reportFormGroup">

    <span class="semiBold"
          [class.text-danger]="isFormSubmitted && reportFormGroup.errors"
    >
        {{ 'commons.report' | translate }}
    </span><br>
    <div *ngIf="!mobile">
        <div id="wantedReportsLeft" class="pull-left">
            <!-- MPO -->
            <div
                    *ngIf="stateService.hasPersonality"
                    class="testSpacing topTest"
            >
                <mat-checkbox id="report_mpo"
                              formControlName="mpo"
                >{{ 'commons.personality' | translate }}
                </mat-checkbox>
            </div>
            <!-- Satellite -->
            <div
                    *ngIf="stateService.hasSatellite"
            >
                <mat-checkbox id="report_satellite"
                              formControlName="satellite"
                >Satellite
                </mat-checkbox>
            </div>
            <!-- DIT -->
            <div class="testSpacing"
                 *ngIf="stateService.hasCommunication"
            >
                <span
                    [tooltip]="activationText"
                    [isDisabled]="!disableDitReportByDefault"
                    style="display: inline-block;"
                >
                    <mat-checkbox id="report_dit"
                                  formControlName="dit"
                    >{{ 'commons.dit' | translate }}
                    </mat-checkbox>
                </span>
                <ng-template #activationText>
                        <span *ngIf="currentLanguage === 'fr'">
                            Pour activer le rapport Communication veuillez cocher le rapport Personnalité.
                        </span>
                    <span *ngIf="currentLanguage === 'en'">
                        In order to enable the Communication report you have to check the Personality report.
                    </span>
                </ng-template>
            </div>
            <!-- Talents -->
            <div
                    *ngIf="stateService.hasTalents"
            >
                <mat-checkbox id="report_talent"
                              formControlName="talents"
                >Talent
                </mat-checkbox>
            </div>
            <!-- Iac -->
            <div
                    *ngIf="raPermission"
            >
                <mat-checkbox id="report_iac"
                              formControlName="iac"
                >{{ 'commons.iac' | translate }}
                </mat-checkbox>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div *ngIf="mobile">
        <!-- MPO -->
        <div>
            <mat-checkbox formControlName="mpo">{{ 'commons.personality' | translate }}</mat-checkbox>
        </div>
        <!-- Satellite -->
        <div>
            <mat-checkbox formControlName="satellite">Satellite</mat-checkbox>
        </div>
        <!-- DIT -->
        <div>
            <mat-checkbox formControlName="dit">{{ 'commons.dit' | translate }}</mat-checkbox>
        </div>
        <!-- Talents -->
        <div>
            <mat-checkbox formControlName="talents">Talent</mat-checkbox>
        </div>
        <!-- IAC -->
        <div *ngIf="raPermission">
            <mat-checkbox formControlName="iac">{{ 'commons.iac' | translate }}</mat-checkbox>
        </div>
    </div>
</div>
