import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PeopleSurveyRequestFormService} from '../../../../../services/people-survey-request-form.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-people-survey-request-credits',
    templateUrl: './credits.component.html',
    styleUrls: ['./credits.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeopleSurveyRequestCreditsComponent implements OnInit, OnDestroy {
    @Input() surveyRequestFormGroup: FormGroup;
    @Input() sessionData: any;

    private onDestroy$: Subject<null> = new Subject();
    public subAccountCreditsString$: Observable<string>;
    public subAccountCredits$: Observable<number | string>;
    public requestCreditsCost$: Observable<number>;
    public displayWarningMessage$: Observable<boolean>;

    constructor(
        private peopleRequestFormService: PeopleSurveyRequestFormService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.subAccountCreditsString$ = this.peopleRequestFormService.subAccountCreditsString$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.subAccountCredits$ = this.peopleRequestFormService.subAccountCredits$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.requestCreditsCost$ = this.peopleRequestFormService.requestCreditsCost$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
        this.displayWarningMessage$ = this.peopleRequestFormService.displayWarningMessage$.pipe(takeUntil(this.onDestroy$), distinctUntilChanged());
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
