
        <h3><strong>{{ 'commons.sendByMyEmail' | translate }}</strong></h3>
        <p>
            {{ 'commons.sendByYourEmailInstructions_client_1' | translate }}
            <span style="font-size: 1.3em; color: red">{{ 'commons.sendByYourEmailInstructions_client_2' | translate }}</span>&nbsp;
            <a style="font-size: 1.3em; color: red" href="{{ getSendMailToLink() }}" class="bbuttonHover">{{ 'commons.here' | translate }}</a>.
        </p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    