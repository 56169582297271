<div *ngIf="!addAnalysis">
    <div class="sticky0">
        <app-mobile-header
            [addAnalysis]="addAnalysis"
            [searchValue]="lastSearch"
            (reloadAction)="refreshList()"
            (searchAction)="setSearchFilter($event)"
        ></app-mobile-header>
    </div>
    <div class="searchResults">
        <span *ngIf="rightMatchService.selectionDone && rightMatchService.totalListItems !== null"
            style="font-weight: bold"
        >
            {{ rightMatchService.totalListItems }}
            {{ rightMatchService.resMention | translate }}
            <span *ngIf="rightMatchService.totalItems">
                {{ 'commons.of' | translate }} {{rightMatchService.totalItems}}
            </span>
        </span>
    </div>
    <h1 class="sectionTitle pb0">
        <span *ngIf='!rightMatchService.archive'>{{ 'app.navigation.analysis' | translate }}</span>
        <span *ngIf='rightMatchService.archive'>{{ 'commons.archives' | translate }}</span>
    </h1>
    <mat-tab-group
        [selectedIndex]="selectedTab"
        (selectedIndexChange)="switchTab($event)"
    >
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.rightMatch' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasCommunication" label="{{ 'commons.idwMatrix' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.graphboard' | translate }}"></mat-tab>
        <mat-tab *ngIf="stateService.hasPersonality" label="{{ 'commons.jobSatisfaction' | translate }}"></mat-tab>
    </mat-tab-group>
    <div infiniteScroll
         [infiniteScrollDistance]="4"
         [infiniteScrollThrottle]="300"
         [scrollWindow]="false"
         (scrolled)="rightMatchService.listScrolled()"
         class="scrollableList"
    >
        <ngx-loading
            [show]="rightMatchService.loading"
            [config]="loaderDisplay"
        ></ngx-loading>
        <app-mobile-main-list *ngIf="rightMatchService.toList"
                              [selectedItemId]="rightMatch.id"
                              [tableDefinition]="rightMatchService.state['tableDefinition']"
                              [sectionName]="'analysis'"
                              [subSection] = "'rightMatch'"
                              [listsData]="rightMatchService.toList"
                              (selectedItem)="rightMatchService.itemSelected($event)"
                              (reordering)="setOrder($event)"
                              [orderedBy]="orderedBy"
        ></app-mobile-main-list>
    </div>
</div>
<button mat-fab *ngIf="!addAnalysis"
        class="newItem-btn"
        (click)="createNewRightMatch()"
><i class="material-icons">add</i></button>
<div *ngIf="addAnalysis">
    <app-mobile-right-matches-edit
        [status]="'new'"
        (close)="backToItemsList()"
    ></app-mobile-right-matches-edit>
</div>
