<h2 class="fontRegular">{{ 'jobs.profilerEmailModal.sendProfiler' | translate }}</h2>
<ng-container *ngIf="toggle == 1" class="reqContent" [@fadeInOut]>
    <h3>{{ 'commons.sendingModeSelection' | translate }}</h3>
    <mat-form-field class="full-width">
        <mat-select
            [(ngModel)]="flipValue"
            (ngModelChange)="toggleFlip(flipValue)"
        >
            <mat-option value="">{{ 'commons.select' | translate }}</mat-option>
            <mat-option value="2">{{ 'commons.sendByMyEmail' | translate }}</mat-option>
            <mat-option value="3">{{ 'commons.sendByServer' | translate }}</mat-option>
            <mat-option value="4">{{ 'commons.cutnpaste' | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>

<ng-container *ngIf="toggle == 2" [@fadeInOut]>
    <div *ngIf="profilerEmailData">
        <h3><b>{{ 'commons.sendByMyEmail' | translate }}</b></h3>
        <p>{{ 'commons.sendByYourEmailInstructions' | translate }}
            <a  style="font-size: 1.3em; color: red"
                href="mailto:?subject={{'jobs.sidebar.targetPersonalityProfileSubject' | translate}}&body={{ mailToGeneration() }}"
                class="bbuttonHover"> {{ 'commons.here' | translate }}
            </a>.
        </p>
        <p class="mailtoWarning" style="color: darkred">
            {{ 'commons.mailtoWarning' | translate }}
        </p>
    </div>
    <div class="modal-footer">
        <div class="pull-right" style="margin-left: 25px">
            <button (click)="finishSending()" class="modalButton">{{ 'commons.finish' | translate }}</button>
        </div>
        <div class="clearfix"></div>
    </div>
</ng-container>

<ng-container *ngIf="toggle == 3" [@fadeInOut]>
    <form [formGroup]="jobProfilerEmailNextForm" (ngSubmit)="onSubmitEmailNext(jobProfilerEmailNextForm)">
        <div *ngIf="profilerEmailData">
            <h3><b>{{ 'commons.sendByNgenioServer' | translate }}</b></h3>
            <div class="form-group">
                <div>
                    <div class="pull-left">
                        <span class="field_title"><label for="emailTo">{{ 'commons.email' | translate }}</label></span>
                    </div>
                    <div class="pull-right">
                        <small *ngIf="blurred && (jobProfilerEmailNextForm.controls.emailTo.dirty ||
                                            jobProfilerEmailNextForm.controls.emailTo.touched)"
                               class="text-danger"
                        >
                                <span *ngIf="jobProfilerEmailNextForm.hasError('invalidEmail')">
                                    {{ 'commons.invalidEmail' | translate }}
                                </span>
                        </small>
                    </div>
                    <div class="clearfix"></div>
                    <input (blur)="blurred = true" type="text" id="emailTo" class="form-control" formControlName="emailTo">
                </div>
                <div>
                    <span class="field_title"><label for="emailMessage">Message</label></span><br/>
                    <textarea
                        style="border: 1px solid #ccc; width: 100%; height: 317px"
                        id="emailMessage"
                        formControlName="message"
                    ></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div style="margin-top: 10px">
                <button type="submit" class="modalButton pull-right"
                        [disabled]="!jobProfilerEmailNextForm.valid">
                    {{ 'commons.send' | translate }}
                </button>
            </div>
            <div style="margin-top: 10px">
                <button type="submit" class="modalButton pull-right" style="margin-right: 15px"
                        (click)="finishSending()">
                    {{ 'commons.cancel' | translate }}
                </button>
            </div>
            <div class="clearfix"></div>
        </div>
    </form>
</ng-container>

<ng-container *ngIf="toggle == 4" [@fadeInOut]>
    <div *ngIf="profilerEmailData" [innerHTML]="cutNPasteGeneration()"></div>
    <div class="modal-footer">
        <div style="margin-top: 15px">
            <div class="pull-right" style="margin-left: 25px">
                <button (click)="finishSending()" class="modalButton">{{ 'commons.finish' | translate }}</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</ng-container>
